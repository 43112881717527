import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import {Grid, Typography, makeStyles} from '@material-ui/core';

import {useAppDispatch, useAppSelector} from 'src/utils/hooks';
import CONSTANT from 'src/config/constant';
import {PageHeader} from 'src/presentation/withHeader';
import MyButton from 'src/presentation/button';
import {
  FormGenerator,
  FormInputFunctions,
} from 'src/presentation/FormGenerator';
import COLOR from 'src/styled/colors';
import {toggleModal, toggleModalOff} from 'src/store/modal/actions';
import {Buttons} from 'src/presentation/ButtonsGroup';
import {DialogContentSubtitle} from 'src/presentation/DialogContentSubtitle';
import {
  approveInvitation,
  inviteContactAgain,
  rejectInvitation,
  revokeContact,
  saveContact,
  unlinkContact,
} from 'src/store/contacts/action';
import {CASL} from '@aglive/frontend-core';
import {
  getBusinessNameDisplay,
  getContactEmailDisplay,
  getContactNameDisplay,
  getStatusDisplay,
} from './utils';
import moment from 'moment';
import {ContactInfo} from './types';

const useStyle = makeStyles((theme) => ({
  greenButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
  },
  sectionTitle: {
    paddingInline: 10,
    paddingBottom: 15,
    marginTop: 50,
    marginBottom: 15,
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    width: '100%',
  },
  buttonGroup: {
    marginTop: 200,
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
  },
  smallButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    fontSize: '1rem',
    textTransform: 'capitalize',
    fontWeight: 400,
    padding: '0.25rem 2rem',
    width: 'max-content',
  },
}));

const ContactDetails: React.FC<{type: 'edit' | 'view'}> = ({type}) => {
  const {id} = useParams<{id: string}>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyle();
  const ability = CASL.useAbility(CASL.AbilityContext);
  const hasEditPermission = ability.can('update', 'contact');

  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const contacts = useAppSelector((state) => state.user.contacts);

  const labels = useMemo(
    () =>
      businessProfile.businessCountry === 'Argentina'
        ? CONSTANT.ES_LABELS
        : CONSTANT.EN_LABELS,
    [businessProfile.businessCountry],
  );

  const contactInfo = useMemo(() => {
    const result = contacts?.find((item) => {
      return item.id === id;
    });
    if (!result) {
      return undefined;
    }
    const name = getContactNameDisplay(result);
    const businessName = getBusinessNameDisplay(result);
    const email = getContactEmailDisplay(result);
    return {
      ...result,
      name: type === 'edit' ? name : name || '-',
      businessName: type === 'edit' ? businessName : businessName || '-',
      email: type === 'edit' ? email : email || '-',
    };
  }, [contacts, id, type]);

  const formRef = useRef<FormInputFunctions<typeof contactInfo>>();

  const [formReady, setFormReady] = useState(false);

  const handleUnlinkContact = () => {
    function onSuccess() {
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Invite Unlinked',
        }),
      );
      history.push('/private/add/contacts');
    }
    dispatch(
      toggleModal({
        status: 'warning',
        title: `${labels.unlink}?`,
        customContent: (
          <DialogContentSubtitle
            texts={[
              'This contact will no longer',
              'be able to access and',
              'do any actions related',
              'to this company account',
            ]}
          />
        ),
        renderButton: (
          <Buttons
            leftButtonTitle={labels.no}
            rightButtonTitle={labels.yes}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              dispatch(unlinkContact({contactId: contactInfo.id}, onSuccess));
            }}
          />
        ),
      }),
    );
  };

  const handleSaveContact = () => {
    const contact = formRef.current.getValues();
    console.log('contact', contact);
    function onSuccess() {
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
        }),
      );
      history.goBack();
    }
    dispatch(
      saveContact(
        {
          contactId: contactInfo.id,
          name: contact.name,
          email: contact.email,
          businessName: contact.businessName,
        },
        onSuccess,
      ),
    );
  };

  const handleRevokeContact = () => {
    function onSuccess() {
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Invite Revoked',
        }),
      );
      history.push('/private/add/contacts');
    }
    dispatch(
      toggleModal({
        status: 'warning',
        title: `${labels.revoke}?`,
        customContent: (
          <DialogContentSubtitle
            texts={[
              'This invitaion will be cancelled',
              'and you will have to',
              'resend the invitation',
            ]}
          />
        ),
        renderButton: (
          <Buttons
            leftButtonTitle={labels.no}
            rightButtonTitle={labels.yes}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              // revoke
              dispatch(revokeContact({contactId: contactInfo.id}, onSuccess));
            }}
          />
        ),
      }),
    );
  };
  const handleRejectContact = () => {
    function onSuccess() {
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Rejected',
        }),
      );
      history.goBack();
    }
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Confirm Reject?',
        subtitle: labels.cannptUndone,
        renderButton: (
          <Buttons
            leftButtonTitle={labels.no}
            rightButtonTitle={labels.yes}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              // revoke
              dispatch(
                rejectInvitation({contactId: contactInfo.id}, onSuccess),
              );
            }}
          />
        ),
      }),
    );
  };

  const handleApproveContact = () => {
    function onSuccess() {
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Approved',
        }),
      );
      history.push('/private/add/contacts');
    }
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Confirm Approve?',
        customContent: (
          <DialogContentSubtitle
            texts={[
              'You are joining the',
              'requested company',
              'as their contact',
              '\n',
              `${labels.continue}?`,
            ]}
          />
        ),
        renderButton: (
          <Buttons
            leftButtonTitle={labels.no}
            rightButtonTitle={labels.yes}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              // revoke
              dispatch(
                approveInvitation({contactId: contactInfo.id}, onSuccess),
              );
            }}
          />
        ),
      }),
    );
  };

  const handleInviteAgain = useCallback(
    (contactInfo: ContactInfo) => {
      function onSuccess() {
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Invited',
            subtitle: 'Email invitation sent',
          }),
        );
        history.push('/private/add/contacts');
      }
      dispatch(
        inviteContactAgain(
          {type: 'contact', contactId: contactInfo.id},
          onSuccess,
        ),
      );
    },
    [dispatch, history],
  );

  const hasIncomingDuplicate =
    contactInfo &&
    contactInfo.status === 'pending' &&
    contactInfo.type === 'outgoing' &&
    !!contacts.find((c) => {
      return (
        c.status === 'active' &&
        c.type === 'incoming' &&
        c.requestorBusinessId === contactInfo.businessId
      );
    });

  const formInputs = useMemo(
    () => [
      {
        type: 'INPUT',
        name: 'name',
        title: labels.name,
        required: true,
        viewMode: type === 'view',
        value: contactInfo?.name,
      },
      {
        type: 'INPUT',
        name: 'businessName',
        title: labels.businessName,
        required: true,
        viewMode: type === 'view',
        value: contactInfo?.businessName,
      },
      {
        type: 'EMAIL',
        name: 'email',
        title: labels.emailAddress,
        required: true,
        viewMode: type === 'view' || contactInfo?.status === 'pending',
        value: contactInfo?.email,
      },
      {
        type: 'INPUT',
        name: 'invitedAt',
        title: labels.invitedOn,
        viewMode: true,
        value: moment(contactInfo.invitedAt).format('DD/MM/YYYY'),
      },
      {
        type: 'INPUT',
        name: 'status',
        title: labels.status,
        required: true,
        viewMode: true,
        value: getStatusDisplay(contactInfo),
        rightButton:
          !!contactInfo &&
          !hasIncomingDuplicate &&
          contactInfo?.status === 'pending' &&
          contactInfo?.type === 'outgoing' &&
          hasEditPermission ? (
            <MyButton
              onClick={() => handleInviteAgain(contactInfo)}
              variant={'outlined'}
              text={labels.inviteAgain}
              buttonClass={classes.smallButton}
            />
          ) : null,
      },
      {
        type: 'INPUT',
        name: 'joinedOn',
        title: labels.joinedOn,
        required: true,
        viewMode: true,
        value:
          contactInfo.status === 'active'
            ? moment(contactInfo.joinedOn).format('DD/MM/YYYY')
            : '-',
      },
    ],
    [
      type,
      contactInfo,
      labels,
      hasEditPermission,
      handleInviteAgain,
      classes.smallButton,
    ],
  );

  if (!contactInfo || (type === 'edit' && hasIncomingDuplicate)) {
    return <Redirect to="/private/add/contacts" />;
  }
  console.log('contactInfo', contactInfo);
  return (
    <PageHeader
      config={{
        title: labels[`${type}Contact`],
        margin: 30,
        back: true,
      }}>
      <Grid container spacing={2}>
        <FormGenerator
          ref={formRef}
          formInputs={formInputs}
          onReady={setFormReady}
          language={
            businessProfile.businessCountry === 'Argentina' ? 'es' : 'en'
          }
        />
        {/* Consignments */}
        {contactInfo.viewAllConsignments && contactInfo.status === 'active' && (
          <Typography variant="h2" className={classes.sectionTitle}>
            Consignments
          </Typography>
        )}

        {/* Buttons */}
        <Grid
          item
          container
          justifyContent="flex-end"
          className={classes.buttonGroup}>
          {type === 'view' && hasEditPermission && (
            <>
              {contactInfo.type === 'incoming' &&
                contactInfo.status === 'pending' && (
                  <>
                    <MyButton
                      text={'Reject'}
                      variant="outlined"
                      buttonClass={classes.greenButton}
                      onClick={handleRejectContact}
                    />
                    <MyButton
                      text={'Approve'}
                      variant="contained"
                      onClick={handleApproveContact}
                    />
                  </>
                )}
              {contactInfo.status === 'active' && (
                <MyButton
                  text={labels.unlink}
                  variant="outlined"
                  buttonClass={classes.greenButton}
                  onClick={handleUnlinkContact}
                />
              )}
              {(contactInfo.type === 'outgoing' ||
                contactInfo.status === 'active') &&
                !hasIncomingDuplicate && (
                  <MyButton
                    text="Edit"
                    variant="contained"
                    onClick={() => {
                      history.push({
                        pathname: `/private/add/contacts/edit/${id}`,
                      });
                    }}
                  />
                )}
            </>
          )}
          {type === 'edit' && hasEditPermission && (
            <>
              {contactInfo.status === 'active' && (
                <MyButton
                  text={labels.unlink}
                  variant="outlined"
                  buttonClass={classes.greenButton}
                  onClick={handleUnlinkContact}
                />
              )}
              {contactInfo.status === 'pending' &&
                contactInfo.type === 'outgoing' && (
                  <MyButton
                    text={'Revoke'}
                    variant="outlined"
                    buttonClass={classes.greenButton}
                    onClick={handleRevokeContact}
                  />
                )}
              <MyButton
                text={labels.save}
                variant="contained"
                disabled={!formReady}
                onClick={handleSaveContact}
              />
            </>
          )}
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default ContactDetails;
