import { TokenService } from "@aglive/data-model";
import { fetchBrands } from "../../store/brand/actions";
import { WebError } from '../../utils/error';

export const BRAND_LIBRARY_PATH = '/private/profile/brand';

export const fetchBrandToken = async (
  dispatch: any,
  id: string
) => {
  const brandTokens = await fetchBrands(dispatch);
  let targetBrand: TokenService.BrandToken;
  if (brandTokens) {
    targetBrand = findTargetBrand(brandTokens, id);
  }

  if (!targetBrand) throw new WebError('INVALID_ERROR');

  return targetBrand;
}

export const getBrandAgliveTokenId = (token : TokenService.BrandToken) => 
  token && token.externalIds.find(id => 'agliveToken' in id)?.agliveToken;

export const findTargetBrand = (
  brandList: Array<TokenService.BrandToken>,
  agliveTokenId: string
) => brandList.find(brand => getBrandAgliveTokenId(brand) === agliveTokenId);

export const findTargetBrandIndex = (
  brandList: Array<TokenService.BrandToken>,
  agliveTokenId: string
) => brandList.findIndex(brand => getBrandAgliveTokenId(brand) === agliveTokenId);
