import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useReducer,
  memo,
  useImperativeHandle,
  forwardRef,
  PropsWithChildren,
  ForwardRefRenderFunction,
} from 'react';
import moment from 'moment';
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Polygon,
  DrawingManager,
  Circle,
} from '@react-google-maps/api';
import COLOR from '../styled/colors';
import {Grid, Box, makeStyles, Typography} from '@material-ui/core';
import {Libraries} from '@react-google-maps/api/dist/utils/make-load-script-url';
import {AnimalMarker, OptiweighMarker, MarkerType} from './MapMarkers';

const libraries: Libraries = ['drawing'];

export type Weight = {
  info: {
    rfid: string;
    weight: number;
    unit: string;
    weightDate: string;
    avgDailyGain?: number;
  };
};

export type Coords = {lat: number; lng: number};
export interface MapRefObj {
  drawingButton: () => void;
}

type Props = {
  locationGeofences: Coords[][];
  polygon?: Array<Array<Coords>>;
  markers?: Array<MarkerType>;
  weights?: Array<Weight>;
  scaleLocation?: google.maps.LatLngLiteral;
  sidebar?: boolean;
  style?: {[attr: string]: string | number};
  center?: Coords;
  zoom?: number;
  showPolygon?: boolean;
  editPolygon?: boolean;
  polygonEdit?: boolean;
  polygonBounds?: {
    sw: Coords;
    ne: Coords;
  };
  polylineOptions?: {
    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
    fillColor?: string;
    fillOpacity?: number;
    clickable?: boolean;
    draggable?: boolean;
    editable?: boolean;
    visible?: boolean;
    radius?: number;
    zIndex?: number;
    z;
  };
  onPolygonCompleteCallback?: (polygonPaths: Array<Coords>) => void;
  handleZoomChanged?: (zoomValue: number) => void;
  handleCenterChanged?: (center: Coords) => void;
  loadLastCenter?: () => void;
};

const useStyle = makeStyles((theme) => ({
  mapHeights: {
    minHeight: 600,
    height: '70vh',
  },
  sidebarContainer: {
    position: 'relative',
  },
  sidebar: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: COLOR.GRAY_SOLID,
  },
  noSidebar: {
    height: 0,
    overflowY: 'auto',
    backgroundColor: COLOR.GRAY_SOLID,
    flexBasis: 0
  },
  sidebarItem: {
    boxShadow: 'rgba(255,255,255,0.5) 0 1px inset',
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    padding: 15,
  },
  pointer: {
    cursor: 'pointer',
  },
  sidebarHighlight: {
    backgroundColor: COLOR.GREEN_LIGHT,
    borderBottom: `1px solid ${COLOR.GREEN_BUTTON}`,
  },
  weightInfoBar: {
    position: 'absolute',
    width: 180,
    left: 0,
    top: 0,
    height: '100%',
    overflowY: 'auto',
    backgroundColor: COLOR.GRAY_SOLID,
  },
  hidden: {visibility: 'hidden'},
  remove: {display: 'none'},
}));

type MapState = {
  activeMarker: number;
  clickedHistory: boolean;
  historyIndex: number;
  activeHistory: any[];
  map: google.maps.Map | null;
  newDrawingManager: google.maps.drawing.DrawingManager | null;
  showWeightInfo: boolean;
  showWeightSidebar: boolean;
  query: string;
};

const initialState: MapState = {
  activeMarker: -1,
  clickedHistory: false,
  historyIndex: -1,
  activeHistory: [],
  map: null,
  newDrawingManager: null,
  showWeightInfo: false,
  showWeightSidebar: false,
  query: '',
};

type Action =
  | {type: 'click/animalMarker'; index: number}
  | {
      type: 'click/history';
      activeHistory: any[];
      clickedHistory: boolean;
      historyIndex: number;
    }
  | {type: 'clear/polygon'}
  | {type: 'click/optiweighScale'}
  | {
      type: 'load/drawingManager';
      drawingManager: google.maps.drawing.DrawingManager;
    }
  | {type: 'load/map'; map: google.maps.Map | null}
  | {
      type: 'initial/marker';
      activeHistory: any[];
      clickedHistory: boolean;
      historyIndex: number;
      activeMarker: number;
    }
  | {type: 'close/optiweighPopup'}
  | {type: 'toggle/weightSidebar'}
  | {type: 'search/identifier'; query: string};

const reducer = (prevState: MapState, action: Action): MapState => {
  switch (action.type) {
    case 'click/animalMarker':
      return {
        ...prevState,
        activeMarker: action.index,
        showWeightInfo: false,
        showWeightSidebar: false,
      };
    case 'load/drawingManager':
      return {...prevState, newDrawingManager: action.drawingManager};

    case 'load/map':
      return {...prevState, map: action.map};
    case 'initial/marker':
      let {activeHistory, clickedHistory, historyIndex, activeMarker} = action;
      return {
        ...prevState,
        activeHistory,
        clickedHistory,
        historyIndex,
        activeMarker,
      };
    case 'close/optiweighPopup':
      return {...prevState, showWeightInfo: false, showWeightSidebar: false};
    case 'toggle/weightSidebar':
      return {...prevState, showWeightSidebar: !prevState.showWeightSidebar};
    case 'search/identifier':
      return {...prevState, query: action.query};

    case 'click/history':
      return {
        ...prevState,
        activeHistory: action.activeHistory,
        clickedHistory: action.clickedHistory,
        historyIndex: action.historyIndex,
      };

    case 'click/optiweighScale':
      return {
        ...prevState,
        showWeightInfo: !prevState.showWeightInfo,
        activeHistory: [],
        clickedHistory: false,
        historyIndex: -1,
        activeMarker: -1,
      };
  }
};
const MapComponent: ForwardRefRenderFunction<
  MapRefObj,
  PropsWithChildren<Props>
> = (props, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const classes = useStyle();
  //only refit the map when map change
  useEffect(() => {
    if (state.map === null) return;
    if (props.polygonBounds === undefined) return;
    const bounds = new window.google.maps.LatLngBounds(
      props.polygonBounds.sw, //{lat: 6.345, lng: 99.223},
      props.polygonBounds.ne, //{lat: 4.345, lng: 101.223},
    );
    state.map.fitBounds(bounds);
  }, [state.map, props.polygonBounds]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null as google.maps.Polygon | null);
  const listenersRef = useRef([] as google.maps.MapsEventListener[]);
  const sidebarRef = useRef(null as HTMLDivElement | null);
  const weightInfoRef = useRef(null as HTMLDivElement | null);

  const createCattleMarkerOnClick = useCallback(
    (index: number, scrollView?: boolean) => () => {
      dispatch({type: 'click/animalMarker', index});
      if (props.sidebar && scrollView) {
        sidebarRef?.current?.children[index].scrollIntoView();
      }
    },
    [props.sidebar],
  );

  const polygonOptions = {
    fillColor: COLOR.BLUE_POLYGON,
    fillOpacity: 0.5,
    strokeColor: COLOR.BLUE_BORDER,
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: true,
    draggable: props.polygonEdit,
    editable: props.polygonEdit,
    geodesic: false,
    zIndex: 1,
  };

  const circleOptions = {
    strokeWeight: 0,
    fillColor: COLOR.BLUE_POLYGON,
    fillOpacity: 0.5,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  const drawingManagerOptions: google.maps.drawing.DrawingManagerOptions = {
    drawingControl: false,
    polygonOptions: {
      strokeColor: COLOR.BLUE_BORDER,
      strokeOpacity: 1,
      strokeWeight: 3,
      clickable: true,
      draggable: true,
      editable: true,
    },
  };

  const polylineOptions = {
    strokeColor: COLOR.RED_WARNING,
    strokeOpacity: 0,
    strokeWeight: 2,
    fillColor: COLOR.RED,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const dottedSymbol = [
    {
      icon: {
        path: 'M 0, 0 0, 0.3',
        strokeOpacity: 1,
        scale: 2,
      },
      offset: '0',
      repeat: '5px',
    },
  ];
  const [map, setMap] = useState<google.maps.Map>(null);

  // load googlemaps script
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB8-3HeNvEcLNgR9twLsh5VMrS65d9OfKE',
    libraries: libraries,
  });

  // disable drawing mode, set options for drawingManager
  const onLoadDrawingManager = (
    drawingManager: google.maps.drawing.DrawingManager,
  ) => {
    drawingManager.setDrawingMode(null);
    drawingManager.setOptions(drawingManagerOptions);
    dispatch({type: 'load/drawingManager', drawingManager});
  };

  // get polygon path when complete drawing and replace it by <Polygon>
  const onPolygonComplete = useCallback(
    (polygon: google.maps.Polygon) => {
      const path = polygon.getPath().getArray();
      const polygonPath = path.map((p) => ({lat: p.lat(), lng: p.lng()}));

      polygon.getPath().clear();

      props.onPolygonCompleteCallback &&
        props.onPolygonCompleteCallback(polygonPath);
    },
    [props],
  );

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return {lat: latLng.lat(), lng: latLng.lng()};
        });
      props.onPolygonCompleteCallback &&
        props.onPolygonCompleteCallback(nextPath);
    }
  }, [props]);

  // Bind refs to current Polygon and listeners
  const onLoadPolygon = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      if (path) {
        listenersRef.current.push(
          path.addListener('set_at', onEdit),
          path.addListener('insert_at', onEdit),
          path.addListener('remove_at', onEdit),
        );
      }
    },
    [onEdit],
  );

  // Clean up refs of polygon
  const onUnmountPolygon = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const toggleOptiweighScale = useCallback(() => {
    dispatch({type: 'click/optiweighScale'});
  }, []);

  const closeOptiweighPopup = useCallback(() => {
    dispatch({type: 'close/optiweighPopup'});
  }, []);

  const toggleWeightSidebar = useCallback(() => {
    dispatch({type: 'toggle/weightSidebar'});
  }, []);

  const closeAnimalWindow = useCallback(() => {
    dispatch({type: 'click/animalMarker', index: -1});
  }, []);

  const searchWeight = useCallback((query: string) => {
    dispatch({type: 'search/identifier', query});
  }, []);

  const showAnimalHistory = useCallback(
    (marker, index) => () => {
      dispatch({
        type: 'click/history',
        activeHistory:
          state.activeHistory.length > 0 ? [] : marker.info.history ?? [],
        clickedHistory: state.activeHistory.length > 0 ? false : true,
        historyIndex: state.activeHistory.length > 0 ? -1 : index,
      });
    },
    [state.activeHistory.length],
  );

  const filterWeights = useCallback(
    (weights) => {
      if (!weights) return [];
      return weights
        .filter((item) => !state.query || item.info.rfid.includes(state.query))
        .slice(0, 50);
    },
    [state.query],
  );

  useEffect(() => {
    if (
      state.newDrawingManager !== null &&
      (props.polygonEdit || props.locationGeofences.length > 0)
    ) {
      state.newDrawingManager.setDrawingMode(null);
    }
  }, [
    state.newDrawingManager,
    props.polygonEdit,
    props.locationGeofences.length,
  ]);

  useEffect(() => {
    dispatch({
      type: 'initial/marker',
      activeHistory: [],
      clickedHistory: false,
      historyIndex: -1,
      activeMarker: -1,
    });
  }, [props.markers]);

  useImperativeHandle(ref, () => ({
    drawingButton() {
      state.newDrawingManager?.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON,
      );
      state.newDrawingManager?.setOptions(drawingManagerOptions);
    },
    stopDrawing() {
      state.newDrawingManager?.setDrawingMode(null);
    },
  }));

  if (!isLoaded) return <></>;

  return (
    <Grid container className={classes.mapHeights}>
      <Grid
        item
        xs={
          props.sidebar && props.markers && props.markers.length > 0 ? 9 : 12
        }>
        <GoogleMap
          mapContainerStyle={{width: '100%', height: '100%', ...props.style}}
          id={'map'}
          center={props.center}
          zoom={props.zoom}
          // onLoad={(map) => dispatch({type: 'load/map', map})}
          onLoad={(map) => {
            setMap(map);
            dispatch({type: 'load/map', map});
          }}
          onUnmount={(_) => dispatch({type: 'load/map', map: null})}
          options={{streetViewControl: false, mapTypeId: 'satellite'}}
          onZoomChanged={() => {
            props.handleZoomChanged(map?.getZoom());
          }}
          onCenterChanged={() => {
            props.handleCenterChanged({lat: map?.getCenter()?.lat(), lng: map?.getCenter()?.lng()})
          }}
        >
          {props.weights?.length && props.scaleLocation && (
            <OptiweighMarker
              clickOptiweighScale={toggleOptiweighScale}
              closeOptiweighPopup={closeOptiweighPopup}
              location={props.scaleLocation}
              search={searchWeight}
              query={state.query}
              showInfo={state.showWeightInfo}
              showSidebar={state.showWeightSidebar}
              toggleWeightSidebar={toggleWeightSidebar}
              weightsNum={props.weights.length}
            />
          )}

          {props.markers &&
            props.markers.map((marker, index) => {
              return (
                <AnimalMarker
                  key={index}
                  marker={marker}
                  index={index}
                  activeMarker={state.activeMarker}
                  clickedHistory={state.clickedHistory}
                  historyIndex={state.historyIndex}
                  infoWindowOnCloseClick={closeAnimalWindow}
                  markerOnClick={createCattleMarkerOnClick(index, true)}
                  historyOnClick={showAnimalHistory(marker, index)}
                  historyText={`${
                    state.activeHistory.length > 0 ? 'Hide' : 'Track'
                  } History`}
                />
              );
            })}
          {props.markers &&
            state.activeMarker > -1 &&
            props.markers[state.activeMarker] && (
              <Circle
                center={{
                  lat: props.markers[state.activeMarker].lat,
                  lng: props.markers[state.activeMarker].lng,
                }}
                options={circleOptions}
                radius={props.markers[state.activeMarker].info.circleRadius}
              />
            )}
          {state.activeHistory.length && props.markers && (
            <Polyline
              path={state.activeHistory}
              options={{
                ...polylineOptions,
                ...props.polylineOptions,
                icons: dottedSymbol,
              }}
            />
          )}
          {(props.showPolygon || props.editPolygon) && (
            <Polygon
              onLoad={onLoadPolygon}
              paths={props.locationGeofences}
              options={polygonOptions}
              onUnmount={onUnmountPolygon}
              // Event used when manipulating and adding points
              onMouseUp={onEdit}
              // Event used when dragging the whole Polygon
              onDragEnd={onEdit}
            />
          )}
          <DrawingManager
            onLoad={onLoadDrawingManager}
            onPolygonComplete={onPolygonComplete}
          />
        </GoogleMap>
      </Grid>
      {props.sidebar && (
        <Grid
          item
          xs={3}
          ref={sidebarRef}
          className={
            props.markers.length === 0 ? classes.noSidebar : classes.sidebar
          }>
          {props.markers?.map((marker, index) => (
            <Box
              key={`AlertItem-${index}`}
              className={`${classes.sidebarItem} ${classes.pointer} ${
                state.activeMarker === index ? classes.sidebarHighlight : ''
              }`}
              onClick={createCattleMarkerOnClick(index)}>
              <Typography variant="h6" id={`AlertId-${marker.info.esn}`}>
                {marker.info.rfid ? (
                  <>
                    {marker.info.rfid}
                    <br />({marker.info.esn})
                  </>
                ) : (
                  <>{marker.info.esn}</>
                )}
              </Typography>
              <Typography variant="subtitle2" color="error" id={`Alert-${marker.info.esn}`}>
                {marker.info.errors?.join(', ')}
              </Typography>
              <Typography variant="subtitle1">
                {moment(marker.info.date).format('MMMM Do yyyy, h:mm:ss a')}
              </Typography>
            </Box>
          ))}
        </Grid>
      )}
      <Grid
        item
        xs={3}
        id='weightSidebar'
        ref={weightInfoRef}
        className={`${classes.weightInfoBar} ${
          state.showWeightSidebar ? '' : classes.hidden
        }`}>
        {filterWeights(props.weights).map((weight, index) => (
          <Box
            key={`InfoItem-${index}`}
id={`weightrfid-${weight.info.rfid.replace(/\s/g, '-')}`}
            className={`${classes.sidebarItem} ${
              index >= 50 ? classes.remove : ''
            }`}>
            <Typography variant="h6">{weight.info.rfid}</Typography>
            <Typography variant="body2">
              {`Weight:  ${weight.info.weight} ${weight.info.unit} (${
                moment(weight.info.weightDate, 'YYYY-MM-DD').isValid()
                  ? moment(weight.info.weightDate).format('MMMM Do YYYY')
                  : weight.info.weightDate
              })`}
            </Typography>
            {!isNaN(weight.info.avgDailyGain ?? NaN) && (
              <Typography
                variant="caption"
                style={{
                  fontWeight: 'bold',
                }}>{`Avg daily gain:  ${weight.info.avgDailyGain} ${weight.info.unit}`}</Typography>
            )}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default memo(forwardRef(MapComponent));
