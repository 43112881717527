import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {withStyles, makeStyles, Typography} from '@material-ui/core';
import {useAppSelector} from '../../utils/hooks';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import COLOR from '../../styled/colors';
import Collapse from '@material-ui/core/Collapse';
import {Checkbox} from '@material-ui/core';

const useStyles = makeStyles((_) => ({
  root: {
    borderBottom: 'none',
  },
  table: {
    minWidth: 700,
    borderBottom: 'none',
  },
  textBlod: {
    fontWeight: 'bold',
  },
}));
const checkBoxStyles = (theme:any) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary[100],
    },
  },
  checked: {},
});

function formatGroupItem(item: Record<string, any>): string {
  const index = Object.values(item).findIndex(x => x !== '');
  if (index < 0) {
    return '';
  }
  const key = Object.keys(item)[index];
  const val = Object.values(item)[index];
  return `${key}: ${val}`;
}

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const Row = ({item, checkedHandle, allSelected, id}:{item:any, checkedHandle:(a:boolean)=>void,allSelected:boolean, id:number}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const user = useAppSelector(
    state => state.user.userProfileData.name,
  );
  const [cellChecked, setCellChecked] = useState(item.checked);
  const handleChecked = () => {
    checkedHandle(!item.checked);
    setCellChecked(!cellChecked);
  };
  useEffect(() => {
    checkedHandle(allSelected);
    setCellChecked(allSelected);
  }, [allSelected]);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {moment(item.activity.createdAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell component="th" scope="row">
          <Button onClick={() => setOpen(!open)} id={`Name${id}`}>
            <span
              style={{color: COLOR.GREENT_TEXT, textTransform: 'capitalize'}}>
              <u>{item.name ? item.name : 'Items'}</u>
            </span>
          </Button>
        </TableCell>
        <TableCell align="left">{user}</TableCell>
        <TableCell align="left">
          {item.activity.details.origin.site
            ? item.activity.details.origin.location +
              '  ' +
              item.activity.details.origin.site
            : item.activity.details.origin.location}
        </TableCell>
        <TableCell align="left">
          {item.activity.details.destination.location +
            '  ' +
            item.activity.details.destination.site}
        </TableCell>

        <TableCell align="left">
          <CustomCheckbox
            checked={cellChecked}
            onChange={handleChecked}
            id={`Checkbox${id}`}
            // name={nestedKey}
          />
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{borderBottom: 'none'}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className={classes.textBlod}>
                {item.items?.length > 1 ? item.items.length : 1}{' '}
                {item.items?.length > 1 ? 'Items:' : 'Item:'}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell align="left" className={classes.textBlod}>
                      NO.
                    </TableCell>
                    <TableCell align="left" className={classes.textBlod}>
                      Item ID
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.items &&
                    item.items.map((item:any, index:number) => (
                      <TableRow key={index}>
                        <TableCell style={{borderBottom: 'none'}} scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell style={{borderBottom: 'none'}}>
                          {formatGroupItem(item)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!item.items && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {'1'}
                      </TableCell>
                      <TableCell>{item.externalId}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
