export interface ManageUserType {
  name: string;
  role: string;
  email: string;
  phone: string;
  invitationDate?: Date;
  addedDate?: Date;
  deactivatedDate?: Date;
  userId?: string;
  invitationCode?: string;
}
export interface ConsigneeTypes {
  receiverName: string;
  receiverEmail: string;
  receiverPhone: string;
  receiverAddress: string;
  receiverPostCode: string;
  receiverTown: string;
  receiverState: string;
  receiverPIC: string;
  receiverCountry: string;
  agliveToken?: string;
  createdAt?: Date;
}
export interface ConsignmentTypes {
  name: string;
  forms: string[];
  formsCount: string;
  status: string[];
  consignee: { id: string; pic: string };
  destination: {
    pic: string;
    postcode?: string;
    town?: string;
    state?: string;
    address?: string;
  };
  productDesc?: string;
  description?: string;
  eNVDS3Link?: string;
  externalIds?: [{ [key: string]: any }];
  answers: { [key: string]: any }[];
  attachments: string[];
  shareTo: { mobile: string[]; email: string[] };
  animalSelectionMode: string;
  assets: { rfid: string; nlis?: string; birthYear?: string; mob?: string }[];
  declaration: { userId: string };
  createdAt: Date;
  csvFile?: { link: string; name: string };
  mob?: { id: string; name: string };
  agliveToken?: string;
  template?: { name?: string; type?: string }[];
}

export interface addState {
  readonly users: ManageUserType[];
  readonly consigneeData: ConsigneeTypes[];
  readonly consignmentData: ConsignmentTypes[];
  readonly cerestagData: { totalRow: number; cerestag: CerestagType[] };
}

export type CerestagType = {
  agliveToken: string;
  picId: string;
  uniqueId: string;
  visualTag?: string;
  dateOfBirth?: string;
  vid?: string;
};

export const CREATE_USER = 'CREATE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const EDIT_CONSIGNEE_DATA = 'EDIT_CONSIGNEE_DATA';
export const DELETE_CONSIGNEE_DATA = 'DELETE_CONSIGNEE_DATA';
export const POST_CONSIGNEE_DATA = 'POST_CONSIGNEE_DATA';
export const FETCH_CONSIGNEE_DATA = 'FETCH_CONSIGNEE_DATA';
export const DELETE_CONSIGNMENT_DATA = 'DELETE_CONSIGNMENT_DATA';
export const FETCH_CONSIGNMENT_DATA = 'FETCH_CONSIGNMENT_DATA';
export const FETCH_CERESTAG_DATA = 'FETCH_CERESTAG_DATA';
export const ADD_CERESTAG = 'ADD_CERESTAG';
export const REPLACE_CERESTAG = 'REPLACE_CERESTAG';
export const REMOVE_CERESTAG = 'REMOVE_CERESTAG';

interface createUser {
  type: typeof CREATE_USER;
  payload: ManageUserType;
}
interface featchUsers {
  type: typeof FETCH_USERS;
  payload: Array<ManageUserType>;
}
interface postConsigneeDataType {
  type: typeof POST_CONSIGNEE_DATA;
  payload: ConsigneeTypes;
}

interface fetchConsigneeDataType {
  type: typeof FETCH_CONSIGNEE_DATA;
  payload: ConsigneeTypes;
}

interface editConsigneeDataType {
  type: typeof EDIT_CONSIGNEE_DATA;
  payload: { index: number; consigneeData: ConsigneeTypes };
}
interface deleteConsigneeDataType {
  type: typeof DELETE_CONSIGNEE_DATA;
  payload: number;
}
interface deleteConsignmentType {
  type: typeof DELETE_CONSIGNMENT_DATA;
  payload: number;
}
interface fetchConsignmentDataType {
  type: typeof FETCH_CONSIGNMENT_DATA;
  payload: ConsignmentTypes;
}

interface fetchCeresTagsDataType {
  type: typeof FETCH_CERESTAG_DATA;
  payload: { totalRow: number; cerestag: Array<CerestagType> };
}

interface addCeresTagType {
  type: typeof ADD_CERESTAG;
  payload: {
    index: number;
    tagVid: string;
  };
}

interface replaceCeresTagType {
  type: typeof REPLACE_CERESTAG;
  payload: {
    index: number;
    tagVid: string;
  };
}

interface removeCeresTagType {
  type: typeof REMOVE_CERESTAG;
  payload: {
    index: number;
  };
}

export type addTypes = Array<
  | featchUsers
  | createUser
  | postConsigneeDataType
  | fetchConsigneeDataType
  | editConsigneeDataType
  | deleteConsigneeDataType
  | fetchConsignmentDataType
  | deleteConsignmentType
  | fetchCeresTagsDataType
  | addCeresTagType
  | replaceCeresTagType
  | removeCeresTagType
>;
