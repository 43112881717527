import React, {useRef, useState} from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import FieldHeading from '../../../presentation/FieldHeading';
import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import MyButton from '../../../presentation/button';
import {fileUploader} from '../../../store/product/actions';
import {useAppSelector} from '../../../utils/hooks';
import {RecursivePartial} from '../../../utils/type';
import RichEditor from 'src/presentation/RichEditor';

type MediaType =
  TokenService.ProductToken['details']['story']['additional'][number]['mediaType'];
interface AdditionalSectionProps {
  story: TokenService.ProductToken['details']['story'];
  validationState: RecursivePartial<
    TokenService.ProductToken['details']['story']
  >;
  onAddAdditionalEntry: () => void;
  onDeleteAdditionalEntry: (index: number) => void;
  onDupAdditionalEntry: (index: number) => void;
  onAdditionalTitleChange: (index: number, value: string) => void;
  onAdditionalContentChange: (index: number, value: string) => void;
  onAdditionalMediaTypeChange: (index: number, value: MediaType) => void;
  onAdditionalUrlChange: (index: number, value: string) => void;
}

type UploaderOption = {
  accept?: string;
  alert: string;
};

const FileUploadConfigs: {[key: string]: UploaderOption} = {
  image: {
    accept: 'image/jpg, image/jpeg, image/png',
    alert: 'Upload image size more than 10mb !',
  },
  file: {
    alert: 'Upload file size more than 10mb !',
  },
};

const AdditionalSection: React.FC<AdditionalSectionProps> = ({
  story,
  validationState,
  onAddAdditionalEntry,
  onDeleteAdditionalEntry,
  onDupAdditionalEntry,
  onAdditionalTitleChange,
  onAdditionalContentChange,
  onAdditionalMediaTypeChange,
  onAdditionalUrlChange,
}) => {
  const classes = useStyles();
  const userId = useAppSelector((state) => state.auth.wallet);

  const additionalFileRef = useRef(null);
  const [targetFileIndex, setTargetFileIndex] = useState(0);

  const handleUploadClick = (targetIndex: number) => {
    setTargetFileIndex(targetIndex);
    additionalFileRef.current?.click();
  };
  const uploadOption =
    FileUploadConfigs[story.additional[targetFileIndex]?.mediaType];

  return (
    <Box p={3} className={classes.tabContainer}>
      <Grid container direction="column">
        {story.additional.map((additional, index) => (
          <React.Fragment key={index}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <FieldHeading
                  title={`Section ${index + 1}`}
                  subtitle="Add extra detail to your story"
                />
              </Grid>
              <Grid item>
                <DeleteIcon
                  style={{paddingRight: 24, cursor: 'pointer'}}
                  onClick={() => onDeleteAdditionalEntry(index)}
                  id={`DeleteAdditional${index}`}
                />
                <FileCopyIcon
                  style={{paddingRight: 16, cursor: 'pointer'}}
                  onClick={() => onDupAdditionalEntry(index)}
                  id={`CopyAdditional${index}`}
                />
              </Grid>
            </Grid>

            <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
              <Grid item container direction="column">
                <TextFieldWithLimit
                  id={`AdditionalHeading${index}`}
                  title="Heading Title"
                  content={additional.title}
                  limit={30}
                  style={classes.inputField}
                  placeholder="Heading"
                  onChange={(value: string) => {
                    onAdditionalTitleChange(index, value);
                  }}
                />
                {/* <TextFieldWithLimit
                  title="Content"
                  content={additional.content}
                  limit={600}
                  style={classes.inputField}
                  placeholder="Add content here"
                  onChange={(value: string) => {
                    onAdditionalContentChange(index, value);
                  }}
                  multiline={true}
                  rows={10}
                /> */}
                <RichEditor
                  id={`AdditionalContent${index}`}
                  title="Content"
                  value={additional.content}
                  onChange={(value: string) => {
                    onAdditionalContentChange(index, value);
                  }}
                  placeholder={'Add content here'}
                  characterLimit={600}
                />
                <Typography
                  variant="h6"
                  style={{fontWeight: 700, marginBottom: '10px'}}>
                  Image, File or Video Link
                </Typography>
                <RadioGroup
                  aria-label="mediaType"
                  name="mediaType"
                  value={story.additional[index].mediaType || ''}
                  onChange={(e) => {
                    onAdditionalMediaTypeChange(
                      index,
                      e.target.value as 'video' | 'image',
                    );
                    onAdditionalUrlChange(index, null);
                    additionalFileRef.current.value = '';
                  }}>
                  <FormControlLabel
                    value="image"
                    control={<Radio color="primary" id={`AdditionalImage-Radio${index}`} />}
                    label="Upload Image"
                  />
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={3}
                    style={{
                      paddingLeft: '35px',
                      marginBottom: '5px',
                    }}>
                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={
                          additional.url && additional.mediaType === 'image'
                            ? decodeURIComponent(
                                additional.url.split('/').reverse()[0],
                              )
                            : ''
                        }
                        placeholder={'png, jpg, or jpeg'}
                        disabled
                        error={
                          !!validationState.additional[index]?.url &&
                          additional.mediaType === 'image'
                        }
                        helperText={
                          additional.mediaType === 'image' &&
                          validationState.additional[index]?.url
                        }
                        FormHelperTextProps={{
                          className: classes.errorHelperText,
                        }}
                        id={`AdditionalImage-TextField${index}`}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <MyButton
                        text={'Upload File'}
                        variant="outlined"
                        onClick={() => {
                          handleUploadClick(index);
                        }}
                        buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                        disabled={additional.mediaType !== 'image'}
                        id={`AdditionalImageBtn${index}`}
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    value="file"
                    control={<Radio color="primary" />}
                    label="Upload File"
                  />
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={3}
                    style={{
                      paddingLeft: '35px',
                      marginBottom: '5px',
                    }}>
                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={
                          additional.url &&
                          additional.mediaType === ('file' as any)
                            ? decodeURIComponent(
                                additional.url.split('/').reverse()[0],
                              )
                            : ''
                        }
                        placeholder={'Image or document file'}
                        disabled
                        error={
                          !!validationState.additional[index]?.url &&
                          additional.mediaType === ('file' as any)
                        }
                        helperText={
                          additional.mediaType === ('file' as any) &&
                          validationState.additional[index]?.url
                        }
                        FormHelperTextProps={{
                          className: classes.errorHelperText,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <MyButton
                        text={'Upload File'}
                        variant="outlined"
                        onClick={() => {
                          handleUploadClick(index);
                        }}
                        buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                        disabled={additional.mediaType !== ('file' as any)}
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    value="video"
                    control={<Radio color="primary" id={`AdditionalVideo-Radio${index}`} />}
                    label="Video URL"
                  />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                    style={{
                      paddingLeft: '35px',
                      marginBottom: '5px',
                    }}>
                    <Grid item xs={12}>
                      <TextField
                        id={`AdditionalVideoURL${index}`}
                        fullWidth
                        variant="outlined"
                        value={
                          additional.mediaType === 'video' ? additional.url : ''
                        }
                        placeholder={'Enter URL'}
                        className={classes.inputField}
                        disabled={additional.mediaType !== 'video'}
                        error={
                          !!validationState.additional[index]?.url &&
                          additional.mediaType === 'video'
                        }
                        helperText={
                          additional.mediaType === 'video' &&
                          validationState.additional[index]?.url
                        }
                        FormHelperTextProps={{
                          className: classes.errorHelperText,
                        }}
                        onChange={(e) => {
                          onAdditionalUrlChange(index, e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    value=""
                    control={<Radio color="primary" id={`AdditionalNoMedia-Radio${index}`} />}
                    label="No Image nor Video"
                  />
                </RadioGroup>
              </Grid>
            </Box>
          </React.Fragment>
        ))}
        <Grid item style={{alignSelf: 'flex-end'}}>
          <MyButton
            id="AddAdditionalBtn"
            onClick={onAddAdditionalEntry}
            variant="outlined"
            text="Add"
            width={250}
            buttonClass={classes.contentButton}
          />
        </Grid>
        <input
          type="file"
          accept={uploadOption?.accept}
          onChange={(e) => {
            uploadFileHandler({
              event: e,
              onValueChange: onAdditionalUrlChange,
              userId,
              targetIndex: targetFileIndex,
              alertMessage: uploadOption?.alert,
            });
          }}
          ref={additionalFileRef}
          style={{display: 'none'}}
          data-cy="AdditionalSectionImage"
        />
      </Grid>
    </Box>
  );
};

type UploadFileHandlerProps = {
  event: React.ChangeEvent<HTMLInputElement>;
  onValueChange: (index: number, value: string) => void;
  alertMessage?: string;
  userId: string;
  targetIndex: number;
};

// Handle image uploads
async function uploadFileHandler({
  event,
  onValueChange,
  alertMessage = 'Upload image size more than 10mb !',
  userId,
  targetIndex,
}: UploadFileHandlerProps) {
  const files = event.target.files;
  if (files && files.length > 0) {
    if (files[0].size > 10485760) {
      window.alert(alertMessage);
    } else {
      const response = await fileUploader(userId, files[0]);
      const responseData = response[0].data;
      if (typeof responseData == 'string') {
        onValueChange(targetIndex, responseData);
      }
    }
  }
}

export default AdditionalSection;
