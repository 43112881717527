import {CASL} from '@aglive/frontend-core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import MyButton from '../button';
import COLOR from '../../styled/colors';
import Qrcode from 'qrcode.react';
import React, {useState} from 'react';
import {CSSProperties} from '@material-ui/core/styles/withStyles';

import LeftIcon from '@material-ui/icons/ChevronLeft';
import RightIcon from '@material-ui/icons/ChevronRight';
import { PrintContent } from './PrintTemplate';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    margin: '10px 0px',
  },
  contentTitle: {
    fontWeight: 600,
  },
  smallText: {
    fontSize: '0.85rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
    gap: theme.spacing(4),
  },
  dialogButton: {
    width: 200,
  },
  greenButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    '&:hover': {
      color: COLOR.GREEN_BUTTON,
      borderColor: COLOR.GREEN_BUTTON,
    },
  },
  qrPreviewTitle: {
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: '1.05rem',
  },
  qrContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(4),
    borderColor: COLOR.GRAY_BUTTON,
  },
  qrViewContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderColor: 'transparent',
  },
  qrNavigateContainer: {
    padding: theme.spacing(0.2),
    backgroundColor: COLOR.GRAY_BUTTON,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR.GRAY_BUTTON,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  qrNavigateText: {
    fontWeight: 600,
  },
  dropdownContainer: {
    minWidth: 200,
  },
}));

const QrCodeStyle: CSSProperties = {
  width: 220,
  height: 220,
  paddingTop: 10,
};

type SimplifiedQrViewProps = {
  classes: ReturnType<typeof useStyles>;
  qrcode: string;
  qrIndex: number;
  totalQrCodes: number;
  viewOnly: boolean;
  onNavigate: (qrIndex: number) => void;
};

const SimplifiedQrView: React.FC<SimplifiedQrViewProps> = ({
  viewOnly,
  qrcode,
  classes,
  qrIndex,
  totalQrCodes,
  onNavigate,
}) => {
  return (
    <div>
      {!viewOnly ? (
        <div className={classes.qrPreviewTitle}>Print Preview</div>
      ) : null}
      <div>
        <div className={viewOnly ? classes.qrViewContainer : classes.qrContainer}>
          <Qrcode value={qrcode} style={QrCodeStyle} />
        </div>
        {totalQrCodes > 1 ? (
          <div className={classes.qrNavigateContainer}>
            <IconButton
              style={{
                visibility: qrIndex === 0 ? 'hidden' : 'inherit',
              }}
              onClick={() => onNavigate(qrIndex - 1)}>
              <LeftIcon />
            </IconButton>
            <Typography variant="inherit" className={classes.qrNavigateText}>
              {qrIndex + 1}/{totalQrCodes}
            </Typography>
            <IconButton
              style={{
                visibility: qrIndex === totalQrCodes - 1 ? 'hidden' : 'inherit',
              }}
              onClick={() => onNavigate(qrIndex + 1)}>
              <RightIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

type PrintDialogProps = {
  open: boolean;
  contents: Array<PrintContent>;
  title: string;
  onClose: () => void;
  tokenSize?: number;
  onTokenSizeChange?: (tokenSize: number) => void;
  onPrintClick?: () => void;
};

const PrintDialog: React.FC<PrintDialogProps> = ({
  open,
  title,
  contents,
  onClose,
  tokenSize = 1,
  onTokenSizeChange = null,
  onPrintClick = () => {},
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentContent = contents[currentIndex];
  const classes = useStyles();
  const viewOnly = onTokenSizeChange == null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      id="print-dialog"
      aria-labelledby="print-group-dialog"
      aria-describedby="print-group-dialog"
      maxWidth="md"
      fullWidth={true}>
      <DialogTitle disableTypography>
        <Typography
          variant="h3"
          style={{
            paddingTop: 20,
            paddingBottom: 10,
            borderBottom: `${COLOR.GREENT_TEXT} 4px solid`,
          }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent style={{height: 450}}>
        <Grid container style={{paddingTop: 30}}>
          <Grid item container xs={6} justifyContent="center">
            <SimplifiedQrView
              viewOnly={viewOnly}
              onNavigate={(i) => setCurrentIndex(i)}
              qrcode={currentContent.qrcode}
              classes={classes}
              qrIndex={currentIndex}
              totalQrCodes={contents.length}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            style={{paddingTop: 15}}>
            {currentContent?.descriptions?.map((desc, i) => {
              return (
                <React.Fragment key={i}>
                  <Typography
                    variant="h6"
                    className={`${classes.textContainer} ${classes.contentTitle}`}>
                    {desc.label}
                  </Typography>
                  <Typography
                    variant="h6"
                    className={`${classes.textContainer}`}>
                    {desc.value}
                  </Typography>
                </React.Fragment>
              );
            })}
            {!viewOnly ? (
              <CASL.Can I="update" a="site">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{fontWeight: 600, padding: '20px 0 10px 0'}}>
                    Token Size ( 1 - 8 )
                  </Typography>
                  <FormControl variant="outlined">
                    <InputLabel id="token-size-select">
                      {'Please select'}
                    </InputLabel>
                    <Select
                      labelId="token-size-select"
                      label="Please select"
                      value={tokenSize}
                      onChange={(e) => {
                        onTokenSizeChange(parseInt(e.target.value as any));
                      }}
                      className={classes.dropdownContainer}>
                      {Array(8)
                        .fill(null)
                        .map((_, index) => (
                          <MenuItem value={index + 1} key={index + 1}>
                            Size {index + 1}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Typography variant="h6" className={`${classes.textContainer} ${classes.smallText}`}>
                    Actual Print Size = {tokenSize}" x {tokenSize}"
                  </Typography>
                </Grid>
              </CASL.Can>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <CASL.Can I="update" a="site">
        <DialogActions className={classes.buttonGroup}>
          <MyButton
            text="Cancel"
            variant="outlined"
            onClick={onClose}
            buttonClass={`${classes.dialogButton} ${classes.greenButton}`}
          />
          <MyButton
            text={viewOnly ? 'Print ID' : 'Print'}
            variant="contained"
            buttonClass={classes.dialogButton}
            onClick={onPrintClick}
          />
        </DialogActions>
      </CASL.Can>
    </Dialog>
  );
};

export default PrintDialog;
