import _ from 'lodash';
import { TokenService } from '@aglive/data-model';
import { INITIAL_CREATE_PROFILE_STATE } from './reducer';
import {
  productProfileNameAction,
  productProfileGtinAction,
  productStateStatusAction,
  detailsDeletePageAction,
  detailsDupPageAction,
  detailsPageInputAction,
  contentAddSubheadingAction,
  contentDeleteSubheadingAction,
  contentDupSubheadingAction,
  attachmentAddAction,
  attachmentDeleteAction,
  productProfileActions,
  productProfileBrandAction,
  detailsSelectPageAction,
  detailsSelectTabAction,
  storySelectImageOptionAction,
  validateDataAction,
  brandListAction,
  detailsSelectPageMediaAction,
} from "./types"

// localDispatch decorator
// takes an action creator and dispatches the generated action object
// the decorator needs to be passed in the localDispatch in the main component
export function localDispatchDecorator<T = productProfileActions>(localDispatch: (arg0: T) => void) {
  return function(targetFunction: (...arg0: any[]) => T) {
    // localDispatchDecorator(localDispatch)(duplicatePage) is the same thing as 
    // (targetPageIndex) => localDispatch({
    //   type: 'details/duplicate_page',
    //   payload: targetPageIndex
    // })
    return function() {
      localDispatch(targetFunction.apply(this, arguments));
    }
  }
}

// metadata operations
const editName = (value: string): productProfileNameAction => ({
  type: 'input/name',
  payload: value
});

const editGtin = (value: string): productProfileGtinAction => ({
  type: 'input/gtin',
  payload: value
});

const changeStateStatus = (value: "draft" | "submit"): productStateStatusAction => ({
  type: 'state/status',
  payload: value
});

const editBrand = (selectedBrand: TokenService.BrandToken): productProfileBrandAction => ({
  type: 'select/brand',
  payload: selectedBrand,
});

const validateForm: validateDataAction = {
  type: 'submit/validate_form'
};

export const isFormValid = <T>(form: T): boolean => {
  const checkValue = value => {
    if (typeof value === 'string') {
      return !value.length;
    } else if (Array.isArray(value)) {
      return value.every(item => checkValue(item));
    } else {
      return Object.values(value).every(item => checkValue(item));
    }
  }

  return checkValue(form);
};

export const getBrandList = (brandTokens: TokenService.BrandToken[]): brandListAction => ({
  type: 'brand_list',
  payload: brandTokens,
})

export const sanitizeForm = (
  form:
    | typeof INITIAL_CREATE_PROFILE_STATE['validation']
    | typeof INITIAL_CREATE_PROFILE_STATE['validation']['story'],
) => {
  const clearWarning = (value) => {
    if (typeof value === 'string') {
      return '';
    } else if (Array.isArray(value)) {
      return value.map((item) => clearWarning(item));
    } else {
      for (let key of Object.keys(value)) {
        value[key] = clearWarning(value[key]);
      }

      return value;
    }
  };

  return clearWarning(form);
};

// page operations
const selectPage = (targetPageIndex: number): detailsSelectPageAction => ({
  type: 'details/select_page',
  payload: targetPageIndex
});

const duplicatePage = (targetPageIndex: number): detailsDupPageAction => ({
  type: 'details/duplicate_page',
  payload: targetPageIndex
});

const deletePage = (targetPageIndex: number): detailsDeletePageAction => ({
  type: 'details/delete_page',
  payload: targetPageIndex
});

const selectTab = (targetPageIndex: number): detailsSelectTabAction => ({
  type: 'details/select_tab',
  payload: targetPageIndex
});

const editPage = (
  targetPageIndex: number, 
  keys: Array<string | number>,
  value: any): detailsPageInputAction => ({
  type: 'details/page_input',
  payload: {
    pageIndex: targetPageIndex,
    keys,
    value,
  }
});

const selectPageMedia = (
  targetPageIndex: number, 
  key: TokenService.ProductToken["details"]["pages"][number]["display"]["item"],
  value: string): detailsSelectPageMediaAction => ({
  type: 'details/select_media',
  payload: {
    pageIndex: targetPageIndex,
    key,
    value
  }
});

// content operations
// take targetPage and push a new subheading template
const addContentSubheading = (targetPageIndex: number): contentAddSubheadingAction => ({
  type: 'content/add_subheading',
  payload: targetPageIndex
});

// delete the targeted subheading template
const deleteContentSubheading = (
  targetPageIndex: number, 
  targetSubheadingIndex: number): contentDeleteSubheadingAction => ({
  type: 'content/delete_subheading',
  payload: {
    pageIndex: targetPageIndex,
    subheadingIndex: targetSubheadingIndex
  }
});

const dupContentSubheading = (
  targetPageIndex: number, 
  targetSubheadingIndex: number): contentDupSubheadingAction => ({
  type: 'content/dup_subheading',
  payload: {
    pageIndex: targetPageIndex,
    subheadingIndex: targetSubheadingIndex
  }
});

// attachment operations
const addAttachment = (targetPageIndex: number): attachmentAddAction => ({
  type: 'attachment/add_entry',
  payload: targetPageIndex
});

const deleteAttachment = (
  targetPageIndex: number, 
  targetSubheadingIndex: number): attachmentDeleteAction => ({
  type: 'attachment/delete_entry',
  payload: {
    pageIndex: targetPageIndex,
    subheadingIndex: targetSubheadingIndex
  }
});

// TYS operations
const editMainSectionImageOption = (
  value: 'image' | '',
): storySelectImageOptionAction => ({
  type: 'TYS/image_option',
  payload: value,
});

export default {
  editName,
  editBrand,
  editGtin,
  changeStateStatus,
  validateForm,
  getBrandList,
  selectPage,
  duplicatePage,
  deletePage,
  editPage,
  selectPageMedia,
  selectTab,
  addContentSubheading,
  deleteContentSubheading,
  dupContentSubheading,
  addAttachment,
  deleteAttachment,
  editMainSectionImageOption
};

export const stateHandler = <T, U = typeof INITIAL_CREATE_PROFILE_STATE>(state: U, diffs: T): T & U => {
  const nextState = _.cloneDeep(state);

  return _.merge(nextState, diffs);
}
