import API from 'src/config/api';
import {AppThunk} from 'src/store/types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from 'src/store/spinner/types';
import {toggleModal} from 'src/store/modal/actions';
import {callAPI} from 'src/utils/network';
import {WebErrorType} from 'src/utils/error';
import CONSTANT from 'src/config/constant';
import {FETCH_GROUP_DATA, GroupDataTypes} from './types';
import {AssetTokenResponse} from 'src/store/asset/types';

export function fetchGroupData(pic_id?: string) {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const data = {
        latestDetails: true,
        status: ['exist'],
        type: [CONSTANT.ASSETTYPE.GROUP],
        activityType: [],
        filter: {
          pic_id: pic_id,
        },
      };
      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: data,
      });

      const groupData = [];
      response?.forEach((item) =>
        groupData.push({
          agliveToken: item.externalIds[0].agliveToken,
          name: item.details.name ?? '',
          animalsItems: item.details.items ?? [],
          animalsItemsCount: item.details.items?.length ?? 0,
          pic_id: item.details.pic_id ?? '',
          createdAt: item.createdAt ?? '',
        }),
      );

      dispatch({
        type: FETCH_GROUP_DATA,
        payload: groupData,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export async function checkGroupAssetLocation(
  tokens: Array<{rfid?: string; nlis_id?: string; agliveToken?: string}>,
  pic?: string,
  industryType?: string,
) {
  let message = 'Some items are not part of the selected location.';
  let type = CONSTANT.ASSETTYPE.ASSET;
  switch (industryType) {
    case 'ANIMALS':
      message = `Some items are not part of the selected PIC.`;
      break;
    case 'PLANTS':
      type = CONSTANT.ASSETTYPE.PLANT;
      break;
    case 'PRODUCTS':
      type = CONSTANT.ASSETTYPE.PRODUCT;
      break;
  }
  try {
    const response = await callAPI({
      url: API.POST.getTokenbyExternalId,
      method: 'POST',
      data: {
        type: [type],
        externalIds: tokens.map((tok) => {
          if (tok.rfid) return {rfid: tok.rfid};
          if (tok.agliveToken) return {agliveToken: tok.agliveToken};
          return tok;
        }),
      },
    });
    let pics = new Set();
    response.forEach((resp) => {
      if (resp.details.pic_id || resp.details.pic) {
        pics.add(resp.details.pic_id ?? resp.details.pic);
      }
    });
    if (pics.size > 1 || (pic && !pics.has(pic))) {
      return message;
    }
    return '';
  } catch (e) {
    return message;
  }
}

export function createGroup(
  name: string,
  tokens: Array<{rfid?: string; nlis_id?: string; agliveToken?: string}>,
  selectedPIC: string,
  industryType?: string,
  successCB?: () => void,
): AppThunk {
  return async (dispatch) => {
    dispatch({type: SPINNER_TOGGLE_ON});
    try {
      const errorMsg = await checkGroupAssetLocation(
        tokens,
        selectedPIC,
        industryType,
      );
      if (!errorMsg) {
        const response = await callAPI({
          url: API.POST.createToken,
          method: 'POST',
          data: {
            blockchain: false,
            tokens: [
              {
                type: 'group',
                tokenId: '',
                externalIds: {},
                details: {
                  name: name,
                  items: tokens,
                  pic_id: selectedPIC,
                  timestamp: new Date(),
                },
              },
            ],
          },
        });

        dispatch(
          toggleModal({
            status: 'success',
            title: 'Success',
            CTAHandler: successCB,
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Error',
            subtitle: errorMsg,
          }),
        );
      }
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateGroupItem(
  type: 'add' | 'remove',
  agliveToken: string,
  tokens: Array<{rfid?: string; agliveToken?: string; nlis_id?: string}>,
  selectedPIC: string,
  industryType?: string,
  successCB?: () => void,
): AppThunk {
  return async (dispatch, state) => {
    dispatch({type: SPINNER_TOGGLE_ON});
    try {
      const errorMsg = await checkGroupAssetLocation(
        tokens,
        selectedPIC,
        industryType,
      );
      if (!errorMsg) {
        let externalIdSet = new Set();
        tokens.forEach((tk) =>
          externalIdSet.add(tk.rfid ? 'rfid' : 'agliveToken'),
        );
        const response = await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: 'group',
                externalIds: {agliveToken},
                activities: [
                  {
                    type: type === 'add' ? 'addGroupItem' : 'delGroupItem',
                    details: {
                      externalIds: Array.from(externalIdSet),
                      items: tokens,
                    },
                  },
                ],
              },
            ],
          },
        });

        dispatch(
          toggleModal({
            status: 'success',
            title: 'Success',
            CTAHandler: successCB,
          }),
        );

        dispatch(
          updateGroupStore(
            agliveToken,
            JSON.parse(JSON.stringify(state().group.groupData)),
            tokens,
            type,
          ),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Error',
            subtitle: errorMsg,
          }),
        );
      }
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateGroupStore(
  agliveToken: string,
  groupList: GroupDataTypes[],
  tokens: {rfid?: string; nlis_id?: string; agliveToken?: string}[],
  type: 'add' | 'remove',
): AppThunk {
  return async (dispatch) => {
    const groupCopyIndex = groupList.findIndex(
      (gr) => gr.agliveToken === agliveToken,
    );
    const tokenChange = tokens.map((tk) => {
      if (tk.nlis_id) {
        tk['nlis'] = tk.nlis_id;
      }
      delete tk.nlis_id;
      return tk;
    });
    const tokenIds = tokens.map((tk) => tk.rfid ?? tk.agliveToken);
    if (type === 'add') {
      groupList[groupCopyIndex].animalsItems = [
        ...groupList[groupCopyIndex].animalsItems,
        ...tokenChange,
      ];
      groupList[groupCopyIndex].animalsItemsCount = String(
        Number(groupList[groupCopyIndex].animalsItemsCount) + tokens.length,
      );
    } else {
      groupList[groupCopyIndex].animalsItems = groupList[
        groupCopyIndex
      ].animalsItems.filter(
        (item) =>
          !(
            tokenIds.includes(item.rfid) || tokenIds.includes(item.agliveToken)
          ),
      );
      groupList[groupCopyIndex].animalsItemsCount = String(
        Number(groupList[groupCopyIndex].animalsItemsCount) - tokens.length,
      );
    }
    dispatch({
      type: FETCH_GROUP_DATA,
      payload: groupList,
    });
  };
}

export function editGroup(
  groupName,
  agliveToken,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.GROUP,
              externalIds: {
                agliveToken,
              },
              activities: [
                {
                  type: 'UP_details',
                  details: {name: groupName},
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteGroup(
  group: {agliveToken?: string},
  refresh: boolean | string = true,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: CONSTANT.ASSETTYPE.GROUP,
            externalIds: {
              agliveToken: group.agliveToken,
            },
            activities: [
              {
                type: 'DEL_token',
                details: {
                  reason: 'no reason',
                },
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          button: 'Close',
          CTAHandler: () => {
            if (refresh) {
              if(refresh === true)
                dispatch(fetchGroupData());
              else
                dispatch(fetchGroupData(refresh));
            }
          },
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchGroupAssets(
  groupId: string,
): AppThunk<Promise<AssetTokenResponse>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: `${API.GET.getGroupItemsByExternalId}/${groupId}`,
        method: 'GET',
      });
      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: 'Fail to fetch group assets',
        }),
      );
      return [];
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
