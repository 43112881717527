import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts';

import {Grid, Typography} from '@material-ui/core';

import {MainHeading, WidgetContainer} from './styles';
import {CustomizedLegend, LegendPayloadType} from './DashboardChart';
import COLOR from '../../styled/colors';

export type DashboardChartBarProps = {
  data: Array<any>;
  xLabel?: string;
  yLabel: string;
  xAxis?: Array<{key: string; label?: string; style?: any, labelPosition?: "right" | "bottom"}>;
  legend?: LegendPayloadType;
  bars?: Array<
    Array<{
      key: string;
      fill: string;
    }>
  >;
  notes?: string;
  chartPosition?: {top?: number, left?: number, right?: number, bottom?: number};
};

const renderCustomizedLabel = (props) => {
  const {x, y, width, height, value} = props;

  return value ? (
    <g>
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle">
        {value}
      </text>
    </g>
  ) : (
    <></>
  );
};

const renderBarSum = (props) => {
  const {x, y, width, value} = props;

  return value ? (
    <g>
      <text
        x={x + width / 2}
        y={y - 15}
        fill="#000"
        font-weight="bold"
        textAnchor="middle"
        dominantBaseline="middle">
        {value}
      </text>
    </g>
  ) : (
    <></>
  );
};

const DashboardChartBar: React.FC<DashboardChartBarProps> = ({
  data,
  xLabel,
  yLabel,
  xAxis,
  legend,
  bars,
  notes,
  chartPosition
}) => {
  return (
    <>
      <div style={{marginLeft: 20}}>
        <MainHeading>{yLabel}</MainHeading>
      </div>
      <div style={{marginBottom: 30}}>
        <ResponsiveContainer width="95%" height={500}>
          <BarChart
            data={data}
            margin={{
              top: chartPosition?.top ?? 40,
              right: chartPosition?.right ?? 20,
              left: chartPosition?.left ?? 20,
              bottom: chartPosition?.bottom ?? 0,
            }}
            barGap={10}
            barSize={60}>
            {xAxis?.map((axis, index) => (
              <XAxis
                key={axis.key}
                dataKey={axis.key}
                xAxisId={index}
                axisLine={!index}
                tickLine={false}
                stroke={'#D2D2D2'}
                tickFormatter={(tick) => axis?.label && !axis?.labelPosition ? axis?.label : tick}
                tick={axis?.style}
              >
                {axis.label && axis.labelPosition && <Label value={axis.label} style={{fontSize: 22, fontFamily: 'Oswald'}} offset={20} position={axis.labelPosition ?? "right"} />}
              </XAxis>
            ))}
            <YAxis stroke={'#D2D2D2'} tick={{fill: 'black'}} />
            {!!legend?.payload && <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="circle"
              wrapperStyle={{
                paddingLeft: 150,
              }}
              payload={legend.payload}
              content={<CustomizedLegend />}
            />}
            {bars
              ?.map((bar, index) =>
                bar.map((stack) => (
                  <Bar
                    key={`${stack.key}-${index}`}
                    dataKey={stack.key}
                    isAnimationActive={false}
                    stackId={index}
                    fill={stack.fill}
                    label={renderBarSum}
                    >
                    {bars.length > 1 && <LabelList
                      dataKey={stack.key}
                      content={renderCustomizedLabel}
                    />}
                    {/* <LabelList dataKey={stack.key} content={renderBarSum} /> */}
                  </Bar>
                )),
              )
              .flat()}
          </BarChart>
        </ResponsiveContainer>
      </div>
      {!!xLabel && <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginBottom: 30,
        }}>
        <MainHeading>{xLabel}</MainHeading>
      </div>}
      {!!notes && <div style={{paddingLeft: 45}}>
        <Typography style={{fontWeight: 'bold', color: '#7E7E7E'}}>Note: </Typography>
        <Typography style={{color: '#7E7E7E'}}>{notes}</Typography>
      </div>}
    </>
  );
};

type DashboardChartBarWidgetProps = {
  title: string;
} & DashboardChartBarProps;

const DashboardChartBarWidget: React.FC<DashboardChartBarWidgetProps> = ({
  title,
  ...props
}) => {
  return (
    <WidgetContainer item container direction="column" style={{padding: 30}}>
      <Grid item container justifyContent="center" style={{marginBottom: 30}}>
        <MainHeading>{title}</MainHeading>
      </Grid>

      <Grid>
        {props.data?.length ? 
        <DashboardChartBar {...props} />
        : <Grid container justifyContent='center' alignContent='center' style={{minHeight: 400}}><Grid item style={{backgroundColor: COLOR.GRAY_SOLID, padding: 20, paddingLeft: 50, paddingRight: 50}}><Typography>No data to display</Typography></Grid></Grid>
        }
      </Grid>
    </WidgetContainer>
  );
};

export default DashboardChartBarWidget;
