import React, {useRef, useState, useEffect, useCallback} from 'react';
import produce from 'immer';
import Qrcode from "qrcode.react";

// Other packages
import moment from 'moment';
// Material UI
import EditIcon from '@material-ui/icons/Edit';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import {useHistory, useRouteMatch, Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../utils/hooks';

import {Grid, Typography} from '@material-ui/core';

import SearchBar from '../../../../presentation/SearchBar';
import MyButton from '../../../../presentation/button';
import withHeader from '../../../../presentation/withHeader';
import agliveLogo from '../../../../img/agliveLogo.png';

import {csvInputT, generateCSV, PRODUCT_CSV_HEADER, updateCodeEntry} from '../../../../store/code/actions';
import { iCodeEntryDocument, iCodeEntryDocumentResponse } from '../../../../store/code/types';
import PromotionLanding from '../../../promotion/PromotionLanding/PromotionLanding';
import SampleSecurityCode from '../../../../img/promotion/SampleSecurityCode.svg';
import { promotionLandingContent } from '../../../promotion/PromotionLanding/promotionLandingContent';
import { callAPI } from '../../../../utils/network';
import API from '../../../../config/api';
import CONSTANT from '../../../../config/constant';
import COLOR from '../../../../styled/colors';

import { TokenService } from '@aglive/data-model';
import MyTable from '../../../../presentation/Table';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../../store/spinner/types';
import { toggleModal } from '../../../../store/modal/actions';
import { WebError } from '../../../../utils/error';
import { INITIAL_PRINT_STATE } from '../../CustomPrintModal';
import { CustomPrintModal } from '../../CustomPrintModal';

import useStyles from './styles';
import "./printStyles.css";
import { fetchSecurityCodeEntries } from '../utils';

const getTableHeader = ['Product Name', 'Available Codes', 'Type', 'Delivery History','Date Created', 'Action'];

// In addition to submit generate request, GenerateSecurityCode will also submit a document to keep track whether the particular batch of codes has been used
// On mount, CodeLibrary will fetch all the code-related documents associated to the user instead of security codes themselves (because we do not keep track of use/unused status on the security code token itself)
const SecurityCodeLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const userid = useAppSelector((state) => state.auth.wallet);

  const [query, setQuery] = useState('');
  const [codeEntries, setCodeEntries] = useState<Array<iCodeEntryDocumentResponse<iCodeEntryDocument>>>([]);
  const [productProfiles, setProductProfiles] = useState<{ [key: string]: TokenService.ProductToken }>(null);

  const getGroupedCodesWithProductProfiles = useCallback(async (
    fetchProductProfiles = true
  ) => {
    const res = await fetchSecurityCodeEntries(dispatch, userid, false, fetchProductProfiles);

    if (fetchProductProfiles) {
      setProductProfiles(res?.uniqueProfileMap || null);
    }
    setCodeEntries(res?.codeList || []);
  }, []);

  const fetchBrand = async (agliveToken: string) => {
    // fetch brand token
    const brandToken = await callAPI({
      url: API.POST.getTokenbyExternalId,      
      method: 'POST',
      data: {
        externalIds: [{
          agliveToken,
        }]
      },
    });

    return brandToken;
  }

  const onDownload = async (entry: iCodeEntryDocumentResponse<iCodeEntryDocument>) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const brandToken = await fetchBrand(productProfiles[entry.details.product.product.agliveToken].details.brand.agliveToken);

      if (!brandToken.length) throw new WebError('INVALID_ERROR');

      const csvFileName = `${entry.details.product.product.agliveToken}-${moment().format('YYYY-MM-DD')}.csv`;
      const csvContent: Array<csvInputT> = entry.details.codes.map(code =>[
        CONSTANT.SCAN_URL(code),
        entry.details.product.container,
        productProfiles[entry.details.product.product.agliveToken].details.name,
        brandToken[0].details.name,
        entry.details.product.source.companyName,
        entry.details.product.source.creatorEmail,
      ]);
      generateCSV(csvContent, csvFileName, PRODUCT_CSV_HEADER);

      // mark code as used
      await updateCodeEntry(entry);

      getGroupedCodesWithProductProfiles(false);
    } catch (e) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Something went wrong!',
          subtitle: e?.message || 'Please try again.',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  // printing content
  // printing logic - print state holds the information necessary to build modal (assetProfileName, permit, qrCode.length)
  // pressing on 'print' icon will trigger the modal,
  // pressing 'cancel' reset the printState, pressing 'print' will set the content (printContent) on the print prompt (hidden at this stage) (ComponentToPrint)
  // the useEffect hook is triggered on printContent changes and will invoke handlePrint and show the print prompt
  const componentRef = useRef();
  const [printState, setPrintState] = useState(INITIAL_PRINT_STATE);
  const [printContent, setPrintContent] = useState([] as typeof INITIAL_PRINT_STATE.qrcode); // this is used to invoke useEffect hook
  const [printCodeEntry, setPrintCodeEntry] = useState<iCodeEntryDocumentResponse<iCodeEntryDocument> | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${
      productProfiles &&
      productProfiles[printCodeEntry?.details.product.product.agliveToken]
        ?.details.name
    } Security Codes`,
  });

  useEffect(() => {
    if (printContent.length) {
      handlePrint();
    }
  }, [printContent]);

  const onPrint = async (entry: iCodeEntryDocumentResponse<iCodeEntryDocument>) => {
    const brandToken = await fetchBrand(productProfiles[entry.details.product.product.agliveToken].details.brand.agliveToken);
    setPrintState({
      show: true,
      tokenSize: 1,
      modalInfo: [{
        title: 'Product Name',
        value: productProfiles[entry.details.product.product.agliveToken].details.name,
      }, {
        title: 'Brand Name',
        value: brandToken[0].details.name,
      }, {
        title: 'No. of Codes to Print',
        value: String(entry.details.codes.length),
      }],

      qrcode: entry.details.codes.map(code => CONSTANT.SCAN_URL(code)),
      description: [
        {
          label: 'Brand',
          value: brandToken[0].details.name,
        },
        {
          label: 'Product',
          value: productProfiles[entry.details.product.product.agliveToken].details.name,
        }
      ],
    });
    setPrintCodeEntry(entry);

    // mark code as used
    // await updateCodeEntry(entry);
  };

  // categorize all entries according to product agliveToken id
  useEffect(() => {
    getGroupedCodesWithProductProfiles();
  }, [getGroupedCodesWithProductProfiles]);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery} 
            label={'Search Product Name'}
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Generate Codes'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(`${path}/new`)}
          />
        </Grid>
      </Grid>
      {codeEntries.length
        ? (
          <MyTable
            firstColumnWidth={classes.firstColumnWidth}
            heads={getTableHeader}
            rows={codeEntries
              ?.filter((res) => 
                // access productProfiles map with agliveToken -> only show entry if productProfile's name include the query
                productProfiles[res.details.product.product.agliveToken].details.name
                  .toLowerCase().includes(query.toLowerCase())
              )
              .map((entry, index) => [
                <span id={`ProductName${index}`}>{productProfiles[entry.details.product.product.agliveToken].details.name}</span>,
                <span id={`AvailableCodes${index}`}>{entry.details.codes.length}</span>,
                <span id={`CodeType${index}`}>
                {entry.details.product.container.charAt(0).toUpperCase() +
                  entry.details.product.container.slice(1)}
                </span>,
                <span id={`DeliveryHistory${index}`}>
                {entry.details.product.showDeliveryHistory
                ? entry.details.product.isConfidential
                  ? 'Confidential'
                  : 'On'
                : 'Off'}
                </span>,
                moment(entry.details.product.date).format('DD/MM/YYYY'),
                <div style={{display: 'flex', gap: 10}}>
                  <EditIcon
                    style={{cursor: 'pointer'}}
                    onClick={() => history.push(`${path}/edit/${entry.docId}`)}
                    id={`EditCode${index}`}
                  />
                  <GetAppSharpIcon
                    style={{cursor: 'pointer'}}
                    onClick={() => onDownload(entry)}
                    id={`DownloadCode${index}`}
                    data-cy={`${entry.details.product.product.agliveToken}-${moment().format('YYYY-MM-DD')}.csv`}
                  />
                  <PrintIcon
                    style={{cursor: 'pointer'}}
                    onClick={() => onPrint(entry)}
                    id={`PrintCode${index}`}
                  />
                </div>,
              ])
            }
          />
        ) : (
          <PromotionLanding
            promotionHeadingConfig={{
              title: 'Start a security code today!',
              subtitle: 'Imagine creating a powerful code like the one below'
            }}
            promotionImage={SampleSecurityCode}
            promotionLandingContent={promotionLandingContent('securityCode')}
          />
        )}
      <Grid item style={{paddingTop: 20}}>
        <Link to={`${path}/used`} className={classes.hyperlink} id="UsedCodes">Used Codes</Link>
      </Grid>

      <div style={{ display: 'none' }}>
        <ComponentToPrint 
          tokenSize={printState.tokenSize}
          qrcode={printContent}
          ref={componentRef}
        />
      </div>

      {printState.show && (
        <CustomPrintModal
          show={printState.show}
          content={printState.qrcode}
          tokenSize={printState.tokenSize}
          modalInfo={printState.modalInfo}
          setPrintContent={setPrintContent}
          onPrint={async () => {
            await updateCodeEntry(printCodeEntry);

            await getGroupedCodesWithProductProfiles(false);
          }}
          onSizeChange={e => setPrintState(prevState => 
            produce(prevState, draft => {
              draft.tokenSize = Number(e.target.value);
            })
          )}
          onCancel={() => setPrintState(INITIAL_PRINT_STATE)}
        />
      )}
    </>
  );
};

class ComponentToPrint extends React.Component<{
  qrcode: Array<string>;
  tokenSize: number;
}> {
  render() {
    if (this.props.qrcode) {
      const printContent = this.props.qrcode;

      if (this.props.tokenSize >= 5) {
        return (
          <div className="print-container" style={{
            margin: "0",
            padding: "0",
            width: '100%',
          }}>
            {printContent.map(qrcode => (
              <SecurityQRCode tokenId={qrcode} tokenSize={this.props.tokenSize} />
            ))}
          </div>
        );
      } else {
        // cannot use conventional flex layout system because print will not correctly add breaks to content and the overflowed content will be cutted and printed in different pages
        let printContentArr = [];
        const printContentLength = printContent.length;

        for (let i = 0; i < Math.floor(printContentLength/2); i++) {
          printContentArr.push([printContent[i * 2], printContent[i * 2 + 1]]);
        }
        
        if (printContentLength % 2) {
          printContentArr.push([printContent[printContentLength - 1], null]);
        }
        
        return (
          <div className="print-container" style={{
            margin: "0",
            padding: "0",
            width: '100%',
          }}>
            {printContentArr.map(([item1, item2]) => (
              <div style={{
                display: 'inline-block',
                width: '100%',
              }}>
                <SecurityQRCode tokenId={item1} tokenSize={this.props.tokenSize} />
                {!!item2 && <SecurityQRCode tokenId={item2} tokenSize={this.props.tokenSize} />}
              </div>
            ))}
            {/* {printContent.map(qrcode => (
              <SecurityQRCode tokenId={qrcode} tokenSize={this.props.tokenSize} />
            ))} */}
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

type SecurityQRCodeProps = {
  tokenId: string;
  tokenSize: number;
}

const SecurityQRCode: React.FC<SecurityQRCodeProps> = ({
  tokenId,
  tokenSize
}) => (
  <div className={`print-security-content ${tokenSize >= 5 ? 'large-content' : 'small-content'}`}>
    <Typography style={{color: COLOR.GREEN_BUTTON}}><b>SCAN ME NOW</b></Typography>
    <Qrcode
      value={tokenId}
      style={{ 
        width: `${tokenSize}in`, 
        height: `${tokenSize}in`, 
      }}
      />
    <div className="print-security-watermark">
      <Typography variant="h6" style={{fontWeight: 600}}>Protected by</Typography>
      <img
        className="print-security-logo"
        src={agliveLogo}
        alt="protected by Aglive"
        />
    </div>
  </div>
);

export default withHeader(
  {
    title: 'Security Codes',
  },
  SecurityCodeLibrary,
);
