import React, {useState, useEffect} from 'react';
import moment from 'moment';
import COLOR from '../../styled/colors';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {Buttons} from '../../presentation/ButtonsGroup';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import FilterList from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from '../../presentation/DatePicker';
import {deleteForm, fetchFormTemplates} from '../../store/form/actions';
import {FormDoc} from '../../store/form/types';
import { CASL } from '@aglive/frontend-core';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  filterContainer: {
    marginRight: 50,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  formTypeFilter: {
    flexDirection: 'row',
    display: 'flex',
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 310,
    maxHeight: 56,
    marginRight: 8,
  },
  label: {
    textTransform: 'none',
    textAlign: 'left',
  },
  icon: {
    cursor: 'pointer',
  },
  hyperlink: {
    color: COLOR.GREENT_TEXT,
    cursor: 'pointer',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    fontSize: 16,
    marginBottom: -10,
  },
  filterLabel: {
    fontWeight: 700,
  },
}));

const FormLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [formType, setFormType] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const savedForms = useAppSelector((state) => state.form)?.sort(
    (form1: FormDoc, form2: FormDoc) => {
      return form1.formName > form2.formName ? 1 : -1;
    },
  );
  const [filteredForms, setFilteredForms] = useState([]);

  const ability = CASL.useAbility(CASL.AbilityContext);
  const TABLE_HEADER = ['Form', 'Form Type', 'Date Created'];
  ability.can('update', 'form') ? TABLE_HEADER.push('Action') : TABLE_HEADER.push('');

  const formTypeList = [
    {label: 'All', value: 'all'},
    {label: 'eNVD', value: 'LPAC1'},
    {label: 'Health Declaration', value: 'HSC2'},
    // {label: 'NFAS Feedlot Delivery Docket', value: 'nfas_delivery'},
    // {label: 'MSA', value: 'msa'},
  ];

  const inDateRange = (date: string, dateFrom: Date, dateTo: Date) => {
    return (
      new Date(date).getDate() >= dateFrom.getDate() &&
      new Date(date).getDate() <= dateTo.getDate()
    );
  };

  const handleFilterChange = (key: string, value: Date | number) => {
    switch (key) {
      case 'dateFrom':
        setDateFrom(value);
        return;
      case 'dateTo':
        setDateTo(value);
        return;
      case 'formType':
        setFormType(formTypeList[value as number].value);
        return;
    }
  };

  const handleResetFilter = () => {
    setDateFrom(null);
    setDateTo(null);
    setFormType(null);
  };

  useEffect(() => {
    dispatch(fetchFormTemplates());
  }, []);

  useEffect(() => {
    let filteredForms = savedForms;
    if (dateFrom && dateTo) {
      filteredForms = filteredForms.filter((form) =>
        inDateRange(form.createdAt, dateFrom, dateTo),
      );
    }
    if (formType) {
      if (formType === 'all') {
        setFilteredForms(filteredForms);
        return;
      }
      filteredForms = filteredForms.filter(
        (form) => form.formType === formType,
      );
    }
    setFilteredForms(filteredForms);
  }, [dateFrom, dateTo, formType, savedForms]);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by Form Name"
          />
        </Grid>
        <CASL.Can I="create" a="form">
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={'Create New'}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() => history.push(`${path}/new`)}
            />
          </Grid>
        </CASL.Can>
      </Grid>
      <Grid item className={classes.filterContainer}>
        <div className={classes.filterButton}>
          <FilterList className={classes.filterIcon} />
          <Button onClick={() => setShowFilter(!showFilter)}>
            <span
              style={{color: COLOR.GREENT_TEXT, textTransform: 'capitalize'}}>
              <u>{'Filter Date and Form Type'}</u>
            </span>
          </Button>
        </div>
        {showFilter === true && (
          <Grid
            item
            container
            justify="flex-start"
            xs={12}
            spacing={1}
            style={{marginBottom: 20, display: 'flex'}}>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                role="label"
                className={classes.filterLabel}>
                Date Created From
              </Typography>
              <DatePicker
                dateValue={dateFrom}
                handleChange={(date) => {
                  handleFilterChange('dateFrom', date);
                }}
                errorStatus={
                  new Date(dateFrom) <= new Date(dateTo) || !dateFrom
                }
                errorMessage={'Invalid date range.'}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                role="label"
                className={classes.filterLabel}>
                Date Created To
              </Typography>
              <DatePicker
                dateValue={dateTo}
                handleChange={(date) => {
                  handleFilterChange('dateTo', date);
                }}
                errorStatus={new Date(dateFrom) <= new Date(dateTo) || !dateTo}
                errorMessage={'Invalid date range.'}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                role="label"
                className={classes.filterLabel}>
                Form Type
              </Typography>
              <Grid className={classes.formTypeFilter}>
                <FormControl
                  id={'FormTypeFormControl'}
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  <Select
                    id={'FormTypeFilter'}
                    labelId="form-library-select-type"
                    value={
                      formType
                        ? formTypeList.indexOf(
                            formTypeList.find(
                              (type) => type.value === formType,
                            ),
                          )
                        : 0
                    }
                    onChange={(
                      e: React.ChangeEvent<{name?: string; value: unknown}>,
                    ) => {
                      handleFilterChange('formType', e.target.value as number);
                    }}>
                    {formTypeList.map((formType, index) => {
                      return (
                        <MenuItem
                          value={index}
                          key={index}
                          id={`FormTypeFilter-${index}`}>
                          {formType.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={3} alignItems="flex-end" style={{display: 'flex'}}>
              <Button
                style={{height: 35, marginLeft: 15}}
                onClick={handleResetFilter}>
                <span className={classes.hyperlink}>
                  <u>{'Reset Filter'}</u>
                </span>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <MyTable
        heads={TABLE_HEADER}
        rows={filteredForms
          ?.filter((form) =>
            form.formName.toLowerCase().includes(query.toLowerCase()),
          )
          .map((form, index) => [
            <Button
              onClick={() => history.push(`${path}/view/${form.docId}`)}
              classes={{
                label: classes.label,
              }}
              style={{justifyContent: 'flex-start'}}>
              <span style={{color: COLOR.GREENT_TEXT}}>
                <u>{form.formName}</u>
              </span>
            </Button>,
            formTypeList.find((type) => form.formType === type.value).label,
            moment(form.createdAt).format('DD/MM/YYYY'),
            <CASL.Can I="update" a="form">
              <div style={{display: 'flex', marginRight: -100}}>
                <div style={{marginRight: 20}}>
                  <EditIcon
                    id={`Edit${index}`}
                    className={classes.icon}
                    onClick={() => history.push(`${path}/edit/${form.docId}`)}
                  />
                </div>
                <div style={{marginRight: 20}}>
                  <FileCopyIcon
                    id={`Copy${index}`}
                    className={classes.icon}
                    onClick={() => {
                      history.push(`${path}/duplicate/${form.docId}`);
                    }}
                  />
                </div>
                <div style={{marginRight: 20}}>
                  <DeleteIcon
                    id={`Delete${index}`}
                    className={classes.icon}
                    onClick={() =>
                      dispatch(
                        toggleModal({
                          status: 'warning',
                          title: 'Delete Form?',
                          subtitle: 'This action cannot be undone',
                          renderButton: (
                            <Buttons
                              leftButtonTitle="Cancel"
                              rightButtonTitle="Delete"
                              leftButtonOnClick={() => dispatch(toggleModalOff())}
                              rightButtonOnClick={() =>
                                dispatch(deleteForm(form.docId))
                              }
                            />
                          ),
                        }),
                      )
                    }
                  />
                </div>
              </div>
            </CASL.Can>,
          ])}
      />
    </>
  );
};

export default withHeader(
  {
    title: 'Saved Forms',
  },
  FormLibrary,
);
