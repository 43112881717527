import {
  Box,
  Collapse,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {useCallback, useState} from 'react';
import MyButton from 'src/presentation/button';
import DeleteIcon from '@material-ui/icons/Delete';
import {toggleModal, toggleModalOff} from 'src/store/modal/actions';
import {useAppDispatch} from 'src/utils/hooks';
import {Buttons} from 'src/presentation/ButtonsGroup';
import moment from 'moment';

import type {GmpLicense, GmpPermit, OdcLicense, OdcPermit, PoisonPermit} from '@aglive/data-model/dist/misc/business';
import DateEditInput from './DateEditInput';
import {TEXT_FIELD_ERROR_STYLE, TEXT_FIELD_NORMAL_STYLE} from '../style';

import {useStyle} from '../style/useStyle';
import {
  IndexedGmpLicense,
  IndexedOdcLicense,
  IndexedPoisonPermit,
} from './types';
import {PermitListLabels, PermitTable} from './PermitTable';
import {LicenseValidationState, PermitValidationState} from './dataTemplate';

type Labels = {
  license: string;
  addLicenseAndPermit: string;
  licenseNumber: string;
  delete: string;
  cannptUndone: string;
  cancel: string;
  licenseStartDate: string;
  licenseExpiryDate: string;
  deleted: string;
} & Partial<PermitListLabels>;

type SavePermitHandler = (
  localLicenseIndex: number,
  licenseIndex: number,
  permitIndex: number,
  permit: OdcPermit | GmpPermit,
) => void;

type AddPermitHandler = (
  localLicenseIndex: number,
  licenseIndex: number,
  permit: OdcPermit | GmpPermit,
) => void;

type DeletePermitHandler = (
  localLicenseIndex: number,
  licenseIndex: number,
  permitIndex: number,
) => void;

type LicenseValidations = Array<
  Partial<LicenseValidationState> & Partial<PermitValidationState>
>;

type Props = {
  licenses: Array<IndexedOdcLicense | IndexedGmpLicense | IndexedPoisonPermit>;
  onAddLicense: () => void;
  onUpdateLicense: (
    globalLicenseIndex: number,
    localLicenseIndex: number,
    fieldName: string,
    value: any,
  ) => void;
  onDeleteLicense: (licenseIndex: number, localIndex: number) => void;
  onDeletePermit?: DeletePermitHandler;
  onSavePermit?: SavePermitHandler;
  onAddPermit?: AddPermitHandler;
  labels: Labels;
  licenseValidations: LicenseValidations;
  onEditing?: (licenseIndex: number, isEditing: boolean) => void;
};

const EditLicenseForm: React.FC<Props> = ({
  licenses,
  labels,
  licenseValidations,
  onAddLicense,
  onUpdateLicense,
  onDeleteLicense,
  onDeletePermit = () => {},
  onSavePermit = () => {},
  onAddPermit = () => {},
  onEditing = () => {},
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyle();
  const [licenseCollapses, setLicenseCollapses] = useState(
    new Array(licenses?.length ?? 0).fill(false),
  );

  const handleLicenseCollapse = useCallback((index: number) => {
    setLicenseCollapses((prev) =>
      prev.map((collapse, i) => (i === index ? !collapse : collapse)),
    );
  }, []);

  const handleSavePermit = 
    (
      localLicenseIndex: number,
      licenseIndex: number,
      permitIndex: number,
      permit: OdcPermit | GmpPermit,
      onSavePermit: SavePermitHandler,
      onSuccess: (permit: OdcPermit | GmpPermit) => void,
    ) => {
      // check duplicate

      const permitNumberUnchanged = licenses.find(license => {
        const existingLicense = license.name !== 'Poison' && license.index === licenseIndex;
        if (existingLicense) {
          if (license.permits[permitIndex]?.permitNumber === permit.permitNumber) {
            return license.permits[permitIndex];
          }
        }
        return undefined;
      });

      const hasDuplicate = checkDuplicatePermitNumber(licenses, permit.permitNumber);
      if (!permitNumberUnchanged && hasDuplicate) {
        dispatch(
          toggleModal({
            status: 'failed',
            title: labels.addPermit?.replace('Add', 'Update'),
            subtitle: 'This permit number already exists',
            button: 'Close',
          }),
        );
      } else {
        onSuccess(permit);
        onSavePermit(localLicenseIndex, licenseIndex, permitIndex, permit);
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Updated',
            button: 'Close',
          }),
        );
      }
    };


  const handleAddLicense = useCallback((onAddLicense: () => void) => {
    setLicenseCollapses((prev) => [...prev, true]);
    onAddLicense();
  }, []);

  const handleAddPermit = (
    localLicenseIndex: number,
    globalLicenseIndex: number,
    permit: OdcPermit | GmpPermit,
    onAddPermit: AddPermitHandler,
    onSuccess: (permit: OdcPermit | GmpPermit) => void,
  ) => {
    const hasDuplicate = checkDuplicatePermitNumber(licenses, permit.permitNumber);
    if (hasDuplicate) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: labels.addPermit,
          subtitle: 'This number already exists',
          button: 'Close',
        }),
      );
    } else {
      onSuccess(permit);
      onAddPermit(localLicenseIndex, globalLicenseIndex, permit);
    }
  };

  const handleDeletePermit = useCallback(
    (
      localLicenseIndex: number,
      licenseIndex: number,
      permitIndex: number,
      onDeleted: (deleted: boolean) => void,
      onDeletePermit: DeletePermitHandler,
    ) => {
      dispatch(
        toggleModal({
          status: 'warning',
          title: `${labels.delete}?`,
          subtitle: labels.cannptUndone,
          renderButton: (
            <Buttons
              leftButtonTitle={labels.cancel}
              rightButtonTitle={labels.delete}
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
                onDeleted(false);
              }}
              rightButtonOnClick={() => {
                dispatch(toggleModalOff());
                // Delete all delete permit
                onDeletePermit(localLicenseIndex, licenseIndex, permitIndex);
                onDeleted(true);
                dispatch(
                  toggleModal({
                    status: 'success',
                    title: 'Deleted',
                    button: 'Close',
                  }),
                );
              }}
            />
          ),
        }),
      );
    },
    [dispatch, labels.cancel, labels.cannptUndone, labels.delete],
  );

  const handleEditingChange = (licenseIndex: number, isEditing: boolean) => {
    onEditing(licenseIndex, isEditing);
  };

  const handleDeleteLicense = (index: number, localIndex: number) => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: `${labels.delete}?`,
        subtitle: labels.cannptUndone,
        renderButton: (
          <Buttons
            leftButtonTitle={labels.cancel}
            rightButtonTitle={labels.delete}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              setLicenseCollapses((prev) =>
                prev.filter((collapse, i) => i !== localIndex),
              );
              onDeleteLicense(index, localIndex);
              dispatch(
                toggleModal({
                  status: 'success',
                  title: 'Deleted',
                  button: 'Close',
                }),
              );
            }}
          />
        ),
      }),
    );
  };
  return (
    <>
      <Grid container item>
        <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
          {labels.license}
        </Typography>
      </Grid>
      {licenses
        ? licenses.map((license, localLicenseIndex: number) => {
            const validation = licenseValidations[localLicenseIndex];
            const licenseValidation = {
              licenseNumber:
                license.name === 'Poison'
                  ? validation.permitNumber
                  : validation.licenseNumber,
              startDate:
                license.name === 'Poison'
                  ? validation.permitStartDate
                  : validation.startDate,
              expiryDate:
                license.name === 'Poison'
                  ? validation.permitExpiryDate
                  : validation.expiryDate,
            };
            return (
              <Grid item xs={12} key={`license-${localLicenseIndex}`} id={`${license.name}-${localLicenseIndex}`} aria-label={`${license.name}-item`}>
                <Box className={classes.titleContainer}>
                  <ListItem
                    style={{paddingLeft: 0}}
                    button
                    onClick={() => handleLicenseCollapse(localLicenseIndex)}>
                    <Grid container justifyContent="space-between">
                      <Typography variant="h3" role="label">
                        {`${labels.license} ${localLicenseIndex + 1}`}
                      </Typography>
                      {licenseCollapses[localLicenseIndex] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </Grid>
                  </ListItem>
                </Box>
                <Collapse in={licenseCollapses[localLicenseIndex]}>
                  <Grid container spacing={3}>
                    <Grid item container xs={6} direction="column">
                      <Typography
                        variant="h6"
                        style={{fontWeight: 600, marginTop: 20}}>
                        {labels.licenseNumber}
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={
                          license.name === 'Poison'
                            ? license.permitNumber
                            : license.licenseNumber
                        }
                        onChange={(e) => {
                          e.persist();
                          onUpdateLicense(
                            license.index,
                            localLicenseIndex,
                            license.name === 'Poison'
                              ? 'permitNumber'
                              : 'licenseNumber',
                            e.target.value as string,
                          );
                        }}
                        error={!!licenseValidation.licenseNumber}
                        helperText={licenseValidation.licenseNumber}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      style={{marginTop: 20}}
                      xs={6}
                      justifyContent="flex-end"
                      alignContent="center">
                      <IconButton
                        style={{
                          width: 50,
                          height: 50,
                        }}
                        onClick={() =>
                          handleDeleteLicense(license.index, localLicenseIndex)
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      style={{marginTop: 40}}
                      direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {labels.licenseStartDate}
                      </Typography>
                      <DateEditInput
                        style={
                          !!licenseValidation.startDate
                            ? TEXT_FIELD_ERROR_STYLE
                            : TEXT_FIELD_NORMAL_STYLE
                        }
                        value={moment(license.startDate) || null}
                        id="date-picker-inline-start"
                        onChange={(date) => {
                          onUpdateLicense(
                            license.index,
                            localLicenseIndex,
                            'startDate',
                            date,
                          );
                        }}
                        errorText={licenseValidation.startDate as string}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      style={{marginTop: 40}}
                      direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {labels.licenseExpiryDate}
                      </Typography>
                      <DateEditInput
                        style={
                          !!licenseValidation.expiryDate
                            ? TEXT_FIELD_ERROR_STYLE
                            : TEXT_FIELD_NORMAL_STYLE
                        }
                        value={moment(license.expiryDate) || null}
                        id="date-picker-inline-expiry"
                        onChange={(date) => {
                          onUpdateLicense(
                            license.index,
                            localLicenseIndex,
                            'expiryDate',
                            date,
                          );
                        }}
                        errorText={licenseValidation.expiryDate as string}
                        minDate={moment(license.startDate).toDate() || null}
                        minDateErrorMessage={
                          'Expiry date should be after the start date'
                        }
                      />
                    </Grid>
                    {license.name === 'ODC' || license.name === 'GMP' ? (
                      <PermitTable
                        license={license}
                        onDeletePermit={(permitIndex, onDeleted) =>
                          handleDeletePermit(
                            localLicenseIndex,
                            license.index,
                            permitIndex,
                            onDeleted,
                            onDeletePermit,
                          )
                        }
                        onAddPermit={(permit, onSuccess) =>
                          handleAddPermit(
                            localLicenseIndex,
                            license.index,
                            permit,
                            onAddPermit,
                            onSuccess,
                          )
                        }
                        onSavePermit={(permitIndex, permit, onSuccess) =>
                          handleSavePermit(
                            localLicenseIndex,
                            license.index,
                            permitIndex,
                            permit,
                            onSavePermit,
                            onSuccess
                          )
                        }
                        onEditing={(isEditing: boolean) =>
                          handleEditingChange(license.index, isEditing)
                        }
                        labels={labels as any}
                      />
                    ) : null}
                  </Grid>
                </Collapse>
              </Grid>
            );
          })
        : null}
      <Grid container item style={{marginTop: 64}}>
        <MyButton
          text={labels.addLicenseAndPermit}
          variant="outlined"
          onClick={() => handleAddLicense(onAddLicense)}
        />
      </Grid>
    </>
  );
};

function checkDuplicatePermitNumber(licenses: Array<GmpLicense | OdcLicense | PoisonPermit>, permitNumber: string) {
  const existingPermits = licenses.flatMap((license) => {
    if (license.name === 'Poison') {
      return [];
    }
    return license.permits || [];
  });
  const hasDuplicate = existingPermits.find(
    (p) => p.permitNumber === permitNumber,
  );
  return !!hasDuplicate;
}

export default EditLicenseForm;
