import {
  GmpLicense,
  OdcLicense,
  OdcPermit,
  PoisonPermit,
} from '@aglive/data-model/dist/misc/business';
import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import {RawAssetProfile} from '../../../store/assetProfile/types';
import {iCodeEntryDocumentResponse} from '../../../store/code/types';
import {PlantCodeDocumentDetails} from '../../../store/plant/types';
import {filterPermits} from '../../../store/profile/utils';
import {ALL_ASSET_PROFILE, ALL_PERMIT, SELECT_ALL, SORT_BY} from './dataTemplate';
import {
  CodeCompareFunction,
  CodeQueryFunction,
  FilterItem,
  FilterName,
  SortBy,
  SortItem,
} from './types';

type Props = {
  licenses: (OdcLicense | GmpLicense | PoisonPermit)[] | undefined;
  assetProfiles: RawAssetProfile[];
  archived?: boolean;
};
export const useCodeSearchForm = ({
  licenses,
  assetProfiles,
  archived = false,
}: Props) => {
  const [query, setQuery] = useState('');

  const [filterItems, setFilterItems] = useState<FilterItem[]>([
    {
      filterName: 'permitNumber',
      selectedItem: ALL_PERMIT.value,
      items: [{...ALL_PERMIT}],
    },
    {
      filterName: 'assetName',
      selectedItem: ALL_ASSET_PROFILE.value,
      items: [{...ALL_ASSET_PROFILE}],
    },
  ]);

  const [sortItem, setSortItem] = useState<SortItem>({
    selectedItem: 'Date Created',
    items: SORT_BY,
  });

  const [selectedPermit, setSelectedPermit] = useState<OdcPermit | null>(null);

  useEffect(() => {
    const permits = filterPermits('ODC', licenses)?.map((permit) => ({
      name: permit.permitNumber,
      value: permit.permitId,
    }));
    if (permits.length > 0) {
      setFilterItems((prev) =>
        prev.map((filterItem) => {
          if (filterItem.filterName === 'permitNumber') {
            return {...filterItem, items: [{...ALL_PERMIT}, ...permits]};
          }
          return filterItem;
        }),
      );
    }
  }, [licenses]);

  useEffect(() => {
    const assets = assetProfiles
      .filter((asset) => (archived ? true : !asset.details.archived))
      .map((asset) => ({
        name: asset.details.assetName,
        value: asset.details.assetName,
      }));
    if (assetProfiles?.length > 0) {
      setFilterItems((prev) =>
        prev.map((filterItem) => {
          if (filterItem.filterName === 'assetName') {
            return {...filterItem, items: [{...ALL_ASSET_PROFILE}, ...assets]};
          }
          return filterItem;
        }),
      );
    }
  }, [assetProfiles, archived]);

  const handleFilterChange = useCallback(
    (filterName: FilterName, value: string) => {
      if (filterName === 'permitNumber') {
        const permit = filterPermits('ODC', licenses)?.find(
          (permit) => permit.permitId === value,
        );
        if (permit) {
          setSelectedPermit(permit);
        } else {
          setSelectedPermit(null);
        }
      }
      setFilterItems((prev) =>
        prev.map((filterItem) =>
          filterItem.filterName === filterName
            ? {...filterItem, selectedItem: value}
            : filterItem,
        ),
      );
    },
    [licenses],
  );

  const handleSortChange = useCallback((value: SortBy) => {
    setSortItem((prev) => ({...prev, selectedItem: value}));
  }, []);

  return {
    query,
    setQuery,
    sortItem,
    filterItems,
    handleFilterChange,
    handleSortChange,
    selectedPermit,
  };
};

export const getCodesFilterFunction =
  (
    query: string,
    permits: Array<OdcPermit>,
    filterItems: FilterItem[],
  ): CodeQueryFunction =>
  (codeEntry) => {
    const permitNumber =
      getPermitFromCode(permits, codeEntry)?.permitNumber ??
      codeEntry.details.assetProfile.mainDisplay.permitNumber;
    const permitId =
      getPermitFromCode(permits, codeEntry)?.permitId ??
      codeEntry.details.assetProfile.mainDisplay.permitID;
    return (
      (codeEntry.details.assetProfile.assetName
        .toLocaleLowerCase()
        .includes(query.toLocaleLowerCase()) ||
        permitNumber.toLocaleLowerCase().includes(query.toLocaleLowerCase())) &&
      filterItems.every((filter) => {
        if (filter.selectedItem === SELECT_ALL) {
          return true;
        } else if (filter.filterName === 'permitNumber') {
          return permitId === filter.selectedItem;
        } else if (filter.filterName === 'assetName') {
          return (
            filter.selectedItem === codeEntry.details.assetProfile.assetName
          );
        }
        return [];
      })
    );
  };

export const getCodesCompareFuction =
  (sortBy: SortBy, permits: Array<OdcPermit>): CodeCompareFunction =>
  (a, b) => {
    if (sortBy === 'Date Created') {
      return moment(a.createdAt).diff(moment(b.createdAt), 'milliseconds');
    } else if (sortBy === 'Asset Profile') {
      return a.details.assetProfile.assetName.localeCompare(
        b.details.assetProfile.assetName,
      );
    } else if (sortBy === 'Generated Codes') {
      return a.details.codes.length - b.details.codes.length;
    } else if (sortBy === 'ODC Permit') {
      const permitNumberA =
        getPermitFromCode(permits, a)?.permitNumber ??
        a.details.assetProfile.mainDisplay.permitNumber;
      const permitNumberB =
        getPermitFromCode(permits, b)?.permitNumber ??
        a.details.assetProfile.mainDisplay.permitNumber;
      return permitNumberA.localeCompare(permitNumberB);
    }
    return 0;
  };

const getPermitFromCode = (
  permits: Array<OdcPermit>,
  codeEntry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>,
) => {
  return permits.find(
    (permit) => permit.permitId === codeEntry.details.permitId,
  );
};
