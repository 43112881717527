import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const SearchBar = ({
  query,
  setQuery,
  label,
}) => (
  <FormControl fullWidth variant="outlined">
    <OutlinedInput
      value={query}
      id="searchBar"
      placeholder={label}
      onChange={e => setQuery(e.target.value)}
      notched={false}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon style={{marginLeft: 5, color: "#d9d9d9"}} />
        </InputAdornment> 
      }
      labelWidth={60}
    />
  </FormControl>
);

export default SearchBar;
