import React, {useCallback, useEffect} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import {useHistory, useLocation} from 'react-router-dom';
import {getQrcode} from '../../store/auth/actions';
import Qrcode from 'qrcode.react';
import {
  Container,
  LoginBox,
  LeftSection,
  ContactBox,
  QRcodeBox,
  Line,
  MailContainer,
  PhoneContainer,
  LoginSteps,
  BottomSection,
  RightSection,
} from './styles';
import {Header2, Paragraph, HyperLink} from '../../presentation/word';

import IconMail from './renderMailIcon';
import IconPhone from './renderPhoneIcon';
import IconSetting from '../../img/IconSetting.png';
import './styles.css';
import Header from '../../presentation/header';
import Footer from '../../presentation/footer';
import CONSTANT from '../../config/constant';
import COLOR from '../../styled/colors';

import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {WEB_ERROR} from '../../utils/error';

type Props = {};

const QRlogin: React.FC<Props> = (_) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const walletLoginId = useAppSelector((state) => state.auth.walletLoginId);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const {state} = useLocation<{
    qrLoginFlag?: boolean;
  }>();
  const checkOnline = useCallback((error) => {
    //in case internet disconnected, refresh QR
    if (navigator.onLine) {
      dispatch(toggleModalOff());
      dispatch(getQrcode(history, checkOnline));
    } else {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error?.title ? error.title : WEB_ERROR.OFFLINE_ERROR.title,
          subtitle: error?.title
            ? error.message
            : WEB_ERROR.OFFLINE_ERROR.details,
          button: 'Reload',
          CTAHandler: () => {
            window.location.reload();
          },
        }),
      );
    }
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(getQrcode(history));
    if (!isLoggedIn) {
      window.addEventListener('online', checkOnline);
      window.addEventListener('offline', checkOnline);
    }
  }, [checkOnline, dispatch, history, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !state.qrLoginFlag) {
      // fromAuth is used to determine whether to pop up the greeting modal upon login
      // Please refer to home/index.tsx for more information
      history.push({
        pathname: '/private',
        state: {fromAuth: true},
      });
    }
  }, [history, state.qrLoginFlag, isLoggedIn]);

  return (
    <div>
      <Header />
      <Container>
        <LoginBox>
          <LeftSection>
            <Header2 pt={'72px'}>Web Portal Login</Header2>
            <LoginSteps>
              <Paragraph fontWeight={300}>
                1. Open Aglive app on your mobile device
              </Paragraph>
              <Paragraph fontWeight={300}>
                2. Tap on <strong>Setting</strong>
                <img
                  src={IconSetting}
                  alt=""
                  style={{position: 'relative', top: '8px'}}
                />{' '}
                and select <strong>Connect to Aglive Web Portal</strong>
              </Paragraph>
              <Paragraph fontWeight={300}>
                3. Use your mobile device to scan the QR code
              </Paragraph>
            </LoginSteps>
            <Line mt={'50px'} />
            <BottomSection>
              <Paragraph fontWeight={700}>
                Don't have the App? Contact us:
              </Paragraph>
              <ContactBox>
                <MailContainer>
                  <IconMail />
                  <HyperLink href="mailto:sales@galive.com" invert={true}>
                    sales@aglive.com
                  </HyperLink>
                </MailContainer>
                <PhoneContainer>
                  <IconPhone />
                  +61 1300 893 531
                </PhoneContainer>
              </ContactBox>
            </BottomSection>
          </LeftSection>
          <RightSection>
            <Paragraph color={COLOR.BLACK_BG} margin={'72px 0 0 0'}>
              Open the Aglive web portal by scanning this code with your
              registered mobile device
            </Paragraph>
            <Line mt={'25px'} />
            <QRcodeBox>
              {walletLoginId && (
                <Qrcode
                  value={`${CONSTANT.WEB_LOGIN_PREFIX_}${walletLoginId}`}
                  className="QrcodeSize"
                />
              )}
            </QRcodeBox>
            <Paragraph
              color={COLOR.BLACK_BG}
              fontWeight={700}
              margin={'50px 0 0 0'}>
              We are serious about protecting your security
            </Paragraph>
          </RightSection>
        </LoginBox>
      </Container>
      <Footer />
    </div>
  );
};

export default QRlogin;
