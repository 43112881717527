import React, {
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  useRef,
} from 'react';
import COLOR from '../../styled/colors';
import {useHistory, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import {
  makeStyles,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  MuiThemeProvider,
} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';
import MyButton from '../../presentation/button';
import CONSTANT, {updateByCSV} from '../../config/constant';
import moment from 'moment';
import CSVUploader from '../../presentation/CSVUploader';
import {HyperLink} from '../../presentation/word';
import {fetchAllAssets} from '../../store/activity/actions';
import {SelectionState} from '@devexpress/dx-react-grid';
import {
  Table,
  TableHeaderRow,
  TableSelection,
  Grid as TableGrid,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  IntegratedSelection,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import API from '../../config/api';
import FilterList from '@material-ui/icons/FilterList';
import {
  validateWithSchema,
  JSONSchemaValidationError,
} from '../../utils/csvReader';
import {Buttons} from '../../presentation/ButtonsGroup';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {
  TableComponent,
  PagingComponent,
  HeaderComponent,
} from '../consignment/ViewConsignment';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {groupSchema, groupIdSchema} from '../../config/schema';
import {ValidationErrorType, NetworkError} from '../../utils/error';
import {AssetDialogMessage, cleanSelectedFile} from '../asset/Components';
import {createTokensByCSV, manageAssetByCSV} from '../../store/asset/actions';
import {
  createGroup,
  updateGroupItem,
  updateGroupStore,
} from '../../store/group/actions';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  buttonContainer: {
    justifyContent: 'end',
    marginTop: 50,
  },
  label: {
    fontWeight: 700,
    marginBottom: 10,
  },
  fieldContainer: {
    marginBottom: 0,
  },
  dividerStyle: {
    width: '100%',
    marginTop: 10,
    opacity: 0.5,
    marginBottom: 0,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  filterColor: {backgroundColor: COLOR.WHITE},
  /*checkbox: {
    '&.MuiCheckbox-root': {
      '&.MuiCheckbox-colorPrimary': {
        color: COLOR.GREEN_BUTTON,
      },
    },
  },*/
}));

const initialState = {
  groupName: '',
  selectAnimalsRadio: 'Upload CSV File',
  csvData: {} as File,
  csvAsset: [],
  animalList: [],
  currentPage: 0,
  pageSize: 10,
  animalSelectedIndex: [] /**can only save index on current page */,
  selectedAnimalInfo: [] /**save all selected animal info */,
  totalData: 0,
  showFilters: false,
  rfidFilter: '',
  nlisidFilter: '',
  pic: '',
  dialogOpen: false,
  error: {
    groupName: false,
  },
  validationResult: {
    type: null as string | null,
    validationError: [] as Array<JSONSchemaValidationError>,
  },
};

type Action =
  | {type: 'change/groupName'; groupName: string}
  | {type: 'change/pic'; pic: string}
  | {type: 'change/selectAnimalsRadio'; selectAnimalsRadio: string}
  | {
      type: 'change/csvData';
      csvData: File;
    }
  | {type: 'change/csvAsset'; csvAsset: Array<any>}
  | {
      type: 'fetch/animalList';
      animalList: Array<{
        identifier: string;
        rfid?: string;
        nlis_id?: string;
        agliveToken?: string;
      }>;
      totalData: number;
    }
  | {type: 'change/page'; currentPage: number}
  | {type: 'change/pageSize'; pageSize: number}
  | {type: 'change/animalSelectedIndex'; animalSelectedIndex: Array<number>}
  | {
      type: 'change/selectedAnimalInfo';
      selectedAnimalInfo: Array<{
        rfid?: string;
        nlis_id?: string;
        agliveToken?: string;
      }>;
    }
  | {type: 'change/showFilters'; showFilters: boolean}
  | {type: 'update/pic'; pic: string}
  | {type: 'update/filters'; filterType: string; data: string}
  | {type: 'toggle/dialog'}
  | {
      type: 'update/validationResult';
      validationResult: typeof initialState['validationResult'];
    }
  | {type: 'change/errorMessage'; error: typeof initialState['error']};

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    case 'update/filters':
      return {...prevState, [action.filterType]: action.data};
    case 'toggle/dialog':
      return {...prevState, dialogOpen: !prevState.dialogOpen};
    default:
      return {...prevState, ...actionData};
  }
};

const Group: React.FC<{type?: 'add' | 'remove'}> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {gid} = useParams<{gid: string}>();
  const dispatch = useAppDispatch();

  const timerFilter = useRef(null);
  const groupData = useAppSelector((state) => state.group.groupData);
  const groupList = useMemo(() => {
    return groupData.map((g) => g.name);
  }, [groupData]);

  const currentGroup = useMemo(() => {
    if (!gid) {
      return undefined;
    }
    return groupData.find((g) => g.agliveToken === gid);
  }, [groupData, gid]);

  const [state, localDispatch] = useReducer(
    reducer,
    currentGroup
      ? {
          ...initialState,
          pic: currentGroup.pic_id,
          groupName: currentGroup.name,
        }
      : initialState,
  );

  const businessProfileData = useAppSelector(
    (state) => state.user.businessProfileData,
  );

  const userRole = useAppSelector((state) => state.user.userProfileData.role)
  const locationUser = userRole && userRole.includes('location-')
        ? businessProfileData.location?.find(
            (location) =>
              location.locationUniqueId === userRole.replace('location-','')
          )
          || null
        : false;

  const picList = useMemo(() => {
    return locationUser === false 
      ? businessProfileData.location?.map((loc) => loc.PICAddress) 
      : (locationUser
          ? [locationUser].map((loc) => loc.PICAddress) 
          : []
        );
  }, [businessProfileData.location]);
  const businessId = useAppSelector(
    (state) => state.user.userProfileData.businessId,
  );

  const columns = [
    {name: 'identifier', title: 'ID/RFID'},
    {name: 'nlis', title: 'NLIS ID (If applicable)'},
  ];

  /** fetch all individual animals */
  const fetchAssets = useCallback(
    async (
      picId = state.pic,
      idFilter = state.rfidFilter,
      nlisFilter = state.nlisidFilter,
      currentPage = state.currentPage,
      itemPerPage = state.pageSize,
      selectedAnimalInfo?: Array<any>,
      updateFilters?: boolean,
    ) => {
      const rfidFilter =
        idFilter && /\d{3} \d{12}/.test(idFilter) ? idFilter : '';
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: [
          businessProfileData.industryType === 'ANIMALS' ? CONSTANT.ASSETTYPE.ASSET
          : businessProfileData.industryType === 'PLANTS' ? CONSTANT.ASSETTYPE.PLANT 
          : CONSTANT.ASSETTYPE.PRODUCT],
        activityType: [],
        businessId: [businessId],
        page: currentPage + 1,
        itemCountPerPage: itemPerPage,
        filter: {
          pic_id: picId,
          rfids: rfidFilter ? [rfidFilter] : [],
          nlis_ids: nlisFilter ? [nlisFilter] : [],
          agliveTokens: !rfidFilter && idFilter ? [idFilter] : [],
        },
      };
      dispatch({type: SPINNER_TOGGLE_ON});
      let list = [];
      let totalRows = 0;
      if (props.type === 'remove') {
        list = currentGroup?.animalsItems
          .filter((itm) => {
            if (!idFilter && !nlisFilter) return true;
            return (
              (rfidFilter && itm.rfid === rfidFilter) ||
              (nlisFilter && itm.nlis === nlisFilter) ||
              (!rfidFilter && idFilter && itm.agliveToken === idFilter)
            );
          })
          .map((itm) => {
            return {...itm, identifier: itm.rfid ?? itm.agliveToken};
          });
        totalRows = Number(currentGroup?.animalsItemsCount);
      } else {
        const response = await fetchAllAssets({
          url: API.POST.getTokenByBusiness,
          method: 'POST',
          data: data,
        });
        const existingRfids = currentGroup?.animalsItems.map(
          (itm) => itm.rfid || itm.agliveToken,
        );
        response.data?.forEach((res) => {
          if (res.type === CONSTANT.ASSETTYPE.ASSET && (
            (res.details.externalIds.rfid &&
              (!existingRfids ||
                !existingRfids.includes(res.details.externalIds.rfid))) ||
            (!res.details.externalIds.rfid &&
              res.details.externalIds.agliveToken &&
              (!existingRfids ||
                !existingRfids.includes(res.details.externalIds.agliveToken))))
          ) {
            list.push({
              identifier:
                res.details.externalIds.rfid ??
                res.details.externalIds.agliveToken,
              rfid: res.details.externalIds.rfid,
              agliveToken: res.details.externalIds.agliveToken,
              nlis: res.details.externalIds.nlis_id ?? ''
            });
          } else if (res.type !== CONSTANT.ASSETTYPE.ASSET && (!existingRfids || !existingRfids.includes(res.externalIds[0].agliveToken))) {
            list.push({
              identifier: res.externalIds[0].agliveToken,
              rfid: '',
              agliveToken: res.externalIds[0].agliveToken,
              nlis: '',
            });
          }
        });
        totalRows = Number(response.headers.total_row);
      }
      localDispatch({
        type: 'fetch/animalList',
        animalList: list,
        totalData: totalRows,
      });
      dispatch({type: SPINNER_TOGGLE_OFF});
      /**fetch Selected Animal Index From rfid array*/
      if (updateFilters) return;
      const currentPageIndex = [];
      const rfidList = selectedAnimalInfo
        ? selectedAnimalInfo.map((item) => item.rfid)
        : state.selectedAnimalInfo.map((item) => item.rfid);

      for (let i = 0; i < itemPerPage; i++) {
        if (list[i]?.rfid && rfidList.includes(list[i]?.rfid)) {
          currentPageIndex.push(i);
        }
      }
      localDispatch({
        type: 'change/animalSelectedIndex',
        animalSelectedIndex: currentPageIndex,
      });
    },
    [
      businessId,
      state.currentPage,
      state.pageSize,
      state.selectedAnimalInfo,
      currentGroup,
      props.type,
    ],
  );

  const changePage = useCallback(
    (pageNum: number) => {
      localDispatch({type: 'change/page', currentPage: pageNum});
      fetchAssets(
        state.pic,
        state.rfidFilter,
        state.nlisidFilter,
        pageNum,
        state.pageSize,
      );
    },
    [
      state.pageSize,
      fetchAssets,
      state.rfidFilter,
      state.nlisidFilter,
      state.pic,
    ],
  );
  const changePageSize = useCallback(
    (size: number) => {
      localDispatch({type: 'change/pageSize', pageSize: size});
      localDispatch({type: 'change/page', currentPage: 0});
      fetchAssets(state.pic, state.rfidFilter, state.nlisidFilter, 0, size);
    },
    [fetchAssets, state.rfidFilter, state.nlisidFilter, state.pic],
  );
  /**when select animal, add or remove animal in selectedAnimalInfo */
  const selectAnimals = useCallback(
    (index: Array<number>) => {
      if (state.animalSelectedIndex.length > index.length) {
        let diff = state.animalSelectedIndex.filter(
          (item) => !index.includes(item),
        )[0];
        const newArray = state.selectedAnimalInfo.filter(
          (item) => item.identifier !== state.animalList[diff].identifier,
        );
        localDispatch({
          type: 'change/selectedAnimalInfo',
          selectedAnimalInfo: newArray,
        });
      } else {
        let diff = index.filter(
          (item) => !state.animalSelectedIndex.includes(item),
        )[0];
        localDispatch({
          type: 'change/selectedAnimalInfo',
          selectedAnimalInfo: [
            ...state.selectedAnimalInfo,
            state.animalList[diff],
          ],
        });
      }
      localDispatch({
        type: 'change/animalSelectedIndex',
        animalSelectedIndex: index,
      });
    },
    [state.animalSelectedIndex, state.selectedAnimalInfo, state.animalList],
  );
  const changeShowFilter = useCallback(() => {
    localDispatch({
      type: 'change/showFilters',
      showFilters: !state.showFilters,
    });
  }, [state.showFilters]);

  const handleSelectAnimals = useCallback(
    (type: string) => (e) => {
      localDispatch({
        type: 'change/selectAnimalsRadio',
        selectAnimalsRadio: e.target.value as
          | 'Upload CSV File'
          | 'Individual Item/Animal Selection',
      });
    },
    [],
  );
  /**individual animal filter  */
  const updateAnimalFilter = useCallback(
    (rfidFilter: string, nlisFilter) => {
      fetchAssets(
        state.pic,
        rfidFilter,
        nlisFilter,
        state.currentPage,
        state.pageSize,
        [],
        true,
      );
      localDispatch({
        type: 'change/animalSelectedIndex',
        animalSelectedIndex: [],
      });
      localDispatch({
        type: 'change/selectedAnimalInfo',
        selectedAnimalInfo: [],
      });
    },
    [state.currentPage, state.pageSize, fetchAssets, state.pic],
  );

  const handleGroupNameChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'change/groupName',
        groupName: e.target.value,
      });
      if (groupList?.length && groupList.includes(e.target.value)) {
        localDispatch({
          type: 'change/errorMessage',
          error: {groupName: true},
        });
      } else if (state.error.groupName) {
        localDispatch({
          type: 'change/errorMessage',
          error: {groupName: false},
        });
      }
    },
    [state.error.groupName, groupList],
  );

  const handlePICChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'change/pic',
        pic: e.target.value,
      });
      fetchAssets(e.target.value);
    },
    [fetchAssets],
  );

  const handleFilterChange = useCallback(
    (type: string) => (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'update/filters',
        filterType: type,
        data: e.target.value,
      });
      const filterObj = {
        rfidFilter: state.rfidFilter,
        nlisidFilter: state.nlisidFilter,
        [type]: e.target.value,
      };

      clearTimeout(timerFilter.current);
      timerFilter.current = setTimeout(() => {
        updateAnimalFilter(filterObj.rfidFilter, filterObj.nlisidFilter);
        clearTimeout(timerFilter.current);
        timerFilter.current = null;
      }, 1000);
    },
    [updateAnimalFilter, state.rfidFilter, state.nlisidFilter],
  );

  /**Handle csv and file uploads*/
  const uploadFileHandler = useCallback((file) => {
    localDispatch({
      type: 'change/csvData',
      csvData: file,
    });
  }, []);
  /** handle csv asset */
  const handleCsvAsset = useCallback((e) => {
    const rfidList = e.map((item) => ({rfid: item.rfid, id: item.id}));
    localDispatch({
      type: 'change/csvAsset',
      csvAsset: rfidList,
    });
  }, []);

  const handleCsvError = useCallback((error) => {
    cleanSelectedFile('csvReader');
    uploadFileHandler(null);
    if (error instanceof NetworkError) {
      const isTable =
        Array.isArray(error.error) &&
        (error.error[0].source?.rows?.length ||
          error.error[0].source?.data?.length);

      if (isTable) {
        const errorList: Array<{row: number; message: any}> = [];
        if (error.error[0].source?.rows?.length) {
          // kept the old code for backwards compatibility
          error.error[0].source.rows.forEach((num: number) => {
            errorList.push({
              row: num + 1,
              message: error.error[0].details,
            });
          });
        } else {
          error.error[0].source.data.forEach(
            ({error, row}: {error: Array<any>; row: any}) => {
              errorList.push({
                row,
                message: error[0],
              });
            },
          );
        }
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            errorInfo: errorList,
            renderButton: (
              <Buttons
                leftButtonTitle="Cancel"
                rightButtonTitle="Upload Csv Again"
                leftButtonOnClick={() => {
                  dispatch(toggleModalOff());
                  cleanSelectedFile('csvReader');
                  uploadFileHandler(null);
                  handleCsvAsset([]);
                }}
                rightButtonOnClick={() => {
                  dispatch(toggleModalOff());
                  cleanSelectedFile('csvReader');
                  uploadFileHandler(null);
                  handleCsvAsset([]);
                }}
              />
            ),
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
        console.error(
          'Error.error undefined. Modal will display fallback message',
        );
      }
    } else {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    }
  }, []);

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        className={classes.bodyContainer}>
        <Grid container item direction="column">
          <Grid
            container
            item
            direction="row"
            style={{marginBottom: 20}}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}>
            <Grid item xs={6} className={classes.fieldContainer}>
              <Typography variant="h6" role="label" className={classes.label}>
                Group/Mob Name
              </Typography>
              {props.type ? (
                <>
                  <Typography variant="h6" role="label">
                    {currentGroup?.name}
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                </>
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  id="groupName"
                  value={state.groupName}
                  onChange={handleGroupNameChange}
                  error={state.error.groupName}
                  helperText={
                    state.error.groupName
                      ? 'This group/mob name already exists'
                      : ''
                  }
                />
              )}
            </Grid>
            <Grid item xs={6} className={classes.fieldContainer}>
              <Typography variant="h6" role="label" className={classes.label}>
                Location e.g. PIC
              </Typography>
              {props.type ? (
                <>
                  <Typography variant="h6" role="label">
                    {currentGroup?.pic_id}
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                </>
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="envd-form-type-select">
                    {'Please select'}
                  </InputLabel>
                  <Select
                    labelId="report-type-select"
                    label="Please select"
                    id="groupPic"
                    value={state.pic}
                    onChange={handlePICChange}>
                    {picList.map((pic, index) => (
                      <MenuItem value={pic} key={index} id={pic}>
                        {pic}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {!!props.type && (
              <>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography
                    variant="h6"
                    role="label"
                    className={classes.label}>
                    Items/Animals
                  </Typography>
                  <Typography variant="h6" role="label">
                    {currentGroup?.animalsItemsCount}
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                </Grid>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Typography
                    variant="h6"
                    role="label"
                    className={classes.label}>
                    Date Created
                  </Typography>
                  <Typography variant="h6" role="label">
                    {currentGroup?.createdAt
                      ? moment(currentGroup.createdAt).format('DD/MM/YYYY')
                      : ''}
                  </Typography>
                  <Divider className={classes.dividerStyle} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} direction="row">
            <Typography
              variant="h6"
              role="label"
              style={{fontWeight: 700, marginBottom: 10}}>
              Select Items/Animals
            </Typography>

            <RadioGroup
              aria-label="item"
              name="item"
              value={state.selectAnimalsRadio}
              onChange={handleSelectAnimals('selectAnimalsRadio')}>
              <FormControlLabel
                id={'uploadCSVFile'}
                value="Upload CSV File"
                control={<Radio color="primary" />}
                label="Upload CSV File"
              />
              {state.selectAnimalsRadio === 'Upload CSV File' && (
                <>
                  <CSVUploader
                    file={state.csvData}
                    setFile={uploadFileHandler}
                    setCSVData={handleCsvAsset}
                    useHeader={false}
                    containerStyle={{marginTop: 0}}
                    inputSizeColumn={6}
                    columnSpacing={5}
                    id={'uploadCSV'}
                  />
                  <Grid
                    container
                    justifyContent="flex-end"
                    spacing={5}
                    style={{marginTop: 10, marginBottom: 20}}>
                    <Grid item xs={6}>
                      <HyperLink href={businessProfileData.industryType === 'ANIMALS' ? CONSTANT.TEMPLATE.GROUP.TEMP : CONSTANT.TEMPLATE.GROUP.TEMP2}>
                        Download CSV Template
                      </HyperLink>
                      <HyperLink
                        style={{marginLeft: 10}}
                        href={businessProfileData.industryType === 'ANIMALS' ? CONSTANT.TEMPLATE.GROUP.EXAMPLE : CONSTANT.TEMPLATE.GROUP.EXAMPLE2}>
                        Download Sample CSV
                      </HyperLink>
                    </Grid>
                  </Grid>
                </>
              )}
              <FormControlLabel
                value="Individual Item/Animal Selection"
                control={<Radio color="primary" />}
                label="Individual Item/Animal Selection"
                id={'individualAnimalSelection'}
              />
              {state.selectAnimalsRadio ===
                'Individual Item/Animal Selection' && (
                <Grid container style={{marginTop: 20, marginLeft: 35}}>
                  <Box
                    id={'viewAttachField'}
                    p={'40px 15px 30px 15px'}
                    ml={5}
                    style={{
                      marginLeft: 0,
                      backgroundColor: COLOR.GRAY_SOLID,
                    }}>
                    <div className={classes.filterButton}>
                      <FilterList className={classes.filterIcon} />
                      <Button id={'filterButton'} onClick={changeShowFilter}>
                        <span
                          style={{
                            color: COLOR.GREENT_TEXT,
                            textTransform: 'capitalize',
                          }}>
                          <u>{'Filter'}</u>
                        </span>
                      </Button>
                    </div>
                    {state.showFilters && (
                      <Grid
                        container
                        direction="row"
                        style={{marginBottom: 20}}>
                        <Grid item xs={3} style={{marginRight: 50}}>
                          <Typography
                            variant="h6"
                            role="label"
                            style={{fontWeight: 700}}>
                            ID/RFID
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={state.rfidFilter}
                            onChange={handleFilterChange('rfidFilter')}
                            className={classes.filterColor}
                            placeholder="XXX XXXXXXXXXXXX"
                            id={'rfidFilter'}
                          />
                        </Grid>
                        {businessProfileData.industryType === 'ANIMALS' && 
                        <Grid item xs={3} style={{marginRight: 50}}>
                          <Typography
                            variant="h6"
                            role="label"
                            style={{fontWeight: 700}}>
                            NLIS ID
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={state.nlisidFilter}
                            onChange={handleFilterChange('nlisidFilter')}
                            className={classes.filterColor}
                            placeholder="XXXXXXXXXXXXXXXX"
                          />
                        </Grid>}
                      </Grid>
                    )}

                    <TableGrid rows={state.animalList} columns={columns}>
                      <SelectionState
                        selection={state.animalSelectedIndex}
                        onSelectionChange={selectAnimals}
                      />
                      <IntegratedSelection />
                      <PagingState
                        currentPage={state.currentPage}
                        onCurrentPageChange={changePage}
                        pageSize={state.pageSize}
                        onPageSizeChange={changePageSize}
                      />
                      <CustomPaging totalCount={state.totalData} />
                      {/* <IntegratedPaging /> */}
                      <Table tableComponent={TableComponent} />

                      <TableHeaderRow contentComponent={HeaderComponent} />
                      <TableSelection cellComponent={cellComponent} />
                      <PagingPanel
                        pageSizes={[5, 10, 15, 20]}
                        containerComponent={PagingComponent}
                      />
                    </TableGrid>
                  </Box>
                </Grid>
              )}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.buttonContainer}>
          <MyButton
            text={'Save'}
            variant="contained"
            width={280}
            fontSize={18}
            disabled={
              (!props.type &&
                (!state.groupName || !state.pic || state.error.groupName)) ||
              !(
                (state.selectAnimalsRadio === 'Upload CSV File' &&
                  state.csvData?.name) ||
                (state.selectAnimalsRadio ===
                  'Individual Item/Animal Selection' &&
                  state.selectedAnimalInfo.length > 0)
              )
            }
            onClick={() => {
              if (state.selectAnimalsRadio === 'Upload CSV File') {
                if(state.csvAsset.length <= 0){
                  dispatch(
                    toggleModal({
                      status: 'failed',
                      title: 'CSV has no record(s) row',
                      subtitle: 'Please upload a CSV with record(s) row',
                    }),
                  );
                } else {
                  validateWithSchema(state.csvAsset, businessProfileData.industryType === 'ANIMALS' ? groupSchema : groupIdSchema, false, [])
                  .then(() => {
                    //reset if have previous errors
                    localDispatch({
                      type: 'update/validationResult',
                      validationResult: {
                        type: null,
                        validationError: [],
                      },
                    });
                  })
                  .catch((error) => {
                    const e = error as ValidationErrorType<
                      Array<JSONSchemaValidationError>
                    >;
                    localDispatch({
                      type: 'update/validationResult',
                      validationResult: {
                        type: e.type,
                        validationError: e.error ?? [],
                      },
                    });
                  })
                  .finally(() => {
                    localDispatch({type: 'toggle/dialog'});
                  });
                }         
              } else {
                const tokenList = state.selectedAnimalInfo.map((info) => {
                  const {identifier, ...remain} = info;
                  return remain;
                });
                if (!props.type) {
                  dispatch(
                    createGroup(
                      state.groupName,
                      tokenList,
                      state.pic,
                      businessProfileData.industryType,
                      history.goBack,
                    ),
                  );
                } else {
                  //add/remove
                  dispatch(
                    updateGroupItem(
                      props.type,
                      gid,
                      tokenList,
                      state.pic,
                      businessProfileData.industryType,
                      history.goBack,
                    ),
                  );
                }
              }
            }}
          />
        </Grid>
      </Grid>
      {state.dialogOpen && (
        <AssetDialogMessage
          open={state.dialogOpen}
          isErr={state.validationResult.type ? true : false}
          fileName={state.csvData?.name}
          handleClose={() => {
            cleanSelectedFile('csvReader');
            uploadFileHandler(null);
            handleCsvAsset([]);
            localDispatch({
              type: 'update/validationResult',
              validationResult: {
                type: null,
                validationError: [],
              },
            });
            localDispatch({type: 'toggle/dialog'});
          }}
          validationResult={state.validationResult}
          rightButtonClick={() => {
            localDispatch({type: 'toggle/dialog'});
            try {
              if (props.type) {
                dispatch(
                  manageAssetByCSV(
                    {
                      template: CONSTANT.TEMPLATE.GROUP.VALUE,
                      group_id: gid,
                      externalIds: businessProfileData.industryType === 'ANIMALS' ? 'rfid' : 'id',
                      blockchain: 'true',
                      activityType:
                        props.type === 'add'
                          ? updateByCSV.addGroupItem
                          : updateByCSV.removeGroupItem,
                      file: state.csvData,
                      csvData: state.csvAsset,
                    },
                    true,
                  ),
                )
                  .then(() => {
                    cleanSelectedFile('csvReader');
                    uploadFileHandler(null);
                    dispatch(
                      updateGroupStore(
                        gid,
                        JSON.parse(JSON.stringify(groupData)),
                        state.csvAsset,
                        props.type,
                      ),
                    );
                    history.goBack();
                  })
                  .catch((e) => {
                    handleCsvError(e);
                  });
              } else {
                dispatch(
                  createTokensByCSV(
                    {
                      template: CONSTANT.TEMPLATE.GROUP.VALUE,
                      //schemaType: groupSchema.id,
                      pic_id: state.pic,
                      group_name: state.groupName,
                      type: CONSTANT.ASSETTYPE.GROUP as any,
                      externalIds: businessProfileData.industryType === 'ANIMALS' ? 'rfid' : 'id',
                      uniqueExternalIds: '',
                      blockchain: 'true',
                      file: state.csvData,
                      csvData: state.csvAsset,
                    },
                    history.goBack,
                    undefined,
                    true,
                  ),
                )
                  .then(() => {
                    cleanSelectedFile('csvReader');
                    uploadFileHandler(null);
                  })
                  .catch((e) => {
                    handleCsvError(e);
                  });
              }
            } catch (error) {
              cleanSelectedFile('csvReader');
              uploadFileHandler(null);
            }
          }}
          rightButtonClickIsErr={() => {
            cleanSelectedFile('csvReader');
            uploadFileHandler(null);
            handleCsvAsset([]);
            localDispatch({
              type: 'update/validationResult',
              validationResult: {
                type: null,
                validationError: [],
              },
            });
            localDispatch({type: 'toggle/dialog'});
          }}
          csvData={state.csvAsset}
        />
      )}
    </>
  );
};

export const CreateGroup = withHeader(
  {
    title: 'Create Group/Mob',
    back: true,
  },
  Group,
);

export const AddToGroup = withHeader(
  {
    title: 'Add Items/Animals',
    back: true,
  },
  Group,
);

export const RemoveFromGroup = withHeader(
  {
    title: 'Remove Items/Animals',
    back: true,
  },
  Group,
);

const cellTheme = createTheme({
  palette: {
    secondary: {
      main: COLOR.GREEN,
    },
  },
});

const cellComponent = (props) => (
  <MuiThemeProvider theme={cellTheme}>
    <TableSelection.Cell {...props} />
  </MuiThemeProvider>
);
