// React
import React from 'react';
// Other packages
import moment from 'moment';
// Material UI
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import PrintIcon from '@material-ui/icons/Print';

// Presentation, Container, Style
import MyTable from '../../presentation/Table';

import CONSTANT from '../../config/constant';

// Redux, Types
import {iCodeEntryDocumentResponse} from '../../store/code/types';
import { PlantCodeDocumentDetails } from '../../store/plant/types';
import { useStyles } from '../promotion/PromotionLibrary/styles';
import { useAppDispatch } from '../../utils/hooks';
import { downloadCodeEntryCSV } from '../../store/code/actions';
import { CodeCompareFunction, CodeQueryFunction } from './forms/types';
import { CODE_SEQUENCE_MAX_DIGITS, padZeros } from './utils';

interface Props {
  tableHeader: Array<string>;
  codeList: Array<iCodeEntryDocumentResponse<PlantCodeDocumentDetails & {permitNumber: string}>>;
  query: string | CodeQueryFunction;
  compareFunction?: CodeCompareFunction;
  onPrint?: (arg0: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>) => void;
}

const CodeLibraryTable: React.FC<Props> = ({
  tableHeader,
  codeList,
  query,
  compareFunction,
  onPrint,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  let codes = codeList?.filter((res) => typeof query === 'string' ? res.details.assetProfile.assetName.includes(query) : query(res));
  if (compareFunction) {
    codes.sort(compareFunction);
  }

  return (
    <MyTable
      firstColumnWidth={classes.firstColumnWidth}
      heads={tableHeader}
      rows={codes?.map((entry, i) => [
          entry.details.permitNumber ?? entry.details.assetProfile.mainDisplay.permitNumber,
          entry.details.assetProfile.assetName,
          entry.details.quantity,
          moment(entry.createdAt).format('DD/MM/YYYY'),
          <div style={{display: 'flex', gap: 10}}>
            <GetAppSharpIcon
              id={`Download${i}`}
              style={{cursor: 'pointer'}}
              onClick={() => dispatch(downloadCodeEntryCSV(entry,
                entry.details.codes.map((code, index) => {
                  const strain = entry.details.assetProfile.mainDisplay.strain;
                  const startNumber = entry.details.startNumber;
                  let assetName = strain;
                  if (startNumber && isNaN(startNumber) === false) {
                    const sequenceNumber = padZeros(`${startNumber + index}`, CODE_SEQUENCE_MAX_DIGITS);
                    assetName = `${strain}_${sequenceNumber}`;
                  }
                  return [
                    code,
                    CONSTANT.ASSETTYPE.PLANT,
                    assetName,
                    entry.details.pic_id,
                    entry.details.source.companyName,
                    entry.details.source.creatorEmail,
                  ]
                })))}
            />
            <PrintIcon onClick={() => onPrint(entry)} style={{cursor: 'pointer'}} />
          </div>
        ])}
    />
  );
};

export default CodeLibraryTable;
