export interface ContactInvitationState {
  readonly invitationCode: string;
  readonly category: string;
}

export const SET_INVITATION = 'SET_INVITATION';
export const RESET_INVATITION = 'RESET_INVITATION';

export interface ContactInvitationCode {
  invitationCode: string;
  category: string;
}

export interface SetInvitation {
  type: typeof SET_INVITATION;
  payload: ContactInvitationCode;
}

export interface ResetInvitation {
  type: typeof RESET_INVATITION;
}

export type ContactInvitationActions = SetInvitation | ResetInvitation;

