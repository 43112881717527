import React, {useState} from 'react';

import {
  Typography,
  Grid,
  TextField,
  withStyles,
  createStyles,
  Theme,
  makeStyles,
  Box,
  SvgIconTypeMap,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {LayerNode} from '../../store/site/types';
import {HyperLink} from '../../presentation/word';
import {TreeItem, TreeItemProps, TreeView} from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './SiteLayerTree.css';
import COLOR from '../../styled/colors';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';

type SiteLayerTreeBaseProps = {
  tree: LayerNode;
  addSibling: (arg0: Array<number>) => void;
  changeLayerName: (arg0: string, arg1: Array<number>) => void;
  index: Array<number>;
  allowDelete?: boolean;
};

type SiteLayerTreeProps = SiteLayerTreeBaseProps & {
  addChild: (arg0: Array<number>) => void;
  removeNode: (arg0: Array<number>) => void;
};

type SiteTreeNodeProps = SiteLayerTreeBaseProps & {
  addChild: (arg0: Array<number>, layerId: string) => void;
  removeNode: (arg0: Array<number>, layerId: string) => void;
};

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: 'none',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      outline: 0,
      WebkitTapHighlightColor: 'transparent',
      '&:focus > $content $label': {
        backgroundColor: 'transparent',
      },
      '&$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&$selected:hover > $content $label:hover': {
        backgroundColor: 'red',
      },
      '&$expanded > $content $label': {
        backgroundColor: 'transparent',
      },
      '&$expanded:hover > $content $label': {
        backgroundColor: 'transparent',
      },
      ':hover &$expanded > $content $label': {
        backgroundColor: 'transparent',
      },
      '&$selected > $content $label:hover, &$selected:focus > $content $label':
        {
          backgroundColor: 'transparent',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
    },
    iconContainer: {
      position: 'relative',
      '& .close': {
        opacity: 0.3,
      },
      paddingRight: theme.spacing(0.5),
    },
    group: {
      marginLeft: 7,
      paddingLeft: 9,
      borderLeft: `1px dashed #dedede`,
      minWidth: 200,
    },
    expanded: {
      backgroundColor: 'transparent',
    },
    content: {
      display: 'flex',
      alignItems: 'flex-start',
      margin: 0,
    },
    label: {
      cursor: 'default',
      position: 'relative',
      '&:hover': {
        backgroundColor: 'transparent',
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
)((props: TreeItemProps) => <TreeItem {...props} />);

export const useStyle = makeStyles(() => ({
  labelContainer: {
    width: '92%',
  },
  clickable: {
    pointerEvents: 'auto',
  },
  pointer: {
    cursor: 'pointer',
  },
  layerLabel: {
    fontWeight: 'bold',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  marginLeft: {
    marginLeft: 20,
  },
  linksContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addIcon: {
    paddingRight: 5,
    fontSize: 25,
  },
  addLink: {
    verticalAlign: 'top',
  },
  inputContainer: {
    paddingBottom: 20,
    gap: 10,
  },
  textContainer: {
    flexGrow: 1,
  },
  text: {
    width: '100%',
    margin: 0,
  },
  deleteContainer: {
    cursor: 'pointer',
    width: 'max-content',
    marginLeft: 'auto',
  },
  delete: {
    fontSize: 30,
  },
}));

const ActiveIconStyle: CSSProperties = {
  color: 'black',
  pointerEvents: 'auto',
  fontSize: 28,
};

const InactiveIconStyle: CSSProperties = {
  color: COLOR.GRAY_BORDER,
  pointerEvents: 'none',
  fontSize: 28,
};

function genereteIcon(
  Comp: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
  isActive: boolean,
) {
  return <Comp style={isActive ? ActiveIconStyle : InactiveIconStyle} />;
}

const NestedTreeItems: React.FC<SiteTreeNodeProps> = ({
  tree,
  addSibling,
  addChild,
  removeNode,
  changeLayerName,
  index,
  allowDelete,
}) => {
  const classes = useStyle();

  // Hide delete icon on the first outermost layer is it the only one left
  const showDeleteIcon = allowDelete && (tree.children.length > 1 || index.length > 0);
  return (
    <>
      {tree.children.map((node, nodeIndex) => (
        <StyledTreeItem
          collapseIcon={genereteIcon(ExpandMoreIcon, node.children.length > 0)}
          expandIcon={genereteIcon(ChevronRightIcon, node.children.length > 0)}
          nodeId={node.id}
          key={node.id}
          label={
            <>
              <Grid
                container
                item
                className={`${classes.labelContainer} ${
                  node.children.length > 0 ? classes.clickable : ''
                }`}>
                <Box>
                  <Typography variant="h6" className={classes.layerLabel}>
                    Layer {node.name}
                  </Typography>
                </Box>
                <Box className={classes.linksContainer}>
                  <Grid
                    item
                    className={classes.pointer}
                    onClick={() => addSibling([...index, nodeIndex])}>
                    <AddBoxIcon
                      className={`${classes.addIcon} ${classes.clickable}`}
                    />
                    <HyperLink
                      id={ `NewLayer${node.name}`}
                      onClick={(e: Event) => {
                        e.preventDefault();
                      }}
                      className={`${classes.addLink} ${classes.clickable}`}>
                      Add New Layer
                    </HyperLink>
                  </Grid>
                  <Grid
                    item
                    onClick={() => addChild([...index, nodeIndex], node.id)}
                    className={`${classes.marginLeft} ${classes.pointer}`}>
                    <AddBoxIcon
                      className={`${classes.addIcon} ${classes.clickable}`}
                    />
                    <HyperLink
                      id={ `NewSubLayer${node.name}`}
                      onClick={(e: Event) => {
                        e.preventDefault();
                      }}
                      className={`${classes.addLink} ${classes.clickable}`}>
                      Add New Sublayer
                    </HyperLink>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                container
                className={classes.inputContainer}
                alignItems="center"
                justifyContent="flex-start">
                <Grid item className={classes.textContainer}>
                  <TextField
                    id={`LayerName${node.name.split('.').join("")}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    value={node.layerName}
                    onChange={(e) =>
                      changeLayerName(e.target.value, [...index, nodeIndex])
                    }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: false,
                    }}
                    className={`${classes.text} ${classes.clickable}`}
                  />
                </Grid>
                {showDeleteIcon && (
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.deleteContainer}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeNode([...index, nodeIndex], node.id);
                    }}>
                    <DeleteOutlinedIcon
                      id={`Delete${node.name.split('.').join("")}`}
                      className={`${classes.delete} ${classes.clickable}`}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          }>
          {node.children && (
            <NestedTreeItems
              tree={node}
              addSibling={addSibling}
              addChild={addChild}
              removeNode={removeNode}
              changeLayerName={changeLayerName}
              index={[...index, nodeIndex]}
              allowDelete={allowDelete}
            />
          )}
        </StyledTreeItem>
      ))}
    </>
  );
};

const SiteLayerTree: React.FC<SiteLayerTreeProps> = ({
  tree,
  addSibling,
  addChild,
  removeNode,
  changeLayerName,
  index,
  allowDelete = true,
}) => {
  const [expanded, setExpanded] = useState<string[]>(
    tree.children.map((node) => node.id),
  );
  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleAddChild = (ids: Array<number>, layerId: string) => {
    setExpanded((prev) => (prev.includes(layerId) ? prev : [...prev, layerId]));
    addChild(ids);
  };
  const handleRemoveNode = (ids: Array<number>, layerId: string) => {
    removeNode(ids);
  };

  return (
    <TreeView
      style={{width: '95%', maxWidth: '95%', overflowX: 'auto'}}
      expanded={expanded}
      onNodeToggle={handleToggle}>
      <NestedTreeItems
        tree={tree}
        addSibling={addSibling}
        addChild={handleAddChild}
        removeNode={handleRemoveNode}
        changeLayerName={changeLayerName}
        index={[...index]}
        allowDelete={allowDelete}
      />
    </TreeView>
  );
};

export default SiteLayerTree;
