import React, { useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { TokenService } from '@aglive/data-model';

import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import ViewProductTabDisplay from './ViewProductTabDisplay';
import ViewProductTabContent from './ViewProductTabContent';
import ViewProductTabAttachment from './ViewProductTabAttachment';
import SmallMockupPhone from '../../../presentation/SmallMockupPhone';
import COLOR from '../../../styled/colors';
import MockupPhoneDetails from '../ProductProfile/MockUpPhoneDetails';

import useStyle from '../ProductProfile/styles';

type CreateProductProfileDetailsType = {
  detailsArr: TokenService.ProductToken['details']['pages'];
}

enum TabMap {
  display,
  content,
  attachment,
}

const TabKeys = [
  'Main Display',
  'Additional Content',
  'Attachments'
];

const ViewProductDetails: React.FC<CreateProductProfileDetailsType> = ({
  detailsArr,
}) => {
  const classes = useStyle();
  const [focusedPageIndex, setFocusedPageIndex] = useState(0);
  const [focusedTabIndex, setFocusedTabIndex] = useState(TabMap.display);

  return (
    <>
      <Grid item xs={6} md={3} lg={2} xl={1} container direction="column" className={classes.sidePanelContainer} id="ProductDetailsPages">
        <Typography variant="h6" role="label" style={{fontWeight: 600}}>
          Select page to preview
        </Typography>
        <Grid
          item
          container
          direction="column"
          className={classes.pagesPreviewContainer}>
          <Grid item className={classes.pagesContainer}>
            {detailsArr.map((page, index) => (
              <SmallMockupPhone
                key={index}
                onClick={() => setFocusedPageIndex(index)}
                index={index}
                isFocused={focusedPageIndex === index}
                page={page}
                isShowHistory={false}
                id={`SmallMockupPhone${index}`}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={9} lg={4} xl={3} className={classes.phoneDetailsContainer}>
        <MockupPhoneDetails
          mediaType={detailsArr[focusedPageIndex].display.item}
          mediaUrl={detailsArr[focusedPageIndex].display.url}

          headline1={detailsArr[focusedPageIndex].headline[0]}
          headline2={detailsArr[focusedPageIndex].headline[1]}
          headline3={detailsArr[focusedPageIndex].headline[2]}

          contentArr={detailsArr[focusedPageIndex].content}

          attachmentArr={detailsArr[focusedPageIndex].attachment}
        />
      </Grid>

      <Grid item xs={12} lg={6} xl={8} id="ProductDetailsForm">
        <Tabs
          tabHeaders={TabKeys as string[]}
          value={focusedTabIndex}
          setValue={setFocusedTabIndex}>
          <TabPanel
            isShown={focusedTabIndex === TabMap.display}
            index={TabMap.display}>
            <Box p={4} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 'none', height: 600, overflowY: 'scroll'}}>
              <ViewProductTabDisplay
                mediaOption={detailsArr[focusedPageIndex].display.item}
                mediaUrl={detailsArr[focusedPageIndex].display.url}
                headline1={detailsArr[focusedPageIndex].headline[0]}
                headline2={detailsArr[focusedPageIndex].headline[1]}
                headline3={detailsArr[focusedPageIndex].headline[2]}
              />
            </Box>
          </TabPanel>
          <TabPanel
            isShown={focusedTabIndex === TabMap.content}
            index={TabMap.content}>
            <Box p={4} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 'none', height: 800, overflowY: 'scroll'}}>
              <ViewProductTabContent
                content={detailsArr[focusedPageIndex].content}
              />
            </Box>
          </TabPanel>
          <TabPanel
            isShown={focusedTabIndex === TabMap.attachment}
            index={TabMap.attachment}>
            <Box p={4} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 'none', height: 800, overflowY: 'scroll'}}>
              <ViewProductTabAttachment
                attachment={detailsArr[focusedPageIndex].attachment}
              />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
}

export default ViewProductDetails;
