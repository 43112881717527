import { useCallback, useState } from "react";

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const treeContainer = useCallback<((containerElem: HTMLDivElement) => (void))>((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({
        x: width / 2 || 0,
        y: height / 10 || 0,
      });
    }
  }, []);
  return { translate, treeContainer };
};
