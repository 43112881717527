import { PrintContent } from './../../presentation/print/PrintTemplate';

type GroupToPrint = {
  agliveToken?: string;
  location?: string;
  groupName?: string;
};

export const toPrintContent = (group: GroupToPrint): PrintContent => {
  return {
    qrcode: group.agliveToken,
    descriptions: [
      {
        label: "Location",
        value: group.location
      },
      {
        label: "Group/Mob Name",
        value: group.groupName
      }
    ]
    ,
  };
};