import React, {useEffect, useState} from 'react';
import {Typography, TextField, Grid, makeStyles} from '@material-ui/core';

import COLOR from '../styled/colors';

type TextFieldTypes = {
  title: string;
  content: string;
  limit?: number;
  id?: string;
  onChange: any;
  style?: string;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  helperTextStyle?: string;
  containerStyle?: any;
  limitTextContainerStyle?: any;
  showLimit?: boolean;
  inputProps?: any;
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  errorHelperText: {
    backgroundColor: COLOR.GRAY_SOLID,
    margin: 0,
  },
}));

const TextFieldWithLimit: React.FC<TextFieldTypes> = ({
  title,
  content,
  id,
  limit,
  onChange,
  style,
  multiline,
  rows,
  placeholder,
  error,
  helperText,
  helperTextStyle,
  containerStyle,
  limitTextContainerStyle,
  showLimit,
  inputProps,
  disabled,
}) => {
  const classes = useStyles();
  const [localError, setLocalError] = useState(null);

  useEffect(() => {
    if (localError && limit && content.length < limit) {
      setLocalError(null);
    }
  }, [content]);

  return (
    <Grid item container className={containerStyle} direction="column">
      <Typography variant="h6" style={{fontWeight: 700, marginBottom: '10px'}}>
        {title}
      </Typography>
      <TextField
        id={id}
        placeholder={placeholder}
        value={content}
        variant="outlined"
        className={style}
        multiline={multiline}
        minRows={rows ? rows : 1}
        error={localError || error}
        helperText={
          <Grid
            container
            justifyContent="space-between"
            className={limitTextContainerStyle}>
            <Grid item>{localError || helperText}</Grid>
            {showLimit || showLimit === undefined ? (
              <Grid item>
                {limit && (
                  <Typography variant="body2">
                    {`${content.length}/${limit} characters`}
                  </Typography>
                )}
              </Grid>
            ) : null}
          </Grid>
        }
        FormHelperTextProps={{
          className: helperTextStyle || classes.errorHelperText,
        }}
        onChange={(e) => {
          if (limit && e.target.value.length > limit) {
            onChange(e.target.value.slice(0, limit));
            setLocalError('Exceeds character limit');
          } else {
            onChange(e.target.value);
          }
        }}
        disabled={disabled}
        inputProps={inputProps}
      />
    </Grid>
  );
};

export default TextFieldWithLimit;
