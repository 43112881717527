import {Theme, Tooltip, withStyles} from '@material-ui/core';

export const LightTooltip = withStyles((theme: Theme) => ({
  arrow: {
    '&:before': {
      boxShadow: '0 0 5px rgba(0,0,0,0.2)',
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '1px 1px 10px rgba(0,0,0,0.2)',
    fontSize: '1rem',
    padding: '1rem'
  },
}))(Tooltip);
