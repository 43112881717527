import moment from 'moment';

import {DashboardConfigType, DashboardWidgetType} from '../types';
import {data} from '../utils';
import API from '../../../../config/api';

const dashboardConfig: DashboardConfigType = {
  rows: [
    {
      widgets: [
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'PIC No.',
            key: 'picAddress',
            source: 'location',
            transformData: (dataStore) =>
              (dataStore?.picAddress || []).map((loc) => ({
                value: loc.PICAddress,
                attribute: {
                  label: `${loc.locationNickname} (${loc.PICAddress})`,
                  key: loc.PICAddress,
                },
              })),
          },
        },
        {
          type: DashboardWidgetType.MULTIDROPDOWN,
          payload: {
            title: 'Site Name',
            key: 'siteName',
            source: 'site',
            transformData: (dataStore) => 
              (dataStore?.siteName || []).map((site, index) => ({
                value: site.details.siteName,
                attribute: {
                  label: site.details.siteName,
                  key: site.details.siteName,
                },
              }))
          },
        }
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'Property Type',
            key: 'property',
            source: 'site',
            transformData: (dataStore) =>
              (dataStore?.property
                ? [
                    ...new Set(
                      dataStore.property.map(
                        (site) => site.details.propertyType,
                      ),
                    ),
                  ]
                : []
              )
                .filter((propertyType) => propertyType)
                .map((propertyType) => ({
                  value: propertyType,
                  attribute: {
                    label: propertyType,
                    key: propertyType,
                  },
                })),
          },
        },
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'Region',
            key: 'region',
            source: 'site',
            transformData: (dataStore) =>
              (dataStore?.region
                ? [
                    ...new Set(
                      dataStore.region.map((site) => site.details.region),
                    ),
                  ]
                : []
              )
                .filter((region) => region)
                .map((region) => ({
                  value: region,
                  attribute: {
                    label: region,
                    key: region,
                  },
                })),
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.CARD,
          payload: {
            title: 'Herd',
            subtitle: 'Total Number Inducted',
            icon: 'Cow',
            dependentKey: ['picAddress', 'siteName', 'property', 'region'],
            source: (_, dependencies) => ({
              url: API.GET.getComponentData,
              method: 'GET',
              params: {
                input: {
                  pic_number: dependencies.picAddress.value,
                  property_type: dependencies.property.value,
                  region: dependencies.region.value,
                  site: Array.isArray(dependencies.siteName.value) ? dependencies.siteName.value.map((s) => s.value) : dependencies.siteName.value,
                },
                category: 'widget',
                subcategory: 'warakirri',
                name: 'herdSize',
              },
            }),
            key: 'herdSize',
            transformData: (dataStore) =>
              (dataStore?.herdSize || []).map(({_id, amount}) => [_id, amount]),
          },
        },
        {
          type: DashboardWidgetType.CARD,
          payload: {
            title: 'Weight',
            subtitle: 'Herd Averages',
            icon: 'Scale',
            dependentKey: ['picAddress', 'siteName', 'property', 'region'],
            source: (_, dependencies) => ({
              url: API.GET.getComponentData,
              method: 'GET',
              params: {
                input: {
                  pic_number: dependencies.picAddress.value,
                  property_type: dependencies.property.value,
                  region: dependencies.region.value,
                  site: Array.isArray(dependencies.siteName.value) ? dependencies.siteName.value.map((s) => s.value) : dependencies.siteName.value,
                },
                category: 'widget',
                subcategory: 'warakirri',
                name: 'herdWeight',
              },
            }),
            key: 'herdWeight',
            transformData: (dataStore) => {
              return (dataStore?.herdWeight || []).map(({_id, avg}) => [_id, avg])
            }
          },
        },
        // {
        //   type: DashboardWidgetType.CARD,
        //   payload: {
        //     title: 'Herd Value',
        //     subtitle: 'Total Value',
        //     icon: 'Money',
        //     cardContent: Object.keys(
        //       fetchedAssets.reduce((accum, asset) => {
        //         const sex =
        //           (asset.sex &&
        //             asset.sex?.slice(0, 1).toUpperCase() + asset.sex?.slice(1)) ??
        //           'Unknown';

        //         if (!accum[sex]) {
        //           accum[sex] = true;
        //         }

        //         return accum;
        //       }, {}),
        //     ).map((sex) => [
        //       <HerdContainer
        //         title={`${sex}s: Latest Price`}
        //         price={hasAngus ? (sex === 'Steer' ? '685.33' : '665.18') : ''}
        //       />,
        //       `$ `,
        //     ]),
        //     sizing: [8, 4]
        //   }
        // },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.PIE,
          payload: {
            title: 'Conception Status vs No. of Animals',
            dependentKey: [
              'picAddress',
              'siteName',
              'property',
              'region',
              'birthYear',
            ],
            key: 'conceptionStatus',
            source: (_, dependencies) => ({
              url: API.GET.getComponentData,
              method: 'GET',
              params: {
                input: {
                  pic_number: dependencies.picAddress.value,
                  property_type: dependencies.property.value,
                  region: dependencies.region.value,
                  site: Array.isArray(dependencies.siteName.value) ? dependencies.siteName.value.map((s) => s.value) : dependencies.siteName.value,
                  ...(String(dependencies.birthYear.value).split('_')[1]
                    ? {
                        birthYear: Number(
                          String(dependencies.birthYear.value).split('_')[1],
                        ),
                      }
                    : {}),
                  birthSeason: String(dependencies.birthYear.value).split('_')[0],
                },
                category: 'widget',
                subcategory: 'warakirri',
                name: 'conceptionStatus',
              },
            }),
            transformData: (dataStore) =>
              (dataStore?.conceptionStatus || []).map((slice) => ({
                name: slice._id,
                value: slice.amount,
              })),
            legend: {
              title: 'Conception Status',
            },
            composition: [
              {
                type: DashboardWidgetType.DROPDOWN,
                payload: {
                  key: 'birthYear',
                  dependentKey: [
                    'picAddress',
                    'siteName',
                    'property',
                    'region',
                  ],
                  transformData: (dataStore) =>
                    (dataStore?.birthYear || [])
                      .filter(({_id, ...stat}) =>
                        Object.values(stat).reduce(
                          (sum, curVal) => sum + curVal,
                          0,
                        ),
                      )
                      .map(({_id}) => ({
                        label: `${_id.season} ${_id.year}`,
                        value: `${_id.season}_${_id.year}`,
                      })),
                },
              },
            ],
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.BAR,
          payload: {
            title: 'Birth Year in Season Group and Region vs No. of Animals',
            dependentKey: ['siteName', 'property', 'region', 'picAddress'],
            key: 'birthYear',
            source: (_, dependencies) => ({
              url: API.GET.getComponentData,
              method: 'GET',
              params: {
                input: {
                  pic_number: dependencies.picAddress.value,
                  property_type: dependencies.property.value,
                  region: dependencies.region.value,
                  site: Array.isArray(dependencies.siteName.value) ? dependencies.siteName.value.map((s) => s.value) : dependencies.siteName.value,
                },
                category: 'widget',
                subcategory: 'warakirri',
                name: 'birthYear',
              },
            }),
            transformData: (dataStore) => {
              let transformedRes: Record<number, any> = {};

              (dataStore?.birthYear || []).forEach((item) => {
                const {season} = item._id;
                Object.entries(item).forEach(([key, value]) => {
                  if (key !== '_id') {
                    if (!transformedRes[item._id.year]) {
                      transformedRes[item._id.year] = {
                        [`${season}.${key}`]: value,
                      };
                    } else {
                      transformedRes[item._id.year][`${season}.${key}`] = value;
                    }
                  }
                });
              });

              return Object.entries(transformedRes).map(([key, value]) => ({
                ...value,
                year: key,
              }));
            },
            xLabel: 'Birth Year',
            yLabel: 'No. of Animals',
            xAxis: [
              {key: 'season', label: 'Autumn Spring'},
              {key: 'year', style: {fontWeight: 'bold', fill: 'black'}},
            ],
            legend: {
              payload: [
                {
                  value: 'Gippsland',
                  type: 'circle',
                  id: 'ID01',
                  color: '#417521',
                },
                {
                  value: 'South Australia',
                  type: 'circle',
                  id: 'ID02',
                  color: '#C0FF99',
                },
                {
                  value: 'Western District',
                  type: 'circle',
                  id: 'ID03',
                  color: '#76B820',
                },
              ],
            },
            bars: [
              [
                {key: 'Autumn.Gippsland', fill: '#417521'},
                {key: 'Autumn.Western District', fill: '#76B820'},
                {key: 'Autumn.South Australia', fill: '#C0FF99'},
              ],
              [
                {key: 'Spring.Gippsland', fill: '#417521'},
                {key: 'Spring.Western District', fill: '#76B820'},
                {key: 'Spring.South Australia', fill: '#C0FF99'},
              ],
            ],
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.TABLE,
          payload: {
            pagination: true,
            columns: data('RFID'),
            key: 'assets',
            source: (_, dependencies, pages = 1, itemPerPage = 10) => ({
              url: API.POST.getFilteredToken,
              method: 'POST',
              data: 
              {
                "pages": pages,
                "itemPerPage": itemPerPage,
                "getFilters": false,
                "fieldCondition": [
                    {
                        "name": "RFID",
                        "display": true
                    },
                    {
                        "name": "Species",
                        "display": true
                    },
                    {
                        "name": "Breed",
                        "display": true
                    },
                    {
                        "name": "Sex",
                        "display": true
                    },
                    {
                        "name": "Current Weight",
                        "display":true
                    },
                    {
                        "name": "Property Type",
                        "display": true,
                        ...(dependencies.property.value
                          ? {filterValue: dependencies.property.value}
                          : {})
                    },
                    {
                        "name": "Region",
                        "display": true,
                        ...(dependencies.region.value
                          ? {filterValue: dependencies.region.value}
                          : {})
                    },
                    {
                        "name": "Current Site",
                        "display": true,
                        ...(dependencies.siteName.value?.length
                          ? {filterValue: dependencies.siteName.value}
                          : {})
                    },
                    {
                        "name": "Pic Id",
                        "display": false,
                        ...(dependencies.picAddress.value
                          ? {filterValue: dependencies.picAddress.value}
                          : {}),
                    }
                ],
                "output": "grid",
                "sort": null,
                "sortOrder": null,
                "type": null,
                "includeAssetWithStatus": ["--null","Unknown","Current","Off Farm"]  // Include asset that doesn't have status + asset that has status ["Unknown","Current","Off Farm"]
            },
          }),
            dependentKey: ['picAddress', 'siteName', 'property', 'region'],
            transformData: (dataStore, dependencies, pages = 1, itemsPerPage = 10) =>
              ({
                totalPages: dataStore?.assets.totalPage ?? 0,
                totalRows: dataStore?.assets.totalRow ?? 0,
                rows: (dataStore?.assets.filteredResult ?? [])
                .map((asset) => ({
                  identifier:
                    asset.RFID,
                  species: asset.Species ?? 'Cattle',
                  breed: asset.Breed,
                  sex: asset.Sex ?? 'Unknown',
                  weight: asset.current_weight,
                  pic: asset["Pic Id"],
                  age: asset['Birth Year']
                    ? new Date().getFullYear() -  asset['Birth Year']
                    : asset['Birth Year']
                    ? new Date().getFullYear() -
                      Number(moment( asset['Birth Year']).format('YYYY'))
                    : undefined,
                }))
                .map((asset, index) => {
                  return {
                    idx: (( pages != 1 ? ((pages -1) * itemsPerPage) : 0) + (1 + index)),
                    identifier: asset.identifier,
                    species: 'Cattle',
                    breed: asset.breed ?? '',
                    age: asset.age,
                    sex: asset.sex,
                    weight: asset.weight ? asset.weight : '',
                  };
                }),
              })
          },
        },
      ],
    },
  ],
};

export default dashboardConfig;
