import React, {useState, useEffect} from 'react';
import COLOR from '../../styled/colors';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  GridSize,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import Tabs from '../../presentation/Tab';
import TextFieldWithLimit from '../../presentation/TextFieldWithLimit';
import DatePicker from '../../presentation/DatePicker';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import {
  Question,
  QuestionInput,
  InputValueType,
  FormAnswers,
} from '../../store/form/types';
import DialogMessage from '../../presentation/DialogMessage';
import NoteIcon from '@material-ui/icons/Note';
import DeleteIcon from '@material-ui/icons/Delete';
import GlobalStyles from '@mui/material/GlobalStyles';
import MyButton from '../../presentation/button';
import {fileUploader} from '../../store/product/actions';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {useAppDispatch} from '../../utils/hooks';
import {Link} from '@mui/material';

type FormCardType = {
  index: number;
  question: Question;
  id?: string;
  changeAnswer: (question: Question, value: string, preValue: string) => void;
  updateSubAnswer: (
    questionId: string | Array<string>,
    value: InputValueType | Array<InputValueType>,
    index: number | null,
    subQuestionId?: string,
    subValue?: InputValueType,
    subIndex?: number | null,
    inputType?: string,
    remove?: boolean,
  ) => void;
  updateQuestion?: (subAnswer: string, inputs: QuestionInput[]) => void;
  formAnswers: FormAnswers;
  viewMode: boolean;
  showError: boolean;
  errorStates: {[key: string]: boolean};
};
const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  cardContainer: {
    backgroundColor: COLOR.WHITE,
    marginTop: 20,
    padding: 10,
    alignContent: 'start',
  },
  tabStyle: {
    borderRadius: 5,
    color: COLOR.BLACK,
  },
  tabsStyle: {
    padding: 3,
    backgroundColor: COLOR.WHITE,
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    borderRadius: 5,
  },
  labelStyle: {
    fontSize: 15,
    textTransform: 'capitalize',
  },
  hyperlink: {
    color: COLOR.GREENT_TEXT,
    cursor: 'pointer',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  questionFiled: {
    marginTop: 15,
    marginBottom: 15,
  },
  radio: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  questionTitle: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 700,
    color: COLOR.BLACK_BG,
    marginBottom: 5,
  },
  boxFiled: {
    marginTop: 15,
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    padding: 10,
    position: 'relative',
  },
  inputField: {
    marginTop: -10,
  },
  limitTextContainer: {
    backgroundColor: COLOR.WHITE,
    width: '100%',
  },
  booleanDateField: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
  },
  helperText: {
    margin: 0,
    color: COLOR.RED,
  },
}));

const FormCardCom: React.FC<FormCardType> = ({
  index,
  question,
  id,
  changeAnswer,
  updateSubAnswer,
  formAnswers,
  viewMode,
  showError,
  errorStates,
  updateQuestion,
}) => {
  const classes = useStyles();
  const [openMore, setOpenMore] = useState(false);
  const [noteMaxHeight, setNoteMaxHeight] = useState('calc(80vh - 300px)');
  const [fileList, setFileList] = useState<{[key: string]: string}>({});
  const tabOptions = question.answers;
  const dispatch = useAppDispatch();
  const tabValue = tabOptions?.indexOf(
    formAnswers[`${question.questionId}-${question.index}`]?.value as string,
  );

  const handleChangeAnswer = React.useCallback(
    (value) => {
      changeAnswer(question, tabOptions[value], tabOptions[tabValue]);
    },
    [changeAnswer, question, tabOptions, tabValue],
  );
  const handleAttachClick = React.useCallback(
    (id: string) => () => {
      (document.querySelector(`#AttachFile-${id}`) as HTMLElement).click();
    },
    [],
  );
  //set to state first, upload later
  const uploadFileHandler = React.useCallback(
    (type: string) => async (e) => {
      const target = e.target.files.length > 0 ? e.target.files[0] : '';
      if (target) {
        if (target.size > 10485760) {
          window.alert('Upload image size more than 10mb ！');
        } else {
          dispatch({type: SPINNER_TOGGLE_ON});
          const response = await fileUploader(type, target, 'envd');
          dispatch({type: SPINNER_TOGGLE_OFF});
          const responseData = response[0].data;
          if (typeof responseData == 'string') {
            const item = type.split('-');
            updateSubAnswer(item[0], responseData, Number(item[1]));
          }
        }
      } else {
        const copy = {...fileList};
        delete copy[`${type}`];
        setFileList(copy);
      }
    },
    [fileList, updateSubAnswer],
  );

  useEffect(() => {
    if (openMore) {
      //some hacky stuff to recalculate height for scrollbars in dialog, cause flexbox doesn't play nice
      //the recalculation is for the question itself, which may be 1 or more lines (hence different height)
      const timer = setTimeout(() => {
        const dialogHeight = (
          document.querySelector('[role="dialog"]') as HTMLElement
        ).offsetHeight;
        const windowHeight = 0.8 * window.innerHeight;
        if (dialogHeight && dialogHeight < windowHeight) {
          const diff = windowHeight - dialogHeight;
          setNoteMaxHeight(`calc(80vh - ${300 - diff}px)`);
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [openMore]);

  const renderProperties = React.useCallback(
    (item: QuestionInput, index: number, rootItemType?: string) => {
      switch (item.type) {
        case 'input':
        case 'input_boolean':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : item.multiline ? 12 : 8}
              key={`input-${item.questionId}-${index}-${item.index}`}
              className={classes.questionFiled}>
              <Grid
                item
                xs={item.colSize ? item.colSize : item.multiline ? 12 : 8}>
                {item.multiline ? (
                  <Typography variant="body1" role="label">
                    {`${item.label} ${
                      item.required && item.type !== 'input_boolean' ? '*' : ''
                    }`}
                  </Typography>
                ) : (
                  <Typography className={classes.questionTitle} role="label">
                    {`${item.label} ${
                      item.required && item.type !== 'input_boolean' ? '*' : ''
                    }`}
                  </Typography>
                )}
              </Grid>
              {item.multiline && item.subLabel && (
                <Grid item style={{display: 'flex'}}>
                  <NoteIcon
                    style={{
                      marginRight: 15,
                      color: COLOR.BLACK_BG,
                    }}
                  />
                  <Typography className={classes.questionTitle} role="label">
                    {item.subLabel ?? 'Specify'}
                  </Typography>
                </Grid>
              )}
              {/* <Icon>note</Icon> */}
              <TextFieldWithLimit
                title={''}
                id={`${id}-TextFieldWithLimit-${item.questionId}-${item.index}`}
                content={
                  (formAnswers[`${item.questionId}-${item.index}`]
                    ?.value as string) ?? ''
                }
                // style={classes.inputField}
                limitTextContainerStyle={classes.limitTextContainer}
                limit={item.maxLength}
                multiline={item.multiline}
                rows={item.multiline ? 10 : 1}
                onChange={(value: string) => {
                  updateSubAnswer(
                    // rootItemType === 'boolean_date_text'
                    //   ? item.subQuestionId
                    //   : item.questionId,
                    item.questionId,
                    value,
                    item.index,
                    undefined,
                    undefined,
                    undefined,
                    item.type,
                  );
                }}
                showLimit={false}
                inputProps={{readOnly: viewMode}}
                disabled={viewMode}
                error={
                  item.required &&
                  errorStates[
                    `${
                      // rootItemType === 'boolean_date_text'
                      //   ? item.subQuestionId
                      //   : item.questionId
                      item.questionId
                    }-${item.index}`
                  ] &&
                  showError
                }
                helperText={
                  item.required &&
                  errorStates[
                    `${
                      // rootItemType === 'boolean_date_text'
                      //   ? item.subQuestionId
                      //   : item.questionId
                      item.questionId
                    }-${item.index}`
                  ] &&
                  showError
                    ? 'Required'
                    : ''
                }
              />
            </Grid>
          );
        case 'radio':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : 8}
              key={`radio-${index}`}
              className={classes.radio}>
              <Typography className={classes.questionTitle} role="label">
                {`${item.label ?? item.text} ${item.required ? '*' : ''}`}
              </Typography>
              <RadioGroup
                id={`${id}-radio-${item.questionId}`}
                name={`${id}-radio-${item.questionId}`}
                value={
                  formAnswers[`${item.questionId}-${item.index}`]?.value ?? ''
                }
                style={{marginLeft: 10}}
                onChange={(e) =>
                  updateSubAnswer(item.questionId, e.target.value, item.index)
                }>
                {item.answers.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={option}
                      value={
                        item.answersValues ? item.answersValues[index] : option
                      }
                      // value={option}
                      control={<Radio color="primary" />}
                      label={option}
                      disabled={viewMode}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          );
        case 'date':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : 8}
              key={`date-${index}`}
              className={classes.questionFiled}>
              <Typography className={classes.questionTitle} role="label">
                {`${item.label} ${item.required ? '*' : ''}`}
              </Typography>
              <Grid container direction="column">
                <DatePicker
                  dateValue={
                    (formAnswers[`${item.questionId}-${item.index}`]
                      ?.value as string) ?? moment().format('YYYY-MM-DD')
                  }
                  errorStatus={
                    !(
                      item.required &&
                      errorStates[`${item.questionId}-${item.index}`] &&
                      showError
                    )
                  }
                  errorMessage={
                    item.required &&
                    errorStates[`${item.questionId}-${item.index}`] &&
                    showError
                      ? 'Required'
                      : ''
                  }
                  handleChange={(date: Date) =>
                    updateSubAnswer(
                      item.questionId,
                      moment(date).format('YYYY-MM-DD'),
                      item.index,
                      undefined,
                      undefined,
                      undefined,
                      item.type,
                    )
                  }
                  disabled={viewMode}
                  readOnly={viewMode}
                />
              </Grid>
            </Grid>
          );
        case 'number':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : 8}
              justifyContent="flex-start"
              key={`number-${index}`}
              className={classes.questionFiled}>
              <Typography className={classes.questionTitle} role="label">
                {`${item.label} ${item.required ? '*' : ''}`}
              </Typography>
              <TextField
                key={`textfield-number-${index}`}
                id={`${id}-number-${item.questionId}`}
                variant="outlined"
                fullWidth
                value={formAnswers[`${item.questionId}-${item.index}`]?.value}
                type={item.type}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateSubAnswer(item.questionId, e.target.value, item.index)
                }
                disabled={viewMode}
                InputProps={{
                  inputProps: {
                    min: item.minimum ?? 0,
                    max: item.maximum,
                    readOnly: viewMode,
                  },
                }}
                error={
                  item.required &&
                  errorStates[`${item.questionId}-${item.index}`] &&
                  showError
                }
                helperText={
                  item.required &&
                  errorStates[`${item.questionId}-${item.index}`] &&
                  showError
                    ? 'Required'
                    : ''
                }
              />
            </Grid>
          );
        case 'boolean_date':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : 12}
              justifyContent="space-between"
              alignContent="center"
              key={`boolean-date-${index}`}
              className={classes.booleanDateField}>
              <Grid
                container
                item
                xs={5}
                alignItems="center"
                style={{display: 'flex'}}>
                <Typography role="label">{`${item.label} ${
                  item.required ? '*' : ''
                }`}</Typography>
              </Grid>
              <Grid
                item
                xs={7}
                style={{display: 'flex'}}
                justifyContent="flex-end"
                alignItems="center">
                <Switch
                  id={`${id}-switch-${item.questionId}`}
                  checked={
                    formAnswers[`${item.questionId}-${item.index}`]?.value ===
                      'true' ||
                    (rootItemType === 'boolean_date_text' &&
                      typeof formAnswers[`${item.questionId}-${item.index}`]
                        ?.value !== 'undefined')
                      ? true
                      : false
                  }
                  onChange={() => {
                    if (rootItemType === 'boolean_date_text') {
                      // clear 'boolean_date_text' text state when toggle off switch button
                      updateSubAnswer(
                        item.questionId,
                        typeof formAnswers[`${item.questionId}-${item.index}`]
                          ?.value !== 'undefined'
                          ? false
                          : '',
                        item.index,
                        item.subQuestionId,
                        !!formAnswers[`${item.questionId}-${item.index}`]?.value
                          ? ''
                          : moment().format('YYYY-MM-DD'),
                        item.index,
                        'boolean_date_text',
                      );
                    } else {
                      if (
                        formAnswers[`${item.questionId}-${item.index}`]
                          ?.value === 'true'
                      ) {
                        updateSubAnswer(
                          item.questionId,
                          'false',
                          item.index,
                          item.subQuestionId,
                          '',
                          item.index,
                          item.type,
                        );
                      } else {
                        updateSubAnswer(
                          item.questionId,
                          'true',
                          item.index,
                          item.subQuestionId,
                          moment().format('YYYY-MM-DD'),
                          item.index,
                          item.type,
                        );
                      }
                    }
                  }}
                  name="boolean"
                  color="primary"
                  disabled={viewMode}
                />
                {(formAnswers[`${item.questionId}-${item.index}`]?.value ===
                  'true' ||
                  (rootItemType === 'boolean_date_text' &&
                    typeof formAnswers[`${item.questionId}-${item.index}`]
                      ?.value !== 'undefined')) && (
                  <DatePicker
                    dateValue={
                      (formAnswers[`${item.subQuestionId}-${item.index}`]
                        ?.value as string) ?? moment().format('YYYY-MM-DD')
                    }
                    errorStatus={
                      item.subQuestionId === '179' &&
                      formAnswers[`${item.subQuestionId}-${item.index}`] &&
                      new Date(
                        formAnswers[`${item.subQuestionId}-${item.index}`]
                          .value as string,
                      ) < new Date('2020-10-14')
                        ? false
                        : true
                    }
                    errorMessage={
                      item.subQuestionId === '179' &&
                      formAnswers[`${item.subQuestionId}-${item.index}`] &&
                      new Date(
                        formAnswers[`${item.subQuestionId}-${item.index}`]
                          .value as string,
                      ) < new Date('2020-10-14')
                        ? 'Please select date after 14/10/2020'
                        : ''
                    }
                    handleChange={(date: Date) => {
                      updateSubAnswer(
                        item.subQuestionId,
                        moment(date).format('YYYY-MM-DD'),
                        item.index,
                        undefined,
                        undefined,
                        undefined,
                        item.type,
                      );
                    }}
                    readOnly={viewMode}
                    disabled={viewMode}
                  />
                )}
              </Grid>
            </Grid>
          );
        case 'select':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : 12}
              justifyContent="space-between"
              key={`select-${index}`}
              className={classes.questionFiled}>
              <Typography className={classes.questionTitle} role="label">
                {`${item.label} ${item.required ? '*' : ''}`}
              </Typography>
              <FormControl variant="outlined" fullWidth style={{marginTop: 20}}>
                <InputLabel id="envd-form-type-select">
                  {'Please select'}
                </InputLabel>
                <Select
                  labelId="report-type-select"
                  label="Please select"
                  id={`${id}-select`}
                  value={
                    formAnswers[`${item.questionId}-${item.index}`]?.value ?? ''
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateSubAnswer(item.questionId, e.target.value, item.index)
                  }
                  inputProps={{readOnly: viewMode}}
                  disabled={viewMode}
                  error={
                    item.required &&
                    errorStates[`${item.questionId}-${item.index}`] &&
                    showError
                  }>
                  {item.answers?.map((value, index) => (
                    <MenuItem
                      value={
                        item.answersValues ? item.answersValues[index] : value
                      }
                      key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {item.required &&
                  errorStates[`${item.questionId}-${item.index}`] &&
                  showError && (
                    <FormHelperText className={classes.helperText}>
                      {'Required'}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          );
        case 'displayBox':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : item.multiline ? 12 : 8}
              key={`displayBox-${index}-${item.index}`}
              className={classes.questionFiled}>
              <Typography className={classes.questionTitle} role="label">
                {`${item.label} ${item.required ? '*' : ''}`}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={item.display}
                // className={classes.inputField}
                inputProps={{readOnly: true}}
                disabled={viewMode}
              />
            </Grid>
          );
        case 'boolean_date_text':
          const booleanDateItem = JSON.parse(JSON.stringify(item));
          booleanDateItem.type = 'boolean_date';
          const inputItem = JSON.parse(JSON.stringify(item));
          inputItem.type = 'input_boolean';
          inputItem.label = '';
          inputItem.multiline = true;
          inputItem.colSize = 12;
          inputItem.required = true;
          return (
            <>
              {renderProperties(booleanDateItem, index, item.type)}
              {typeof formAnswers[`${item.questionId}-${item.index}`]?.value !==
                'undefined' && renderProperties(inputItem, index, item.type)}
            </>
          );
        case 'file':
          return (
            <Grid
              container
              item
              xs={item.colSize ? item.colSize : item.multiline ? 12 : 8}
              justifyContent="space-between"
              key={`file-${index}`}
              className={classes.questionFiled}
              style={{position: 'relative'}}>
              <Typography variant="h4">{item.label}</Typography>
              <Divider
                style={{width: '100%', marginBottom: 15, marginTop: 10}}
              />
              {formAnswers[`${item.questionId}-${item.index}`]?.value ? (
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Link
                      href={
                        formAnswers[`${item.questionId}-${item.index}`]
                          ?.value as string
                      }
                      target="_blank"
                      variant="body2"
                      style={{color: COLOR.GREENT_TEXT, fontSize: 16}}>
                      {(
                        formAnswers[`${item.questionId}-${item.index}`]
                          ?.value as string
                      )
                        .split('/')
                        .pop()}
                    </Link>
                  </Grid>
                  {!viewMode && (
                    <Grid item xs={2}>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          updateSubAnswer(
                            item.questionId,
                            '',
                            item.index,
                            undefined,
                            undefined,
                            undefined,
                            item.type,
                          )
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <>
                  <MyButton
                    disabled={viewMode}
                    text={'Attach File'}
                    variant="outlined"
                    style={{width: '100%'}}
                    onClick={handleAttachClick(
                      `${item.questionId}-${item.index}`,
                    )}
                  />
                  <input
                    type="file"
                    disabled={viewMode}
                    id={`AttachFile-${item.questionId}-${item.index}`}
                    accept="image/*, .pdf, .doc, .docx"
                    onChange={uploadFileHandler(
                      `${item.questionId}-${item.index}`,
                    )}
                    //ref={attachFileUpload}
                    style={{position: 'absolute', visibility: 'hidden'}}
                  />
                </>
              )}
            </Grid>
          );
      }
    },
    [
      errorStates,
      formAnswers,
      handleAttachClick,
      showError,
      updateSubAnswer,
      uploadFileHandler,
      viewMode,
      id
    ],
  );
  const renderInputs = React.useCallback(
    (
      style: string,
      items: QuestionInput[],
      addText?: string,
      subAnswer?: string,
    ) => {
      switch (style) {
        case 'boxed':
          let maxLen: number = 0;
          for (const item of items) {
            if (item.boxWrapIndex) {
              maxLen = Math.max(item.boxWrapIndex, maxLen);
            }
          }
          let boxList: QuestionInput[][] = [];
          for (let i = 0; i <= maxLen; i++) {
            boxList[i] = [];
          }
          for (const item of items) {
            if (item.boxWrapIndex !== undefined) {
              boxList[item.boxWrapIndex]?.push(item);
            }
          }
          return boxList.map((boxs: QuestionInput[], index: number) => {
            return (
              <Grid
                container
                item
                xs={12}
                key={index}
                className={classes.boxFiled}>
                {boxs.map((item: QuestionInput, index: number) => {
                  return renderProperties(item, index);
                })}
                {addText && index === boxList.length - 1 && !viewMode && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    style={{marginBottom: 20}}
                    xs={8}>
                    <Button
                      onClick={() => {
                        let copy: QuestionInput[] = JSON.parse(
                          JSON.stringify(boxs),
                        );
                        let qids: string[] = [];
                        let vals: InputValueType[] = [];
                        copy.forEach((cp, idx) => {
                          copy[idx].index = boxList.length;
                          copy[idx].boxWrapIndex = boxList.length;
                          qids.push(cp.questionId);
                          vals.push(
                            cp.type.includes('date')
                              ? moment().format('YYYY-MM-DD')
                              : '',
                          );
                        });
                        updateQuestion(subAnswer, [...items, ...copy]);
                        updateSubAnswer(qids, vals, boxList.length);
                      }}>
                      <span className={classes.hyperlink}>
                        <u>{addText}</u>
                      </span>
                    </Button>
                  </Grid>
                )}
                {addText && index > 0 && !viewMode && (
                  <IconButton
                    style={{position: 'absolute', top: 10, right: 10}}
                    onClick={() => {
                      let copy: QuestionInput[] = JSON.parse(
                        JSON.stringify(items),
                      );
                      let qList: Array<string> = [];
                      copy.forEach((cp, idx) => {
                        if (cp.index === index) {
                          delete copy[idx];
                          qList.push(cp.questionId);
                        } else if (cp.index > index) {
                          copy[idx].index--;
                          copy[idx].boxWrapIndex--;
                        }
                      });
                      updateSubAnswer(
                        qList,
                        '',
                        Number(index),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true,
                      );
                      updateQuestion(
                        subAnswer,
                        copy.filter((c) => c),
                      );
                    }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            );
          });
        case 'whole_box':
          return (
            <Grid
              container
              item
              xs={12}
              key={index}
              className={classes.boxFiled}>
              {items.map((item, index) => {
                return renderProperties(item, index);
              })}
            </Grid>
          );
        default:
          if (items[0].webIndex !== undefined) {
            items.sort((a, b) => a.webIndex - b.webIndex);
            return (
              <Grid
                container
                item
                justifyContent="space-between"
                spacing={2}
                className={classes.questionFiled}>
                {items.map((item, index) => {
                  return renderProperties(item, index);
                })}
              </Grid>
            );
          } else {
            return items.map((item, index) => {
              return renderProperties(item, index);
            });
          }
      }
    },
    [index, renderProperties, updateQuestion, updateSubAnswer, viewMode],
  );
  return (
    <Grid id={id} container item xs={12} className={classes.cardContainer}>
      <Grid container justifyContent="space-between">
        <Grid style={{height: 30}}>
          <Typography variant="h5" role="label">
            Question {index + 1}
          </Typography>
        </Grid>
        <Grid style={{marginRight: 10}}>
          {question.fullText && (
            <Button
              onClick={() => {
                setOpenMore(true);
              }}>
              <span className={classes.hyperlink}>
                <u>{'More >'}</u>
              </span>
            </Button>
          )}
        </Grid>
      </Grid>

      <Divider style={{width: '100%', marginTop: 0}} />
      <Grid container item xs={12} style={{display: 'flex'}}>
        <Grid container alignItems="flex-start">
          <Grid
            container
            item
            xs={
              (tabOptions
                ? tabOptions.length === 2
                  ? 9
                  : tabOptions.length === 3
                  ? 8
                  : 12
                : 12) as GridSize
            }
            style={{marginTop: 10, paddingRight: 12}}>
            <Typography variant="body1" role="label">
              {question.text}
            </Typography>
          </Grid>
          {tabOptions && (
            <Grid
              container
              item
              xs={
                (tabOptions.length === 2
                  ? 3
                  : tabOptions.length === 3
                  ? 4
                  : 12) as GridSize
              }
              style={{marginTop: 10}}>
              <Tabs
                id={`${id}-tabs`}
                tabHeaders={tabOptions}
                value={tabValue > -1 ? tabValue : 0}
                labelStyle={classes.labelStyle}
                setValue={handleChangeAnswer}
                tabStyle={classes.tabStyle}
                style={classes.tabsStyle}
                readOnly={viewMode}>
                <></>
              </Tabs>
            </Grid>
          )}
        </Grid>
        {question.inputs && renderInputs(question.style, question.inputs)}
        {question.subQuestions?.Yes?.inputs &&
          tabOptions[tabValue] === 'Yes' &&
          renderInputs(
            question.subQuestions.Yes.style ?? 'normal',
            question.subQuestions.Yes.inputs,
            question.subQuestions.Yes.addText,
            'Yes',
          )}
        {question.subQuestions?.No?.inputs &&
          tabOptions[tabValue] === 'No' &&
          renderInputs(
            question.subQuestions.No.style ?? 'normal',
            question.subQuestions.No.inputs,
            question.subQuestions.No.addText,
            'No',
          )}
      </Grid>
      <DialogMessage
        open={openMore}
        title={`Question ${index + 1}`}
        handleClose={() => setOpenMore(false)}
        size="sm"
        marginBottom={50}
        dialogStyle={{overflow: 'hidden'}}>
        <>
          <Grid
            container
            item
            xs={12}
            alignContent={'flex-start'}
            style={{
              backgroundColor: COLOR.GRAY_SOLID,
              marginTop: 20,
              paddingBottom: 40,
            }}>
            <Typography
              variant="body1"
              style={{padding: '16px 16px 0px 16px', fontSize: 20}}>
              {question.fullText ?? 'Note'}
            </Typography>
          </Grid>
          <GlobalStyles
            styles={{
              h1: {color: 'grey'},
              '*::-webkit-scrollbar': {
                width: '0.2em',
              },
              '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.09)',
              },
              '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
              },
            }}
          />
          <Grid
            container
            item
            style={{marginTop: 30}}
            direction="column"
            alignContent={'flex-start'}>
            <Grid item>
              <Typography variant="h3">{'Explanatory Note'}</Typography>
              <Divider style={{marginTop: 10}} />
            </Grid>
            <Grid
              container
              item
              style={{
                marginTop: 20,
                marginBottom: 10,
                overflow: 'auto',
                maxHeight: `${noteMaxHeight}`,
              }}>
              <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                {question.note ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </>
      </DialogMessage>
    </Grid>
  );
};

export const FormCard = React.memo(FormCardCom);
