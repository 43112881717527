import _ from 'lodash';
import {History} from 'history';
import {TokenService} from '@aglive/data-model';

import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal, toggleModalOff} from '../modal/actions';
import {callAPI} from '../../utils/network';
import API from '../../config/api';
import CONSTANT from '../../config/constant';
import {WebErrorType} from '../../utils/error';

export const createPromotion =
  (
    promotion: TokenService.PromotionToken['details'],
    publish?: boolean,
  ): AppThunk<Promise<TokenService.PromotionToken | void>> =>
  async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      let tempPromotion: TokenService.PromotionToken['details'] =
        _.cloneDeep(promotion);
      if (publish) {
        tempPromotion.status = 'published';
      } else {
        tempPromotion.status = 'draft';
      }
      // Unlike product promotion, the Tell Your Story content is included in Animal Promotion token.
      // Thus, the logoType logic is handled here.
      if (tempPromotion.type === 'ANIMALS') {
        if (
          tempPromotion.story.logoType !== 'user' &&
          tempPromotion.story.logoType !== 'brand'
        ) {
          tempPromotion.story.logoType = undefined;
        }
      }

      const response: { message: string; data: Array<TokenService.PromotionToken> } = await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.PROMOTION,
              tokenId: '',
              details: tempPromotion,
            },
          ],
        },
      });
      return response.data[0];
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

export const getPromotions =
  (
    promotionType: TokenService.PromotionToken['details']['type'],
  ): AppThunk<Promise<TokenService.PromotionToken[]>> =>
  async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: TokenService.PromotionToken[] = await callAPI({
        url: API.POST.getTokenByBusiness,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [CONSTANT.ASSETTYPE.PROMOTION],
          activityType: [],
          businessId: [getState().user.userProfileData.businessId],
        },
      });

      return response.filter((token) => token.details.type === promotionType);
    } catch (e) {
      if (e.code === 'E_NOT_FOUND') {
        return [];
      } else {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

export function getOnePromotion(
  id: string,
  history: History,
  promotionType: TokenService.PromotionToken['details']['type'],
): AppThunk<Promise<TokenService.PromotionToken>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: Array<TokenService.PromotionToken> = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [CONSTANT.ASSETTYPE.PROMOTION],
          activityType: [],
          externalIds: [{agliveToken: id}],
        },
      });

      return response[0];
    } catch (e) {
      const error = e as WebErrorType;
      if (error.code === 'E_NOT_FOUND') {
        error.title = 'Promotion Not Found';
      }
      history.replace(
        `/private/${
          promotionType === 'ANIMALS' ? 'assets' : 'products'
        }/promotions`,
      );
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export const editPromotion =
  (
    promotion: TokenService.PromotionToken['details'],
    agliveToken: string,
    publish: boolean,
    oldPromotion?: TokenService.PromotionToken['details'],
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      let tempPromotion: TokenService.PromotionToken['details'] =
        _.cloneDeep(promotion);
      // It is possible that the fetched promotion has the scan counts in the token details,
      // They are excluded here to prevent them from being edited through UP_details
      tempPromotion = _.omit(tempPromotion, [
        'webCount',
        'supplierCount',
        'consumerCount',
      ]) as TokenService.PromotionToken['details'];

      if (publish) {
        tempPromotion.status = 'published';
      }
      // Unlike product promotion, the Tell Your Story content is included in Animal Promotion token.
      // Thus, the logoType logic is handled here.
      if (
        tempPromotion.type === 'ANIMALS' &&
        oldPromotion?.type === 'ANIMALS'
      ) {
        if (
          tempPromotion.story.logoType !== 'user' &&
          tempPromotion.story.logoType !== 'brand'
        ) {
          tempPromotion.story.logoType = undefined;
        }

        /**
         * Remove logoType from token if user changed from brand/uploaded logo to no logo.
         * If the story object is different, remove the entire story field. It will be added back on the following
         * UP_details request. Calling DEL_details is to ensure milestones and additional sections removed by users
         * are actually being deleted because UP_details will not overwrite the milestones and additional sections.
         */
        const detailsPayload = {};
        if (!tempPromotion.story.logoType && oldPromotion) {
          detailsPayload['story'] = {
            logoType: oldPromotion.story.logoType,
          };
        }
        if (!_.isEqual(tempPromotion.story, oldPromotion.story)) {
          detailsPayload['story'] = oldPromotion.story;
        }
        if (!_.isEqual(tempPromotion.mob, oldPromotion.mob)) {
          detailsPayload['mob'] = oldPromotion.mob;
        }
        // Call create activity API to remove logoType, milestones, additional sections or linked mob
        if (!_.isEmpty(detailsPayload)) {
          await callAPI({
            url: API.POST.createActivity,
            method: 'POST',
            data: {
              tokens: [
                {
                  type: CONSTANT.ASSETTYPE.PROMOTION,
                  externalIds: {
                    agliveToken,
                  },
                  activities: [
                    {
                      type: 'DEL_details',
                      details: detailsPayload,
                    },
                  ],
                },
              ],
            },
          });
        }
      }

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.PROMOTION,
              externalIds: {
                agliveToken,
              },
              activities: [
                {
                  type: 'UP_details',
                  details: tempPromotion,
                },
              ],
            },
          ],
        },
      });
      return;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

export const manageArchivePromotion = (
  promotion: TokenService.PromotionToken,
  archive: boolean,
  successCB: () => void,
): AppThunk<Promise<boolean | void>> => {
  return async (dispatch) => {
    try {
      dispatch(toggleModalOff());
      dispatch({type: SPINNER_TOGGLE_ON});
      // Call create activity API to set archive in promotion details to true
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.PROMOTION,
              externalIds: {
                agliveToken: promotion.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'UP_details',
                  details: {
                    archive,
                  },
                },
              ],
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: archive ? 'Archived' : 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );

      return true;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
};

export const getLinkMobList =
  (
    isAngus: boolean,
  ): AppThunk<
    Promise<TokenService.AnimalPromotion['details']['mob']['linkMob'][]>
  > =>
  async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: TokenService.AnimalPromotion['details']['mob']['linkMob'][] =
        await callAPI({
          url: API.POST.getLinkMob,
          method: 'POST',
          data: {
            userId: getState().auth.wallet,
            angusVerified: isAngus,
          },
        });

      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
