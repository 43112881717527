import produce from 'immer';
import {
  POST_USER_PROFILE_DATA,
  ProfileTypes,
  profileState,
  POST_BUSINESS_PROFILE_DATA,
  DROP_PROFILE_DATA,
  POST_PLUGIN_DATA,
  POST_REPORT_DATA,
  POST_BUSINESS_ID,
  POST_API_KEY,
  TOGGLE_ANGUS,
  POST_SCHEMA_DATA,
  POST_CONTACTS,
} from './types';

const initialState: profileState = {
  userProfileData: {
    externalIds: {userId: null},
    id: null,
    image: null,
    name: null,
    role: null,
    mobileNumber: null,
    emailAddress: null,
    signature: null,
    address: null,
    businessId: null,
    country: null,
  },
  businessProfileData: {
    businessScope: null,
    businessType: null,
    companyName: null,
    companyNumber: null,
    brandDescription: null,
    industryType: null,
    subIndustryType: null,
    imageUri: null,
    location: [
      {
        locationNickname: null,
        locationType: null,
        PICAddress: null,
        renspa: null,
        address: null,
        postcode: null,
        town: null,
        state: null,
        LPAUserID: null,
        LPAPassword: null,
        NLISUserID: null,
        NLISPassword: null,
        geofence: null,
        country: null,
        locationId: null,
      },
    ],
  },
  contacts: [],
};

function profileReducer(
  state = initialState,
  action: ProfileTypes,
): profileState {
  switch (action.type) {
    case POST_USER_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.userProfileData = action.payload;
      });
    case POST_BUSINESS_ID:
      return produce(state, (draft) => {
        draft.userProfileData.businessId = action.payload;
      });
    case POST_BUSINESS_PROFILE_DATA:
      return produce(state, (draft) => {
        if (action.payload['angusProfile'] && !('angusEnabled' in action.payload)) {
          draft.businessProfileData = {...action.payload, angusEnabled: true};
        } else {
          draft.businessProfileData = action.payload;
        }
      });
    case TOGGLE_ANGUS:
      return produce(state, (draft) => {
        draft.businessProfileData.angusEnabled = !state.businessProfileData.angusEnabled;
      });
    case DROP_PROFILE_DATA:
      return initialState;
    case POST_PLUGIN_DATA:
      return produce(state, (draft) => {
        draft.plugins = action.payload;
      });
    case POST_REPORT_DATA:
      return produce(state, (draft) => {
        draft.reports = action.payload;
      });
    case POST_SCHEMA_DATA:
      return produce(state, (draft) => {
        draft.schema = action.payload;
      });
    case POST_API_KEY:
      return produce(state, (draft) => {
        draft.apiKeys = action.payload;
      });
    case POST_CONTACTS:
      return produce(state, (draft) => {
        draft.contacts = action.payload;
      })
    default:
      return state;
  }
}

export default profileReducer;
