import {TokenService} from '@aglive/data-model';
import _ from 'lodash';
import {CompleteProductPromotion} from '../../../store/promotion/types';

export const convertPromotionTokens = (
  promotions: TokenService.PromotionToken[],
  productProfiles: TokenService.ProductToken[],
) => {
  // Cloning promotions and product profiles to ensure the original states are not mutated
  const clonedPromotions = _.cloneDeep(promotions);
  const clonedProductProfiles = _.cloneDeep(productProfiles);
  const convertedPromotions: (
    TokenService.PromotionToken
  )[] = [];

  for (const promotion of clonedPromotions) {
    if (promotion.details.type === 'PRODUCTS') {
      const {product} = promotion.details;
      if (product.agliveToken) {
        const productProfile = clonedProductProfiles.find(
          (productProfile) =>
            productProfile.externalIds[0].agliveToken === product.agliveToken,
        );
        if (productProfile) {
          const completeProductPromotion: CompleteProductPromotion = {
            ...promotion,
            details: {
              productName: productProfile.details.name,
              ...productProfile.details,
              ...promotion.details,
            },
          };
          convertedPromotions.push(completeProductPromotion as TokenService.PromotionToken);
          continue;
        }
      }
    }
    convertedPromotions.push(promotion);
  }

  return convertedPromotions;
};
