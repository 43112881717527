import API from '../../config/api';
import CONSTANT from '../../config/constant';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
// Redux
import {AppThunk} from '../types';
import {RawAssetProfile} from '../assetProfile/types';
import {SiteTokenResponse} from '../site/types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {
  CreateAssetDetails,
  CreatePlantTokenDetails,
  CreateTokenPayload,
  PlantCodeDocumentRequest,
} from './types';
import {toggleModal} from '../modal/actions';
import {
  bulkCreateTokenRes,
} from '../code/actions';

export function fetchDropdownOptions(): AppThunk<
  Promise<Array<RawAssetProfile | SiteTokenResponse>>
> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: Array<RawAssetProfile | SiteTokenResponse> =
        await callAPI({
          url: API.POST.getTokenbyAddr,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            type: [CONSTANT.ASSETTYPE.ASSET_PROFILE, CONSTANT.ASSETTYPE.SITE],
            activityType: [],
          },
        });

      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type GenerateAssetProps = {
  assetDetails: CreateAssetDetails;
  download: boolean;
  assetProfile: RawAssetProfile;
  siteLayerName: string;
  successCB: (docId: string) => void;
};

export function generateAssets({
  assetDetails,
  download,
  assetProfile,
  siteLayerName,
  successCB,
}: GenerateAssetProps): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      // Get user ID from redux
      const userProfileData = getState().user.userProfileData;
      const businessProfileData = getState().user.businessProfileData;

      // The initial payload for generating assets without group
      const createTokenPayload: CreateTokenPayload<CreatePlantTokenDetails> = {
        type: CONSTANT.ASSETTYPE.PLANT,
        generateAgliveToken: true,
        generateUniqueAmount: true,
        amount: assetDetails.quantity, // Number of assets to be generated
        details: {
          permitId: assetDetails.permit,
          assetProfile: assetDetails.assetProfile, // The agliveToken of assetProfile
          site: {
            agliveToken: assetDetails.siteAgliveToken, // The agliveToken of site
            layerId: assetDetails.layerId, // The layerId of site
            layerName: siteLayerName, // The layerName of site
          },
          pic_id: assetDetails.pic_id,
          purpose: assetDetails.purpose,
          type: assetDetails.type,
          externalSource: assetDetails.externalSource,
        },
      };
      /* The back-end create token API is updated specifically for this feature which uses
         a specific type of payload and will automatically generates 'x' number of plant tokens */
      const createTokenResponse: bulkCreateTokenRes = await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [createTokenPayload],
        },
      });
      const generatedIds: bulkCreateTokenRes['data'] =
        createTokenResponse.data[0].data;
      const startNumber: number = createTokenResponse.data[0].startNumber;
      // Call create document API to create a `code` record for keeping track of the tokens generated in this particular request
      const createCodeResponse = await callAPI({
        url: API.POST.postDocument,
        method: 'POST',
        data: {
          userId: userProfileData.externalIds.userId,
          type: 'code',
          details: {
            permitId: assetDetails.permit,
            startNumber: startNumber,
            quantity: assetDetails.quantity,
            used: download,
            codes: generatedIds,
            assetProfileId: assetDetails.assetProfile, // The agliveToken of assetProfile
            site: {
              agliveToken: assetDetails.siteAgliveToken, // The agliveToken of site
              layerId: assetDetails.layerId, // The layerId of site
              layerName: siteLayerName, // The layerName of site
            },
            assetProfile: assetProfile.details,
            pic_id: assetDetails.pic_id,
            source: {
              companyName: businessProfileData.companyName,
              companyNumber: businessProfileData.companyNumber,
              creatorEmail: userProfileData.emailAddress,
            },
          },
        } as PlantCodeDocumentRequest,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Saved',
          button: 'Close',
          CTAHandler: () => successCB(createCodeResponse.docId),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchStartNumber(strain: string): AppThunk<Promise<number>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: number = await callAPI({
        url: `${API.GET.getStrainStartNumber}/${strain}`,
        method: 'GET',
      });

      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
