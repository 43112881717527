import React from 'react';

import {Container, Grid, Typography} from '@material-ui/core';

import {useStyles} from './styles';
import {PromotionLandingContentT} from './types';
import MyButton from '../../../presentation/button';
import PromotionHeading from './PromotionHeading';
import PromotionLandingContent from './PromotionLandingContentCom';

interface PromotionLandingProps {
  promotionHeadingConfig?: {
    title: string;
    subtitle: string;
  }
  promotionImage: string;
  promotionLandingContent: PromotionLandingContentT;
  button?: {
    label: string;
    onClick: () => void;
  };
  showPromotionHeading?: boolean; // for mainLanding sample popup
}

const PromotionLanding: React.FC<PromotionLandingProps> = ({
  promotionHeadingConfig,
  promotionImage,
  promotionLandingContent,
  button,
  showPromotionHeading = true,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" id="PromotionLandingContainer">
      {showPromotionHeading && (
        <PromotionHeading
          title={promotionHeadingConfig?.title}
          subtitle={promotionHeadingConfig?.subtitle}
        />
      )}

      <Grid container className={classes.landingContainer} justifyContent="center">
        <Grid item xs={12} md={4}>
          <img
            src={promotionImage}
            alt="Sample Promotion"
            width="100%"
            className={classes.promotionImage} />
        </Grid>
        <Grid item xs={12} md={8}>
          <PromotionLandingContent content={promotionLandingContent} />
        </Grid>

        {promotionLandingContent.caseStudy && (
          <Grid
            item
            container
            xs={12}
            md={11}
            className={classes.caseStudyContainer}>
            <Grid item xs={12} md={4} xl={3}>
              <img
                src={promotionLandingContent.caseStudy.image}
                alt="Case Study"
                width="100%"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              xl={9}
              className={classes.caseStudyDetails}>
              <Typography
                component="h6"
                className={classes.caseStudyLabel}
                gutterBottom={true}>
                Case Study:
              </Typography>
              <Typography variant="h3" gutterBottom={true}>
                {promotionLandingContent.caseStudy.title}
              </Typography>
              <Typography variant="body2">
                {promotionLandingContent.caseStudy.description}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid item>
          {button && (
            <MyButton
              text={button.label}
              variant="contained"
              width={250}
              fontSize={18}
              buttonClass={classes.button}
              onClick={button.onClick}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PromotionLanding;
