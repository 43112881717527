import React, {useState} from 'react';

import {Box, Grid} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import MockupPhoneTellYourStory from '../../../presentation/MockupPhoneTellYourStory';
import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import {useStyles} from './styles';
import FieldHeading from '../../../presentation/FieldHeading';
import LabelData from '../../../presentation/LabelData';

enum TabMap {
  mainSection,
  keyMilestones,
  additionalSection,
}
const TabLabels = Object.values(TabMap).slice(0, 3);

interface TellYourStoryProps {
  brandLogo: string | null;
  story: TokenService.ProductToken['details']['story'];
  mainSectionImageOption: 'image' | '';
  isAngus?: boolean;
}

const ViewTellYourStory: React.FC<TellYourStoryProps> = ({
  brandLogo,
  story,
  mainSectionImageOption,
  isAngus,
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(TabMap.mainSection);

  return (
    <>
      <Grid item container xs={12} lg={5} justifyContent="center">
        <MockupPhoneTellYourStory
          brandLogo={brandLogo}
          story={story}
          mainSectionImageOption={mainSectionImageOption}
          angusVerified={isAngus}
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <Tabs
          tabHeaders={TabLabels as string[]}
          value={tabValue}
          setValue={setTabValue}>
          {/* Main Section tab */}
          <TabPanel
            isShown={tabValue === TabMap.mainSection}
            index={TabMap.mainSection}>
            <Box p={3} className={classes.tabContainer}>
              <FieldHeading title="Logo" subtitle="Upload your brand logo" />
              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <LabelData
                  label="Logo"
                  field={
                    story?.logoType?.length
                    ? story.logoType === 'user'
                      ? story.logo
                        ? decodeURIComponent(story.logo.split('/').reverse()[0])
                        : '-'
                      : brandLogo
                        ? decodeURIComponent(brandLogo.split('/').reverse()[0])
                        : '-'
                    : '-'
                  }
                  valueId="Logo"
                />
              </Box>

              <FieldHeading
                title="Image"
                subtitle="Upload a compelling image"
              />
              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <LabelData
                  label="Image"
                  field={
                    story?.image?.length
                    ? decodeURIComponent(story.image.split('/').reverse()[0]) || '-'
                    : '-'
                  }
                  valueId="MainSectionImage"
                />
              </Box>

              <FieldHeading
                title="Headline"
                subtitle="Capture your audience's attention"
              />
              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Grid item container direction="column">
                  <LabelData
                    label="Line 1"
                    placeHolder="Enter Text"
                    field={story?.headline[0]}
                    characters={30}
                    valueId="Headline1"
                  />
                  <LabelData
                    label="Line 2"
                    placeHolder="Enter Text"
                    field={story?.headline[1]}
                    characters={30}
                    valueId="Headline2"
                  />
                  <LabelData
                    label="Line 3"
                    placeHolder="Enter Text"
                    field={story?.headline[2]}
                    characters={30}
                    valueId="Headline3"
                  />
                </Grid>
              </Box>

              <FieldHeading
                title="Content"
                subtitle="Tell your story to boost sales"
              />
              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Grid item container direction="column">
                  <LabelData
                    label=""
                    placeHolder="Add content here"
                    field={story?.content}
                    characters={600}
                    minHeight={250}
                    valueId="Content"
                  />
                </Grid>
              </Box>
            </Box>
          </TabPanel>
          {/* Key Milestones tab */}
          <TabPanel
            isShown={tabValue === TabMap.keyMilestones}
            index={TabMap.keyMilestones}>
            <Box p={3} className={classes.tabContainer}>
              {story?.milestones.map((milestone, index) => (
                <React.Fragment key={index}>
                  <FieldHeading title={`Milestone ${index + 1}`} subtitle="" />
                  <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                    <Grid item container direction="column">
                      <LabelData
                        label="Heading Title"
                        placeHolder="e.g. Production Line"
                        field={milestone.title}
                        characters={30}
                        valueId={`MilestoneHeading${index}`}
                      />
                      <LabelData
                        label="Details"
                        placeHolder="e.g. Secure Location"
                        field={milestone.details}
                        characters={30}
                        valueId={`MilestoneDetails${index}`}
                      />
                    </Grid>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </TabPanel>
          {/* Additional Section tab */}
          <TabPanel
            isShown={tabValue === TabMap.additionalSection}
            index={TabMap.additionalSection}>
            <Box p={3} className={classes.tabContainer}>
              {story?.additional.map((additional, index) => (
                <React.Fragment key={index}>
                  <FieldHeading
                    title={`Section ${index + 1}`}
                    subtitle="Add extra detail to your story"
                  />
                  <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                    <Grid item container direction="column">
                      <LabelData
                        label="Heading Title"
                        placeHolder="Heading"
                        field={additional.title}
                        characters={30}
                        valueId={`AdditionalHeading${index}`}
                      />
                      <LabelData
                        label="Content"
                        placeHolder="Add content here"
                        field={additional.content}
                        characters={600}
                        minHeight={250}
                        valueId={`AdditionalContent${index}`}
                      />
                      <LabelData
                        label="Image, File or Video Link"
                        field={
                          additional.mediaType === 'image'
                            ? decodeURIComponent(
                                additional.url.split('/').reverse()[0],
                              ) || '-'
                            : additional.mediaType === 'video'
                            ? additional.url
                            : '-'
                        }
                        valueId={`AdditionalMedia${index}`}
                      />
                    </Grid>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
};

export default ViewTellYourStory;
