import {produce} from 'immer';
import API from '../../config/api';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal} from '../modal/actions';
import CONSTANT from '../../config/constant';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
import {GroupDataTypes, formType, fileType} from './types';
import moment from 'moment';
import axios, {AxiosRequestConfig} from 'axios';
import {authUrls} from '../../utils/auth';
import {NetworkError} from '../../utils/error';
import {TokenService} from '@aglive/data-model';

export function fetchGroupTokens(
  userid: string,
  extraTypes: Array<string> = [],
): AppThunk<Promise<void>> {
  const tokenTypes: Array<string> = [CONSTANT.ASSETTYPE.GROUP];
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: tokenTypes.concat(extraTypes),
          activityType: [],
        },
      });
      // group token by dispatch types
      const filtersByType: Record<string, Array<string>> = {
        FETCH_GROUP_PROJECTS: [CONSTANT.ASSETTYPE.GROUP],
        FETCH_ASSET_PROJECTS: [
          CONSTANT.ASSETTYPE.ASSET,
          CONSTANT.ASSETTYPE.PLANT,
        ],
        FETCH_SITE_TOKENS: [CONSTANT.ASSETTYPE.SITE],
      };
      const tokensByType: Record<string, Array<any>> = response.reduce(
        (result, token) => {
          const filterKey = Object.keys(filtersByType).find((k) =>
            filtersByType[k].includes(token.type),
          );
          if (filterKey) {
            if (!result[filterKey]) {
              result[filterKey] = [];
            }
            result[filterKey].push(token);
          }
          return result;
        },
        {},
      );
      // store state
      Object.entries(tokensByType).forEach(([fetchKey, tokens]) => {
        if (tokens.length) {
          dispatch({
            type: fetchKey,
            payload: tokens,
          });
        }
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export type PlantSite = TokenService.PlantToken['details']['site'];

export function addMoveActivity(
  group: GroupDataTypes,
  originLocation: string,
  originSite: string | PlantSite,
  destLocation: string,
  destSite: string | PlantSite,
  forms: Array<formType>,
  files: Array<fileType>,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {
        auth: {wallet},
      } = getState();
      if (wallet) {
        const fileArray = files.filter((file) => file.url !== '');
        const formArray = forms
          .filter((form) => form.url !== '')
          .map((form) => {
            delete form.checked;
            return form;
          });
        const activity = {
          type: 'move',
          details: {
            destination: {
              location: destLocation,
              site: destSite,
            },
            origin: {
              location: originLocation,
              site: originSite,
            },
          },
        };
        if (formArray.length > 0) {
          activity.details['forms'] = formArray;
        }
        if (fileArray.length > 0) {
          activity.details['files'] = fileArray;
        }

        let groupItemSites: Record<string, PlantSite> | undefined = undefined;
        if (originSite === CONSTANT.MULTIPLE_SITES) {
          const groupItems = await callAPI<Array<TokenService.PlantToken>>({
            url: API.POST.getTokenbyExternalId,
            method: 'POST',
            data: {
              externalIds: group?.details.items,
            },
          });
          groupItemSites = groupItems.reduce((result, item) => {
            const id = item.externalIds[0].agliveToken;
            const value = item.details.site;
            return {...result, [id]: value};
          }, {});
        }

        // Create group activity
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.GROUP,
                externalIds: {agliveToken: group.externalIds[0].agliveToken},
                activities: [activity],
              },
            ],
          },
        });

        const itemActivity = {
          ...activity,
          details: {
            ...activity.details,
            moveWithinGroup: true,
          },
        };

        // individual activities
        for (let i = 0; i < group.details.items?.length; i++) {
          const groupItem = group.details.items[i];
          let currentActivity = itemActivity;
          if (groupItemSites && groupItemSites[groupItem.agliveToken]) {
            const itemOriginSite = groupItemSites[groupItem.agliveToken];
            currentActivity = produce(itemActivity, (draft) => {
              draft.details.origin.site = itemOriginSite;
            });
            await callAPI({
              url: API.POST.createActivity,
              method: 'POST',
              data: {
                tokens: [
                  {
                    externalIds: {agliveToken: groupItem.agliveToken},
                    activities: [currentActivity],
                  },
                ],
              },
            });
          }
        }
      }

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Created',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type csvInputT = [
  Date, //activity date
  string, // name
  string, // item
  string, // by
  string, // grom
  string, //to
];
function generateCSV(codeTokens: Array<csvInputT>, csvName = 'move.csv'): void {
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  const CSV_HEADER = [['Date', 'Name', 'Item', 'By', 'From', 'To']];

  let csvContent =
    'data:text/csv;charset=utf-8,' +
    CSV_HEADER.map((row) => row.join(',')).join('\n') +
    '\n' +
    codeTokens.map((row) => row.join(',')).join('\n');

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', csvName);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file
}

type showListType = {
  activity: {[key: string]: any};
  name: string;
  items: Array<{agliveToken: string}>;
  externalId: string;
  checked: boolean;
};
export function downloadMoveCSV(
  showList: Array<showListType>,
  user: string,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    const selectedActivity = showList.filter((item) => item.checked === true);
    const csvData = [];
    selectedActivity.map((item) => {
      if (item.items) {
        item.items.map((child) => {
          csvData.push([
            moment(item.activity.createAt).format('DD/MM/YYYY'),
            item.name,
            child.agliveToken,
            user,
            item.activity.details.origin.location +
              '  ' +
              item.activity.details.origin.site,
            item.activity.details.destination.location +
              '  ' +
              item.activity.details.destination.site,
          ]);
        });
      } else {
        csvData.push([
          moment(item.activity.createAt).format('DD/MM/YYYY'),
          'Items',
          item.externalId,
          user,
          item.activity.details.origin.location +
            '  ' +
            item.activity.details.origin.site,
          item.activity.details.destination.location +
            '  ' +
            item.activity.details.destination.site,
        ]);
      }
    });
    generateCSV(
      csvData,
      'move' + moment(new Date()).format('DD/MM/YYYY') + '.csv',
    );
  };
}
export async function fetchAllAssets(config: AxiosRequestConfig) {
  try {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    if (
      authUrls
        .filter((url) => url !== API.POST.createUserWithAuth)
        .includes(config.url)
    ) {
      Object.assign(config, {withCredentials: true});
    }

    const response = await axios.request<any>(config);

    return Promise.resolve(response);
  } catch (e) {
    if (e.response?.status === 401 && e.response.data) {
      e.response.data.title = 'Session expired';
      e.response.data.details =
        'Scan the QR code on the portal with the Aglive app to login';
    }
    console.error('callAPI -> error', e);
    throw new NetworkError(e);
  }
}
