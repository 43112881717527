import React, {useState, useEffect, useRef, useMemo} from 'react';
import {useReactToPrint} from 'react-to-print';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import SearchBar from '../../presentation/SearchBar';
import withHeader from '../../presentation/withHeader';
import CodeLibraryTable from './CodeLibraryTable';

import {makeStyles, Grid, Box} from '@material-ui/core';
import {fetchCodeEntries} from '../../store/code/actions';
import {iCodeEntryDocumentResponse} from '../../store/code/types';
import {PlantCodeDocumentDetails} from '../../store/plant/types';
import CodeGridPrint from './Print/CodeGridPrint';
import {INITIAL_CANNABIS_PRINT_STATE} from './utils';
import {useParams} from 'react-router-dom';
import {filterPermits} from '../../store/profile/utils';
import {
  getCodesCompareFuction,
  getCodesFilterFunction,
  useCodeSearchForm,
} from './forms/useCodeSearchForm';
import {fetchAssetProfiles} from '../../store/assetProfile/actions';
import CodeSearchForm from './forms/CodeSearchForm';
import COLOR from '../../styled/colors';

const tableHeader = [
  'ODC Permit',
  'Asset Profile',
  'Available Codes',
  'Date Created',
  'Action',
];

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
  },
  plantLimitNote: {
    margin: 'auto',
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '1rem 2rem',
    fontWeight: 'bold',
  },
}));

const UsedCodes: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {docId} = useParams<{docId: string}>();

  const unprocessedCodeList = useAppSelector((state) => state.code) as Array<
    iCodeEntryDocumentResponse<PlantCodeDocumentDetails>
  >;
  const licenses = useAppSelector((state) =>
    state.user.businessProfileData.industryType === 'PLANTS'
      ? state.user.businessProfileData.licenses ?? []
      : [],
  );
  const odcPermits = useMemo(() => filterPermits('ODC', licenses), [licenses]);
  const usedCodeList = useMemo(() => {
    return unprocessedCodeList
      .filter((entry) => entry.details.used)
      .map((entry) => {
        const permitNumber = odcPermits.find(
          (permit) => permit.permitId === entry.details.permitId,
        )?.permitNumber;
        return {...entry, details: {...entry.details, permitNumber}};
      });
  }, [odcPermits, unprocessedCodeList]);

  const userid = useAppSelector((state) => state.auth.wallet);
  const isCannabis = useAppSelector(
    (state) =>
      state.user.businessProfileData.industryType === 'PLANTS' &&
      state.user.businessProfileData.subIndustryType === 'Cannabis',
  );

  useEffect(() => {
    dispatch(fetchCodeEntries(userid));
  }, []);
  // printing content
  // printing logic - print state holds the information necessary to build modal (assetProfileName, permit, qrCode.length)
  // pressing on 'print' icon will trigger the modal,
  // pressing 'cancel' reset the printState, pressing 'print' will set the content (printContent) on the print prompt (hidden at this stage) (ComponentToPrint)
  // the useEffect hook is triggered on printContent changes and will invoke handlePrint and show the print prompt
  const componentRef = useRef();
  const [cannabisPrintState, setCannabisPrintState] = useState(
    INITIAL_CANNABIS_PRINT_STATE,
  );

  // prevent print dialog to pop up again after redirect from generate asset.
  const [printing, setPrinting] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (printing && cannabisPrintState?.codes?.length > 0) {
      handlePrint();
      setPrinting(false);
    }
  }, [cannabisPrintState, printing, handlePrint]);

  useEffect(() => {
    const codeEntry = unprocessedCodeList.find((code) => code.docId === docId);
    if (!codeEntry) {
      return;
    }
    setPrinting(true);
    setCannabisPrintState({
      startNumber: codeEntry.details.startNumber,
      strain: codeEntry.details.assetProfile.mainDisplay.strain,
      codes: codeEntry.details.codes,
    });
  }, [docId, unprocessedCodeList]);

  useEffect(() => {
    dispatch(fetchAssetProfiles());
  }, [dispatch]);

  const assetProfiles = useAppSelector(
    (state) => state.assetProfile.fetchedAssetProfiles,
  );

  const {
    query,
    setQuery,
    filterItems,
    sortItem,
    handleFilterChange,
    handleSortChange,
    selectedPermit,
  } = useCodeSearchForm({licenses, assetProfiles, archived: true});
  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by ODC Permit or Asset Profile"
          />
        </Grid>
      </Grid>
      <CodeSearchForm
        filterItems={filterItems}
        sortItem={sortItem}
        onFilterChanged={handleFilterChange}
        onSortChanged={handleSortChange}
      />
      {selectedPermit && selectedPermit.maxNumber ? (
        <Grid container item xs={12} style={{marginBottom: '2rem'}}>
          <Box className={classes.plantLimitNote}>
            {`Note: The selected ODC Permit allows a maximum of ${selectedPermit.maxNumber} plant codes to be generated`}
          </Box>
        </Grid>
      ) : null}
      <CodeLibraryTable
        tableHeader={tableHeader}
        codeList={usedCodeList}
        query={getCodesFilterFunction(query, odcPermits, filterItems)}
        compareFunction={getCodesCompareFuction(
          sortItem.selectedItem,
          odcPermits,
        )}
        onPrint={(
          entry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>,
        ) => {
          if (isCannabis) {
            setCannabisPrintState({
              startNumber: entry.details.startNumber,
              strain: entry.details.assetProfile.mainDisplay.strain,
              codes: entry.details.codes,
            });
            setPrinting(true);
            return;
          }
        }}
      />

      <div style={{display: 'none'}}>
        {isCannabis ? (
          <CodeGridPrint
            ref={componentRef}
            startNumber={cannabisPrintState.startNumber}
            qrCodes={cannabisPrintState.codes}
            strain={cannabisPrintState.strain}
          />
        ) : null}
      </div>
    </>
  );
};

export default withHeader(
  {
    title: 'View History',
    back: true,
    id: 'ViewUsedCode',
  },
  UsedCodes,
);
