import {Box, makeStyles, Typography} from '@material-ui/core';
import {useState} from 'react';
import COLOR from '../../styled/colors';

type Props = {
  assetName: string;
  strain: string;
  codes?: Array<string>;
};
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
  },
  body: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  row: {
    display: 'flex',
  },
  rowLabel: {
    width: '25%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  hyperlink: {
    fontFamily: 'Open Sans',
    color: COLOR.GREENT_TEXT,
    textDecoration: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
  },
}));

const PAGE_SIZE = 10;

const PagedAssetTable: React.FC<Props> = ({assetName, strain, codes}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const startIndex = page * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentData = codes.slice(startIndex, endIndex);
  const hasPreviousPage = page > 0;
  const hasNextPage = codes.length > endIndex;
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box>
          <Typography variant="inherit">Asset Profile: </Typography>
          <Typography variant="inherit" style={{fontWeight: 'bold'}}>
            {assetName}
          </Typography>
        </Box>
        <Box>
          <Typography variant="inherit">Strain: </Typography>
          <Typography variant="inherit" style={{fontWeight: 'bold'}}>
            {strain}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.body}>
        <Box className={classes.row}>
          <Box className={classes.rowLabel} style={{fontWeight: 'bold'}}>
            #
          </Box>
          <Box style={{fontWeight: 'bold'}}>Plant Code</Box>
        </Box>
        {currentData?.map((code, i) => (
          <Box className={classes.row} key={`${code}-${i}`}>
            <Box className={classes.rowLabel}>{(page * PAGE_SIZE) + i + 1}</Box>
            <Box>{code}</Box>
          </Box>
        ))}
      </Box>
      <Box className={classes.footer}>
        {hasPreviousPage ? <Box component={'div'} className={classes.hyperlink} onClick={() => setPage(prev => prev - 1)}>Previous</Box> : null}
        {hasNextPage ? <Box component={'div'} className={classes.hyperlink} onClick={() => setPage(prev => prev + 1)}>Next</Box> : null}
      </Box>
    </Box>
  );
};

export default PagedAssetTable;
