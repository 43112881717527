import React, {useEffect, useReducer, useState} from 'react';
import {Link, useHistory, useParams, useLocation} from 'react-router-dom';

import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import {TokenService} from '@aglive/data-model';
import Qrcode from 'qrcode.react';
import {pdf} from '@react-pdf/renderer';

import {PageHeader} from '../../../presentation/withHeader';
import {useStyles} from './styles';
import MyButton from '../../../presentation/button';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {fetchBrands} from '../../../store/brand/actions';
import {processBrandList} from '../CreateAssetPromotion/utils';
import DisplayPoster from '../DisplayPoster';
import {
  createPromotion,
  editPromotion,
  getOnePromotion,
} from '../../../store/promotion/actions';
import {toggleModal, toggleModalOff} from '../../../store/modal/actions';
import ScanPosterModalContent from '../CreateAssetPromotion/ScanPosterModalContent';
import {Buttons} from '../../../presentation/ButtonsGroup';
import {Message} from '../../../presentation/Modal';
import {
  productPromotionReducer,
  INITIAL_STATE,
} from './productPromotionState/reducer';
import {useDownloadPoster} from '../../../utils/promotion';
import LocalActions from './productPromotionState/actions';
import {localDispatchDecorator} from '../ProductProfile/CreateProductProfile/productProfileState/actions';
import {validateData} from './utils';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import PromotionPDF from '../PromotionPDF';
import {fetchProductProfileTokens} from '../ProductProfileLibrary/utils';
import {WebErrorType} from '../../../utils/error';
import {CompleteProductPromotion} from '../../../store/promotion/types';
import CONSTANT from '../../../config/constant';
import {REMOVE_TEMP_PRODUCT} from '../../../store/product/types';

const CREATE_PRODUCT_PROFILE_PATH = '/private/products/productProfile/new';

const CreateProductPromotion: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const {id} = useParams<{id: string}>();

  const userId = useAppSelector((state) => state.auth.wallet);
  const tempProduct = useAppSelector((state) => state.product.tempProduct);

  const [productPromotion, localDispatch] = useReducer(
    productPromotionReducer,
    INITIAL_STATE,
  );
  const localDispatchAction = localDispatchDecorator(localDispatch);
  const [productPromotionValidation, setProductPromotionValidation] = useState(
    INITIAL_STATE.payload,
  );

  // Custom hooks for download poster
  const {
    promotionToDownload,
    setPromotionToDownload,
    QRCodeImage,
    getQRCodeSize,
    downloadPosterHandler,
  } = useDownloadPoster();

  useEffect(() => {
    (async () => {
      try {
        const [productProfiles, brandTokens] = await Promise.all([
          fetchProductProfileTokens(),
          fetchBrands(dispatch),
        ]);

        let selectedProduct = '';
        if (id) {
          const promotionToken = await dispatch(getOnePromotion(id, history, 'PRODUCTS'))
          if (
            promotionToken &&
            promotionToken.details.type === 'PRODUCTS'
          ) {
            const promotionDetails = promotionToken.details;
            localDispatch(
              LocalActions.retrieveProductPromotion(promotionDetails),
            );
            localDispatch(
              LocalActions.editOriginalEndDate(
                promotionDetails.display.endDate,
              ),
            );
            localDispatch(
              LocalActions.editFooterImageOption(
                promotionDetails.display.footerImage ? 'image' : '',
              ),
            );
            if (promotionDetails.product.agliveToken) {
              selectedProduct = promotionDetails.product.agliveToken;
            }
          }
        }

        const filteredProductProfiles = productProfiles.filter(
          (productProfile) =>
            !productProfile.details.archive ||
            productProfile.externalIds.find(
              (externalId) => 'agliveToken' in externalId,
            ).agliveToken === selectedProduct,
        );
        if (filteredProductProfiles.length > 0) {
          if (brandTokens) {
            const tempBrandList = processBrandList(brandTokens);
            for (const productProfile of filteredProductProfiles) {
              const brandIndex = tempBrandList.findIndex(
                (brand) =>
                  brand.agliveToken ===
                  productProfile.details.brand.agliveToken,
              );
              if (
                productProfile.details.story.logoType === 'brand' &&
                brandIndex !== -1
              ) {
                productProfile.details.story.logo =
                  tempBrandList[brandIndex].logo;
              }
            }
          }
          localDispatch(
            LocalActions.populateProductProfiles(filteredProductProfiles),
          );
          if (selectedProduct) {
            localDispatch(
              LocalActions.selectProduct(selectedProduct),
            );
          }
        }
      } catch (e) {
        const error = e as WebErrorType;

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    })();
  }, [dispatch, userId, id]);

  // Validate input on keyup after submit button is clicked
  useEffect(() => {
    if (productPromotion.state.submitted) {
      const [validatedData] = validateData(
        productPromotion.payload,
        productPromotion.state.footerImageOption,
        productPromotion.state.submitted,
      );
      setProductPromotionValidation(validatedData);
    }
  }, [
    productPromotion.payload,
    productPromotion.state.footerImageOption,
    productPromotion.state.submitted,
  ]);

  // Prompt success modal only after the poster PDF is ready (useEffect below will only run for published promotion)
  useEffect(() => {
    if (promotionToDownload && QRCodeImage.length > 0) {
      promptSuccessModal(promotionToDownload, 'publish');
    }
  }, [promotionToDownload, QRCodeImage]);

  useEffect(() => {
    /**
     * If temp product is found in redux, it means that the user just
     * returned from create product profile page. Thus, populate the dropdown
     * with the created product profile and remove the temp product from redux store.
     */
    if (tempProduct && productPromotion.state.productProfiles.length > 0) {
      localDispatch(
        LocalActions.selectProduct(
          tempProduct.externalIds.find(
            (externalId) => 'agliveToken' in externalId,
          ).agliveToken,
        ),
      );
      dispatch({type: REMOVE_TEMP_PRODUCT});
    }
  }, [tempProduct, dispatch, productPromotion.state.productProfiles]);

  const handleSave = async (type: 'draft' | 'publish') => {
    localDispatch(LocalActions.setSubmitFlag(type));
    const [validatedData, error] = validateData(
      productPromotion.payload,
      productPromotion.state.footerImageOption,
      type,
    );
    setProductPromotionValidation(validatedData);

    const hasDate = productPromotion.payload.display.startDate && productPromotion.payload.display.endDate;

    if (
      (type === 'publish' && !hasDate) || 
        (hasDate && (moment(productPromotion.payload.display.endDate).valueOf() <
          moment(productPromotion.payload.display.startDate).valueOf() ||
        moment(productPromotion.payload.display.endDate).valueOf() <
          moment().valueOf()))
    ) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Error',
          subtitle: 'Valid expiry date required',
          button: 'Close',
          renderButton: (
            <Buttons
              leftButtonTitle="Cancel"
              rightButtonTitle="Set Date"
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
                history.push('/private/codes/promotionCodes');
              }}
              rightButtonOnClick={() => {
                dispatch(toggleModalOff());
              }}
            />
          ),
        }),
      );
    } else if (error === 0) {
      let promotionToken: void | TokenService.PromotionToken;
      if (!id) {
        promotionToken = await dispatch(
          createPromotion(productPromotion.payload, type === 'publish'),
        );
      } else {
        await dispatch(
          editPromotion(productPromotion.payload, id, type === 'publish'),
        );
        promotionToken = await dispatch(
          getOnePromotion(id, history, 'PRODUCTS'),
        );
      }

      if (
        promotionToken &&
        promotionToken.details.type === 'PRODUCTS' &&
        type === 'publish'
      ) {
        const productProfileDetails =
          productPromotion.state.productProfiles[
            productPromotion.state.selectedProductProfile
          ]?.details;
        const completeProductPromotion: CompleteProductPromotion = {
          ...promotionToken,
          details: {
            productName: productProfileDetails.name,
            ...productProfileDetails,
            ...promotionToken.details,
          },
        };
        setPromotionToDownload(completeProductPromotion as TokenService.PromotionToken);
      } else if (promotionToken) {
        promptSuccessModal(promotionToken, 'draft');
      }
    }
  };

  const promptSuccessModal = (
    promotionToken: TokenService.PromotionToken,
    type: 'draft' | 'publish',
  ) => {
    const payload: Message = {
      status: 'success',
      title: type === 'publish' ? 'Published' : 'Saved',
      button: 'Close',
      customContent: (
        <ScanPosterModalContent
          story={
            productPromotion.payload.product.agliveToken
              ? productPromotion.state.productProfiles[
                  productPromotion.state.selectedProductProfile
                ]?.details.story
              : undefined
          }
          display={productPromotion.payload.display}
          status={promotionToken.details.status}
          footerImageOption={
            productPromotion.payload.display.footerImage ? 'image' : ''
          }
          agliveToken={promotionToken.externalIds[0].agliveToken}
          brandLogo={
            productPromotion.state.productProfiles[productPromotion.state.selectedProductProfile].details.story?.logo || null
          }
        />
      ),
      width: 520,
      CTAHandler: () => {
        history.push('/private/codes/promotionCodes');
      },
    };

    if (type === 'publish') {
      delete payload.CTAHandler;
      payload.renderButton = (
        <Buttons
          leftButtonTitle="Close"
          rightButtonTitle="Download"
          leftButtonOnClick={() => {
            dispatch(toggleModalOff());
            history.push('/private/codes/promotionCodes');
          }}
          rightButtonOnClick={downloadPoster}
        />
      );
    }

    dispatch(toggleModal(payload));
  };

  const downloadPoster = async () => {
    if (promotionToDownload) {
      dispatch({type: SPINNER_TOGGLE_ON});
      const QRSize = await getQRCodeSize(promotionToDownload, false);
      const blob = await pdf(
        <PromotionPDF
          story={promotionToDownload?.details['story']}
          display={promotionToDownload?.details.display}
          QRCodeImage={QRCodeImage}
          QRSize={QRSize}
          isAngus={false}
        />,
      ).toBlob();
      downloadPosterHandler(blob, promotionToDownload?.details.name);
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  const CreateProductPromotionContent = (
    <>
      <Grid container spacing={8} justifyContent="center">
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Promotion Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={productPromotion.payload.name}
            onChange={(e) => {
              localDispatch(LocalActions.editName(e.target.value));
            }}
            error={!!productPromotionValidation.name}
            helperText={productPromotionValidation.name}
            id="PromotionName-TextField"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Product Name
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!productPromotionValidation.product.agliveToken}>
            <InputLabel id="create-productPromotion-select-product">
              Please select
            </InputLabel>
            <Select
              value={
                productPromotion.state.productProfiles.length > 0
                  ? productPromotion.payload.product.agliveToken
                  : ''
              }
              labelId="create-productPromotion-select-product"
              label="Please select"
              onChange={(e) => {
                localDispatch(
                  LocalActions.selectProduct(e.target.value as string),
                );
              }}
              id="ProductProfile-Dropdown">
              {productPromotion.state.productProfiles.map((productProfile) => (
                <MenuItem
                  value={productProfile.externalIds[0].agliveToken}
                  key={productProfile.externalIds[0].agliveToken}>
                  {productProfile.details.name}
                </MenuItem>
              ))}
            </Select>
            <Grid
              container
              justify={
                !!productPromotionValidation.product.agliveToken
                  ? 'space-between'
                  : 'flex-end'
              }>
              {!!productPromotionValidation.product.agliveToken && (
                <FormHelperText id="ProductProfile-helper-text">
                  {productPromotionValidation.product.agliveToken}
                </FormHelperText>
              )}
              <Link
                to={{
                  pathname: CREATE_PRODUCT_PROFILE_PATH,
                  state: {prevLocation: location.pathname},
                }}
                className={classes.hyperlink}>
                Create a product now
              </Link>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.stepContainer}>
        <DisplayPoster
          display={productPromotion.payload.display}
          story={
            productPromotion.payload.product.agliveToken
              ? productPromotion.state.productProfiles[
                  productPromotion.state.selectedProductProfile
                ]?.details.story
              : undefined
          }
          status={productPromotion.payload.status}
          footerImageOption={productPromotion.state.footerImageOption}
          validationState={productPromotionValidation.display}
          onFooterImageOptionChange={localDispatchAction(
            LocalActions.editFooterImageOption,
          )}
          onFooterImageChange={localDispatchAction(
            LocalActions.editFooterImage,
          )}
          onStartDateChange={localDispatchAction(LocalActions.editStartDate)}
          onEndDateChange={localDispatchAction(LocalActions.editEndDate)}
          qrOverlay={true}
          originalEndDate={productPromotion.state.originalEndDate}
          brandLogo={
            productPromotion.state.productProfiles[productPromotion.state.selectedProductProfile]?.details.story?.logo || null
          }
        />
      </Grid>
      <Grid item container justifyContent="flex-end" spacing={3}>
        {productPromotion.payload.status === 'draft' && (
          <Grid item>
            <MyButton
              text="Save as Draft"
              variant="outlined"
              buttonClass={classes.contentButton}
              onClick={() => {
                handleSave('draft');
              }}
            id="SaveAsDraftBtn"
            />
          </Grid>
        )}
        <Grid item>
          <MyButton
            text="Save and Publish"
            variant="contained"
            onClick={() => {
              handleSave('publish');
            }}
            id="SaveAndPublishBtn"
          />
        </Grid>
      </Grid>

      <div style={{display: 'none'}}>
        <Qrcode
          value={
            promotionToDownload?.externalIds[0].agliveToken
              ? `${CONSTANT.SCAN_URL(
                  promotionToDownload?.externalIds[0].agliveToken,
                )}`
              : 'Invalid code'
          }
          size={256}
          id="qrCode"
        />
      </div>
    </>
  );

  return (
    <PageHeader
      config={{
        title: id ? 'Edit Promotion Code' : 'Create Promotion Code',
        margin: 60,
        back: true,
      }}>
      {CreateProductPromotionContent}
    </PageHeader>
  );
};

export default CreateProductPromotion;
