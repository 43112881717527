import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Divider, Grid, Switch} from '@material-ui/core';
import DialogMessage from '../../presentation/DialogMessage';
import {CustomCheckbox} from '../asset/MoveTable';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {sendAlertSetting} from '../../store/profile/actions';
import {businessProfileTypes} from '../../store/profile/types';

export const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(3),
  },
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 600, // TODO
  },
}));

type AlertSettingModalProps = {
  pic: string;
  open: boolean;
  handleClose: () => void;
  rightButtonClick: () => void;
  isSettingCard?: boolean;
};

const AlertSettingModal: React.FC<AlertSettingModalProps> = (props) => {
  const businessProfileData: businessProfileTypes = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const [editButton, setEditButton] = useState(true);
  const dispatch = useAppDispatch();
  const {businessId} = useAppSelector((state) => state.user.userProfileData);
  const locations = businessProfileData.location;
  const tagAlertStandard = locations.find((loc) => loc.PICAddress === props.pic)?.tagAlertStandard;

  const [alertLevel, setAlertLevel] = useState({
    extremlyHighAlert: tagAlertStandard?.activityLevel?.extreme ?? true,
    highAlert: tagAlertStandard?.activityLevel?.high ?? false,
    mediumAlert: tagAlertStandard?.activityLevel?.medium ?? false,
    lowAlert: tagAlertStandard?.activityLevel?.low ?? true,
    noAlert: tagAlertStandard?.activityLevel?.no ?? false,
  });
  const [locationAlert, setLocationAlert] = useState(
    tagAlertStandard?.location ?? false,
  );
  const [activityAlert, setActivityAlert] = useState(
    alertLevel.extremlyHighAlert ||
      alertLevel.highAlert ||
      alertLevel.mediumAlert ||
      alertLevel.lowAlert ||
      alertLevel.noAlert,
  );
  const [inactivityAlert, setInactivityAlert] = useState(
    tagAlertStandard?.inactive ?? false
  )
  const [disableLocation, setDisableLocation] = useState(true);
  const [disableActivity, setDisableActivity] = useState(true);
  const [disableInactive, setDisableInactive] = useState(true);
  const setInitialAlertLevel = (
    exterme: boolean,
    high: boolean,
    medium: boolean,
    low: boolean,
    no: boolean,
  ) => {
    setAlertLevel({
      extremlyHighAlert: exterme,
      highAlert: high,
      mediumAlert: medium,
      lowAlert: low,
      noAlert: no,
    });
  };

  useEffect(() => {
    if (activityAlert === false) {
      setInitialAlertLevel(false, false, false, false, false);
    }
  }, [activityAlert]);

  useEffect(() => {
    setLocationAlert(tagAlertStandard?.location ?? false);
    if (tagAlertStandard?.activityLevel) {
      setInitialAlertLevel(
        tagAlertStandard.activityLevel.extreme,
        tagAlertStandard.activityLevel.high,
        tagAlertStandard.activityLevel.medium,
        tagAlertStandard.activityLevel.low,
        tagAlertStandard.activityLevel.no,
      );
      setLocationAlert(tagAlertStandard.location);
    } else {
      setInitialAlertLevel(true, false, false, true, false);
      setLocationAlert(false);
    }
  }, [tagAlertStandard?.location, tagAlertStandard?.activityLevel]);

  return (
    <DialogMessage
      open={props.open}
      isSettingCard={props.isSettingCard}
      title={`Alert Settings`}
      handleClose={props.handleClose}
      leftButtonText={editButton ? 'Edit' : ''}
      leftButtonClick={() => {
        setEditButton(!editButton);
        setDisableLocation(false);
        setDisableActivity(false);
        setDisableInactive(false);
      }}
      rightButtonText={editButton ? '' : 'Save'}
      rightButtonClick={() => {
        dispatch(
          sendAlertSetting(businessId, props.pic, {
            noActivity: alertLevel.noAlert,
            lowActivity: alertLevel.lowAlert,
            mediumActivity: alertLevel.mediumAlert,
            highActivity: alertLevel.highAlert,
            extremelyHighActivity: alertLevel.extremlyHighAlert,
            location: locationAlert,
            inactive: inactivityAlert
          }),
        );
        props.rightButtonClick();
      }}>
      <Grid container style={{marginTop: 20}}>
        <Grid container>
          <Grid container alignItems="center" justifyContent='space-between'>
            <Grid id="GeofenceLocationAlerts" item component="span">
              {`Geofence breached`}
            </Grid>
            <Grid item component="span">
              <Switch
                checked={locationAlert}
                onChange={() => {
                  if (!disableLocation) {
                    setLocationAlert(!locationAlert);
                  }
                }}
                color="primary"
                name="checkedLocation"
                id="checkedLocationSwitch"
              />
            </Grid>
          </Grid>
          <Divider style={{width: '100%', marginTop: 10, marginBottom: 10}}/>
          <Grid container alignItems="center" justifyContent='space-between'>
            <Grid item component="span" id="ActivityLevelAlerts">
              {`Set animal activity level`}
            </Grid>
            <Grid item component="span">
              <Switch
                checked={activityAlert}
                onChange={() => {
                  if (!disableActivity) {
                    setActivityAlert(!activityAlert);
                  }
                }}
                color="primary"
                name="checkedActivity"
                id="checkedActivitySwitch"
              />
            </Grid>
          </Grid>
          {activityAlert && (
            <Grid container item xs={12} direction="column">
              <Grid container alignItems="center">
                <CustomCheckbox
                  id="ExtremelyHighCheckbox"
                  checked={alertLevel.extremlyHighAlert}
                  disabled={disableActivity}
                  onChange={() =>
                    setAlertLevel((oldState) => ({
                      ...oldState,
                      extremlyHighAlert: !alertLevel.extremlyHighAlert,
                    }))
                  }
                />
                <Grid item component="span">
                  {'Extremely High'}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <CustomCheckbox
                  id="HighCheckbox"
                  checked={alertLevel.highAlert}
                  disabled={disableActivity}
                  onChange={() =>
                    setAlertLevel((oldState) => ({
                      ...oldState,
                      highAlert: !alertLevel.highAlert,
                    }))
                  }
                />
                <Grid item component="span">
                  {'High'}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <CustomCheckbox
                  id="MediumCheckbox"
                  checked={alertLevel.mediumAlert}
                  disabled={disableActivity}
                  onChange={() =>
                    setAlertLevel((oldState) => ({
                      ...oldState,
                      mediumAlert: !alertLevel.mediumAlert,
                    }))
                  }
                />
                <Grid item component="span">
                  {'Medium'}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <CustomCheckbox
                  id="LowCheckbox"
                  checked={alertLevel.lowAlert}
                  disabled={disableActivity}
                  onChange={() =>
                    setAlertLevel((oldState) => ({
                      ...oldState,
                      lowAlert: !alertLevel.lowAlert,
                    }))
                  }
                />
                <Grid item component="span">
                  {'Low'}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <CustomCheckbox
                  id="NoCheckbox"
                  checked={alertLevel.noAlert}
                  disabled={disableActivity}
                  onChange={() =>
                    setAlertLevel((oldState) => ({
                      ...oldState,
                      noAlert: !alertLevel.noAlert,
                    }))
                  }
                />
                <Grid item component="span">
                  {'No'}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Divider style={{width: '100%', marginTop: 10, marginBottom: 10}}/>
          <Grid container alignItems="center" justifyContent='space-between'>
            <Grid item component="span" id="InactivityLevelAlerts">
              {`No data received for 24 hours`}
            </Grid>
            <Grid item component="span">
              <Switch
                checked={inactivityAlert}
                onChange={() => {
                  if (!disableInactive) {
                    setInactivityAlert(!inactivityAlert);
                  }
                }}
                color="primary"
                name="checkedInactivity"
                id="checkedInactivitySwitch"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogMessage>
  );
};

export default AlertSettingModal;
