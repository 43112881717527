import {PluginModel} from '@aglive/data-model';
import moment from 'moment';
import API from '../../config/api';
import {tokenService} from '../../env';
import {MultipleSelectOptionsType} from '../../presentation/MultipleSelect';
import {HyperLink} from '../../presentation/word';

const plantSubPages = [
  'treatment',
  'movement',
  'transfer',
  'inventory',
] as const;

export function getSubpages(isPlant: boolean) {
  return isPlant ? plantSubPages : [];
}

export type SubPageType = string | (typeof plantSubPages)[number];

const PlantPayloads = [
  'treatment',
  'movement',
  'transfer',
  'inventory',
] as const;
const plantPayloadType: Record<(typeof PlantPayloads)[number], any> = {
  treatment: 'addTreatment',
  movement: 'move',
  transfer: 'transfer',
  inventory: null,
};

const payloadType = {};

export function getReportPayloadType(isPlant: boolean): {
  [key in string | (typeof PlantPayloads)[number]]?: any;
} {
  return isPlant ? plantPayloadType : payloadType;
}

type ReportColumnFieldType = 'text' | 'multiSelect' | 'dateRange';
type AutofillOption =
  | 'site'
  | 'siteLayer'
  | 'strain'
  | 'species'
  | 'substrain'
  | 'assetName';

type ReportTableRowRenderer = (value: any) => React.ReactNode;

export type ReportColumn = {
  name: string;
  title: string;
  field?: string;
  customFilter?: boolean;
  type?: ReportColumnFieldType;
  numberDisplayed?: number;
  viewDetails?: boolean;
  columnSize?: number; // the grid xs value for viewDetails
  tableColumns?: Array<string>;
  displayTable?: boolean;
  tableRowsRenderer?: Record<string, ReportTableRowRenderer>;
  optionNames?: Array<string>;
  dropdownOptions?: Array<{label: string; value: string}>;
  autofillOptions?: AutofillOption; // column options not in api response
  url?: string;
};

type ColumnRecord<K extends string, V> = {
  ceres?: V;
  management: {
    [key in K]?: V;
  };
};

type InitialColumns = ColumnRecord<SubPageType, Array<ReportColumn>>;

const PLANT_TREATMENT_COLUMNS: Array<ReportColumn> = [
  {
    name: 'plant_code',
    title: 'Plant Code',
    customFilter: true,
    type: 'text',
  },
  {
    name: 'treatment',
    optionNames: ['product_name'],
    title: 'Treatment',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'beneficial_bugs',
    optionNames: ['beneficial_bugs'],
    title: 'Beneficial Bugs',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'chemical_control',
    optionNames: ['chemical_control'],
    title: 'Chemical Control',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'nutrients',
    optionNames: ['nutrients'],
    title: 'Nutrients',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'treatment_date',
    title: 'Record Date',
  },
  {
    name: 'treated_by',
    optionNames: ['treated_by'],
    title: 'Treated By',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'note',
    title: 'Note',
    customFilter: false,
    viewDetails: true,
    columnSize: 12,
  },
  {
    name: 'attachments',
    title: 'Attachments',
    customFilter: false,
    viewDetails: true,
    columnSize: 12,
    displayTable: true,
    tableColumns: ['Name'],
    tableRowsRenderer: {
      Name: (val: {attachmentName: string; attachmentURL: string}) => {
        return (
          <HyperLink href={val.attachmentURL} target="_blank">
            {val.attachmentName}
          </HyperLink>
        );
      },
    },
  },
];

const PLANT_MOVEMENT_COLUMNS: Array<ReportColumn> = [
  {name: 'plant_code', title: 'Plant Code', customFilter: true, type: 'text'},
  {
    name: 'origin',
    title: 'Origin',
    customFilter: true,
    type: 'multiSelect',
    autofillOptions: 'siteLayer',
  },
  {
    name: 'destination',
    title: 'Destination',
    autofillOptions: 'siteLayer',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'strain',
    title: 'Strain',
    customFilter: true,
    type: 'multiSelect',
    autofillOptions: 'strain',
  },
  {
    name: 'species',
    title: 'Species',
    customFilter: true,
    type: 'multiSelect',
    autofillOptions: 'species',
  },
  {
    name: 'sub_strain',
    title: 'Sub Strain',
    customFilter: true,
    type: 'multiSelect',
    autofillOptions: 'substrain',
  },
  {
    name: 'asset_name',
    title: 'Asset Name',
    customFilter: true,
    type: 'multiSelect',
    autofillOptions: 'assetName',
  },
  {
    name: 'timestamp',
    title: 'Date Moved',
  },
];

const PLANT_TRANSFER_COLUMNS: Array<ReportColumn> = [
  {
    name: 'plant_code',
    title: 'Plant Code',
    customFilter: true,
    type: 'text',
  },
  {
    name: 'origin',
    title: 'Origin',
    customFilter: true,
    type: 'multiSelect',
    optionNames: ['assets.details.pic_id'],
  },
  {
    name: 'sender',
    title: 'Sender',
    optionNames: ['sender.name'],
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'destination',
    title: 'Destination',
    optionNames: ['destination.pic', 'consignee.pic'],
    customFilter: true,
    type: 'multiSelect',
    field: 'destination.locationName',
  },
  {
    name: 'company',
    title: 'Company',
    customFilter: true,
    type: 'multiSelect',
    optionNames: ['NDLicenseHolder.companyName', 'receiver.companyName'],
  },
  {
    name: 'consignee',
    optionNames: ['consigneeDetails.receiverName'],
    title: 'Consignee',
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'date_transferred',
    title: 'Date Transferred',
    customFilter: true,
    type: 'dateRange',
  },
];

const PLANT_INVENTORY_COLUMNS: Array<ReportColumn> = [
  {
    name: 'site_map',
    title: 'Site Name',
    optionNames: ['siteDetails.siteName'],
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'site_layer',
    title: 'Site Layer',
    optionNames: ['site.layerName'],
    customFilter: true,
    type: 'multiSelect',
  },
  {
    name: 'plant_amount',
    title: 'No. of Plants',
  },
];

export const initialPlantColumns: InitialColumns = {
  management: {
    treatment: PLANT_TREATMENT_COLUMNS,
    movement: PLANT_MOVEMENT_COLUMNS,
    transfer: PLANT_TRANSFER_COLUMNS,
    inventory: PLANT_INVENTORY_COLUMNS,
  },
};

export type FilteringStateColumn = {
  columnName: string;
  filteringEnabled: boolean;
};
export type SortingStateColumn = {columnName: string; sortingEnabled: boolean};

const disabledChooserColumnExtensions = {
  management: {},
};

type HideColumn = {
  columnName: string;
};

const plantHideColumnExtensions: Record<
  string,
  HideColumn | Record<SubPageType, Array<HideColumn>> | {}
> = {
  management: {
    treatment: [{columnName: 'note'}, {columnName: 'attachments'}],
  },
};

const disabledPlantFilterColumnExtensions: ColumnRecord<
  SubPageType,
  Array<FilteringStateColumn>
> = {
  management: {
    treatment: [
      {columnName: 'note', filteringEnabled: false},
      {columnName: 'attachment', filteringEnabled: false},
      {columnName: 'treatment_date', filteringEnabled: false},
    ],
    inventory: [{columnName: 'plant_amount', filteringEnabled: false}],
    movement: [{columnName: 'timestamp', filteringEnabled: false}],
  },
};

function setSorting(colName: string, sorted: boolean) {
  return {
    columnName: colName,
    sortingEnabled: sorted,
  };
}

type SortColumnsProps = {
  columns: Array<ReportColumn>;
  defaultVal: boolean;
  exceptColumns?: Array<string>;
};

function sortColumns({columns, defaultVal, exceptColumns}: SortColumnsProps) {
  return columns.map((column) => {
    return setSorting(
      column.name,
      exceptColumns?.includes(column.name) ? !defaultVal : defaultVal,
    );
  });
}

const disabledPlantSortingColumnExtensions: ColumnRecord<
  SubPageType,
  Array<SortingStateColumn>
> = {
  management: {
    treatment: sortColumns({
      columns: initialPlantColumns.management.treatment,
      defaultVal: false,
    }),
    movement: sortColumns({
      columns: initialPlantColumns.management.movement,
      defaultVal: false,
    }),
    transfer: sortColumns({
      columns: initialPlantColumns.management.transfer,
      defaultVal: false,
    }),
    inventory: sortColumns({
      columns: initialPlantColumns.management.inventory,
      defaultVal: false,
      exceptColumns: ['site_layer'],
    }),
  },
};

type TableAlignType = 'left' | 'center' | 'right';
const defaultRowFields: ColumnRecord<SubPageType, Record<string, any>> = {
  ceres: {
    ceres_tag_esn: '',
    ceres_tag_vid: '',
    pic_name: '',
    sex: '',
    observationDateUTC: '',
    latitude: 0,
    longitude: 0,
    activityLevel: 0,
    temperature: 0,
  },
  management: {},
} as const;

function defaultFieldValueReducer(value: any) {
  return function (
    fields: ColumnRecord<SubPageType, Record<string, any>>,
    col: ReportColumn,
  ) {
    return {...fields, [col.name]: value};
  };
}

function getDefaultPlantRowFields(subpage: SubPageType, value: any) {
  return initialPlantColumns.management[subpage]?.reduce(
    defaultFieldValueReducer(''),
    {} as ColumnRecord<SubPageType, Record<string, any>>,
  );
}

const defaultPlantRowFields: ColumnRecord<SubPageType, Record<string, any>> = {
  management: {
    treatment: getDefaultPlantRowFields('treatment', ''),
    movement: getDefaultPlantRowFields('movement', ''),
    transfer: getDefaultPlantRowFields('transfer', ''),
    inventory: getDefaultPlantRowFields('inventory', ''),
  },
} as const;

export type RowFields = typeof defaultRowFields | typeof defaultPlantRowFields;

const weightUnits = ['LBS', 'KG'];
export type WeightUnits = typeof weightUnits;
export const sex = ['Male', 'Female'];
export const sexList = sex.map((sex) => ({label: sex, value: sex}));
type sexListType = typeof sexList;
const species = ['Beef', 'Goat', 'Cattle'];
const speciesType = ['Angus', 'Meat', 'Dairy Breeds', 'Beef'];
const breedsList = [
  'angus',
  'Holstein / Friesian',
  'Anvus',
  'Billy',
  'Angus',
  'Friesian - Jersey',
  'Avv',
  'Bos Taurus',
  'Unknown',
  'Friesian',
  'Holstein',
  'JERSEY',
  'Holstein / Jersey',
];
const statusList = ['Dead', 'Sold', 'Exported'];
const birthSeasonList = ['Spring', 'Autumn'];
export type Species = typeof species;
export type SpeciesType = typeof speciesType;
export type BreedsList = typeof breedsList;
export type StatusList = typeof statusList;
export type BirthSeasonList = typeof birthSeasonList;
export interface FormMapT {
  key?: string;
  label: string;
  value: string;
  subfields?: Array<Subfield>;
  outerFilterFields?: Array<OuterFilterField>;
  hide?: boolean;
  dateFormat?: string;
  formatCsv?: boolean;
  hidePagination?: boolean;
  group?: string;
}
export type Subfield = {
  label: string;
  sublabel?: string;
  sublabel2?: string;
  key: string;
  key2?: string;
  type: 'number' | 'date' | 'select' | 'multiselect' | 'text';
  required: boolean;
  options?: Array<string>;
};

export type OuterFilterField = {
  label?: string;
  // sublabel?: string;
  // sublabel2?: string;
  key: string;
  key2?: string;
  secondKey?: string;
  defaultValue?: string | Array<string>;
  type: 'number' | 'text' | 'dateRange' | 'multiselect' | 'date' | 'dropdown';
  variant?: 'standard' | 'outlined';
  required: boolean;
  inputProps?: {min: number; step: number};
  disablePast?: boolean;
  options?: Array<{label: string; value: string}>; // for multiselect options
  customUpdate?: boolean; // update inner filter when outer filter changes, e.g. site_moved_to
  customOther?: boolean;
  disableWeek?: string;
  viewDetails?: boolean;
  columnSize?: string;
};

export type Lists = {
  unit?: WeightUnits;
  sex?: typeof sex;
  species?: Species;
  species_type?: SpeciesType;
  breed?: BreedsList;
  breed_type?: Array<string>;
};

//generic
const dateRangeField = (key: string, label: string): OuterFilterField => ({
  key: key,
  key2: 'dates',
  label: label,
  type: 'date',
  required: true,
});

// ceres
const startDate = (): OuterFilterField => ({
  key: 'start_date',
  key2: 'dates',
  label: 'Start Date',
  type: 'date',
  required: true,
});

const endDate = (): OuterFilterField => ({
  key: 'end_date',
  key2: 'dates',
  label: 'End Date',
  type: 'date',
  required: true,
});

const locations = (): OuterFilterField => ({
  key: 'locations',
  key2: 'locationPicAddr',
  type: 'multiselect',
  required: true,
  customOther: true,
});

// treatment
const treatmentDateRangeFrom = (label?: string): OuterFilterField =>
  dateRangeField('start_date', label ?? 'Treatment Date Range (From)');

const treatmentDateRangeTo = (label?: string): OuterFilterField =>
  dateRangeField('end_date', label ?? 'Treatment Date Range (To)');

// transfer
const transferType = (): OuterFilterField => ({
  key: 'transferType',
  label: 'Transfer Type',
  type: 'dropdown',
  required: true,
  options: [
    {
      label: 'Transfer In',
      value: 'in',
    },
    {
      label: 'Transfer Out',
      value: 'out',
    },
  ],
});

// inventory
const inventoryLicense = (
  options?: Array<{label: string; value: string}>,
): OuterFilterField => ({
  key: 'licenseNumber',
  label: 'License Number',
  type: 'dropdown',
  required: true,
  options: options ?? [],
});

type PlantConfig = {
  licenses: Array<string>;
};

export const getFormMap = (
  page: string,
  plantConfig: PlantConfig,
  profileOverride?: Array<PluginModel.PluginReport>,
  includeDefaults?: boolean,
) => {
  if (page === 'ceres') {
    return [
      {
        label: 'Ceres Tag Report',
        key: 'ceres',
        value: API.POST.getFilteredToken,
        outerFilterFields: [startDate(), endDate(), locations()],
      },
    ];
  } else if (page === 'management') {
    let list = getManagementFormMap(plantConfig?.licenses);
    if (profileOverride?.length) {
      const add = profileOverride.map((pf) => {
        return {
          label: pf.type as string,
          key: pf.type.replace(/\s+/g, '_').toLowerCase(),
          value: pf['apiPath']
            ? tokenService + pf['apiPath']
            : API.POST.getFilteredToken, // Todo add api path to json
          outerFilterFields: pf.outerFilters,
          hide: false,
          dateFormat: pf['dateFormat'],
          hidePagination: pf['hidePagination'],
          group: pf.group ?? '',
        };
      });
      if (includeDefaults) {
        list = [...list, ...add];
      } else {
        return add;
      }
    }
    return list;
  } else if (profileOverride?.length) {
    return profileOverride
      .filter((pf) => pf['group'] === page.replace(/\s/g, '-'))
      .map((pf) => {
        return {
          label: pf.type,
          key: pf.type,
          value: API.POST.getFilteredToken,
          outerFilterFields: pf.outerFilters,
        };
      });
  }
};

function getPlantManagementFormMap(
  licenseOptions?: Array<string>,
): Array<FormMapT> {
  return [
    {
      label: 'Treatment Report',
      key: 'treatment',
      value: API.POST.getFilteredToken,
      outerFilterFields: [
        treatmentDateRangeFrom('Start Date'),
        treatmentDateRangeTo('End Date'),
      ],
      dateFormat: 'DD/MM/YYYY',
      formatCsv: true,
      group: 'Cannabis'
    },
    {
      label: 'Movement Report',
      key: 'movement',
      value: API.POST.getFilteredToken,
      outerFilterFields: [
        dateRangeField('start_date', 'Start Date'),
        dateRangeField('end_date', 'End Date'),
      ],
      dateFormat: 'DD/MM/YYYY',
      formatCsv: true,
      group: 'Cannabis'
    },
    {
      label: 'Transfer Report',
      key: 'transfer',
      value: API.POST.getFilteredToken,
      outerFilterFields: [transferType()],
      dateFormat: 'DD/MM/YYYY',
      formatCsv: true,
      group: 'Cannabis'
    },
    {
      label: 'Inventory Report',
      key: 'inventory',
      value: API.POST.getFilteredToken,
      outerFilterFields: [
        inventoryLicense(
          licenseOptions.map((x) => ({
            label: x,
            value: x,
          })),
        ),
      ],
      group: 'Cannabis'
    },
  ];
}

function getManagementFormMap(licenseOptions?: Array<string>) {
  return !!licenseOptions ? getPlantManagementFormMap(licenseOptions) : []; //MANAGEMENT_FORM_MAP;
}

export type FieldConditionMetadata = {
  name: string;
  field?: string;
  alternateField?: string;
  display?: boolean;
  dateField?: boolean;
  source?: string;
  getList?: boolean; //return a dropdown list for use in table
  getListFromActivity?: boolean;
  filterValue?: string | Array<string>;
  type?: string; // filter type - defaults to '$eq'
  aggregate?: string; // aggregated field
  preField?: boolean;
};

const PLANT_TRANSFER_IN_FIELDS = {
  plant_code: {
    name: 'Plant Code',
    display: true,
    field: 'assets.details.label',
  },
  origin: {
    name: 'Origin',
    display: true,
    field: 'assets.details.pic_id',
    getListFromActivity: true,
  },
  sender: {
    name: 'Sender',
    display: true,
    field: 'sender.name',
    getList: true,
  },
  destination: {
    name: 'Destination',
    display: true,
    field: 'destination.locationName',
    getList: true,
  },
  company: {
    name: 'Company',
    display: true,
    field: 'receiver.companyName',
    getList: true,
  },
  consignee: {
    name: 'Consignee',
    display: true,
    field: 'consigneeDetails.receiverName',
    getList: true,
  },
  date_transferred: {
    name: 'Date Transferred',
    display: true,
    field: 'createdAt',
    dateField: true,
  },
};

export const PLANT_TRANSFER_OUT_FIELDS: Record<string, FieldConditionMetadata> =
  {
    plant_code: {
      name: 'Plant Code',
      display: true,
      field: 'assets.details.label',
      source: 'activities',
    },
    origin: {
      name: 'Origin',
      display: true,
      field: 'assets.details.pic_id',
      source: 'activities',
      getListFromActivity: true,
    },
    sender: {
      name: 'Sender',
      display: true,
      field: 'sender.name',
      getListFromActivity: true,
      source: 'activities',
    },
    destination: {
      name: 'Destination',
      display: true,
      field: 'destination.pic',
      alternateField: 'consignee.pic',
      getListFromActivity: true,
      source: 'activities',
    },
    company: {
      name: 'Company',
      display: true,
      field: 'NDLicenseHolder.companyName',
      alternateField: 'receiver.companyName',
      getListFromActivity: true,
      source: 'activities',
    },
    consignee: {
      name: 'Consignee',
      display: true,
      field: 'consigneeDetails.receiverName',
      getListFromActivity: true,
      source: 'activities',
    },
    date_transferred: {
      name: 'Date Transferred',
      display: true,
      field: 'createdAt',
      source: 'activities',
      dateField: true,
    },
  };

const PLANT_INVENTORY_FIELDS: Record<string, FieldConditionMetadata> = {
  site_map: {
    name: 'Site Name',
    display: true,
    field: 'siteDetails.siteName',
    getList: true,
  },
  site_layer: {
    name: 'Site Layer',
    display: true,
    field: 'site.layerName',
    getList: true,
  },
  plant_amount: {
    name: 'No. of Plants',
    display: true,
    field: 'label',
    aggregate: 'count',
  },
};

const PLANT_TREATMENT_FIELDS: Record<string, FieldConditionMetadata> = {
  plant_code: {name: 'Plant Code', display: true, field: 'label'},
  treatment: {
    name: 'Treatment',
    field: 'product_name',
    source: 'activities',
    getListFromActivity: true,
    display: true,
  },
  beneficial_bugs: {
    name: 'Beneficial Bugs',
    field: 'beneficial_bugs',
    source: 'activities',
    getListFromActivity: true,
    display: true,
  },
  chemical_control: {
    name: 'Chemical Control',
    getListFromActivity: true,
    field: 'chemical_control',
    source: 'activities',
    display: true,
  },
  nutrients: {
    name: 'Nutrients',
    field: 'nutrients',
    source: 'activities',
    getListFromActivity: true,
    display: true,
  },
  treatment_date: {
    name: 'Record Date',
    field: 'treatment_date',
    source: 'activities',
    display: true,
  },
  treated_by: {
    name: 'Treated By',
    field: 'treated_by',
    source: 'activities',
    getListFromActivity: true,
    display: true,
  },
  note: {
    name: 'Note',
    field: 'note',
    source: 'activities',
    display: true,
  },
  attachments: {
    name: 'Attachments',
    field: 'attachments',
    source: 'activities',
    display: true,
  },
};

const PLANT_MOVEMENT_FIELDS: Record<string, FieldConditionMetadata> = {
  plant_code: {name: 'Plant Code', display: true, field: 'label'},
  origin: {
    name: 'Origin',
    display: true,
    field: 'origin.site.layerName',
    source: 'activities',
    filterValue: '',
    type: 'ne',
  },
  destination: {
    name: 'Destination',
    display: true,
    field: 'destination.site.layerName',
    source: 'activities',
  },
  strain: {
    name: 'Strain',
    display: true,
    field: 'assetDetails.mainDisplay.strain',
  },
  species: {
    name: 'Species',
    display: true,
    field: 'assetDetails.mainDisplay.species',
  },
  sub_strain: {
    name: 'Sub Strain',
    display: true,
    field: 'assetDetails.mainDisplay.subStrain',
  },
  asset_name: {
    name: 'Asset Name',
    display: true,
    field: 'assetDetails.assetName',
  },
  timestamp: {
    name: 'Date Moved',
    field: 'createdAt',
    display: false,
    source: 'activities',
    dateField: true,
  },
};

const initialPlantFieldConditions: ColumnRecord<
  string,
  Record<string, FieldConditionMetadata>
> = {
  management: {
    treatment: PLANT_TREATMENT_FIELDS,
    movement: PLANT_MOVEMENT_FIELDS,
    transfer: PLANT_TRANSFER_IN_FIELDS,
    inventory: PLANT_INVENTORY_FIELDS,
  },
};

const DATE_RANGE_INITIAL_FILTER_VALUES = {
  start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
};

const initialPlantOuterFilter: ColumnRecord<SubPageType, any> = {
  management: {
    treatment: DATE_RANGE_INITIAL_FILTER_VALUES,
    movement: DATE_RANGE_INITIAL_FILTER_VALUES,
    transfer: {
      transferType: 'in',
    },
    inventory: {
      licenseNumber: '',
    },
  },
};

const initialPlantSelectedFilters: ColumnRecord<
  SubPageType,
  Record<string, Array<MultipleSelectOptionsType>>
> = {
  management: {
    treatment: initialPlantColumns.management.treatment?.reduce((acc, col) => {
      if (['plant_code', 'note', 'attachments'].includes(col.name)) {
        return acc;
      }
      return {
        ...acc,
        [col.name]: [] as Array<MultipleSelectOptionsType>,
      };
    }, {}),
    movement: initialPlantColumns.management.movement?.reduce((acc, col) => {
      if (['plant_code'].includes(col.name)) {
        return acc;
      }
      return {
        ...acc,
        [col.name]: [] as Array<MultipleSelectOptionsType>,
      };
    }, {}),
    transfer: initialPlantColumns.management.transfer?.reduce((acc, col) => {
      if (['plant_code', 'date_transferred'].includes(col.name)) {
        return acc;
      }
      return {
        ...acc,
        [col.name]: [] as Array<MultipleSelectOptionsType>,
      };
    }, {}),
    inventory: initialPlantColumns.management.inventory?.reduce((acc, col) => {
      return {
        ...acc,
        [col.name]: [] as Array<MultipleSelectOptionsType>,
      };
    }, {}),
  },
};

type ColumnStyleMetadata = Array<{
  columnName: string;
  width?: string; // accept % or px
  align?: TableAlignType;
}>;

const initialPlantColumnExtensions: ColumnRecord<
  SubPageType,
  ColumnStyleMetadata
> = {
  management: {
    treatment: initialPlantColumns.management.treatment?.map((col) => ({
      columnName: col.name,
      width: '300px',
      align: 'center' as TableAlignType,
    })),
    movement: initialPlantColumns.management.movement?.map((col) => ({
      columnName: col.name,
      width: '300px',
      align: 'center' as TableAlignType,
    })),
    transfer: initialPlantColumns.management.transfer?.map((col) => ({
      columnName: col.name,
      width: '300px',
      align: 'center' as TableAlignType,
    })),
    inventory: initialPlantColumns.management.inventory?.map((col) => ({
      columnName: col.name,
      width: '300px',
      align: 'center' as TableAlignType,
    })),
  },
};

const convertFieldNames = (nm: string, colName?: string) => {
  return colName ? colName : nm.replace(/\W/g, '_').toLowerCase();
};

export const initialFieldsGenerator = (
  isPlant: boolean,
  reports?: Array<PluginModel.PluginReport>,
  includeDefaults?: boolean,
) => {
  let list = {
    initialColumns: isPlant ? initialPlantColumns : {},
    initialColumnExtensions: isPlant ? initialPlantColumnExtensions : {},
    initialFieldConditions: isPlant ? initialPlantFieldConditions : {},
    initialOuterFilter: isPlant ? initialPlantOuterFilter : {},
    initialSelectedFilters: isPlant ? initialPlantSelectedFilters : {},
    defaultRowFields: isPlant ? defaultPlantRowFields : defaultRowFields,
    disabledFilterColumnExtensions: isPlant
      ? disabledPlantFilterColumnExtensions
      : {},
    disabledSortingColumnExtensions: isPlant
      ? disabledPlantSortingColumnExtensions
      : {},
    disabledChooserColumnExtensions,
    hideColumnExtensions: isPlant ? plantHideColumnExtensions : {},
    payloadType: getReportPayloadType(isPlant),
  };
  if (reports?.length) {
    const management = {
      columns: {},
      columnExt: {},
      fieldCond: {},
      outerFilters: {},
      selectedFilters: {},
      defaultRowFields: {},
      activityType: {},
      disabledFilters: {},
      disabledSorting: {},
      disabledChooser: {},
      hideColumn: {},
    };
    reports.forEach((rpt) => {
      management['columns'][rpt.type] = rpt.columns.map((col) => {
        return {
          name: convertFieldNames(col.name, col.column),
          title: col.name,
          field: col.field,
          customFilter: !col['disableInnerFilter'],
          viewDetails: col['viewDetails'],
          url: col['url'],
          columnSize: col['columnSize'],
          display: col['display'],
          autofillOptions: col['autofillOptions'],
          dropdownOptions: col['dropdownOptions'],
          type:
            col.type ??
            (col.getList
              ? 'multiSelect'
              : col.name.toLowerCase().includes('date')
              ? 'dateRange'
              : 'text'),
        };
      });
      management['columnExt'][rpt.type] = rpt.columns.map((col) => {
        return {
          columnName: convertFieldNames(col.name, col.column),
          width:
            col.name === 'Digital Tag'
              ? '25%'
              : col.columnWidth ??
                `${Math.max(Math.floor(100 / rpt.columns.length), 15)}%`,
          align: (col['align'] || 'left') as TableAlignType,
        };
      });
      management['outerFilters'][rpt.type] = {};
      rpt.outerFilters.forEach((otf) => {
        const today = moment().format('YYYY-MM-DD');
        const monday = moment().weekday(1).format('YYYY-MM-DD');
        management['outerFilters'][rpt.type][otf.key] =
          otf.defaultValue ??
          (otf.type === 'date' && otf.required
            ? otf.key === 'weekStarting'
              ? monday
              : today
            : otf.type === 'multiselect'
            ? []
            : '');
        if (otf['secondKey']) {
          management['outerFilters'][rpt.type][otf['secondKey']] = '';
        }
      });
      management['selectedFilters'][rpt.type] = {};
      management['fieldCond'][rpt.type] = {};
      management['defaultRowFields'][rpt.type] = {};
      management['disabledFilters'][rpt.type] = [];
      management['disabledSorting'][rpt.type] = [];
      management['disabledChooser'][rpt.type] = [];
      management['hideColumn'][rpt.type] = [];
      rpt.columns.forEach((cl) => {
        const fld = convertFieldNames(cl.name, cl.column);
        management['fieldCond'][rpt.type][fld] = cl;
        management['defaultRowFields'][rpt.type][fld] = '';
        if (cl.type === 'multiSelect') {
          management['selectedFilters'][rpt.type][fld] =
            [] as Array<MultipleSelectOptionsType>;
        }
        if (cl.disableInnerFilter) {
          management['disabledFilters'][rpt.type].push({
            columnName: fld,
            filteringEnabled: false,
          });
        }
        if (cl.disableSorting) {
          management['disabledSorting'][rpt.type].push({
            columnName: fld,
            sortingEnabled: false,
          });
        }
        management['disabledChooser'][rpt.type].push({
          columnName: fld,
          togglingEnabled: !cl.disableChooser,
        });
        if (cl.hideColumn) {
          management['hideColumn'][rpt.type].push({
            columnName: fld,
          });
        }
      });
      management['activityType'][rpt.type] = rpt.activityType;
    });
    const add = {
      initialColumns: {management: management['columns']},
      initialColumnExtensions: {management: management['columnExt']},
      initialFieldConditions: {management: management['fieldCond']},
      initialOuterFilter: {management: management['outerFilters']},
      initialSelectedFilters: {management: management['selectedFilters']},
      defaultRowFields: {management: management['defaultRowFields']},
      disabledFilterColumnExtensions: {
        management: management['disabledFilters'],
      },
      disabledSortingColumnExtensions: {
        management: management['disabledSorting'],
      },
      disabledChooserColumnExtensions: {
        management: management['disabledChooser'],
      },
      hideColumnExtensions: {
        management: management['hideColumn'],
      },
      payloadType: management['activityType'],
    };
    if (!includeDefaults) {
      return add;
    } else {
      for (const key in list) {
        if (list[key].management) {
          list[key] = {
            ...list[key],
            management: {...list[key].management, ...add[key].management},
          };
        } else {
          list[key] = {...list[key], ...add[key]};
        }
      }
    }
  }

  return list;
};
