import { GmpPermit, OdcPermit } from '@aglive/data-model/dist/misc/business';
import { License, licenseType } from '@aglive/data-model/dist/misc/business';
import moment from 'moment';
import CONSTANT from '../../config/constant';

export function filterPermits(licenseType: licenseType, licenses: License[] | undefined | null): OdcPermit[] | GmpPermit[] {
  return licenses?.reduce((permits, license) => {
    if (license.name === 'Poison') {
      return permits;
    }
    if (license.name === licenseType) {
      return [...permits, ...license.permits];
    }
    return permits;
  }, []
  ) ?? [];
}

export function isPermitExpired(date: string) {
  const format = CONSTANT.YYYY_MM_DD_REGEX.test(date) ? 'yyyy-MM-DD' : undefined;
  const expiryDate = moment(date, format).startOf('date');
  const today = moment().startOf('date');
  return today > expiryDate;
}
