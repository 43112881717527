import React, {useState, useEffect} from 'react';
import moment from 'moment';
import COLOR from '../../styled/colors';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {Buttons} from '../../presentation/ButtonsGroup';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles, Grid, Button, Typography} from '@material-ui/core';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {
  deleteConsignment,
  fetchConsignmentData,
  fetchConsigneeData,
} from '../../store/add/actions';
import imgConsignment from '../../img/conginment.svg';
import {CASL} from '@aglive/frontend-core';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  filterContainer: {
    marginRight: 50,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  formTypeFilter: {
    flexDirection: 'row',
    display: 'flex',
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 310,
    maxHeight: 56,
    marginRight: 8,
  },
  label: {
    textTransform: 'none',
  },
  icon: {
    cursor: 'pointer',
  },
}));

const ConsignmentLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const [query, setQuery] = useState('');
  const consignmentsList = useAppSelector((state) => state.add.consignmentData);

  const ability = CASL.useAbility(CASL.AbilityContext);
  const TABLE_HEADER = [
    'Consignment Name',
    'Form Added',
    'Date Created',
    'Status',
  ];
  ability.can('update', 'consignment')
    ? TABLE_HEADER.push('Action')
    : TABLE_HEADER.push('');

  const consignmentStatus = (status) => {
    if (status.includes('completed')) {
      return 'Completed';
    } else if (status.includes('submitted')) {
      return 'Submitted';
    } else if (status.includes('draft')) {
      return 'Draft';
    }
  };
  useEffect(() => {
    dispatch(fetchConsigneeData());
    dispatch(fetchConsignmentData());
  }, []);
  return (
    <>
      {consignmentsList.length === 0 ? (
        <>
          <Grid
            container
            className={classes.bodyContainer}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Typography
              variant="h2"
              role="label"
              style={{
                fontWeight: 700,
                marginBottom: 30,
                color: COLOR.GRAY_TEXT,
              }}>
              Manage your paperwork
            </Typography>

            <Typography variant="h6" role="label" style={{marginBottom: 50}}>
              Create a consignment in advance or add forms and files
            </Typography>

            <img src={imgConsignment} style={{marginBottom: 50}} />
            <CASL.Can I="create" a="consignment">
              <MyButton
                text={'Create Consignment'}
                variant="contained"
                width={160}
                fontSize={18}
                onClick={() => history.push(`${path}/new`)}
              />
            </CASL.Can>
          </Grid>
        </>
      ) : (
        <>
          <Grid alignItems="center" container className={classes.bodyContainer}>
            <Grid item className={classes.searchBarContainer}>
              <SearchBar
                query={query}
                setQuery={setQuery}
                label="Search by Consignment Name"
              />
            </Grid>
            <CASL.Can I="create" a="consignment">
              <Grid item className={classes.buttonContainer}>
                <MyButton
                  text={'Create New'}
                  variant="contained"
                  width={160}
                  fontSize={18}
                  onClick={() => history.push(`${path}/new`)}
                />
              </Grid>
            </CASL.Can>
          </Grid>
          <MyTable
            heads={TABLE_HEADER}
            rows={consignmentsList
              ?.filter((consignment) =>
                consignment.name.toLowerCase().includes(query.toLowerCase()),
              )
              .map((consignment, index) => [
                <Button
                id={`viewConsignment${index}`}
                  onClick={() =>
                    history.push(`${path}/view/${consignment.agliveToken}`)
                  }
                  classes={{
                    label: classes.label,
                  }}>
                  <span style={{color: COLOR.GREENT_TEXT}}>
                    <u>{consignment.name}</u>
                  </span>
                </Button>,
                consignment.formsCount,
                moment(consignment.createdAt).format('DD/MM/YYYY'),
                consignmentStatus(consignment.status),
                <CASL.Can I="update" a="consignment">
                  <div
                    style={{
                      display: 'flex',
                    }}>
                    <div
                      style={
                        consignment.status.includes('draft')
                          ? {marginRight: 20}
                          : {marginRight: 0}
                      }>
                      {consignment.status.includes('draft') && (
                        <EditIcon
                          id={`editConsignment${index}`}
                          className={classes.icon}
                          onClick={() =>
                            history.push(
                              `${path}/edit/${consignment.agliveToken}`,
                            )
                          }
                        />
                      )}
                    </div>
                    <div
                      style={
                        consignment.status.includes('draft')
                          ? {marginRight: 20}
                          : {marginLeft: 50}
                      }>
                      <FileCopyIcon
                        id={`duplicateConsignment${index}`}
                        className={classes.icon}
                        onClick={() =>
                          history.push(
                            `${path}/duplicate/${consignment.agliveToken}`,
                          )
                        }
                      />
                    </div>
                    <div style={{marginRight: 20}}>
                      {consignment.status.includes('draft') && (
                        <DeleteIcon
                          id={`deleteConsignment${index}`}
                          className={classes.icon}
                          onClick={() =>
                            dispatch(
                              toggleModal({
                                status: 'warning',
                                title: 'Delete Consignment?',
                                subtitle: 'This action cannot be undone',
                                renderButton: (
                                  <Buttons
                                    leftButtonTitle="Cancel"
                                    rightButtonTitle="Delete"
                                    leftButtonOnClick={() =>
                                      dispatch(toggleModalOff())
                                    }
                                    rightButtonOnClick={() => {
                                      dispatch(toggleModalOff());
                                      dispatch(deleteConsignment(consignment));
                                    }}
                                  />
                                ),
                              }),
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </CASL.Can>,
              ])}
          />
        </>
      )}
    </>
  );
};
export default withHeader(
  {
    title: 'Consignments',
  },
  ConsignmentLibrary,
);
