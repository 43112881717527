import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Grid, Typography} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import MyButton from '../../presentation/button';
import {PageHeader} from '../../presentation/withHeader';
import {ConsigneeTypes} from '../../store/add/types';
import { CASL } from '@aglive/frontend-core';
import { BusinessMisc } from '@aglive/data-model';
import CONSTANT from '../../config/constant';
import { FormGenerator, FormInputFunctions } from '../../presentation/FormGenerator';
import { editConsignee, postConsignee } from '../../store/add/actions';

const SingleConsignee: React.FC<{view?: boolean}> = (props) => {
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormInputFunctions<ConsigneeTypes>>();
  const COUNTRY_LIST = Object.keys(BusinessMisc.SupportedCountries);
  const STATE_LIST = React.useMemo(() => {
    let list: {[key: string]: {label: string, value: string}[]} = {}
    for (const [key, val] of Object.entries(BusinessMisc.SupportedCountries)) {
      list[key] = val.map((s) => ({label: s, value: s}));
    }
    return list;
  }, []);
  const consigneeList: Array<ConsigneeTypes> = useAppSelector(
    (state) => state.add.consigneeData,
  );
  const targetIndex = consigneeList.findIndex(
    (consignee) => consignee.agliveToken === id,
  );
  const consignee: ConsigneeTypes = targetIndex > -1 ? {...consigneeList[targetIndex]} : {} as ConsigneeTypes;
  const [formReady, setFormReady] = useState(targetIndex > -1 ? true : false);

  const saveButton = () => {
    if (consignee.createdAt) {
      dispatch(
        editConsignee({...consignee, ...formRef.current?.getValues()}, () => {
          history.push('/private/add/consignees');
        }),
      );
    } else {
      dispatch(
        postConsignee(formRef.current?.getValues(), () => {
          history.goBack();
        }),
      );
    }
  };

  const formInputs = [
    {
      type: 'INPUT',
      name: 'receiverName',
      title: 'Consignee Name',
      required: true,
      viewMode: props.view,
      value: consignee?.receiverName
    },
    {
      type: 'EMAIL',
      name: 'receiverEmail',
      title: 'Consignee Email',
      required: true,
      viewMode: props.view,
      value: consignee?.receiverEmail
    },
    {
      type: 'PHONE',
      name: 'receiverPhone',
      title: 'Consignee Phone',
      required: true,
      viewMode: props.view,
      value: consignee?.receiverPhone
    },
    {
      type: 'SELECT',
      name: 'receiverCountry',
      title: 'Country',
      required: true,
      viewMode: props.view,
      value: consignee?.receiverCountry,
      items: COUNTRY_LIST.map((cl) => ({label: cl, value: cl}))
    },
    {
      type: 'INPUT',
      name: 'receiverPostCode',
      title: 'Post Code',
      required: true,
      viewMode: props.view,
      value: consignee?.receiverPostCode,
      dependentErrorMsg: CONSTANT.POSTCODE_REGEX_WARNING,
      dependentValidationRegex: CONSTANT.POSTCODE_REGEX,
      dependsOn: 'receiverCountry'
    },
    {
      type: 'INPUT',
      name: 'receiverTown',
      title: 'Town',
      required: true,
      viewMode: props.view,
      value: consignee?.receiverTown
    },
    {
      type: 'SELECT',
      name: 'receiverState',
      title: 'State',
      required: true,
      viewMode: props.view,
      items: [],
      dependentItems: STATE_LIST,
      dependsOn: 'receiverCountry',
      value: consignee?.receiverState
    },
    {
      type: 'INPUT',
      name: 'receiverPIC',
      title: 'Consignee Location ID (if applicable)',
      viewMode: props.view,
      value: consignee?.receiverPIC
    },
    {
      type: 'INPUT',
      name: 'receiverAddress',
      title: 'Address',
      required: true,
      viewMode: props.view,
      minRows: 3,
      value: consignee?.receiverAddress
    },
  ];
  return (
    <PageHeader config={{title: `${props.view ? 'View' : targetIndex > -1 ? 'Edit' : 'Add'} Consignee`, margin: 60, back: true}}>
      <Grid container spacing={3}>
        <FormGenerator ref={formRef} formInputs={formInputs} onReady={setFormReady} />
        <Grid item container justifyContent="flex-end" style={{marginTop: 300}}>
          <CASL.Can I="update" a="consignee">
            {props.view ? 
              <MyButton
                text={'Edit'}
                variant="contained"
                onClick={() => history.push(`/private/add/consignees/${id}`)}
            />
            :
              <MyButton text={'Save'} disabled={!formReady} variant="contained" onClick={saveButton} />
            }
          </CASL.Can>
        </Grid>
      </Grid>
    </PageHeader>
  );
};
export default SingleConsignee;
