import {tokenService, csvService} from '../env';

const API = {
  GET: {
    //walletLogin
    getQR: `${tokenService}/auth/scanLogin`,
    socketURL: `${tokenService}`,
    // Asset related APIs
    getAsset: `${csvService}/api/asset/activities/uploadcsv`,
    // Document related APIs
    getDocuments: `${csvService}/api/document`,
    getAngus: `${csvService}/api/angus`,
    //plugins list
    plugins: `${csvService}/api/plugin`,

    // Report related API
    getPICReport: `${csvService}/api/reports/individual-animals`,
    getWeightHistoryReport: `${csvService}/api/reports/weight-history`,
    getWeightGainReport: `${csvService}/api/reports/weight-gain`,
    getMovementReport: `${csvService}/api/reports/monthlyMovementReport`,
    getTransferReport: `${csvService}/api/reports/monthlyTransferReport`,
    getWeightGroupReport: `${csvService}/api/reports/weight-group`,
    getPreSaleReport: `${csvService}/api/report/presale`,
    getTreatmentReport: `${csvService}/api/reports/treatment`,
    getCeresTagReport: `${csvService}/api/reports/ceresTag`,
    getTreatmentDocument: `${csvService}/api/document/get`,
    getComponentData: `${csvService}/api/report/component`,
    // Form
    getFormTemplates: `${csvService}/api/consignment/template`,
    // Asset
    getTokensWithFilters: `${csvService}/api/token/tokens/userid/filter`,
    getStrainStartNumber: `${csvService}/api/token/plant/sequence`,
    getSiteAssetsByExternalId: `${csvService}/api/token/site/asset`,
    // group
    getGroupItemsByExternalId: `${csvService}/api/token/group/items`,
    // Validate invite codes
    validateCode: `${csvService}/api/code/validate`,
  },
  POST: {
    // Token related APIs
    createToken: `${csvService}/api/token/tokens`,
    createActivity: `${csvService}/api/token/activities`,
    getTokenbyAddr: `${csvService}/api/token/tokens/userid`,
    getBusinessbyToken: `${csvService}/api/token/tokens/tokenid`,
    getTokenbyTokenId: `${csvService}/api/token/tokens/tokenid`,
    getTokenbyExternalId: `${csvService}/api/token/tokens/externalid`,
    getTokenByBusiness: `${csvService}/api/token/tokens/businessid`,
    getFilteredToken: `${csvService}/api/reports/animalReport`,
    // Asset related APIs
    postCSV: `${csvService}/api/token/tokens/csv`,
    postGroupCSV: `${csvService}/api/token/group/csv`,
    updateAssetsByCSV: `${csvService}/api/token/activities/csv`,
    updateGroupAssetsByCSV: `${csvService}/api/token/activities/group/csv`,
    exportReport: `${csvService}/api/token/tokens/report`,
    getCSVbyUser: `${csvService}/api/token/tokens/externalid`,
    // Product related APIs
    uploadTos3: `${csvService}/api/uploader/s3`,
    // Validate PIC,LPA and NLIS
    validate: `${csvService}/api/nlis/validation`,
    // Document related APIs,
    postDocument: `${csvService}/api/document`,
    postFormTemplate: `${csvService}/api/document/template`,
    getDocumentCountByLocation: `${csvService}/api/document/count/location`,
    //Augus
    angus: `${csvService}/api/angus`,
    // Auth
    createUserWithAuth: `${tokenService}/api/auth/register`,
    authUser: `${tokenService}/api/auth/login`,
    refreshToken: `${tokenService}/api/auth/refresh-token`,
    revokeToken: `${tokenService}/api/auth/revoke-token`,
    ssoAuthUser: `${tokenService}/api/auth/sso/token`,
    //optiweigh
    optiweigh: `${tokenService}/api/job/optiweigh`,
    // Promotion
    getLinkMob: `${tokenService}/api/promotion/linkmob`,
    getActivitiesReport: `${csvService}/api/reports/activitiesReport`,

    getAmbioxeraPICs: `${tokenService}/api/reports/ambioxeraPICs`,
    // reports
    getIndustryReport: `${tokenService}/api/reports/industry`,
  },
  PUT: {
    // Asset related APIs
    updateAsset: `${csvService}/api/asset/assets/csv`,
    updateDocument: `${csvService}/api/document`,
  },
  DELETE: {
    deleteDocument: `${csvService}/api/document`,
  },
};

export default API;
