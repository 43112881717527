import React ,{ useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormHelperText, Grid, Typography, Checkbox, FormControlLabel} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { findTargetBrandIndex, getBrandAgliveTokenId } from '../brand/utils';

import { TokenService } from '@aglive/data-model';

interface iProductHeader {
  productName: TokenService.ProductToken["details"]["name"];
  productBrand?: TokenService.BrandToken; // manually pass brand token to header for prefill
  onNameChange: (arg0: string) => void;
  onBrandChange: (arg0: TokenService.BrandToken) => void;
  onGtinChange: (arg0: string) => void;
  disabled?: boolean;
  brandList?: Array<TokenService.BrandToken>;

  productGtin: TokenService.ProductToken["details"]["gtin"];

  productNameError: string;
  productBrandError: string;
  productGtinError: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      // minWidth: 879,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const ProductHeader: React.FC<iProductHeader> =({
  productName,
  productBrand,
  productGtin,
  onNameChange,
  onBrandChange,
  onGtinChange,
  disabled = false,
  brandList = [],

  productNameError,
  productBrandError,
  productGtinError
}) => {
  const classes = useStyles();

  const [brand, setBrand] = useState('' as number | '');
  const brandHandleChange =(event: React.ChangeEvent<{ value: unknown }>)=>{
    const index=event.target.value as number;
    setBrand(index);
    onBrandChange(brandList[index]);
  }

  const [gtin, setGtin] = useState(false);

  useEffect(() => {
    if (productBrand) {
      const targetBrandIndex = findTargetBrandIndex(
        brandList,
        getBrandAgliveTokenId(productBrand)
      );
      
      setBrand(targetBrandIndex);
      onBrandChange(brandList[targetBrandIndex]);
    }
  }, [productBrand]);
  
  useEffect(() => {
    if (productGtin) {
      setGtin(true);
    }
  });
    
  return(
    <Grid item container spacing={3}>
      <Grid item container xs={6}>
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{ fontWeight: 600, marginBottom: 10 }}>
            Product Name
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            aria-label=""
            style={{float:'right', marginTop:'-10px'}}
            control={
              <Checkbox
                color="primary"
                checked={gtin}
                aria-checked={gtin}
                onChange={(e) => {
                  setGtin(!gtin)
                }}
                id="GTIN-Checkbox"
              />
            }
            label="Add GTIN"
          />
        </Grid>
        <Grid item xs={gtin ? 6 : 12}>
          <TextField
            fullWidth
            variant="outlined"
            value={productName}
            onChange={(e) => onNameChange(e.target.value)}
            disabled={disabled}
            error={!!productNameError.length}
            helperText={productNameError}
            id="ProductName-TextField"
          />
        </Grid>
        {gtin &&(<Grid item xs={6}>
          {(<TextField
            fullWidth
            variant="outlined"
            value={productGtin}
            onChange={(e) => onGtinChange(e.target.value)}
            disabled={disabled}
            error={!!productGtinError.length}
            helperText={productGtinError}
            id="GTIN-TextField"
            InputProps={{
              startAdornment: <InputAdornment position="start">GTIN:</InputAdornment>,
            }}
          />)}
        </Grid>)}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" role="label" style={{ fontWeight: 600, marginBottom: 10 }}>
          Brand
        </Typography>
        {
          disabled ? (
            <TextField
              fullWidth
              variant="outlined"
              value={productBrand?.details?.name}
              onChange={(e) => {}}
              disabled={disabled}
              id="Brand-TextField"
            />
          ) : (
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
              error={!!productBrandError.length}>
              <InputLabel id="product-brand">{"Please select"}</InputLabel>
              <Select
                label={"Please Select"}
                labelId="product-brand"
                value={brand}
                onChange={brandHandleChange}  
                id="Brand-Dropdown"
              >
                {brandList.map((brand,index) => {
                  return (
                    <MenuItem value={index} key={brand.externalIds[0].agliveToken} id={brand?.details?.name}>{brand?.details?.name}</MenuItem>
                  );
                })}
              </Select>
              <FormHelperText id="Brand-helper-text">{productBrandError}</FormHelperText>
            </FormControl>
          )
        }
      </Grid>
    </Grid>
  )
};

export default ProductHeader;
