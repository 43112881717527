import {compose, createStore, combineReducers, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import authReducer from './auth/reducers';
import modalReducer from './modal/reducers';
import locationReducer from './location/reducers';
import spinnerReducer from './spinner/reducers';
import productReducer from './product/reducers';
import profileReducer from './profile/reducers';
import codeReducer from './code/reducers';
import addReducer from './add/reducers';
import activityReducer from './activity/reducers';
import siteReducer from './site/reducers';
import assetProfileReducer from './assetProfile/reducers';
import formReducer from './form/reducers';
import groupReducer from './group/reducers';
import contactInvitationReducer from './contacts/reducers';

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

// https://stackoverflow.com/questions/56099291/how-to-persist-to-both-localstorage-and-sessionstorage-at-the-same-time-using-re
const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['location', 'site', 'user'],
  blacklist: ['product', 'modal', 'spinner'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

const contactInvitePersistConfig = {
  key: 'contact',
  storage: storageSession,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  code: codeReducer,
  location: locationReducer,
  modal: modalReducer,
  product: productReducer,
  site: siteReducer,
  spinner: spinnerReducer,
  user: profileReducer,
  add: addReducer,
  activity: activityReducer,
  assetProfile: assetProfileReducer,
  form: formReducer,
  group: groupReducer,
  contact: persistReducer(contactInvitePersistConfig, contactInvitationReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk)),
);
// Middleware: Redux Persist Persister
export const persistor = persistStore(store);
