import React, {useRef, useState, useEffect, useMemo, useCallback} from 'react';
import {useReactToPrint} from 'react-to-print';
import {useHistory, useRouteMatch, Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import {Box, Grid} from '@material-ui/core';

import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import withHeader from '../../presentation/withHeader';

import {fetchCodeEntries, updateCodeEntry} from '../../store/code/actions';
import {iCodeEntryDocumentResponse} from '../../store/code/types';
import {PlantCodeDocumentDetails} from '../../store/plant/types';

import useStyles from './SecurityCode/SecurityCodeLibrary/styles';
import CodeSearchForm from './forms/CodeSearchForm';
import {fetchAssetProfiles} from '../../store/assetProfile/actions';
import {filterPermits} from '../../store/profile/utils';
import {INITIAL_CANNABIS_PRINT_STATE} from './utils';
import CodeGridPrint from './Print/CodeGridPrint';
import CodeLibraryTable from './CodeLibraryTable';
import {
  getCodesCompareFuction,
  getCodesFilterFunction,
  useCodeSearchForm,
} from './forms/useCodeSearchForm';
import {getBusinessProfile} from '../../store/profile/actions';

const tableHeader = [
  'ODC Permit',
  'Asset Profile',
  'Available Codes',
  'Date Created',
  'Action',
];

const CodeLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const userid = useAppSelector((state) => state.auth.wallet);
  const isCannabis = useAppSelector(
    (state) =>
      state.user.businessProfileData.industryType === 'PLANTS' &&
      state.user.businessProfileData.subIndustryType === 'Cannabis',
  );

  useEffect(() => {
    dispatch(fetchCodeEntries(userid));
  }, [dispatch, userid]);

  const assetProfiles = useAppSelector(
    (state) => state.assetProfile.fetchedAssetProfiles,
  );

  useEffect(() => {
    // to sync up mobile app changes
    dispatch(getBusinessProfile());
  }, []);

  useEffect(() => {
    dispatch(fetchAssetProfiles());
  }, [dispatch]);

  const unprocessedCodeList = useAppSelector((state) => state.code) as Array<
    iCodeEntryDocumentResponse<PlantCodeDocumentDetails>
  >;

  const licenses = useAppSelector((state) =>
    state.user.businessProfileData.industryType === 'PLANTS'
      ? state.user.businessProfileData.licenses ?? []
      : [],
  );
  const odcPermits = useMemo(() => filterPermits('ODC', licenses), [licenses]);
  const codeList = useMemo(() => {
    return unprocessedCodeList
      .filter((entry) => !entry.details.used)
      .map((entry) => {
        const permitNumber = odcPermits.find(
          (permit) => permit.permitId === entry.details.permitId,
        )?.permitNumber;
        return {...entry, details: {...entry.details, permitNumber}};
      });
  }, [unprocessedCodeList, odcPermits]);

  // printing content
  // printing logic - print state holds the information necessary to build modal (assetProfileName, permit, qrCode.length)
  // pressing on 'print' icon will trigger the modal,
  // pressing 'cancel' reset the printState, pressing 'print' will set the content (printContent) on the print prompt (hidden at this stage) (ComponentToPrint)
  // the useEffect hook is triggered on printContent changes and will invoke handlePrint and show the print prompt
  const componentRef = useRef();
  const [cannabisPrintState, setCannabisPrintState] = useState(
    INITIAL_CANNABIS_PRINT_STATE,
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (cannabisPrintState?.codes.length > 0) {
      handlePrint();
      dispatch(fetchCodeEntries(userid));
    }
  }, [cannabisPrintState]);

  const handlePrintClick = useCallback(
    (entry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>, isCannabis: boolean) => {
      if (isCannabis) {
        updateCodeEntry(entry).then(() =>
          setCannabisPrintState({
            startNumber: entry.details.startNumber,
            strain: entry.details.assetProfile.mainDisplay.strain,
            codes: entry.details.codes,
          }),
        );
        return;
      }
    },
    [],
  );

  const {
    query,
    setQuery,
    filterItems,
    sortItem,
    handleFilterChange,
    handleSortChange,
    selectedPermit,
  } = useCodeSearchForm({licenses, assetProfiles});

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label={'Search by ODC Permit or Asset Profile'}
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Generate Codes'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(`${path}/new`)}
          />
        </Grid>
      </Grid>
      <CodeSearchForm
        filterItems={filterItems}
        sortItem={sortItem}
        onFilterChanged={handleFilterChange}
        onSortChanged={handleSortChange}
      />
      {selectedPermit && selectedPermit.maxNumber ? (
        <Grid container item xs={12} style={{marginBottom: '2rem'}}>
          <Box className={classes.plantLimitNote}>
            {`Note: The selected ODC Permit allows a maximum of ${selectedPermit.maxNumber} plant codes to be generated`}
          </Box>
        </Grid>
      ) : null}
      <CodeLibraryTable
        tableHeader={tableHeader}
        codeList={codeList}
        query={getCodesFilterFunction(query, odcPermits, filterItems)}
        compareFunction={getCodesCompareFuction(
          sortItem.selectedItem,
          odcPermits,
        )}
        onPrint={(codeEntry) => handlePrintClick(codeEntry, isCannabis)}
      />

      <Grid item style={{paddingTop: 20}}>
        <Link to={`${path}/used`} className={classes.hyperlink} id={'UsedCodesButton'}>
          Used Codes
        </Link>
      </Grid>

      <div style={{display: 'none'}}>
        {isCannabis ? (
          <CodeGridPrint
            ref={componentRef}
            startNumber={cannabisPrintState.startNumber}
            qrCodes={cannabisPrintState.codes}
            strain={cannabisPrintState.strain}
          />
        ) : null}
      </div>
    </>
  );
};

export default withHeader(
  {
    title: 'Codes',
  },
  CodeLibrary,
);
