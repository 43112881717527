import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ResultTable from '../container/asset/ResultTable';
import IconTick from '../img/IconTick.png';
import IconCross from '../img/IconCross.png';
import IconAlert from '../img/IconAlert.png';
import IconInfo from '../img/IconInfo.png';
import MyButton from './button';
import COLOR from '../styled/colors';

const StatusIconMap = {
  success: IconTick,
  failed: IconCross,
  warning: IconAlert,
  info: IconInfo,
};

// import this if you want to declare the config object as another const
export type Message = {
  status: keyof typeof StatusIconMap | '';
  title?: string;
  subtitle?: string | string[];
  customContent?: React.ReactNode | string;
  button?: string; // supply a string to be displayed on the default button, default text: Close
  renderButton?: React.ReactNode; // add one or many custom buttons with different actions and this will ignore button attribute
  aria?: {
    label?: string;
    describe?: string;
  };
  errorInfo?: string | string[] | Array<{row: number; message: any}>;
  CTAHandler?: () => void; // add CTAHandler if you want a custom action on the default dismiss (through 'Close' button or background click)
  width?: number;
};

type ModalProps = {
  message: Message;
  isOpen: boolean;
  closeHandler: () => void; // if no CTAHandler, button will close Modal by default
};

const MyModal: React.FC<ModalProps> = (props) => {
  const {isOpen, closeHandler, message} = props;
  const {
    status,
    title,
    subtitle,
    button,
    renderButton,
    aria,
    errorInfo,
    CTAHandler,
    customContent,
    width,
  } = message;
  const contentSubtitle = typeof subtitle === 'string' ? [subtitle] : subtitle;
  const lineStyle =
    title === 'Request Submitted' ? {marginBottom: 0} : {marginBottom: 20};
  let paperStyle: CSSProperties = Array.isArray(errorInfo)
    ? {overflowY: 'visible'}
    : {overflowY: 'visible', width: width ?? 480, height: 'auto'};
  if (title === 'Request Submitted') {
    paperStyle = {overflowY: 'visible', width: 370, height: 'auto'};
  }
  const customCloseAction = CTAHandler
    ? () => {
        CTAHandler();
        closeHandler();
      }
    : closeHandler;

  const modalButton = renderButton || (
    <MyButton
      id="CloseButton"
      onClick={customCloseAction}
      variant="contained"
      text={button || 'Close'}
    />
  );
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => reason === 'backdropClick' ? customCloseAction : closeHandler}
      aria-labelledby={aria?.label || 'alert-dialog-title'}
      aria-describedby={aria?.describe || 'alert-dialog-description'}
      PaperProps={{style: paperStyle}}
      id="ModalDialog">
      <DialogTitle disableTypography>
        <ActionIconContainer style={{minHeight: 80}}>
          {!!status.length && (
            <img
              src={StatusIconMap[status]}
              alt={`action ${status}`}
              style={{margin: 'auto'}}
            />
          )}
        </ActionIconContainer>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: customContent ? '-25px' : undefined,
        }}>
        <MyDialogContentText variant="h3" style={{marginBottom: 20}}>
          {title}
        </MyDialogContentText>
        {customContent}
        {contentSubtitle?.map((line, i) => (
          <MyDialogContentText variant="body1" key={`${line}-${i}`} style={lineStyle}>
            {line}
          </MyDialogContentText>
        ))}
        {errorInfo && ResultTable('API_ERROR', errorInfo)}
      </DialogContent>
      <DialogActions
        style={{display: 'flex', justifyContent: 'center', marginBottom: 30}}>
        {modalButton}
      </DialogActions>
    </Dialog>
  );
};

const ActionIconContainer = styled.figure`
  display: flex;
  justify-content: center;
  margin-top: -65px;
`;

const MyDialogContentText = styled(DialogContentText)`
  text-align: center;
  color: ${COLOR.BLACK};
`;

export default MyModal;
