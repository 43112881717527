import {DashboardRowConfigType} from './types';

export const data = (id: string) => [
  {name: 'idx', title: '#', width: '9%'},
  {name: 'identifier', title: id, width: id === 'Digital Tag' ? '26%' : '19%'},
  {name: 'species', title: 'Species', width: '13%'},
  {name: 'breed', title: 'Breed', width: '13%'},
  {name: 'age', title: 'Age', width: '13%'},
  {name: 'sex', title: 'Sex', width: '13%'},
  {name: 'weight', title: 'Current Weight', width: '13%'},
];

// take account of nestedWidgets
export const effectiveWidgets = (widgets: DashboardRowConfigType['widgets']) =>
  widgets
    .map((widget) =>
      widget.payload?.composition
        ? [widget, ...widget.payload.composition]
        : widget,
    )
    .flat();

export const getExIds = (asset) => {
  let exIds = asset?.details?.externalIds ?? {};
  if (asset && asset.details && !asset.details.externalIds) {
    asset.externalIds.forEach((eid) => {
      const {timestamp, ...tmp} = eid;
      exIds = {...exIds, ...tmp}
    })
  }
  return exIds;
}

export const stripNonChar = (val?: string | string[] | any[]) => {
  if (typeof val === 'string') {
    return val.replace(/[/\-.]/g, '');
  } else if (Array.isArray(val) && typeof val[0] === 'string') {
    return val.map((v) => v.replace(/[/\-.]/g, ''));
  }
  return val;
}
