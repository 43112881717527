import {Box, Typography} from '@material-ui/core';

type Props = {
  texts: Array<string>;
};
export const DialogContentSubtitle: React.FC<Props> = ({texts}) => {
  return (
    <Box marginY={1} justifyContent="center" textAlign={'center'}>
      {texts.map((text, i) => (
        text === '\n' ? <br key={i}/> : <Typography key={i} variant="body1">{text}</Typography>
      ))}
    </Box>
  );
};
