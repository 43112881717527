import React from 'react';

import {Box, Grid} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import TabHeading from '../TabHeading';
import COLOR from '../../../styled/colors';
import LabelData from '../../../presentation/LabelData';

type contentType = {
  content: Array<
    TokenService.ProductToken['details']['pages'][0]['content'][0]
  >;
};

const ViewProductTabContent: React.FC<contentType> = ({content}) => {
  return (
    <Grid container direction="column">
      {content.map(({subheading, content}, index) => (
        <>
          <TabHeading
            heading={`Subheading ${index + 1}`}
            subheading="Add more details"
          />
          <Box
            p={2}
            mb={3}
            style={{
              backgroundColor: COLOR.GRAY_SOLID,
            }}>
            <LabelData
              label="Subheading"
              placeHolder="Enter Text"
              field={subheading}
              characters={30}
              valueId={`AdditionalHeading${index}`}
            />
            <LabelData
              label="Content"
              placeHolder="Add content here"
              field={content}
              characters={600}
              minHeight={250}
              valueId={`AdditionalContent${index}`}
            />
          </Box>
        </>
      ))}
    </Grid>
  );
};

export default ViewProductTabContent;
