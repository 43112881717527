import {Box, Grid, makeStyles, MenuItem, Typography} from '@material-ui/core';
import withHeader from '../../presentation/withHeader';
import MyButton from '../../presentation/button';
import DropdownSelection from '../../presentation/DropdownSelection';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {useEffect, useMemo, useState} from 'react';
import {OdcPermit} from '@aglive/data-model/dist/misc/business';
import CropsTable from './CropsTable';
import COLOR from '../../styled/colors';
import MyTable from '../../presentation/Table';
import {downloadSpreadSheet} from './spreadsheet';
import {CropReportData, CropReportDetails, CropReportType} from './crop/types';
import {formatPermitDate, getYearsFromNow, isPermitExpired} from './crop/utils';
import {callAPI} from '../../utils/network';
import API from '../../config/api';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {toggleModal} from '../../store/modal/actions';
import {fetchSiteTokens} from '../../store/site/actions';
import {CustomError} from '../../utils/error';
import moment from 'moment';

type SelectOption = {
  label: string;
  value: string;
};
const reportTypes: Array<SelectOption> = [
  {
    label: 'Crops - Medicinal',
    value: 'medicinal',
  },
  {
    label: 'Crops - Scientific',
    value: 'scientific',
  },
];

const reportingYears: Array<SelectOption> = getYearsFromNow(10).map((y) => ({
  label: `${y}`,
  value: `${y}`,
}));

const initialFormState = {
  licenseNumber: '',
  year: `${new Date().getFullYear()}`,
  type: '' as CropReportType,
  pic: '',
};

type FormDataType = typeof initialFormState;
type FormDataKey = keyof FormDataType;

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    fontWeight: 'bold',
  },
  fieldValue: {
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.3),
    flexGrow: 1,
  },
  titleContainer: {
    border: 'none',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
  },
  reportContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  titleAnnotation: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  redText: {
    color: COLOR.RED,
  }
}));

const CropReports = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(initialFormState);

  const siteMaps = useAppSelector((state) => state.site.map((s) => s));

  const businessData = useAppSelector(
    (state) => state.user.businessProfileData,
  );

  const licenses = useAppSelector((state) =>
    state.user.businessProfileData.industryType === 'PLANTS'
      ? state.user.businessProfileData.licenses
      : [],
  );

  const licenseOptions: Array<SelectOption> = useMemo(() => {
    if (!licenses) {
      return [];
    }
    return licenses?.reduce((result, license) => {
      if (license.name === 'ODC') {
        return [
          ...result,
          {label: license.licenseNumber, value: license.licenseNumber},
        ];
      }
      return result;
    }, []);
  }, [licenses]);

  const selectedOdcPermits: Array<OdcPermit> = useMemo(() => {
    return licenses.reduce((result, license) => {
      if (
        license.name === 'ODC' &&
        license.licenseNumber === formData.licenseNumber
      ) {
        result.push(...license.permits);
      }
      return result;
    }, []);
  }, [formData.licenseNumber, licenses]);

  const siteOptions: Array<SelectOption> = useMemo(() => {
    if (!siteMaps) {
      return [];
    }
    return siteMaps.reduce((result, site) => {
      const loc = businessData.location.find(
        (loc) => loc.PICAddress === site.details.location,
      );
      if (
        loc &&
        loc.country === 'Australia' &&
        loc.permits?.some((permitId) =>
          selectedOdcPermits.find((p) => p.permitId === permitId),
        )
      ) {
        result.push({
          label: site.details.siteName,
          value: site.details.location,
        });
      }
      return result;
    }, []);
  }, [siteMaps, businessData.location, selectedOdcPermits]);

  const permitsInSite: CropReportDetails['permits'] = useMemo(() => {
    const locPermitIds = businessData.location.reduce((result, loc) => {
      if (loc.country === 'Australia' && loc.PICAddress === formData.pic) {
        result.push(...loc.permits);
      }
      return result;
    }, []);
    return selectedOdcPermits
      .filter((permit) => locPermitIds.find((id) => permit.permitId === id))
      .map((permit) => ({
        permitId: permit.permitId,
        permitNumber: permit.permitNumber,
        startDate: formatPermitDate(permit.startDate),
        expiryDate: formatPermitDate(permit.expiryDate),
      }));
  }, [formData.pic, selectedOdcPermits, businessData.location]);

  const siteName = useMemo(() => {
    return siteOptions.find((site) => site.value === formData.pic)?.label;
  }, [siteOptions, formData.pic]);

  const canSubmit = useMemo(() => {
    const permitIds = permitsInSite.map((permit) => permit.permitId);
    return (
      !Object.values(formData).some((v) => v === '') && permitIds.length > 0
    );
  }, [formData, permitsInSite]);

  const handleInputChange = (key: FormDataKey, value: any) => {
    setReportData(undefined);
    setFormData((prev) => {
      let result = prev;
      if (key === 'licenseNumber') {
        result = {...result, licenseNumber: value, pic: ''};
      } else {
        result = {...result, [key]: value};
      }
      return result;
    });
  };

  const [reportData, setReportData] = useState<CropReportData | undefined>(
    undefined,
  );

  const handleGenerateClick = async () => {
    const permitIds = permitsInSite.map((permit) => permit.permitId);
    const requestPayload = {
      filters: {
        year: formData.year,
        type: formData.type,
        licenseNumber: formData.licenseNumber,
        permitId: permitIds,
      },
      output: 'grid',
    };
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI<CropReportData>({
        url: API.POST.getIndustryReport,
        method: 'POST',
        data: requestPayload,
      });
      if (!response.monthly || !response.annual || !response.stock) {
        const err = response as any;
        throw new CustomError({
          title: 'Request Error',
          code: err.code,
          message: err.title,
          name: err.code,
        });
      }
      setReportData({
        stock: response.stock,
        monthly: response.monthly,
        annual: response.annual,
      });
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  const downloadExcelFile = () => {
    downloadSpreadSheet(formData.type, reportData, {
      licenseHolderName: businessData?.companyName,
      licenseNumber: formData.licenseNumber,
      permits: permitsInSite as any,
      siteId: siteName,
      year: formData.year,
    });
  };

  useEffect(() => {
    dispatch(fetchSiteTokens());
  }, [dispatch]);

  return (
    <Grid container justifyContent="space-between" item spacing={4} xs={12}>
      <Grid container item justifyContent="flex-start" spacing={4} xs={12}>
        <Grid container item xs={4}>
          <DropdownSelection
            title="Report Type"
            value={formData.type}
            onChange={(e) => handleInputChange('type', e.target.value)}>
            {reportTypes.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </DropdownSelection>
        </Grid>
        <Grid container item xs={4}>
          <DropdownSelection
            title="Reporting Year"
            value={formData.year}
            onChange={(e) => {
              handleInputChange('year', e.target.value);
            }}>
            {reportingYears.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </DropdownSelection>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-start" spacing={4} xs={12}>
        <Grid container item xs={4}>
          <DropdownSelection
            onChange={(e) => handleInputChange('licenseNumber', e.target.value)}
            value={formData.licenseNumber}
            title="License Number">
            {licenseOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </DropdownSelection>
        </Grid>
        <Grid container item xs={4}>
          <Grid container item direction="column">
            <Typography variant="h6" className={classes.fieldLabel}>
              {'License Holder'}
            </Typography>
            <Typography className={classes.fieldValue}>
              {businessData?.companyName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={4}>
          <DropdownSelection
            title="Site Name"
            onChange={(e) => handleInputChange('pic', e.target.value)}
            value={formData.pic}>
            {siteOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </DropdownSelection>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-start" spacing={4} xs={12}>
        {/* Permit Number */}
        <Grid container item xs={4} direction="column">
          <Grid container item>
            <Typography
              variant="h6"
              className={`${classes.fieldLabel} ${classes.mb2}`}>
              {'Permit Number'}
            </Typography>
          </Grid>
          {permitsInSite.map((permit) => (
            <Grid
              container
              item
              className={`${classes.mb2}`}
              key={permit.permitId}>
              <Typography>{permit.permitNumber}</Typography>
            </Grid>
          ))}
        </Grid>
        {/* Permit Start Date */}
        <Grid container item xs={4} direction="column">
          <Grid container item>
            <Typography
              variant="h6"
              className={`${classes.fieldLabel} ${classes.mb2}`}>
              {'Permit Start Date'}
            </Typography>
          </Grid>
          {permitsInSite.map((permit) => (
            <Grid
              container
              item
              className={`${classes.mb2}`}
              key={permit.permitId}>
              <Typography>{permit.startDate}</Typography>
            </Grid>
          ))}
        </Grid>
        {/* Permit Expiry Date */}
        <Grid container item xs={4} direction="column">
          <Grid container item>
            <Typography
              variant="h6"
              className={`${classes.fieldLabel} ${classes.mb2}`}>
              {'Permit Expiry Date'}
            </Typography>
          </Grid>
          {permitsInSite.map((permit) => (
            <Grid
              container
              item
              className={`${classes.mb2}`}
              key={permit.permitId}>
              {}
              <Typography className={`${isPermitExpired(permit.expiryDate)? classes.redText : '' }`}>{permit.expiryDate}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Box>
          <MyButton
            disabled={!canSubmit}
            text="Generate"
            variant="contained"
            onClick={handleGenerateClick}
          />
        </Box>
      </Grid>
      {reportData ? (
        <>
          {/* Monthly Report */}
          <Grid
            item
            container
            justifyContent="flex-start"
            direction="column"
            spacing={6}
            className={classes.reportContainer}>
            <Grid item>
              <Typography
                variant="h3"
                role="label"
                className={classes.titleContainer}>
                {'Monthly Report'}
              </Typography>
            </Grid>
            <Grid item container>
              <CropsTable
                rows={reportData.monthly}
                type={formData.type as any}
              />
            </Grid>
          </Grid>
          {/* Annual Report */}
          <Grid
            item
            container
            justifyContent="flex-start"
            direction="column"
            spacing={6}
            className={classes.reportContainer}>
            <Grid item className={classes.titleContainer}>
              <Typography variant="h3" role="label" display="inline">
                {'Annual Report'}
              </Typography>
              <Typography
                variant="h4"
                role="label"
                display="inline"
                className={classes.titleAnnotation}>
                {'(excludes rollover stock)'}
              </Typography>
            </Grid>
            <Grid item container>
              <CropsTable
                rows={reportData.annual}
                type={formData.type as any}
              />
            </Grid>
          </Grid>
          {/* Stock Reconciliation */}
          <Grid
            item
            container
            justifyContent="flex-start"
            direction="column"
            spacing={6}
            className={classes.reportContainer}>
            <Grid item>
              <Typography
                variant="h3"
                role="label"
                className={classes.titleContainer}>
                {'Stock Reconciliation'}
              </Typography>
            </Grid>
            <Grid item data-cy="StockReconciliation">
              <MyTable
                heads={[
                  'Type',
                  'Total Plants Generated',
                  'Total Plants Processed',
                  'Plants Not Accounted For',
                ]}
                rows={reportData.stock.map((stock) => [
                  stock.type,
                  stock.generated ?? 0,
                  stock.processed ?? 0,
                  stock.unaccounted ?? 0,
                ])}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Box>
              <MyButton
                text="Download as Excel File"
                variant="contained"
                onClick={downloadExcelFile}
              />
            </Box>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Crops Reports',
    margin: 40,
  },
  CropReports,
);
