import {useEffect, useState} from 'react';
import {TokenService} from '@aglive/data-model';
import {CompleteProductPromotion} from '../../store/promotion/types';

export const useDownloadPoster = () => {
  const [promotionToDownload, setPromotionToDownload] = useState<
    TokenService.PromotionToken
  >();
  const [QRCodeImage, setQRCodeImage] = useState('');

  useEffect(() => {
    if (promotionToDownload) {
      const qrCodeCanvas = document.getElementById(
        'qrCode',
      ) as HTMLCanvasElement;
      const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
      setQRCodeImage(qrCodeDataUri);
    }
  }, [promotionToDownload]);

  const getQRCodeSize = async (
    promotion: TokenService.PromotionToken,
    isAngus: boolean,
  ) => {
    let QRSize = 165;
    let waitImage = true;
    let waitCount = 5;
    if (promotion?.details.display.footerImage) {
      const footerImage = new Image();
      footerImage.src = promotion?.details.display.footerImage;
      footerImage.onload = function () {
        // Calculation below are converted from px to pt due to the pdf renderer package doesn't support px
        const tempHeight =
          (270 / (footerImage.width * 0.75)) * (footerImage.height * 0.75);
        const renderedHeight = tempHeight < 203 ? tempHeight : 203;
        const newHeight = 203 - renderedHeight + 120 + (isAngus ? -18.75 : 15);
        if (newHeight < 165) {
          QRSize = newHeight;
        }
        waitImage = false;
      };
      do {
        // Delay PDF generation to ensure the images are fetched before generating the file
        // Wait for a maximum of 5 seconds
        await new Promise((resolve) => setTimeout(resolve, 1000));
        waitCount--;
      } while (waitImage && waitCount > 0);
    }

    return QRSize;
  };

  const downloadPosterHandler = (blob: Blob, fileName: string) => {
    const downloadLink = URL.createObjectURL(blob);
    const downloadLinkElement = document.createElement('a');
    downloadLinkElement.href = downloadLink;
    downloadLinkElement.setAttribute('download', `${fileName}.pdf`);
    downloadLinkElement.click();
    downloadLinkElement.remove();
  };

  return {
    promotionToDownload,
    setPromotionToDownload,
    QRCodeImage,
    setQRCodeImage,
    getQRCodeSize,
    downloadPosterHandler,
  };
};
