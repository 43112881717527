import React from 'react';

import {
  FormControl,
  Select,
  SelectProps,
  Typography,
  MenuItem,
  Button,
  Grid,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import {DashboardWidgetInputSchema} from './Dashboard';
import COLOR from '../../styled/colors';

type DashboardDropDownWidgetProps = {
  title: string;
  dropdownKey: string; // Please pass a key if multiple dropdown were used
  options: DashboardWidgetInputSchema;
  clearFilter: () => void;
} & SelectProps;

const DashboardDropDownWidget: React.FC<DashboardDropDownWidgetProps> = ({
  title,
  dropdownKey,
  options,
  clearFilter,
  ...modalOptions
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h6" role="label" style={{fontWeight: 600}}>
          {title}
        </Typography>
        <Button onClick={() => clearFilter()} id={"clear" + title.replace(/ /g, '')}>
          <span
            style={{
              color: COLOR.GREENT_TEXT,
              textTransform: 'capitalize',
            }}>
            <u>{'Clear Filter'}</u>
          </span>
        </Button>
      </Grid>
      <FormControl variant="outlined" style={{width: '100%'}}>
        <InputLabel id={`dropdown-label-${dropdownKey}`}>{'Please select'}</InputLabel>
        <Select
          label={'Please Select'}
          labelId={`dropdown-label-${dropdownKey}`}
          id={`dropdown-${dropdownKey}`}
          {...modalOptions}>
          {options.map((option, index) => (
            <MenuItem value={option.value} key={option.attribute.key}>
              {option.attribute.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default DashboardDropDownWidget;
