// React
import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
// Material UI Imports

// Presentation, Container, Styles
import withHeader from '../../presentation/withHeader';
// Redux
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  RawAssetProfile,
  REMOVE_TEMP_ASSET_PROFILE,
} from '../../store/assetProfile/types';
import {
  fetchDropdownOptions,
  fetchStartNumber,
  generateAssets,
} from '../../store/plant/actions';
import {SiteTokenResponse} from '../../store/site/types';
import {getBusinessProfile} from '../../store/profile/actions';
import CONSTANT from '../../config/constant';
import {filterPermits, isPermitExpired} from '../../store/profile/utils';
import {toggleModal} from '../../store/modal/actions';
import {DialogContentSubtitle} from '../../presentation/DialogContentSubtitle';
import GenerateAssetsForm, {
  GenerateAssetPayload,
} from './forms/GenerateAssetsForm';

type DropdownOptions = {
  assetProfiles: Array<RawAssetProfile>;
  sites: Array<SiteTokenResponse>;
};

const GenerateCodes: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  // States
  const locations = useAppSelector(
    (state) => state.user.businessProfileData.location,
  );

  const tempAssetProfile = useAppSelector(
    (state) => state.assetProfile.tempAssetProfile,
  );
  const [strainStartNumber, setStrainStartNumber] = useState<
    number | undefined
  >();

  const [allAssetProfiles, setAllAssetProfiles] = useState<
    Array<RawAssetProfile>
  >([]);
  const [allSites, setAllSites] = useState<Array<SiteTokenResponse>>([]);

  const licenses = useAppSelector((state) =>
    state.user.businessProfileData.industryType === 'PLANTS'
      ? state.user.businessProfileData.licenses ?? []
      : [],
  );
  const permits = useMemo(() => {
    const result = filterPermits('ODC', licenses).filter(
      (p) => !isPermitExpired(p.expiryDate),
    );
    return result;
  }, [licenses]);

  const handleStrainChange = useCallback(
    (strain: string) => {
      dispatch(fetchStartNumber(strain)).then((startNumber) => {
        setStrainStartNumber(startNumber);
      });
    },
    [dispatch],
  );

  const handleGenerate = useCallback(
    ({
      download,
      assetDetails,
      assetProfile,
      siteLayerName,
    }: GenerateAssetPayload) => {
      let callBackPath = '/private/codes/generate';
      if (download) {
        callBackPath = '/private/codes/generate/used';
      }
      dispatch(
        generateAssets({
          download,
          assetDetails,
          assetProfile,
          siteLayerName,
          successCB: (docId: string) => {
            if (download) history.replace('/private/codes/generate');
            history.push(download ? `${callBackPath}/${docId}` : callBackPath);
          },
        }),
      );
    },
    [dispatch, history],
  );

  // Side Effects
  useEffect(() => {
    // Fetch Asset Profiles and Sites
    dispatch(fetchDropdownOptions()).then((response) => {
      let initialDropdownOptions: DropdownOptions = {
        assetProfiles: [],
        sites: [],
      };
      const dropdownOptions: DropdownOptions = response.reduce(
        (result, token) => {
          if (token.type === CONSTANT.ASSETTYPE.ASSET_PROFILE) {
            const assetToken = token as RawAssetProfile;
            if (!assetToken.details.archived) {
              result.assetProfiles.push(assetToken);
            }
          } else if (token.type === CONSTANT.ASSETTYPE.SITE) {
            result.sites.push(token as SiteTokenResponse);
          }
          return result;
        },
        initialDropdownOptions,
      );

      setAllAssetProfiles(dropdownOptions.assetProfiles);
      setAllSites(dropdownOptions.sites);
    });
    // Fetch latest business profile to get updated locations
    dispatch(getBusinessProfile());
  }, [dispatch]);

  useEffect(() => {
    /**
     * If temporary asset profile is found in redux, it means that the user just
     * returned from create asset profile page. Thus, populate the dropdown and
     * remove the temp asset profile.
     */
    if (tempAssetProfile && allAssetProfiles.length) {
      dispatch({type: REMOVE_TEMP_ASSET_PROFILE});
    }
  }, [tempAssetProfile, allAssetProfiles.length, dispatch]);

  return (
    <GenerateAssetsForm
      pathname={location.pathname}
      assetProfiles={allAssetProfiles}
      allSites={allSites}
      permits={permits}
      locations={locations}
      onGenerateAssets={handleGenerate}
      onStrainChange={handleStrainChange}
      strainStartNumber={strainStartNumber}
      initialAssetProfileId={
        allAssetProfiles.length > 0
          ? tempAssetProfile?.externalIds[0]?.agliveToken
          : undefined
      }
    />
  );
};

export default withHeader(
  {
    title: 'Generate Codes',
    back: true,
  },
  GenerateCodes,
);
