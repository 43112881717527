import {BusinessMisc, PluginModel, TokenService} from '@aglive/data-model';
import { SiteToken } from 'src/store/site/types';
export interface userProfileTypes {
  id: number | string;
  image: string;
  name: string;
  role: string;
  mobileNumber: string;
  emailAddress: string;
  signature: string;
  address: string;
  businessId: string;
  externalIds: {userId: string};
  country?: string;
}

export interface locationTypes {
  geofence?: Array<{lat: number, lng: number}>;
  locationNickname: string;
  locationType?: string;
  PICAddress?: string;
  locationId?: string;
  country?: string;
  address: string;
  postcode: string;
  town: string;
  state: string;
  LPAUserID?: string;
  LPAPassword?: string;
  NLISUserID?: string;
  NLISPassword?: string;
  tagAlertStandard?: BusinessMisc.TagAlertStandard;
}

export interface optiweighType{
  clientId: string;
  apiKey: string;
  agliveKey: string;
  interval: string;
}
export interface optiweighDeleteType extends optiweighType{
  delete?: boolean;
}
export interface editBusinessProfileTypes {
  businessScope: string;
  businessType: string;
  companyName: string;
  companyNumber: string;
  licenseNumber?: string;
  licenseExpiryDate?: string|Date; // initialized value string
  brandDescription?: string;
  industryType: string;
  imageUri?: string;
  location: Array<locationTypes>;
  angusProfile?: angusProfileType;
  permits?: Array<BusinessMisc.Permit>;
  optiweigh?: optiweighType;
  businessCountry?:string;
}
/*export interface businessProfileTypes extends editBusinessProfileTypes {
  industryType: string;
  users?: Array<any>;
}*/
//type test = TokenService.BusinessToken['details'];
export type businessProfileTypes = TokenService.BusinessToken['details'];

export type angusProfileType = {
  NLISPassword: string;
  NLISUserID: string;
  abn: string;
  acctID: string;
  angusAuthToken: string;
  angusTechPassword: string;
  angusTechUsername: string;
  businessName: string;
  isVerifiedProducer: boolean;
  properties: Array<any>;
};

export type ApiKeyProfile = {key: string, scope: string, locationUniqueId?: string};
export interface profileState {
  readonly userProfileData: userProfileTypes;
  readonly businessProfileData: TokenService.BusinessToken['details'] & {angusEnabled?: boolean};
  readonly plugins?: TokenService.BusinessToken['plugins'];
  readonly reports?: Array<PluginModel.PluginReport>;
  readonly schema?: {
    induction: PluginModel.Plugin['csvSchema']['induction'],
    template: PluginModel.Plugin['csvSchema']['template'],
    management: {
      replace?: PluginModel.Plugin['csvSchema']['management']['schema']
      add?: PluginModel.Plugin['csvSchema']['management']['schema']
    }
  };
  readonly apiKeys?: Array<ApiKeyProfile>;
  readonly contacts: TokenService.BusinessToken['contacts'];
}

export const POST_BUSINESS_PROFILE_DATA = 'POST_BUSINESS_PROFILE_DATA';
export const POST_BUSINESS_ID = 'POST_BUSINESS_ID';
export const POST_USER_PROFILE_DATA = 'POST_USER_PROFILE_DATA';
export const UPDATE_USER_PROFILE_DATA = 'UPDATE_USER_PROFILE_DATA';
export const DROP_PROFILE_DATA = 'DROP_PROFILE_DATA';
export const POST_PLUGIN_DATA = 'POST_PLUGIN_DATA';
export const POST_REPORT_DATA = 'POST_REPORT_DATA';
export const POST_SCHEMA_DATA = 'POST_SCHEMA_DATA';
export const POST_API_KEY = 'POST_API_KEY';
export const TOGGLE_ANGUS = 'TOGGLE_ANGUS';
export const POST_CONTACTS = 'POST_CONTACTS';

export interface postUserType {
  type: typeof POST_USER_PROFILE_DATA;
  payload: userProfileTypes;
}

export interface postBusinessType {
  type: typeof POST_BUSINESS_PROFILE_DATA;
  payload: TokenService.BusinessToken['details'];
}

export interface postBusinessIdType {
  type: typeof POST_BUSINESS_ID;
  payload: string;
}

export interface postPluginType {
  type: typeof POST_PLUGIN_DATA;
  payload: TokenService.BusinessToken['plugins'];
}

export interface postSchemaType {
  type: typeof POST_SCHEMA_DATA;
  payload: profileState['schema']
}

export interface postReportType {
  type: typeof POST_REPORT_DATA;
  payload: Array<PluginModel.PluginReport>;
}

export interface postApiKeyType {
  type: typeof POST_API_KEY;
  payload: Array<ApiKeyProfile>;
}

export interface postContacts {
  type: typeof POST_CONTACTS;
  payload: TokenService.BusinessToken['contacts'];
}

export type ProfileTypes = postUserType 
  | postBusinessType
  | { type: typeof DROP_PROFILE_DATA }
  | postBusinessIdType
  | postPluginType
  | postReportType
  | postSchemaType
  | postApiKeyType
  | postContacts
  | { type: typeof TOGGLE_ANGUS}

export type PolygonPaths = Array<{
    lat: number,
    lng: number,
}>;
