import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const CustomDialogTitleStyles = (theme: Theme) =>
  createStyles({
    titleContainer: {
      margin: 0,
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      paddingBottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    title: {
      fontSize: '1.5rem',
    },
  });

interface CustomDialogTitleProps
  extends WithStyles<typeof CustomDialogTitleStyles> {
  children: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = withStyles(CustomDialogTitleStyles)(
  ({children, onClose, classes, ...other}: CustomDialogTitleProps) => {
    return (
      <DialogTitle
        disableTypography
        className={classes.titleContainer}
        {...other}>
        <Typography variant="h5" className={classes.title}>
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  },
);

type FullWidthModalProps = {
  open?: boolean;
  onClose?: () => void;
  title: string;
  maxWidth?: 'xl' | 'xs' | 'sm' | 'md' | 'lg';
};

const FullWidthModal: React.FC<FullWidthModalProps> = ({
  open,
  onClose,
  title,
  children,
  maxWidth = 'xl',
}) => {
  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default FullWidthModal;
