import React from 'react';
import Qrcode from 'qrcode.react';
import './CodeGridPrint.css';
import {CODE_SEQUENCE_MAX_DIGITS, padZeros} from '../utils';

type Props = {
  startNumber: number;
  strain: string;
  qrCodes: Array<string>;
};

const MAX_QR_PER_PAGE = 70;
const CodeGridPrint = React.forwardRef<HTMLDivElement, Props>(
  ({startNumber, strain, qrCodes}, ref) => {
    const qrchunksPerPage: Array<Array<{qr: string; qrIndex: number}>> =
      qrCodes.reduce((result, qr, index) => {
        const chunkIndex = Math.floor(index / MAX_QR_PER_PAGE);
        if (!result[chunkIndex]) {
          result[chunkIndex] = []; // start a new chunk
        }
        result[chunkIndex].push({qr, qrIndex: index});
        return result;
      }, []);
    const strainDisplay = strain;
    return (
      <div ref={ref} className="code-grid-print-container">
        {qrchunksPerPage.map((qrchunks, i) => {
          return (
            <div key={`qrchunk-${i}`}>
              {i > 0 ? <div className="page-break" /> : null}
              <div className="code-grid">
                {qrchunks.map((code) => {
                  const label = padZeros(
                    `${startNumber + code.qrIndex}`,
                    CODE_SEQUENCE_MAX_DIGITS,
                  );
                  return (
                    <div className="qr-container" key={code.qr}>
                      <Qrcode
                        value={code.qr}
                        style={{
                          width: '42px',
                          height: '42px',
                        }}
                      />
                      <div className="code-label-container">
                        <div>{`${strainDisplay}_`}</div>
                        <div>{label}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  },
);

export default CodeGridPrint;
