import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import COLOR from '../../styled/colors';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import withHeader from '../../presentation/withHeader';
import Button from '@material-ui/core/Button';
import {withStyles, makeStyles, Grid, Typography} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {SiteTreeLayerNode} from '../../store/site/types';
import {fetchGroupTokens, downloadMoveCSV} from '../../store/activity/actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FilterList from '@material-ui/icons/FilterList';
import moment from 'moment';
import {getUserProfile} from '../../store/profile/actions';
import Row from './MoveTable';
import PrintIcon from '@material-ui/icons/Print';
import {IconButton} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {flattenTree} from '../../store/site/tree';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CONSTANT from '../../config/constant';
import produce from 'immer';

type Props = {};

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 10,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  filterContainer: {
    marginRight: 50,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 310,
    maxHeight: 56,
    marginRight: 8,
  },
  table: {
    minWidth: 700,
  },
  locationFilter: {
    flexDirection: 'row',
    display: 'flex',
  },
}));
const StyledTableCell = withStyles((theme: any) => ({
  // TODO Fix TS error
  head: {
    backgroundColor: COLOR.GRAY_SOLID,
    // color: theme.palette.primary.main,
    ...theme.typography.h6,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

type MoveItem = {
  activity: {
    type: string;
    userId: string;
    details: any;
    createdAt: string;
    hash: string;
  };
  name: any;
  items: any;
  externalId: string;
  checked: boolean;
};

type FilterData = {
  from_date: string;
  to_date: string;
  from_location_pic: string;
  from_location_site: string;
  to_location_pic: string;
  to_location_site: string;
};

type FlattenedSite = {
  agliveToken: string;
  layers: SiteTreeLayerNode[];
};

type ShownItem = {
  activity: {
    type: string;
    userId: string;
    details: any;
    createdAt: string;
    hash: string;
  };
  name: any;
  items: any;
  externalId: string;
  checked: boolean;
};

const MoveActivity: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const userid = useAppSelector((state) => state.auth.wallet);
  const siteTokensResArr = useAppSelector((state) => state.site);
  const locationState = useAppSelector((state) => state.location.location);
  const [allSite, setAllSite] = useState<Array<string>>([]);
  const [toAllSite, setToAllSite] = useState<Array<string>>([]);
  const industryType = useAppSelector(
    (state) => state.user.businessProfileData.industryType,
  );
  const [fromPIC, selectedFromPIC] = useState('');
  const [fromSite, selectedFromSite] = useState('');
  const [toPIC, selectedToPIC] = useState('');
  const [toSite, selectedToSite] = useState('');
  const picList = useMemo(() => {
    return ['None', ...locationState.map((location) => location.PICAddress)];
  }, [locationState]);

  const flattenedSiteLayers = useMemo(() => {
    return siteTokensResArr.map((site) => {
      const agliveToken = site.externalIds[0]?.agliveToken;
      const layers = flattenTree(site.details.layers);
      return {agliveToken, layers};
    });
  }, [siteTokensResArr]);

  const siteState = useMemo(
    () =>
      siteTokensResArr.filter(
        (token) => token.details.location === picList[Number(fromPIC)],
      ),
    [fromPIC, picList, siteTokensResArr],
  );

  const toSiteState = useMemo(
    () =>
      siteTokensResArr.filter(
        (token) => token.details.location === picList[Number(toPIC)],
      ),
    [toPIC, picList, siteTokensResArr],
  );

  const [showFilter, setShowFilter] = useState(false);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;
  const tableData = [
    'Date',
    'Group/Items',
    'By',
    'From',
    'To',
    <Button onClick={() => setAllSelected(!allSelected)} id={'AllButton'}>
      <span style={{color: COLOR.GREENT_TEXT}}>
        <u>{'All'}</u>
      </span>
    </Button>,
    <IconButton
      id={'PrintButton'}
      onClick={() => {
        dispatch(downloadMoveCSV(showList, user));
      }}>
      <PrintIcon style={{color: COLOR.BLACK_BG}} />
    </IconButton>,
  ];

  const filterDataInitialState: FilterData = useMemo(
    () => ({
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
      from_location_pic: '',
      from_location_site: '',
      to_location_pic: '',
      to_location_site: '',
    }),
    [],
  );

  const [filterData, setFilterData] = useState<FilterData>(
    filterDataInitialState,
  );

  useEffect(() => {
    if (!userid) {
      return;
    }
    dispatch(
      fetchGroupTokens(userid, [
        CONSTANT.ASSETTYPE.ASSET,
        CONSTANT.ASSETTYPE.PLANT,
        CONSTANT.ASSETTYPE.SITE,
      ]),
    );
    dispatch(getUserProfile(userid));
  }, [dispatch, userid]);

  const handleDate = (date: Date, fieldName: string) => {
    setFilterData((prevState) => ({
      ...prevState,
      [fieldName]: new Date(date),
    }));
  };

  const setLocationData =
    (key: string) =>
    (e: React.ChangeEvent<{name?: string; value: unknown}>) => {
      const index = e.target.value as string;
      if (key === 'from_location_pic') {
        selectedFromPIC(index);

        if (Number(index) !== 0) {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: picList[Number(index)],
          }));
        } else {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: '',
          }));
          setFilterData((prevState) => ({
            ...prevState,
            from_location_site: '',
          }));
          selectedFromSite('');
        }
      } else if (key === 'from_location_site') {
        selectedFromSite(index);
        if (Number(index) === 0) {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: '',
          }));
        } else if (Number(index) === allSite.length - 1) {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: 'Mutiple Sites',
          }));
        } else {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: allSite[Number(index)],
          }));
        }
      }
      if (key === 'to_location_pic') {
        selectedToPIC(index);
        if (Number(index) !== 0) {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: picList[Number(index)],
          }));
        } else {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: '',
          }));
          selectedToSite('');
          setFilterData((prevState) => ({
            ...prevState,
            to_location_site: '',
          }));
        }
      } else if (key === 'to_location_site') {
        selectedToSite(index);
        if (Number(index) !== 0) {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: toAllSite[Number(index)],
          }));
        } else {
          setFilterData((prevState) => ({
            ...prevState,
            [key]: '',
          }));
        }
      }
    };
  const user = useAppSelector((state) => state.user.userProfileData.name);

  const {groups, singleAssets} = useAppSelector((state) => state.activity);

  const activitiesList = useMemo(() => {
    return groups.concat(singleAssets).map((item) => {
      return {
        indicator: item.activities.values(),
        name: item.details.name,
        items: item.details.items,
        externalId: item.externalIds[0].agliveToken,
      };
    });
  }, [groups, singleAssets]);

  const [allSelected, setAllSelected] = useState(false);

  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());

  const moveList = useMemo(() => {
    const result: MoveItem[] = [];
    activitiesList.forEach((item) => {
      for (let activity of item.indicator) {
        if (
          activity &&
          activity?.type === 'move' &&
          !(
            activity.details.hasOwnProperty('moveWithinGroup') &&
            activity.details.moveWithinGroup === true
          ) &&
          activity.details?.origin?.site?.layerName !== ''
        ) {
          result.push({
            activity: activity,
            name: item.name,
            items: item.items,
            externalId: item.externalId,
            checked: false,
          });
        }
      }
    });
    return result;
  }, [activitiesList]);

  const showList: Array<ShownItem> = useMemo(() => {
    if (showFilter) {
      return filterMoveActivities(
        moveList,
        flattenedSiteLayers,
        filterData,
      ).slice(0, 10);
    }

    // default list
    return moveList
      .map((x) => ({
        ...transformSiteData(flattenedSiteLayers, x),
        checked: checkedItems.has(x.externalId),
      }))
      .sort((a, b) => {
        return (
          new Date(b.activity.createdAt).valueOf() -
          new Date(a.activity.createdAt).valueOf()
        );
      })
      .slice(0, 10);
  }, [checkedItems, filterData, flattenedSiteLayers, moveList, showFilter]);

  useEffect(() => {
    if (siteState.length > 0) {
      const fromSite = flattenTree(siteState[0].details.layers)
        .slice(1)
        .map((site) => site.layerName);
      fromSite.unshift('None');
      fromSite.push('Mutiple Sites');
      setAllSite(fromSite);
    } else {
      setAllSite([]);
    }
  }, [fromPIC, siteState]);

  useEffect(() => {
    if (toSiteState.length > 0) {
      const toSite = flattenTree(toSiteState[0].details.layers)
        .slice(1)
        .map((site) => site.layerName);
      toSite.unshift('None');
      setToAllSite(toSite);
    } else {
      setToAllSite([]);
    }
  }, [toPIC, toSiteState]);

  const [query, setQuery] = useState('');

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search Group Name/Items"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Create New'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(`${path}/create`)}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.filterContainer}>
        <div className={classes.filterButton}>
          <FilterList className={classes.filterIcon} />
          <Button onClick={() => setShowFilter(!showFilter)}>
            <span style={{color: COLOR.GREENT_TEXT}}>
              <u>{'Filter Date and Location'}</u>
            </span>
          </Button>
        </div>
        {showFilter === true && (
          <Grid
            item
            container
            justifyContent="flex-start"
            xs={12}
            spacing={1}
            style={{marginBottom: 20}}>
            <Grid item xs={3}>
              <Typography variant="h6" role="label" style={{fontWeight: 600}}>
                From
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="inductonStartDate"
                  style={{
                    margin: 0,
                    border: 'solid 1px lightgray',
                    padding: '11px 14px',
                    borderRadius: '5px',
                  }}
                  emptyLabel={'dd/mm/yyyy'}
                  value={moment(filterData.from_date) || null}
                  onChange={(date) => {
                    handleDate(date, 'from_date');
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  keyboardIcon={<DateRangeIcon style={{fill: '#373935'}} />}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" role="label" style={{fontWeight: 600}}>
                To
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="inductonStartDate"
                  style={{
                    margin: 0,
                    border: 'solid 1px lightgray',
                    padding: '11px 14px',
                    borderRadius: '5px',
                  }}
                  emptyLabel={'dd/mm/yyyy'}
                  value={moment(filterData.to_date) || null}
                  onChange={(date) => {
                    handleDate(date, 'to_date');
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  keyboardIcon={<DateRangeIcon style={{fill: '#373935'}} />}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" role="label" style={{fontWeight: 600}}>
                From Location
              </Typography>
              <Grid className={classes.locationFilter}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {fromPIC === '' && (
                    <InputLabel id="move-select-from-location" shrink={false}>
                      {industryType === 'PLANTS'
                        ? 'Please select'
                        : isAustralia
                        ? 'PIC'
                        : 'PID'}
                    </InputLabel>
                  )}
                  <Select
                    labelId="move-select-from-location"
                    value={fromPIC}
                    onChange={setLocationData('from_location_pic')}>
                    {picList.map((pic, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {pic}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {fromSite === '' && (
                    <InputLabel id="move-select-from-site" shrink={false}>
                      {'Site Map'}
                    </InputLabel>
                  )}
                  <Select
                    labelId="move-select-from-site"
                    value={fromSite}
                    onChange={setLocationData('from_location_site')}>
                    {allSite?.map((sit, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {sit}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" role="label" style={{fontWeight: 600}}>
                To Location
              </Typography>
              <Grid className={classes.locationFilter}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {toPIC === '' && (
                    <InputLabel id="move-select-to-location" shrink={false}>
                      {industryType === 'PLANTS'
                        ? 'Please select'
                        : isAustralia
                        ? 'PIC'
                        : 'PID'}
                    </InputLabel>
                  )}
                  <Select
                    labelId="move-select-to-location"
                    value={toPIC}
                    onChange={setLocationData('to_location_pic')}>
                    {picList.map((pic, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {pic}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {toSite === '' && (
                    <InputLabel id="move-select-to-site" shrink={false}>
                      {'Site Map'}
                    </InputLabel>
                  )}
                  <Select
                    labelId="move-select-to-site"
                    value={toSite}
                    onChange={setLocationData('to_location_site')}>
                    {toAllSite?.map((sit, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {sit}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableData.map((title, index) => (
                <StyledTableCell align="left" key={index}>
                  {title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showList
              .filter((activity) =>
                (activity.name?.length ? activity.name : 'items')
                  .toLowerCase()
                  .includes(query.toLowerCase()),
              )
              .map((item, index) => (
                <Row
                  item={item}
                  checkedHandle={(checked: boolean) => {
                    const id = item.externalId;
                    setCheckedItems((prev) => {
                      if (checked) {
                        prev.add(id);
                      } else {
                        prev.delete(id);
                      }
                      return prev;
                    });
                  }}
                  allSelected={allSelected}
                  id={index}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const filterMoveActivities = (
  moveList: Array<MoveItem>,
  flattenedSiteLayers: Array<FlattenedSite>,
  filterData: FilterData,
) => {
  let res = moveList
    .map((x) => transformSiteData(flattenedSiteLayers, x))
    .filter((item) => {
      return (
        moment(item.activity.createdAt).isSameOrAfter(
          filterData.from_date,
          'day',
        ) &&
        moment(item.activity.createdAt).isSameOrBefore(
          filterData.to_date,
          'day',
        )
      );
    });
  if (filterData.from_location_pic) {
    res = res.filter((item) => {
      return (
        item.activity.details.origin.location === filterData.from_location_pic
      );
    });
  }
  if (filterData.from_location_site) {
    res = res.filter((item) => {
      return (
        item.activity.details.origin.site === filterData.from_location_site
      );
    });
  }
  if (filterData.to_location_pic) {
    res = res.filter((item) => {
      return (
        item.activity.details.destination.location ===
        filterData.to_location_pic
      );
    });
  }
  if (filterData.to_location_site) {
    res = res.filter((item) => {
      return (
        item.activity.details.destination.site === filterData.to_location_site
      );
    });
  }
  return res;
};

const transformSiteData = (
  flattenedSiteLayers: Array<FlattenedSite>,
  moveItem: MoveItem,
) => {
  const originLocation = moveItem.activity.details.origin;
  const originLayer = findLayer(
    flattenedSiteLayers,
    originLocation?.site?.agliveToken,
    originLocation?.site?.layerId,
  );
  const originLayerName = originLayer?.layerName ?? '';
  const transformedOrigin = {
    ...originLocation,
    site:
      typeof originLocation?.site === 'string' &&
      originLocation?.site.length > 0
        ? originLocation?.site
        : originLayerName,
  };

  const destinationLocation = moveItem.activity.details.destination;
  const destinationLayer = findLayer(
    flattenedSiteLayers,
    destinationLocation?.site?.agliveToken,
    destinationLocation?.site?.layerId,
  );
  const destinationLayerName = destinationLayer?.layerName ?? '';
  const transformedDestination = {
    ...originLocation,
    site: destinationLayerName,
  };

  return produce(moveItem, (draft) => {
    draft.activity.details.origin =
      typeof originLocation?.site === 'string' &&
      originLocation?.site.length > 0
        ? originLocation
        : transformedOrigin;
    draft.activity.details.destination =
      typeof destinationLocation?.site === 'string' &&
      destinationLocation?.site.length > 0
        ? destinationLocation
        : transformedDestination;
  });
};

function findLayer(
  flattenedSiteLayers: Array<FlattenedSite>,
  agliveToken: string,
  layerId: string,
) {
  const site = flattenedSiteLayers.find(
    (site) => site.agliveToken === agliveToken,
  );
  if (!site) {
    return;
  }
  let currentLayerId = layerId;
  if (
    agliveToken?.length > 0 &&
    layerId?.length > 0 &&
    layerId.includes(agliveToken)
  ) {
    const splitted = layerId.split('_');
    currentLayerId = splitted[splitted.length - 1];
  }
  const layer = site.layers.find((layer) => layer.id === currentLayerId);
  if (!layer) {
    return {layerName: '', layerId: ''};
  }
  return {
    layerName: layer.layerName,
    layerId: layer.id,
  };
}

export default withHeader(
  {
    title: 'Move',
  },
  MoveActivity,
);
