import {
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {FilterItem, SortItem} from './types';
const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 56,
  },
  bodyContainer: {
    marginBottom: 30,
  },
}));

type CodeSearchFormProps = {
  filterItems: Array<FilterItem>;
  sortItem: SortItem;
  onFilterChanged: (filterName: string, value: string) => void;
  onSortChanged: (value: string) => void;
};

const CodeSearchForm: React.FC<CodeSearchFormProps> = ({
  filterItems,
  sortItem,
  onFilterChanged,
  onSortChanged,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      xs={12}
      className={classes.bodyContainer}>
      <Grid item>
        <Typography style={{fontWeight: 'bold'}}>Filter:</Typography>
      </Grid>
      {filterItems.map((filterItem) => (
        <Grid item sm={12} md={3} key={filterItem.filterName}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}>
            <Select
              labelId={`filter-${filterItem.filterName.split(' ').join('-')}`}
              value={filterItem.selectedItem}
              onChange={(e) =>
                onFilterChanged(filterItem.filterName, e.target.value as string)
              }>
              {filterItem.items.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ))}
      <Grid item sm={12} md={3}>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}>
          <Select
            labelId="sort-by-asset"
            value={sortItem.selectedItem}
            onChange={(e) => onSortChanged(e.target.value as string)}
          >
            {
              sortItem.items.map((value) => <MenuItem key={value} value={value}>Sort By {value}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CodeSearchForm;
