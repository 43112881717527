import React, { useEffect, useMemo, useReducer } from 'react';
import { Grid, Link } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';
import { useHistory } from 'react-router';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CreateProductProfileDetails from '../CreateProductProfileDetails';
import TellYourStory from '../../TellYourStory';

import MyButton from '../../../../presentation/button';
import ProgressBar from '../../../../presentation/ProgressBar';
import withHeader from '../../../../presentation/withHeader';
import ProductHeader from '../../ProductHeader';
import toggleQRCodeModal from './toggleQRCodeModal';

import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../../store/spinner/types';
import { toggleModal } from '../../../../store/modal/actions';

import productProfileReducer, { canSubmit, INITIAL_CREATE_PROFILE_STATE } from './productProfileState/reducer';

import { productProfileActions } from './productProfileState/types';
import localActions, { localDispatchDecorator } from './productProfileState/actions';
import { TellYourStoryActions as TellYourStoryActionTypes } from '../../TellYourStory/tellYourStoryState/types';
import TellYourStoryActions from '../../TellYourStory/tellYourStoryState/actions';

import { findTargetBrand } from '../../../brand/utils';
import { fetchBrands } from '../../../../store/brand/actions';
import { callAPI } from '../../../../utils/network';
import { addProductToBrand } from '../../../../store/product/actions';

import { WebErrorType, WebError } from '../../../../utils/error';
import { TokenService } from '@aglive/data-model';

import COLOR from '../../../../styled/colors';
import API from '../../../../config/api';
import CONSTANT from '../../../../config/constant';
import { STORE_TEMP_PRODUCT } from '../../../../store/product/types';
import produce from 'immer';

export const CREATE_PRODUCT_PROMOTION_PATH = '/private/codes/promotionCodes/new';
export const CREATE_SECURITY_CODE_PATH = '/private/codes/securityCodes/new';

export const stateHasPrevState = (state: unknown): state is {prevLocation: string} => {
  return Object.keys(state).includes('prevLocation');
}

const CreateProductProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const businessPlugins = useAppSelector((state) => state.user.plugins);
  const isMackasShowcase = useMemo(
    () =>
      !!businessPlugins?.find(
        (p) =>
          p.status === 'activated' &&
          p.name === CONSTANT.MACKAS_SHOWCASE_PLUGIN,
      ),
    [businessPlugins],
  );

  const isAmbioxera = useMemo(
    () =>
      !!businessPlugins?.find(
        (br) =>
          br.name.toLowerCase() === 'ambioxera' && br.status === 'activated',
      ),
    [businessPlugins],
  );

  const tmpInitial: typeof INITIAL_CREATE_PROFILE_STATE = useMemo(() => {
    return produce(INITIAL_CREATE_PROFILE_STATE, (draft) => {
      if (isAmbioxera || isMackasShowcase) {
        draft.state.story.mainSectionImageOption = '';
        // Skip product details pages
        draft.skipPages = true;
      }
    });
  }, [isAmbioxera, isMackasShowcase]);

  const [productProfileState, localDispatch] = useReducer(productProfileReducer, tmpInitial);

  // the state will store the fetch response data to preserve blockchain id
  const userid = useAppSelector(state => state.auth.wallet);
  useEffect(() => {
    fetchBrands(dispatch).then((brandTokens) => {
      if (brandTokens) {
        localDispatch(localActions.getBrandList(brandTokens.filter(brand => !brand.details.archive)))
      }
    });
  }, []);

  const localDispatchAction = localDispatchDecorator<productProfileActions | TellYourStoryActionTypes>(localDispatch);

  useEffect(() => {
    // the productProfileState.state.status serves as a relay and is only changed by onSubmit->reducer->validation
    // if all tests pass then status = 'submit', useEffect hook will be triggered on state change and trigger the following submission logic
    const submitForm = async () => {
      if (productProfileState.state.status === 'draft') {
        return;
      }

      try {
        dispatch({ type: SPINNER_TOGGLE_ON });

        const response: {
          message: string;
          data: Array<TokenService.ProductToken>;
        } = await callAPI({
          url: API.POST.createToken,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.PRODUCT,
                tokenId: '',
                details: productProfileState.payload,
              },
            ],
          },
        });
        if (!response.data) {
          throw new WebError('REQUEST_ERROR')
        }
        /**
         * If user came from create promotion/security code page, store the newly generated
         * token into redux for dropdown population purpose. The token will be removed from redux 
         * store once the user returned back to the create promotion/security code page
         */
        if (history?.location?.state) {
          const {state} = history.location;
          if (
            stateHasPrevState(state) &&
            (state.prevLocation === CREATE_PRODUCT_PROMOTION_PATH ||
              state.prevLocation === CREATE_SECURITY_CODE_PATH)
          ) {
            dispatch({type: STORE_TEMP_PRODUCT, payload: response.data[0]});
          }
        }

        toggleQRCodeModal(
          dispatch,
          history, 
          {
            status: 'success',
            title: 'Saved',
            QRCodeContent: CONSTANT.SCAN_URL(response.data[0]?.externalIds?.[0]?.agliveToken),
          }
        );
      } catch (e) {
        const error = e as WebErrorType;
  
        {localDispatch(localActions.changeStateStatus('draft'))}

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      } finally {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      }
    }

    submitForm();
  }, [productProfileState.state.status]);

  const checkProductDetailsMedia = () => {
    // Prompt warning modal if image or video in product details
    for (const page of productProfileState.payload.pages) {
      if (!page.display.url.trim().length) {
        dispatch(
          toggleModal({
            status: 'warning',
            title: 'Just One More Step',
            subtitle: 'Image or Video Required in Product Details',
            button: 'Close',
          }),
        );
        return;
      }
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <ProductHeader
          productName={productProfileState.payload.name}
          onNameChange={localDispatchAction(localActions.editName)}
          onBrandChange={(selectedBrand) => {
            localDispatch(localActions.editBrand(selectedBrand));

            if (productProfileState.payload.story.logoType === 'brand') {
              localDispatch(
                TellYourStoryActions.editLogo(
                  selectedBrand.details.launchScreen.display.mainLogo,
                ),
              );
            }
          }}
          productGtin={productProfileState.payload.gtin}
          onGtinChange={localDispatchAction(localActions.editGtin)}
          brandList={productProfileState.state.brandList}
          productNameError={productProfileState.validation.name}
          productBrandError={productProfileState.validation.brand}
          productGtinError={productProfileState.validation.gtin}
        />
      </Grid>

      {!isAmbioxera && !isMackasShowcase && (
        <Grid item style={{alignSelf: 'center', width: '60%'}}>
          <ProgressBar
            percent={productProfileState.state.progressBarPosition}
            steps={[
              {
                label: 'Tell Your Story',
                onClick: () =>
                  localDispatch({type: 'navigate', payload: 'story'}),
              },
              {
                label: 'Product Details',
                onClick: () =>
                  localDispatch({type: 'navigate', payload: 'pages'}),
              },
            ]}
          />
        </Grid>
      )}

      <Grid item container style={{marginTop: 50}}>
        {(() => {
          switch (productProfileState.state.section) {
            case 'story':
              return (
                <TellYourStory
                  tabValue={productProfileState.state.story.focusedTabIndex}
                  story={productProfileState.payload.story}
                  mainSectionImageOption={
                    productProfileState.state.story.mainSectionImageOption
                  }
                  hideSection={{
                    mainImage: isAmbioxera,
                    additionalSection: isAmbioxera,
                    keyMilestones: isAmbioxera,
                  }}
                  tabRename={
                    isAmbioxera ? {mainSection: 'tellYourStory'} : undefined
                  }
                  brandLogo={
                    productProfileState.payload.brand.agliveToken.length
                      ? findTargetBrand(
                          productProfileState.state.brandList,
                          productProfileState.payload.brand.agliveToken,
                        )?.details.launchScreen.display.mainLogo || null
                      : null
                  }
                  validationState={productProfileState.validation.story}
                  onTabChange={localDispatchAction(
                    TellYourStoryActions.changeTab,
                  )}
                  onMainSectionImageOptionChange={localDispatchAction(
                    localActions.editMainSectionImageOption,
                  )}
                  onLogoChange={localDispatchAction(
                    TellYourStoryActions.editLogo,
                  )}
                  onLogoTypeChange={localDispatchAction(
                    TellYourStoryActions.editLogoType,
                  )}
                  onMainSectionImageChange={localDispatchAction(
                    TellYourStoryActions.editImage,
                  )}
                  onHeadlineChange={localDispatchAction(
                    TellYourStoryActions.editHeadline,
                  )}
                  onContentChange={localDispatchAction(
                    TellYourStoryActions.editContent,
                  )}
                  onAddMilestonesEntry={localDispatchAction(
                    TellYourStoryActions.addMilestonesEntry,
                  )}
                  onDeleteMilestonesEntry={localDispatchAction(
                    TellYourStoryActions.deleteMilestonesEntry,
                  )}
                  onDupMilestonesEntry={localDispatchAction(
                    TellYourStoryActions.dupMilestonesEntry,
                  )}
                  onMilestonesTitleChange={localDispatchAction(
                    TellYourStoryActions.editMilestonesTitle,
                  )}
                  onMilestonesDetailsChange={localDispatchAction(
                    TellYourStoryActions.editMilestonesDetails,
                  )}
                  onAddAdditionalEntry={localDispatchAction(
                    TellYourStoryActions.addAdditionalEntry,
                  )}
                  onDeleteAdditionalEntry={localDispatchAction(
                    TellYourStoryActions.deleteAdditionalEntry,
                  )}
                  onDupAdditionalEntry={localDispatchAction(
                    TellYourStoryActions.dupAdditionalEntry,
                  )}
                  onAdditionalTitleChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalTitle,
                  )}
                  onAdditionalContentChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalContent,
                  )}
                  onAdditionalMediaTypeChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalMediaType,
                  )}
                  onAdditionalUrlChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalUrl,
                  )}
                />
              );
            case 'pages':
              return (
                <CreateProductProfileDetails
                  detailsArr={productProfileState.payload.pages}
                  validationState={productProfileState.validation.pages}
                  focusedPageIndex={
                    productProfileState.state.pages.focusedPageIndex
                  }
                  focusedTabIndex={
                    productProfileState.state.pages.focusedTabIndex
                  }
                  onPageAdd={() =>
                    localDispatch({
                      type: 'details/add_page',
                    })
                  }
                  onPageSelect={localDispatchAction(localActions.selectPage)}
                  onPageDuplicate={localDispatchAction(
                    localActions.duplicatePage,
                  )}
                  onPageDelete={localDispatchAction(localActions.deletePage)}
                  onPageMediaSelect={localDispatchAction(
                    localActions.selectPageMedia,
                  )}
                  onPageEdit={localDispatchAction(localActions.editPage)}
                  onTabSelect={localDispatchAction(localActions.selectTab)}
                  onContentAdd={localDispatchAction(
                    localActions.addContentSubheading,
                  )}
                  onContentDelete={localDispatchAction(
                    localActions.deleteContentSubheading,
                  )}
                  onContentDup={localDispatchAction(
                    localActions.dupContentSubheading,
                  )}
                  onAttachmentAdd={localDispatchAction(
                    localActions.addAttachment,
                  )}
                  onAttachmentDelete={localDispatchAction(
                    localActions.deleteAttachment,
                  )}
                />
              );
          }
        })()}
      </Grid>

      {!isAmbioxera && !isMackasShowcase && (
        <Grid
          item
          container
          justifyContent="space-between"
          style={{
            borderTop: `1px solid ${COLOR.GRAY_BORDER}`,
            marginTop: 40,
            paddingTop: 20,
            marginBottom: 75,
          }}>
          <Link
            component="button"
            variant="body2"
            underline="always"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility:
                productProfileState.state.section !== 'story'
                  ? 'visible'
                  : 'hidden',
            }}
            onClick={() => localDispatch({type: 'navigate/prev_step'})}>
            <ChevronLeftIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginRight: 10,
              }}
            />
            Previous Step
          </Link>

          <Link
            component="button"
            variant="body2"
            underline="always"
            style={{
              color: COLOR.GREEN_BUTTON,
              textDecoration: 'underline',
              visibility:
                productProfileState.state.section !== 'pages'
                  ? 'visible'
                  : 'hidden',
            }}
            onClick={() => localDispatch({ type: 'navigate/next_step' })}
            id="NextStep"
          >
            Next Step
            <ChevronRightIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginLeft: 10,
              }}
            />
          </Link>
        </Grid>
      )}

      <Grid item container justifyContent="flex-end">
        <MyButton
          text="Save and Preview"
          variant="contained"
          disabled={!canSubmit(productProfileState, isAmbioxera || isMackasShowcase, isAmbioxera || isMackasShowcase)}
          onClick={() => {
            localDispatch(localActions.validateForm);
            !isAmbioxera && !isMackasShowcase && checkProductDetailsMedia();
          }}
          id="SaveAndPreviewBtn"
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Create Product Profile',
    back: true,
  },
  CreateProductProfile,
);
