import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {toggleModal} from '../../store/modal/actions';
import {getBusinessProfile, getProfiles} from '../../store/profile/actions';
import {useHistory, useLocation} from 'react-router-dom';
import Dashboard from './Dashboard';
import ProductProfileLibrary from '../promotion/ProductProfileLibrary';
import TrackAnimal from '../map/TrackAnimal';
import BusinessProfile from '../profile/BusinessProfile';
import {
  linkInvitationCode,
  resetInvitationAction,
  validateInvitationCode,
} from 'src/store/contacts/action';
import {TokenService} from '@aglive/data-model';

type HomeProps = {};
type LocationParam = {
  fromAuth?: boolean;
  newSsoUser?: boolean;
  role?: string;
};
const Home: React.FC<HomeProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {state} = useLocation<LocationParam>();

  const userid = useAppSelector((state) => state.auth.wallet);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const plugins = useAppSelector((state) => state.user.plugins);
  const contacts = useAppSelector((state) => state.user.contacts);
  const userRole = useAppSelector((state) => state.user.userProfileData?.role);
  const isCeresTag = plugins?.find((plugin) => plugin.name === 'Ceres Tag');
  const isProducts = businessProfile.industryType === 'PRODUCTS';
  const isCannabis =
    businessProfile.industryType === 'PLANTS' &&
    businessProfile.subIndustryType === 'Cannabis';
  const isCanadaCerestag =
    isCeresTag && businessProfile.businessCountry === 'Canada';

  const invitationCode = useAppSelector(
    (state) => state.contact.invitationCode,
  );
  const inviteCategory = useAppSelector((state) => state.contact.category);
  const [inviteCodeResult, setInviteCodeResult] = useState<
    {isValid: boolean} | undefined
  >(undefined);

  useEffect(() => {
    if (!userid) {
      return;
    }
    async function fetchProfiles() {
      await dispatch(getProfiles(userid));
      try {
        if (!invitationCode) {
          setInviteCodeResult({isValid: false});
          return;
        }
        const isValid = await dispatch(
          validateInvitationCode({
            code: invitationCode,
            type: inviteCategory,
          }),
        );
        if (isValid) {
          await dispatch(linkInvitationCode({invitationCode}));
          await dispatch(getBusinessProfile());
        }
        setInviteCodeResult({isValid});
      } catch (error) {
        setInviteCodeResult({isValid: false});
      }
    }
    fetchProfiles();
  }, [dispatch, userid, invitationCode, inviteCategory]);

  // if isAngus, route to Angus Dashboard
  useEffect(() => {
    if (!inviteCodeResult) {
      return;
    }
    if (inviteCodeResult.isValid) {
      routeInvitation();
    } else {
      console.log('invitation not valid');
      routeProfiles({
        businessProfile: businessProfile,
        fromAuth: state?.fromAuth,
        newSsoUser: state?.newSsoUser,
        role: state?.role,
        userRole: userRole,
      });
    }
    dispatch(resetInvitationAction());

    function routeInvitation() {
      const contact = contacts.find((c) => c.invitationCode === invitationCode);
      console.log('contact', contact);
      if (!!contact) {
        history.push(`/private/add/contacts/view/${contact.id}`);
      } else {
        routeProfiles({
          businessProfile: businessProfile,
          fromAuth: state?.fromAuth,
          newSsoUser: state?.newSsoUser,
          role: state?.role,
          userRole: userRole,
        });
      }
    }

    function routeProfiles({
      fromAuth,
      newSsoUser,
      businessProfile,
      role,
      userRole,
    }: {
      fromAuth?: boolean;
      newSsoUser?: boolean;
      role?: string;
      businessProfile: TokenService.BusinessToken['details'];
      userRole: string;
    }) {
      // --- angus ---
      if (fromAuth && businessProfile.companyName) {
        if (
          businessProfile.industryType === 'ANIMALS' &&
          businessProfile.businessType === 'Angus'
        ) {
          console.log('to angus');
          history.push('/private/verified');
        } else {
          dispatch(
            toggleModal({
              status: 'success',
              title: 'Welcome',
              subtitle: [''],
              button: 'Continue',
              CTAHandler: () => {
                if (
                  businessProfile.industryType === 'ANIMALS' &&
                  businessProfile.businessType === 'Angus'
                ) {
                  history.push('/private/verified');
                } else {
                  //history.push('/private/register/asset');
                }
              },
            }),
          );
        }
      }

      // --- non angus ---
      if (fromAuth || userRole?.includes('establishment-') || userRole?.includes('location-')) {
        console.log('to non angus');
        if (
          newSsoUser ||
          role?.includes('establishment-') ||
          userRole?.includes('establishment-') ||
          role?.includes('location-') ||
          userRole?.includes('location-')
        ) {
          history.push('/private/profile/businessProfile');
          dispatch(
            toggleModal({
              status: 'success',
              title: 'Welcome',
              subtitle: [''],
              button: 'Continue',
            }),
          );
        } else {
          if (isCannabis) {
            history.push('/private/');
            dispatch(
              toggleModal({
                status: 'success',
                title: 'Welcome',
                subtitle: [''],
                button: 'Continue',
              }),
            );
          } else if (isProducts) {
            history.push('/private/products/productProfile');
            dispatch(
              toggleModal({
                status: 'success',
                title: 'Welcome',
                subtitle: [''],
                button: 'Continue',
              }),
            );
          } else if (isCanadaCerestag) {
            history.push('/private/profile/map');
            dispatch(
              toggleModal({
                status: 'success',
                title: 'Welcome',
                subtitle: [''],
                button: 'Continue',
              }),
            );
          }
        }
      }
    }
  }, [
    dispatch,
    userid,
    invitationCode,
    inviteCategory,
    history,
    isCannabis,
    isProducts,
    isCanadaCerestag,
    businessProfile,
    state?.fromAuth,
    state?.newSsoUser,
    state?.role,
    userRole,
    inviteCodeResult,
    contacts,
  ]);

  if (
    (state?.fromAuth &&
      (state?.newSsoUser ||
        state?.role?.includes('establishment-') ||
        state?.role?.includes('location-'))) ||
    userRole?.includes('establishment-') ||
    userRole?.includes('location-')
  ) {
    return <BusinessProfile />;
  } else if (state?.fromAuth) {
    if (isProducts) {
      return <ProductProfileLibrary />;
    } else if (isCanadaCerestag) {
      return <TrackAnimal />;
    } else {
      return <Dashboard />;
    }
  } else {
    return <Dashboard />;
  }
};

export default Home;
