import React, { useState } from 'react';
import { PageHeader } from '../../presentation/withHeader';
import {
  Grid,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import moment from 'moment';
import { assetStyles } from '../asset/Components';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { toggleModal, toggleModalOff } from '../../store/modal/actions';
import COLOR from '../../styled/colors';
import { Line } from '../../presentation/Line';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useLocation, useHistory } from 'react-router-dom';
import { Buttons } from '../../presentation/ButtonsGroup';
import { getAnimalProfile, updateMob, deleteAnimal } from '../../store/angus/actions';
import _ from 'lodash';
import MyButton from '../../presentation/button';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: "10px",
  },
  marginTop: {
    marginTop: 60,
  },
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
  },
  halfWidth: {
    width: window.innerWidth / 2,
  },
  cursor: {
    cursor: 'pointer'
  },
  tablePad: {paddingBottom: 16, paddingTop: 16},
  tableSpace: {padding:16, paddingLeft: 8, paddingRight: 8, borderBottom: 'none'},
  link: {
    color: COLOR.GREEN_BUTTON,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}));

const MobDetails: React.FC<{}> = (props) => {
  const classes = { ...assetStyles(), ...useStyles() };
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userId = useAppSelector(state => state.auth.wallet);
  const state: any = useLocation().state;
  const [details, setDetails] = useState(state.details.details);
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const isAnimalBusiness = businessProfile.industryType == "ANIMALS" 
  const mobDetails = [
    { name: 'PIC Number', value: 'Mob Name', bold: true},
    { name: details.pic, value: details.mob_name, underline: true },
    { name: 'Number of Animals', value: 'Sex', bold: true },
    { name: details.calves.add.length, value: details.mob_sex, underline: true },
    { name: 'Birth Year', value: details.sire_id ? 'Single Sire' : 'Sire Group', bold: true },
    {
      name: details.birth_year, value: details.sire_id ? details.sire_id : details.sire_group_name, underline: true
    },
    { name: 'Breeder PIC Number', value: 'Created On', bold: true },
    { name: details.breeder_pic_number?? details.breederPicAddress, value: details.created_on, underline: true },
  ];

  const mobBirthRange = [
    { name: 'Start Month', value: 'End Month', bold: true },
    { 
      name: moment(details.birth_start, 'YYYY-MM').format('MM-YYYY'), 
      value: moment(details.birth_end, 'YYYY-MM').format('MM-YYYY'), 
      underline: true 
    },
  ];

  const animalTagsHeader = [
    { name: 'Visual Tag', value: '' },
  ];

  const isAnimalinOtherMob = async (animal: any) : Promise<boolean> => {
    let other = false;
    await dispatch(getAnimalProfile({rfid: animal.rfid}))
    .then((resp) => {
      if(resp.length>0){
        if(resp[0].details.mobs?.length > 1){
          other = true;
        } else {
          other = false;
        }
      }
      return other;
    });
    return other;
  }
 

  const _deleteAnimal = (animal: any, otherMob: boolean) => {
    dispatch(toggleModalOff());

    const agliveToken = details.externalIds.filter(id => id.agliveToken)[0].agliveToken;
    const updateDetails = {
      angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
      acct_id: details.acct_id,
      isAngusMob: true,
      sire_id: details.sire_id,
      sire_group_name: details.sire_group_name,
      birth_year: details.birth_year,
      birth_start: details.birth_start,
      birth_end: details.birth_end,
      pic_number: details?.pic,
      mob_name: details.mob_name,
      calves: {
        add: [],
        remove: [animal]
      },
      nlis_id: animal.nlis_id,
      breederPicAddress: details?.breeder_pic_number??details?.breederPicAddress,
    };
    dispatch(updateMob(
      userId,
      agliveToken,
      updateDetails,
      businessProfile.location[0]?.country === 'Australia' ? businessProfile.location[0].NLISUserID : '',
      businessProfile.location[0]?.country === 'Australia' ? businessProfile.location[0].NLISPassword : '',
      businessProfile.users[0].email
    ));
    if(!otherMob){
      const angusAuthToken = isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined
      dispatch(deleteAnimal(userId,{ rfid: animal.rfid }, angusAuthToken, history))
    }
    let newDetails = details;
    let filteredAnimals = _.filter(details.calves.add, (calve: any) => calve !== animal);
    newDetails.calves.add = filteredAnimals;
    setDetails(newDetails);
    history.replace({
      pathname: `/private/verified/recordAnimals/mob-details`, 
      state: Object.assign(state, {details: Object.assign(state.details, {details: newDetails})})
    }); 
  }

  const createAnimalsTable = () => {
    let animalList = [];
    details.calves.add.sort((a, b) => a.visual_tag > b.visual_tag ? 1 : -1).forEach((animal, index) => {
      animalList.push({
        tag: animal.visual_tag || animal.rfid,
        nlisId: animal.nlis_id,
        rfid: animal.rfid,
        edit: 
          <EditIcon
            className={classes.cursor}
            id={`Edit${animal.visual_tag || animal.rfid}`}
            onClick={() => {
              history.push(`/private/verified/recordAnimals/edit-animal`, { 
                visualTag: animal.visual_tag, 
                rfid: animal.rfid, 
                nlis_id: animal.nlis_id
              });
            }}
          />,
        delete:
          <DeleteIcon
            className={classes.cursor}
            id={`Delete${animal.visual_tag || animal.rfid}`}
            onClick={async () => {
              await isAnimalinOtherMob(animal).then((otherMob: boolean) => {
                dispatch(
                  toggleModal({
                  status: 'warning',
                  title: otherMob ? 'Delete animal from mob?' : 'Delete animal permanently?',
                  subtitle: otherMob ? 'Animal will remain in other mobs' : 'Delete animal permanently?',   
                  renderButton: (
                    <Buttons
                      leftButtonTitle="No"
                      rightButtonTitle="Yes"
                      leftButtonOnClick={() => {
                        dispatch(toggleModalOff());
                      }}
                      rightButtonOnClick={() => {
                        dispatch(toggleModalOff());
                        _deleteAnimal(animal, otherMob);
                      }}
                    />
                  ),
                }),
              );})
            }}
          />
      });
    });
    return animalList;
  };
  const animalTagsBody = createAnimalsTable();

  const getRowStyle = (underline, bold) => {
    if (!underline) {
      return { fontWeight: bold ? 700 : 400, borderBottom: "none" };
    }
    return { fontWeight: bold ? 700 : 400, borderBottomColor: COLOR.GRAY_SOLID };
  }

  return (
    <PageHeader config={{ title: 'Mob Details', margin: 60, back: true , id:'MobDetails'}}>
      <Grid container xs={12} lg={6} md={8} sm={12}>
        <TableContainer>
          <Table padding='none'>
            <TableBody>
              {mobDetails.map((row, index) => (
                <TableRow key={`${row.name}_${index}`}>
                  <TableCell align="left" classes={{root: classes.tablePad}} style={getRowStyle(row.underline, row.bold)}>{row.name}</TableCell>
                  <TableCell classes={{root: classes.tableSpace}}>&nbsp;</TableCell>
                  <TableCell align="left" classes={{root: classes.tablePad}} style={getRowStyle(row.underline, row.bold)}>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Typography variant='h3' className={classes.marginTop}>Birth Range by Month</Typography>
      <Line />
      <Grid container xs={12} lg={6} md={8} sm={12}>
        <TableContainer>
          <Table padding='none'>
            <TableBody>
              {mobBirthRange.map((row, index) => (
                <TableRow key={`${row.name}_${index}`}>
                  <TableCell align="left" classes={{root: classes.tablePad}} style={getRowStyle(row.underline, row.bold)}>{row.name}</TableCell>
                  <TableCell classes={{root: classes.tableSpace}}>&nbsp;</TableCell>
                  <TableCell align="left" classes={{root: classes.tablePad}} style={getRowStyle(row.underline, row.bold)}>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>


      <Typography variant='h3' className={classes.marginTop}>Animals</Typography>
      <Line />
      <Grid container xs={12} lg={4} md={6} sm={12}>
        <TableContainer>
          <Table>
            <TableBody>
              {animalTagsHeader.map((cell) => (
                <TableRow key={cell.name} className={classes.greyBox}>
                  <TableCell align="left" style={{ fontWeight: 700 }}>{cell.name}</TableCell>
                  <TableCell align="left" style={{ fontWeight: 700 }}>{cell.value}</TableCell>
                </TableRow>
              ))}
              {animalTagsBody.map((row) => (
                <TableRow key={row.nlisId} >
                  <TableCell align="left">
                    <Link className={classes.link} id={row.tag} onClick={() => {
                        history.push(`/private/verified/animalProfile/view-animal`, { nlis: row.nlisId, rfid: row.rfid });
                      }}>{row.tag}</Link>
                  </TableCell>
                  <TableCell align="right">{row.edit} {row.delete}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container justify="flex-end">
        <MyButton
          buttonClass={classes.saveButton}
          text={'Add Animal'}
          variant="contained"
          onClick={() => {
            history.push(`/private/verified/recordAnimals/mob-details/add-animal`, { details: details });
          }}
        />
      </Grid>
    </PageHeader >
  );
};

export default MobDetails;
