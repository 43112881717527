import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import COLOR from 'src/styled/colors';
import SearchBar from 'src/presentation/SearchBar';
import MyButton from 'src/presentation/button';
import MyTable from 'src/presentation/Table';
import EditIcon from '@material-ui/icons/Edit';
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from 'src/utils/hooks';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {PageHeader} from 'src/presentation/withHeader';
import CONSTANT from 'src/config/constant';
import {
  approveInvitation,
  bulkApproveInvitation,
} from 'src/store/contacts/action';
import {CASL} from '@aglive/frontend-core';
import moment from 'moment';
import {ContactFilterStatus, ContactInfo} from './types';
import {
  getBusinessNameDisplay,
  getContactEmailDisplay,
  getContactNameDisplay,
  getStatusDisplay,
} from './utils';
import {TokenService} from '@aglive/data-model';
import {getBusinessProfile} from 'src/store/profile/actions';
import {toggleModal, toggleModalOff} from 'src/store/modal/actions';
import {DialogContentSubtitle} from 'src/presentation/DialogContentSubtitle';
import {Buttons} from 'src/presentation/ButtonsGroup';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  filterRow: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: 8,
    marginBottom: 30,
    alignItems: 'center',
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 210,
    maxHeight: 56,
    marginRight: 20,
  },
  editIconContainer: {
    marginRight: 20,
    padding: 2,
  },
  editIcon: {
    cursor: 'pointer',
    color: COLOR.BLACK,
  },
  editIconGrey: {
    color: COLOR.GRAY_BORDER,
  },
  hyperlink: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: 0,
    alignItems: 'flex-start',
  },
  contactLink: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    padding: 0,
    justifyContent: 'flex-start',
  },
  contactLinkLabel: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'capitalize',
    cursor: 'pointer',
    width: 'max-content',
  },
  linkText: {
    color: COLOR.GREENT_TEXT,
    textDecoration: 'underline',
    textTransform: 'none',
  },
  textLeft: {
    textAlign: 'left',
  },
  filterLabel: {fontWeight: 600, marginRight: 20},
}));

const filterDataInitialState = {
  status: '' as ContactFilterStatus,
  query: '',
  viewPendingRequests: false,
};

type ContactFilter = keyof typeof filterDataInitialState;
type ContactFilterData = typeof filterDataInitialState;

type ContactStatusOption = {
  label: string;
  value: ContactInfo['status'] | ContactInfo['type'];
};

const ContactLibrary = () => {
  const {path} = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  useEffect(() => {
    dispatch(getBusinessProfile());
  }, [dispatch]);

  const labels = useMemo(() => {
    return businessProfile.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  }, [businessProfile]);
  const statusList = useMemo(
    () =>
      [
        {label: 'All', value: ''},
        {label: labels.active, value: 'active'},
        {label: labels.pending, value: 'pending'},
        {label: labels.pendingApprove, value: 'incoming'},
      ] as Array<ContactStatusOption>,
    [labels],
  );
  const ability = CASL.useAbility(CASL.AbilityContext);

  const hasEditPermission = ability.can('update', 'contact');

  const headers = useMemo(() => {
    let result = [
      labels.name,
      'Business Name',
      labels.emailAddress,
      'Invited On',
      'Joined On',
      labels.status,
    ];
    if (hasEditPermission) {
      result.push(labels.action);
    }
    return result;
  }, [labels, hasEditPermission]);

  const classes = useStyles();

  const [filterData, setFilterData] = useState(filterDataInitialState);

  const changeFilterData = useCallback(
    <TKey extends ContactFilter>(key: TKey, val: ContactFilterData[TKey]) => {
      setFilterData((prev) => ({...prev, [key]: val}));
    },
    [],
  );
  const contacts: TokenService.BusinessToken['contacts'] = useAppSelector(
    (state) => state.user.contacts,
  );

  const filteredContacts = useMemo(() => {
    console.log('contacts', contacts);
    if (!contacts) {
      return [];
    }
    if (filterData.viewPendingRequests || filterData.status === 'incoming') {
      return contacts.filter(
        (c) => c.type === 'incoming' && c.status === 'pending',
      );
    }
    return contacts.filter(
      (contact) =>
        isMatchingUser(contact, filterData.query) &&
        isMatchingStatus(contact, filterData.status) &&
        contact.status !== 'obsolete',
    );
  }, [
    contacts,
    filterData.status,
    filterData.query,
    filterData.viewPendingRequests,
  ]);

  const handleApproveInvitation = (contactIds: Array<string>) => {
    if (contactIds.length === 0) {
      return;
    }
    function onSuccess() {
      dispatch(getBusinessProfile());
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Approved',
        }),
      );
      setFilterData({...filterDataInitialState});
    }
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Confirm Approve?',
        customContent: (
          <DialogContentSubtitle
            texts={[
              'You are joining the',
              'requested company',
              'as their contact',
              '\n',
              `${labels.continue}?`,
            ]}
          />
        ),
        renderButton: (
          <Buttons
            leftButtonTitle={labels.no}
            rightButtonTitle={labels.yes}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              if (contacts.length > 1) {
                dispatch(
                  bulkApproveInvitation(
                    contactIds.map((id) => ({contactId: id})),
                    onSuccess,
                  ),
                );
              } else {
                dispatch(
                  approveInvitation(
                    {
                      contactId: contactIds[0],
                    },
                    onSuccess,
                  ),
                );
              }
            }}
          />
        ),
      }),
    );
  };

  return (
    <PageHeader
      config={{title: labels.contactLibrary, margin: 60, back: false}}>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={filterData.query}
            setQuery={(v) => {
              changeFilterData('query', v);
            }}
            label={labels.search}
          />
        </Grid>
        {hasEditPermission && (
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={labels.createNew}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() => {
                history.push(`${path}/new`);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        component={Box}
        display={'flex'}
        flexDirection={'row'}>
        <Grid
          item
          className={classes.filterRow}
          component={Box}
          minWidth={'max-content'}
          flex={1}>
          <Typography variant="h6" role="label" className={classes.filterLabel}>
            {labels.filters}
          </Typography>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}>
            {filterData.status.length === 0 && (
              <InputLabel id="contact-library-select-status" shrink={false}>
                {`---${labels.status}---`}
              </InputLabel>
            )}
            <Select
              labelId="contact-library-select-status"
              value={filterData.status}
              onChange={(e) =>
                changeFilterData('status', e.target.value as any)
              }>
              {statusList.map((status, statusIndex) => (
                <MenuItem value={status.value} key={statusIndex}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          component={Box}
          alignSelf={'center'}
          marginLeft={'auto'}
          marginRight={'auto'}
          flex={1}>
          <FormControlLabel
            aria-label="viewPendingRequests"
            control={
              <Checkbox
                color="primary"
                checked={filterData.viewPendingRequests}
                aria-checked={filterData.viewPendingRequests}
                onChange={(e) => {
                  setFilterData({
                    ...filterDataInitialState,
                    viewPendingRequests: e.target.checked,
                  });
                }}
              />
            }
            label="View all pending contact requests"
          />
        </Grid>
        {filterData.viewPendingRequests && hasEditPermission && (
          <Grid item component={Box} alignSelf={'center'} marginLeft={'auto'}>
            <Button
              className={classes.hyperlink}
              aria-labelledby="approveContact"
              onClick={() =>
                handleApproveInvitation(filteredContacts.map((c) => c.id))
              }>
              <Typography
                variant="inherit"
                display="block"
                className={classes.linkText}>
                Approve All
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <MyTable
        heads={headers}
        rows={filteredContacts.map((contact) => {
          const hasIncomingDuplicate =
            contact.status === 'pending' &&
            contact.type === 'outgoing' &&
            !!contacts.find((c) => {
              return (
                c.status === 'active' &&
                c.type === 'incoming' &&
                c.requestorBusinessId === contact.businessId
              );
            });
          return [
            <div>
              {contact.type === 'incoming' && (
                <Typography
                  component={'label'}
                  htmlFor={contact.id}
                  className={classes.contactLinkLabel}
                  variant="inherit"
                  display="block">
                  From:
                </Typography>
              )}
              <Button
                className={classes.contactLink}
                aria-label="contactName"
                id={contact.id}
                onClick={() => {
                  history.push({
                    pathname: `${path}/view/${contact.id}`,
                  });
                }}>
                <Typography
                  variant="inherit"
                  display="block"
                  className={`${classes.linkText}`}>
                  {contact.invitedThrough === 'email' &&
                  contact.status === 'pending' &&
                  contact.type === 'outgoing' &&
                  !contact.name
                    ? contact.email
                    : getContactNameDisplay(contact)}
                </Typography>
              </Button>
            </div>,
            <Typography variant="inherit" aria-label="business name">
              {getBusinessNameDisplay(contact) || '-'}
            </Typography>,
            <Typography variant="inherit" aria-label="email">
              {getContactEmailDisplay(contact) || '-'}
            </Typography>,
            <Typography variant="inherit" aria-label="invited at">
              {moment(contact.invitedAt).format('DD/MM/YYYY')}
            </Typography>,
            <Typography variant="inherit" aria-label="joined on">
              {contact.status === 'active'
                ? moment(contact.joinedOn).format('DD/MM/YYYY')
                : '-'}
            </Typography>,
            <Typography variant="inherit" aria-label="status">
              {getStatusDisplay(contact)}
            </Typography>,
          ].concat(
            hasEditPermission
              ? [
                  contact.type === 'incoming' &&
                  contact.status === 'pending' ? (
                    <Button
                      aria-label="approve contact"
                      className={classes.hyperlink}
                      id={contact.id}
                      onClick={() => handleApproveInvitation([contact.id])}>
                      <Typography
                        variant="inherit"
                        display="block"
                        className={classes.linkText}>
                        Approve
                      </Typography>
                    </Button>
                  ) : (
                    <IconButton
                      disabled={hasIncomingDuplicate}
                      className={classes.editIconContainer}
                      aria-label="edit"
                      onClick={() => {
                        history.push({
                          pathname: `${path}/edit/${contact.id}`,
                        });
                      }}>
                      <EditIcon
                        className={
                          hasIncomingDuplicate
                            ? classes.editIconGrey
                            : classes.editIcon
                        }
                        id={`Edit${contact.id}`}
                      />
                    </IconButton>
                  ),
                ]
              : [],
          );
        })}
      />
    </PageHeader>
  );
};

function isMatchingUser(contact: ContactInfo, query: string): boolean {
  const businessName = getBusinessNameDisplay(contact);
  const name = getContactNameDisplay(contact);
  return (
    businessName.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
    name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  );
}

function isMatchingStatus(contact: ContactInfo, status: string) {
  if (status.length === 0) {
    return true;
  }
  if (contact.status === 'pending' && contact.type === 'incoming') {
    return contact.type === status;
  }
  return contact.status === status;
}

export default ContactLibrary;
