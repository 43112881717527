import {CropReportType} from './types';
import moment from 'moment';

export function getCropReportPurpose(type: CropReportType) {
  const purpose = type === 'medicinal' ? 'production' : type;
  return purpose;
}

export function getYearsFromNow(amount: number) {
  const now = new Date();
  const year = now.getFullYear();
  const startYear = year - amount + 1;
  let result = [];
  for (let i = 0; i < amount; i++) {
    result.push(startYear + i);
  }
  return result;
}

const PERMIT_DATE_FORMAT = 'DD/MM/yyyy';
export function formatPermitDate(timestamp: string) {
  return moment(timestamp).format(PERMIT_DATE_FORMAT);
}

export function isPermitExpired(date: string) {
  const expiryDate = moment(date, PERMIT_DATE_FORMAT).startOf('day');
  const today = moment().startOf('day');
  return today > expiryDate;
}
