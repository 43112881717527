import produce from 'immer';
import {
  ContactInvitationState,
  ContactInvitationActions,
  RESET_INVATITION,
  SET_INVITATION,
} from './types';

const initialState: ContactInvitationState = {
  invitationCode: '',
  category: '',
};

export default function contactInvitationReducer(
  state = initialState,
  action: ContactInvitationActions,
): ContactInvitationState {
  switch (action.type) {
    case SET_INVITATION:
      return action.payload;
    case RESET_INVATITION:
      return {
        invitationCode: '',
        category: '',
      };
    default:
      return state;
  }
}
