import React, {useState, useEffect, useMemo} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import {useHistory} from 'react-router';
import {getBusinessProfile, getUserProfile} from '../../store/profile/actions';
import {
  Grid,
  makeStyles,
  Typography,
  ListItem,
  Collapse,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import COLOR from '../../styled/colors';
import MyButton from '../../presentation/button';
import {businessProfileObject} from '@aglive/frontend-core';
import {PageHeader} from '../../presentation/withHeader';
import CONSTANT from '../../config/constant';
import {BusinessMisc} from '@aglive/data-model';
import {CASL} from '@aglive/frontend-core';
import LocationUsers from './LocationUsers';
import LicensesView from './viewBusiness/LicensesView';
import PoisonPermitsView from './viewBusiness/PoisonPermitView';
import {
  GmpLicense,
  OdcLicense,
  PoisonPermit,
} from '@aglive/data-model/dist/misc/business';
import {FormGenerator} from '../../presentation/FormGenerator';
import {businessInputs} from './EditBusinessProfile';
import useSiteOptions from '../site/useSiteOptions';

const useStyle = makeStyles(() => ({
  imageStyle: {
    width: '100%',
    height: '100%',
    maxWidth: 312,
    maxHeight: 212,
  },
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
}));

const BusinessProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userId = useAppSelector((state) => state.auth.wallet);
  const ability = CASL.useAbility(CASL.AbilityContext);
  const [businessTitles, setBusinessTitles] = useState(CONSTANT.EN_LABELS);

  const businessProfileDoc = useAppSelector(
    (state) =>
      state.user.businessProfileData as
        | BusinessMisc.AnimalBusinessInterface
        | BusinessMisc.PlantBusinessInterface,
  );
  const businessPlugins = useAppSelector((state) => state.user.plugins);
  const isAmbioxera =
    businessPlugins?.filter(
      (br) =>
        br.name.toLowerCase() === 'ambioxera' && br.status === 'activated',
    ).length > 0;
  const businessApiKeys = useAppSelector((state) => state.user.apiKeys);
  const placeType = isAmbioxera ? 'Establishment' : 'Location';

  const isWarakirri =
    businessPlugins?.filter(
      (br) =>
        br.name.toLowerCase() === 'dairy reports' && br.status === 'activated',
    ).length > 0;

  const {regions, propertyTypes} = useSiteOptions();

  let optiweighData = {
    interval: '',
    apiKey: '',
    clientId: '',
    agliveKey: '',
  };
  if (businessProfileDoc.industryType === 'ANIMALS') {
    optiweighData = businessProfileDoc.optiweigh;
  }

  const businessProfile = useMemo(
    () => businessProfileObject(businessProfileDoc),
    [businessProfileDoc],
  );
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const EDIT_BUSINESS_PROFILE = '/private/profile/businessProfile/edit';

  const initialCollapseState = {
    location: false,
    LPA: false,
    NLIS: false,
  };

  const locationArrObj = [];

  // multiple location
  const [collapse, setCollapse] = useState(
    locationArrObj as Array<typeof initialCollapseState>,
  );
  // multiple location
  const handleCollapse =
    (collapseName: 'location' | 'NLIS' | 'LPA', index: number) => (e) => {
      let updateState = [...collapse];
      updateState[index] = {
        ...updateState[index],
        [collapseName]: !updateState[index][collapseName],
      };
      setCollapse(updateState);
    };
  const odcLicenses =
    businessProfileDoc.industryType === 'PLANTS'
      ? (businessProfileDoc.licenses?.filter(
          (license) => license.name === 'ODC',
        ) as OdcLicense[]) ?? []
      : [];

  const gmpLicenses =
    businessProfileDoc.industryType === 'PLANTS' &&
    businessProfileDoc.subIndustryType === 'Cannabis'
      ? (businessProfileDoc.licenses?.filter(
          (license) => license.name === 'GMP',
        ) as GmpLicense[]) ?? []
      : [];

  const poisonPermits =
    businessProfileDoc.industryType === 'PLANTS' &&
    businessProfileDoc.subIndustryType === 'Cannabis'
      ? (businessProfileDoc.licenses?.filter(
          (license) => license.name === 'Poison',
        ) as PoisonPermit[]) ?? []
      : [];

  const odcPermits = useMemo(() => {
    let permitList: {[key: string]: string} = {};
    if (businessProfileDoc['licenses']?.length) {
      businessProfileDoc['licenses'].forEach((l: OdcLicense) => {
        if (l.name === 'ODC' && l.permits?.length) {
          l.permits.forEach((lp) => {
            permitList[lp.permitId] = lp.permitNumber;
          });
        }
      });
    }
    return permitList;
  }, [businessProfileDoc]);
  const classes = useStyle();
  useEffect(() => {
    for (let i = 0; i <= businessProfile.numLocations(); i++) {
      locationArrObj.push(initialCollapseState);
    }
    setCollapse(locationArrObj);
  }, [businessProfile.numLocations()]);
  useEffect(() => {
    dispatch(getUserProfile(userId));
  }, []);
  useEffect(() => {
    if (userProfile.businessId) {
      dispatch(getBusinessProfile());
    }
  }, [userProfile?.businessId]);
  useEffect(() => {
    switch (businessProfileDoc.businessCountry) {
      case 'Argentina':
        setBusinessTitles((pre) => ({...pre, ...CONSTANT.ES_LABELS}));
        break;
      case 'Australia':
      case 'Canada':
      default:
        setBusinessTitles((pre) => ({...pre, ...CONSTANT.EN_LABELS}));
        break;
    }
  }, [businessProfileDoc.businessCountry]);

  return (
    <PageHeader
      config={{
        title: businessTitles.viewBusinessProfile,
        margin: 60,
        back: false,
      }}>
      <Grid container direction="column">
        {/* <Grid container justifyContent="center" alignItems="center">
        <img src={defaultImage} className={classes.imageStyle} alt="" />
      </Grid> */}
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessTitles.companyName}
            </Typography>
            <Typography variant="h6" style={{marginTop: 8}} id="CompanyName">
              {businessProfileDoc.companyName}
            </Typography>
          </Grid>
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessTitles.companyNumber}
            </Typography>
            <Typography variant="h6" style={{marginTop: 8}} id="CompanyNumber">
              {businessProfileDoc.companyNumber}
            </Typography>
          </Grid>

          {/* Hide country field for non Livestock user */}
          {businessProfile.isLivestock() && (
            <Grid
              item
              container
              xs={6}
              direction="column"
              style={{marginTop: 15}}>
              <Typography variant="h6" style={{fontWeight: 600}}>
                {businessTitles.country}
              </Typography>
              <Typography
                variant="h6"
                style={{marginTop: 8}}
                id="CompanyCountry">
                {businessProfileDoc.businessCountry ?? 'Australia'}
              </Typography>
            </Grid>
          )}
          {businessProfile.isArgentina() && (
            <Grid
              item
              container
              xs={6}
              direction="column"
              style={{marginTop: 15}}>
              <Typography variant="h6" style={{fontWeight: 600}}>
                {businessTitles.CUIG}
              </Typography>
              <Typography variant="h6" style={{marginTop: 8}}>
                {businessProfileDoc.cuig}
              </Typography>
            </Grid>
          )}
        </Grid>

        {businessProfileDoc.industryType === 'PLANTS' && (
          <>
            <LicensesView
              addPermitButton={userProfile.role === 'admin'}
              licenses={odcLicenses}
              labels={{
                license: businessTitles.odcLicense,
                licenseNumber: businessTitles.odcLicenseNumber,
                licenseStartDate: businessTitles.odcLicenseStartDate,
                licenseExpiryDate: businessTitles.odcLicenseExpiryDate,
                permits: businessTitles.odcPermits,
                permitNumber: businessTitles.permitNumber,
                permitStarDate: businessTitles.startDate,
                permitExpiryDate: businessTitles.expiryDate,
                permitPlantLimit: businessTitles.plantsLimit,
                addPermit: businessTitles.addOdcPermit,
                thcValue: businessTitles.thcValue,
              }}
            />
            {businessProfileDoc.subIndustryType === 'Cannabis' ? (
              <>
                <LicensesView
                  licenses={gmpLicenses}
                  addPermitButton={userProfile.role === 'admin'}
                  labels={{
                    license: businessTitles.gmpLicense,
                    licenseNumber: businessTitles.gmpLicenseNumber,
                    licenseStartDate: businessTitles.gmpLicenseStartDate,
                    licenseExpiryDate: businessTitles.gmpLicenseExpiryDate,
                    permits: businessTitles.gmpCertificates,
                    permitNumber: businessTitles.gmpCertificateNumber,
                    permitStarDate: businessTitles.startDate,
                    permitExpiryDate: businessTitles.expiryDate,
                    addPermit: businessTitles.addGmpCertificate,
                  }}
                />
                <PoisonPermitsView
                  permits={poisonPermits}
                  labels={{
                    poisonPermit: businessTitles.poisonPermit,
                    poisonPermitNumber: businessTitles.poisonPermitNumber,
                    poisonPermitStartDate: businessTitles.poisonPermitStartDate,
                    poisonPermitExpiryDate:
                      businessTitles.poisonPermitExpiryDate,
                  }}
                />
              </>
            ) : null}
          </>
        )}

        <Grid container item>
          <Typography variant="h2" role="label" style={{marginTop: 80}}>
            {businessTitles[`my${placeType}`]}{' '}
            {businessProfile.isLivestock() &&
              !businessProfile.isArgentina() && <>e.g. PIC/PID</>}
          </Typography>
        </Grid>
        {businessProfileDoc.location
          ?.filter(
            (l) =>
              !(isAmbioxera || isWarakirri) ||
              ['admin', 'user'].includes(userProfile.role) ||
              userProfile.role.includes(l.locationUniqueId),
          )
          .map((location, index) => (
            <Grid item xs={12} key={'location' + index}>
              <div className={classes.titleContainer}>
                <ListItem
                  id={`LocationCollapse${index}`}
                  style={{paddingLeft: 0}}
                  button
                  onClick={handleCollapse('location', index)}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="h3" role="label">
                      {location?.locationNickname}
                    </Typography>
                    {collapse[index]?.location ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </Grid>
                </ListItem>
              </div>
              <Collapse in={collapse[index]?.location}>
                <Grid container spacing={3} style={{marginTop: 24}}>
                  <FormGenerator
                    language={businessProfile.isArgentina() ? 'es' : 'en'}
                    formInputs={[
                      businessInputs(
                        `${placeType.toLowerCase()}Nickname`,
                        location.locationNickname,
                        true,
                        !businessProfile.isArgentina(),
                      ),
                      businessInputs(
                        businessProfile.isArgentina()
                          ? 'RENSPA'
                          : businessProfile.isLivestock()
                          ? businessProfile.isCountry('USA')
                            ? 'locationId'
                            : 'PICAddress'
                          : `${placeType.toLowerCase()}Id`,
                        location.locationId || location.PICAddress,
                        true,
                        businessProfile.isCountry('USA') &&
                          businessProfile.isLivestock(),
                        businessProfile.isIndustry('PLANTS'),
                      ),
                      businessInputs(
                        'linkedPermit',
                        location['permits']
                          ? odcPermits[
                              location['permits'][
                                location['permits'].length - 1
                              ]
                            ]
                          : '',
                        true,
                        true,
                        !businessProfile.isIndustry('PLANTS'),
                      ),
                      businessInputs(
                        'country',
                        location.country,
                        true,
                        businessProfile.isLivestock(),
                        isAmbioxera,
                      ),
                      businessInputs(
                        'state',
                        location.state,
                        true,
                        businessProfile.isLivestock(),
                        isAmbioxera,
                      ),
                      businessInputs(
                        'town',
                        location.town,
                        true,
                        businessProfile.isLivestock(),
                        isAmbioxera,
                      ),
                      businessInputs(
                        'postcode',
                        location.postcode,
                        true,
                        businessProfile.isLivestock(),
                        isAmbioxera,
                      ),
                      businessInputs(
                        'region',
                        location['region'],
                        true,
                        !isWarakirri,
                        !isWarakirri,
                        undefined,
                        regions.map((r) => ({label: r, value: r})),
                      ),
                      businessInputs(
                        'property',
                        location['property_type'],
                        true,
                        !isWarakirri,
                        !isWarakirri,
                        undefined,
                        propertyTypes.map((p) => ({label: p, value: p})),
                      ),
                      businessInputs(
                        'address',
                        location.address,
                        true,
                        businessProfile.isLivestock(),
                        isAmbioxera,
                      ),
                      {
                        type: 'DIVIDER',
                        skip: !businessProfile.isLivestock() && !isAmbioxera,
                        name: 'divider',
                      },
                      businessInputs(
                        'NLISUserID',
                        location['NLISUserID'] &&
                          (isAmbioxera || userProfile.role !== 'admin') &&
                          !userProfile.role.startsWith('establishment-')
                          ? Array(location['NLISUserID'].length)
                              .fill('•')
                              .join('')
                          : location['NLISUserID'],
                        true,
                        false,
                        (!businessProfile.isLivestock() && !isAmbioxera) ||
                          location.country !== 'Australia',
                      ),
                      businessInputs(
                        'NLISUserPassword',
                        location['NLISPassword'] &&
                          (isAmbioxera || userProfile.role !== 'admin')
                          ? Array(location['NLISPassword']?.length ?? 0)
                              .fill('•')
                              .join('')
                          : location['NLISPassword'],
                        true,
                        false,
                        (!businessProfile.isLivestock() && !isAmbioxera) ||
                          location.country !== 'Australia',
                      ),
                      businessInputs(
                        'LPAUserID',
                        userProfile.role === 'admin'
                          ? location['LPAUserID']
                          : Array(location['LPAUserID']?.length)
                              .fill('•')
                              .join(''),
                        true,
                        false,
                        !businessProfile.isLivestock() ||
                          location.country !== 'Australia',
                      ),
                      businessInputs(
                        'LPAPassword',
                        userProfile.role === 'admin'
                          ? location['LPAPassword']
                          : Array(location['LPAPassword']?.length)
                              .fill('•')
                              .join(''),
                        true,
                        false,
                        !businessProfile.isLivestock() ||
                          location.country !== 'Australia',
                      ),
                      businessInputs(
                        'locationId',
                        location.locationId,
                        true,
                        false,
                        !businessProfile.isIndustry('PLANTS'),
                      ),
                    ]}
                  />
                  {isAmbioxera && businessApiKeys?.length && (
                    <Grid container item>
                      {businessApiKeys
                        .filter(
                          (bak) =>
                            bak.locationUniqueId === location.locationUniqueId,
                        )
                        .map((bak) => (
                          <Grid item container xs={12}>
                            <Typography variant="h6" style={{fontWeight: 600}}>
                              API Key
                            </Typography>
                            <Typography
                              variant="h6"
                              style={{wordBreak: 'break-all'}}>
                              {bak.key}
                            </Typography>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                  {(isAmbioxera || isWarakirri) && (
                    <Grid item container>
                      <LocationUsers
                        businessLanguage={businessTitles}
                        users={businessProfileDoc.users}
                        rolePrefix={placeType.toLowerCase()}
                        locationId={location.locationUniqueId}
                        businessId={userProfile.businessId}
                        editable={false}
                        currentUser={userId}
                      />
                    </Grid>
                  )}
                </Grid>
              </Collapse>
            </Grid>
          ))}
        {businessProfile.isLivestock() && (
          <>
            <Grid container item>
              <Typography variant="h2" role="label" style={{marginTop: 80}}>
                {businessTitles.CattleIdentification}
              </Typography>
            </Grid>
            <Grid container style={{marginTop: 48}}>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessTitles.brandDescription}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}} id="Brand">
                  {businessProfileDoc.brandDescription}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessTitles.cattleBrandSymbol}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  <img
                    style={{maxWidth: 223, maxHeight: 110}}
                    src={businessProfileDoc.imageUri}
                    alt=""
                  />
                </Typography>
              </Grid>
            </Grid>
            {businessProfile.isOptiweigh() && (
              <>
                <Grid container item>
                  <Typography variant="h2" role="label" style={{marginTop: 80}}>
                    Optiweigh Settings
                  </Typography>
                </Grid>
                <Grid container style={{marginTop: 48}}>
                  <Grid item container>
                    <Grid item container xs={4} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Client ID
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{marginTop: 8}}
                        id="ClientID">
                        {optiweighData.clientId}
                      </Typography>
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        API Key
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{marginTop: 8, wordBreak: 'break-all'}}
                        id="APIKey">
                        {optiweighData.apiKey}
                      </Typography>
                    </Grid>
                    <Grid item container xs={4} direction="column">
                      <Typography
                        variant="h6"
                        style={{fontWeight: 600, marginTop: 32}}>
                        Interval
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{marginTop: 8}}
                        id="Interval">
                        {optiweighData.interval
                          ? `Every ${optiweighData.interval}`
                          : ''}
                      </Typography>
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography
                        variant="h6"
                        style={{fontWeight: 600, marginTop: 32}}>
                        Aglive API Key
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{marginTop: 8, wordBreak: 'break-all'}}
                        id="AgliveAPIKey">
                        {optiweighData.agliveKey}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        {(userProfile?.role === 'admin' || userProfile?.role === 'user') && 
        <CASL.Can I="update" a="business">
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{marginTop: 200}}>
            <MyButton
              id="edit-btn"
              disabled={userProfile?.role === 'admin' ? false : true}
              text={businessTitles.edit}
              variant="contained"
              onClick={() => history.push(EDIT_BUSINESS_PROFILE)}
            />
          </Grid>
        </CASL.Can>}
        {(isAmbioxera || isWarakirri) && (userProfile?.role.startsWith('establishment-') || userProfile?.role.startsWith('location-')) && (ability.can('update', 'establishment') || ability.can('update','location')) && (
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{marginTop: 200}}>
            <MyButton
              text={businessTitles.edit}
              variant="contained"
              onClick={() =>
                history.push(`${EDIT_BUSINESS_PROFILE}Establishment`)
              }
            />
          </Grid>
        )}
      </Grid>
    </PageHeader>
  );
};

export default BusinessProfile;
