import { GmpLicense, GmpPermit, licenseType, OdcLicense, OdcPermit, PoisonPermit } from "@aglive/data-model/dist/misc/business";
import moment from "moment";

export const PERMITS_TEMPLATE = {
  permitNumber: '',
  permitExpiryDate: '',
};

export const INITIAL_PERMIT_VALIDATION_STATE = {
  maxNumber: '',
  permitNumber: '',
  permitStartDate: '',
  permitExpiryDate: '',
};

export type PermitValidationState = typeof INITIAL_PERMIT_VALIDATION_STATE;

export const INITIAL_PERMIT_STATE: OdcPermit | GmpPermit = {
  permitId: '',
  permitNumber: '',
  startDate: '',
  expiryDate: '',
  maxNumber: undefined,
};

export const INITIAL_LICENSE_VALIDATION_STATE = {
  licenseNumber: '',
  startDate: '',
  expiryDate: '',
};

export type LicenseValidationState = typeof INITIAL_LICENSE_VALIDATION_STATE;

const INITIAL_LICENSE_STATE = {
  name: '',
  licenseId: '',
  licenseNumber: '',
  startDate: '',
  expiryDate: '',
};

export const getInitialPermitState = (type: licenseType): OdcPermit | GmpPermit => {
  const today = moment().format('yyyy-MM-DD');
  if (type === 'Poison' || type === 'GMP') {
    return { ...INITIAL_PERMIT_STATE, startDate: today, expiryDate: today };
  }
  if (type === 'ODC') {
    return { ...INITIAL_PERMIT_STATE, startDate: today, expiryDate: today, moreThanDefaultThc: true, lessThanDefaultThc: false};
  }
};

export function newEmptyLicense(licenseType: licenseType): OdcLicense | GmpLicense | PoisonPermit {
  const today = moment().format('yyyy-MM-DD');
  if (licenseType === 'ODC' || licenseType === 'GMP') {
    return {
      ...INITIAL_LICENSE_STATE,
      name: licenseType,
      startDate: today,
      expiryDate: today,
      permits: []
    };
  }
  else if (licenseType === 'Poison') {
    return {
      ...getInitialPermitState('Poison'),
      name: 'Poison'
    };
  }
  return undefined;
}

export function newEmptyLicenseValidation(licenseType: licenseType) {
  if (licenseType === 'ODC' || licenseType === 'GMP') {
    const { name, licenseId, ...rest } = INITIAL_LICENSE_STATE;
    return {
      ...rest,
      permits: []
    };
  }
  else if (licenseType === 'Poison') {
    return {
      ...INITIAL_PERMIT_VALIDATION_STATE,
      name: 'Poison'
    };
  }
  return undefined;
}