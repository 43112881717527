import React, {useState} from 'react';

import moment from 'moment';
import {Box, Grid, Typography} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import PosterPreview from '../PosterPreview';
import {useStyles} from './styles';
import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import LabelData from '../../../presentation/LabelData';

enum TabMap {
  display,
}
const TabLabels = Object.values(TabMap).slice(0, 1);

interface DisplayPosterProps {
  display: TokenService.PromotionToken['details']['display'];
  story: TokenService.ProductToken['details']['story'];
  footerImageOption: 'image' | '';
  agliveToken: string;
  isAngus?: boolean;
  brandLogo: string | null;
}

const ViewDisplayPoster: React.FC<DisplayPosterProps> = ({
  display,
  story,
  footerImageOption,
  agliveToken,
  isAngus,
  brandLogo,
}) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(TabMap.display);

  return (
    <>
      <Grid item container xs={12} lg={5} justifyContent="center">
        <PosterPreview
          display={display}
          story={story}
          footerImageOption={footerImageOption}
          agliveToken={agliveToken}
          border={true}
          angusVerified={isAngus}
          scale={0.9}
          brandLogo={brandLogo}
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <Tabs
          tabHeaders={TabLabels as string[]}
          value={tabValue}
          setValue={setTabValue}>
          <TabPanel
            isShown={tabValue === TabMap.display}
            index={TabMap.display}>
            <Box p={3} className={classes.tabContainer}>
              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Typography
                  variant="h6"
                  style={{fontWeight: 700, marginBottom: '10px'}}>
                  1. Set Footer Image
                </Typography>
                <Grid style={{marginLeft: 20}}>
                  <LabelData
                    label="Image"
                    field={
                      display?.footerImage?.length
                      ? decodeURIComponent(
                          display.footerImage.split('/').reverse()[0],
                        ) || '-'
                      : '-'
                    }
                    valueId="FooterImage"
                  />
                </Grid>
              </Box>

              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Typography
                  variant="h6"
                  style={{fontWeight: 700, marginBottom: '10px'}}>
                  2. Set Duration
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '10px',
                  }}>
                  <Grid container item xs={6} alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{fontWeight: 700, marginBottom: '10px'}}>
                        Start Date
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item>
                        <Typography variant="h6" id="StartDate">
                          {(display?.startDate &&
                            moment(display.startDate).format('DD/MM/YYYY')) ||
                            '-'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={classes.dateDivider}></div>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" id="StartTime">
                          {(display?.startDate &&
                            moment(display.startDate).format('hh:mm A')) ||
                            '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{fontWeight: 700, marginBottom: '10px'}}>
                        End Date
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item>
                        <Typography variant="h6" id="EndDate">
                          {(display?.endDate &&
                            moment(display.endDate).format('DD/MM/YYYY')) ||
                            '-'}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={classes.dateDivider}></div>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" id="EndTime">
                          {(display?.endDate &&
                            moment(display.endDate).format('hh:mm A')) ||
                            '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
};

export default ViewDisplayPoster;
