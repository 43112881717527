import qrcodeIcon from '../img/qr_code-24px.svg';

const QrCodeIcon: React.FC<React.HTMLProps<HTMLImageElement>> = ({ 
  onClick = () => {},
  style = {},
  id
}) => (
  <img id={id} style={{...style, cursor: 'pointer'}} src={qrcodeIcon} alt="QR code icon" onClick={onClick} />
);

export default QrCodeIcon;
