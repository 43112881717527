import React, {useState, useEffect} from 'react';
import withHeader from '../../presentation/withHeader';
import {
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';

import MyButton from '../../presentation/button';
import AngusVERIFIED from '../../img/AngusVERIFIED.png';
import COLOR from '../../styled/colors';
import TableContainer from '@material-ui/core/TableContainer';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import MenuItem from '@material-ui/core/MenuItem';
import { getMobsByBusiness, downloadPreSaleReportCSV,fetchPreSale} from '../../store/angus/actions';
import { Document, Page, Text, View, Image, StyleSheet, Font, Link, pdf} from '@react-pdf/renderer';
import { toggleModal } from '../../store/modal/actions';
import Spinner from '../../presentation/Spinner';
import moment from "moment";
import { HyperLink } from '../../presentation/word';
import {preSaleDataType} from '../../store/angus/actions';
import Dropdown from '../../presentation/Dropdown';
import {getLocation} from '../../store/add/actions';

const useStyles = makeStyles((theme) => ({
  table: {
    width: 703,
    margin: 'auto',
  },
  tableContainer: {
    width: '100%',
  },
  bodyContainer: {
    marginBottom: 100,
  },
  generateContainer: {
    width: 311,
  },
  buttonContainer: {
    marginTop: 60,
  },
  button: {
    backgroundColor: COLOR.AA_RED,
    borderColor: COLOR.AA_RED,
    boxShadow: 'none',
  },
  buttonPDF: {
    color: COLOR.AA_RED,
    borderColor: COLOR.AA_RED,
    boxShadow: 'none',
    marginRight: 30,
  },
  head: {
    backgroundColor: COLOR.GRAY_SOLID,
    fontWeight: 'bold',
    width: '30%',
  },
  headFirst: {
    backgroundColor: COLOR.GRAY_SOLID,
    fontWeight: 'bold',
    width: '10%',
  },
  headLast: {
    backgroundColor: COLOR.GRAY_SOLID,
    fontWeight: 'bold',
    width: '30%',
  },
  body: {
    fontSize: 14,
  },
  checkCircle: {
    color: COLOR.GREEN_BUTTON,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
    maxWidth: 400,
  },
  select: {
    height: 48,
    borderColor: COLOR.GRAY_BORDER,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 170,
  },
}));

const rows2 = [];
function createData(index, rfid, sex, lt, eu, birth_start, birth_end) {
  return {index, rfid, sex, lt, eu, birth_start, birth_end};
}

const TableComponment =(props:{verifiedTable:Array<preSaleDataType>})=>{
    const classes = useStyles();
    return(
        <TableContainer className={classes.tableContainer} >
        <Table className={classes.table} aria-label="simple table">
            <TableHead >
                <TableRow>
                    <TableCell className={classes.headFirst} align="left">#</TableCell>
                    <TableCell className={classes.head} align="center">RFID</TableCell>
                    <TableCell className={classes.head} align="center">Sex</TableCell>
                    <TableCell className={classes.headLast} align="right">Birth Range by Month</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {props.verifiedTable.map((row,index) => (
                    <TableRow key={index}>
                        <TableCell component="th" scope="row" align="left">
                            {index+1}
                        </TableCell>
                        <TableCell align="center">{row.rfid}</TableCell>
                        <TableCell align="center">{row.sex}</TableCell>
                        <TableCell align="right">{row.birth_start} <span>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;</span>{row.birth_end}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    )
}

const PresaleReport: React.FC<{}> = () => {
  const classes = useStyles();
  const locationState = useAppSelector((state) => state.location);
  const userId = useAppSelector((state) => state.auth.wallet);
  const businessProfile = useAppSelector(state => state.user.businessProfileData);
  const {NLISUserID,NLISPassword} = businessProfile.industryType == "ANIMALS"? businessProfile.angusProfile: {NLISUserID:undefined, NLISPassword:undefined}
  const {emailAddress, businessId} = useAppSelector(
    (state) => state.user.userProfileData,
  );
  const [location, setLocation] = useState('');
  const [mobs, setMobs] = useState([]);
  const [mobSelect, setMobSelect] = useState('');
  const [mobId, setMobId] = useState('');
  const mobName = mobs.map((mob) => mob.details.mob_name);
  const [verifiedTable, setVerifiedTable] = useState([]);
  const [isSpinnerOpen, setIsSpinnerOpen] = useState(false);
  const handleChange = (event) => {
    setLocation(event.target.value);
  };
  const handleMobChange = (event) => {
    setMobSelect(event.target.value);
    const index = mobName.indexOf(event.target.value);
    setMobId(mobs[index]?.externalIds[0]?.agliveToken);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMobsByBusiness(userId, businessId)).then((resp) =>
      setMobs(resp),
    );
    dispatch(getLocation())
  }, []);
  const registerFont = () => {
    Font.register({
      family: 'Oswald',
      src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
    });

    Font.register({
      family: 'Open Sans',
      src: 'https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOUuhs.ttf',
    });
  };

        
    useEffect(() => {
    registerFont();
  }, []);

  const getPreSaleData = () => {
    setIsSpinnerOpen(true);
    fetchPreSale(mobId, location, emailAddress, NLISUserID, NLISPassword)
      .then((response: any) => {
        setVerifiedTable(
          response.data.map((data, index) => {
            data.birth_start = moment(data.birth_start).format('MM-YYYY');
            data.birth_end = moment(data.birth_end).format('MM-YYYY');

            return Object.assign(data, {index: index});
          }),
        );
        setIsSpinnerOpen(false);
      })
      .catch((err) => {
        dispatch(
          toggleModal({
            status: 'failed',
            title: err.title ? 'Error' : 'Unexpected Error',
            subtitle: err.error.details ?? err.message ?? 'Unexpected Error',
          }),
        );
        setIsSpinnerOpen(false);
      });
  };

  const addIndex = (start: number, array: Array<any>) => {
    array.map((data, index) => Object.assign(data, {index: index + start + 1}));
    return array;
  };
  const [page1Table1, setPage1Table1] = useState([]);
  const [page1Table2, setPage1Table2] = useState([]);
  const [otherPageTables, setOtherPageTables] = useState([]);
  const pagination = (tableData: Array<any>) => {
    const length = tableData.length;
    if (length <= 38) {
      if (length < 19) {
        setPage1Table1(addIndex(0, tableData));
      } else {
        setPage1Table1(addIndex(0, tableData.slice(0, 19)));
        setPage1Table2(addIndex(19, tableData.slice(19, length)));
      }
    } else {
      setPage1Table1(addIndex(0, tableData.slice(0, 19)));
      setPage1Table2(addIndex(19, tableData.slice(19, 38)));
      let row = length - 38;
      let startIndex = 38;
      while (row > 0) {
        if (row > 52) {
          let pageTableObj = {
            table1: addIndex(
              startIndex,
              tableData.slice(startIndex, startIndex + 26),
            ),
            table2: addIndex(
              startIndex + 26,
              tableData.slice(startIndex + 26, startIndex + 52),
            ),
          };
          setOtherPageTables((otherPageTables) => [
            ...otherPageTables,
            pageTableObj,
          ]);
          startIndex += 52;
          row -= 52;
        } else {
          if (row > 26) {
            let pageTableObj = {
              table1: addIndex(
                startIndex,
                tableData.slice(startIndex, startIndex + 26),
              ),
              table2: addIndex(
                startIndex + 26,
                tableData.slice(startIndex + 26, length),
              ),
            };
            setOtherPageTables((otherPageTables) => [
              ...otherPageTables,
              pageTableObj,
            ]);
          } else {
            let pageTableObj = {
              table1: addIndex(startIndex, tableData.slice(startIndex, length)),
              table2: [],
            };
            setOtherPageTables((otherPageTables) => [
              ...otherPageTables,
              pageTableObj,
            ]);
          }
          startIndex += 52;
          row -= 52;
        }
      }
    }
  };
  useEffect(() => {
    pagination(verifiedTable);
  }, [verifiedTable]);
  const DownloadPdf = () => (
    <MyButton
      text={'Download as PDF'}
      variant="outlined"
      id={'DownloadPDF'}
      buttonClass={classes.buttonPDF}
      onClick={async () => {
        const blob = await pdf(
          <MyDocument
            location={location}
            mobSelect={mobSelect}
            verifiedTable={verifiedTable}
            page1Table1={page1Table1}
            page1Table2={page1Table2}
            otherPageTables={otherPageTables}
          />,
        ).toBlob();
        const blobUrl = await URL.createObjectURL(blob);

        // https://dev.to/nombrekeff/download-file-from-blob-21ho
        // Create a link element
        const link = document.createElement('a');

        // Set link's href to point to the Blob URL
        link.setAttribute('href', blobUrl);
        link.setAttribute('download', mobSelect);

        document.body.appendChild(link); // Required for FF

                // Dispatch click event on the link
                // This is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(
                    new MouseEvent('click', { 
                    bubbles: true, 
                    cancelable: true, 
                    view: window 
                    })
                );
            }}   
        />
    );
    return(
      <Grid container>
        <Grid container justify="space-between" className={classes.bodyContainer}>
          <Grid item  container xs={4} >
            <Dropdown
              name={'PIC No.'}
              handleChange={handleChange}
              id={'PICSelect'}
              defaultValue={location}
              itemNames={locationState.location.map(({PICAddress})=>PICAddress)}
            />              
          </Grid>
          <Grid item container xs={4} >
            <Dropdown
                name={'Mob Name'}
                handleChange={handleMobChange}
                id={'MobSelect'}
                defaultValue={mobSelect}
                itemNames={mobName}
            />
          </Grid>

          <Grid item className={classes.buttonContainer}>
              <MyButton
                  text={'Generate'}
                  variant="contained"
                  width={250}
                  fontSize={18}
                  buttonClass={classes.button}
                  disabled={!mobSelect||!location}
                  onClick={() =>
                      getPreSaleData()
                  }
              />
          </Grid>
        </Grid>
        {(verifiedTable.length!==0||rows2.length!==0)&&
            <Grid container style={{marginBottom:30, display:"flex", justifyContent:'space-between'}}>
              <Grid item style={{ maxWidth:'70%'}}>
                <Typography variant="h2" role="label" align='left' style={{marginBottom:15}}>
                    ANGUS VERIFIED PRE-SALE REPORT
                </Typography>
                <Typography variant="h6" role="label" align='left' style={{marginBottom:15}}>
                    All sires of Angus Verified animals are registered with Angus Australia.<br/><br/>
                    It is declared that all Angus Verified animals meet all requirements of Angus Australia's Black Angus Cattle Assessment Guidelines.
                </Typography>
                <Typography variant="h6" role="label" align='left' >
                    Angus Verified animals status can be checked on the Angus Australia website using RFIDs
                </Typography>
                <div >
                    <HyperLink style={{color:COLOR.BLACK_BG}} href={'https://angus.tech/angus/verified/animal'}  >
                        https://angus.tech/angus/verified/animal
                    </HyperLink>
                </div>
              </Grid>
              <Grid item >
                <img
                src={AngusVERIFIED}                   
                alt=""
                style={{width:204, marginLeft:30}}
                />
              </Grid>
            </Grid>
          }
        {verifiedTable.length!==0&&
          <>
            <Grid container style={{marginBottom:20, alignItems:'center', justifyContent:'center', marginTop:30}}>
              <Grid item >
                <Typography variant="h2" role="label" align='center' >
                  ALL LISTED RFIDS ARE ANGUS VERIFIED
                </Typography>                     
              </Grid>           
            </Grid>
            <TableComponment verifiedTable={verifiedTable}/>
          </>
        }
        {(verifiedTable.length!==0)&&
          <Grid container justify="flex-end" className={classes.buttonGrid}>
            <DownloadPdf/>
              <MyButton
                text={'Download as CSV'}
                variant="contained"
                buttonClass={classes.button}
                onClick={() =>dispatch(downloadPreSaleReportCSV(verifiedTable,mobSelect)) } 
                id={'DownloadCSV'}             
              />
          </Grid>
        }
        <Spinner isOpen={isSpinnerOpen} />           
      </Grid>

    );
  };

export default withHeader(
  {
    title: 'Angus Verified Pre-Sale Report',
    margin: 40,
  },
  PresaleReport,
);

interface propInterface {
  location: string;
  mobSelect: string;
  verifiedTable: Array<any>;
  page1Table1: Array<any>;
  page1Table2: Array<any>;
  otherPageTables: Array<any>;
}
function MyDocument(props: propInterface) {
  const styles = StyleSheet.create({
    pdfTitle: {
      fontFamily: 'Oswald',
      fontSize: 20,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    line: {
      borderBottomColor: COLOR.BLACK,
      borderBottomWidth: 4,
      marginTop: 6,
      marginBottom: 12,
      alignSelf: 'stretch',
      width: '100%',
    },
    contentText: {
      fontFamily: 'Open Sans',
      fontSize: 11,
      color: COLOR.BLACK_BG,
    },
    text: {
      fontFamily: 'Open Sans',
      fontSize: 8,
      color: COLOR.BLACK_BG,
    },
    head: {
      fontFamily: 'Oswald',
      fontSize: 24,
      fontWeight: 400,
      color: COLOR.BLACK_BG,
    },
    margin10: {
      marginBottom: 10,
    },
    margin3: {
      marginBottom: 3,
    },
    left3: {
      marginLeft: 3,
    },
    pageNumbers: {
      marginTop: 20,
      fontSize: 7,
      textAlign: 'right',
      position: 'absolute',
      bottom: 55,
      left: 0,
      right: 40,
      color: COLOR.BLACK_BG,
    },
    leftColumn: {
      flexDirection: 'column',
      paddingRight: 15,
      width: '50%',
    },
    container: {
      flex: 1,
      flexDirection: 'row',
    },
  });
  return (
    <Document>
      <Page size="A4">
        <View style={{margin: '30 20 20 35', padding: 10}}>
          <View style={{marginBottom: 20}}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}>
              <View>
                <Text style={[styles.head, styles.margin10]}>
                  ANGUS VERIFIED PRE-SALE REPORT
                </Text>
                <Text style={[styles.contentText, styles.margin10]}>
                  All sires of Angus Verified animals are registered with Angus
                  Australia
                </Text>
                <Text style={[styles.contentText, styles.margin3]}>
                  It is declared that all Angus Verified animals meet all
                  requirements of
                </Text>
                <Text style={[styles.contentText, styles.margin3]}>
                  Angus Australia's Black Angus Cattle Assessment Guidelines
                </Text>
              </View>
              <Image src={AngusVERIFIED} style={{width: 120, height: 90}} />
            </View>
            <View>
              <Text style={[styles.contentText, styles.margin3]}>
                Angus Verified animal status can be checked on the Angus
                Australia website using RFIDs
              </Text>
              <Link
                src={'https://angus.tech/angus/verified/animal'}
                style={styles.contentText}>
                https://angus.tech/angus/verified/animal
              </Link>
            </View>
          </View>
          {props.verifiedTable.length !== 0 && (
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text style={[styles.pdfTitle, styles.margin3]}>
                ALL LISTED RFIDS ARE ANGUS VERIFIED
              </Text>
            </View>
          )}
          <View style={styles.container}>
            <View style={styles.leftColumn}>
              {props.page1Table1.length !== 0 && (
                <PdfTables tableData={props.page1Table1} />
              )}
            </View>
            <View style={styles.leftColumn}>
              {props.page1Table2.length !== 0 && (
                <PdfTables tableData={props.page1Table2} />
              )}
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumbers}
          render={({pageNumber, totalPages}) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
      {props.otherPageTables.length !== 0 &&
        props.otherPageTables.map((data) => {
          return (
            <Page size="A4" style={{marginBottom: 30}}>
              <View style={{margin: '40 20 20 35', padding: 10}}>
                <View style={styles.container}>
                  <View style={styles.leftColumn}>
                    {data.table1.length !== 0 && (
                      <PdfTables tableData={data.table1} />
                    )}
                  </View>
                  <View style={styles.leftColumn}>
                    {data.table2.length !== 0 && (
                      <PdfTables tableData={data.table2} />
                    )}
                  </View>
                </View>
              </View>
              <Text
                style={styles.pageNumbers}
                render={({pageNumber, totalPages}) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
                fixed
              />
            </Page>
          );
        })}
    </Document>
  );
}

  function PdfTables(props:{tableData:Array<any>}){
    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });
    Font.register( {
        family: 'Open Sans',
        src: 'https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOUuhs.ttf',
      });
    const styles = StyleSheet.create({
        table: {
            display: 'flex',
            width: 'auto',
            flexDirection: 'column',
            borderBottom: `1px solid ${COLOR.BLACK_BG}`
        },
        tableRow: {
            display: 'flex',
            margin: 'auto',
            flexDirection: 'row'
        },
        tableColHeader: {
            backgroundColor: COLOR.GRAY_BUTTON, 
            height:20,
            fontFamily: "Open Sans",
            fontWeight:700, 
            fontSize:8,
            borderRight: `1px solid ${COLOR.WHITE}`,
            alignItems: 'center'
        },
        tableCol: {
            borderTop: `1px solid ${COLOR.BLACK_BG}`,
            alignItems: 'center'
        },
        tableCell: {
            fontFamily: "Open Sans",
            fontSize: 8,        
            color: COLOR.BLACK_BG,
        },
        head: {
            fontFamily: "Open Sans",
            backgroundColor: COLOR.GRAY_SOLID,
            fontWeight: 700,   
        },
        left3:{
            marginLeft:3
        },
      });
      
      return(
          <View >
            <View style={styles.table}>
                <View style={[styles.tableRow, {borderTop: `1px solid ${COLOR.BLACK_BG}`}]}>
                    <View style={[styles.tableColHeader, {width: '9%'}]}>
                        <Text>#</Text>                       
                    </View>
                    <View style={[styles.tableColHeader, {width: '36%'}]}>
                        <Text>RFID</Text>
                    </View>
                    <View style={[styles.tableColHeader, {width: '19%'}]}>
                        <Text>Sex</Text>
                    </View>
                    <View style={[styles.tableColHeader, {width: '36%', borderRight: 0}]}>
                        <Text>Birth Range by Month</Text>                       
                    </View>
                </View> 
                {props.tableData.map((r, i) => <View style={styles.tableRow} key={`PDFTableRow-${i}`}>
                    <View style={[styles.tableCol, {height:25, width: '9%'}]}>
                        <Text style={styles.tableCell}>{r.index}</Text>
                    </View>
                    <View style={[styles.tableCol, {height:25, width: '36%'}]}>
                        <Text style={styles.tableCell}>{r.rfid}</Text>
                    </View>
                    <View style={[styles.tableCol, {height:25, width: '19%'}]}>
                        <Text style={styles.tableCell}>{r.sex}</Text>
                    </View>
                    <View style={[styles.tableCol, {height:25, width: '36%', alignItems: 'flex-start'}]}>
                        <Text style={[styles.tableCell, styles.left3]}>{r.birth_start} - {r.birth_end}</Text>
                    </View>
                </View>)}
            </View>
          </View>
      )
  }
