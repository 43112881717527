import {clone} from 'lodash';
import {LayerNode, SiteTreeLayerNode} from './types';

export function validateTree(tree: LayerNode): boolean {
  if (!tree.layerName) return false;

  if (tree.children?.length) {
    for (const childNode of tree.children) {
      if (!validateTree(childNode)) return false;
    }
  }

  return true;
}

export function cloneNode(
  state: LayerNode,
  targetHandler: (arg0: LayerNode) => any,
): LayerNode {
  let nextState: LayerNode = clone(state);
  let ptr = nextState;

  targetHandler(ptr);
  return nextState;
}

export function targetParentNode(
  target: LayerNode,
  args: Array<number>,
): LayerNode {
  // move pointer to the target's parent node and push a new node
  for (let i = 0; i < args.length - 1; i++) {
    target = target.children[args[i]];
  }

  return target;
}

export function targetNode(target: LayerNode, args: Array<number>): LayerNode {
  // move pointer to the target node
  for (let i = 0; i < args.length; i++) {
    target = target.children[args[i]];
  }

  return target;
}

export function siblingNamingFunction(siblingName: string) {
  let oldNameArr = siblingName.split('.');
  const oldNameIndex = Number(oldNameArr[oldNameArr.length - 1]);
  oldNameArr[oldNameArr.length - 1] = String(oldNameIndex + 1);

  return oldNameArr.join('.');
}

export function childNamingFunction(parentName: string, index: number) {
  let parentNameArr = parentName.split('.');
  return [...parentNameArr, String(index)].join('.');
}

function removeEmptyChildren(tree: LayerNode) {
  if (tree.children?.length) {
    tree.children = tree.children.map((child) => removeEmptyChildren(child));

    return tree;
  } else {
    delete tree.children;
    return tree;
  }
}

export function nodeCount(tree: LayerNode): number {
  if (tree.children?.length) {
    return tree.children.reduce(
      (accum, curNode) => accum + nodeCount(curNode),
      1,
    );
  } else {
    return 1;
  }
}

export function flattenTree(tree: LayerNode): Array<SiteTreeLayerNode> {
  if (tree.children?.length) {
    return tree.children.reduce(
      (accumArr, curNode) => [...accumArr, ...flattenTree(curNode)],
      [
        {
          name: tree.name,
          id: tree.id,
          layerName: tree.layerName,
        },
      ],
    );
  } else {
    const {children, ...rest} = tree;
    return [{...rest}];
  }
}
