import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  getBusinessProfile,
  validateCredential,
  updateBusinessProfile,
  canDeleteLocation,
} from '../../store/profile/actions';
import {
  editUser,
  inviteUserAgain,
  submitProject,
} from '../../store/add/actions';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ListItem,
  Collapse,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Switch,
  InputLabel,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import COLOR from '../../styled/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import MyButton from '../../presentation/button';
import {FormHelperText} from '@material-ui/core';
import CONSTANT from '../../config/constant';
import {Buttons} from '../../presentation/ButtonsGroup';
import {businessProfileObject, initialStateType} from '@aglive/frontend-core';
import {BusinessMisc, TokenService} from '@aglive/data-model';
import {PageHeader} from '../../presentation/withHeader';
import InputMask from 'react-input-mask';
import LocationUsers from './LocationUsers';
import {clearWarning} from '../../utils/validation';
import EditLicenseForm from './editBusiness/EditLicenseForm';
import {
  INITIAL_LICENSE_VALIDATION_STATE,
  INITIAL_PERMIT_VALIDATION_STATE,
  newEmptyLicense,
  newEmptyLicenseValidation,
} from './editBusiness/dataTemplate';
import {
  GmpLicense,
  GmpPermit,
  licenseType,
  OdcLicense,
  OdcPermit,
  PoisonPermit,
} from '@aglive/data-model/dist/misc/business';
import _ from 'lodash';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {LicenseUnsavedState} from './editBusiness/types';
import {DialogContentSubtitle} from 'src/presentation/DialogContentSubtitle';
import {
  FormGenerator,
  FormInputFunctions,
} from 'src/presentation/FormGenerator';
import {fetchSiteTokens} from 'src/store/site/actions';
import {UpdatedLinkedSite} from 'src/store/site/types';
import { filterPermits } from 'src/store/profile/utils';
import useSiteOptions from '../site/useSiteOptions';

const LicenseTypeToValidationName: Record<licenseType, string> = {
  ODC: 'odcLicense',
  GMP: 'gmpLicense',
  Poison: 'poisonPermit',
} as const;

const COUNTRY_LIST = Object.keys(BusinessMisc.SupportedCountries); //['Australia', 'Canada', 'Argentina'];
const STATE_OPTIONS = BusinessMisc.SupportedCountries;

const INTERVAL_OPTIONS = ['1 Day', '7 Days', '30 Days'];

const INITIAL_COLAPSE_STATE = {
  location: true,
  LPA: false,
  NLIS: false,
};

const useStyle = makeStyles(() => ({
  imageStyle: {
    width: '100%',
    height: '100%',
    maxWidth: 312,
    maxHeight: 212,
  },
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
  table: {
    marginTop: 30,
    '& td': {verticalAlign: 'top'},
  },
  tableHeader: {
    backgroundColor: COLOR.GRAY_SOLID,
    '& td': {
      borderBottom: 0,
      minWidth: 120,
      fontWeight: 700,
    },
  },
  tableEditPadding: {paddingTop: 10, paddingBottom: 10},
  tableIcons: {
    marginRight: 10,
    cursor: 'pointer',
  },
  linkButton: {
    textTransform: 'none',
    color: COLOR.GREENT_TEXT,
  },
}));

export const businessInputs = (
  key: string,
  value?: string,
  viewMode?: boolean,
  required?: boolean,
  skip?: boolean,
  customValidation?: (value: string) => {result: boolean; error: string},
  items?: {label: string; value: string; disabled?: boolean}[] | string[],
  onChange?: (value: any) => void,
  typeChange?: string,
) => {
  switch (key) {
    case 'locationNickname':
    case 'establishmentNickname':
      return {
        type: 'INPUT',
        name: 'locationNickname',
        required: !!required,
        viewMode: !!viewMode,
        skip: skip,
        value: value,
        titleKey: key,
        onChange,
      };
    case 'locationId':
    case 'establishmentId':
      return {
        type: 'INPUT',
        name: 'locationId',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        skip: skip,
        titleKey: key,
        validationRegex: /^[a-zA-Z0-9 -]+$/,
        titleSuffix: !!required ? ' (Optional)' : '',
        customValidation: customValidation,
      };
    case 'PICAddress':
      return {
        type: 'INPUT',
        name: 'PICAddress',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        skip: skip,
        titleKey: 'locationId',
        titleSuffix: ' e.g. PIC/PID',
        customValidation: customValidation,
        onChange,
      };
    case 'RENSPA':
      return {
        type: 'MASK',
        name: 'PICAddress',
        placeholder: '99.999.9.99999/99',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        skip: skip,
        titleKey: 'locationId',
        validationRegex: CONSTANT.RENSPA_REGEX,
        errorMsgKey: 'validRenspa',
      };
    case 'country':
      return {
        type: 'SELECT',
        name: 'country',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        skip: skip,
        titleKey: 'country',
        items: items ? items : COUNTRY_LIST,
      };
    case 'state':
      return {
        type: 'SELECT',
        name: 'state',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        titleKey: 'state',
        skip: skip,
        dependsOn: 'country',
        dependentItems: JSON.parse(JSON.stringify(STATE_OPTIONS)),
        dependentTitleKey: {
          Australia: 'state',
          USA: 'state',
          Argentina: 'provinceTerritory',
          Canada: 'provinceTerritory',
          default: 'state'
        },
      };
    case 'town':
      return {
        type: 'INPUT',
        name: 'town',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        skip: skip,
        dependsOn: 'country',
        dependentTitleKey: {USA: 'cityTownSuburb', default: 'suburb'},
      };
    case 'postcode':
      return {
        type: 'INPUT',
        name: 'postcode',
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        skip: skip,
        dependsOn: 'country',
        dependentValidationRegex: CONSTANT.POSTCODE_REGEX,
        dependentErrorMsg: CONSTANT.POSTCODE_REGEX_WARNING,
        dependentTitleKey: {USA: 'zipcode', default: 'postcode'},
      };
    case 'address':
      return {
        type: 'INPUT',
        name: 'address',
        skip: skip,
        required: !!required,
        viewMode: !!viewMode,
        value: value,
        titleKey: 'address',
        minRows: 3,
      };
    case 'region':
      return {
        type: 'SELECT',
        name: 'region',
        required: !!required,
        viewMode: !!viewMode,
        value: value ?? 'N/A',
        skip: skip,
        titleKey: 'region',
        items: items,
      };
    case 'property':
      return {
        type: 'SELECT',
        name: 'property_type',
        required: !!required,
        viewMode: !!viewMode,
        value: value ?? 'N/A',
        skip: skip,
        titleKey: 'propertytype',
        items: items,
      };
    case 'NLISUserID':
      return {
        type: typeChange ?? 'INPUT',
        name: 'NLISUserID',
        skip: skip,
        dependentRequired: true,
        dependsOn: 'NLISPassword',
        viewMode: !!viewMode,
        value: value,
        titleKey: 'nlisUser',
      };
    case 'NLISUserPassword':
      return {
        type: typeChange ?? 'INPUT',
        name: 'NLISPassword',
        skip: skip,
        dependentRequired: true,
        dependsOn: 'NLISUserID',
        viewMode: !!viewMode,
        value: value,
        titleKey: 'nlisPassword',
      };
    case 'LPAUserID':
      return {
        type: 'INPUT',
        name: 'LPAUserID',
        skip: skip,
        dependentRequired: true,
        dependsOn: 'LPAPassword',
        viewMode: !!viewMode,
        value: value,
        titleKey: 'lpaUser',
      };
    case 'LPAPassword':
      return {
        type: 'INPUT',
        name: 'LPAPassword',
        skip: skip,
        dependentRequired: true,
        dependsOn: 'LPAUserID',
        viewMode: !!viewMode,
        value: value,
        titleKey: 'lpaPassword',
      };
    case 'linkedPermit':
      return {
        type: 'SELECT',
        name: 'permits',
        skip: skip,
        viewMode: !!viewMode,
        value: value,
        required: !!required,
        title: 'Linked Permit',
        items: items,
        customValidation: customValidation,
      };
  }
};

function changeLicenseField<T>(
  prevState: TokenService.BusinessToken['details'],
  index: number,
  fieldName: string,
  value: T,
) {
  if (prevState.industryType === 'PLANTS') {
    const newLicenses = prevState.licenses.map((license, i) =>
      index === i ? {...license, [fieldName]: value} : license,
    );
    return {
      ...prevState,
      licenses: newLicenses,
    };
  }
  return prevState;
}

const INITIAL_PLANT_LICENSES = {ODC: [], GMP: [], Poison: []};

function changePermitField<T>(
  prevState: TokenService.BusinessToken['details'],
  licenseIndex: number,
  permitIndex: number,
  newPermit: T,
) {
  if (prevState.industryType !== 'PLANTS') {
    return prevState;
  }
  const newLicenses = prevState.licenses.map((license, i) => {
    if (licenseIndex !== i) {
      return license;
    }
    if (license.name === 'Poison') {
      return license;
    }
    const newPermits = license.permits.map((oldPermit, j) => {
      if (permitIndex !== j) {
        return oldPermit;
      }
      return newPermit;
    });
    return {...license, permits: newPermits};
  });
  return {
    ...prevState,
    licenses: newLicenses,
  };
}

const EditBusinessProfile: React.FC<{isEstablishment?: boolean}> = (props) => {
  const {hash} = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const businessProfileDoc: TokenService.BusinessToken['details'] =
    useAppSelector((state) => state.user.businessProfileData);
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const businessPlugins = useAppSelector((state) => state.user.plugins);
  const isAmbioxera =
    businessPlugins?.filter(
      (br) =>
        br.name.toLowerCase() === 'ambioxera' && br.status === 'activated',
    ).length > 0;
  const placeType = isAmbioxera ? 'Establishment' : 'Location';
  const isWarakirri =
    businessPlugins?.filter(
      (br) =>
        br.name.toLowerCase() === 'dairy reports' && br.status === 'activated',
    ).length > 0;

  const {regions, propertyTypes} = useSiteOptions();

  const [editBusiness, setEditBusiness] = useState<
    TokenService.BusinessToken['details']
  >(JSON.parse(JSON.stringify(initEditBusinessData(businessProfileDoc))));

  const siteTokens = useAppSelector((state) => state.site);

  // only for edit location, {agliveToken -> UpdatedLinkedSite}
  const updatedLinkedSitesRef = useRef<Record<string, UpdatedLinkedSite>>({});

  const locationFormRef = useRef<FormInputFunctions[]>([]);

  const plantLicenses =
    editBusiness.industryType === 'PLANTS'
      ? editBusiness.licenses?.reduce(
          (group, license, i) => {
            const licenses = group[license.name] ?? [];
            group[license.name] = [...licenses, {...license, index: i}];
            return group;
          },
          {...INITIAL_PLANT_LICENSES},
        ) ?? {...INITIAL_PLANT_LICENSES}
      : {...INITIAL_PLANT_LICENSES};
  const businessProfile = useMemo(
    () =>
      businessProfileObject(
        initEditBusinessData(editBusiness) as
          | BusinessMisc.AnimalBusinessInterface
          | BusinessMisc.PlantBusinessInterface,
      ),
    [editBusiness],
  );

  const allowLPA =
    businessProfile.isLivestock() && businessProfile.isAustralia();
  const allowNLIS = allowLPA || isAmbioxera;

  const [useOptiweigh, setUseOptiweigh] = useState(
    businessProfile.isOptiweigh(),
  );
  // multiple location
  const [collapse, setCollapse] = useState(
    Array.from({length: businessProfile.numLocations()}, (_) => ({
      ...INITIAL_COLAPSE_STATE,
    })),
  );
  const [validationState, setValidationState] = useState({
    ...businessProfile.initialValidationState(),
  });

  const [deleteLocation, setDeleteLocation] = useState(false);
  const [deleteLicense, setDeleteLicense] = useState(false);
  const [unsavedLicenses, setUnsavedLicenses] = useState<LicenseUnsavedState>(
    {} as LicenseUnsavedState,
  );

  const [businessLanguage, setBusinessLanguage] = useState(CONSTANT.EN_LABELS);

  const defaultLocationCountry =
    editBusiness.industryType === 'PLANTS'
      ? 'Australia'
      : editBusiness.businessCountry;

  const isLivestock = businessProfile.isLivestock();

  const oldOdcPermits = useMemo(
    () =>
      businessProfileDoc.industryType === 'PLANTS'
        ? filterPermits('ODC', businessProfileDoc.licenses)
        : [],
    [businessProfileDoc],
  );

  const validPermits = useMemo(() => {
    let vp: {label: string; value: string}[] = [];
    let unexpired: string[] = [];
    let mapping: {[key: string]: string} = {};
    if (editBusiness['licenses']?.length) {
      editBusiness['licenses'].forEach((l: OdcLicense) => {
        if (l.name === 'ODC' && l.permits?.length) {
          l.permits.forEach((lp) => {
            if (moment(lp.expiryDate).isAfter() && lp.permitId) {
              vp.push({label: lp.permitNumber, value: lp.permitId});
              unexpired.push(lp.permitId);
            }
            mapping[lp.permitId] = lp.permitNumber;
          });
        }
      });
    }
    return {valid: vp, unexpired, mapping};
  }, [editBusiness]);

  /**Handle Onchange TextField*/
  const handleEditState =
    (editName: string, subKey?: string) =>
    (e: React.ChangeEvent<{name?: string; value: any}>) => {
      e.persist();
      setValidationState((prevState) => clearWarning(prevState));
      setEditBusiness((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        if (subKey) {
          if (!newState[subKey]) {
            newState[subKey] = {};
          }
          newState[subKey][editName] = e.target.value;
        } else {
          newState[editName] = e.target.value;
        }
        return newState;
      });
    };

  const handleEditPIC = (locationIndex: number, key: string, value: string) => {
    if (
      key === 'PICAddress' ||
      (businessProfileDoc.industryType === 'PLANTS' &&
        key === 'locationNickname')
    ) {
      const originalLocation = businessProfileDoc.location[locationIndex];
      if (!originalLocation) {
        return;
      }
      const linkedSite = siteTokens?.find(
        (site) => site.details.location === originalLocation.PICAddress && (!isWarakirri || site.details.propertyType === originalLocation['property_type']),
      );
      if (!linkedSite) {
        return;
      }
      const agliveToken = linkedSite.externalIds[0].agliveToken;
      if (!agliveToken) {
        return;
      }
      updatedLinkedSitesRef.current[agliveToken] = {
        agliveToken,
        siteDetails: linkedSite.details,
        newPICAddress: value,
      };
    }
  };

  const handleAddLicense = (licenseType: licenseType) => () => {
    setValidationState((prev) => {
      let newState = _.cloneDeep(prev);
      const licenseFieldName = LicenseTypeToValidationName[licenseType];
      let licenses = (newState as any)[licenseFieldName];
      const result = {
        ...newState,
        [licenseFieldName]: [
          ...licenses,
          newEmptyLicenseValidation(licenseType),
        ],
      };
      return result;
    });
    setEditBusiness((prevState) => {
      if (prevState.industryType === 'PLANTS') {
        return {
          ...prevState,
          licenses: [...prevState.licenses, newEmptyLicense(licenseType)],
        };
      }
      return prevState;
    });
  };

  const handleUpdateLicense = (
    globalLicenseIndex: number,
    localLicenseIndex: number,
    fieldName: string,
    newValue: any,
  ) => {
    setValidationState((prevState) => clearWarning(prevState));
    if (editBusiness.industryType === 'PLANTS') {
      const updatedValue =
        newValue instanceof Date ? moment(newValue) : newValue;
      setEditBusiness((prevState) =>
        changeLicenseField(
          prevState,
          globalLicenseIndex,
          fieldName,
          updatedValue,
        ),
      );
    }
  };

  const handleSavePermit = (
    localLicenseIndex: number,
    licenseIndex: number,
    permitIndex: number,
    permit: OdcPermit | GmpPermit,
  ) => {
    if (editBusiness.industryType === 'PLANTS') {
      setEditBusiness((prev) =>
        changePermitField(prev, licenseIndex, permitIndex, permit),
      );
    }
  };

  const handleAddPermit =
    (licenseType: licenseType) =>
    (
      localLicenseIndex: number,
      licenseIndex: number,
      permit: OdcPermit | GmpPermit,
    ) => {
      setEditBusiness((prev) => {
        if (prev.industryType === 'PLANTS') {
          const newLicense = prev.licenses.map((license, i) => {
            if (licenseIndex !== i || license.name === 'Poison') {
              return license;
            }
            const result = {
              ...license,
              permits: [...license.permits, permit],
            };
            return result;
          });
          return {...prev, licenses: newLicense};
        }
        return prev;
      });
    };

  const handleDeletePermit =
    (licenseType: licenseType) =>
    (localLicenseIndex: number, licenseIndex: number, permitIndex: number) => {
      setEditBusiness((prevState) => {
        if (prevState.industryType === 'PLANTS') {
          const newLicenses = prevState.licenses.map((license, i) => {
            if (licenseIndex !== i || license.name === 'Poison') {
              return license;
            }
            return {
              ...license,
              permits: license.permits.filter(
                (_permit, j) => j !== permitIndex,
              ),
            };
          });
          return {...prevState, licenses: newLicenses};
        }
        return prevState;
      });
      setValidationState((prev) => {
        let newState = _.cloneDeep(prev);
        let licenseField = LicenseTypeToValidationName[licenseType];
        let licenseValidationState = (newState as any)[licenseField][
          localLicenseIndex
        ];
        licenseValidationState.permits = licenseValidationState.permits.filter(
          (_p, i) => i !== permitIndex,
        );
        return newState;
      });
    };

  const handleDeleteLicense =
    (licenseType: licenseType) =>
    (licenseIndex: number, localIndex: number) => {
      setEditBusiness((prevState) => {
        if (prevState.industryType === 'PLANTS') {
          return {
            ...prevState,
            licenses: prevState.licenses.filter(
              (_license, index) => index !== licenseIndex,
            ),
          };
        }
        return prevState;
      });

      setValidationState((prevState) => {
        let newState = _.cloneDeep(prevState) as any;
        if (newState.licenses) {
          const licenseField = LicenseTypeToValidationName[licenseType];
          newState[licenseField] = newState[licenseField].filter(
            (license, index) => index !== localIndex,
          );
        }
        return newState;
      });
      setDeleteLicense(true);
    };

  const handleLicenseEditingState = (
    licenseIndex: number,
    isEditing: boolean,
  ) => {
    setUnsavedLicenses((prev) => ({...prev, [licenseIndex]: isEditing}));
  };

  // multiple location
  const handleCollapse =
    (collapseName: 'location' | 'NLIS' | 'LPA', index: number) => () => {
      let updateState = [...collapse];
      updateState[index][collapseName] = !updateState[index][collapseName];
      setCollapse(updateState);
    };

  // Add new Location
  const handleAddLocation = () => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      if (!newState.hasOwnProperty('location')) {
        newState.location = [];
      }
      newState.location.push({
        ...businessProfile.locationTemplate(),
        country: defaultLocationCountry,
      });
      return newState;
    });

    setCollapse((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.push(INITIAL_COLAPSE_STATE);
      return newState;
    });

    setValidationState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.push({...businessProfile.locationTemplate()});
      return newState;
    });
  };

  const handleEditInterval = (
    e: React.ChangeEvent<{name?: string; value: any}>,
  ) => {
    setValidationState((prevState) => clearWarning(prevState));
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      if (!newState['optiweigh']) {
        newState['optiweigh'] = {};
      }
      newState['optiweigh']['interval'] = e.target.value;
      return newState;
    });
  };

  // Remove Location
  const handleRemoveLocation = async (index: number) => {
    const removedLocation = editBusiness?.location[index];
    if (removedLocation && editBusiness.industryType === 'PLANTS') {
      const delLocationResponse = await dispatch(
        canDeleteLocation(removedLocation.PICAddress),
      );
      if (!delLocationResponse.canDelete && delLocationResponse.reason) {
        let errorMessage = '';
        if (delLocationResponse.reason === 'linked_site') {
          errorMessage = 'You cannot delete a location with a linked site';
        }
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Error',
            subtitle: errorMessage,
          }),
        );
        return;
      }
    }

    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.splice(index, 1);
      return newState;
    });

    setCollapse((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(index, 1);
      return newState;
    });

    setValidationState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.splice(index, 1);
      return newState;
    });

    setDeleteLocation(true);
    locationFormRef.current = locationFormRef.current.slice(
      0,
      editBusiness?.location?.length - 1,
    );
  };

  useEffect(() => {
    dispatch(getBusinessProfile());
  }, []);

  useEffect(() => {
    if (!containerRef.current || !hash || !businessProfileDoc) {
      return;
    }
    const element = containerRef.current.querySelector(hash);
    if (!element) {
      return;
    }
    element.scrollIntoView();
  }, [businessProfileDoc, hash]);

  useEffect(() => {
    let businessProfileCopy = JSON.parse(JSON.stringify(businessProfileDoc));
    businessProfileCopy.location = businessProfileCopy.location?.map((loc) => {
      return {...loc, country: loc.country ?? 'Australia'};
    });
    setEditBusiness(initEditBusinessData(businessProfileCopy));
  }, [businessProfileDoc]);

  useEffect(() => {
    dispatch(fetchSiteTokens());
  }, [dispatch]);

  const handleSaveBusinessData = () => {
    if (
      editBusiness.industryType === 'PLANTS' &&
      hasUnsavedData(unsavedLicenses)
    ) {
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'Unsaved Changes',
          customContent: (
            <DialogContentSubtitle
              texts={[
                'Do you want to proceed',
                'without saving the new changes?',
              ]}
            />
          ),
          renderButton: (
            <Buttons
              leftButtonTitle={'No'}
              rightButtonTitle={'Yes'}
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
              }}
              rightButtonOnClick={() => {
                dispatch(toggleModalOff());
                handleSaveModifiedData();
              }}
            />
          ),
        }),
      );
    } else {
      handleSaveModifiedData();
    }
  };

  const saveModifiedData = async (
    editBusiness: TokenService.BusinessToken['details'],
    locations: Array<TokenService.BusinessToken['details']['location'][number]>,
  ) => {
    const validatedData = validateBusinessData(
      editBusiness,
      {...businessProfile.initialValidationState()},
      useOptiweigh,
      isAmbioxera,
    );
    const validLocation =
      locationFormRef.current.filter((loc) => {
        return loc && !loc.isValid({forceTouch: true});
      }).length === 0;
    setValidationState(validatedData);
    //need to add back filtered out establishments, ambioxera establishment users cannot add/remove an establishment
    if ((isAmbioxera && userProfile.role.includes('establishment-')) || (isWarakirri && userProfile.role.includes('location-'))) {
      locations = editBusiness.location.map((loc) => {
        if (
          loc.locationUniqueId &&
          loc.locationUniqueId === locations[0].locationUniqueId
        ) {
          return locations[0];
        }
        return loc;
      });
    }
    if (isAllowedtoSubmit(validatedData) && validLocation) {
      if (locations && !isAmbioxera) {
        try {
          await validateCredential(locations, dispatch);
        } catch (e) {
          let err = Array.isArray(e)
            ? e.map((er) => `Location ${er.index}: ${er.details?.join(', ')}`)
            : [e.details];
          dispatch(
            toggleModal({
              status: 'failed',
              title: 'Location Validation Failed',
              subtitle: err.join('\n'),
            }),
          );
          return;
        }
      }
      let updateOptiweigh:
        | undefined
        | BusinessMisc.AnimalBusinessInterface['optiweigh'] = undefined;
      if (
        businessProfile.checkOptiweighModified(
          businessProfileDoc as BusinessMisc.AnimalBusinessInterface,
          useOptiweigh,
        ) &&
        editBusiness.industryType === 'ANIMALS'
      ) {
        updateOptiweigh = {
          ...editBusiness['optiweigh'],
          interval:
            editBusiness['optiweigh']?.interval.replace(/\D+/, '') ?? '',
        };
      }
      const updatedLinkedSites = Object.values(
        updatedLinkedSitesRef.current,
      )?.filter((updatedSite) => {
        return !!siteTokens?.find(
          (site) =>
            site.externalIds[0].agliveToken === updatedSite.agliveToken &&
            site.details.location !== updatedSite.newPICAddress,
        );
      }) ?? [];

      dispatch(
        updateBusinessProfile({
          isLivestock: businessProfile.isLivestock(),
          updatedBusinessData: {
            ...editBusiness,
            location: locations,
            isAmbioxera,
            isWarakirri,
          },
          deleteLocation,
          initialBusinessData: businessProfileDoc,
          deleteLicense,
          optiweigh: {
            ...updateOptiweigh,
            delete: businessProfile.isOptiweigh() && !useOptiweigh,
          },
          updatedLinkedSites: updatedLinkedSites,
        }),
      );
    }
  };

  const handleSaveModifiedData = async () => {
    try {
      if (
        businessProfile.isLivestock() &&
        !editBusiness.hasOwnProperty('businessCountry')
      ) {
        editBusiness['businessCountry'] = 'Australia';
      }
      const locations = editBusiness?.location
        ?.filter(
          (l) =>
            (!isAmbioxera && !isWarakirri) ||
            ['admin', 'user'].includes(userProfile.role) ||
            userProfile.role.includes(l.locationUniqueId),
        )
        .map((l, i) => {
          let formData = locationFormRef.current[i].getValues();
          formData['locationNickname'] = formData['locationNickname'].trim();
          if (l['permits']) {
            const permitList = Array.isArray(l['permits'])
              ? l['permits']
              : [l['permits']];
            const lastPermit = permitList[permitList.length - 1];
            //replacing unexpired permit
            if (
              formData['permits'] &&
              validPermits.unexpired.includes(lastPermit)
            ) {
              let permitCopy = [...permitList];
              permitCopy[permitCopy.length - 1] = formData['permits'];
              formData['permits'] = permitCopy;
            } else if (lastPermit !== formData['permits']) {
              //replace expired permit
              formData['permits'] = [...permitList, formData['permits']];
            }
          }
          if (formData['permits'] && !Array.isArray(formData['permits'])) {
            formData['permits'] = [formData['permits']];
          }
          return {...l, ...formData};
        });

      if (editBusiness.industryType === 'PLANTS') {
        let errorMessages = [];
        let errorTitle = 'Error';
        const odcLicenses = editBusiness.licenses.filter(
          (license) => license.name === 'ODC',
        ) as Array<OdcLicense>;

        const duplicateLicenseResult = checkDuplicateLicenseNumber(
          editBusiness.licenses,
        );

        if (odcLicenses.length === 0) {
          errorMessages.push('At least one ODC License is required');
        } else if (
          odcLicenses.flatMap((license) => license.permits).length === 0
        ) {
          errorMessages.push('At least one ODC Permit is required');
        } else if (duplicateLicenseResult.ODC.length) {
          errorTitle = 'Duplicate ODC License';
          errorMessages.push('This number already exists');
          errorMessages.push(duplicateLicenseResult.ODC.join(', '));
        } else if (duplicateLicenseResult.GMP.length) {
          errorTitle = 'Duplicate GMP License';
          errorMessages.push('This number already exists');
          errorMessages.push(duplicateLicenseResult.GMP.join(', '));
        } else if (duplicateLicenseResult.Poison.length) {
          errorTitle = 'Duplicate Poison Permit';
          errorMessages.push('This number already exists');
          errorMessages.push(duplicateLicenseResult.Poison.join(', '));
        }

        if (!errorMessages.length) {
          const permitList = locations
            .map((loc) =>
              loc['permits'] ? loc['permits'][loc['permits'].length - 1] : '',
            )
            .filter((p) => !!p);
          const permitNonDup = new Set(permitList);
          if (permitNonDup.size !== permitList.length) {
            errorMessages.push(
              'The same permit cannot be used for multiple locations',
            );
          }
        }
        if (errorMessages.length) {
          dispatch(
            toggleModal({
              status: 'failed',
              title: errorTitle,
              subtitle: errorMessages,
            }),
          );
          return;
        }

        let linkedPermitChanged = false;
        if (oldOdcPermits.length > 0) {
          const getLinkedPermits = (
            loc: TokenService.BusinessToken['details']['location'][number],
          ) => {
            return (
              loc.country === 'Australia' && {
                pic: loc.PICAddress,
                linkedPermit:
                  !!loc.permits && loc.permits.length > 0
                    ? loc.permits[loc.permits.length - 1]
                    : [],
              }
            );
          };
          const newPics = locations.map(getLinkedPermits);
          const oldPics = businessProfileDoc.location.map(getLinkedPermits);
          linkedPermitChanged = oldPics.some((oldPic) => {
            const changed = newPics?.find(
              (newPic) =>
                newPic.pic === oldPic.pic &&
                _.isEqual(newPic.linkedPermit, oldPic.linkedPermit) === false,
            );
            return changed;
          });
        }

        if (linkedPermitChanged) {
          dispatch(
            toggleModal({
              status: 'warning',
              title: 'Reminder',
              customContent: (
                <DialogContentSubtitle
                  texts={[
                    'Changing linked permit will transfer all plants from the current permit to the new permit.',
                    '\n',
                    'Continue?',
                  ]}
                />
              ),
              renderButton: (
                <Buttons
                  leftButtonTitle={businessLanguage.no}
                  rightButtonTitle={businessLanguage.yes}
                  leftButtonOnClick={() => {
                    dispatch(toggleModalOff());
                  }}
                  rightButtonOnClick={() => {
                    dispatch(toggleModalOff());
                    saveModifiedData(editBusiness, locations);
                  }}
                />
              ),
            }),
          );
        } else {
          saveModifiedData(editBusiness, locations);
        }

      } else if (isWarakirri) {
        const pic_property = locations.map(l => `${l.PICAddress}_${l['property_type']}`);
        const nonDup = new Set(pic_property);
        if (nonDup.size === pic_property.length) {
          saveModifiedData(editBusiness, locations);
        } else {
          let errorMsg: string[] = [];
          pic_property.forEach((pp, i) => {
            //has duplicates
            if (pic_property.lastIndexOf(pp) > i) {
              errorMsg.push(`Duplicate PIC and property: Location ${i+1} and ${pic_property.lastIndexOf(pp) + 1}`)
            }
          })
          dispatch(
            toggleModal({
              status: 'failed',
              title: 'Location Validation Failed',
              subtitle: errorMsg.join(' \n'),
            }),
          );
          return;
        }
      } else {
        saveModifiedData(editBusiness, locations);
      }
    } catch (error) {
      console.error(error);
      if (Array.isArray(error)) {
        const subtitleString: string[] = [];
        error.map((error) => {
          const {details, index} = error;

          if (details) {
            const errorString = `Location ${index + 1}: ${details.join('. ')}`;
            subtitleString.push(errorString);
          } else {
            const errorString = `Location ${index + 1}`;
            subtitleString.push(errorString);
          }
        });
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Location Validation Failed',
            subtitle: subtitleString.join(' \n'),
          }),
        );
      } else {
        console.error(error);
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Location Validation Failed',
            subtitle: 'Something went wrong!',
          }),
        );
      }
    }
  };

  const classes = useStyle();

  useEffect(() => {
    switch (editBusiness.businessCountry) {
      case 'Argentina':
        setBusinessLanguage((pre) => ({...pre, ...CONSTANT.ES_LABELS}));
        break;
      case 'Canada':
      case 'Australia':
      default:
        setBusinessLanguage((pre) => ({...pre, ...CONSTANT.EN_LABELS}));
        break;
    }
  }, [editBusiness.businessCountry]);

  const commonLicenseLabels = useMemo(() => {
    return {
      permitUpdateWarning: businessLanguage.permitUpdateWarning,
      enterNumber: businessLanguage.enterNumber,
      continue: businessLanguage.continue,
      yes: businessLanguage.yes,
      no: businessLanguage.no,
      update: businessLanguage.update,
      deleted: businessLanguage.deleted,
      action: businessLanguage.action,
      required: businessLanguage.requiredField,
      cannptUndone: businessLanguage.cannptUndone,
      delete: businessLanguage.delete,
      cancel: businessLanguage.cancel,
    };
  }, [businessLanguage]);

  return (
    <PageHeader
      config={{
        title: businessLanguage.editBusinessProfile,
        margin: 60,
        back: true,
      }}>
      <Grid container spacing={3} ref={containerRef}>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {businessLanguage.companyName}
          </Typography>
          {props.isEstablishment ? (
            <Typography variant="h6" style={{marginTop: 8}}>
              {businessProfileDoc.companyName}
            </Typography>
          ) : (
            <TextField
              id="CompanyName"
              variant="outlined"
              value={editBusiness.companyName}
              onChange={handleEditState('companyName')}
              error={!!validationState.companyName}
              helperText={validationState.companyName}
            />
          )}
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {editBusiness.businessCountry === 'Canada'
              ? 'Business Number'
              : businessLanguage.companyNumber}
          </Typography>
          {props.isEstablishment ? (
            <Typography variant="h6" style={{marginTop: 8}}>
              {businessProfileDoc.companyNumber}
            </Typography>
          ) : businessProfile.isArgentina() ? (
            <InputMask
              mask={'99-99999999/9'}
              maskChar={null}
              value={editBusiness.companyNumber}
              onChange={handleEditState('companyNumber')}
              placeholder={'99-99999999/9'}>
              {(props) => (
                <TextField
                  {...props}
                  id="CompanyNumber"
                  fullWidth
                  variant="outlined"
                  helperText={validationState.companyNumber}
                  error={!!validationState.companyNumber}
                />
              )}
            </InputMask>
          ) : (
            <TextField
              id="CompanyNumber"
              variant="outlined"
              value={editBusiness.companyNumber}
              onChange={handleEditState('companyNumber')}
              error={!!validationState.companyNumber}
              helperText={validationState.companyNumber}
            />
          )}
        </Grid>
        {/* hide the country field from everyone except Canada */}
        {isLivestock && (
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessLanguage.country}
            </Typography>
            {props.isEstablishment ? (
              <Typography variant="h6" style={{marginTop: 8}}>
                {businessProfileDoc.businessCountry}
              </Typography>
            ) : 
            <FormControl
              variant="outlined"
              error={!!validationState.businessCountry}>
              <InputLabel id={`business-select-country`}>
                {businessLanguage.pleaseSelect}
              </InputLabel>
              <Select
                value={editBusiness.businessCountry ?? 'Australia'}
                label="Please select"
                id="CountrySelect"
                onChange={handleEditState('businessCountry')}>
                {COUNTRY_LIST.map((cty) => (
                  <MenuItem value={cty} key={cty} id={`CountrySelect${cty}`}>
                    {cty}
                  </MenuItem>
                ))}
              </Select>
              {!!validationState.businessCountry && (
                <FormHelperText>
                  {validationState.businessCountry}
                </FormHelperText>
              )}
            </FormControl>}
          </Grid>
        )}
        {businessProfile.isArgentina() && (
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessLanguage.CUIG}
            </Typography>
            {props.isEstablishment ? (
              <Typography variant="h6" style={{marginTop: 8}}>
                {businessProfileDoc.cuig}
              </Typography>
            ) : (
              <TextField
                id="CUIG"
                variant="outlined"
                value={editBusiness.cuig}
                onChange={handleEditState('cuig')}
                error={!!validationState.cuig}
                helperText={validationState.cuig}
                placeholder="AB000"
              />
            )}
          </Grid>
        )}

        {editBusiness.industryType === 'PLANTS' && (
          <Grid container item spacing={3} id={CONSTANT.ODC_LICENSE_HASH}>
            <EditLicenseForm
              licenses={plantLicenses['ODC']}
              licenseValidations={(validationState as any).odcLicense}
              onAddLicense={handleAddLicense('ODC')}
              onUpdateLicense={handleUpdateLicense}
              onDeleteLicense={handleDeleteLicense('ODC')}
              onDeletePermit={handleDeletePermit('ODC')}
              onSavePermit={handleSavePermit}
              onAddPermit={handleAddPermit('ODC')}
              onEditing={handleLicenseEditingState}
              labels={{
                ...commonLicenseLabels,
                license: businessLanguage.odcLicense,
                licenseNumber: businessLanguage.odcLicenseNumber,
                addLicenseAndPermit: businessLanguage.addOdcLicenseAndPermit,
                licenseStartDate: businessLanguage.odcLicenseStartDate,
                licenseExpiryDate: businessLanguage.odcLicenseExpiryDate,
                permits: businessLanguage.odcPermits,
                permitPlantLimit: businessLanguage.plantsLimit,
                permitNumber: businessLanguage.permitNumber,
                permitStarDate: businessLanguage.startDate,
                permitExpiryDate: businessLanguage.expiryDate,
                addPermit: businessLanguage.addOdcPermit,
                thcValue: businessLanguage.thcValue,
              }}
            />
            {editBusiness.subIndustryType === 'Cannabis' ? (
              <>
                <EditLicenseForm
                  licenses={plantLicenses['GMP']}
                  licenseValidations={(validationState as any).gmpLicense}
                  onAddLicense={handleAddLicense('GMP')}
                  onUpdateLicense={handleUpdateLicense}
                  onDeleteLicense={handleDeleteLicense('GMP')}
                  onDeletePermit={handleDeletePermit('GMP')}
                  onSavePermit={handleSavePermit}
                  onAddPermit={handleAddPermit('GMP')}
                  onEditing={handleLicenseEditingState}
                  labels={{
                    ...commonLicenseLabels,
                    license: businessLanguage.gmpLicense,
                    licenseNumber: businessLanguage.gmpLicenseNumber,
                    addLicenseAndPermit:
                      businessLanguage.addGmpLicenseAndCertificates,
                    licenseStartDate: businessLanguage.gmpLicenseStartDate,
                    licenseExpiryDate: businessLanguage.gmpLicenseExpiryDate,
                    permits: businessLanguage.gmpCertificates,
                    permitNumber: businessLanguage.certificateNumber,
                    permitStarDate: businessLanguage.startDate,
                    permitExpiryDate: businessLanguage.expiryDate,
                    addPermit: businessLanguage.addGmpCertificate,
                  }}
                />
                <EditLicenseForm
                  licenses={plantLicenses['Poison']}
                  licenseValidations={(validationState as any).poisonPermit}
                  onAddLicense={handleAddLicense('Poison')}
                  onUpdateLicense={handleUpdateLicense}
                  onDeleteLicense={handleDeleteLicense('Poison')}
                  labels={{
                    ...commonLicenseLabels,
                    license: businessLanguage.poisonPermit,
                    licenseNumber: businessLanguage.poisonPermitNumber,
                    addLicenseAndPermit: businessLanguage.addPoisonPermit,
                    licenseStartDate: businessLanguage.poisonPermitStartDate,
                    licenseExpiryDate: businessLanguage.poisonPermitExpiryDate,
                  }}
                />
              </>
            ) : null}
          </Grid>
        )}
        <Grid container item>
          <Typography
            variant="h2"
            role="label"
            style={{marginTop: '80px'}}
            id={CONSTANT.LOCATION_HASH}>
            {businessLanguage[`my${placeType}`]}{' '}
            {businessProfile.isLivestock() &&
              !businessProfile.isArgentina() && <>e.g. PIC/PID</>}
          </Typography>
        </Grid>
        {!!editBusiness?.location?.length &&
          editBusiness?.location
            .filter(
              (l) =>
                !(isAmbioxera || isWarakirri) ||
                ['admin', 'user'].includes(userProfile.role) ||
                userProfile.role.includes(l.locationUniqueId),
            )
            .map((location, index) => (
              <Grid
                item
                xs={12}
                key={'location' + index}
                aria-label={'location-item'}>
                <div className={classes.titleContainer}>
                  <ListItem
                    id={`LocationCollapse${index}`}
                    style={{paddingLeft: 0}}
                    button
                    onClick={handleCollapse('location', index)}>
                    <Grid container justifyContent="space-between">
                      <Typography variant="h3" role="label">
                        {location.locationNickname}
                      </Typography>
                      {collapse[index]?.location ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </Grid>
                  </ListItem>
                </div>
                <Collapse in={collapse[index]?.location}>
                  <Grid container direction="column">
                    <Grid container item xs={12} justifyContent="flex-end">
                      <IconButton
                        id={`DeleteLocation${index}`}
                        onClick={() =>
                          dispatch(
                            toggleModal({
                              status: 'warning',
                              title: `${businessLanguage.delete}?`,
                              subtitle: businessLanguage.cannptUndone,
                              renderButton: (
                                <Buttons
                                  leftButtonTitle={businessLanguage.cancel}
                                  rightButtonTitle={businessLanguage.delete}
                                  leftButtonOnClick={() => {
                                    dispatch(toggleModalOff());
                                  }}
                                  rightButtonOnClick={() => {
                                    dispatch(toggleModalOff());
                                    handleRemoveLocation(index);
                                  }}
                                />
                              ),
                            }),
                          )
                        }>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid container style={{marginTop: 24}} spacing={3}>
                      <FormGenerator
                        ref={(el) => (locationFormRef.current[index] = el)}
                        language={
                          editBusiness.businessCountry === 'Argentina'
                            ? 'es'
                            : 'en'
                        }
                        formInputs={[
                          businessInputs(
                            `${placeType.toLowerCase()}Nickname`,
                            location.locationNickname,
                            false,
                            editBusiness.businessCountry !== 'Argentina',
                            undefined,
                            undefined,
                            undefined,
                            (val) =>
                              handleEditPIC(
                                index,
                                `${placeType.toLowerCase()}Nickname`,
                                val,
                              ),
                          ),
                          businessInputs(
                            getPicKey({
                              country: editBusiness.businessCountry,
                              isLivestock,
                              placeType,
                            }),
                            location.locationId || location.PICAddress,
                            false,
                            editBusiness.businessCountry !== 'USA' &&
                              isLivestock,
                            editBusiness.industryType === 'PLANTS',
                            !isAmbioxera && !isWarakirri ? (val) => {
                              const res = editBusiness.location.findIndex(
                                (o, idx) => {
                                  if (val && val.trim().length) {
                                    const id = o.locationId || o.PICAddress;
                                    return id.trim() == val?.trim() && index !== idx
                                  }
                                  return false;
                                }
                              ) > -1;
                              return {result: res, error: isLivestock && editBusiness.businessCountry !== 'USA' ? businessLanguage.duplicatePIC : 'This location ID already exists'}
                            } : undefined,
                            undefined,
                            (val) =>
                              handleEditPIC(
                                index,
                                getPicKey({
                                  country: editBusiness.businessCountry,
                                  isLivestock,
                                  placeType,
                                }),
                                val,
                              ),
                          ),
                          businessInputs(
                            'linkedPermit',
                            location['permits']
                              ? location['permits'][
                                  location['permits'].length - 1
                                ]
                              : '',
                            false,
                            oldOdcPermits.length > 0,
                            editBusiness.industryType !== 'PLANTS',
                            undefined, 
                            location['permits'] && !validPermits.unexpired.includes(location['permits'][location['permits'].length - 1]) 
                              ? [{label: validPermits.mapping[location['permits'][location['permits'].length - 1]], disabled: true, value: location['permits'][location['permits'].length - 1]}, ...validPermits.valid] 
                              : validPermits.valid),
                          businessInputs('country', location.country ?? 'Australia', false, isLivestock, isAmbioxera, undefined, editBusiness.industryType === 'PLANTS' && editBusiness.subIndustryType === 'Cannabis' ? [ { label: 'Australia', value: 'Australia', disabled: false } ]  : undefined ),
                          businessInputs('state', location.state, false, isLivestock, isAmbioxera),
                          businessInputs('town', location.town, false, isLivestock, isAmbioxera),
                          businessInputs('postcode', location.postcode, false, isLivestock, isAmbioxera),
                          businessInputs('region', location['region'], false, !isWarakirri, !isWarakirri, undefined, regions.map((r) => ({label: r, value: r}))),
                          businessInputs('property', location['property_type'], false, !isWarakirri, !isWarakirri, undefined, propertyTypes.map((p) => ({label: p, value: p}))),
                          businessInputs('address', location.address, false, isLivestock, isAmbioxera),
                          {type: 'DIVIDER', skip: (!isLivestock && !isAmbioxera) || location.country !== 'Australia', name: 'divider'},
                          businessInputs('NLISUserID', location['NLISUserID'], false, false, (!isLivestock && !isAmbioxera) || location.country !== 'Australia', undefined, undefined, undefined, !isAmbioxera || userProfile.role.startsWith('establishment-') ? undefined : 'PASSWORD'),
                          businessInputs('NLISUserPassword', location['NLISPassword'], false, false, (!isLivestock && !isAmbioxera) || location.country !== 'Australia', undefined, undefined, undefined, !isAmbioxera || userProfile.role.startsWith('establishment-') ? undefined : 'PASSWORD'),
                          businessInputs('LPAUserID', location['LPAUserID'], false, false, !isLivestock || location.country !== 'Australia'),
                          businessInputs('LPAPassword', location['LPAPassword'], false, false, !isLivestock || location.country !== 'Australia'),
                          businessInputs(
                            'locationId',
                            location.locationId,
                            false,
                            false,
                            editBusiness.industryType !== 'PLANTS',
                            (val) => {
                              const res =
                                editBusiness.location.findIndex((o, idx) => {
                                  if (val && val.trim().length) {
                                    const id = o.locationId;
                                    return (
                                      id.trim() == val?.trim() && index !== idx
                                    );
                                  }
                                  return false;
                                }) > -1;
                              return {
                                result: res,
                                error:
                                  isLivestock &&
                                  editBusiness.businessCountry !== 'USA'
                                    ? businessLanguage.duplicatePIC
                                    : 'This location ID already exists',
                              };
                            },
                          ),
                        ]}
                      />
                    </Grid>
                    {(isAmbioxera || isWarakirri) && location.locationUniqueId && (
                      <LocationUsers
                        businessLanguage={businessLanguage}
                        users={businessProfileDoc.users}
                        rolePrefix={placeType.toLowerCase()}
                        locationId={location.locationUniqueId}
                        businessId={userProfile.businessId}
                        businessCountry={defaultLocationCountry}
                        editable={true}
                        currentUser={userProfile.externalIds.userId}
                        addUser={(name, email, phone, invCode) => {
                          dispatch(
                            submitProject(
                              {
                                name,
                                email,
                                phone,
                                role: `${placeType.toLowerCase()}-${
                                  location.locationUniqueId
                                }`,
                                userId: '',
                                status: '',
                                statusChange: '',
                                roleChange: '',
                                invitationCode: invCode,
                              },
                              '',
                              defaultLocationCountry,
                              () => {
                                dispatch(getBusinessProfile());
                              },
                              true,
                            ),
                          );
                        }}
                        inviteUser={(userId, invCode) => {
                          dispatch(
                            inviteUserAgain(
                              invCode,
                              userId,
                              defaultLocationCountry,
                              () => {
                                dispatch(getBusinessProfile());
                              },
                            ),
                          );
                        }}
                        editUser={(userId, type, userInfo) => {
                          dispatch(
                            editUser(
                              userId,
                              userProfile.businessId,
                              userInfo,
                              type,
                              () => {
                                dispatch(getBusinessProfile());
                              },
                            ),
                          );
                        }}
                      />
                    )}
                  </Grid>
                </Collapse>
              </Grid>
            ))}
        {!props.isEstablishment && (
          <Grid container item style={{marginTop: 64}}>
            <MyButton
              text={businessLanguage[`add${placeType}`]}
              variant="outlined"
              onClick={() => handleAddLocation()}
            />
          </Grid>
        )}
        {businessProfile.isLivestock() && ['admin','user'].includes(userProfile.role) && (
          <>
            <Grid container item>
              <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
                {businessLanguage.CattleIdentification}
              </Typography>
            </Grid>
            <Grid container item style={{marginTop: 16}} spacing={3}>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessLanguage.brandDescription}
                </Typography>
                <TextField
                  id="Brand"
                  variant="outlined"
                  value={editBusiness.brandDescription}
                  onChange={handleEditState('brandDescription')}
                  error={!!validationState.brandDescription}
                  helperText={validationState.brandDescription}
                />
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessLanguage.cattleBrandSymbol}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  <img
                    style={{maxWidth: 223, maxHeight: 110}}
                    src={businessProfileDoc.imageUri}
                    alt=""
                  />
                </Typography>
              </Grid>
            </Grid>
            {!businessProfile.isArgentina() && ['admin','user'].includes(userProfile.role) && (
              <Box mt={5}>
                <Grid container spacing={3}>
                  <Grid container item>
                    <Typography
                      variant="h2"
                      role="label"
                      style={{marginTop: 80}}>
                      Optiweigh Settings
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} style={{marginTop: 30}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={useOptiweigh}
                          onChange={() => {
                            setUseOptiweigh(!useOptiweigh);
                          }}
                          name="useOptiweigh"
                          color="primary"
                          id="useOptiweighSwitch"
                        />
                      }
                      label="Use Optiweigh"
                    />
                  </Grid>
                </Grid>
                {useOptiweigh && editBusiness.industryType === 'ANIMALS' && (
                  <Grid container spacing={3}>
                    <Grid item container xs={4} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Optiweigh Client ID
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={editBusiness.optiweigh?.clientId ?? ''}
                        onChange={handleEditState('clientId', 'optiweigh')}
                        error={!!validationState.optiweigh?.clientId}
                        helperText={validationState.optiweigh?.clientId}
                        id="OptiweighClientId"
                      />
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Optiweigh API Key
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={editBusiness.optiweigh?.apiKey ?? ''}
                        onChange={handleEditState('apiKey', 'optiweigh')}
                        error={!!validationState.optiweigh?.apiKey}
                        helperText={validationState.optiweigh?.apiKey}
                        id="OptiweighApiKey"
                      />
                    </Grid>
                    <Grid item container xs={4} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Update every
                      </Typography>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={!!validationState.optiweigh?.interval}>
                        <Select
                          id="IntervalSelect"
                          value={editBusiness.optiweigh?.interval ?? ''}
                          onChange={handleEditInterval}>
                          {INTERVAL_OPTIONS.map((ivl) => (
                            <MenuItem value={ivl} key={ivl} data-cy={ivl}>
                              {ivl}
                            </MenuItem>
                          ))}
                        </Select>
                        {validationState.optiweigh?.interval && (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Aglive API Key
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={editBusiness.optiweigh?.agliveKey ?? ''}
                        onChange={handleEditState('agliveKey', 'optiweigh')}
                        error={!!validationState.optiweigh?.agliveKey}
                        helperText={validationState.optiweigh?.agliveKey}
                        id="AgliveApiKey"
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </>
        )}
        <Grid item container justifyContent="flex-end" style={{marginTop: 200}}>
          <MyButton
            text={businessLanguage.save}
            variant="contained"
            onClick={handleSaveBusinessData}
          />
        </Grid>
      </Grid>
    </PageHeader>
  );
};

function isEmptyOrSpaces(str: string) {
  return str == null || str.match(/^ *$/) !== null;
}

function duplicatedLicenseNumbers(
  licenses: Array<OdcLicense | GmpLicense>,
  type: 'ODC' | 'GMP',
): Array<OdcLicense | GmpLicense> {
  const duplicates = licenses.filter((li, index) => {
    return (
      licenses.findIndex(
        (x) =>
          li.name === type &&
          x.name === type &&
          x.licenseNumber === li.licenseNumber,
      ) !== index
    );
  });
  return [...new Set(duplicates)];
}

function duplicatedPoisionPermit(permits: Array<PoisonPermit>) {
  const duplicates = permits.filter((p, index) => {
    return (
      permits.findIndex((x) => x.permitNumber === p.permitNumber) !== index
    );
  });
  return [...new Set(duplicates)];
}

function checkDuplicateLicenseNumber(
  licenses: Array<OdcLicense | GmpLicense | PoisonPermit>,
) {
  const {odc, gmp, poison} = licenses.reduce(
    (result, li) => {
      if (li.name === 'Poison') {
        result.poison.push(li);
      } else if (li.name === 'GMP') {
        result.gmp.push(li);
      } else if (li.name === 'ODC') {
        result.odc.push(li);
      }
      return result;
    },
    {
      odc: [] as Array<OdcLicense>,
      gmp: [] as Array<GmpLicense>,
      poison: [] as Array<PoisonPermit>,
    },
  );
  const odcDuplicates = duplicatedLicenseNumbers(odc, 'ODC');
  const gmpDuplicates = duplicatedLicenseNumbers(gmp, 'GMP');
  const poisonDuplicates = duplicatedPoisionPermit(poison);
  return {
    ODC: odcDuplicates?.map((li) => li.licenseNumber),
    GMP: gmpDuplicates?.map((li) => li.licenseNumber),
    Poison: poisonDuplicates?.map((p) => p.permitNumber),
  };
}

function validatePoisonPermit(
  poisonPermit: PoisonPermit,
  labels: {required: string},
) {
  let validation = {...INITIAL_PERMIT_VALIDATION_STATE};
  if (poisonPermit.permitNumber.trim() === '') {
    validation.permitNumber = labels.required;
  } else if (!/^[a-zA-Z0-9 ]+$/.test(poisonPermit.permitNumber)) {
    validation.permitNumber = 'Must contains letters or digits only';
  }
  if (!poisonPermit.startDate) {
    validation.permitStartDate = labels.required;
  } else if (!moment(poisonPermit.startDate).isValid()) {
    validation.permitExpiryDate = 'Invalid Date';
  }
  if (!poisonPermit.expiryDate) {
    validation.permitStartDate = labels.required;
  } else if (!moment(poisonPermit.expiryDate).isValid()) {
    validation.permitStartDate = 'Invalid Date';
  } else if (
    poisonPermit.startDate &&
    new Date(poisonPermit.startDate).valueOf() -
      new Date(poisonPermit.expiryDate).valueOf() >
      0
  ) {
    validation.permitExpiryDate = 'Cannot be earlier than Start Date';
  }
  return validation;
}

function validateLicense(
  license: OdcLicense | GmpLicense,
  labels: {required: string},
) {
  let validation = {...INITIAL_LICENSE_VALIDATION_STATE};
  if (license.licenseNumber.trim() === '') {
    validation.licenseNumber = labels.required;
  } else if (!/^[a-zA-Z0-9 ]+$/.test(license.licenseNumber)) {
    validation.licenseNumber = 'Must contains letters or digits only';
  }
  if (!license.startDate) {
    validation.startDate = labels.required;
  } else if (!moment(license.startDate).isValid()) {
    validation.startDate = 'Invalid Date';
  }
  if (!license.expiryDate) {
    validation.expiryDate = labels.required;
  } else if (!moment(license.expiryDate).isValid()) {
    validation.expiryDate = 'Invalid Date';
  } else if (
    license.startDate &&
    new Date(license.startDate).valueOf() -
      new Date(license.expiryDate).valueOf() >=
      0
  ) {
    validation.expiryDate = 'Expiry date should be after the start date';
  }
  return validation;
}

function validateBusinessData(
  business: TokenService.BusinessToken['details'],
  validationState: initialStateType,
  useOptiweigh?: boolean,
  isAmbioxera?: boolean,
) {
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const isLivestock = business.industryType === 'ANIMALS';
  const isArgentina = business.businessCountry === 'Argentina';
  const requiredField =
    business.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS.requiredField
      : CONSTANT.EN_LABELS.requiredField;
  const displayLanguage =
    business.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  // Validate company name
  if (business.companyName.trim() === '') {
    validationState.companyName = requiredField;
  }
  // else if (!/^[a-zA-Z0-9 ]+$/.test(business.companyName))
  //   validationState.companyName = 'Must contains letters or digits only';
  // Validate company number
  if (
    business.businessCountry !== 'USA' &&
    String(business.companyNumber).trim() === ''
  ) {
    validationState.companyNumber = requiredField;
  } else if (
    !isArgentina &&
    business.businessCountry !== 'USA' &&
    String(business.companyNumber).length > 0 &&
    !CONSTANT.COMPANY_NUMBER_REGEX['Australia'].test(
      String(business.companyNumber),
    )
  ) {
    validationState.companyNumber = 'Must be only digits';
  } else if (
    isArgentina &&
    !CONSTANT.COMPANY_NUMBER_REGEX['Argentina'].test(
      String(business.companyNumber),
    )
  ) {
    validationState.companyNumber = displayLanguage.validCUIT;
  }
  /**Validate CUIG */
  if (isArgentina && String(business.cuig).trim() === '') {
    validationState.cuig = requiredField;
  } else if (isArgentina && !CONSTANT.CUIG_REGEX.test(String(business.cuig))) {
    validationState.cuig = displayLanguage.validCUIG;
  }
  // Validate business country

  if (isLivestock) {
    // Validation for livestock fields
    // Validate brand description
    // if (!isArgentina && business.brandDescription?.trim() === '') {
    //   validationState.brandDescription = requiredField;
    // }
    if (useOptiweigh && business.industryType === 'ANIMALS') {
      ['clientId', 'apiKey', 'agliveKey', 'interval'].forEach((key) => {
        validationState.optiweigh[key as keyof initialStateType['optiweigh']] =
          !business.optiweigh?.[key as keyof initialStateType['optiweigh']] ||
          business.optiweigh?.[
            key as keyof initialStateType['optiweigh']
          ].trim() === ''
            ? requiredField
            : '';
      });
    }
    if (business.businessCountry.trim() === '') {
      validationState.businessCountry = requiredField;
    }
  } else if (business.industryType === 'PLANTS' && validationState.licenses) {
    // Validation for non-livestock fields
    // Validate license number
    business.licenses
      .filter((license) => license.name === 'ODC')
      .forEach((license, index) => {
        if (license.name === 'ODC') {
          let licenseValidationState = (validationState as any).odcLicense[
            index
          ];
          if (licenseValidationState) {
            (validationState as any).odcLicense[index] = validateLicense(
              license,
              {required: requiredField},
            );
          }
        }
      });
    business.licenses
      .filter((license) => license.name === 'GMP')
      .forEach((license, index) => {
        if (license.name === 'GMP') {
          let licenseValidationState = (validationState as any).gmpLicense[
            index
          ];
          if (licenseValidationState) {
            (validationState as any).gmpLicense[index] = validateLicense(
              license,
              {required: requiredField},
            );
          }
        }
      });
    business.licenses
      .filter((license) => license.name === 'Poison')
      .forEach((license, index) => {
        if (license.name === 'Poison') {
          let licenseValidationState = (validationState as any).poisonPermit[
            index
          ];
          if (licenseValidationState) {
            (validationState as any).poisonPermit[index] = validatePoisonPermit(
              license,
              {required: requiredField},
            );
          }
        }
      });
  }
  // Validate location
  if (business.location?.length > 0 && false) {
    business.location?.forEach((location, index) => {
      // Validate location nickname
      if (!isArgentina) {
        if (location.locationNickname.trim() === '')
          validationState.location[index].locationNickname = requiredField;
      }

      // Validate country
      if (
        !isAmbioxera &&
        (location.country?.trim() === '' || !location.country)
      )
        validationState.location[index].country = requiredField;

      if (isLivestock) {
        const idType = location.country === 'USA' ? 'locationId' : 'PICAddress';
        // Validation for livestock location fields
        // Validate state
        if (location.state.trim() === '')
          validationState.location[index].state = requiredField;
        // Validate location postcode
        if (!location.postcode.trim()) {
          if (!isArgentina) {
            validationState.location[index].postcode = requiredField;
          }
        } else if (
          !CONSTANT.POSTCODE_REGEX[location.country as string]?.test(
            location.postcode,
          )
        ) {
          validationState.location[index].postcode =
            CONSTANT.POSTCODE_REGEX_WARNING[location.country as string];
        }
        // Validate location town
        if (location.town.trim() === '')
          validationState.location[index].town = requiredField;

        // Validate location town
        if (location.address.trim() === '')
          validationState.location[index].address = requiredField;

        // Validate picaddress
        if (
          !isArgentina &&
          location.country !== 'USA' &&
          location.PICAddress?.trim() === ''
        ) {
          validationState.location[index].PICAddress = requiredField;
        } else if (
          location.country === 'Argentina' &&
          !CONSTANT.RENSPA_REGEX.test(location.PICAddress)
        ) {
          validationState.location[index].PICAddress =
            displayLanguage.validRenspa;
        } else if (
          location[idType] &&
          business.location.findIndex(
            (o, idx) =>
              o[idType].trim() == location[idType]?.trim() && index !== idx,
          ) > -1
        ) {
          validationState.location[index][idType] =
            displayLanguage.duplicatePIC;
        }
        // Validate credentials
        // LPA username and password
        if (
          location.country === 'Australia' &&
          !isEmptyOrSpaces(location.LPAUserID) &&
          isEmptyOrSpaces(location.LPAPassword)
        ) {
          validationState.location[index].LPAPassword =
            CONSTANT.LOCATION_CREDENTIAL_WARNINGS.LPAPassword;
        } else if (
          location.country === 'Australia' &&
          !isEmptyOrSpaces(location.LPAPassword) &&
          isEmptyOrSpaces(location.LPAUserID)
        ) {
          validationState.location[index].LPAUserID =
            CONSTANT.LOCATION_CREDENTIAL_WARNINGS.LPAUserID;
        }
        // NLIS username and password
        if (
          location.country === 'Australia' &&
          !isEmptyOrSpaces(location.NLISUserID) &&
          isEmptyOrSpaces(location.NLISPassword)
        ) {
          validationState.location[index].NLISPassword =
            CONSTANT.LOCATION_CREDENTIAL_WARNINGS.NLISPassword;
        } else if (
          location.country === 'Australia' &&
          !isEmptyOrSpaces(location.NLISPassword) &&
          isEmptyOrSpaces(location.NLISUserID)
        ) {
          validationState.location[index].NLISUserID =
            CONSTANT.LOCATION_CREDENTIAL_WARNINGS.NLISUserID;
        }
      } else {
        // Validation for non-livestock location fields
        // Validate location id
        if (
          location.locationId &&
          location.locationId.trim() !== '' &&
          !/^[a-zA-Z0-9 ]+$/.test(location.locationId)
        ) {
          validationState.location[index].locationId =
            'Must contains letters or digits only';
        }
        if (
          location.locationId &&
          index !=
            business.location.findIndex(
              (o) => o.locationId == location.locationId?.trim(),
            )
        ) {
          validationState.location[index].locationId =
            'This location ID already exists';
        }
        // Validate picaddress
        // if (location.PICAddress.trim() === '')
        //   validationState.location[index].PICAddress = 'This field is required';

        // Validate location postcode
        if (
          location.postcode.trim() !== '' &&
          !/^[0-9]+$/.test(location.postcode)
        )
          validationState.location[index].postcode = 'Must be only digits';
      }
    });
  }
  return validationState;
}

function hasUnsavedData(unsavedState: LicenseUnsavedState) {
  return Object.values(unsavedState).some((unsavedState) => unsavedState);
}

function initEditBusinessData(
  businessProfileDoc: TokenService.BusinessToken['details'],
) {
  if (businessProfileDoc.industryType !== 'PLANTS') {
    return businessProfileDoc;
  }
  if (businessProfileDoc.licenses) {
    return businessProfileDoc;
  }
  const result = {...businessProfileDoc, licenses: []};
  return result;
}

// if any of the entries is true we know that an error is triggered
function isAllowedtoSubmit(business: initialStateType) {
  return !Object.entries(business).some(([key, value]) => {
    if (key === 'location') {
      return business.location.some((location) =>
        Object.values(location).some((value) => value),
      );
    } else if (key === 'odcLicense' || key === 'gmpLicense') {
      const licenses = (business as any)[key];
      return licenses.some((license) => {
        const noError = Object.entries(license).some(([key, value]) => {
          return key === 'permits'
            ? Object.values(value).some((permit) =>
                Object.values(permit).some((x) => x),
              )
            : value;
        });
        return noError;
      });
    } else if (key === 'poisonPermit') {
      return (value as any[]).some((permit) =>
        Object.values(permit).some((fieldValue) => fieldValue),
      );
    } else if (typeof value === 'object') {
      return Object.values(business.optiweigh).some((value) => value);
    } else {
      return value;
    }
  });
}

type GetPicKeyProps = {
  country: TokenService.BusinessToken['details']['businessCountry'];
  isLivestock: boolean;
  placeType: string;
};
function getPicKey({country, isLivestock, placeType}: GetPicKeyProps) {
  return country === 'Argentina'
    ? 'RENSPA'
    : isLivestock
    ? country === 'USA'
      ? 'locationId'
      : 'PICAddress'
    : `${placeType.toLowerCase()}Id`;
}

export default EditBusinessProfile;
