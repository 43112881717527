import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import { Buttons } from '../../../presentation/ButtonsGroup';

import CreateProductTabDisplay from './CreateProductTabDisplay';
import CreateProductTabContent from './CreateProductTabContent';
import CreateProductTabAttachment from './CreateProductTabAttachment';

import SmallMockupPhone from '../../../presentation/SmallMockupPhone';
import MockupPhoneDetails from './MockUpPhoneDetails';

import COLOR from '../../../styled/colors';

import { TokenService } from '@aglive/data-model';
import { RecursivePartial } from '../../../utils/type';

import useStyle from './styles';
import { useAppDispatch } from '../../../utils/hooks';
import { toggleModal, toggleModalOff } from '../../../store/modal/actions';

type CreateProductProfileDetailsType = {
  detailsArr: TokenService.ProductToken['details']['pages'];
  validationState: RecursivePartial<TokenService.ProductToken['details']['pages'][number]>[];
  focusedPageIndex: number;
  focusedTabIndex: number;

  onPageAdd: () => void;
  onPageSelect: (arg0: number) => void;
  onPageDuplicate: (arg0: number) => void;
  onPageDelete: (arg0: number) => void;
  onPageMediaSelect: (arg0: number, key: TokenService.ProductToken["details"]["pages"][number]["display"]["item"], value: string) => void;
  onPageEdit: (
    arg0: number, 
    key: Array<number | string>,
    value: any
    ) => void;
  onTabSelect: (arg0: number) => void;
  onContentAdd: (arg0: number) => void;
  onContentDelete: (arg0: number, arg1: number) => void;
  onContentDup: (arg0: number, arg1: number) => void;

  onAttachmentAdd: (arg0: number) => void;
  onAttachmentDelete: (arg0: number, arg1: number) => void;
}

export enum DetailsTabMap {
  display,
  content,
  attachment,
}

const TabKeys = [
  'Main Display',
  'Additional Content',
  'Attachments'
];

const CreateProductProfileDetails: React.FC<CreateProductProfileDetailsType> = ({
  detailsArr,
  validationState,
  focusedPageIndex,
  focusedTabIndex,
  onPageAdd,
  onPageSelect,
  onPageDuplicate,
  onPageDelete,
  onPageMediaSelect,
  onPageEdit,
  onTabSelect,
  onContentAdd,
  onContentDelete,
  onContentDup,
  onAttachmentAdd,
  onAttachmentDelete
}) => {
  const classes = useStyle();
  const dispatch = useAppDispatch();

  const onPageDeleteHandler = () => {
    if (detailsArr.length !== 1) {
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'Delete Page?',
          subtitle:
            'This action cannot be undone',
          renderButton: (
            <Buttons
              leftButtonTitle="No"
              rightButtonTitle="Yes"
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
              }}
              rightButtonOnClick={() => {
                onPageDelete(focusedPageIndex);
                dispatch(toggleModalOff());
              }}
            />
          ),
        }),
      );
    }
  }

  return (
    <>
      <Grid item xs={6} md={3} lg={2} xl={1} container direction="column" className={classes.sidePanelContainer} id="ProductDetailsPages">
        <Typography variant="h6" role="label" style={{fontWeight: 600}}>
          Select page to preview
        </Typography>
        <Grid
          item
          container
          direction="column"
          className={classes.pagesPreviewContainer}>
          <Grid item>
            <AddCircleIcon
              style={{paddingRight: 24}}
              onClick={onPageAdd}
              id="AddPage"
            />
            <FileCopyIcon
              style={{paddingRight: 24}}
              onClick={() => onPageDuplicate(focusedPageIndex)}
            />
            <DeleteIcon
              style={{ opacity: detailsArr.length === 1 ? 0.6 : 1 }}
              onClick={onPageDeleteHandler}
              id="DeletePage"
            />
          </Grid>
          <Grid item className={classes.pagesContainer}>
            {detailsArr.map((page, index) => (
              <SmallMockupPhone
                key={index}
                onClick={() => onPageSelect(index)}
                index={index}
                isFocused={focusedPageIndex === index}
                page={page}
                isShowHistory={false}
                id={`SmallMockupPhone${index}`}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={9} lg={4} xl={3} style={{paddingLeft: 20}}>
        <MockupPhoneDetails
          mediaType={detailsArr[focusedPageIndex].display.item}
          mediaUrl={detailsArr[focusedPageIndex].display.url}

          headline1={detailsArr[focusedPageIndex].headline[0]}
          headline2={detailsArr[focusedPageIndex].headline[1]}
          headline3={detailsArr[focusedPageIndex].headline[2]}

          contentArr={detailsArr[focusedPageIndex].content}

          attachmentArr={detailsArr[focusedPageIndex].attachment}
        />
      </Grid>

      <Grid item xs={12} lg={6} xl={8} id="ProductDetailsForm">
        <Tabs
          tabHeaders={TabKeys as string[]}
          value={focusedTabIndex}
          setValue={onTabSelect}>
          <TabPanel
            isShown={focusedTabIndex === DetailsTabMap.display}
            index={DetailsTabMap.display}>
            <Box p={4} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 'none', height: 600, overflowY: 'scroll'}}>
              <CreateProductTabDisplay
                mediaOption={detailsArr[focusedPageIndex].display.item}
                mediaUrl={detailsArr[focusedPageIndex].display.url}
                headline1={detailsArr[focusedPageIndex].headline[0]}
                headline2={detailsArr[focusedPageIndex].headline[1]}
                headline3={detailsArr[focusedPageIndex].headline[2]}
                onPageMediaSelect={(key, value) => onPageMediaSelect(focusedPageIndex, key, value)}
                onChange={(keys, value) => onPageEdit(focusedPageIndex, keys, value)}
                validationState={validationState[focusedPageIndex]}
              />
            </Box>
          </TabPanel>
          <TabPanel
            isShown={focusedTabIndex === DetailsTabMap.content}
            index={DetailsTabMap.content}>
            <Box p={4} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 'none', height: 800, overflowY: 'scroll'}}>
              <CreateProductTabContent
                content={detailsArr[focusedPageIndex].content}
                onAdd={() => onContentAdd(focusedPageIndex)}
                onDelete={(targetSubheadingIndex) => onContentDelete(focusedPageIndex, targetSubheadingIndex)}
                onDup={(targetSubheadingIndex) => onContentDup(focusedPageIndex, targetSubheadingIndex)}
                onChange={(keys, value) => onPageEdit(focusedPageIndex, keys, value)}
              />
            </Box>
          </TabPanel>
          <TabPanel
            isShown={focusedTabIndex === DetailsTabMap.attachment}
            index={DetailsTabMap.attachment}>
            <Box p={4} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 'none', height: 800, overflowY: 'scroll'}}>
              <CreateProductTabAttachment
                attachment={detailsArr[focusedPageIndex].attachment}
                onAdd={() => onAttachmentAdd(focusedPageIndex)}
                onDelete={(targetAttachmentIndex) => onAttachmentDelete(focusedPageIndex, targetAttachmentIndex)}
                onChange={(keys, value) => onPageEdit(focusedPageIndex, keys, value)}
              />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
}

export default CreateProductProfileDetails;
