import React, {CSSProperties} from 'react';
import {withStyles, Theme, createStyles, alpha} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import COLOR from '../styled/colors';
import {Typography} from '@material-ui/core';

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      color: 'inherit',
      display: 'table-row',
      verticalAlign: 'middle',
      '&$hover:hover': {
        backgroundColor: alpha(COLOR.GREEN_LIGHT, 0.1),
      },
      '&$selected, &$selected:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
      },
    },
    hover: {},
    selected: {
      outline: `2px solid ${COLOR.GREEN_HIGHLIGHT}`,
    },
  }),
)(TableRow);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    // TODO Fix TS error
    head: {
      backgroundColor: COLOR.GRAY_SOLID,
      fontFamily: theme.typography.h6.fontFamily,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

type MyTableProps = {
  heads: Array<string>;
  rows?: Array<Array<string | React.ReactNode>>;
  tableBody?: JSX.Element;
  firstColumnWidth?: any;
  dataEmptyStyle?: CSSProperties;
};

const DefaultEmptyStyle: CSSProperties = {
  width: '100%',
  paddingTop: 200,
  paddingBottom: 200,
  textAlign: 'center',
};

const MyTable: React.FC<MyTableProps> = ({
  rows,
  heads,
  tableBody,
  firstColumnWidth,
  dataEmptyStyle = DefaultEmptyStyle,
}) => {
  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {heads.map((title) => (
              <StyledTableCell align="left" key={title}>
                {title}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(([name, ...row], index) => (
            <TableRow key={index}>
              <StyledTableCell
                component="td"
                scope="row"
                className={firstColumnWidth}>
                {name}
              </StyledTableCell>
              {row?.map((data, index) => (
                <StyledTableCell align="left" key={index}>
                  {data}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
          {tableBody}
        </TableBody>
      </Table>
      {rows?.length === 0 && (
        <div style={dataEmptyStyle}>
          <Typography variant="h6">
            There is no items to be displayed!
          </Typography>
        </div>
      )}
    </TableContainer>
  );
};

export default MyTable;
