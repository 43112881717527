import {ThcValues} from './editBusiness/types';
import {INITIAL_PERMIT_VALIDATION_STATE} from './editBusiness/dataTemplate';
import {GmpPermit, OdcPermit} from '@aglive/data-model/dist/misc/business';
import moment from 'moment';

export function getThcValue(permit: OdcPermit): string {
  const result = [];
  if ((permit as OdcPermit).moreThanDefaultThc) {
    result.push('>1% THC' as ThcValues);
  }
  if ((permit as OdcPermit).lessThanDefaultThc) {
    result.push('≤1% THC' as ThcValues);
  }
  return result.join(' and ');
}

export function validatePermit(
  permit: OdcPermit | GmpPermit,
  labels: {required: string},
  maxNumberRequired?: boolean,
) {
  let newState = {...INITIAL_PERMIT_VALIDATION_STATE};
  if (!permit.permitNumber) {
    newState.permitNumber = labels.required;
  } else if (permit.permitNumber.trim() === '') {
    newState.permitNumber = labels.required;
  }

  if (!permit.startDate) {
    newState.permitStartDate = labels.required;
  } else if (!moment(permit.startDate).isValid()) {
    newState.permitStartDate = 'Invalid Date';
  }
  if (!permit.expiryDate) {
    newState.permitExpiryDate = labels.required;
  } else if (!moment(permit.expiryDate).isValid()) {
    newState.permitExpiryDate = 'Invalid Date';
  } else if (
    permit.startDate &&
    new Date(permit.startDate).valueOf() -
      new Date(permit.expiryDate).valueOf() >=
      0
  ) {
    newState.permitExpiryDate = 'Expiry date should be after the start date';
  }
  if (maxNumberRequired) {
    const maxNumber = parseInt(`${(permit as OdcPermit).maxNumber}`);
    if (isNaN(maxNumber)) {
      newState.maxNumber = labels.required;
    } else if (maxNumber <= 0) {
      newState.maxNumber = 'Must be greater than 0';
    }
  }
  return newState;
}
