import React from 'react';

import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import {DashboardWidgetInputSchema} from './Dashboard';
import COLOR from '../../styled/colors';
import {MultipleSelect} from '../../presentation/MultipleSelect';

type DashboardDropDownWidgetProps = {
  title: string;
  dropdownKey: string; // Please pass a key if multiple dropdown were used
  options: DashboardWidgetInputSchema;
  value: string[] | string;
  clearFilter: () => void;
  onChange: (val: any) => void;
};

const DashboardMultiDropDownWidget: React.FC<DashboardDropDownWidgetProps> = ({
  title,
  dropdownKey,
  options,
  clearFilter,
  value,
  onChange
}) => {
  const list = options.map((opt) => ({label: opt.attribute.label, value: opt.value as string}));
  const currVal = Array.isArray(value) 
    ? value.map((v) => typeof v === 'string' ? {label: v, value: v} : v) 
    : [{label: value, value: value}];
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h6" role="label" style={{fontWeight: 600}}>
          {title}
        </Typography>
        <Button onClick={() => clearFilter()} id={"clear" + title.replace(/ /g, '')}>
          <span
            style={{
              color: COLOR.GREENT_TEXT,
              textTransform: 'capitalize',
            }}>
            <u>{'Clear Filter'}</u>
          </span>
        </Button>
      </Grid>
      
        <MultipleSelect
          dataSet={list}
          value={currVal}
          selectedData={currVal}
          setSelectedData={(val) => {
            onChange(val);
          }}
          variant="outlined"
          //placeholder="Please Select"
          dropdownHeight={300}
          key={`dropdown-${dropdownKey}`}
          //numberDisplayed={column.numberDisplayed}
        />
    </>
  );
};

export default DashboardMultiDropDownWidget;
