import {makeStyles} from '@material-ui/core';
import COLOR from '../../../styled/colors';

export const useStyle = makeStyles(() => ({
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
  table: {
    marginTop: 30,
    '& td': {verticalAlign: 'top'},
  },
  tableHeader: {
    backgroundColor: COLOR.GRAY_SOLID,
    '& td': {
      borderBottom: 0,
      minWidth: 120,
      fontWeight: 700,
    },
  },
  tableEditPadding: {paddingTop: 10, paddingBottom: 10},
  tableIcons: {
    marginRight: 10,
    cursor: 'pointer',
  },
  linkButton: {
    textTransform: 'none',
    color: COLOR.GREENT_TEXT,
  },
  hyperlink: {
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    textDecoration: 'underline',
    color: COLOR.GREENT_TEXT,
  },
}));
