import {CSSProperties} from '@material-ui/core/styles/withStyles';
import React, {useState} from 'react';
import Tree from 'react-d3-tree';
import {
  CustomNodeElementProps,
  TreeNodeDatum,
} from 'react-d3-tree/lib/types/types/common';

import {LayerNode} from '../../../store/site/types';
import COLOR from '../../../styled/colors';
import './styles.css';
import {useCenteredTree} from './useCenteredTree';

const NodeTooltip = ({title}) => {
  return (
    <>
      <rect className="node__tooltip box"></rect>
      <rect className="node__tooltip arrow"></rect>
      <text className="node__tooltip">{title}</text>
    </>
  );
};

type SiteTreeNodeProps = CustomNodeElementProps & {
  showTooltipId: string;
  fullscreen: boolean;
};

const RenderNodeWithCustomEvents = ({
  nodeDatum,
  toggleNode,
  onNodeMouseOver,
  onNodeMouseOut,
  showTooltipId,
  fullscreen,
}: SiteTreeNodeProps) => {
  const layerNode = nodeDatum as unknown as TreeNodeDatum & LayerNode;
  return (
    <g>
      {/* Root */}
      {!layerNode.id ? (
        <>
          <rect className={`node__root ${fullscreen ? 'fullscreen' : ''}`} />
          <text className={`node__root ${fullscreen ? 'fullscreen' : ''}`}>
            {nodeDatum.name}
          </text>
        </>
      ) : null}
      {/* Children */}
      {!fullscreen && layerNode.id ? (
        <g onMouseOut={onNodeMouseOut} onMouseOver={onNodeMouseOver}>
          <circle className="node__child" />
          <text className="node__child">{layerNode.id}</text>
          {showTooltipId === layerNode.id && (
            <NodeTooltip title={layerNode.layerName} />
          )}
        </g>
      ) : null}
      {fullscreen && layerNode.id ? (
        <g>
          <rect className="node__child fullscreen" />
          <text className="node__child fullscreen">
            {layerNode.id}: {layerNode.layerName}
          </text>
        </g>
      ) : null}
    </g>
  );
};

const ContainerStyle: CSSProperties = {
  border: '1px #DDD solid',
};

const TreeContainerStyle: CSSProperties = {
  width: '100%',
  height: 660,
};

const FullScreenTreeContainerStyle: CSSProperties = {
  width: '100%',
  height: 750,
};

const LinkStyle: CSSProperties = {
  margin: 'auto',
  marginBottom: '2rem',
  width: 'max-content',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  color: COLOR.GREENT_TEXT,
  textDecoration: 'underline',
  cursor: 'pointer',
  userSelect: 'none',
};

const FullScreenWarningTextStyle: CSSProperties = {
  margin: 'auto',
  marginBottom: '2rem',
  color: COLOR.RED_WARNING,
  width: 'max-content',
  fontFamily: 'Open Sans',
  userSelect: 'none',
};

type CenteredTreeProps = {
  tree: LayerNode;
  fullscreen?: boolean;
  onFullscreenClick?: () => void;
};

const CenteredTree: React.FC<CenteredTreeProps> = ({
  tree,
  fullscreen,
  onFullscreenClick,
}) => {
  const {treeContainer, translate} = useCenteredTree();
  const [showTooltipId, setShowTooltipId] = useState();
  return (
    <div style={fullscreen ? {} : ContainerStyle}>
      <div
        style={fullscreen ? FullScreenTreeContainerStyle : TreeContainerStyle}
        ref={treeContainer}>
        <Tree
          data={tree}
          translate={translate}
          orientation={'vertical'}
          pathFunc="step"
          pathClassFunc={() => 'tree-path-line'}
          renderCustomNodeElement={(rd3tProps) =>
            RenderNodeWithCustomEvents({
              ...rd3tProps,
              showTooltipId,
              fullscreen,
            })
          }
          onNodeMouseOver={(node) => {
            setShowTooltipId((node.data as any)?.id);
          }}
          onNodeMouseOut={(node) => {
            setShowTooltipId(undefined);
          }}
          separation={{
            siblings: fullscreen ? 2.5 : 1.5,
            nonSiblings: fullscreen ? 3 : 1,
          }}
          transitionDuration={0}
          zoomable></Tree>
      </div>
      {!fullscreen && onFullscreenClick ? (
        <div style={LinkStyle} onClick={onFullscreenClick}>
          Click to view in full screen
        </div>
      ) : null}
      {fullscreen ? (
        <div style={FullScreenWarningTextStyle}>
          all in full screen, zoom in and zoom out, hand to move around
        </div>
      ) : null}
    </div>
  );
};

export default CenteredTree;
