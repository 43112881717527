import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import SearchBar from '../../../../presentation/SearchBar';
import MyTable from '../../../../presentation/Table';
import withHeader from '../../../../presentation/withHeader';

import { fetchSecurityCodeEntries } from '../utils';

import useStyles from '../SecurityCodeLibrary/styles';

import { iCodeEntryDocument, iCodeEntryDocumentResponse } from '../../../../store/code/types';
import { TokenService } from '@aglive/data-model';

const getTableHeader = ['Product Name', 'Available Codes', 'Type', 'Delivery History','Date Created', 'Action'];
const EDIT_SECURITY_CODE_PATH = `/private/codes/securityCodes/edit`;

const UsedSecurityCodes: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userid = useAppSelector((state) => state.auth.wallet);

  const [query, setQuery] = useState('');
  const [codeEntries, setCodeEntries] = useState<Array<iCodeEntryDocumentResponse<iCodeEntryDocument>>>([]);
  const [productProfiles, setProductProfiles] = useState<{ [key: string]: TokenService.ProductToken }>(null);

  useEffect(() => {
    getGroupedCodesWithProductProfiles();
  }, []);

  const getGroupedCodesWithProductProfiles = useCallback(async () => {
    const {codeList, uniqueProfileMap} = await fetchSecurityCodeEntries(dispatch, userid, true, true);

    setProductProfiles(uniqueProfileMap);
    setCodeEntries(codeList);
  }, []);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={`${classes.searchBarContainer} ${classes.fullWidthSearchBar}`}>
          <SearchBar
            query={query}
            setQuery={setQuery} 
            label={'Search Product Name'}
          />
        </Grid>
      </Grid>
      <MyTable
        firstColumnWidth={classes.firstColumnWidth}
        heads={getTableHeader}
        rows={codeEntries
          ?.filter((res) => 
            // access productProfiles map with agliveToken -> only show entry if productProfile's name include the query
            productProfiles[res.details.product.product.agliveToken].details.name
              .toLowerCase().includes(query.toLowerCase())
          )
          .map((entry, index) => [
            <span id={`ProductName${index}`}>{productProfiles[entry.details.product.product.agliveToken].details.name}</span>,
            <span id={`AvailableCodes${index}`}>{entry.details.codes.length}</span>,
            <span id={`CodeType${index}`}>
              {entry.details.product.container.charAt(0).toUpperCase() +
              entry.details.product.container.slice(1)}
            </span>,
            <span id={`DeliveryHistory${index}`}>
            {entry.details.product.showDeliveryHistory
            ? entry.details.product.isConfidential
              ? 'Confidential'
              : 'On'
            : 'Off'}
            </span>,
            moment(entry.details.product.date).format('DD/MM/YYYY'),
            <div style={{display: 'flex', gap: 10}}>
              <EditIcon
                style={{cursor: 'pointer'}}
                onClick={() => history.push(`${EDIT_SECURITY_CODE_PATH}/${entry.docId}`)}
                id={`EditCode${index}`}
              />
            </div>
          ])
        }
      />
    </>
  );
};

export default withHeader(
  {
    title: 'Used Codes',
    back: true,
  },
  UsedSecurityCodes,
);
