import React from 'react';

import {Grid, Box} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import TabHeading from '../TabHeading';
import COLOR from '../../../styled/colors';
import LabelData from '../../../presentation/LabelData';

type attachmentType = {
  attachment: Array<
    TokenService.ProductToken['details']['pages'][0]['attachment'][0]
  >;
};

const ViewProductTabAttachment: React.FC<attachmentType> = ({attachment}) => {
  return (
    <Grid container direction="column">
      {attachment?.map(({title, url}, index) => (
        <React.Fragment key={index}>
          <TabHeading
            heading={`Attachment ${index + 1}`}
            subheading="Add a supporting file (pdf, png, doc, etc.)"
          />
          <Box
            p={2}
            mb={3}
            style={{
              backgroundColor: COLOR.GRAY_SOLID,
            }}>
            <LabelData
              label="Attachment Title"
              placeHolder="Enter Text"
              field={title}
              characters={30}
              valueId={`AttachmentTitle${index}`}
            />
            <LabelData
              label="File"
              field={decodeURIComponent(url?.split('/').reverse()[0]) || '-'}
              valueId={`AttachmentFile${index}`}
            />
          </Box>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default ViewProductTabAttachment;
