import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@material-ui/core';

const useDropdownWrapperStyles = makeStyles({
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
});

type DropdownWrapperProps = {
  variant?: 'outlined' | 'standard' | 'filled';
  error?: string;
  selectLabel?: string;
  title: string;
  id?: string;
  linkElement?: React.ReactNode;
  labelId?: string;
  value: any;
  onChange: SelectProps['onChange'];
  children?: React.ReactNode;
};

const DropdownSelection = ({
  variant = 'outlined',
  error,
  id,
  title,
  linkElement,
  children,
  selectLabel = 'Please select',
  value,
  labelId = 'select-dropdown',
  onChange,
}: DropdownWrapperProps) => {
  const classes = useDropdownWrapperStyles();
  return (
    <Grid container item direction="column">
      <Grid container item>
        <Typography variant="h6" className={classes.inputLabel}>
          {title}
        </Typography>
      </Grid>
      <Grid container item>
        <FormControl fullWidth variant={variant} error={!!error}>
          <InputLabel id={labelId}>{selectLabel}</InputLabel>
          <Select
            aria-labelledby={labelId}
            id={id}
            label={selectLabel}
            value={value}
            onChange={onChange}>
            <MenuItem value={''}>{'Please select'}</MenuItem>
            {children}
          </Select>
          <Grid
            container
            item
            justifyContent={!!error ? 'space-between' : 'flex-end'}>
            {!!error && <FormHelperText>{error}</FormHelperText>}
            {linkElement}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DropdownSelection;
