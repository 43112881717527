import { CSSProperties } from "react";

export const TEXT_FIELD_ERROR_STYLE: CSSProperties = {
  margin: 0,
  border: 'solid 1px red',
  padding: '11px 14px',
  borderRadius: '5px',
};

export const TEXT_FIELD_NORMAL_STYLE: CSSProperties = {
  margin: 0,
  border: 'solid 1px lightgray',
  padding: '11px 14px',
  borderRadius: '5px',
};

export const TABLE_DATA_EMPTY_STYLE: CSSProperties = {
  width: '100%',
  paddingTop: 20,
  paddingBottom: 20,
  textAlign: 'center',
};

export const SELECT_MAX_NUMBER_CONTAINER_STYLE: CSSProperties = { display: 'flex', flexDirection: 'row', gap: '0.5rem' };

export const ACTION_COLUMN_CONTAINER_STYLE: CSSProperties = { display: 'flex', flexDirection: 'row', gap: '0.5rem' };