import { PlantDetails } from '@aglive/data-model/dist/misc/plant';
import {iDocumentRequest} from '../../utils/type';
import {AssetProfileDetails} from '../assetProfile/types';

export const PlantTypes = ['Genetic', 'Production'] as const;
export const PlantPurposes = ['Medicinal', 'Scientific'] as const;

export interface CreateAssetDetails {
  assetProfile: string;
  permit: string;
  layerId: string;
  quantity: number;
  pic_id: string;
  purpose: PlantDetails['purpose'];
  type: PlantDetails['type'];
  externalSource: boolean;
  siteAgliveToken: string;
}

export interface PlantCodeDocumentRequest extends iDocumentRequest {
  details: PlantCodeDocumentDetails;
}

export interface PlantCodeDocumentDetails {
  startNumber?: number;
  quantity: number;
  used: boolean;
  codes: Array<string>;
  permitId?: string;
  assetProfile: AssetProfileDetails;
  pic_id: string;
  source: {
    companyName: string;
    companyNumber: string;
    creatorEmail: string;
  };
}

export type CreateTokenPayload<T> = {
  type: string;
  generateAgliveToken: boolean;
  generateUniqueAmount: boolean;
  amount: number; // Number of assets to be generated
  details: T;
};

export type CreatePlantTokenDetails = PlantDetails;