import styled from 'styled-components';
import {Grid, Typography} from '@material-ui/core';

import COLOR from '../../styled/colors';
import React from 'react';

export const Background = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #1e7b3e 0%, #87d978 99.49%);
`;

export const MainHeading = ({children}) => (
  <Typography
    variant="h2"
    style={{
      fontSize: 22,
      whiteSpace: 'pre',
      margin: 'auto'
    }}>
    {children}
  </Typography>
);

export const WidgetContainer = styled(Grid)`
  min-height: 300px;
  border-radius: 0.75rem;
  border: 1px solid ${COLOR.GRAY_BORDER};
  padding: 24px;
`;
