import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {Grid, makeStyles} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';

import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArchiveIcon from '@material-ui/icons/Archive';

import SearchBar from '../../../presentation/SearchBar';
import MyButton from '../../../presentation/button';
import MyTable from '../../../presentation/Table';
import withHeader from '../../../presentation/withHeader';
import {Buttons} from '../../../presentation/ButtonsGroup';

import {getBrandAgliveTokenId} from '../utils';
import {toggleModal, toggleModalOff} from '../../../store/modal/actions';
import {callAPI} from '../../../utils/network';
import {addBrandToBusiness, fetchBrands} from '../../../store/brand/actions';

import {useStyles} from '../../promotion/PromotionLibrary/styles';

import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import API from '../../../config/api';
import CONSTANT from '../../../config/constant';

import {TokenService} from '@aglive/data-model';
import {WebErrorType} from '../../../utils/error';
import {CASL} from '@aglive/frontend-core';

const useLocalStyle = makeStyles({
  firstColumnWidth: {
    minWidth: '40%',
    width: '55%',
  },
});

type Props = {};

const BrandProfile: React.FC<Props> = () => {
  const classes = useStyles();
  const localClasses = useLocalStyle();

  const ability = CASL.useAbility(CASL.AbilityContext);
  const data = ['Brand', 'Date Created'];
  ability.can('update', 'brand') ? data.push('Action') : data.push('');

  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const businessId = useAppSelector(
    (state) => state.user.userProfileData.businessId,
  );

  const [query, setQuery] = useState('');
  const [brandList, setBrandList] = useState<Array<TokenService.BrandToken>>(
    [],
  );

  const loadBrandTokens = useCallback(async () => {
    const brandTokens = await fetchBrands(dispatch);
    if (brandTokens) {
      setBrandList(brandTokens.filter((brand) => !brand.details.archive));
    }
  }, []);

  useEffect(() => {
    loadBrandTokens();
  }, [loadBrandTokens]);

  const onCopy = async (brandToken: TokenService.BrandToken) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const token = await callAPI<{
        message: string;
        data: Array<TokenService.BrandToken>;
      }>({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BRAND,
              tokenId: '',
              details: {
                ...brandToken.details,
                products: [],
                name: `${brandToken.details.name} - Copy`,
              },
            },
          ],
        },
      });

      // add the new brand to business
      await addBrandToBusiness(
        businessId,
        getBrandAgliveTokenId(token.data[0]),
      );

      loadBrandTokens();
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  const onArchive = async (brand: TokenService.BrandToken) => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Archive?',
        subtitle:
          'Archived items remain active and results will still be displayed when they are scanned',
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={async () => {
              try {
                dispatch(toggleModalOff());
                dispatch({type: SPINNER_TOGGLE_ON});
                await callAPI({
                  url: API.POST.createActivity,
                  method: 'POST',
                  data: {
                    tokens: [
                      {
                        type: CONSTANT.ASSETTYPE.BRAND,
                        externalIds: {
                          agliveToken: getBrandAgliveTokenId(brand),
                        },
                        activities: [
                          {
                            type: 'UP_details',
                            details: {
                              archive: true,
                            },
                          },
                        ],
                      },
                    ],
                  },
                });

                dispatch(
                  toggleModal({
                    status: 'success',
                    title: 'Archived',
                    CTAHandler: () => history.push(`${path}/archived`),
                  }),
                );
              } catch (e) {
                const error = e as WebErrorType;
                dispatch(
                  toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                  }),
                );
              } finally {
                dispatch({type: SPINNER_TOGGLE_OFF});
              }
            }}
          />
        ),
      }),
    );
  };

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar query={query} setQuery={setQuery} label="Search Brand" />
        </Grid>
        <CASL.Can I="create" a="brand">
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={'Create New'}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() => history.push(`${path}/new`)}
            />
          </Grid>
        </CASL.Can>
      </Grid>

      <MyTable
        firstColumnWidth={localClasses.firstColumnWidth}
        heads={data}
        rows={brandList
          ?.filter((brand) =>
            brand.details.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map((brand, index) => [
            <Link
              to={{
                pathname: `${path}/view/${getBrandAgliveTokenId(brand)}`,
              }}
              className={classes.hyperlink}
              id={`BrandName${index}`}>
              {brand.details.name}
            </Link>,

            moment(brand.createdAt).format('DD/MM/YYYY'),
            <CASL.Can I="update" a="brand">
              <div style={{display: 'flex', gap: 20, width: 'fit-content'}}>
                <div>
                  <EditIcon
                    id={'edit' + index}
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                      history.push(
                        `${path}/edit/${getBrandAgliveTokenId(brand)}`,
                      )
                    }
                  />
                </div>
                <div>
                  <FileCopyIcon
                    id={'copy' + index}
                    style={{cursor: 'pointer'}}
                    onClick={() => onCopy(brand)}
                  />
                </div>
                <div>
                  <ArchiveIcon
                    id={'archive' + index}
                    onClick={() => onArchive(brand)}
                    style={{cursor: 'pointer'}}
                  />
                </div>
              </div>
            </CASL.Can>,
          ])}
      />

      <Grid item style={{marginTop: 20}}>
        <Link to={`${path}/archived`} className={classes.hyperlink}>
          Archived Brands
        </Link>
      </Grid>
    </>
  );
};

export default withHeader(
  {
    title: 'Brand Library',
  },
  BrandProfile,
);
