import {GmpPermit, OdcPermit} from '@aglive/data-model/dist/misc/business';
import {TableRow} from '@material-ui/core';

import moment from 'moment';
import {StyledTableCell} from 'src/presentation/Table';
import { getThcValue } from '../utils';

type PermitRowViewLabels = {
  permitPlantLimit?: string;
  thcValue?: string;
};

type PermitRowViewProps = {
  permit: OdcPermit | GmpPermit;
  labels: PermitRowViewLabels;
};
export const PermitRowView: React.FC<PermitRowViewProps> = ({
  permit,
  labels,
}) => {
  const expired = moment(permit.expiryDate).isBefore(moment.now())

  return (
    <TableRow>
      <StyledTableCell align="left">
        <span style={{fontSize: '1rem'}}>{permit.permitNumber}</span>
      </StyledTableCell>
      {labels.thcValue ? (
        <StyledTableCell align="left">
          <span style={{fontSize: '1rem'}}>{getThcValue(permit)}</span>
        </StyledTableCell>
      ) : null}
      <StyledTableCell align="left">
        <span style={{fontSize: '1rem'}}>
          {moment(permit.startDate).format('DD/MM/YYYY')}
        </span>
      </StyledTableCell>
      <StyledTableCell align="left">
        <span style={{fontSize: '1rem', ...(expired && labels.permitPlantLimit ? {color: 'red'} : {})}}>
          {moment(permit.expiryDate).format('DD/MM/YYYY')}
        </span>
      </StyledTableCell>
      {labels.permitPlantLimit ? (
        <StyledTableCell align="left">
          <span style={{fontSize: '1rem'}}>
            {(permit as OdcPermit).maxNumber
              ? `${(permit as OdcPermit).maxNumber}`
              : 'Unlimited'}
          </span>
        </StyledTableCell>
      ) : null}
    </TableRow>
  );
};

  