// React
import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
// Material UI Imports
import {Grid, Typography, makeStyles, Box} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

// Presentation, Container, Styles
import ProductPagePhone from '../../presentation/MockupPhoneProduct';
import MyButton from '../../presentation/button';
import COLOR from '../../styled/colors';
import Tabs from '../../presentation/Tab';
import TabPanel from '../../presentation/TabPanel';
import FieldHeading from '../../presentation/FieldHeading';
import withHeader from '../../presentation/withHeader';
import LabelData from '../../presentation/LabelData';

// Redux
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {AssetProfileDetails} from '../../store/assetProfile/types';
import {fetchSingleAssetProfile} from '../../store/assetProfile/actions';
import {getBusinessProfile} from '../../store/profile/actions';
import { filterPermits } from '../../store/profile/utils';
import { OdcLicense } from '@aglive/data-model/dist/misc/business';

interface Props {}

enum TabMap {
  mainDisplay,
  additionalContent,
  attachments,
}

const TabLabels = Object.values(TabMap).slice(0, 3);

const ViewAssetProfile: React.FC<Props> = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();
  const EDIT_ASSET_PROFILE_PATH = `/private/assets/assetProfile/edit/${id}`;

  // States
  const [assetProfile, setAssetProfile] = useState<AssetProfileDetails>();
  const [tabValue, setTabValue] = useState(TabMap.mainDisplay);

  // Side Effects
  useEffect(() => {
    async function fetchAssetProfile() {
      const fetchedAssetProfile = await dispatch(
        fetchSingleAssetProfile(id, history),
      );
      if (fetchedAssetProfile) {
        setAssetProfile(fetchedAssetProfile.details);
      }
    }
    fetchAssetProfile();
  }, [id]);

  useEffect(() => {
    dispatch(getBusinessProfile());
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body1" role="label" style={{fontWeight: 600}}>
            Asset Name
          </Typography>
          <Typography variant="body1" role="label">
            {assetProfile?.assetName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{marginTop: 60}}>
        <Grid item xs={12}>
          <Typography variant="h2" role="label" style={{marginBottom: 10}}>
            Asset Details
          </Typography>
          <Divider />
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        {/* Reuse the mockup phone from Product Profile */}
        <Grid
          item
          container
          xs={12}
          lg={5}
          style={{marginTop: 50, paddingLeft: 30}}
          justifyContent="center">
          <ProductPagePhone
            isShowHistory={false}
            page={{
              mainDisplay: assetProfile?.mainDisplay,
              content: assetProfile?.content,
              attachment: assetProfile?.attachment,
            }}
            isProduct={false}
          />
        </Grid>

        {/* Render 3 tabs */}
        <Grid item xs={12} lg={7} style={{marginTop: 70}}>
          <Tabs
            tabHeaders={TabLabels as string[]}
            value={tabValue}
            setValue={setTabValue}>
            {/* Main Display tab */}
            <TabPanel
              isShown={tabValue === TabMap.mainDisplay}
              index={TabMap.mainDisplay}>
              <Box
                p={3}
                style={{
                  border: `1px solid ${COLOR.GRAY_BORDER}`,
                  borderTop: 0,
                }}>
                {/* Image or Video section */}
                <FieldHeading
                  title="Image or Video"
                  subtitle="Enhance your story with an image or video"
                />
                <Box p={3} marginBottom={3} className={classes.fieldContainer}>
                  <LabelData
                    label={'Image'}
                    field={
                      assetProfile?.mainDisplay.item === 'image'
                        ? decodeURIComponent(
                            assetProfile.mainDisplay.url
                              .split('/')
                              .reverse()[0],
                          )
                        : '-'
                    }
                  />
                  {/* (Currently commented out as it is not included in Cannabis Phase 1)
                  <LabelData
                    label={'Video Link'}
                    field={
                      assetProfile?.mainDisplay.item === 'video'
                        ? assetProfile.mainDisplay.url
                        : '-'
                    }
                  /> */}
                </Box>

                {/* Asset Info section */}
                <FieldHeading
                  title={assetProfile?.assetName}
                  subtitle="Enter the basic info for your asset"
                />
                <Box p={3} className={classes.fieldContainer}>
                  {/* Species */}
                  <LabelData
                    label={'Species'}
                    field={assetProfile?.mainDisplay.species}
                    characters={30}
                  />
                  {/* Strain */}
                  <LabelData
                    label={'Strain'}
                    field={assetProfile?.mainDisplay.strain}
                    characters={30}
                  />
                  {/* Sub Strain */}
                  <LabelData
                    label={'Sub Strain'}
                    field={assetProfile?.mainDisplay.subStrain}
                    characters={30}
                  />
                </Box>
              </Box>
            </TabPanel>
            {/* Additional Content tab */}
            <TabPanel
              isShown={tabValue === TabMap.additionalContent}
              index={TabMap.additionalContent}>
              <Box
                p={3}
                style={{
                  border: `1px solid ${COLOR.GRAY_BORDER}`,
                  borderTop: 0,
                }}>
                <Grid container direction="column" justifyContent="space-between">
                  {assetProfile?.content?.map((content, index) => (
                    <React.Fragment key={index}>
                      <FieldHeading
                        title={`Subheading ${index + 1}`}
                        subtitle="Add more details"
                      />
                      <Box
                        p={3}
                        marginBottom={3}
                        className={classes.fieldContainer}>
                        <LabelData
                          label={'Subheading'}
                          field={content.subheading}
                          characters={30}
                        />
                        <LabelData
                          label={'Content'}
                          field={content.content}
                          characters={600}
                        />
                      </Box>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </TabPanel>
            {/* Attachment tab */}
            <TabPanel
              isShown={tabValue === TabMap.attachments}
              index={TabMap.attachments}>
              <Box
                p={3}
                style={{
                  border: `1px solid ${COLOR.GRAY_BORDER}`,
                  borderTop: 0,
                }}>
                <Grid container direction="column" justifyContent="space-between">
                  {assetProfile?.attachment?.map((attachment, index) => (
                    <React.Fragment key={index}>
                      <FieldHeading
                        title={`Attachment ${index + 1}`}
                        subtitle="Add a supporting file (pdf, png, doc, etc.)"
                      />
                      <Box
                        p={3}
                        marginBottom={3}
                        className={classes.fieldContainer}>
                        <LabelData
                          label={'Attachment Title'}
                          field={attachment.title}
                          characters={30}
                        />
                        <LabelData
                          label={'Attachment'}
                          field={decodeURIComponent(
                            attachment.url.split('/').reverse()[0],
                          )}
                        />
                      </Box>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </TabPanel>
          </Tabs>
        </Grid>
      </Grid>

      <Grid item container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <MyButton
            text="Edit"
            variant="contained"
            onClick={() => history.push(EDIT_ASSET_PROFILE_PATH)}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  fieldContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
}));

export default withHeader(
  {
    title: 'View Asset Profile',
    margin: 60,
    back: true,
    id: 'ViewAsset',
  },
  ViewAssetProfile,
);
