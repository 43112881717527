import React from 'react';
import {Divider, Grid, styled, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import ReactPlayer from 'react-player/youtube';

import {MockupPhoneNewWithNav} from '../../../presentation/MockUpPhoneNew';

import WaterMarks from "../../../img/Watermarks.png";
import NewDefaultImage from '../../../img/NewDefaultImage.png';

import { TokenService } from '@aglive/data-model';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    minHeight: 550,
    width: '100%',
    overflow: 'auto',
  },

  mediaPreview: {
    width: '100%',
    position: 'relative',
  },
  media: {
    width: '100%',
    height: '100%',
  },
  defaultMediaText: {
    fontSize: 12,
    color: COLOR.WHITE,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 600,
  },

  textContentContainer: {
    padding: 15,
    width: '100%',
  },

  headlineContainer: {
    gap: 5,
  },

  addContentContainer: {
    marginBottom: 15,
  },

  divider: {
    width: '100%',
    margin: '15px 0 20px 0',
  },

  protectedByContainer: {
    alignSelf: 'center',
    paddingTop: 20
  },
  protectedBy: {
    width: 80,
    height: 20
  },
}));

const DetailTextHeading = styled((props) => <Typography variant="h6" {...props} />)({
  fontWeight: 600,
  fontSize: 18
});

const DetailTextSubheading = styled((props) => <Typography variant="subtitle1" {...props} />)({
  fontSize: 15,
  overflowWrap: 'break-word',
});

const DetailTextSubText = styled((props) => <Typography variant="body1" {...props} />)({
  fontSize: 13,
  marginTop: 3,
  wordWrap: 'break-word',
  whiteSpace: 'pre-line',
});

const DetailTextHyperlink = styled('a')({
  fontSize: 13,
  marginTop: 3,
  wordWrap: 'break-word',
  whiteSpace: 'pre-line',
  fontFamily: 'Open Sans',
  color: COLOR.GREENT_TEXT,
});

export type MockupPhoneDetailsT = {
  mediaType: TokenService.ProductToken["details"]["pages"][number]["display"]["item"];
  mediaUrl: string;

  headline1: string;
  headline2: string;
  headline3: string;

  contentArr: TokenService.ProductToken["details"]["pages"][number]["content"];

  attachmentArr: TokenService.ProductToken["details"]["pages"][number]["attachment"];
}

const MockupPhoneDetails: React.FC<MockupPhoneDetailsT> = ({
  mediaType,
  mediaUrl,

  headline1,
  headline2,
  headline3,

  contentArr,

  attachmentArr
}) => {
  const classes = useStyles();

  return (
    <MockupPhoneNewWithNav
      backgroundColor={COLOR.BLACK_BG}
      navTitle={"Product Information"}
      id="ProductDetailsPhone"
    >
      <Grid container direction="column">
        <Grid
          item
          container
          direction="column"
          className={classes.contentContainer}
        >
          <Grid className={classes.mediaPreview}>
            <ProductProfileMedia
              mediaType={mediaType}
              mediaUrl={mediaUrl}
            />
          </Grid>
          <Grid item container direction="column" className={classes.textContentContainer}>
            <Grid item container direction="column" className={classes.headlineContainer}>
              <Typography variant="h6">{headline1}</Typography>
              <DetailTextHeading>
                {headline2}
              </DetailTextHeading>
              <Typography variant="h6">{headline3}</Typography>
            </Grid>

            <Divider className={classes.divider} />

            <Grid item container direction="column" className={classes.addContentContainer}>
              {contentArr?.map(({subheading, content}, index) => (
                <Grid item key={index} className={classes.addContentContainer}>
                  <DetailTextSubheading>{subheading}</DetailTextSubheading>
                  <DetailTextSubText>{content}</DetailTextSubText>
                </Grid>
              ))}
            </Grid>

            <Grid item container direction="column">
              {attachmentArr?.map(({title, url}, index) => (
                <React.Fragment key={index}>
                  <DetailTextSubheading>{title}</DetailTextSubheading>
                  <DetailTextHyperlink href={url} target="_blank">
                    {decodeURIComponent(url.split('/').reverse()[0])}
                  </DetailTextHyperlink>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.protectedByContainer}>
          <img
            src={WaterMarks}
            className={classes.protectedBy}
            alt="protected by Aglive"
          />
        </Grid>
      </Grid>
    </MockupPhoneNewWithNav>
  );
};

type ProductProfileMediaT = {
  mediaType?: TokenService.ProductToken["details"]["pages"][number]["display"]["item"];
  mediaUrl?: string;
}

const ProductProfileMedia: React.FC<ProductProfileMediaT> = ({
  mediaType,
  mediaUrl,
}) => {
  const classes = useStyles();

  switch (mediaType) {
    case 'video':
      if (ReactPlayer.canPlay(mediaUrl)) {
        return (
          <ReactPlayer
            url={mediaUrl}
            width="100%"
            height="100%"
            controls
          />
        );
      } else {
        return <ProductProfileMedia />;
      }
    case 'image':
      if (mediaUrl.length) {
        return (
          <img
            src={mediaUrl}
            alt="Product profile media"
            className={classes.media}
          />
        );
      } else {
        return <ProductProfileMedia />;
      }
    default:
      return (
        <>
          <Typography className={classes.defaultMediaText}>
            Image or Video Here
          </Typography>
          <img
            src={NewDefaultImage}
            alt="default product profile"
            className={classes.media}
          />
        </>
      );
  }
}

export default MockupPhoneDetails;
