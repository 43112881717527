import dashboardConfig_Argentina from './country/Argentina';
import dashboardConfig_Australia from './country/Australia';
import dashboardConfig_Canada from './country/Canada';

import dashboardConfig_Angus from './client/angus';
import dashboardConfig_Warakirri from './client/warrakirri';

import dashboardConfig_Cannabis from './industry/cannabis';

const configSelector = (country: string, industry?: string, client?: string) => {
  if (client) {
    switch (client) {
      case 'Angus':
        return dashboardConfig_Angus;
      case 'Warakirri':
        return dashboardConfig_Warakirri;
    }
  }

  if (industry) {
    switch (industry) {
      case 'PLANTS':
        return dashboardConfig_Cannabis;
    }
  }

  switch (country) {
    case 'Argentina':
      return dashboardConfig_Argentina;
    case 'Canada':
      return dashboardConfig_Canada;
    case 'Australia':
    default:
      return dashboardConfig_Australia;
    // default:
    //   return {};
  }
};

export default configSelector;
