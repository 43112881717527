import { SelectItem, SortBy } from "./types";

export const SELECT_ALL = 'SELECT_ALL';

export const ALL_PERMIT: SelectItem = {
  name: 'All Permit',
  value: SELECT_ALL,
};

export const ALL_ASSET_PROFILE: SelectItem = {
  name: 'All Asset Profile',
  value: SELECT_ALL,
};

export const SORT_BY: SortBy[] = ['Date Created', 'ODC Permit', 'Asset Profile', 'Generated Codes'];