import { CreatePlantTokenDetails } from './../../store/plant/types';
import {PlantPurposes, PlantTypes} from '../../store/plant/types';

export const CODE_SEQUENCE_MAX_DIGITS = 6;

export const INITIAL_CANNABIS_PRINT_STATE = {
  startNumber: 1,
  strain: '',
  codes: [],
};

export function padZeros(str: string, width: number) {
  const zero = '0';
  return (Array(width).fill(zero).join('') + str).slice(-width);
}

const PLANT_PURPOSE_SEPARATOR = ' - ';
export const purposeOptions = PlantTypes.flatMap((type) =>
  PlantPurposes.map((purpose) => ({
    label: `${type}${PLANT_PURPOSE_SEPARATOR}${purpose}`,
    value: `${type}${PLANT_PURPOSE_SEPARATOR}${purpose}`,
  })),
);

export function getPurpose(v: string) {
  const [plantType, plantPurpose] = v.split(PLANT_PURPOSE_SEPARATOR);
  const type = plantType as CreatePlantTokenDetails['type'];
  const purpose = plantPurpose as CreatePlantTokenDetails['purpose'];
  return {type , purpose};
}
