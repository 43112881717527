import {Box, Collapse, Typography, makeStyles} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import COLOR from 'src/styled/colors';

export interface CollapsedContainerProps {
  onClick: () => void;
  isExpanded: boolean;
  label: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const useStyle = makeStyles((theme) => ({
  labelBox: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLOR.GRAY_SOLID,
    },
    padding: '0.75rem 0.5rem',
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
  },
  collapseContainer: {
    width: '100%',
  },
}));

const CollapsedContainer: React.FC<CollapsedContainerProps> = ({
  label,
  onClick,
  isExpanded,
  children,
  className,
  style,
}) => {
  const classes = useStyle();
  return (
    <Box className={className} style={style}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        onClick={onClick}
        className={classes.labelBox}
        aria-label="show-more"
        aria-expanded={isExpanded}>
        <Typography variant="h3" role="label">
          {label}
        </Typography>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Collapse in={isExpanded} className={classes.collapseContainer}>
        <Box aria-expanded={isExpanded} aria-label="expanded-content">
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsedContainer;
