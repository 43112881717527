import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {getUserProfile, getBusinessProfile, updateUserProfile, editUserProfileType} from '../../store/profile/actions';
import MyButton from '../../presentation/button';
import {PageHeader} from '../../presentation/withHeader';
import CONSTANT from '../../config/constant';
import { FormGenerator, FormInputFunctions } from '../../presentation/FormGenerator';

const UserProfile: React.FC<{view?: boolean}> = ({view}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userid = useAppSelector((state) => state.auth.wallet);
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const EDIT_USER_PROFILE = '/private/profile/users/edit';
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const userLabels = businessProfile.businessCountry === 'Argentina'
    ? CONSTANT.ES_LABELS
    : CONSTANT.EN_LABELS;
  const userId = useAppSelector((state) => state.auth.wallet);
  const formRef = useRef<FormInputFunctions<editUserProfileType>>();

  const [formReady, setFormReady] = useState(true);
  
  useEffect(() => {
    if (view) {
      dispatch(getUserProfile(userid));
      dispatch(getBusinessProfile());
    }
  }, [dispatch, userid, view]);

  const formInputs = [
    {
      type: 'INPUT',
      name: 'name',
      id: 'userName',
      titleKey: 'fullName',
      required: true,
      viewMode: !!view,
      value: userProfile?.name
    },
    {
      type: 'PHONE',
      name: 'mobileNumber',
      id: 'userMobile',
      titleKey: 'phone',
      required: true,
      viewMode: !!view,
      value: userProfile?.mobileNumber
    },
    {
      type: 'EMAIL',
      name: 'emailAddress',
      id: 'userEmail',
      titleKey: 'emailAddress',
      errorMsgKey: 'inputValidEmail',
      required: true,
      viewMode: !!view,
      value: userProfile?.emailAddress
    },
    {
      type: 'CUSTOM',
      name: 'signature',
      skip: !view,
      titleKey: 'signature',
      customComponent: <img
        style={{marginTop: 8, width: '100%'}}
        src={userProfile?.signature}
        alt=""
      />
    }
  ];

  return (
    <PageHeader
      config={{title: userLabels[`${view ? 'view' : 'edit'}UserProfile`], margin: 60, back: !view}}>
      <Grid container direction="row" spacing={3}>
        <FormGenerator ref={formRef} formInputs={formInputs} onReady={setFormReady} language={businessProfile.businessCountry === 'Argentina' ? 'es' : 'en'} />
        <Grid item container justifyContent="flex-end" style={{marginTop: 300}}>
          {view ? 
            <MyButton
              text={userLabels.edit}
              variant="contained"
              onClick={() => history.push(EDIT_USER_PROFILE)}
            /> : 
            <MyButton
              text={userLabels.save}
              variant="contained"
              disabled={!formReady}
              onClick={() => {
                if (formRef.current && formRef.current?.isValid()) {
                  dispatch(
                    updateUserProfile(
                      formRef.current?.getValues(),
                      userId,
                      businessProfile.businessCountry,
                    ),
                  );
                }
              }}
            />
          }
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default UserProfile;
