import { GmpPermit, License, OdcPermit } from "@aglive/data-model/dist/misc/business";

const updateLicense = (licenses: License[]) => {
  const updatedLicenses = licenses.map(license => {
    if (license.name === 'Poison') {
      return license;
    }
    return { ...license, permits: license.permits.filter(permit => permit.permitId && permit.permitId.length > 0) };
  });
  return {
    "type": "upLicense",
    "details": {
      "licenses": updatedLicenses
    }
  };
};

const deleteLicense = (licenseIds: string[]) => ({
  "type": "delLicense",
  "details": {
    "licenseIds": licenseIds
  }
});

const addLicense = (licenses: License[]) => ({
  "type": "addLicense",
  "details": {
    "licenses": licenses.map((license) => {
      if (license.name === 'Poison') {
        const { permitId, ...rest } = license;
        return { ...rest };
      }
      const { licenseId, ...rest } = license;
      return { ...rest };
    })
  }
});

function addPermit(licenseId: string, permits: (OdcPermit | GmpPermit)[]) {
  return {
    type: "addPermit",
    details: {
      licenseId: licenseId,
      permits: permits
    }
  };
};

export const ActivityBuilder = {
  updateLicense,
  deleteLicense,
  addLicense,
  addPermit
};