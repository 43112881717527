import React, {useState} from 'react';
import {Box, Grid, Link, Switch, Typography} from '@material-ui/core';

import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import TabHeading from '../TabHeading';
import MockupPhoneDelivery from './MockUpPhoneDeliveryHistory';

import COLOR from '../../../styled/colors';
import MockupPhoneDetails, { MockupPhoneDetailsT } from './MockUpPhoneDetails';

enum TabMap {
  delivery,
}

const TabKeys = ['Delivery History'];

type CreateProductProfileDeliveryType = {
  showDeliveryHistory: boolean;
  showMap: boolean;
  isConfidential: boolean;

  onHistoryToggle: () => void;
  onMapViewToggle: () => void;
  onConfidentialModeToggle: () => void;
} & Partial<MockupPhoneDetailsT>;

const CreateProductProfileDelivery: React.FC<CreateProductProfileDeliveryType> = ({
  showDeliveryHistory,
  showMap,
  isConfidential,
  
  onHistoryToggle,
  onMapViewToggle,
  onConfidentialModeToggle,

  // props for product information mockup phone
  mediaType = 'image',
  mediaUrl = '',

  headline1 = '',
  headline2 = '',
  headline3 = '',

  contentArr = [{ content: '', subheading: ''}],

  attachmentArr = []
}) => {
  const [focusedTabIndex, setFocusedTabIndex] = useState(TabMap.delivery);

  return (
    <Grid container justify="space-between">
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        lg={5}
        style={{minWidth: 360}}>
        {showDeliveryHistory
          ? (
            <MockupPhoneDelivery
              showDeliveryHistory={showDeliveryHistory}
              showMap={showMap}
              isConfidential={isConfidential}
            />
          ) : (
            <MockupPhoneDetails
              mediaType={mediaType}
              mediaUrl={mediaUrl}

              headline1={headline1}
              headline2={headline2}
              headline3={headline3}

              contentArr={contentArr}
              attachmentArr={attachmentArr}
            /> 
          )}
      </Grid>

      <Grid item xs={12} lg={7}>
        <Tabs
          tabHeaders={TabKeys as string[]}
          value={focusedTabIndex}
          setValue={setFocusedTabIndex}>
          <TabPanel
            isShown={focusedTabIndex === TabMap.delivery}
            index={TabMap.delivery}>
            <Box
              p={4}
              style={{
                border: `1px solid ${COLOR.GRAY_BORDER}`,
                borderTop: 'none',
                height: 600,
                overflowY: 'scroll',
              }}>
              <CreateProductTabDelivery
                showDeliveryHistory={showDeliveryHistory}
                showMap={showMap}
                isConfidential={isConfidential}
                onHistoryToggle={onHistoryToggle}
                onMapViewToggle={onMapViewToggle}
                onConfidentialModeToggle={onConfidentialModeToggle}
              />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </Grid>
  );
};

type CreateProductTabDeliveryType = {
  showDeliveryHistory: boolean;
  showMap: boolean;
  isConfidential: boolean;

  onHistoryToggle: () => void;
  onMapViewToggle: () => void;
  onConfidentialModeToggle: () => void;
};

const CreateProductTabDelivery: React.FC<CreateProductTabDeliveryType> = ({
  showDeliveryHistory,
  showMap,
  isConfidential,
  onHistoryToggle,
  onMapViewToggle,
  onConfidentialModeToggle,
}) => (
  <Grid item container direction="column">
    <TabHeading
      heading="Select Display"
      subheading="Choose how you would like to display delivery history"
    />

    <Box
      p={2}
      style={{
        backgroundColor: COLOR.GRAY_SOLID,
        marginBottom: 5,
      }}>
      <TabSwitch
        title="Show Delivery History"
        description="Displays the full journey of the product shipment along the supply chain"
        isChecked={showDeliveryHistory}
        onChange={onHistoryToggle}
      />
      <TabSwitch
        title="Confidential Mode"
        description="Displays the full product shipment's journey, but the supply chain participants remain anonymous"
        isChecked={isConfidential}
        onChange={onConfidentialModeToggle}
        disabled={!showDeliveryHistory}
      />
      <TabSwitch
        title="Show Map View (Coming Soon)"
        description={
          <>
            <Typography variant="h6" style={{fontSize: 14}}>
              Customers see the product shipment's journey on a map
            </Typography>
            <Typography variant="h6" style={{fontSize: 14}}>
              <strong>*Note:</strong> Not available when Confidential Mode is ON
            </Typography>
            <Link underline="always" variant="h6" style={{fontSize: 14}}>
              View Sample
            </Link>
          </>
        }
        isChecked={showMap}
        onChange={() => {}}
        disabled
      />
    </Box>
  </Grid>
);

type TabSwitchType = {
  title: string;
  description: string | JSX.Element;
  isChecked: boolean;
  onChange: () => void;
  disabled? : boolean;
};

const TabSwitch: React.FC<TabSwitchType> = ({
  title,
  description,
  isChecked,
  onChange,
  disabled
}) => (
  <Grid container direction="column" style={{marginBottom: 40, opacity: disabled ? 0.4 : undefined}}>
    <Grid item container justifyContent="space-between">
      <Typography variant="h6" style={{fontWeight: 700, marginTop: 3}}>
        {title}
      </Typography>
      <Switch checked={isChecked} onChange={onChange} color="primary" data-cy={`${title}-Switch`} />
    </Grid>
    <Grid item>
      {typeof description === 'string' ? (
        <Typography variant="h6" style={{fontSize: 14}}>
          {description}
        </Typography>
      ) : (
        description
      )}
    </Grid>
  </Grid>
);

export default CreateProductProfileDelivery;
