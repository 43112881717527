import React, {useEffect, useRef, useState} from 'react';

import {TokenService} from '@aglive/data-model';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import FieldHeading from '../../../presentation/FieldHeading';
import {useStyles} from './styles';
import MyButton from '../../../presentation/button';
import DeclarationCheckbox from './DeclarationCheckbox';

import {mobInfoDeclarations} from './mobInfoDeclarations';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {toggleModal} from '../../../store/modal/actions';
import {fileUploader} from '../../../store/product/actions';

interface MobMainSectionProps {
  mob: TokenService.AnimalPromotion['details']['mob'];
  mobList: TokenService.AnimalPromotion['details']['mob']['linkMob'][];
  validationState: TokenService.AnimalPromotion['details']['mob'];
  onMediaItemChange: (
    value: TokenService.AnimalPromotion['details']['mob']['media']['item'],
  ) => void;
  onMediaUrlChange: (value: string) => void;
  onLinkMobChange: (value: string) => void;
  onDisplayChange: (value: 'mob' | 'animal' | 'mob_animal') => void;
  onMsaChange: (value: boolean) => void;
  onSignedChange: (value: boolean) => void;
  onDeclarationsChange: (
    key: 'ERP' | 'BRD' | 'WHP' | 'WHP_ESI' | 'fiveInOne',
    value: boolean,
  ) => void;
}

const MobMainSection: React.FC<MobMainSectionProps> = ({
  mob,
  mobList,
  validationState,
  onMediaItemChange,
  onMediaUrlChange,
  onLinkMobChange,
  onDisplayChange,
  onMsaChange,
  onSignedChange,
  onDeclarationsChange,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.wallet);
  const signature = useAppSelector(
    (state) => state.user.userProfileData.signature,
  );

  const imageRef = useRef(null);
  const [mobDisplayOption, setMobDisplayOption] = useState<
    'mob_animal' | 'mob' | 'animal'
  >('mob_animal');

  useEffect(() => {
    if (!mob.display.isMob && mob.display.isAnimal) {
      setMobDisplayOption('animal');
    } else if (mob.display.isMob && !mob.display.isAnimal) {
      setMobDisplayOption('mob');
    } else {
      setMobDisplayOption('mob_animal');
    }
  }, []);

  const handleSignedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (signature?.length > 0) {
      onSignedChange(e.target.checked);
    } else {
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'No Signature Recorded',
          subtitle:
            'Please add your signature under User Settings in the Aglive app',
          button: 'Close',
        }),
      );
    }
  };

  const handleMobDisplayOption = (e) => {
    onDisplayChange(e.target.value);
    setMobDisplayOption(e.target.value);
  };

  // Handle image uploads
  const uploadImageHandler = async (
    e,
    imageDispatch: (value: string) => void,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        window.alert('Upload image size more than 10mb ！');
      } else {
        const response = await fileUploader(userId, e.target.files[0]);
        const responseData = response[0].data;
        if (typeof responseData == 'string') {
          imageDispatch(responseData);
        }
      }
    }
  };

  return (
    <>
      <FieldHeading
        title="Image or Video*"
        subtitle="Enhance your story with an image or video"
      />
      <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
        <RadioGroup
          aria-label="item"
          name="item"
          value={mob.media.item}
          onChange={(e) => {
            onMediaItemChange(
              e.target
                .value as TokenService.AnimalPromotion['details']['mob']['media']['item'],
            );
            onMediaUrlChange('');
            imageRef.current.value = '';
          }}>
          <FormControlLabel
            value="image"
            control={<Radio color="primary" id="MobDetailsImage-Radio" />}
            label="Image"
          />
          <Grid
            container
            alignItems="flex-start"
            justifyContent="center"
            spacing={3}
            style={{
              paddingLeft: '40px',
            }}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                variant="outlined"
                value={
                  mob.media.item === 'image' && mob.media.url
                    ? decodeURIComponent(mob.media.url.split('/').reverse()[0])
                    : ''
                }
                placeholder={'png, jpg, or jpeg'}
                disabled
                error={
                  !!validationState.media.url && mob.media.item === 'image'
                }
                helperText={
                  mob.media.item === 'image' && validationState.media.url
                }
                FormHelperTextProps={{
                  className: classes.errorHelperText,
                }}
                id="MobDetailsImage-TextField"
              />
            </Grid>
            <Grid item xs={5}>
              <MyButton
                text={'Upload File'}
                variant="outlined"
                onClick={() => {
                  imageRef.current.click();
                }}
                buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                disabled={mob.media.item !== 'image'}
              />
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onChange={(e) => {
                  uploadImageHandler(e, onMediaUrlChange);
                }}
                ref={imageRef}
                style={{height: 0, width: 0}}
                data-cy="MobDetailsImage"
              />
            </Grid>
          </Grid>
          <FormControlLabel
            value="video"
            control={<Radio color="primary" id="MobDetailsVideo-Radio" />}
            label="Video URL"
          />
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
            style={{
              paddingLeft: '40px',
            }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                value={mob.media.item === 'video' ? mob.media.url : ''}
                placeholder={'Enter URL'}
                className={classes.inputField}
                disabled={mob.media.item !== 'video'}
                error={
                  !!validationState.media.url && mob.media.item === 'video'
                }
                helperText={
                  mob.media.item === 'video' && validationState.media.url
                }
                FormHelperTextProps={{
                  className: classes.errorHelperText,
                }}
                onChange={(e) => {
                  onMediaUrlChange(e.target.value);
                }}
                id="MobDetailsVideo-TextField"
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>

      <Typography variant="h6" style={{fontWeight: 700, marginBottom: '10px'}}>
        Link Mob
      </Typography>
      <FormControl
        fullWidth
        variant="outlined"
        error={!!validationState.linkMob?.mobId}>
        <InputLabel id="select-link-mob">Please select</InputLabel>
        <Select
          value={mob.linkMob?.mobId || ''}
          labelId="select-link-mob"
          label="Please select"
          onChange={(e) => {
            onLinkMobChange(e.target.value as string);
          }}
          id="LinkMob-Dropdown">
          {mobList.length > 0 &&
            mobList.map((mob, index) => (
              <MenuItem value={mob.mobId} key={mob.mobId} id={`Mob${index}`}>
                {mob.mobName}
              </MenuItem>
            ))}
        </Select>
        {!!validationState.linkMob?.mobId && (
          <FormHelperText id="LinkMob-Dropdown-helper-text">{validationState.linkMob?.mobId}</FormHelperText>
        )}
      </FormControl>

      <Box mt={3}>
        <FieldHeading
          title="Display"
          subtitle="Select the information to display when QR code is scanned"
        />
        <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
          <RadioGroup
            aria-label="displayType"
            name="displayType"
            value={mobDisplayOption}
            onChange={handleMobDisplayOption}>
            <FormControlLabel
              value="mob_animal"
              control={<Radio color="primary" />}
              label="Display Mob and Animal Info"
              id="MobAndAnimal-Radio"
            />
            <FormControlLabel
              value="mob"
              control={<Radio color="primary" />}
              label="Display Mob Info Only"
              id="MobOnly-Radio"
            />
            <FormControlLabel
              value="animal"
              control={<Radio color="primary" />}
              label="Display Animal Info Only"
              id="AnimalOnly-Radio"
            />
          </RadioGroup>
        </Box>
      </Box>

      <Box mt={3} style={{opacity: mob.linkMob ? 1 : 0.6}}>
        <FieldHeading title="Declaration" subtitle="" />
        <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
          {/* Mob Info */}
          {mobInfoDeclarations.map((mobInfoDeclaration) => (
            <DeclarationCheckbox
              containerStyle={classes.mobInfoDeclaration}
              checked={mob.linkMob?.info[mobInfoDeclaration.key] || false}
              onChange={(e) =>
                onDeclarationsChange(mobInfoDeclaration.key, e.target.checked)
              }
              disabled={mob.linkMob === null}
              message={mobInfoDeclaration.message}
              key={mobInfoDeclaration.key}
              id={mobInfoDeclaration.key}
            />
          ))}
          {/* MSA declaration */}
          <DeclarationCheckbox
            containerStyle={classes.mobInfoDeclaration}
            checked={mob.msa}
            onChange={(e) => onMsaChange(e.target.checked)}
            disabled={mob.linkMob === null}
            message="I am a Meat Standards Australia (MSA) registered producer."
            errorText={validationState.msa && 'This field is required'}
            id="MSA"
          />
          {/* Signature */}
          <DeclarationCheckbox
            containerStyle={classes.signatureContainer}
            checked={mob.signed}
            onChange={handleSignedChange}
            disabled={mob.linkMob === null}
            message="Apply signature:
                    <br />
                    <br />I declare that, I am the owner or the person
                    responsible for the husbandry of the cattle and all the
                    information is true and correct."
            errorText={validationState.signed && 'Apply signature is required'}
            id="Signature"
          />
        </Box>
      </Box>
    </>
  );
};

export default MobMainSection;
