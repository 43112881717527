import React, {useState, ReactNode, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import FormControl from '@material-ui/core/FormControl';
import MyButton from '../../presentation/button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './style.css';

import {
  validateWithSchema,
  JSONSchemaValidationError,
} from '../../utils/csvReader';
import {inductionSchema, uniqueIds} from '../../config/schema';
import WORD from '../../intl/en';
import withHeader from '../../presentation/withHeader';
import CSVUploader from '../../presentation/CSVUploader';
import {HyperLink} from '../../presentation/word';
import CONSTANT from '../../config/constant';
import {createTokensByCSV} from '../../store/asset/actions';
import {AssetDialogMessage, cleanSelectedFile, assetStyles} from './Components';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {Buttons} from '../../presentation/ButtonsGroup';
import {ValidationErrorType, NetworkError} from '../../utils/error';
import {useHistory} from 'react-router-dom';
import {WebErrorType} from '../../utils/error';
import {schemaType} from '../../utils/type';
import {InputLabel} from '@material-ui/core';
import constant from '../../config/constant';
import {LayerNode} from '../../store/site/types';
import {fetchSiteTokens} from '../../store/site/actions';

const AssetInduction: React.FC<{}> = () => {
  const classes = assetStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const locationState = useAppSelector((state) => state.location);
  const [location, setLocation] = useState('');
  const [file, setFile] = useState<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [validationResult, setValidationResult] = useState({
    type: null as string | null,
    validationError: [] as Array<JSONSchemaValidationError>,
  });

  const [open, setOpen] = useState(false);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const [country, setCountry] = useState(businessProfile.businessCountry ?? 'Australia' as string);
  const {customSchema, template} = useAppSelector((state) => ({customSchema: state.user.schema?.induction, template: state.user.schema?.template}));
  const plugins = useAppSelector((state) => state.user.plugins.filter((plg) => plg.status === "activated"));

  const siteTokens = useAppSelector((state) => state.site)?.sort(
    (token, token2) => {
      return token.details.siteName > token2.details.siteName ? 1 : -1;
    },
  );
  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;
  const handleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
    child: ReactNode,
  ) => {
    const location = String(event.target.value);
    const country = locationState.location.find(
      (loc) => loc.PICAddress === location,
    )?.country;
    setLocation(location);
    setCountry(country ?? 'Australia');
  };
  // use Australia csv and schema by default
  const [csvTemplate, setCsvTemplate] = useState(
    CONSTANT.TEMPLATE.INDUCTION.AUSTRALIA.TEMP as string,
  );
  const [csvExample, setCsvExample] = useState(
    CONSTANT.TEMPLATE.INDUCTION.AUSTRALIA.EXAMPLE as string,
  );
  const [csvTemplateValue, setCsvTemplateValue] = useState(
    CONSTANT.TEMPLATE.INDUCTION.AUSTRALIA.VALUE as string,
  );
  const [csvSchema, setCsvSchema] = useState<schemaType>(
    inductionSchema.Australia,
  );

  // uniqueIdTypes defaut to Australia and change by country, for different payload externalIds
  const [uniqueIdTypes, setUniqueIdTypes] = useState(
    uniqueIds.Australia as Array<string>,
  );

  /**Warakirri changes */
  const isWarakirri = !!plugins.find((plg) => plg.name === "Dairy Reports");
  const isUSA = businessProfile.businessCountry === 'USA' || !businessProfile.businessCountry;
  const [customDropdown, setCustomDropdown] = useState(() => {
    let dropdown: {[key:string]: string} = {};
    customSchema?.dropDown?.forEach((dd) => {
      dropdown[dd.key] = '';
    })
    return dropdown;
  });
  const allSites: Array<{label: string, value: string}> = [];
  const siteStates: Array<{
    layers: LayerNode;
    location: string;
    region?: string;
    propertyType?: string;
  }> = siteTokens.map((token) => {
    const {layers, location, region, propertyType} = token.details;
    return {
      layers,
      location,
      region,
      propertyType,
    };
  });
  const siteDetailsMap = new Map<
    string,
    {location: string; region?: string; propertyType?: string}
  >();
  const handleDropdownChange = (isWarakirri?: boolean) => (event: React.ChangeEvent<{name?: string; value: string}>) => {
    setCustomDropdown({[event.target.name]: String(event.target.value)});
    if (isWarakirri && event.target.name === 'site') {
      setLocation(siteDetailsMap.get(event.target.value)?.location);
    }
  };
  /**flat all pic sites and save pic and site relation map*/
  if (siteStates.length > 0) {
    siteStates.forEach((siteState) => {
      allSites.push({label: siteState.layers.name, value: siteState.layers.name});
      siteDetailsMap.set(siteState.layers.name, {...siteState});
    });
  }

  const customDropdownList = customSchema?.dropDown?.map((dd) => ({
      title: dd.title,
      name: dd.key,
      value: customDropdown[dd.key],
      onChange: handleDropdownChange(isWarakirri),
      list: dd.key === 'site' ? allSites : dd.list,
      ifUpperCase: dd.ifUpperCase,
      required: dd.required
  }));
  const disableSave = () => {
    if (customSchema?.dropDown) {
      const reqDd = customSchema.dropDown.filter((dd) => !!dd.required);
      const checkDropdown = reqDd.map((dd) => customDropdown[dd.key]).filter((dd) => !!dd);
      if (csvData.length && checkDropdown.length >= reqDd.length) return false;
      else return true;
    } else if(isUSA) {
      if (csvData.length) return false
      else return true
    }
    else{
      if (csvData.length && location.length) return false;
      else return true;
    }
  };
  // change csv template and validation schema by country of PIC
  useEffect(() => {
    if (customSchema?.schema) {
      let schema = JSON.parse(JSON.stringify(customSchema.schema));
      if (isWarakirri && schema.items.properties.breeder_site) {
        delete schema.items.properties.breeder_site.type;
        schema.items.properties.breeder_site.enum = siteStates.filter((ss) => ss.propertyType === 'Dairy').map((ss) => ss.layers.name.toLowerCase());
        schema.items.properties.breeder_site.customError = "Breeder Site must be an existing dairy property";
      }
      setCsvSchema(schema);
      setUniqueIdTypes(customSchema.uniqueIds);
      setCsvTemplate(template.INDUCTION.TEMP);
      setCsvExample(template.INDUCTION.EXAMPLE);
      setCsvTemplateValue(template.INDUCTION.VALUE);
    } else {
      setCsvSchema(inductionSchema[country]);
      setUniqueIdTypes(uniqueIds[country]);
      setCsvTemplate(CONSTANT.TEMPLATE.INDUCTION[country.toUpperCase()].TEMP);
      setCsvExample(CONSTANT.TEMPLATE.INDUCTION[country.toUpperCase()].EXAMPLE);
      setCsvTemplateValue(
        CONSTANT.TEMPLATE.INDUCTION[country.toUpperCase()].VALUE,
      );
    }
  }, [country, customSchema]);

  useEffect(() => {
    dispatch(fetchSiteTokens());
  }, [dispatch]);

  const csvRequestData = () => {
    const csvObj = {
      template: csvTemplateValue,
      schemaType: csvSchema.id,
      pic_id: location,
      pic_name: Object.values(locationState.location)?.find(
        (item) => item?.PICAddress === location,
      )?.locationNickname,
      type: CONSTANT.ASSETTYPE.ASSET as any,
      externalIds: uniqueIdTypes.length ? uniqueIdTypes.reduce((sum, current) => sum + ',' + current): '',
      uniqueExternalIds: uniqueIdTypes.length ? uniqueIdTypes.reduce(
        (sum, current) => sum + ',' + current,
      ) : '',
      blockchain: 'true',
      file,
      csvData,
    };
    if (customSchema?.dropDown) {
      customSchema?.dropDown.forEach((dd) => {
        csvObj[dd.dbKey ?? dd.key] = customDropdown[dd.key];
      });

      if (isWarakirri && customDropdown['site']) {
        const {region, propertyType} = siteDetailsMap.get(
          customDropdown['site'],
        );
        // Region and Property Type is a new optional field, existing sites may not have them
        if (region) {
          csvObj['preset_site_moved_to_region'] = region;
        }
        if (propertyType) {
          csvObj['preset_site_moved_to_property_type'] = propertyType;
        }
      }
    }
    return csvObj;
  };
  return (
    <>
      <Typography variant="h3">{'Setting'}</Typography>
      <Grid container>
        {customDropdownList?.length ? customDropdownList?.map((field) => {
            return (
              <Grid item xs={6} sm={6}>
                <Typography variant="h6" className={classes.subTitle}>
                  {field.title}
                </Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small">
                  <InputLabel
                    id={`asset-induction-select-${field.name}`}
                    className={classes.selectLabel}>
                    Please select
                  </InputLabel>
                  <Select
                    labelId={`asset-induction-select-${field.name}`}
                    className={classes.select}
                    name={field.name}
                    required={field.required}
                    value={field.value}
                    onChange={field.onChange}
                    label="Please select">
                    {field.list?.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {field.ifUpperCase ? item.label.toUpperCase() : item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )
          }) : (
          <Grid item xs={4} sm={4}>
            <Typography variant="h6" className={classes.subTitle}>
              {isAustralia
                ? constant.LOCALISATION_PIC.AU_LOCATION_PIC
                : constant.LOCALISATION_PIC.CA_LOCATION_PID}
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small">
              <InputLabel
                id="asset-induction-select-location"
                className={classes.selectLabel}>
                Please select
              </InputLabel>
              <Select
                labelId="asset-induction-select-location"
                className={classes.select}
                id={'Location'}
                value={location}
                onChange={handleChange}
                label="Please select">
                {locationState.location?.filter(({PICAddress, locationId}) => PICAddress || locationId).map(({PICAddress, locationId}) => (
                  <MenuItem value={PICAddress ?? locationId} key={PICAddress ?? locationId}>
                    {PICAddress?.toUpperCase() ?? locationId?.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <CSVUploader
        file={file}
        setFile={setFile}
        setCSVData={setCSVData}
        id={'assetInductionCSV'}
      />

      <Grid container>
        <Grid
          item
          className={classes.linksContainer}
          style={{
            maxWidth: window.innerWidth / 2,
            width: '100%',
            textAlign: 'right',
          }}>
          <HyperLink className={classes.linksBetween} href={csvTemplate}>
            Download CSV Template
          </HyperLink>
          <HyperLink href={csvExample}>Download Sample CSV</HyperLink>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classes.buttonGrid}>
        <MyButton
          // disabled={csvData.length && location.length ? false : true}
          disabled={disableSave()}
          text={'Save'}
          variant="contained"
          onClick={() => {
            validateWithSchema(csvData, csvSchema, true, uniqueIdTypes)
              .then(() => {
                //reset if have previous errors
                setValidationResult({
                  type: null,
                  validationError: [],
                });

                setCSVData((prevCSVEntries) =>
                  prevCSVEntries.map((CSVEntry) => {
                    CSVEntry.externalIds = uniqueIdTypes.reduce(
                      (accumIDs, curID) => ({
                        ...accumIDs,
                        [curID]: CSVEntry[curID],
                      }),
                      {},
                    );
                    return CSVEntry;
                  }),
                );
              })
              .catch((error) => {
                const e = error as ValidationErrorType<
                  Array<JSONSchemaValidationError>
                >;
                setValidationResult({
                  type: e.type,
                  validationError: e.error ?? [],
                });
              });
            setOpen(true);
          }}
        />
      </Grid>
      {open && (
        <AssetDialogMessage
          open={open}
          isErr={validationResult.type ? true : false}
          fileName={file?.name}
          handleClose={() => {
            cleanSelectedFile('csvReader');
            setFile(null);
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
            setOpen(false);
          }}
          validationResult={validationResult}
          rightButtonClick={() => {
            setOpen(false);
            dispatch(createTokensByCSV(csvRequestData(), history.goBack))
              .then(() => {
                cleanSelectedFile('csvReader');
                setFile('');
              })
              .catch((error: WebErrorType) => {
                cleanSelectedFile('csvReader');
                setFile('');
                if (error instanceof NetworkError) {
                  const isTable =
                    Array.isArray(error.error) &&
                    (error.error[0].source?.rows?.length ||
                      error.error[0].source?.data?.length);

                  if (isTable) {
                    const errorList: Array<{row: number; message: any}> = [];
                    if (error.error[0].source?.rows?.length) {
                      // kept the old code for backwards compatibility
                      error.error[0].source.rows.forEach((num: number) => {
                        errorList.push({
                          row: num + 1,
                          message: error.error[0].details,
                        });
                      });
                    } else {
                      error.error[0].source.data.forEach(
                        ({error, row}: {error: Array<any>; row: any}) => {
                          errorList.push({
                            row,
                            message: error[0],
                          });
                        },
                      );
                    }
                    dispatch(
                      toggleModal({
                        status: 'failed',
                        title: error.title,
                        errorInfo: errorList,
                        renderButton: (
                          <Buttons
                            leftButtonTitle="Cancel"
                            rightButtonTitle="Upload Csv Again"
                            leftButtonOnClick={() => {
                              dispatch(toggleModalOff());
                              cleanSelectedFile('csvReader');
                              setFile('');
                              setCSVData([]);
                            }}
                            rightButtonOnClick={() => {
                              dispatch(toggleModalOff());
                              cleanSelectedFile('csvReader');
                              setFile('');
                              setCSVData([]);
                            }}
                          />
                        ),
                      }),
                    );
                  } else {
                    dispatch(
                      toggleModal({
                        status: 'failed',
                        title: error.title,
                        subtitle: error.message,
                      }),
                    );
                    console.error(
                      'Error.error undefined. Modal will display fallback message',
                    );
                  }
                } else {
                  dispatch(
                    toggleModal({
                      status: 'failed',
                      title: error.title,
                      subtitle: error.message,
                    }),
                  );
                }
              });
          }}
          rightButtonClickIsErr={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
            setOpen(false);
          }}
          csvData={csvData}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: WORD.ASSET_INDUCTION,
    back: true,
    id: 'AssetInductionBack',
  },
  AssetInduction,
);
