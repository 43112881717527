import {History} from 'history';
import API from '../../config/api';
import CONSTANT from '../../config/constant';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {callAPI} from '../../utils/network';
import {FETCH_ASSET_PROFILE} from './types';
import {WebErrorType} from '../../utils/error';
import {toggleModal, toggleModalOff} from '../modal/actions';
import {
  RawAssetProfile,
  AssetProfileDetails,
  STORE_TEMP_ASSET_PROFILE,
} from './types';

export function fetchAssetProfiles(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: Array<RawAssetProfile> = await callAPI({
        url: API.POST.getTokenByBusiness,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [CONSTANT.ASSETTYPE.ASSET_PROFILE],
          activityType: [],
          businessId: [getState().user.userProfileData.businessId],
        },
      });

      if (response.length > 0) {
        const userIds: string[] = [];
        // Loop through the asset profiles to get the list of creators
        response.forEach((assetProfile: RawAssetProfile) => {
          if (!userIds.some((userId) => userId === assetProfile.userId)) {
            userIds.push(assetProfile.userId);
          }
        });

        // Fetch all asset profile creators to get their name
        const userResult = await callAPI({
          url: API.POST.getTokenbyAddr,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            type: [CONSTANT.ASSETTYPE.USER],
            activityType: [],
            externalIds: userIds.map((userId) => ({userId})),
          },
        });

        // Map the user's name to the asset profile
        response.forEach((assetProfile: RawAssetProfile, index: number) => {
          const user = userResult.find(
            (user) => assetProfile.userId === user.userId,
          );

          if (user) {
            response[index].details.createdBy = user.details.name;
          } else {
            response[index].details.createdBy = 'Unknown';
          }
        });
      }

      dispatch({
        type: FETCH_ASSET_PROFILE,
        payload: response,
      });
    } catch (e) {
      if (e.code === 'E_NOT_FOUND') {
        dispatch({
          type: FETCH_ASSET_PROFILE,
          payload: [],
        });
      } else {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchSingleAssetProfile(
  id: string,
  history: History,
): AppThunk<Promise<RawAssetProfile>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: Array<RawAssetProfile> = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [CONSTANT.ASSETTYPE.ASSET_PROFILE],
          activityType: [],
          externalIds: [{agliveToken: id}],
        },
      });

      return response[0];
    } catch (e) {
      const error = e as WebErrorType;
      if (error.code === 'E_NOT_FOUND') {
        error.title = 'Asset Profile Not Found';
      }
      history.replace('/private/codes/assetProfile');
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function createAssetProfile(
  assetProfile: AssetProfileDetails,
  successCB?: () => void,
  history?: History,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      // Call create token API
      const response = await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.ASSET_PROFILE,
              tokenId: '',
              details: assetProfile,
            },
          ],
        },
      });
      const data: RawAssetProfile[] = response.data;
      /**
       * If user came to create asset profile from generate assets page, store the newly generated
       * token into redux for dropdown pre-population purpose, and will be removed from redux
       * state once the user returned back to generate assets page
       */

      if (data[0] && history?.location?.state) {
        const {state} = history.location;
        if (
          typeof state === 'object' &&
          'prevState' in state &&
          state.prevState === '/private/codes/generate/new'
        ) {
          dispatch({type: STORE_TEMP_ASSET_PROFILE, payload: data[0]});
        }
      }

      if (successCB) {
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Saved',
            button: 'Close',
            CTAHandler: successCB,
          }),
        );
      }
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function editAssetProfile(
  assetProfile: AssetProfileDetails,
  oldAssetProfile: RawAssetProfile,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      // Call create activity API to remove existing content and attatchment
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.ASSET_PROFILE,
              externalIds: {
                agliveToken: oldAssetProfile.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'DEL_details',
                  details: {
                    content: oldAssetProfile.details.content,
                    attachment: oldAssetProfile.details.attachment,
                  },
                },
              ],
            },
          ],
        },
      });
      // Call create activity API to update with the new asset profile details
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.ASSET_PROFILE,
              externalIds: {
                agliveToken: oldAssetProfile.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'UP_details',
                  details: assetProfile,
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function manageArchiveAssetProfile(
  assetProfile: RawAssetProfile,
  archive: boolean,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(toggleModalOff());
      dispatch({type: SPINNER_TOGGLE_ON});
      // Call create activity API to set archived in asset profile details to true
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.ASSET_PROFILE,
              externalIds: {
                agliveToken: assetProfile.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'UP_details',
                  details: {
                    archived: archive,
                  },
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: archive ? 'Archived' : 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
