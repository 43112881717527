import { PageHeader } from '../../presentation/withHeader';
import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { assetStyles } from '../asset/Components';
import COLOR from '../../styled/colors';
import { Paragraph } from '../../presentation/word';
import { useLocation } from 'react-router-dom';

export const useStyles = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
    justifyContent: 'center',
  },
}));

const Treatment: React.FC<{}> = () => {
  const classes = { ...assetStyles(), ...useStyles() };
  const state: any = useLocation().state;
  const { title } = state;

  return (
    <PageHeader config={{ title: title, margin: 60, back: true , id:'Treatment'}}>
      <Grid>
        <Typography variant="h3">
          Treatment
        </Typography>
        <Grid container className={classes.greyBox}>
          <Paragraph>No Treatment Records</Paragraph>
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default Treatment;