import React, {useEffect, useState} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import {useHistory} from 'react-router-dom';
import agliveLogoLarge from '../../img/agliveLogoLarge.png';
import {
  ContainerFoot,
  Logo,
  SsoContainer,
  SsoLeftSection,
  SsoLoginBox,
  SsoRightSection,
} from './styles';
import {Header2, Header3, HyperLink} from '../../presentation/word';
import './styles.css';
import COLOR from '../../styled/colors';
import MyButton from '../../presentation/button';
import {
  Grid,
  TextField,
  Typography,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import CONSTANT from '../../config/constant';
import {clearWarning, isAllowedtoSubmit} from '../../utils/validation';
import {POST_BUSINESS_PROFILE_DATA} from '../../store/profile/types';
import {updateSignupUserProfile} from '../../store/profile/actions';
import {submitCodeHandler} from '../../store/auth/actions';
import {LOGOUT} from '../../store/auth/types';
import {HelpOutlined} from '@material-ui/icons';
import {BusinessMisc} from '@aglive/data-model';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  signupContainer: {
    marginTop: 16,
  },
  textfield: {
    height: 50,
    marginTop: 6,
  },
  label1: {
    fontWeight: 700,
  },
  label2: {
    fontWeight: 700,
    marginTop: 12,
  },
  label3: {
    marginTop: 20,
  },
  invitationCode: {
    height: 50,
    marginTop: 6,
    width: 320,
  },
  greenText: {
    color: COLOR.GREENT_TEXT,
    fontSize: 16,
    textTransform: 'none',
  },
  grayText: {
    fontWeight: 400,
    marginTop: 16,
    color: COLOR.GRAY_TEXT,
    fontSize: 14,
    padding: '0px 20px 0px 20px',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-60px 95px 0px 45px',
    padding: 20,
  },

  header2: {
    maxWidth: 350,
  },
  line1: {
    width: '80%',
    border: '1px solid #d9d9d9',
  },
  line2: {
    width: '100%',
    border: '1px solid #d9d9d9',
    padding: 0,
  },
  header3: {
    textAlign: 'left',
    marginTop: 20,
  },
  QuestionIcon: {
    width: '20px',
    height: '20px',
    padding: '0px 0px',
  },
  select: {
    height: 50,
    textAlign: 'left',
    marginTop: 6,
  },
}));

type Props = {};
type SignupDetailsType = {
  fullName: string;
  email: string;
  businessName?: string;
  businessNumber?: string;
  businessCountry?: string;
  invitationCode?: string;
};

const initailSignupDetails: SignupDetailsType = {
  fullName: '',
  email: '',
  businessName: '',
  businessNumber: '',
  businessCountry: '',
  invitationCode: '',
};

const SsoLogin: React.FC<Props> = (_) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userId = useAppSelector(
    (state) => state.user.userProfileData.externalIds.userId,
  );
  const COUNTRY_LIST = Object.keys(BusinessMisc.SupportedCountries);
  const [signupDetails, setSignupDetails] =
    useState<SignupDetailsType>(initailSignupDetails);
  const [signupValidation, setSignupValidation] = useState({
    ...initailSignupDetails,
  });
  const {name, emailAddress} = useAppSelector(
    (state) => state.user.userProfileData,
  );
  const [joinBusinessFlag, setJoinBusinessFlag] = useState(false);

  const handleSignupChange =
    (key: keyof typeof signupDetails) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSignupDetails((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));
    };

  const handleSignup = () => {
    const validatedData = validateSignupData(signupDetails, joinBusinessFlag);
    setSignupValidation(validatedData);
    const isAllowed = isAllowedtoSubmit(validatedData);

    if (!isAllowed) return;

    const userInfo = {
      name: signupDetails.fullName,
      emailAddress: signupDetails.email,
      // mobileNumber: '',
    };

    if (!joinBusinessFlag) {
      // update user name and email
      dispatch(updateSignupUserProfile(userInfo, userId));
      history.push({
        pathname: '/select-industry',
        state: {
          businessName: signupDetails.businessName,
          businessNumber: signupDetails.businessNumber,
          businessCountry: signupDetails.businessCountry,
        },
      });
    } else {
      dispatch(
        submitCodeHandler(signupDetails.invitationCode, userId, history),
      ).then(() => {
        // update user name and email
        dispatch(updateSignupUserProfile(userInfo, userId, signupDetails.invitationCode));
      });
    }
  };

  // reset validationState and warnings onKeyDown
  useEffect(() => {
    const resetValidationState = () =>
      setSignupValidation((prevState) => clearWarning(prevState));
    window.addEventListener('keydown', resetValidationState);

    return () => {
      window.removeEventListener('keydown', resetValidationState);
    };
  }, []);

  // reset validationState and warnings when select country
  useEffect(() => {
    if (signupDetails.businessCountry) {
      setSignupValidation((prevState) => clearWarning(prevState));
    }
  }, [signupDetails.businessCountry]);

  useEffect(() => {
    setSignupDetails((prevState) => ({
      ...prevState,
      fullName: name,
      email: emailAddress,
    }));
  }, [emailAddress, name]);

  // reset values when change joinBusinessFlag
  useEffect(() => {
    setSignupDetails((prevState) => ({
      ...prevState,
      businessName: '',
      businessNumber: '',
      businessCountry: '',
      invitationCode: '',
    }));
  }, [joinBusinessFlag]);

  return (
    <Grid container className={classes.outerContainer}>
      <SsoContainer>
        <SsoLoginBox>
          <SsoLeftSection>
            <div className={classes.leftSection}>
              <Logo src={agliveLogoLarge} />
              <Header2 invert={true} className={classes.header2}>
                Prove the value of your product and boost sales{' '}
              </Header2>
            </div>
            <ContainerFoot>
              Copyright &reg; 2020 Aglive International Pty Ltd ACN 605 120 081
            </ContainerFoot>
          </SsoLeftSection>
          <SsoRightSection>
            <Header2 invert={true} className={classes.header2}>
              Welcome to Aglive
            </Header2>
            <hr className={classes.line1} />
            <Grid
              item
              container
              direction="column"
              xs={4}
              className={classes.signupContainer}>
              <Typography variant="h6" align="left" className={classes.label1}>
                {'Full Name*'}
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                value={signupDetails.fullName}
                onChange={handleSignupChange('fullName')}
                className={classes.textfield}
                helperText={signupValidation.fullName}
                error={!!signupValidation.fullName}
              />
              <Typography variant="h6" align="left" className={classes.label2}>
                {'Email*'}
              </Typography>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                value={signupDetails.email}
                onChange={handleSignupChange('email')}
                className={classes.textfield}
                helperText={signupValidation.email}
                error={!!signupValidation.email}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={4}
              style={{
                marginTop: 10,
              }}>
              <Header3 invert={true} className={classes.header3}>
                Business Info
              </Header3>
              <hr className={classes.line2} />
              <Grid
                item
                container
                justifyContent="flex-end"
                alignContent="flex-start">
                {!joinBusinessFlag ? (
                  <Button
                    onClick={() => {
                      setJoinBusinessFlag(true);
                    }}>
                    <span className={classes.greenText}>
                      <u>{'Join Existing Business?'}</u>
                    </span>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setJoinBusinessFlag(false);
                      dispatch({
                        type: POST_BUSINESS_PROFILE_DATA,
                        payload: {},
                      });
                    }}>
                    <span className={classes.greenText}>
                      <u>{'Register New Business?'}</u>
                    </span>
                  </Button>
                )}
              </Grid>

              {joinBusinessFlag ? (
                <>
                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.label1}>
                    {'Enter Invitation Code'}
                  </Typography>
                  <TextField
                    id={'invitationCode'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={signupDetails.invitationCode}
                    onChange={handleSignupChange('invitationCode')}
                    className={classes.textfield}
                    helperText={signupValidation.invitationCode}
                    error={!!signupValidation.invitationCode}
                  />
                  <div style={{display: 'flex', marginTop: 18}}>
                    <HelpOutlined style={{height: 24, width: 24}} />
                    <HyperLink
                      style={{marginLeft: 10}}
                      target="_blank"
                      href={CONSTANT.GET_INVITATION_CODE_URL}>
                      How do I get the code?
                    </HyperLink>
                  </div>
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.label1}>
                    {'Business Name*'}
                  </Typography>
                  <TextField
                    id={'SsoBusinessName'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={signupDetails.businessName}
                    onChange={handleSignupChange('businessName')}
                    className={classes.textfield}
                    helperText={signupValidation.businessName}
                    error={!!signupValidation.businessName}
                  />

                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.label2}>
                    {'Business Number*'}
                  </Typography>
                  <TextField
                    id={'SsoBusinessNumber'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={signupDetails.businessNumber}
                    onChange={handleSignupChange('businessNumber')}
                    className={classes.textfield}
                    helperText={signupValidation.businessNumber}
                    error={!!signupValidation.businessNumber}
                  />
                  <Grid
                    item
                    container
                    direction="column"
                    style={{marginBottom: 20}}>
                    <Typography
                      variant="h6"
                      align="left"
                      className={classes.label2}>
                      {'Country*'}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      error={!!signupValidation.businessCountry}>
                      <InputLabel id={'businessCountry'}>
                        {'Please Select'}
                      </InputLabel>
                      <Select
                        id={'SsoBusinessCountry'}
                        value={signupDetails.businessCountry ?? 'Please Select'}
                        labelId={'constru-select'}
                        label={'Please Select'}
                        className={classes.select}
                        onChange={handleSignupChange('businessCountry')}>
                        {COUNTRY_LIST.map((cty) => (
                          <MenuItem value={cty} key={cty}>
                            {cty}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!signupValidation.businessCountry && (
                        <FormHelperText>
                          {signupValidation.businessCountry}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={4}
              alignContent="center"
              justifyContent="center"
              alignItems="center">
              <MyButton
                text={'Sign Up'}
                variant="contained"
                width={296}
                height={64}
                fontSize={25}
                marginTop={10}
                onClick={handleSignup}
              />
              <Typography align="center" className={classes.grayText}>
                By clicking on Sign Up, you agree to Aglive&apos;s Terms of Use
                and Privacy Policy
              </Typography>
              <Button
                onClick={() => {
                  dispatch({
                    type: LOGOUT,
                  });
                  history.push({
                    pathname: '/qr-login',
                    state: {qrLoginFlag: false},
                  });
                }}>
                <span className={classes.greenText}>
                  <u>{'Sign in using QR Scan'}</u>
                </span>
              </Button>
            </Grid>
          </SsoRightSection>
        </SsoLoginBox>
      </SsoContainer>
    </Grid>
  );
};

export default SsoLogin;

const validateSignupData = (
  signupDetails: SignupDetailsType,
  joinBusinessFlag: boolean,
) => {
  var validationState = {...initailSignupDetails};
  const requiredField = 'This field is required';
  if (signupDetails.fullName.trim() === '') {
    validationState.fullName = requiredField;
  }
  if (signupDetails.email.trim() === '') {
    validationState.email = requiredField;
  } else if (!CONSTANT.EMAIL_REGEX.test(signupDetails.email)) {
    validationState.email = 'Please input a valid email';
  }
  if (!joinBusinessFlag) {
    if (signupDetails.businessName.trim() === '') {
      validationState.businessName = requiredField;
    }
    if (signupDetails.businessNumber.trim() === '') {
      validationState.businessNumber = requiredField;
    }
    if (signupDetails.businessCountry.trim() === '') {
      validationState.businessCountry = 'Please select business country';
    }
  } else {
    if (signupDetails.invitationCode.trim() === '') {
      validationState.invitationCode = requiredField;
    }
  }

  return validationState;
};
