import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import CONSTANT from 'src/config/constant';
import DropdownSelection from 'src/presentation/DropdownSelection';
import {LightTooltip} from 'src/presentation/LightTooltip';
import QrCodeIcon from 'src/presentation/QrCodeIcon';
import {
  CODE_SEQUENCE_MAX_DIGITS,
  getPurpose,
  padZeros,
  purposeOptions,
} from '../utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {RawAssetProfile} from 'src/store/assetProfile/types';
import {SiteTokenResponse, SiteTreeLayerNode} from 'src/store/site/types';
import {flattenTree} from 'src/store/site/tree';
import MyButton from 'src/presentation/button';
import {GmpPermit, OdcPermit} from '@aglive/data-model/dist/misc/business';
import {TokenService} from '@aglive/data-model';
import COLOR from 'src/styled/colors';
import HelpIcon from '@material-ui/icons/Help';
import {CreateAssetDetails} from 'src/store/plant/types';
import MyModal from 'src/presentation/Modal';
import {DialogContentSubtitle} from 'src/presentation/DialogContentSubtitle';

export type AssetDetails = {
  permit: string;
  assetProfile: string;
  pic_id: string;
  layerId: string;
  quantity: string;
  startSequence: string;
  endSequence: string;
  remaining: string;
  maxNumber: string;
  purpose: string;
  externalSource: boolean;
  siteAgliveToken: string;
};

export type GenerateAssetPayload = {
  download: boolean;
  assetDetails: CreateAssetDetails;
  assetProfile: RawAssetProfile;
  siteLayerName: string;
};

type GenerateAssetsFromProps = {
  pathname: string;
  initialAssetProfileId: string | undefined;
  strainStartNumber: number | undefined;
  onStrainChange: (strain: string) => void;
  assetProfiles: RawAssetProfile[];
  allSites: SiteTokenResponse[];
  locations: TokenService.BusinessToken['details']['location'];
  permits: OdcPermit[] | GmpPermit[];
  onGenerateAssets: (assetPayload: GenerateAssetPayload) => void;
};

export const assetDetailsInitialState: AssetDetails = {
  permit: '',
  assetProfile: '',
  pic_id: '', // pic_id is supposed to be replaced with a proper ID used for Cannabis in later phase
  layerId: '',
  quantity: '',
  startSequence: '',
  endSequence: '0',
  remaining: '-',
  maxNumber: '',
  purpose: '',
  externalSource: false,
  siteAgliveToken: '',
};

const CREATE_ASSET_PROFILE_PATH = '/private/assets/assetProfile/new';

const GenerateAssetsForm: React.FC<GenerateAssetsFromProps> = ({
  initialAssetProfileId,
  pathname,
  strainStartNumber,
  assetProfiles,
  permits,
  allSites,
  locations,
  onGenerateAssets,
  onStrainChange,
}) => {
  const classes = useStyles();
  const [validationState, setValidationState] = useState(
    getInitialValidationState(assetDetailsInitialState),
  );

  const [assetDetails, setAssetDetails] = useState<AssetDetails>({
    ...assetDetailsInitialState,
  });

  const selectedPermit = useMemo(() => {
    return permits.find((p) => p.permitId === assetDetails.permit);
  }, [permits, assetDetails.permit]);

  const siteName = useMemo(() => {
    const selectedSite = findSite(allSites, assetDetails.siteAgliveToken);
    return selectedSite?.details.siteName;
  }, [assetDetails.siteAgliveToken, allSites]);

  const selectedAsset = useMemo(() => {
    return findAsset(assetProfiles, assetDetails.assetProfile);
  }, [assetProfiles, assetDetails.assetProfile]);

  const siteLayers = useMemo(() => {
    const selectedSite = findSite(allSites, assetDetails.siteAgliveToken);
    if (selectedSite) {
      const layers = flattenTree(selectedSite.details.layers);
      if (layers.length > 1) {
        layers.shift();
        return layers;
      }
    }
    return [];
  }, [assetDetails.siteAgliveToken, allSites]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle all input fields
  const handleInputChange = useCallback(
    (value: any, key: keyof AssetDetails) => {
      if (key === 'quantity') {
        setAssetDetails((prevState) => {
          let quantity = parseInt(value);
          let startSequence = parseInt(prevState.startSequence);
          if (isNaN(startSequence)) {
            startSequence = 0;
          }
          let endSequence = isNaN(quantity)
            ? startSequence
            : startSequence + quantity - 1;
          return {
            ...prevState,
            quantity: value,
            endSequence: padZeros(`${endSequence}`, CODE_SEQUENCE_MAX_DIGITS),
            startSequence: padZeros(
              `${startSequence}`,
              CODE_SEQUENCE_MAX_DIGITS,
            ),
          };
        });
      } else if (key === 'permit') {
        const permit = findPermit(permits, value);
        if (!permit) {
          return;
        }

        if (
          permit.balance != null &&
          permit.balance !== undefined &&
          permit.balance <= 0 &&
          !!permit.maxNumber
        ) {
          setIsModalOpen(true);
        }

        const hasBalance =
          !!permit.maxNumber &&
          permit.balance != null &&
          !isNaN(permit.balance);
        const remaining = hasBalance ? permit.balance : '-';

        const selectedSite = findSiteByPermitId(locations, allSites, value);
        const siteId = selectedSite?.externalIds[0]?.agliveToken || '';

        setAssetDetails((prevState) => ({
          ...prevState,
          permit: permit.permitId,
          siteAgliveToken: siteId,
          layerId: '',
          pic_id: selectedSite?.details.location ?? '',
          maxNumber: !!permit.maxNumber ? `${permit.maxNumber}` : 'Unlimited',
          remaining: `${remaining}`,
        }));
      } else if (key === 'assetProfile') {
        const assetProfile = findAsset(assetProfiles, value);
        if (!assetProfile) {
          setAssetDetails((prevState) => ({
            ...prevState,
            startSequence: '',
            endSequence: '',
          }));
          return;
        }
        setAssetDetails((prevState) => ({
          ...prevState,
          assetProfile: value,
        }));
        onStrainChange(assetProfile.details.mainDisplay.strain);
      } else {
        setAssetDetails((prevState) => ({...prevState, [key]: value}));
      }
    },
    [permits, locations, allSites, assetProfiles, onStrainChange],
  );

  const handleGenerate = useCallback(
    ({
      download,
      assetDetails,
      assetProfiles,
      siteLayers,
    }: {
      download: boolean;
      assetDetails: AssetDetails;
      assetProfiles: Array<RawAssetProfile>;
      siteLayers: Array<SiteTreeLayerNode>;
    }) => {
      const validatedData = validateData(assetDetails);
      setValidationState(validatedData);
      const canSubmit = isAllowedtoSubmit(validatedData);
      if (canSubmit) {
        const purpose = getPurpose(assetDetails.purpose);
        const payload = {
          ...assetDetails,
          ...purpose,
          quantity: parseInt(assetDetails.quantity),
        };

        const siteLayerName = siteLayers.find(
          (site) => site.id === payload.layerId,
        )?.layerName;
        const assetProfile = assetProfiles.find(
          (assetProfile) =>
            assetProfile.externalIds[0].agliveToken === payload.assetProfile,
        );
        const assetPayload = {
          download,
          assetDetails: payload,
          assetProfile,
          siteLayerName,
        };
        onGenerateAssets(assetPayload);
      }
    },
    [onGenerateAssets],
  );

  const [loadedAssetProfile, setLoadedAssetProfile] = useState(false);
  useEffect(() => {
    if (initialAssetProfileId && !loadedAssetProfile) {
      handleInputChange(initialAssetProfileId, 'assetProfile');
      setLoadedAssetProfile(true);
    }
  }, [
    initialAssetProfileId,
    assetDetails,
    handleInputChange,
    loadedAssetProfile,
  ]);

  useEffect(() => {
    if (strainStartNumber !== undefined) {
      let quantity = parseInt(assetDetails?.quantity);
      const endSequence = isNaN(quantity)
        ? strainStartNumber
        : strainStartNumber + quantity - 1;
      setAssetDetails((prevState) => ({
        ...prevState,
        startSequence: `${strainStartNumber}`,
        endSequence: `${endSequence}`,
      }));
    }
  }, [strainStartNumber, assetDetails?.quantity]);

  return (
    <>
      <MyModal
        isOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
        message={{
          status: 'warning',
          title: `Insufficient Plant Codes under ${selectedPermit?.permitNumber}`,
          customContent: (
            <DialogContentSubtitle
              texts={[
                'Please select a different permit or',
                'update the permit under Business Profile',
              ]}
            />
          ),
        }}
      />
      <Grid item xs={12} className={classes.mainContainer}>
        <Grid container item>
          {/* Selections */}
          <Grid item xs={12} style={{minHeight: '25rem'}}>
            <Grid container item direction="row" spacing={3}>
              {/* ODC Permit Number */}
              <Grid item xs={12} lg={6}>
                <DropdownSelection
                  labelId="permitSelect"
                  id="permitSelectOption"
                  title="ODC Permit Number"
                  error={validationState.permit}
                  value={assetDetails.permit}
                  onChange={(e) => handleInputChange(e.target.value, 'permit')}
                  linkElement={
                    <Link
                      to={{
                        pathname: CONSTANT.EDIT_BUSINESS_PATH,
                        hash: `#${CONSTANT.ODC_LICENSE_HASH}`,
                        state: {prevState: pathname},
                      }}
                      className={classes.hyperlink}>
                      Create new
                    </Link>
                  }>
                  {permits.map((permit) => (
                    <MenuItem value={permit.permitId} key={permit.permitId}>
                      {permit.permitNumber}
                    </MenuItem>
                  ))}
                </DropdownSelection>
              </Grid>
              {/* Asset Profile */}
              <Grid item xs={12} lg={6}>
                <DropdownSelection
                  title="Strain"
                  labelId="strainSelect"
                  id="Strain"
                  error={validationState.assetProfile}
                  value={assetDetails.assetProfile}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'assetProfile')
                  }
                  linkElement={
                    <Link
                      to={{
                        pathname: CREATE_ASSET_PROFILE_PATH,
                        state: {prevState: pathname},
                      }}
                      className={classes.hyperlink}>
                      Create new
                    </Link>
                  }>
                  {assetProfiles.map((assetProfile) => (
                    <MenuItem
                      value={assetProfile.externalIds[0].agliveToken}
                      key={assetProfile.externalIds[0].agliveToken}>
                      {assetProfile.details.mainDisplay.strain}
                    </MenuItem>
                  ))}
                </DropdownSelection>
              </Grid>
              {/* Site Name */}
              <Grid container item xs={12} lg={6}>
                <Grid container item direction="column">
                  <Typography variant="h6" className={classes.fieldLabel}>
                    {'Site Name'}
                  </Typography>
                  <Typography className={classes.fieldValue}>
                    {siteName}
                  </Typography>
                </Grid>
              </Grid>
              {/* Site Layer */}
              <Grid container item xs={12} lg={6}>
                <DropdownSelection
                  id="SiteLayer"
                  title="Site Layer"
                  error={validationState.layerId}
                  value={assetDetails.layerId}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'layerId')
                  }>
                  {siteLayers.map((site) => (
                    <MenuItem value={site.id} key={site.id}>
                      {site.layerName}
                    </MenuItem>
                  ))}
                </DropdownSelection>
              </Grid>
              {/* Purpose */}
              <Grid item xs={12} lg={6}>
                <DropdownSelection
                  id="Purpose"
                  title="Purpose"
                  error={validationState.purpose}
                  value={assetDetails.purpose}
                  onChange={(e) => {
                    handleInputChange(e.target.value, 'purpose');
                  }}>
                  {purposeOptions.map((v) => (
                    <MenuItem value={v.value} key={v.label}>
                      {v.label}
                    </MenuItem>
                  ))}
                </DropdownSelection>
              </Grid>
              {/* Externally obtained */}
              <Grid container item xs={12} lg={6}>
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="flex-start"
                  className={classes.checkboxContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={assetDetails.externalSource}
                        onChange={(e) =>
                          handleInputChange(e.target.checked, 'externalSource')
                        }
                        name="externalSource"
                      />
                    }
                    label="Externally obtained"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Codes */}
          <Grid container item xs={12}>
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography
                variant="h6"
                role="label"
                className={classes.sectionTitle}>
                {'Codes'}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between">
              <Grid container item xs={12} lg={7}>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  className={classes.settingContainer}>
                  {/* Number of Plants Allowed */}
                  <Grid container item justifyContent="space-between">
                    <Typography variant="h6" className={classes.inputLabel}>
                      {'Number of Plants Allowed'}
                    </Typography>
                    <LightTooltip
                      title="The selected ODC Permit allows a maximum number of plant codes to be generated."
                      placement="top"
                      arrow>
                      <HelpIcon />
                    </LightTooltip>
                  </Grid>
                  <Grid container item justifyContent="space-between">
                    <Typography style={{padding: '0.15rem'}}>
                      {assetDetails.maxNumber}
                    </Typography>
                    {/* TODO: link to reports */}
                    {/* <Link
                      to={{
                        pathname: CODE_LIBRARY_PATH,
                        state: {prevState: pathname},
                      }}
                      className={classes.hyperlink}>
                      View History
                    </Link> */}
                  </Grid>
                </Grid>
                {/* Remaining */}
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  className={classes.settingContainer}>
                  <Grid container item justifyContent="space-between">
                    <Typography variant="h6" className={classes.inputLabel}>
                      {'Remaining'}
                    </Typography>
                  </Grid>
                  <Grid container item justifyContent="space-between">
                    <Typography style={{padding: '0.15rem'}}>
                      {assetDetails.remaining}
                    </Typography>
                  </Grid>
                </Grid>
                {/* Quantity to Generate */}
                <Grid
                  container
                  item
                  className={classes.settingContainer}
                  xs={12}>
                  <Grid container item direction="column">
                    <Grid item>
                      <Typography variant="h6" className={classes.inputLabel}>
                        {'Quantity to Generate'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled={
                          assetDetails.assetProfile.length === 0 ||
                          assetDetails.permit === ''
                        }
                        id="Quantity"
                        fullWidth
                        value={assetDetails.quantity}
                        type="number"
                        variant="outlined"
                        error={!!validationState.quantity}
                        helperText={validationState.quantity}
                        InputProps={{inputProps: {min: 1}}}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value.replace(/[^0-9]/g, ''),
                            'quantity',
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.codeSequenceContainer}
                direction="column"
                item
                xs={12}
                lg={4}>
                <Typography variant="h5" style={{fontSize: '2rem'}}>
                  Code Sequence
                </Typography>
                <Grid item className={classes.sequenceRangeContainer}>
                  <Grid container item direction="column">
                    <Typography variant="h6" className={classes.inputLabel}>
                      {'Start'}
                    </Typography>
                    <Typography
                      variant="inherit"
                      style={{
                        padding: '0.15rem',
                      }}>
                      {assetDetails.startSequence}
                    </Typography>
                  </Grid>
                  <Grid container item direction="column">
                    <Typography variant="h6" className={classes.inputLabel}>
                      {'End'}
                    </Typography>
                    <Typography
                      variant="inherit"
                      style={{
                        padding: '0.15rem',
                      }}>
                      {assetDetails.endSequence}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Asset details */}
        <Grid
          container
          item
          direction="column"
          className={classes.assetDetailsContainer}>
          <Grid item className={classes.assetDetailsSection}>
            <Grid container item className={classes.titleContainer}>
              <Typography variant="h4" className={classes.subsectionTitle}>
                {'Asset Details'}
              </Typography>
            </Grid>
            <Grid container item className={classes.assetValues}>
              <Box className={classes.assetDetailsPairContainer}>
                <Typography
                  className={classes.assetDetailsLabel}
                  variant="inherit">
                  {'Asset Profile'}
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsValue}>
                  {selectedAsset?.details.assetName}
                </Typography>
              </Box>
              <Box className={classes.assetDetailsPairContainer}>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsLabel}>
                  {'Permit Number'}
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsValue}>
                  {findPermit(permits, assetDetails.permit)?.permitNumber ?? ''}
                </Typography>
              </Box>
              <Box className={classes.assetDetailsPairContainer}>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsLabel}>
                  {'Species'}
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsValue}>
                  {selectedAsset?.details.mainDisplay.species}
                </Typography>
              </Box>
              <Box className={classes.assetDetailsPairContainer}>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsLabel}>
                  {'Strain'}
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsValue}>
                  {selectedAsset?.details.mainDisplay.strain}
                </Typography>
              </Box>
              <Box className={classes.assetDetailsPairContainer}>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsLabel}>
                  {'Sub Strain'}
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.assetDetailsValue}>
                  {selectedAsset?.details.mainDisplay.subStrain}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item className={classes.titleContainer}>
            <Typography
              variant="h4"
              role="label"
              className={classes.subsectionTitle}>
              {'Label Preview'}
            </Typography>
          </Grid>
          <Box component={'div'} className={classes.labelsContainer}>
            <Typography
              variant="inherit"
              role="label"
              style={{fontWeight: 'bold'}}>
              {'First Label'}
            </Typography>
            <Box className={classes.qrLabel}>
              <Box style={{width: '4rem'}}>
                <QrCodeIcon style={{width: '100%'}} />
              </Box>
              <Box className={classes.codeSequenceValue}>
                <Typography variant="inherit" role="label">
                  {selectedAsset
                    ? `${selectedAsset?.details.mainDisplay.strain}_`
                    : ''}
                </Typography>
                <Typography variant="inherit" role="label">
                  {!!selectedAsset
                    ? padZeros(
                        assetDetails.startSequence,
                        CODE_SEQUENCE_MAX_DIGITS,
                      )
                    : ''}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="inherit"
              role="label"
              style={{fontWeight: 'bold'}}>
              {'Last Label'}
            </Typography>
            <Box className={classes.qrLabel}>
              <Box style={{width: '4rem'}}>
                <QrCodeIcon style={{width: '100%'}} />
              </Box>
              <Box className={classes.codeSequenceValue}>
                <Typography variant="inherit" role="label">
                  {selectedAsset
                    ? `${selectedAsset?.details.mainDisplay.strain}_`
                    : ''}
                </Typography>
                <Typography variant="inherit" role="label">
                  {!!selectedAsset
                    ? padZeros(
                        assetDetails.endSequence,
                        CODE_SEQUENCE_MAX_DIGITS,
                      )
                    : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        className={classes.buttonGrid}
        spacing={3}>
        <Grid item>
          <MyButton
            text="Save"
            variant="outlined"
            buttonClass={classes.saveButton}
            onClick={() =>
              handleGenerate({
                download: false,
                assetDetails,
                assetProfiles,
                siteLayers,
              })
            }
          />
        </Grid>
        <Grid item>
          <MyButton
            text="Save and Download"
            variant="contained"
            onClick={() =>
              handleGenerate({
                download: true,
                assetDetails,
                assetProfiles,
                siteLayers,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

function findAsset(assetProfiles: Array<RawAssetProfile>, assetId: string) {
  return assetProfiles.find(
    (assetProfile) => assetProfile.externalIds[0].agliveToken === assetId,
  );
}

function findSite(allSites: Array<SiteTokenResponse>, agliveToken: string) {
  return allSites.find(
    (site) => site.externalIds[0]?.agliveToken === agliveToken,
  );
}

function validateData(
  assetDetails: AssetDetails,
): Record<keyof AssetDetails, string> {
  const validationState = {...getInitialValidationState(assetDetails)};

  // Validate asset profile is selected
  if (!assetDetails.assetProfile.trim().length)
    validationState.assetProfile = 'This field is required';

  // Validate location (siteAgliveToken) is selected
  if (!assetDetails.layerId.trim().length)
    validationState.layerId = 'This field is required';

  // Validate permit is selected
  if (!assetDetails.permit.trim().length)
    validationState.permit = 'This field is required';

  // Validate quantity has been entered
  if (!assetDetails.quantity.trim().length)
    validationState.quantity = 'This field is required';
  else if (parseInt(assetDetails.quantity) < 1)
    validationState.quantity = 'Quantity must be greater than 0';
  else if (parseInt(assetDetails.quantity) > parseInt(assetDetails.remaining)) {
    validationState.quantity =
      'Quantity amount must be less or equal to the remaining number of codes';
  }

  // Validate purpose
  if (!assetDetails.purpose.length) {
    validationState.purpose = 'This field is required';
  }

  return validationState;
}

function isAllowedtoSubmit(
  validationState: Record<keyof AssetDetails, string>,
) {
  return !Object.values(validationState).some((value) => value);
}

function getInitialValidationState(
  initialAssetState: AssetDetails,
): Record<keyof AssetDetails, string> {
  const entries = Object.entries(initialAssetState).map(([k]) => [k, '']);
  return Object.fromEntries(entries);
}

function findPermit(permits: Array<OdcPermit>, permitId: string) {
  return permits.find((permit) => permit.permitId === permitId);
}

function findSiteByPermitId(
  locations: TokenService.BusinessToken['details']['location'],
  sites: Array<SiteTokenResponse>,
  permitId: string,
) {
  const selectedLocation = locations.find(
    (loc) => loc.country === 'Australia' && loc.permits?.includes(permitId),
  );
  const selectedSite = sites.find(
    (site) => site.details.location === selectedLocation?.PICAddress,
  );
  return selectedSite;
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3rem',
  },
  settingContainer: {
    marginBottom: 30,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 100,
  },
  fieldLabel: {
    fontWeight: 'bold',
  },
  fieldValue: {
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.3),
    flexGrow: 1,
  },
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginBottom: 10,
  },
  subsectionTitle: {
    fontWeight: 500,
    fontSize: '1.5rem',
    marginBottom: 10,
  },
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    marginBottom: 30,
  },
  saveButton: {
    borderColor: COLOR.GREEN,
    color: COLOR.GREEN,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
  codeSequenceContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR.GRAY_BORDER,
    padding: '1.5rem',
    height: 'fit-content',
  },
  sequenceRangeContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  assetDetailsContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
    width: '40%',
    padding: '1.25rem',
    fontFamily: 'Open Sans',
  },
  assetDetailsSection: {
    height: '30rem',
  },
  assetValues: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    height: '50rem',
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  qrLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '1rem',
    backgroundColor: COLOR.WHITE,
    padding: '0.5rem',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR.GRAY_BORDER,
  },
  codeSequenceValue: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  assetDetailsPairContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  assetDetailsLabel: {width: '50%', height: '2rem'},
  assetDetailsValue: {fontWeight: 'bold', width: '50%', height: '2rem'},
  fullHeight: {height: '100%'},
  checkboxContainer: {paddingTop: '2.5rem', paddingLeft: '1rem'},
}));

export default GenerateAssetsForm;
