import React from 'react';

import moment from 'moment';
import {TokenService} from '@aglive/data-model';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import ScanPosterModalContent from '../CreateAssetPromotion/ScanPosterModalContent';
import {useStyles} from './styles';
import {Buttons} from '../../../presentation/ButtonsGroup';
import MyButton from '../../../presentation/button';
import {CompleteProductPromotion} from '../../../store/promotion/types';

interface PreviewModalProps {
  openPreview: boolean;
  setOpenPreview: React.Dispatch<React.SetStateAction<boolean>>;
  previewPromotion: TokenService.PromotionToken;
  isAngus: boolean;
  archived?: boolean;
  downloadPoster?: () => void;
  brandLogo: string | null;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  openPreview,
  setOpenPreview,
  previewPromotion,
  downloadPoster,
  isAngus,
  archived = false,
  brandLogo
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openPreview}
      onClose={() => {
        setOpenPreview(false);
      }}
      PaperProps={{style: {width: 500}}}
      id="PosterPreviewDialog">
      <DialogTitle disableTypography>
        <Typography className={classes.previewHeader} variant="h3">
          Preview
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ScanPosterModalContent
          story={previewPromotion?.details['story']}
          display={previewPromotion?.details.display}
          status={previewPromotion?.details.status}
          footerImageOption={
            previewPromotion?.details.display.footerImage ? 'image' : ''
          }
          agliveToken={previewPromotion?.externalIds[0].agliveToken}
          isAngus={isAngus}
          archived={archived}
          brandLogo={brandLogo}
        />
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 30,
          marginTop: 20,
        }}>
        {previewPromotion?.details.display.endDate &&
        moment(previewPromotion?.details.display.endDate).valueOf() >
          moment().valueOf() &&
        previewPromotion?.details.status === 'published' &&
        !archived ? (
          <Buttons
            leftButtonTitle="Close"
            rightButtonTitle="Download"
            leftButtonOnClick={() => {
              setOpenPreview(false);
            }}
            rightButtonOnClick={downloadPoster}
          />
        ) : (
          <MyButton
            onClick={() => {
              setOpenPreview(false);
            }}
            variant="contained"
            text="Close"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;
