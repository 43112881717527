import React, {useEffect, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {useHistory, useLocation} from 'react-router-dom';
import {ssoAuthUser} from '../../store/auth/actions';
import agliveLogoLarge from '../../img/agliveLogoLarge.png';
import googleLogo from '../../img/g-logo.png';
import qrLogo from '../../img/qr-logo.png';
import {tokenService} from '../../env';
import Button from '../../presentation/button';
import {
  ContainerFoot,
  Line,
  Logo,
  SsoLandingContainer,
  SsoLandingRightSection,
  SsoLeftSection,
  SsoLoginBox,
} from './styles';
import {Header2} from '../../presentation/word';
import './styles.css';
import Avatar from '@mui/material/Avatar';
import CONSTANT from '../../config/constant';
import {LOGOUT} from '../../store/auth/types';
import { setInvitationAction } from 'src/store/contacts/action';

type Props = {};

const Auth: React.FC<Props> = (_) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);

  const handleGoogleSSO = async () => {
    const googleLoginURL = tokenService + CONSTANT.SSO_URL;
    window.open(googleLoginURL, '_self');
  };

  useEffect(() => {
    // get authorizationCode from rederect url and auth user
    const urlParams = new URLSearchParams(search);
    if (urlParams.has('authorizationCode')) {
      const authorizationCode = urlParams.get('authorizationCode');
      dispatch(ssoAuthUser(authorizationCode, history));
    }
  }, [search, dispatch, history]);

  useEffect(() => {
    if (search.length > 0) {
      // store invitation code
      const urlParams = new URLSearchParams(search);
      const invitationCode = urlParams.get('invitationCode');
      if (!invitationCode) {
        return;
      }
      const category = urlParams.get('category');
      console.log('invitation code', invitationCode)
      dispatch(setInvitationAction({
        invitationCode,
        category
      }));
    }
    // redirect
    if (isLoggedIn) {
      history.push('/private');
    }
  }, [dispatch, search, isLoggedIn, history])

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <SsoLandingContainer>
          <SsoLoginBox>
            <SsoLeftSection>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '-60px 95px 0px 45px',
                  padding: 20,
                }}>
                <Logo src={agliveLogoLarge} />
                <Header2
                  invert={true}
                  style={{
                    marginTop: 30,
                    maxWidth: 350,
                  }}>
                  Prove the value of your product and boost sales{' '}
                </Header2>
              </div>
              <ContainerFoot>
                Copyright &reg; 2020 Aglive International Pty Ltd ACN 605 120
                081
              </ContainerFoot>
            </SsoLeftSection>
            <SsoLandingRightSection id="LoginContainer">
              <Header2 invert={true}>Get started in seconds</Header2>
              <Line mt={25} style={{width: '80%'}} />
              <Button
                variant={'outlined'}
                text={'Sign In With Google'}
                style={{
                  margin: '40px 0px 10px 0px',
                  color: 'black',
                  fontSize: '25px',
                }}
                startIcon={<Avatar src={googleLogo} />}
                onClick={handleGoogleSSO}
              />
              <Button
                variant={'outlined'}
                id="qrLogin"
                text={'Sign In With QR Scan'}
                style={{
                  margin: '30px 0px 10px 0px',
                  color: 'black',
                  fontSize: '25px',
                }}
                onClick={() => {
                  dispatch({
                    type: LOGOUT,
                  });
                  history.push({
                    pathname: '/qr-login',
                    state: {qrLoginFlag: false},
                  });
                }}
                startIcon={<Avatar src={qrLogo} />}
              />
            </SsoLandingRightSection>
          </SsoLoginBox>
        </SsoLandingContainer>
      </div>
    </div>
  );
};

export default Auth;
