import {
  recordSiresSchema,
  aiJoiningSchema,
  sireGroupsSchema,
  recordAnimalsSchema,
} from './schema';
import {ENV, walletConnectURL} from '../env';

const WEB_LOGIN_PREFIX_ = 'WEB_LOGIN_PREFIX_';

export enum updateByCSV {
  updateWeight = 'updateWeight',
  updatePregnancyStatus = 'updatePreganacyStatus',
  updateDeceaseStatus = 'updateDeceaseStatus',
  updateAngusSireGroup = 'updateAngusSireGroup',
  angusAIJoining = 'angusAIJoining',
  addGroupItem = 'addGroupItem',
  removeGroupItem = 'delGroupItem',
}

const TEMPLATE = {
  INDUCTION: {
    AUSTRALIA: {
      VALUE: 'INDUCTION',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_induction.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_induction.xlsx',
    },
    CANADA: {
      VALUE: 'INDUCTION_CANADA',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_canada_induction.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_canada_induction.xlsx',
    },
    WARAKIRRI: {
      VALUE: 'INDUCTION_WARAKIRRI',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_induction.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_induction.xlsx',
    },
    USA: {
      VALUE: 'INDUCTION_USA',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/template_usa_induction.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/example_usa_induction.csv',
    },
  },
  MANAGEMENT: {
    WEIGHT: {
      VALUE: 'WEIGHT',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_weight.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_weight.xlsx',
    },
    PREGNANCY: {
      VALUE: 'PREGNANCY',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_pregnancy.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_pregnancy.xlsx',
    },
    DECEASED: {
      VALUE: 'DECEASED',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_deceased.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_deceased.xlsx',
    },
    HEIGHT: {
      VALUE: 'HEIGHT',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_height.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_height.xlsx',
    },
    TREATMENT: {
      VALUE: 'TREATMENT',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_treatment.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_treatment.xlsx',
    },
    TRAITS: {
      VALUE: 'TRAITS',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_traits.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_traits.xlsx',
    },
    MOVE: {
      VALUE: 'MOVE',
      TEMP: 'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_move.csv',
      EXAMPLE: '',
    },
    ATTACHMENT: {
      //for digirodeo
      VALUE: 'ATTACHMENT',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_DigiRodeo.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_DigiRodeo.xlsx',
    },
  },
  WARAKIRRI_MANAGEMENT: {
    WEIGHT: {
      VALUE: 'WEIGHT',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_weight.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_weight.xlsx',
    },
    PREGNANCY: {
      VALUE: 'PREGNANCY',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_pregnancy.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_pregnancy.xlsx',
    },
    STATUS: {
      VALUE: 'STATUS',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_status.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_status.xlsx',
    },
    TREATMENT: {
      VALUE: 'TREATMENT',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_treatment.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_treatment.xlsx',
    },
    MOVE: {
      VALUE: 'MOVE',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_movement.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_movement.xlsx',
    },
    CALF_INFO_UPDATE: {
      VALUE: 'CALF_INFO_UPDATE',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_update_calf_info.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_update_calf_info.xlsx',
    },
    RFID_REPLACEMENT: {
      VALUE: 'RFID_REPLACEMENT',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_rfid_replacement.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_rfid_replacement.xlsx',
    },
    VISUAL_TAG_REPLACEMENT: {
      VALUE: 'VISUAL_TAG_REPLACEMENT',
      TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_warakirri_visual_tag_replacement.csv',
      EXAMPLE:
        'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_warakirri_visual_tag_replacement.xlsx',
    },
  },
  CONSIGNMENT: {
    VALUE: 'ANIMALS',
    TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_consignment_animals.csv',
    EXAMPLE:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_consignment_animals.csv',
  },
  GROUP: {
    VALUE: 'GROUP',
    TEMP: 'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_group.csv',
    TEMP2:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/temp_group_id.csv',
    EXAMPLE:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_group.csv',
    EXAMPLE2:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/examp_group_id.csv',
  },
} as const;

const TREATMENT_PRODUCT_LIST =
  'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/treatment_list.xlsx';

const ASSETINDUCTION = {
  RECORDSIRES: {
    VALUE: 'RECORDSIRES',
    TEMPLATE: 'RECORD SIRES',
    TYPE: 'angusSire',
    SCHEMA: recordSiresSchema,
    CSVTEMP:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/record-sires-template+V2.csv',
    CSVSAMPLE:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/Record+Sires+Example+%26+Instructions+V2.xlsx',
  },
  AIJOINING: {
    VALUE: 'AIJOINING',
    TEMPLATE: 'angusAIJoining',
    TYPE: updateByCSV.angusAIJoining,
    SCHEMA: aiJoiningSchema,
    CSVTEMP:
      'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/temp_ai_joining.csv',
    CSVSAMPLE:
      'https://mvp-dev-aglive.s3-ap-southeast-2.amazonaws.com/web/csv_template/examp_ai_joining.xlsx',
  },
  SIREGROUPS: {
    VALUE: 'SIREGROUPS',
    TEMPLATE: 'SIRE GROUPS',
    ACTIVITYTEMPLATE: 'SIRE GROUP',
    TYPE: 'angusSireGroup',
    ACTIVITYTYPE: 'updateAngusSireGroup',
    SCHEMA: sireGroupsSchema,
    CSVTEMP:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/sire-groups-template+V2.csv',
    CSVSAMPLE:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/Sire+Groups+Example+%26+Instructions+V2.xlsx',
  },
  RECORDANIMALS: {
    VALUE: 'RECORDANIMALS',
    TEMPLATE: 'RECORD ANIMALS',
    TYPE: 'angusMob',
    ACTIVITYTYPE: 'updateAngusMob',
    SCHEMA: recordAnimalsSchema,
    CSVTEMP:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/record-animals-template+V2.csv',
    CSVSAMPLE:
      'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/web/csv_template/Record+Animals+Example+%26+Instructions+V2.xlsx',
  },
};

const ASSETTYPE = {
  ASSET: 'asset',
  USER: 'user',
  PRODUCT: 'product',
  SITE: 'site',
  BRAND: 'brand',
  BUSINESS: 'business',
  GROUP: 'group',
  ASSET_PROFILE: 'assetProfile',
  PLANT: 'plant',
  PROMOTION: 'promotion',
  CONSIGNEE: 'consignee',
  CONSIGNMENT: 'consignment',
  ADD_CONSIGNMENT_ATTACHMENT: 'addConsignmentAttachment',
};

const OPERATIONTYPE = {
  TOKEN: 'TOKEN',
  ACTIVITY: 'ACTIVITY',
  PRODUCT: 'PRODUCT',
  BRAND: 'BRAND',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_BRAND: 'DELETE_BRAND',
  SUBMIT_CONSIGNMENT: 'submitConsignment',
  ADD_GROUP_ITEM: 'addGroupItem',
  REMOVE_GROUP_ITEM: 'delGroupItem',
};

const WALLETCONNECT = {
  BRIDGE: walletConnectURL,
  SESSION_UPDATE: 'session_update',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  WALLET_CONNECT: 'walletconnect',
  CALL_REQUEST: 'call_request',
};

const CODE_PREFIX = 'https://scan.aglive.com/s/';

const DEVICE_ID = 'device_id';

const BUSINESS_SUPPORT_CHANNEL = 'support@aglive.com';
const COMPANY_NUMBER_REGEX: {[key: string]: RegExp} = {
  Australia: /^[\d|\s]+$/,
  Argentina: /^[0-9]{2}[-][0-9]{8}[/][0-9]{1}$/,
};
const CUIG_REGEX: RegExp = /^[A-Za-z]{2}[0-9]{3}$/;
const POSTCODE_REGEX: {[key: string]: RegExp} = {
  Australia: /^[0-9]{4}$/,
  Canada:
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
  Argentina: /^[A-Za-z][0-9]{4}$/,
};
const POSTCODE_REGEX_WARNING: {[key: string]: string} = {
  Australia: 'Please enter a valid Austrialian Postcode',
  Canada: 'Please enter a valid Canadian Postcode',
  Argentina: 'Please enter Codigo Postal in the format as per example provided',
};
const RENSPA_REGEX: RegExp =
  /^[0-9]{2}[.][0-9]{3}[.][0-9][.][0-9]{5}[/][0-9]{2}$/;
const MOBILE_REGEX: {[key: string]: RegExp} = {
  Australia:
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
  Canada:
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
};
const MOBILE_REGEX_WARNING: {[key: string]: string} = {
  Australia: 'Please input a validate Australian mobile number',
  Canada: 'Please input a valid Canadian mobile number',
};
const EMAIL_REGEX: RegExp =
  /^([a-zA-Z0-9\.-_]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{2,8})?$/;
const GLOBAL_MOBILE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const GLOBAL_MOBILE_REGEX_WARNING = 'Please input a valid mobile number';
const DD_MM_YYYY_REGEX =
  /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;
const YYYY_MM_DD_REGEX =
  /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/;

const LOCALISATION_PIC = {
  AU_LOCATION_PIC: 'Location e.g. PIC',
  CA_LOCATION_PID: 'Location e.g. PID',
};

const LOCATION_CREDENTIAL_WARNINGS: {[key: string]: string} = {
  LPAUserID: 'Missing LPA user ID',
  LPAPassword: 'Missing LPA password',
  NLISUserID: 'Missing NLIS user ID',
  NLISPassword: 'Missing NLIS password',
};

const SCAN_URL = (id: string) =>
  `https://promotion.aglive.com/?env=${ENV.toLowerCase()}&id=${id}`;
const WARAKIRRI_BUSINESS_ID = '6d383583-7c65-4c99-8e31-c4eb004455c4';

const FORM_JSON_LINK =
  'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/mvp-utils/others/form.json';
const FORM_COL_BREAK_INDEX = {LPAC1: 4, HSC2: 7}; // LPAC1 for eNVD, HSC2 for HD
const FORM_DECLARATIONS = {
  envd: 'I declare that, I am the owner or the person responsible for the husbandry of the cattle  at all the Information in part A of this document Is true and correct. I also declare that I have read and understood all the questions that I have answered, that I have read and understood the explanatory notes, and that, while under my control, the cattle were not fed restricted animal material (Including meat and bone meal) In breach of State or Territory legislation. ',
  hd: 'I declare that, I am the owner or the person responsible for the husbandry of the cattle and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, that I have read and understood the explanatory notes, and that I have inspected the animals and deem them to be healthy, free of signs of disease and fit to travel.',
};

const ENABLE_PLUGIN = true;
/**English Labels */
const EN_LABELS = {
  //Invit User Label
  readOnly: 'Read Only',
  search: 'Search',
  createNew: 'Create New',
  filters: 'Filters',
  inactive: 'Inactive',
  active: 'Active',
  pending: 'Pending',
  userLibrary: 'User Library',
  contactLibrary: 'Contact Library',
  name: 'Name',
  phone: 'Cell/Mobile',
  email: 'Email',
  emailAddress: 'Email Address',
  role: 'Role',
  status: 'Status',
  action: 'Action',
  admin: 'Admin',
  user: 'User',
  none: 'none',
  addUser: 'Add User',
  editUser: 'Edit User',
  viewUser: 'View User',
  enterPhoneNumber: 'Enter phone number',
  inputValidEmail: 'Please input a valid email',
  pendingAcceptance: 'Pending for Acceptance',
  pendingApprove: 'Pending to approve',
  inviteAgain: 'Invite Again',
  invite: 'Invite',
  invited: 'Invited',
  invitedOn: 'Invited On',
  joinedOn: 'Joined On',
  unlink: 'Unlink',
  revoke: 'Revoke',
  invitedMessage: 'The SMS invitation sent. New user code is',
  save: 'Save',
  mobileWarning: 'Please input a valid mobile number',
  //User Profile
  signature: 'Signature',
  edit: 'Edit',
  viewUserProfile: 'View User Profile',
  editUserProfile: 'Edit User Profile',
  viewContact: 'View Contact',
  editContact: 'Edit Contact',
  addContact: 'Add Contact',
  requiredField: 'This field is required',
  validNumber: 'Please enter a valid mobile number',
  validEmail: 'Please enter your email address in format: yourname@example.com',
  enterNumber: 'Enter number',
  fullName: 'Full Name',
  //Business Profile
  delete: 'Delete',
  deleted: 'Deleted',
  cancel: 'Cancel',
  addLocation: 'Add Location',
  addLocationUsers: 'Add Location Users',
  addEstablishment: 'Add Establishment',
  addMoreEstablishment: 'Add More Establishments',
  addEstablishmentUsers: 'Add Establishment Users',
  updated: 'Updated',
  close: 'Close',
  pleaseSelect: 'Please Select',
  cannptUndone: 'This action cannot be undone',
  businessName: 'Business Name',
  companyName: 'Company/Business Name',
  companyNumber: 'Company/Business Number',
  licenseNumber: 'License Number',
  licenseExpiryDate: 'License Expiry Date',
  permits: 'Permits',
  permit: 'Permit',
  permitNumber: 'Permit Number',
  permitExpiryDate: 'Permit Expiry Date',
  addPermit: 'Add Permit',
  myLocation: 'My Location',
  myEstablishment: 'My Establishments',
  locationId: 'Location ID',
  locationNickname: 'Location Nickname',
  locationUsers: 'Location Users',
  establishmentId: 'Establishment ID',
  establishmentNickname: 'Establishment Nickname',
  establishmentUsers: 'Establishment Users',
  duplicatePIC: 'This PIC already exists',
  country: 'Country',
  state: 'State',
  provinceTerritory: 'Province/Territory',
  suburb: 'Town or Suburb',
  cityTownSuburb: 'City/Town/Suburb',
  postcode: 'Postcode',
  zipcode: 'Zip Code',
  address: 'Address',
  region: 'Region',
  propertytype: 'Property Type',
  lpaUser: 'LPA Username',
  lpaPassword: 'LPA Password',
  nlisUser: 'NLIS Username',
  nlisPassword: 'NLIS Password',
  CattleIdentification: 'Cattle Identification',
  brandDescription: 'Brand/Eartag Description',
  cattleBrandSymbol: 'Cattle Brand Symbol',
  editBusinessProfile: 'Edit Business Profile',
  viewBusinessProfile: ' View Business Profile',
  CUIG: 'CUIG',
  validCUIG: 'Please enter CUIG in the format as per example provided',
  validCUIT: 'Please enter CUIT in the format as per example provided',
  validRenspa: 'Please enter Renspa in the format as per example provided',
  license: 'License',
  startDate: 'Start Date',
  expiryDate: 'Expiry Date',
  certificateNumber: 'Certificate Number',
  odcLicense: 'ODC License',
  odcLicenseNumber: 'ODC License Number',
  odcLicenseStartDate: 'ODC License Start Date',
  odcLicenseExpiryDate: 'ODC License Expiry Date',
  odcPermits: 'ODC Permits',
  addOdcPermit: 'Add ODC Permit',
  addOdcLicenseAndPermit: 'Add ODC License and Permit',
  plantsLimit: '# Plants Limit',
  gmpLicense: 'GMP License',
  gmpLicenseNumber: 'GMP License Number',
  gmpLicenseStartDate: 'GMP License Start Date',
  gmpLicenseExpiryDate: 'GMP License Expiry Date',
  gmpCertificates: 'GMP Certificates',
  gmpCertificateNumber: 'GMP Certificates',
  addGmpCertificate: 'Add GMP Certificate',
  addGmpLicenseAndCertificates: 'Add GMP License and Certificates',
  poisonPermit: 'Poison Permit',
  poisonPermitNumber: 'Poison Permit Number',
  poisonPermitStartDate: 'Poison Permit Start Date',
  poisonPermitExpiryDate: 'Poison Permit Expiry Date',
  addPoisonPermit: 'Add Poison Permit',
  permitUpdateWarning: 'Changes will reflect on all linked plants.',
  continue: 'Continue',
  update: 'Update',
  yes: 'Yes',
  no: 'No',
  thcValue: 'THC Value',
};

/**Spanish Labels */
const ES_LABELS = {
  //Invit User Label
  readOnly: 'Solo Lectura',
  search: 'Buscar',
  createNew: 'Crear Nuevo',
  filters: 'Filtros',
  inactive: 'Inactivo',
  active: 'Activo',
  pending: 'Pendiente',
  userLibrary: 'Librería de Usuario',
  contactLibrary: 'Contact Library',
  name: 'Nombre',
  phone: 'Celular',
  email: 'Email',
  emailAddress: 'Email',
  role: 'Rol',
  status: 'Estado',
  action: 'Acción',
  admin: 'Administrador',
  user: 'Usuario',
  none: 'Ninguno',
  addUser: 'Agregar Usuario',
  enterPhoneNumber: 'Ingrese su número de teléfono',
  inputValidEmail: 'Por favor ingrese un email válido',
  pendingAcceptance: 'Esperando ser Aceptado',
  pendingApprove: 'Pending to approve',
  inviteAgain: 'Invitar de Nuevo',
  invite: 'Invitar',
  invited: 'Invitado',
  invitedOn: 'Invited On',
  joinedOn: 'Joined On',
  unlink: 'Unlink',
  revoke: 'Revoke',
  invitedMessage:
    'La invitación por SMS enviada. El nuevo código de usuario es',
  save: 'Guardar',
  editUser: 'Editar Usuario',
  viewUser: 'Ver Usuario',
  viewContact: 'View Contact',
  editContact: 'Edit Contact',
  addContact: 'Add Contact',
  mobileWarning: 'Por favor ingrese un número válido de celular',
  //User Profile
  signature: 'Firma',
  edit: 'Editar',
  viewUserProfile: 'Ver Perfil de Usuario',
  editUserProfile: 'Editar Perfil de Usuario',
  requiredField: 'Este campo es obligatorio',
  validNumber: 'Por favor ingrese un número válido de celular',
  validEmail:
    'Por favor ingrese su mail con el siguiente formato: sunombre@ejemplo.com',
  enterNumber: 'Por favor seleccione',
  fullName: 'Nombre Completo',
  //Buisiness Profile
  delete: 'Eliminar',
  deleted: 'eliminado',
  cancel: 'Cancelar',
  addLocation: 'Agregar Ubicación',
  addLocationUsers: 'Agregar Usuario',
  addEstablishment: 'Agregar Ubicación',
  addMoreEstablishment: 'Agregar Ubicación',
  addEstablishmentUsers: 'Agregar Usuario',
  updated: 'Actualizado',
  close: 'Cerrar',
  pleaseSelect: 'Seleccione',
  cannptUndone: 'Esta acción no puede ser eliminada',
  businessName: 'Business Name',
  companyName: 'Nombre de la Empresa',
  companyNumber: 'CUIT',
  myLocation: 'Mi Ubicación',
  myEstablishment: 'Mi Ubicación',
  locationId: 'RENSPA',
  locationNickname: 'Nombre de la Ubicación ',
  locationUsers: 'Ubicación Usuario',
  establishmentId: 'RENSPA',
  establishmentNickname: 'Nombre de la Ubicación ',
  establishmentUsers: 'Ubicación Usuario',
  duplicatePIC: 'Esta RENSPA ya existe',
  country: 'País',
  state: 'Provincia',
  provinceTerritory: 'Provincia',
  suburb: 'Localidad',
  cityTownSuburb: 'Localidad',
  postcode: 'Codigo Postal',
  zipcode: 'Codigo Postal',
  address: 'Dirección',
  CattleIdentification: 'Identificación de Bovina',
  brandDescription: 'Raza / Descripción de Caravana',
  cattleBrandSymbol: 'Símbolo de la Raza Bovina',
  editBusinessProfile: 'Editar Perfil Comercial',
  viewBusinessProfile: 'Ver Perfil Comercial',
  CUIG: 'CUIG',
  validCUIG:
    'Por favor ingrese el CUIG en el mismo formato que explica el ejemplo',
  validCUIT:
    'Por favor ingrese el CUIT en el mismo formato que explica el ejemplo',
  validRenspa:
    'Por favor ingrese el RENSPA en el mismo formato que explica el ejemplo',
  //Don't need Spanish below
  licenseNumber: 'License Number',
  licenseExpiryDate: 'License Expiry Date',
  permits: 'Permits',
  permit: 'Permit',
  permitNumber: 'Permit Number',
  permitExpiryDate: 'Permit Expiry Date',
  addPermit: 'Add Permit',
  lpaUser: 'LPA Username',
  lpaPassword: 'LPA Password',
  nlisUser: 'NLIS Username',
  nlisPassword: 'NLIS Password',
  license: 'License',
  startDate: 'Start Date',
  expiryDate: 'Expiry Date',
  certificateNumber: 'Certificate Number',
  odcLicense: 'ODC License',
  odcLicenseNumber: 'ODC License Number',
  odcLicenseStartDate: 'ODC License Start Date',
  odcLicenseExpiryDate: 'ODC License Expiry Date',
  odcPermits: 'ODC Permits',
  addOdcPermit: 'Add ODC Permit',
  addOdcLicenseAndPermit: 'Add ODC License and Permit',
  plantsLimit: '# Plants Limit',
  gmpLicense: 'GMP License',
  gmpLicenseNumber: 'GMP License Number',
  gmpLicenseStartDate: 'GMP License Start Date',
  gmpLicenseExpiryDate: 'GMP License Expiry Date',
  gmpCertificates: 'GMP Certificates',
  gmpCertificateNumber: 'GMP Certificates',
  addGmpCertificate: 'Add GMP Certificate',
  addGmpLicenseAndCertificates: 'Add GMP License and Certificates',
  poisonPermit: 'Poison Permit',
  poisonPermitNumber: 'Poison Permit Number',
  poisonPermitStartDate: 'Poison Permit Start Date',
  poisonPermitExpiryDate: 'Poison Permit Expiry Date',
  addPoisonPermit: 'Add Poison Permit',
  continue: 'Continue',
  permitUpdateWarning: 'Changes will reflect on all linked plants.',
  update: 'Update',
  yes: 'Yes',
  no: 'No',
  thcValue: 'THC Value',
};

const USER_GEOLOCATION_STORAGE_KEY = 'user_geolocation';

const SITE_OPTIONS_URL =
  'https://mvp-dev-aglive.s3.ap-southeast-2.amazonaws.com/mvp-utils/others/site_options.json';

const GET_INVITATION_CODE_URL =
  'https://help.aglive.com/aglive/aglive-web-portal/get-invitation-code';

const SSO_URL = '/api/auth/sso/google';

const INDUSTRY_TYPE = {
  ANIMALS: {
    TITLE: 'Livestock',
    KEYNAME: 'ANIMALS',
    SUBTYPE: [
      {
        TITLE: 'Cattle',
        IMAG: '',
        SUBTYPE: [{TITLE: 'Beef'}, {TITLE: 'Dairy'}],
      },
      {TITLE: 'Sheep'},
      {TITLE: 'Goats'},
      {TITLE: 'Pigs'},
      {TITLE: 'Horses'},
      {TITLE: 'Chickens'},
      {TITLE: 'Fish'},
    ],
    SERVICE_TYPE: [
      {TITLE: 'Producer'},
      {TITLE: 'Agent'},
      {TITLE: 'Saleyard'},
      {TITLE: 'Feedlot'},
      {TITLE: 'Vet'},
      {TITLE: 'Processor'},
    ],
  },
  PLANTS: {
    TITLE: 'Plants',
    KEYNAME: 'PLANTS',
    SUBTYPE: [
      {TITLE: 'Cannabis'},
      {TITLE: 'Hemp'},
      {TITLE: 'Fruit'},
      {TITLE: 'Vegetable', IMAG: ''},
      {TITLE: 'Wheat', IMAG: ''},
      {TITLE: 'Rice', IMAG: ''},
    ],
  },
  PRODUCTS: {
    TITLE: 'Products',
    KEYNAME: 'PRODUCTS',
    SUBTYPE: [
      {TITLE: 'Alcohol', IMAG: ''},
      {TITLE: 'Drinks', IMAG: ''},
      {TITLE: 'Meat', IMAG: ''},
      {TITLE: 'Seafood', IMAG: ''},
      {TITLE: 'Dairy', IMAG: ''},
      {TITLE: 'Vitamins', IMAG: ''},
      {TITLE: 'Eggs', IMAG: ''},
      {TITLE: 'Wool', IMAG: ''},
      {TITLE: 'Medicine', IMAG: ''},
      {TITLE: 'Supplements', IMAG: ''},
    ],
  },
  LOGISTICS: {
    TITLE: 'Logistics',
    KEYNAME: 'LOGISTICS',
  },
  SERICES: {
    TITLE: 'Services',
    KEYNAME: 'SERICES',
    SUBTYPE: [
      {TITLE: 'Certifier', IMAG: ''},
      {TITLE: 'Auditor', IMAG: ''},
      {TITLE: 'Insurer', IMAG: ''},
      {TITLE: 'Lender', IMAG: ''},
      {TITLE: 'Government', IMAG: ''},
      {TITLE: 'Vet', IMAG: ''},
      {TITLE: 'Agent', IMAG: ''},
    ],
  },
  BUYER: {TITLE: 'Buyer', KEYNAME: 'BUYER'},
};

const PHONE_COUNTRY_CODES = ['AU', 'CA', 'AR', 'US'];

const ODC_LICENSE_HASH = 'odcLicense';
const LOCATION_HASH = 'location';
const EDIT_BUSINESS_PATH = '/private/profile/businessProfile/edit';
const MULTIPLE_SITES = 'Multiple Sites';
const MACKAS_SHOWCASE_PLUGIN = 'Mackas Showcase';

const CONSTANT = {
  WEB_LOGIN_PREFIX_,
  TEMPLATE,
  TREATMENT_PRODUCT_LIST,
  ASSETTYPE,
  OPERATIONTYPE,
  ASSETINDUCTION,
  WALLETCONNECT,
  CODE_PREFIX,
  DEVICE_ID,
  BUSINESS_SUPPORT_CHANNEL,
  POSTCODE_REGEX,
  POSTCODE_REGEX_WARNING,
  MOBILE_REGEX,
  MOBILE_REGEX_WARNING,
  GLOBAL_MOBILE_REGEX,
  GLOBAL_MOBILE_REGEX_WARNING,
  DD_MM_YYYY_REGEX,
  YYYY_MM_DD_REGEX,
  LOCALISATION_PIC,
  SCAN_URL,
  WARAKIRRI_BUSINESS_ID,
  ENABLE_PLUGIN,
  ES_LABELS,
  EN_LABELS,
  COMPANY_NUMBER_REGEX,
  CUIG_REGEX,
  RENSPA_REGEX,
  FORM_JSON_LINK,
  FORM_COL_BREAK_INDEX,
  USER_GEOLOCATION_STORAGE_KEY,
  EMAIL_REGEX,
  FORM_DECLARATIONS,
  SITE_OPTIONS_URL,
  LOCATION_CREDENTIAL_WARNINGS,
  GET_INVITATION_CODE_URL,
  INDUSTRY_TYPE,
  SSO_URL,
  PHONE_COUNTRY_CODES,
  ODC_LICENSE_HASH,
  LOCATION_HASH,
  EDIT_BUSINESS_PATH,
  MULTIPLE_SITES,
  MACKAS_SHOWCASE_PLUGIN,
};

export default CONSTANT;