import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import COLOR from '../styled/colors';
import MyButton from './button';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    '&:hover': {
      borderColor: COLOR.GREEN_BUTTON,
      boxShadow: 'none',
    },
  },
}));

export const Buttons = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-around">
      <MyButton
        text={props.leftButtonTitle}
        width={200}
        variant="outlined"
        buttonClass={classes.cancelButton}
        onClick={props.leftButtonOnClick}
      />
      <MyButton
        text={props.rightButtonTitle}
        width={200}
        variant="contained"
        onClick={props.rightButtonOnClick}
      />
    </Grid>
  );
};
