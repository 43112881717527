import React, { useEffect, useMemo, useReducer } from 'react';
import produce from 'immer';
import _ from 'lodash';
import { Grid, Link } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';
import { useHistory, useParams } from 'react-router';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CreateProductProfileDetails from '../CreateProductProfileDetails';
import TellYourStory from '../../TellYourStory';

import MyButton from '../../../../presentation/button';
import ProgressBar from '../../../../presentation/ProgressBar';
import withHeader from '../../../../presentation/withHeader';
import ProductHeader from '../../ProductHeader';

import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../../store/spinner/types';
import { toggleModal } from '../../../../store/modal/actions';

import createProductProfileReducer, { canSubmit, DETAILS_VALIDATION_TEMPLATE, INITIAL_CREATE_PROFILE_STATE } from '../CreateProductProfile/productProfileState/reducer';

import { productProfileActions } from '../CreateProductProfile/productProfileState/types';
import localActions, { localDispatchDecorator, stateHandler } from '../CreateProductProfile/productProfileState/actions';
import { TellYourStoryActions as TellYourStoryActionTypes } from '../../TellYourStory/tellYourStoryState/types';
import TellYourStoryActions from '../../TellYourStory/tellYourStoryState/actions';
import { loadTokenAction, viewProfileActions as viewProfileActionTypes } from '../ViewProductProfile/productProfileState/types';
import viewProfileActions from '../ViewProductProfile/productProfileState/actions';

import { fetchProductProfileTokens } from '../../ProductProfileLibrary/utils';
import { fetchBrands } from '../../../../store/brand/actions';
import { findTargetBrand, getBrandAgliveTokenId } from '../../../brand/utils';
import { callAPI } from '../../../../utils/network';

import { WebErrorType } from '../../../../utils/error';
import { TokenService } from '@aglive/data-model';

import COLOR from '../../../../styled/colors';
import API from '../../../../config/api';
import CONSTANT from '../../../../config/constant';
import toggleQRCodeModal from '../CreateProductProfile/toggleQRCodeModal';
import { INITIAL_ADDITIONAL_SECTION } from '../../CreateAssetPromotion/assetPromotionState/reducer';

const INITIAL_EDIT_PROFILE_STATE = stateHandler(
  INITIAL_CREATE_PROFILE_STATE,
  {
    state: {
      brand: null as TokenService.BrandToken,
    }
  }
);

const productProfileReducer = (
  state: typeof INITIAL_EDIT_PROFILE_STATE,
  action: productProfileActions | TellYourStoryActionTypes | loadTokenAction) => {
  switch (action.type) {
    case 'load/load_token':
      return produce(state, draft => {
        draft.payload = action.payload.profileToken;
        draft.state.currentProductProfile = _.cloneDeep(action.payload.profileToken);
        draft.state.brand = action.payload.brandToken;

        // let user to be able to submit right away
        draft.state.canSubmit = true;

        // select TYS image option on load
        draft.state.story.mainSectionImageOption = action.payload.profileToken.story.image
          ? 'image'
          : '';

        // update validation state accordingly
        // TYS additional
        draft.validation.story.additional = Array(action.payload.profileToken.story.additional.length).fill(INITIAL_ADDITIONAL_SECTION);

        // details
        draft.validation.pages = action.payload.profileToken.pages.map(page => ({
          display: {
            ...DETAILS_VALIDATION_TEMPLATE.display,
            item: page.display.item
          }
        }));
      });
    default:
      return createProductProfileReducer(state, action);
  }
};

function findPayloadDifferences (productProfileStateOld, productProfileStatePayload) {
  var differences = _.reduce(productProfileStateOld, (result, value , key) => {
    if(!_.isEqual(value, productProfileStatePayload[key])){
      if(_.isObject(value) && _.isObject(productProfileStatePayload[key])){
        const nestedDifferences = findPayloadDifferences(value, productProfileStatePayload[key]);
        if(!_.isEmpty(nestedDifferences)){
          result[key] = nestedDifferences;
        }
      }else{
        if(productProfileStatePayload[key] == '' || productProfileStatePayload[key] === null || (Array.isArray(productProfileStatePayload[key]) && productProfileStatePayload[key].length === 0) || (_.isObject(productProfileStatePayload[key]) && _.isEmpty(productProfileStatePayload[key]))){
          result[key] = value;
        }
      }
    }
    return result;
  }, {});

  return differences;
}

const EditProductProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const businessPlugins = useAppSelector((state) => state.user.plugins);
  const isMackasShowcase = useMemo(
    () =>
      !!businessPlugins?.find(
        (p) =>
          p.status === 'activated' &&
          p.name === CONSTANT.MACKAS_SHOWCASE_PLUGIN,
      ),
    [businessPlugins],
  );

  const isAmbioxera = useMemo(
    () =>
      !!businessPlugins?.find(
        (br) =>
          br.name.toLowerCase() === 'ambioxera' && br.status === 'activated',
      ),
    [businessPlugins],
  );
  const tmpInitial: typeof INITIAL_EDIT_PROFILE_STATE = useMemo(() => {
    return produce(INITIAL_EDIT_PROFILE_STATE, (draft) => {
      if (isAmbioxera || isMackasShowcase) {
        draft.skipPages = true;
      }
    });
  }, [isAmbioxera, isMackasShowcase]);
  const [productProfileState, localDispatch] = useReducer(productProfileReducer, tmpInitial);

  // the state will store the fetch response data to preserve blockchain id
  const userid = useAppSelector(state => state.auth.wallet);

  const localDispatchAction = localDispatchDecorator<
    productProfileActions
    | TellYourStoryActionTypes
    | viewProfileActionTypes
  >(localDispatch);

  // fetch target token on mount
  useEffect(() => {
    async function fetchTargetToken() {
      try {
        dispatch({ type: SPINNER_TOGGLE_ON });
        const brands = await fetchBrands(dispatch);
        const [targetToken] = await fetchProductProfileTokens([{agliveToken: id}]);

        const targetBrand = findTargetBrand(
          brands,
          targetToken.details.brand.agliveToken
        );

        localDispatch(localActions.getBrandList(
          brands.filter(brand => !brand.details.archive || getBrandAgliveTokenId(targetBrand) === getBrandAgliveTokenId(brand))
        )); // brandList must be dispatched before brand otherwise it will cause error\
        // only allow unarchived brand and current brand whether archived or not

        localDispatch(viewProfileActions.loadToken(
          targetToken.details,
          targetBrand
        ));
      } catch (e) {
        const error = e as WebErrorType;

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          })
        );
      } finally {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      }
    }

    fetchTargetToken();
  }, []);

  useEffect(() => {
    // the productProfileState.state.status serves as a relay and is only changed by onSubmit->reducer->validation
    // if all tests pass then status = 'submit', useEffect hook will be triggered on state change and trigger the following submission logic
    const submitForm = async () => {
      if (productProfileState.state.status === 'draft') {
        return;
      }

      try {
        dispatch({ type: SPINNER_TOGGLE_ON });
      
        // Create DEL_details activity first to ensure unwanted details are being removed,
        // For instance, if user has removed one of the product details page, without calling 
        // DEL_details will cause the product details page not being removed from DB because
        // backend will just merge the old and new details together in UP_details
        // ***** CHANGED to only DEL_details when value is ''/null for each key-pair *****
        const differences = findPayloadDifferences(productProfileState.state.currentProductProfile,productProfileState.payload);
        var activitiesArr = [
          {
            type: 'UP_details',
            details: productProfileState.payload
          }
        ];
        if(!_.isEmpty(differences)){
          activitiesArr.unshift({
            type: 'DEL_details',
            details: differences,
          });
        }
        
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.PRODUCT,
                externalIds: {
                  agliveToken: id,
                },
                activities: activitiesArr,
              },
            ],
          },
        });

        toggleQRCodeModal(
          dispatch, 
          history, 
          {
            status: 'success',
            title: 'Saved',
            QRCodeContent: CONSTANT.SCAN_URL(id),
          }
        );
      } catch (e) {
        const error = e as WebErrorType;
  
        //State.status remain as 'submit' after error return
        //Change state.status to 'draft', in order for submitForm to be triggered again when submit in onSubmit->reducer->validation
        {localDispatch(localActions.changeStateStatus('draft'))}

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      } finally {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      }
    }

    submitForm();
  }, [productProfileState.state.status]);

  const checkProductDetailsMedia = () => {
    // Prompt warning modal if image or video in product details
    for (const page of productProfileState.payload.pages) {
      if (!page.display.url.trim().length) {
        dispatch(
          toggleModal({
            status: 'warning',
            title: 'Just One More Step',
            subtitle: 'Image or Video Required in Product Details',
            button: 'Close',
          }),
        );
        return;
      }
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <ProductHeader
          productName={productProfileState.payload.name}
          productBrand={productProfileState.state.brand}
          onNameChange={localDispatchAction(localActions.editName)}
          onBrandChange={(selectedBrand) => {
            localDispatch(localActions.editBrand(selectedBrand));

            if (productProfileState.payload.story.logoType === 'brand') {
              localDispatch(
                TellYourStoryActions.editLogo(
                  selectedBrand.details.launchScreen.display.mainLogo,
                ),
              );
            }
          }}
          productGtin={productProfileState.payload.gtin}
          onGtinChange={localDispatchAction(localActions.editGtin)}
          brandList={productProfileState.state.brandList}
          productNameError={productProfileState.validation.name}
          productBrandError={productProfileState.validation.brand}
          productGtinError={productProfileState.validation.gtin}
        />
      </Grid>

      {!isAmbioxera && !isMackasShowcase && (
        <Grid item style={{alignSelf: 'center', width: '60%'}}>
          <ProgressBar
            percent={productProfileState.state.progressBarPosition}
            steps={[
              {
                label: 'Tell Your Story',
                onClick: () =>
                  localDispatch({type: 'navigate', payload: 'story'}),
              },
              {
                label: 'Product Details',
                onClick: () =>
                  localDispatch({type: 'navigate', payload: 'pages'}),
              },
            ]}
          />
        </Grid>
      )}

      <Grid item container style={{marginTop: 50}}>
        {(() => {
          switch (productProfileState.state.section) {
            case 'story':
              return (
                <TellYourStory
                  tabValue={productProfileState.state.story.focusedTabIndex}
                  story={productProfileState.payload.story}
                  hideSection={{
                    mainImage: isAmbioxera,
                    additionalSection: isAmbioxera,
                    keyMilestones: isAmbioxera,
                  }}
                  tabRename={
                    isAmbioxera ? {mainSection: 'tellYourStory'} : undefined
                  }
                  onTabChange={localDispatchAction(
                    TellYourStoryActions.changeTab,
                  )}
                  mainSectionImageOption={
                    productProfileState.state.story.mainSectionImageOption
                  }
                  brandLogo={
                    productProfileState.payload.brand.agliveToken.length
                      ? findTargetBrand(
                          productProfileState.state.brandList,
                          productProfileState.payload.brand.agliveToken,
                        )?.details.launchScreen.display.mainLogo || null
                      : null
                  }
                  validationState={productProfileState.validation.story}
                  onMainSectionImageOptionChange={localDispatchAction(
                    localActions.editMainSectionImageOption,
                  )}
                  onLogoChange={localDispatchAction(
                    TellYourStoryActions.editLogo,
                  )}
                  onLogoTypeChange={localDispatchAction(
                    TellYourStoryActions.editLogoType,
                  )}
                  onMainSectionImageChange={localDispatchAction(
                    TellYourStoryActions.editImage,
                  )}
                  onHeadlineChange={localDispatchAction(
                    TellYourStoryActions.editHeadline,
                  )}
                  onContentChange={localDispatchAction(
                    TellYourStoryActions.editContent,
                  )}
                  onAddMilestonesEntry={localDispatchAction(
                    TellYourStoryActions.addMilestonesEntry,
                  )}
                  onDeleteMilestonesEntry={localDispatchAction(
                    TellYourStoryActions.deleteMilestonesEntry,
                  )}
                  onDupMilestonesEntry={localDispatchAction(
                    TellYourStoryActions.dupMilestonesEntry,
                  )}
                  onMilestonesTitleChange={localDispatchAction(
                    TellYourStoryActions.editMilestonesTitle,
                  )}
                  onMilestonesDetailsChange={localDispatchAction(
                    TellYourStoryActions.editMilestonesDetails,
                  )}
                  onAddAdditionalEntry={localDispatchAction(
                    TellYourStoryActions.addAdditionalEntry,
                  )}
                  onDeleteAdditionalEntry={localDispatchAction(
                    TellYourStoryActions.deleteAdditionalEntry,
                  )}
                  onDupAdditionalEntry={localDispatchAction(
                    TellYourStoryActions.dupAdditionalEntry,
                  )}
                  onAdditionalTitleChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalTitle,
                  )}
                  onAdditionalContentChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalContent,
                  )}
                  onAdditionalMediaTypeChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalMediaType,
                  )}
                  onAdditionalUrlChange={localDispatchAction(
                    TellYourStoryActions.editAdditionalUrl,
                  )}
                />
              );
            case 'pages':
              return (
                <CreateProductProfileDetails
                  detailsArr={productProfileState.payload.pages}
                  validationState={productProfileState.validation.pages}
                  focusedPageIndex={
                    productProfileState.state.pages.focusedPageIndex
                  }
                  focusedTabIndex={
                    productProfileState.state.pages.focusedTabIndex
                  }
                  onPageAdd={() =>
                    localDispatch({
                      type: 'details/add_page',
                    })
                  }
                  onPageSelect={localDispatchAction(localActions.selectPage)}
                  onPageDuplicate={localDispatchAction(
                    localActions.duplicatePage,
                  )}
                  onPageDelete={localDispatchAction(localActions.deletePage)}
                  onPageMediaSelect={localDispatchAction(
                    localActions.selectPageMedia,
                  )}
                  onPageEdit={localDispatchAction(localActions.editPage)}
                  onTabSelect={localDispatchAction(localActions.selectTab)}
                  onContentAdd={localDispatchAction(
                    localActions.addContentSubheading,
                  )}
                  onContentDelete={localDispatchAction(
                    localActions.deleteContentSubheading,
                  )}
                  onContentDup={localDispatchAction(
                    localActions.dupContentSubheading,
                  )}
                  onAttachmentAdd={localDispatchAction(
                    localActions.addAttachment,
                  )}
                  onAttachmentDelete={localDispatchAction(
                    localActions.deleteAttachment,
                  )}
                />
              );
          }
        })()}
      </Grid>

      {!isAmbioxera && !isMackasShowcase && (
        <Grid
          item
          container
          justifyContent="space-between"
          style={{
            borderTop: `1px solid ${COLOR.GRAY_BORDER}`,
            marginTop: 40,
            paddingTop: 20,
            marginBottom: 75,
          }}>
          <Link
            component="button"
            underline="always"
            variant="body2"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility:
                productProfileState.state.section !== 'story'
                  ? 'visible'
                  : 'hidden',
            }}
            onClick={() => localDispatch({type: 'navigate/prev_step'})}>
            <ChevronLeftIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginRight: 10,
              }}
            />
            Previous Step
          </Link>

          <Link
            component="button"
            underline="always"
            variant="body2"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility:
                productProfileState.state.section !== 'pages'
                  ? 'visible'
                  : 'hidden',
            }}
            onClick={() => localDispatch({ type: 'navigate/next_step' })}
            id="NextStep"
          >
            Next Step
            <ChevronRightIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginLeft: 10,
              }}
            />
          </Link>
        </Grid>
      )}

      <Grid item container justifyContent="flex-end">
        <MyButton
          text="Save and Preview"
          variant="contained"
          disabled={!canSubmit(productProfileState, isAmbioxera || isMackasShowcase, isAmbioxera || isMackasShowcase)}
          onClick={() => {
            localDispatch(localActions.validateForm);
            !isAmbioxera && !isMackasShowcase && checkProductDetailsMedia();
          }}
          id="SaveAndPreviewBtn"
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Edit Product Profile',
    back: true,
  },
  EditProductProfile,
);
