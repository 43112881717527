import React from 'react';
import { Typography, Grid, Radio } from '@material-ui/core';

import MyButton from '../../../../presentation/button';
import defaultImage from '../../../../img/defaultImage.png';

import {fileUploader} from '../../../../store/product/actions';
import {useAppSelector} from "../../../../utils/hooks";

import COLOR from '../../../../styled/colors';
import { TokenService } from '@aglive/data-model';

type displayTypes = 
  TokenService.BrandToken["details"]["aboutUs"]["display"] &
  {
    onItemChange: (itemType: 'video' | 'image') => void;
    onImageUrlChange: (imageUrl: string) => void;
    onVideoUrlChange: (videoUrl: string) => void;
  };

const CreateAboutUsTabDisplay: React.FC<displayTypes> = ({
  item,
  imageUrl,
  videoUrl,

  onItemChange,
  onImageUrlChange,
  onVideoUrlChange
}) => {
  const userid = useAppSelector(state => state.auth.wallet);
  const uploadImageHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        window.alert("Upload image size more than 10mb ！");
      } else {
        const response=await fileUploader(userid,e.target.files[0]);
        const responseData=response[0].data;
        if(typeof responseData=='string'){
          onImageUrlChange(responseData);
        }   
      }
    }
  };
  const displayImageUpload = React.useRef<HTMLInputElement>(null);
  const handleClick=()=>{
    displayImageUpload.current?.click();
  }
  return (
    <Grid item container direction="column" >
      <Grid item container direction="column" style={{backgroundColor:COLOR.WHITE}}>
        <Grid item container justify="center" style={{marginBottom: 30}}>
          <Typography variant="h6" role="label" style={{ fontWeight: 600,marginTop:16}}>
            Select display
          </Typography>
        </Grid>
        <Grid item container style={{marginBottom:131}}>
          <Grid item xs={6} container direction="column" alignItems="center">
            <Radio
              color="primary"
              checked={item === 'image'}
              onChange={() => onItemChange('image')}
              value="image"
              name="product-display"
              inputProps={{ 'aria-label': 'image' }}
            />
         
            <div style={{marginTop:8,marginBottom:8}}>
              <Typography variant="h6" role="label" style={{marginBottom:8, fontWeight: 600}}>
                Page Image
              </Typography>
              <img src={imageUrl || defaultImage} height="95" width="186" alt="Display preview" />
            </div>
              <MyButton
              text={'Upload'}
              variant="contained"
              width={186}
              onClick={handleClick}
            /> 

            <input type="file" accept="image/*" data-cy="PageImage" onChange={uploadImageHandler} ref={displayImageUpload} style={{height: 0, width: 0}} />

          </Grid>
          {/* <Grid item xs={6} container direction="column" alignItems="center">
            <Radio
              color="primary"
              checked={item === 'video'}
              onChange={() => onItemChange('video')}
              value="video"
              name="product-display"
              inputProps={{ 'aria-label': 'image' }}
            />
               <div style={{marginTop:8,marginBottom:8}}>
                <Typography variant="h6" role="label" style={{marginBottom:8, fontWeight: 600}}>
                  Page Video URL
                </Typography>
                <CreateProductTextField
                  title={`Page Video URL`}
                  content={videoUrl}
                  style={classes.tabs}
                  onChange={value => onChange(value, 'videoUrl')}
                  />
            </div>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        style={{
          backgroundColor:'#FFFFFF',
          marginTop:29,
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Typography variant="h6" role="label" style={{ fontWeight: 500 }}>
          Tell your company story to consumers
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CreateAboutUsTabDisplay;