import React, { useState, useEffect } from 'react';
import withHeader from '../../presentation/withHeader';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Link
} from '@material-ui/core';
import CSVUploader from '../../presentation/CSVUploader';
import { HyperLink } from '../../presentation/word';
import MyButton from '../../presentation/button';
import { Line } from '../../presentation/Line';
import SearchBar from '../../presentation/SearchBar';
import { AssetDialogMessage, cleanSelectedFile, assetStyles } from '../asset/Components';
import CONSTANT from '../../config/constant';
import {
  validateWithSchema,
  JSONSchemaValidationError,
} from '../../utils/csvReader';
import { uniqueIds } from '../../config/schema';
import { ValidationErrorType, NetworkError, handleUploadErrors } from '../../utils/error';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { toggleModal } from '../../store/modal/actions';
import { getSires } from '../../store/angus/actions';
import { createTokensByCSV } from '../../store/asset/actions';
import COLOR from '../../styled/colors';
import AiJoiningImage from "../../img/AiJoining.png";
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from "@material-ui/core/TableCell";
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: "10px",
  },
  Image: {
    width: "106px",
    height: "106px",
  },
  marginTop: {marginTop: 60},
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 350,
  },
  cursor: {
    cursor: 'pointer'
  },
  link: {
    color: COLOR.GREEN_BUTTON,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}));

const RecordSires: React.FC<{ Props }> = (props) => {
  const classes = { ...assetStyles(), ...useStyles() };
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [csvData, setCSVData] = useState([]);
  const [sireList, setSireList] = useState([]);
  const [query, setQuery] = useState('');
  const [validationResult, setValidationResult] = useState({
    type: null as string,
    validationError: [] as Array<JSONSchemaValidationError>,
  });
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const userid = useAppSelector(state => state.auth.wallet);
  const [checked, setChecked] = useState({
    registed: false,
    updated: false,
    declared: false,
    signed: false,
  });


  const userProfile = useAppSelector(
    state => state.user.userProfileData,
  );
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const isAnimalBusiness = businessProfile.industryType == "ANIMALS"
  useEffect(() => {
    setChecked({
      registed: false,
      updated: false,
      declared: false,
      signed: false,
    });
  }, [file]);

  useEffect(() => {
    dispatch(getSires(userid)).then((data) => {
      setSireList(data.map((sire) => {
        const agToken = sire.externalIds.filter((exid) => !!exid.agliveToken);
        return {...sire.details, date: agToken[0].timestamp, agliveToken: agToken[0].agliveToken}
      }));
    });
  }, [dispatch, userid]);

  const siresHeader = [
    {sire: 'Angus Sire ID', date: 'Date Added', birthDate: 'Birth Date', edit: 'Edit', ai: 'AI Sire'},
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const { registed, updated, declared, signed } = checked;
  const noOfCheckedBoxes = [registed, updated, declared, signed].filter((v) => v).length;
  const uniqueIdTypes = uniqueIds.Australia;

  function ShowDeclaration() {
    if (csvData.length) {
      return (
        <Grid container>
          <FormControl component="fieldset" >
            <Typography variant='h3' className={classes.subTitle} >Declaration</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={registed} onChange={handleChange} color="primary" name="registed" id={'Declaration1'} />}
                label="I declare that all Angus sires recorded are registered sires with Angus Australia"
              />
              <FormControlLabel
                control={<Checkbox checked={updated} onChange={handleChange} color="primary" name="updated" id={'Declaration2'} />}
                label="I acknowledge my responsibility to identify each AI sire and update the number of females joined to an AI sire each year in AI Joining"
              />

              <img key="aijoining" className={classes.Image} alt="AI Joining" src={AiJoiningImage} />

              <FormControlLabel
                control={<Checkbox checked={declared} onChange={handleChange} color="primary" name="declared" id={'Declaration3'} />}
                label="I declare this information to be true and correct and now submit it to Angus Australia"
              />

              <FormControlLabel
                control={<Checkbox checked={signed} onChange={handleChange} color="primary" name="signed" id={'Declaration4'} />}
                label="Apply Signature"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <CSVUploader file={file} setFile={setFile} setCSVData={setCSVData} id={'RecordSiresCSV'}/>

      <Grid container justify="flex-end" style={{ maxWidth: window.innerWidth / 2 }}>
        <Grid item className={classes.linksContainer}  >
          <HyperLink
            className={classes.linksBetween}
            href={CONSTANT.ASSETINDUCTION.RECORDSIRES.CSVTEMP}>
            Download CSV Template
          </HyperLink>
          <HyperLink href={CONSTANT.ASSETINDUCTION.RECORDSIRES.CSVSAMPLE}>
            Download Example and Instructions
          </HyperLink>
        </Grid>
      </Grid>

      <ShowDeclaration />

      <Grid container justify="flex-end" >
        <MyButton
          disabled={csvData.length && (noOfCheckedBoxes === 4) ? false : true}
          buttonClass={classes.saveButton}
          text={'Save'}
          variant="contained"
          onClick={() => {
            validateWithSchema(csvData, CONSTANT.ASSETINDUCTION.RECORDSIRES.SCHEMA, true, uniqueIdTypes)
              .then(() => {
                setValidationResult({ type: null, validationError: [] });
                setCSVData((prevCSVEntries) =>
                  prevCSVEntries.map((CSVEntry) => {
                    CSVEntry.externalIds = uniqueIdTypes.reduce(
                      (accumIDs, curID) => ({
                        ...accumIDs,
                        [curID]: CSVEntry[curID],
                      }),
                      {},
                    );
                    uniqueIdTypes.map((id) => delete CSVEntry[id]);

                    return CSVEntry;
                  }),
                );
              })
              .catch((error) => {
                const e = error as ValidationErrorType<
                  Array<JSONSchemaValidationError>
                >;
                setValidationResult({
                  type: e.type,
                  validationError: e.error,
                });
              });
            setOpen(true);
          }}
        />
      </Grid>
      <Typography variant='h3' className={classes.marginTop}>Existing Sires</Typography>
      <Line />
      <Grid item container xs={12} sm={12} md={8} lg={8}>
        <Grid className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by Angus Sire ID"
          />
        </Grid>
        <TableContainer>
          <Table>
            <TableBody>
              {siresHeader.map((cell) => (
                <TableRow key={cell.sire} className={classes.greyBox}>
                  <TableCell align="left" style={{ fontWeight: 700 }}>{cell.sire}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.birthDate}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.ai}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.date}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.edit}</TableCell>
                </TableRow>
              ))}
              {sireList?.filter(res => res.sire_id.toLowerCase().includes(query?.toLocaleLowerCase())).sort((currentSire, nextSire) => 
                currentSire.sire_id.localeCompare(nextSire.sire_id)
              )
              .map((row) => (
                <TableRow key={row.sire_id} >
                  <TableCell align="left">
                    <Link className={classes.link} id={`Link${row.sire_id}`} onClick={() => {
                        history.push(`/private/verified/animalProfile/view-sire`, { sireID: row.sire_id });
                    }}>{row.sire_id}</Link>
                  </TableCell>
                  <TableCell align="center">{row.birth_date ? moment(row.birth_date, 'DD/MM/YYYY').format('DD-MM-YYYY') : 'No Birth Date'}</TableCell>
                  <TableCell align="center">{row.is_ai ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="center">{moment(row.date).format('DD-MM-YYYY')}</TableCell>
                  <TableCell align="center">
                    <EditIcon
                    className={classes.cursor}
                    id={`Edit${row.sire_id}`}
                    onClick={() => {
                      history.push(`/private/verified/recordSires/edit-sire`, { sireID: row.sire_id, dateRecorded: row.date, rfid: row.rfid, nlis_id: row.nlis_id, agliveToken: row.agliveToken });
                    }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {open && (
        <AssetDialogMessage
          open={open}
          isErr={validationResult.type ? true : false}
          fileName={file.name}
          handleClose={() => {
            setOpen(false);
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
          }}
          validationResult={validationResult}
          rightButtonClick={() => {
            setOpen(false);
            dispatch(
              createTokensByCSV({
                template: CONSTANT.ASSETINDUCTION.RECORDSIRES.TEMPLATE,
                accountId: isAnimalBusiness? businessProfile.angusProfile.acctID: undefined,
                angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
                activity_type: "",
                businessId: userProfile.businessId,
                nlisUserId: businessProfile.location[0]?.country === 'Australia' ? businessProfile.location[0].NLISUserID : '',
                nlisPassword: businessProfile.location[0]?.country === 'Australia' ? businessProfile.location[0].NLISPassword : '',
                nlisEmail: businessProfile.users[0].email,
                type: CONSTANT.ASSETINDUCTION.RECORDSIRES.TYPE,
                file,
                csvData,
              }, () => dispatch(getSires(userid)).then((data) => {
                setSireList(data.map((sire) => {
                  const agToken = sire.externalIds.filter((exid) => !!exid.agliveToken);
                  return {...sire.details, date: agToken[0].timestamp, agliveToken: agToken[0].agliveToken}
                }));
              }),
              'You\'ll get NLIS emails when using Angus Verified. Click the \'Opt-Out\' link in one of these emails.'
              ),
            )
              .then(() => {
                cleanSelectedFile('csvReader');
                setFile('');
              })
              .catch((error) => {
                cleanSelectedFile('csvReader');
                setFile('');
                if (error instanceof NetworkError) {
                  const processedError = handleUploadErrors(error, csvData.length, 'sires');
                  dispatch(
                    toggleModal({
                      status: processedError.status,
                      title: processedError.title,
                      subtitle: processedError.subtitle,
                      errorInfo: processedError.errorInfo ? processedError.errorInfo : '',
                    }),
                  );
                } else {
                  dispatch(
                    toggleModal({
                      status: 'failed',
                      title: error.title,
                      subtitle: error.message,
                    }),
                  );
                }
              });
          }}
          rightButtonClickIsErr={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({ type: null, validationError: [] });
            setOpen(false);
          }}
          csvData={csvData}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Record Sires',
    margin: 60,
    back: true
  },
  RecordSires,
);