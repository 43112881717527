import {makeStyles} from '@material-ui/core';
import COLOR from '../../../styled/colors';

const useStyles = makeStyles((theme) => ({
  sidePanelContainer: {
    maxHeight: 700,
    flexWrap: 'nowrap',
  },
  pagesPreviewContainer: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[200],
    marginTop: 25,
    flexGrow: 1,
  },
  pagesContainer: {
    height: 685,
    maxHeight: 685,
    flexGrow: 1,
    overflow: 'auto',
  },
  contentButton: {
    borderColor: COLOR.GREEN_BUTTON,
    color: COLOR.GREEN_BUTTON,
  },
  errorHelperText: {
    backgroundColor: COLOR.GRAY_SOLID,
    margin: 0,
  },
  textField: {
    backgroundColor: COLOR.WHITE,
  },
  textFieldContainer: {
    marginBottom: 20,
  },
  phoneDetailsContainer: {
    paddingLeft: 20,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 15
    },
  }
}));

export default useStyles;
