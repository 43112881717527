import moment from 'moment';

import {DashboardConfigType, DashboardWidgetType} from '../types';
import {data, getExIds, stripNonChar} from '../utils';
import API from '../../../../config/api';

const dashboardConfig: DashboardConfigType = {
  rows: [
    {
      widgets: [
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'PIC No.',
            key: 'picAddress',
            source: 'location',
            transformData: (dataStore) =>
              (dataStore?.picAddress || []).map((loc) => ({
                value: loc.PICAddress,
                attribute: {
                  label: `${loc.locationNickname} (${loc.PICAddress})`,
                  key: loc.PICAddress,
                },
              })),
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.CARD,
          payload: {
            title: 'Herd',
            subtitle: 'Total Number Inducted',
            icon: 'Cow',
            dependentKey: ['picAddress'],
            source: (_, dependencies) => ({
              url: API.GET.getTokensWithFilters,
              method: 'GET',
              params: {
                type: 'asset',
                pic_id: dependencies.picAddress.value,
              },
            }),
            key: 'assets',
            transformData: (dataStore, dependencies) =>
              Object.entries(
                (dataStore?.assets || []).reduce((accum, asset) => {
                  const exIds = getExIds(asset);
                  const processedAsset = {
                    identifier:
                      exIds.uhf ??
                      exIds.ceres_tag_vid ??
                      exIds.rfid,
                    species: asset.details.species ?? 'Cattle',
                    breed: asset.details.breed,
                    sex: asset.details.sex ?? 'Unknown',
                    weight: asset.details.weight,
                    pic: asset.details.pic_id,
                    age: asset.details.birth_year
                      ? new Date().getFullYear() - asset.details.birth_year
                      : asset.details.date_of_birth
                      ? new Date().getFullYear() -
                        Number(
                          moment(asset.details.date_of_birth).format('YYYY'),
                        )
                      : undefined,
                  };

                  if (
                    stripNonChar(dependencies.picAddress.value) !== stripNonChar(processedAsset.pic)
                  ) {
                    return accum;
                  }

                  const sex =
                    (processedAsset.sex &&
                      processedAsset.sex?.slice(0, 1).toUpperCase() +
                        processedAsset.sex?.slice(1)) ??
                    'Unknown';

                  if (!accum[sex]) {
                    accum[sex] = 1;
                  } else {
                    accum[sex]++;
                  }

                  return accum;
                }, {}),
              ).map(([sex, quantity]) => [`${sex}s`, quantity]),
          },
        },
        {
          type: DashboardWidgetType.CARD,
          payload: {
            title: 'Weight',
            subtitle: 'Herd Average',
            icon: 'Scale',
            dependentKey: ['picAddress'],
            source: (_, dependencies) => ({
              url: API.GET.getTokensWithFilters,
              method: 'GET',
              params: {
                type: 'asset',
                pic_id: dependencies.picAddress.value,
              },
            }),
            key: 'assets',
            transformData: (dataStore, dependencies) => {
              const assets = dataStore?.assets || [];

              const weightBySex = assets.reduce((accum, asset) => {
                const exIds = getExIds(asset);
                const processedAsset = {
                  identifier:
                    exIds.uhf ??
                    exIds.ceres_tag_vid ??
                    exIds.rfid,
                  species: asset.details.species ?? 'Cattle',
                  breed: asset.details.breed,
                  sex: asset.details.sex ?? 'Unknown',
                  weight: asset.details.weight,
                  pic: asset.details.pic_id,
                  age: asset.details.birth_year
                    ? new Date().getFullYear() - asset.details.birth_year
                    : asset.details.date_of_birth
                    ? new Date().getFullYear() -
                      Number(moment(asset.details.date_of_birth).format('YYYY'))
                    : undefined,
                };

                if (
                  stripNonChar(dependencies.picAddress.value) !== stripNonChar(processedAsset.pic)
                ) {
                  return accum;
                }

                const sex =
                  (processedAsset.sex &&
                    processedAsset.sex?.slice(0, 1).toUpperCase() +
                      processedAsset.sex?.slice(1)) ??
                  'Unknown';
                const weight = Number(processedAsset?.weight || 0);

                if (!accum[sex]) {
                  if (weight > 0) {
                    accum[sex] = {
                      quantity: 1,
                      weight,
                    };
                  }
                } else {
                  if (weight > 0) {
                    accum[sex].quantity++;
                    accum[sex].weight += weight;
                  }
                }

                return accum;
              }, {});

              return Object.entries<Record<string, any>>(
                weightBySex,
              ).map(([sex, stats]) => [
                `Average ${sex}`,
                `${Math.floor(
                  stats?.weight / Math.max(stats?.quantity, 1),
                )} KG`,
              ]);
            },
          },
        },
        // {
        //   type: DashboardWidgetType.CARD,
        //   payload: {
        //     title: 'Herd Value',
        //     subtitle: 'Total Value',
        //     icon: 'Money',
        //     cardContent: Object.keys(
        //       fetchedAssets.reduce((accum, asset) => {
        //         const sex =
        //           (asset.sex &&
        //             asset.sex?.slice(0, 1).toUpperCase() + asset.sex?.slice(1)) ??
        //           'Unknown';

        //         if (!accum[sex]) {
        //           accum[sex] = true;
        //         }

        //         return accum;
        //       }, {}),
        //     ).map((sex) => [
        //       <HerdContainer
        //         title={`${sex}s: Latest Price`}
        //         price={hasAngus ? (sex === 'Steer' ? '685.33' : '665.18') : ''}
        //       />,
        //       `$ `,
        //     ]),
        //     sizing: [8, 4]
        //   }
        // },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.TABLE,
          payload: {
            columns: data('Ceres Tag VID'),
            key: 'assets',
            source: (_, dependencies) => ({
              url: API.GET.getTokensWithFilters,
              method: 'GET',
              params: {
                type: 'asset',
                pic_id: dependencies.picAddress.value,
              },
            }),
            dependentKey: ['picAddress'],
            transformData: (dataStore, dependencies) =>
              (dataStore?.assets || [])
                .map((asset) => {
                  const exIds = getExIds(asset);
                  return {
                    identifier:
                      exIds.uhf ??
                      exIds.ceres_tag_vid ??
                      exIds.rfid,
                    species: asset.details.species ?? 'Cattle',
                    breed: asset.details.breed,
                    sex: asset.details.sex ?? 'Unknown',
                    weight: asset.details.weight,
                    pic: asset.details.pic_id,
                    age: asset.details.birth_year
                      ? new Date().getFullYear() - asset.details.birth_year
                      : asset.details.date_of_birth
                      ? new Date().getFullYear() -
                        Number(moment(asset.details.date_of_birth).format('YYYY'))
                      : undefined,
                  }
                })
                .filter(
                  (asset) => stripNonChar(dependencies.picAddress.value) === stripNonChar(asset.pic)
                )
                .map((asset, index) => {
                  return {
                    idx: index + 1,
                    identifier: asset.identifier,
                    species: 'Cattle',
                    breed: asset.breed ?? '',
                    age: asset.age,
                    sex: asset.sex,
                    weight: asset.weight ? asset.weight : '',
                  };
                }),
          },
        },
      ],
    },
  ],
};

export default dashboardConfig;
