import React, {
  useEffect,
  useReducer,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {useAppSelector} from '../../utils/hooks';
import {toggleModal} from '../../store/modal/actions';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../../store/spinner/types';
import {fetchReports} from '../../store/asset/actions';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import CONSTANT from '../../config/constant';
import {fetchSiteTokens} from 'src/store/site/actions';
import {flattenTree} from 'src/store/site/tree';
import {
  getFormMap,
  initialFieldsGenerator,
  FormMapT,
  RowFields,
  FilteringStateColumn,
  SortingStateColumn,
  getSubpages,
  SubPageType,
  PLANT_TRANSFER_OUT_FIELDS,
  ReportColumn,
  FieldConditionMetadata,
} from './ReportsFields';
import {businessProfileObject} from '@aglive/frontend-core';
import {BusinessMisc, TokenService} from '@aglive/data-model';
import {sex, getReportPayloadType} from './ReportsFields';
import {MultipleSelectOptionsType} from '../../presentation/MultipleSelect';
import {
  Column,
  Sorting,
  Table,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import {OuterFilter} from '@aglive/data-model/dist/misc/report';
import {fetchAssetProfiles} from '../../store/assetProfile/actions';
import {RawAssetProfile} from '../../store/assetProfile/types';
import {csvToJson, formatJsonData, jsonToCsv} from './csv';
import produce from 'immer';

const INITIAL_DATA_STATE = {
  rows: [] as Array<RowFields['ceres'] | RowFields['management']>,
  totalData: 0,
  report: undefined as undefined | number, //index of select
  currentPage: 0,
  pageSize: 10,
  subPage: null as SubPageType,
  hidePagination: false,
  tableGenerated: false, // keep table showing if search result has 0 row
  updateOptions: false, // decide if update multiselct options for inner filter
  sorting: [] as Sorting[], // e.g.: [{ columnName: 'rfid', direction: 'asc' }]
  fieldConditions: {} as {[key: string]: any},
  outerFilter: {} as {[key: string]: any},
  multiSelectOptions: {} as {
    [key: string]: Array<{label: string; value: string}>;
  },
  selectedFilters: {} as {[key: string]: Array<MultipleSelectOptionsType>},
  columns: [] as Array<Column> | Array<Column & ReportColumn>,
  columnExtensions: [] as Table.ColumnExtension[],
  columnOrder: [] as string[],
  hiddenColumns: [] as string[],
  hideColumn: [] as string[],
  sites: [] as string[],
  columnFilter: [] as Array<FilteringStateColumn>, //filteringStateColumnExtensions
  columnSort: [] as Array<SortingStateColumn>, //sortingStateColumnExtensions
  columnVisibility: [] as TableColumnVisibility.ColumnExtension[], // disable toggling month and average daily gain
  errorState: {
    dates: {
      status: true,
      message: '',
    },
  } as {[key: string]: {status: boolean; message: string}},
};
type PageType = 'other' | 'ceres' | 'management';
type InitialSelectedFilters = (typeof INITIAL_DATA_STATE)['selectedFilters'];
type FetchReportOptions = {
  download?: boolean;
  requestFirstPage?: boolean;
  sorting?: boolean;
};

type Action =
  | {
      type: 'get/data';
      rows: Array<RowFields['ceres'] | RowFields['management']>;
    }
  | {type: 'change/page'; currentPage: number}
  | {type: 'change/pageSize'; pageSize: number}
  | {type: 'change/totalData'; totalData: number}
  | {type: 'change/hiddenColumns'; hiddenColumns: Array<string>}
  | {type: 'set/error'; errorState: (typeof INITIAL_DATA_STATE)['errorState']}
  | {type: 'update/form'; report?: number}
  | {type: 'update/fieldConditions'; fieldConditions: {[key: string]: any}}
  | {
      type: 'update/multiSelectOptions';
      multiSelectOptions: {
        [key: string]: Array<{label: string; value: string}>;
      };
    }
  | {type: 'change/selectedFilters'; selectedFilters: InitialSelectedFilters}
  | {type: 'change/subPage'; subPage: SubPageType}
  | {type: 'change/tableGenerated'; tableGenerated: boolean}
  | {type: 'change/updateOptions'; updateOptions: boolean}
  | {type: 'update/hidePagination'; hidePagination: boolean}
  | {type: 'change/sorting'; sorting: Sorting[]}
  | {
      type: 'update/columns';
      columns: Array<Column> | Array<Column & {type: string}>;
    }
  | {type: 'update/columnExtensions'; columnExtensions: Table.ColumnExtension[]}
  | {type: 'change/columnOrder'; columnOrder: Array<string>}
  | {type: 'update/columnFilter'; columnFilter: Array<FilteringStateColumn>}
  | {type: 'update/columnSort'; columnSort: Array<SortingStateColumn>}
  | {
      type: 'update/columnVisibility';
      columnVisibility: TableColumnVisibility.ColumnExtension[];
    }
  | {type: 'update/hideColumn'; hideColumn: Array<string>}
  | {type: 'update/sites'; sites: Array<string>}
  | {type: 'update/outerFilter'; outerFilter: {[key: string]: any}};

const reducer = (
  prevState: typeof INITIAL_DATA_STATE,
  action: Action,
): typeof INITIAL_DATA_STATE => {
  const {type, ...actionData} = action;
  switch (action.type) {
    default:
      return {...prevState, ...actionData};
  }
};

function dateRangeFieldFilter(
  fieldConditions: Record<string, any>,
  fieldName: string,
  dateRange: {start_date: string; end_date: string},
) {
  const start_date = moment(dateRange.start_date).format('YYYY-MM-DD');
  const end_date = moment(dateRange.end_date).format('YYYY-MM-DD');
  return {
    [fieldName]: {
      ...fieldConditions[fieldName],
      filterValue: [start_date, end_date],
    },
  };
}

function getOdcLicenses(business: TokenService.BusinessToken['details']) {
  if (business.industryType !== 'PLANTS') {
    return undefined;
  }
  const licenses: Array<string> = business.licenses.reduce((result, l) => {
    if (l.name === 'ODC') {
      return [...result, l.licenseNumber];
    }
    return result;
  }, []);
  return licenses;
}

function getPlantConfig(business: TokenService.BusinessToken['details']) {
  if (business.industryType !== 'PLANTS') {
    return undefined;
  }
  return {
    licenses: getOdcLicenses(business),
  };
}

const SEX_LIST = sex.map((sex) => ({label: sex, value: sex}));

export const useReport = (page: PageType, subType?: SubPageType) => {
  const dispatch = useDispatch();
  const businessData = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const businessProfile = useMemo(
    () =>
      businessProfileObject(
        businessData as
          | BusinessMisc.AnimalBusinessInterface
          | BusinessMisc.PlantBusinessInterface,
      ),
    [businessData],
  );
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const businessReports = useAppSelector((state) => state.user.reports);
  const localizedPIC = businessProfile.isAustralia()
    ? CONSTANT.LOCALISATION_PIC.AU_LOCATION_PIC
    : CONSTANT.LOCALISATION_PIC.CA_LOCATION_PID;
  const formMap = useRef<FormMapT[]>(
    getFormMap(
      page,
      getPlantConfig(businessData),
      businessReports,
      businessProfile.isAustralia(),
    ),
  );
  const currentTimer = useRef<undefined | ReturnType<typeof setTimeout>>();
  const formMapIndex = useMemo(() => {
    const index = formMap?.current.findIndex(
      (fm) =>
        fm.key.toLowerCase() === subType?.toLowerCase().replace(/\s/g, '_'),
    );
    const givenIndex = index > -1 ? index : undefined;
    return givenIndex;
  }, [subType]);

  let additionalOverride = {};
  if (subType) {
    additionalOverride['subPage'] = subType;
    additionalOverride['report'] = formMapIndex;
  }
  const [state, localDispatch] = useReducer(reducer, {
    ...INITIAL_DATA_STATE,
    ...additionalOverride,
  });
  const locationSet = useAppSelector((state) => state.location.location);

  const supplierSet = useAppSelector(
    (state) => state.user.businessProfileData.location,
  );

  const supplierLocation = supplierSet
    ?.filter(
      (loc) =>
        !(
          userProfile.role.startsWith('establishment-') ||
          userProfile.role.startsWith('location-')
        ) ||
        userProfile.role === `location-${loc.locationUniqueId}` ||
        userProfile.role === `establishment-${loc.locationUniqueId}`,
    )
    .map((loc) => ({
      label: `${loc.locationNickname}`,
      value: `${loc.locationId}::${loc.locationNickname}`,
    }));

  const siteMaps = useAppSelector((state) => state.site)?.sort(
    (token, token2) => {
      return token.details.siteName > token2.details.siteName ? 1 : -1;
    },
  );

  const currentPluginReport = businessReports.find(
    (br) => br.type === state.subPage,
  );

  const allSiteNames: Array<string> = useMemo(() => {
    if (!siteMaps) {
      return [];
    }
    const siteList = Array.from(
      new Set(siteMaps.map((site) => site.details.siteName)),
    );
    localDispatch({type: 'update/sites', sites: siteList});
    return siteList;
  }, [siteMaps]);

  const allSiteLayerNames: Array<string> = useMemo(() => {
    if (!siteMaps) {
      return [];
    }
    return siteMaps.flatMap((site) =>
      flattenTree(site.details.layers).map((layer) => layer.layerName),
    );
  }, [siteMaps]);

  const assetProfileList: Array<RawAssetProfile> = useAppSelector(
    (state) => state.assetProfile.fetchedAssetProfiles,
  );

  const assetOptions: {
    strain: Array<string>;
    species: Array<string>;
    substrain: Array<string>;
    assetName: Array<string>;
  } = useMemo(() => {
    const result = {
      strain: [],
      species: [],
      substrain: [],
      assetName: [],
    };
    if (assetProfileList) {
      return assetProfileList.reduce((options, asset) => {
        if (asset.details.archived === false) {
          options.strain.push(asset.details.mainDisplay.strain);
          options.substrain.push(asset.details.mainDisplay.subStrain);
          options.species.push(asset.details.mainDisplay.species);
          options.assetName.push(asset.details.assetName);
        }
        return options;
      }, result);
    }
    return result;
  }, [assetProfileList]);

  const autoFillOptions: Record<
    ReportColumn['autofillOptions'],
    Array<string>
  > = useMemo(() => {
    return {
      site: allSiteNames,
      siteLayer: allSiteLayerNames,
      strain: assetOptions.strain,
      species: assetOptions.species,
      substrain: assetOptions.substrain,
      assetName: assetOptions.assetName,
    };
  }, [allSiteLayerNames, allSiteNames, assetOptions]);

  const locationPicAddr = useMemo(
    () =>
      locationSet.map((loc) => ({
        label: `${loc.locationNickname} (${loc.PICAddress})`,
        value: loc.PICAddress,
      })),
    [locationSet],
  );

  const {
    initialFieldConditions,
    initialOuterFilter,
    initialColumnExtensions,
    initialColumns,
    initialSelectedFilters,
    defaultRowFields,
    disabledFilterColumnExtensions,
    disabledSortingColumnExtensions,
    disabledChooserColumnExtensions,
    hideColumnExtensions,
    payloadType,
  } = useMemo(
    () =>
      initialFieldsGenerator(
        businessProfile.isPlants(),
        businessReports,
        businessProfile.isAustralia(),
      ),
    [businessProfile, businessReports],
  );

  const subPagesList = useMemo(() => {
    let tmpList = [...getSubpages(businessProfile.isPlants())];
    if (businessReports?.length) {
      const newSubPage = [];
      businessReports.forEach((rp) => {
        newSubPage.push(rp.type);
      });
      tmpList = businessProfile.isAustralia()
        ? [...tmpList, ...newSubPage]
        : newSubPage;
    }
    return tmpList;
  }, [businessReports, businessProfile]);

  const allowGenerate = useMemo(() => {
    let allow = true;
    const currRep =
      formMap?.current[state.report ?? 0]?.outerFilterFields ?? [];
    currRep.forEach((outerFilter) => {
      if (outerFilter.required) {
        const val = state.outerFilter[outerFilter.key];
        if (!val || (Array.isArray(val) && val.length === 0)) {
          allow = false;
        }
      }
    });
    return allow;
  }, [state.outerFilter, state.report]);

  const updateForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let updateVal: {[key: string]: number | string} = {
        [e.target.name]: e.target.value,
      };
      localDispatch({type: 'update/form', ...updateVal});
    },
    [],
  );

  const changePage = useCallback((pageNum: number) => {
    localDispatch({type: 'change/page', currentPage: pageNum});
  }, []);

  const changePageSize = useCallback((size: number) => {
    localDispatch({type: 'change/pageSize', pageSize: size});
  }, []);

  const changeColumnOrder = useCallback((newOrder: Array<string>) => {
    localDispatch({type: 'change/columnOrder', columnOrder: newOrder});
  }, []);

  const changeHiddenColumn = useCallback(
    (hidden: Array<string>) => {
      // always fetch the fields in viewDetails
      const hiddenCols = state.columns
        .filter((c) => c['viewDetails'])
        .map((c) => c.name);
      const colsToHide = hidden.filter((h) => !hiddenCols.includes(h));
      localDispatch({type: 'change/hiddenColumns', hiddenColumns: colsToHide});
    },
    [state.columns],
  );

  const resetFieldConditionsAndFilters = useCallback(() => {
    localDispatch({
      type: 'update/fieldConditions',
      fieldConditions: state.subPage
        ? initialFieldConditions[page][state.subPage] ?? {}
        : initialFieldConditions[page] ?? {},
    });
    if (state.subPage && state.subPage === 'movement') {
      // do not reset selectedFilters in movement page
      // to ensure outer and inner site_moved_to can change synchronously
      localDispatch({
        type: 'change/selectedFilters',
        selectedFilters: {
          ...initialSelectedFilters[page][state.subPage],
          site_moved_to: state.selectedFilters.site_moved_to,
        },
      });
    } else {
      let tmp = JSON.parse(
        JSON.stringify(
          state.subPage
            ? initialSelectedFilters[page][state.subPage] ?? {}
            : initialSelectedFilters[page] ?? {},
        ),
      );
      for (const [k, v] of Object.entries(state.selectedFilters)) {
        if (
          tmp[k] &&
          initialFieldConditions[page][state.subPage][k]['outerFilter']
        ) {
          //fill in if previously selected, for inner/outer filter combo
          tmp[k] = [...v];
        }
      }
      localDispatch({
        type: 'change/selectedFilters',
        selectedFilters: tmp,
      });
    }
    localDispatch({type: 'change/page', currentPage: 0});
  }, [
    initialFieldConditions,
    initialSelectedFilters,
    page,
    state.selectedFilters,
    state.subPage,
  ]);

  const updateConditions = useCallback(
    (
      payload: {
        [item: string]: Date | number | string | MultipleSelectOptionsType[];
      },
      type?: string,
      raw?: {[item: string]: number | string | MultipleSelectOptionsType[]},
    ) => {
      let fieldCond: typeof payload = {};
      let outerFilter: typeof payload = {};
      for (const [item, value] of Object.entries(payload)) {
        if (typeof state.fieldConditions[item] !== 'undefined') {
          fieldCond[item] = {
            ...state.fieldConditions[item],
            filterValue: Array.isArray(value)
              ? value.map((v) => (typeof v === 'object' ? v.value : v))
              : value,
            type: type,
          };
        }
        if (typeof state.outerFilter[item] !== 'undefined') {
          outerFilter[item] = value ?? raw[item];
        }
      }
      if (Object.keys(fieldCond).length) {
        localDispatch({
          type: 'update/fieldConditions',
          fieldConditions: {
            ...state.fieldConditions,
            ...fieldCond,
          },
        });
      }
      if (Object.keys(outerFilter).length) {
        localDispatch({
          type: 'update/outerFilter',
          outerFilter: {...state.outerFilter, ...outerFilter},
        });
      }
    },
    [state.fieldConditions, state.outerFilter],
  );

  const updateFieldConditions = useCallback(
    (
      item: string,
      value?: number | string | MultipleSelectOptionsType[],
      type?: string,
      raw?: number | string | MultipleSelectOptionsType[],
    ) => {
      updateConditions({[item]: value}, type, {[item]: raw});
      localDispatch({type: 'change/page', currentPage: 0});
    },
    [updateConditions],
  );

  const updateOuterFilter = useCallback(
    (
      item: string,
      payload: Date | number | string | MultipleSelectOptionsType[],
      secondaryField?: {[key: string]: string},
    ) => {
      let obj = {[item]: payload};
      if (secondaryField) {
        obj = Object.assign(obj, secondaryField);
      }
      updateConditions(obj);
    },
    [updateConditions],
  );

  const changeSelectedFilters = useCallback(
    (selectedOptions: MultipleSelectOptionsType[], columnName: string) => {
      localDispatch({
        type: 'change/selectedFilters',
        selectedFilters: {
          ...state.selectedFilters,
          [columnName]: selectedOptions,
        },
      });
    },
    [state.selectedFilters],
  );

  const updateOuterInnerFilters = useCallback(
    (item: string, payload: MultipleSelectOptionsType[]) => {
      updateOuterFilter(item, payload);
      changeSelectedFilters(payload, item);
    },
    [changeSelectedFilters, updateOuterFilter],
  );

  const changeSubPage = useCallback((subPage: SubPageType) => {
    localDispatch({type: 'change/subPage', subPage: subPage});
  }, []);

  const changeTableGenerated = useCallback((state: boolean) => {
    localDispatch({type: 'change/tableGenerated', tableGenerated: state});
  }, []);

  const changeSorting = useCallback((sorting: Sorting[]) => {
    localDispatch({type: 'change/sorting', sorting: sorting});
  }, []);

  const setInnerFilterDateError = useCallback(
    (errorStatus: boolean, errorMessage: string, fieldName: string) => {
      localDispatch({
        type: 'set/error',
        errorState: {
          ...state.errorState,
          [fieldName]: {
            status: errorStatus,
            message: errorMessage,
          },
        },
      });
    },
    [state.errorState],
  );

  const changeColumnStates = useCallback(
    (
      initiate: boolean,
      columns?: Column[],
      columnOrder?: string[],
      columnExtensions?: Table.ColumnExtension[],
      columnFilter?: Array<FilteringStateColumn>,
      columnSort?: Array<SortingStateColumn>,
      columnVisibility?: TableColumnVisibility.ColumnExtension[],
      hideColumn?: string[],
    ) => {
      if (initiate) {
        localDispatch({
          type: 'update/columns',
          columns: state.subPage
            ? initialColumns[page][state.subPage] ?? []
            : Array.from(initialColumns[page] ?? []),
        });
        localDispatch({
          type: 'change/columnOrder',
          columnOrder: Object.keys(
            state.subPage
              ? defaultRowFields[page][state.subPage] ?? []
              : Array.from(defaultRowFields[page] ?? []),
          ),
        });
        localDispatch({
          type: 'update/columnExtensions',
          columnExtensions: state.subPage
            ? initialColumnExtensions[page][state.subPage] ?? []
            : Array.from(initialColumnExtensions[page] ?? []),
        });
        localDispatch({
          type: 'update/columnFilter',
          columnFilter: state.subPage
            ? disabledFilterColumnExtensions[page][state.subPage] ?? []
            : Array.from(disabledFilterColumnExtensions[page] ?? []),
        });
        localDispatch({
          type: 'update/columnSort',
          columnSort: state.subPage
            ? disabledSortingColumnExtensions[page][state.subPage] ?? []
            : Array.from(disabledSortingColumnExtensions[page] ?? []),
        });
        localDispatch({
          type: 'update/columnVisibility',
          columnVisibility: state.subPage
            ? disabledChooserColumnExtensions[page][state.subPage] ?? []
            : Array.from(disabledChooserColumnExtensions[page] ?? []),
        });
        localDispatch({
          type: 'update/hideColumn',
          hideColumn: state.subPage
            ? hideColumnExtensions[page][state.subPage] ?? []
            : Array.from(hideColumnExtensions[page] ?? []),
        });
        // localDispatch({type: 'update/columnVisibility', columnVisibility: []});
      } else {
        localDispatch({type: 'update/columns', columns: columns ?? []});
        localDispatch({
          type: 'change/columnOrder',
          columnOrder: columnOrder ?? [],
        });
        localDispatch({
          type: 'update/columnExtensions',
          columnExtensions: columnExtensions ?? [],
        });
        localDispatch({
          type: 'update/columnSort',
          columnSort: columnSort ?? [],
        });
        localDispatch({
          type: 'update/columnFilter',
          columnFilter: columnFilter ?? [],
        });
        localDispatch({
          type: 'update/columnVisibility',
          columnVisibility: columnVisibility ?? [],
        });
        localDispatch({
          type: 'update/hideColumn',
          hideColumn: hideColumn ?? [],
        });
      }
    },
    [
      state.subPage,
      initialColumns,
      page,
      defaultRowFields,
      initialColumnExtensions,
      disabledFilterColumnExtensions,
      disabledSortingColumnExtensions,
      disabledChooserColumnExtensions,
      hideColumnExtensions,
    ],
  );

  const updateMultiSelectOptions = useCallback(
    (optionsList: {[key: string]: Array<string>}) => {
      // update options for inner multiselect filters
      // based on `options` from response or `site` from redux
      let newMultiSelectOptions = {};
      const getOptions = (
        optionsList: {
          [key: string]: string[];
        },
        optionNames: Array<string>,
        colName: string,
      ) => {
        if (!optionNames) {
          return optionsList[colName];
        }
        for (let i = 0; i < optionNames.length; i++) {
          const optionName = optionNames[i];
          if (optionsList[optionName]) {
            return optionsList[optionName];
          }
        }
        return optionsList[colName];
      };
      state.columns?.forEach((col: ReportColumn) => {
        if (col.type === 'multiSelect') {
          if (col.dropdownOptions) {
            newMultiSelectOptions[col.name] = col.dropdownOptions;
          } else {
            const options = col.autofillOptions
              ? autoFillOptions[col.autofillOptions]
              : getOptions(optionsList, col.optionNames, col.field ?? col.name);
            const uniqueOptions = Array.from(
              new Set(options ? options.flat() : []),
            );
            newMultiSelectOptions[col.name] = uniqueOptions.map((data) =>
              data?.toString().length
                ? {label: data, value: data}
                : {label: 'Null/Empty', value: data},
            );
          }
        }
      });
      localDispatch({
        type: 'update/multiSelectOptions',
        multiSelectOptions: newMultiSelectOptions,
      });
    },
    [state.columns, autoFillOptions],
  );

  const handleSubPageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      changeSubPage(subPagesList[e.target.value]);
      updateForm(e);
      changeTableGenerated(false);
    },
    [changeSubPage, changeTableGenerated, subPagesList, updateForm],
  );

  const handleMultiSelectChange = useCallback(
    (columnName: string, selectedData: MultipleSelectOptionsType[]) => {
      changeSelectedFilters(selectedData, columnName);
      if (
        !selectedData?.length ||
        (selectedData?.length > 1 &&
          selectedData?.length === state.multiSelectOptions[columnName]?.length)
      ) {
        // default to all if unselect all or select all (if only one option, select all default to this one option)
        updateFieldConditions(columnName, undefined, undefined, selectedData);
      } else {
        updateFieldConditions(columnName, selectedData);
      }
    },
    [changeSelectedFilters, state.multiSelectOptions, updateFieldConditions],
  );

  const handleTextfieldChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      column: Column & {type: string},
    ) => {
      clearTimeout(currentTimer?.current);
      currentTimer.current = setTimeout(() => {
        if (!e.target.value) {
          // if clear filter value should show all records
          updateFieldConditions(column.name);
        } else {
          switch (column.type) {
            case 'text':
              updateFieldConditions(
                column.name,
                e.target.value,
                businessProfile.isPlants() ? null : 'partial',
              );
              break;
            case 'number':
              updateFieldConditions(column.name, Number(e.target.value));
              break;
          }
        }
      }, 2000);
    },
    [businessProfile, updateFieldConditions],
  );

  const handleDateRangeChange = useCallback(
    (columnName: string, date: Array<Date>) => {
      if (
        date === null ||
        !moment(date[0]).isValid() ||
        !moment(date[1]).isValid()
      ) {
        // remove filtering if date is cleared or invalid
        updateFieldConditions(columnName);
        if (
          date !== null &&
          (!moment(date[0]).isValid() || !moment(date[1]).isValid())
        ) {
          setInnerFilterDateError(false, 'Invalid date', columnName);
        }
      } else {
        updateFieldConditions(columnName, [
          moment(date[0]).format('yyyy-MM-DD'),
          moment(date[1]).format('yyyy-MM-DD'),
        ]);
        setInnerFilterDateError(true, '', columnName);
      }
    },
    [setInnerFilterDateError, updateFieldConditions],
  );

  const handleClickGenerate = useCallback(() => {
    changeTableGenerated(true);
    localDispatch({
      type: 'update/hidePagination',
      hidePagination: formMap.current[state.report].hidePagination ?? false,
    });
    localDispatch({type: 'change/updateOptions', updateOptions: true});
    resetFieldConditionsAndFilters();
    localDispatch({
      type: 'change/hiddenColumns',
      hiddenColumns: state.columns
        .filter((c) => c['display'] === false)
        .map((c) => c.name),
    });
  }, [
    changeTableGenerated,
    state.report,
    state.columns,
    resetFieldConditionsAndFilters,
  ]);

  const handleHiddenColumnsChange = useCallback(() => {
    // set dispaly false/true when hidden/show columns
    // display for 'sex' should always be false
    let newConditions = JSON.parse(JSON.stringify(state.fieldConditions));
    for (let [key, value] of Object.entries(newConditions))
      if (state.hiddenColumns.includes(key)) {
        value['display'] = false;
      } else if (!state.hiddenColumns.includes(key)) {
        value['display'] = true;
      }
    localDispatch({
      type: 'update/fieldConditions',
      fieldConditions: newConditions,
    });
  }, [state.fieldConditions, state.hiddenColumns]);

  const resetSubPageStates = useCallback(() => {
    localDispatch({
      type: 'update/outerFilter',
      outerFilter: state.subPage
        ? initialOuterFilter[page][state.subPage] ?? {}
        : initialOuterFilter[page] ?? {},
    });
    localDispatch({
      type: 'update/fieldConditions',
      fieldConditions: state.subPage
        ? initialFieldConditions[page][state.subPage] ?? {}
        : initialFieldConditions[page] ?? {},
    });
    localDispatch({
      type: 'change/selectedFilters',
      selectedFilters: state.subPage
        ? initialSelectedFilters[page][state.subPage] ?? {}
        : initialSelectedFilters[page] ?? {},
    });
    localDispatch({type: 'change/page', currentPage: 0});
    localDispatch({
      type: 'change/sorting',
      sorting: INITIAL_DATA_STATE['sorting'],
    });
  }, [
    state.subPage,
    initialOuterFilter,
    page,
    initialFieldConditions,
    initialSelectedFilters,
  ]);

  const fetchReport =
    (
      opt: FetchReportOptions = {
        download: false,
        requestFirstPage: false,
        sorting: false,
      },
    ) =>
    async () => {
      dispatch({type: SPINNER_TOGGLE_ON});

      let data = {};
      let params = {};
      let fileName = '';
      let postMethod = 'POST' as 'POST' | 'GET';
      let transformDateFields: {[key: string]: string} = {};
      const currentReport = formMap?.current[state.report ?? 0];
      let reportPayloadType = payloadType[state.subPage];

      let formattedOuterFilters: {[key: string]: string | string[]} = {
        ...state.outerFilter,
      };
      for (const [k, v] of Object.entries(state.outerFilter)) {
        if (Array.isArray(v) && typeof v[0] === 'object') {
          formattedOuterFilters[k] = v.map((ofv) => ofv['value']);
        }
      }

      switch (page) {
        case 'ceres':
          /*data = {
          start_date: moment(state.outerFilter.start_date).format('YYYY-MM-DD'),
          end_date: moment(state.outerFilter.end_date).format('YYYY-MM-DD'),
          pic: state.outerFilter.locations?.map((loc) => loc.value)
        };*/
          data = {
            // get data for first page when opt.requestFirstPage is true
            pages: opt.download
              ? null
              : opt.requestFirstPage
              ? 1
              : state.currentPage + 1,
            itemPerPage: opt.download ? null : state.pageSize,
            getFilters: true, //  getFilters should always be true, but decide if update options by `state.updateOptions`
            fieldCondition: Object.values({
              ...state.fieldConditions,
              time: {
                ...state.fieldConditions['time'],
                filterValue: [
                  moment(state.outerFilter.start_date).format('YYYY-MM-DD'),
                  moment(state.outerFilter.end_date).format('YYYY-MM-DD'),
                ],
              },
              pic: {
                ...state.fieldConditions['pic'],
                filterValue: state.outerFilter.locations?.map(
                  (loc) => loc.value,
                ),
              },
            }),
            output: opt.download ? 'csv' : 'grid',
            type: 'addMovement',
          };
          fileName =
            state.outerFilter.locations.length > 3
              ? `Ceres Tag Report ${state.outerFilter.start_date} to ${
                  state.outerFilter.end_date
                } (${state.outerFilter.locations.length.toString()} locations)`
              : `Ceres Tag Report ${state.outerFilter.start_date} to ${
                  state.outerFilter.end_date
                } (${state.outerFilter.locations
                  .map((obj: {label: string; value: string}) => obj.value)
                  .join(', ')})`;
          break;
        case 'management':
          let fieldConditions = {} as {[key: string]: any};

          switch (state.subPage) {
            case 'management':
              // add outer filter 'Sex' to inner filter fieldCondition in payload
              // use initial fieldConditions if sex changed and click `Generate`
              // if state.updateOptions is true, need to initiate fieldConditions, add sex
              fieldConditions = state.updateOptions
                ? {
                    ...initialFieldConditions[page][state.subPage],
                    sex: {
                      ...initialFieldConditions[page][state.subPage].sex,
                      filterValue: state.outerFilter.selectedSex?.map(
                        (obj: {label: string; value: string}) => obj.value,
                      ),
                    },
                  }
                : {
                    ...state.fieldConditions,
                    sex: {
                      ...state.fieldConditions.sex,
                      filterValue: state.outerFilter.selectedSex?.map(
                        (obj: {label: string; value: string}) => obj.value,
                      ),
                    },
                  };
              break;
            case 'movement':
              // add outer filter 'Site Moved To' to inner filter fieldCondition in payload
              // if state.updateOptions is true, need to initiate fieldConditions columns, but except site_moved_to to keep inner sync to outer site_moved_to
              const transformCondition = (
                condition: Record<string, FieldConditionMetadata>,
              ) => {
                return produce(condition, (draft) => {
                  const originFilter = draft['origin'].filterValue;
                  if (originFilter === undefined) {
                    draft['origin'].filterValue = '';
                    draft['origin'].type = 'ne';
                  }
                });
              };
              if (businessProfile.isPlants()) {
                fieldConditions = state.updateOptions
                  ? initialFieldConditions[page][state.subPage]
                  : transformCondition(state.fieldConditions);
              }

              if (
                state.outerFilter?.end_date &&
                state.outerFilter?.start_date
              ) {
                fieldConditions = {
                  ...fieldConditions,
                  ...dateRangeFieldFilter(
                    state.fieldConditions,
                    'timestamp',
                    state.outerFilter as any,
                  ),
                };
              }
              break;
            case 'treatment':
              // add outer filter treatment date to inner filter
              const fieldName = 'treatment_date';
              const subPage = state.subPage;
              const start_date = moment(state.outerFilter.start_date).format(
                'YYYY-MM-DD',
              );
              const end_date = moment(state.outerFilter.end_date).format(
                'YYYY-MM-DD',
              );
              fieldConditions = state.updateOptions
                ? {
                    ...initialFieldConditions[page][subPage],
                    [fieldName]: {
                      ...initialFieldConditions[page][subPage][fieldName],
                      filterValue: [start_date, end_date],
                    },
                  }
                : {
                    ...state.fieldConditions,
                    ...dateRangeFieldFilter(
                      state.fieldConditions,
                      fieldName,
                      state.outerFilter as any,
                    ),
                  };
              if (opt.download) {
                const {attachments, ...rest} = fieldConditions;
                fieldConditions = {...rest};
              }
              break;
            case 'transfer':
              reportPayloadType =
                state.outerFilter?.transferType === 'out'
                  ? 'outConsignment'
                  : null;
              const transferFieldConditions =
                state.outerFilter?.transferType === 'out'
                  ? PLANT_TRANSFER_OUT_FIELDS
                  : initialFieldConditions[page][state.subPage];
              if (!state.updateOptions) {
                fieldConditions = Object.keys(state.fieldConditions).reduce(
                  (conditions, fieldConditionKey) => {
                    const filterValue =
                      state.fieldConditions[fieldConditionKey]?.filterValue;
                    const transferField =
                      transferFieldConditions[fieldConditionKey];
                    if (filterValue) {
                      return {
                        ...conditions,
                        [fieldConditionKey]: {
                          ...transferField,
                          filterValue: filterValue,
                        },
                      };
                    }
                    return {
                      ...conditions,
                      [fieldConditionKey]: {
                        ...transferField,
                      },
                    };
                  },
                  {},
                );
              } else {
                fieldConditions = transferFieldConditions;
              }
              break;
            default:
              fieldConditions = state.fieldConditions;
              if (businessReports?.length) {
                if (
                  currentPluginReport &&
                  currentPluginReport.outerFilters?.length
                ) {
                  const outerFilterValues: {
                    [key: string]: OuterFilter & {
                      value: string | Array<string>;
                    };
                  } = {};
                  //rearrange to object
                  currentPluginReport.outerFilters.forEach((otf) => {
                    outerFilterValues[otf.key] = {
                      ...otf,
                      value: formattedOuterFilters[otf.key],
                    };
                    if (
                      otf.type === 'date' &&
                      ['datesTz', 'datesEndTz'].includes(otf.key2)
                    ) {
                      transformDateFields[otf.key] = otf.key2;
                    }
                  });
                  for (const [key, val] of Object.entries(fieldConditions)) {
                    if (val['outerFilter']) {
                      if (Array.isArray(val['outerFilter'])) {
                        val['filterValue'] = val['outerFilter'].map(
                          (fcof, idx) => {
                            const newDate = new Date(
                              moment(outerFilterValues[fcof].value).format(
                                'YYYY-MM-DD',
                              ),
                            )
                              .toISOString()
                              .split('T')[0];
                            return val.dateField && idx === 1
                              ? `${moment(newDate).format(
                                  'YYYY-MM-DD',
                                )}T23:59:59`
                              : newDate;
                          },
                        );
                      } else if (val['outerFilter'] === 'locations') {
                        val['filterValue'] = state.outerFilter.locations.map(
                          (loc) => {
                            return val.field === 'pic_name'
                              ? loc.label.replace(
                                  new RegExp(` \\(${loc.value}\\)`),
                                  '',
                                )
                              : loc.value;
                          },
                        );
                      } else {
                        val['filterValue'] = val.dateField
                          ? `${moment(
                              outerFilterValues[val['outerFilter']].value,
                            ).format('YYYY-MM-DD')}T23:59:59`
                          : outerFilterValues[val['outerFilter']].value;
                        delete formattedOuterFilters[val['outerFilter']];
                      }
                      // delete val['outerFilter'];
                      fieldConditions[key] = val;
                    }
                    if (fieldConditions[key]['type'] !== 'partial') {
                      delete fieldConditions[key]['type'];
                    }
                  }
                }
              }
              break;
          }

          const outerFilters = {
            management: {
              date: moment(state.outerFilter.projectedWeightDate).format(
                'YYYY-MM-DD',
              ),
              averageGain: state.outerFilter.averageDailyGain,
            },
            supplierSummary: {
              weekStarting: moment(state.outerFilter).format('YYYY-MM-DD'),
            },
            weightHistory: {
              months: Number(state.outerFilter.numberOfMonths),
            },
            weightGain: {},
            averageDailyGain: {
              dateRange: {
                start: moment(state.outerFilter.start_date).format(
                  'YYYY-MM-DD',
                ),
                end: moment(state.outerFilter.end_date).format('YYYY-MM-DD'),
              },
            },
            'Average Daily Gain Report': {
              dateRange: {
                start: moment(state.outerFilter.start_date).format(
                  'YYYY-MM-DD',
                ),
                end: moment(state.outerFilter.end_date).format('YYYY-MM-DD'),
              },
            },
            movement: {},
            deadSoldExported: {},
            treatment: {},
          };

          let filterValues = outerFilters[state.subPage] ?? {
            ...formattedOuterFilters,
          };
          if (Object.keys(transformDateFields).length) {
            for (const [key, val] of Object.entries(transformDateFields)) {
              const tzOffset = Math.round(moment().utcOffset() / 60);
              let timing = '00:00:00';
              if (val === 'datesEndTz') {
                timing = '23:59:59';
              }
              filterValues[key] =
                moment(filterValues[key]).format('YYYY-MM-DD') +
                `T${timing}.000` +
                (tzOffset > 0 ? '+' : '-') +
                String(Math.abs(tzOffset)).padStart(2, '0') +
                ':00';
            }
          }

          let includeStatus = [];
          if (
            currentPluginReport &&
            currentPluginReport.includeStatus?.length
          ) {
            includeStatus = currentPluginReport.includeStatus;
          }

          // payload for grid table, retrive current page
          // payload for download csv, not passing `itemPerPage` and `pages` params
          data = {
            // get data for first page when opt.requestFirstPage is true
            pages: opt.download
              ? null
              : opt.requestFirstPage
              ? 1
              : state.currentPage + 1,
            itemPerPage: opt.download ? null : state.pageSize,
            getFilters: businessProfile.isPlants()
              ? state.subPage !== 'movement' && state.updateOptions
              : true, //  getFilters should always be true, but decide if update options by `state.updateOptions`
            fieldCondition: Object.values(fieldConditions), // for inner filters
            output: opt.download ? 'csv' : 'grid',
            filters: filterValues, // for outer filters
            sort:
              opt.sorting && state.sorting.length
                ? initialColumns['management'][state.subPage].find(
                    (item) => item.name === state.sorting[0].columnName,
                  )?.title
                : null,
            sortOrder:
              opt.sorting && state.sorting.length
                ? state.sorting[0].direction
                : null,
            type: reportPayloadType,
            tokenType: businessProfile.isPlants() ? 'plant' : 'asset',
            includeAssetWithStatus: includeStatus,
          };

          fileName = `${currentReport.label}`;
          if (state.subPage === 'inventory') {
            fileName = `${fileName} - ${state.outerFilter['licenseNumber']}`;
          } else if (state.subPage === 'transfer') {
            const postfix =
              state.outerFilter?.transferType === 'out'
                ? 'Transfer Out'
                : 'Transfer In';
            fileName = `${fileName} - ${postfix}`;
          }
          break;
      }
      await fetchReports({
        url: currentReport.value,
        method: postMethod,
        data: data,
        params: params,
      })
        .then((resp: any) => {
          const rowsToSet = [];
          const headers = [] as string[];
          let newColumnOrder = [],
            newColumns = [],
            newColumnExtensions = [],
            newColumnVisibility = [],
            newColumnSort = [],
            newColumnFilter = [];

          if (resp.extraColumns?.length > 0) {
            // show dynamic `month` and `average daily gain` columns by number of month
            const initOrder = Object.keys(
              state.subPage
                ? defaultRowFields[page][state.subPage] ?? []
                : Array.from(defaultRowFields[page] ?? []),
            );
            newColumnOrder = JSON.parse(
              JSON.stringify(
                state.columnOrder.filter((co) => initOrder.includes(co)),
              ),
            );
            newColumns = JSON.parse(
              JSON.stringify(
                state.columns.filter((co) => initOrder.includes(co.name)),
              ),
            );
            newColumnExtensions = JSON.parse(
              JSON.stringify(
                state.columnExtensions.filter((co) =>
                  initOrder.includes(co.columnName),
                ),
              ),
            );
            newColumnVisibility = JSON.parse(
              JSON.stringify(
                state.columnVisibility.filter((co) =>
                  initOrder.includes(co.columnName),
                ),
              ),
            );
            newColumnSort = JSON.parse(
              JSON.stringify(
                state.columnSort.filter((co) =>
                  initOrder.includes(co.columnName),
                ),
              ),
            );
            newColumnFilter = JSON.parse(
              JSON.stringify(
                state.columnFilter.filter((co) =>
                  initOrder.includes(co.columnName),
                ),
              ),
            );
            resp.extraColumns.forEach((extraCol) => {
              if (newColumnOrder.includes(extraCol)) return;
              newColumnOrder.push(extraCol);
              newColumns.push({
                name: extraCol,
                title: extraCol,
                customFilter: false,
              });
              newColumnExtensions.push({
                columnName: extraCol,
                width: '200',
                align: 'center',
                wordWrapEnabled: true,
              });
              newColumnVisibility.push({
                columnName: extraCol,
                togglingEnabled: false,
              });
              newColumnSort.push({columnName: extraCol, sortingEnabled: false});
              newColumnFilter.push({
                columnName: extraCol,
                filteringEnabled: false,
              });
            });
            changeColumnStates(
              false,
              newColumns,
              newColumnOrder,
              newColumnExtensions,
              newColumnFilter,
              newColumnSort,
              newColumnVisibility,
            );
          }

          const currColumnOrder =
            resp.extraColumns?.length > 0 ? newColumnOrder : state.columnOrder;
          if (Array.isArray(resp)) {
            // responses for Ceres Tag Report
            resp?.forEach((res) => {
              rowsToSet.push(
                formatRows(
                  res,
                  state.columns as any,
                  currColumnOrder,
                  state.hiddenColumns,
                  opt.download,
                  opt.download && state.hiddenColumns.length > 0,
                  formMap?.current[state.report ?? 0]?.dateFormat,
                ),
              );
            });
          } else if (typeof resp === 'object') {
            // responses for Management Report
            resp.filteredResult?.forEach((res) => {
              rowsToSet.push(
                formatRows(
                  res,
                  state.columns as any,
                  currColumnOrder,
                  state.hiddenColumns,
                  opt.download,
                  opt.download && state.hiddenColumns.length > 0,
                  formMap?.current[state.report ?? 0]?.dateFormat,
                ),
              );
            });
            localDispatch({type: 'change/totalData', totalData: resp.totalRow});
            // column filter options for response data
            if (resp.options) {
              formMap.current = getFormMap(
                page,
                getPlantConfig(businessData),
                businessReports,
                businessProfile.isAustralia(),
              );

              //management pages -> only update inner multiselect filters' options when state.updateOptions is true
              if (state.updateOptions) {
                updateMultiSelectOptions(resp.options);
                localDispatch({
                  type: 'change/updateOptions',
                  updateOptions: false,
                });
              }
            }
          }
          if (opt.download) {
            if (page === 'ceres') {
              state.columnOrder.forEach((col) => {
                if (!state.hiddenColumns.includes(col)) {
                  headers.push(
                    initialColumns[page].find((header) => header.name === col)[
                      'title'
                    ],
                  );
                }
              });
            }
            const csvBuffer = rowsToSet.length > 0 ? rowsToSet : resp;
            let formattedCsv = csvBuffer;
            if (formMap?.current[state.report ?? 0].formatCsv) {
              const csvFormat = {
                date: formMap?.current[state.report ?? 0]?.dateFormat,
              };
              const rows = csvToJson(formattedCsv);
              const formattedRows = rows.map((r) =>
                formatJsonData(r, csvFormat),
              );
              formattedCsv = jsonToCsv(formattedRows);
            }
            const csvFileName = fileName;
            downloadCSV(
              {
                headers: headers,
                body: formattedCsv,
              },
              csvFileName,
            );
          } else {
            localDispatch({type: 'get/data', rows: rowsToSet});
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(
            toggleModal({
              status: 'failed',
              title: error.title,
              subtitle: error.message,
            }),
          );
        });
      dispatch({type: SPINNER_TOGGLE_OFF});
    };

  useEffect(() => {
    changeTableGenerated(false);
    if (!subPagesList[formMapIndex]) {
      changeSubPage('');
      return;
    }
    changeSubPage(subPagesList[formMapIndex] as SubPageType);
    localDispatch({type: 'update/form', report: formMapIndex});
  }, [changeSubPage, changeTableGenerated, formMapIndex, subPagesList]);

  // for outer filter DatePicker
  useEffect(() => {
    const isDatesValid =
      moment(state.outerFilter.end_date) >=
      moment(state.outerFilter.start_date);

    localDispatch({
      type: 'set/error',
      errorState: {
        ...state.errorState,
        dates: {
          status: isDatesValid,
          message: isDatesValid ? '' : 'Please select valid dates.',
        },
      },
    });
  }, [state.outerFilter.start_date, state.outerFilter.end_date]);

  useEffect(() => {
    // prevent fetchReport when initiate fieldConditions
    if (
      page === 'management' &&
      (!state.subPage ||
        !state.fieldConditions ||
        Object.keys(state.fieldConditions).length === 0 ||
        state.fieldConditions === initialFieldConditions[page][state.subPage] ||
        !state.tableGenerated)
    )
      return;
    if (state.outerFilter?.locations?.length > 0 || state.subPage) {
      // if currentPage is 0, need to requestFirstPage to prevent requesting page > totalPage
      // if currentPage > 0, only update currentPage to 0
      if (state.currentPage === 0) {
        fetchReport({download: false, requestFirstPage: true, sorting: true})();
      } else {
        localDispatch({type: 'change/page', currentPage: 0});
      }
    }
  }, [state.fieldConditions, state.tableGenerated]);

  useEffect(() => {
    if (state.outerFilter?.locations?.length > 0 || state.subPage) {
      if (!state.tableGenerated) return;
      // prevent fetchReport twice when re-click generate
      // by resetFieldConditionsAndFilters() change currentPage to 0
      if (state.updateOptions) return;
      fetchReport({download: false, sorting: true})();
    }
  }, [state.currentPage]);

  useEffect(() => {
    if (!state.tableGenerated) return;
    if (state.currentPage === 0) {
      fetchReport({download: false, sorting: true})();
    } else {
      localDispatch({type: 'change/page', currentPage: 0});
    }
  }, [state.sorting, state.pageSize]);

  useEffect(() => {
    localDispatch({type: 'change/tableGenerated', tableGenerated: false});
    changeColumnStates(true);
    resetSubPageStates();
  }, [state.subPage]);

  useEffect(() => {
    dispatch(fetchSiteTokens());
  }, [dispatch]);

  useEffect(() => {
    if (!state.tableGenerated) return;
    handleHiddenColumnsChange();
  }, [state.hiddenColumns]);

  useEffect(() => {
    if (!state.subPage || state.subPage !== 'weightHistory') return;
    // reset states if change numberOfMonths
    changeColumnStates(true);
  }, [state.outerFilter.numberOfMonths]);

  useEffect(() => {
    if (!state.subPage || state.subPage !== 'movement') return;
    // keep outer filter site_moved_to sync to inner filter site_moved_to
    updateOuterFilter('site_moved_to', state.selectedFilters.site_moved_to);
  }, [state.selectedFilters.site_moved_to]);

  useEffect(() => {
    if (businessData.industryType === 'PLANTS') {
      dispatch(fetchAssetProfiles());
    }
  }, [dispatch, businessData.industryType]);

  return {
    state,
    localizedPIC,
    locationPicAddr,
    supplierLocation,
    allowGenerate,
    updateForm,
    changePage,
    changePageSize,
    changeSubPage,
    changeColumnOrder,
    changeHiddenColumn,
    fetchReport,
    initialColumns,
    defaultRowFields,
    updateFieldConditions,
    changeSelectedFilters,
    changeTableGenerated,
    changeSorting,
    setInnerFilterDateError,
    updateOuterFilter,
    updateOuterInnerFilters,
    handleSubPageChange,
    handleMultiSelectChange,
    handleTextfieldChange,
    handleDateRangeChange,
    resetFieldConditionsAndFilters,
    handleClickGenerate,
    sexList: SEX_LIST,
    businessProfile,
    ...{formMap: formMap?.current},
    ...{columns: state.columns},
    ...{columnExtensions: state.columnExtensions},
  };
};

function formatRows(
  res,
  defaultColumns: Array<ReportColumn>,
  columnOrder: Array<string>,
  hiddenColumns: Array<string>,
  isArray?: boolean,
  hideCols?: boolean,
  dateFormat?: string,
) {
  const array = [];
  const obj = {};
  //lowercase all keys
  const response = Object.keys(res).reduce((destination, key) => {
    const col = defaultColumns.find((c) => c.title === key);
    const resKey =
      col && col.name ? col.name : key.toLowerCase().replace(/\W/g, '_');
    destination[resKey] = res[key];
    return destination;
  }, {} as any);
  columnOrder.forEach((row) => {
    if (hideCols && hiddenColumns.includes(row)) return;
    let rowData = response[row] ?? res[row];
    // if (page === 'management') {
    //   rowData = response[row];
    // } else {
    if (row === 'temperature' && /^[\d.]+$/.test(rowData)) {
      rowData += '°C';
    } else if (
      rowData &&
      ((row.toLowerCase().includes('date') && dateFormat) ||
        row === 'date' ||
        row.toLowerCase() === 'timestamp' ||
        row.includes('time') ||
        row.includes('week'))
    ) {
      const dd_mm_yyy = CONSTANT.DD_MM_YYYY_REGEX.test(rowData)
        ? 'DD-MM-YYYY'
        : undefined;
      let date = moment(rowData, dd_mm_yyy);
      const rowDateformat =
        dateFormat ??
        (isArray ? 'yyyy-MM-DD hh:mm:ss' : 'MMMM Do yyyy, h:mm:ss a');
      rowData = date.format(rowDateformat);
    } else if (row === 'rfid' && !rowData) {
      rowData = response.externalIds?.find((eid) => eid.rfid);
      rowData = rowData ? rowData['rfid'] : '';
    }
    //}

    if (isArray) {
      array.push(rowData);
    } else {
      obj[row] = rowData;
    }
  });
  return isArray ? array : obj;
}

function downloadCSV(
  response: {headers: Array<string>; body: Array<Array<string>> | string},
  reportName: string,
) {
  let responseData = '';
  if (typeof response.body !== 'string') {
    // for Ceres Tag Report, body is JSON format
    responseData =
      response['headers'].join(',') +
      '\n' +
      response['body'].map((row) => row.join(',')).join('\n');
  } else {
    responseData = response['body'];
  }
  let csvData = new Blob([responseData], {
    type: 'text/csv;charset=utf-8;',
  });

  //In FF link must be added to DOM to be clicked
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(csvData);
  link.setAttribute('download', reportName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
