import React from 'react';
import MockupPhone from './MockupPhone';
import {Divider, makeStyles, Typography} from '@material-ui/core';
import defaultImage from '../img/defaultImage.png';
import {HyperLink} from './word';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import WaterMarks from '../img/Watermarks.png';
import {AssetProfilePage} from '../store/assetProfile/types';
import {TokenService} from '@aglive/data-model';
import {useAppSelector} from '../utils/hooks';
import { filterPermits } from '../store/profile/utils';

interface iProductMockupPhone {
  page:
    | TokenService.ProductToken['details']['pages'][number]
    | AssetProfilePage;
  isShowHistory?: boolean;
  isProduct?: boolean;
}

const useStyle = makeStyles((theme) => ({
  imagePreviewContainer: {
    width: '100%',
    borderRadius: '40px',
    height: 119,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    width: '95%',
    margin: 'auto',
    marginTop: '10px',
  },
}));

const ProductPagePhone: React.FC<iProductMockupPhone> = ({
  page,
  isShowHistory,
  isProduct = true,
}) => {
  const classes = useStyle();

  const displayUrl =
    'display' in page ? page.display?.url : page.mainDisplay?.url;
  const header = 'display' in page ? 'Product Information' : 'Item Information';
  const isVideo =
    'display' in page
      ? page.display?.item === 'video'
      : page.mainDisplay?.item === 'video';
  const licenses = useAppSelector((state) =>
    state.user.businessProfileData.industryType === 'PLANTS'
      ? state.user.businessProfileData.licenses ?? []
      : [],
  );
  return (
    <MockupPhone backgroundColor="#373935">
      <>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <ArrowBackIosIcon
            color="secondary"
            style={{
              marginTop: 95,
              marginLeft: 12,
              marginRight: 9,
              width: 18,
              height: 15,
            }}
          />
          <Typography
            variant="h5"
            role="label"
            style={{
              marginTop: 90,
              color: 'white',
              fontWeight: 400,
            }}>
            {header}
          </Typography>
        </div>

        <div
          style={{
            backgroundColor: 'white',
            width: 237,
            height: isShowHistory || !isProduct ? 334 : 374,
            marginTop: 5,
            borderRadius: '15px 15px 0 0',
            marginLeft: 12,
            display: 'flex',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}>
          <div style={{width: '100%'}}>
            <div className={classes.imagePreviewContainer}>
              {displayUrl && !isVideo ? (
                <img
                  src={displayUrl}
                  alt="Display preview"
                  width="100%"
                  height="119"
                />
              ) : (
                <img
                  src={defaultImage}
                  alt="Display preview"
                  width="100%"
                  height="119"
                />
              )}

              {/* Image or Video here */}
            </div>
            <Typography
              variant="h6"
              style={{
                fontWeight: 600,
                fontSize: 10,
                marginTop: 10,
                marginLeft: 9,
              }}>
              {'headline' in page ? (
                page.headline[0]
              ) : (
                <>
                  {page.mainDisplay?.species || 'Species'}
                </>
              )}
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontWeight: 600,
                fontSize: 10,
                marginTop: 2,
                marginLeft: 9,
              }}>
              {'headline' in page
                ? page.headline[1]
                : page.mainDisplay?.strain || 'Strain'}
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontWeight: 600,
                fontSize: 10,
                marginTop: 2,
                marginLeft: 9,
              }}>
              {'headline' in page
                ? page.headline[2]
                : page.mainDisplay?.subStrain || 'Sub Strain'}
            </Typography>

            <Divider className={classes.divider} />

            {page.content?.map(({subheading, content}, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="subtitle1"
                  style={{marginLeft: 9, marginTop: index === 0 ? 14 : 9}}>
                  {subheading}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: 9,
                    marginLeft: 9,
                    marginTop: 2,
                    marginRight: 9,
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                  }}>
                  {content}
                </Typography>
              </React.Fragment>
            ))}
            {page.attachment?.map(({title, url}, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="subtitle1"
                  style={{marginLeft: 9, marginTop: 9}}>
                  {title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginLeft: 9,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <HyperLink
                    href={url}
                    style={{fontSize: 9, lineHeight: '13px'}}
                    target="_blank">
                    {decodeURIComponent(url.split('/').reverse()[0])}
                  </HyperLink>
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            width: 237,
            height: isShowHistory || !isProduct ? 100 : 60,
            marginLeft: 12,
            display: 'flex',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}>
          <div style={{width: '100%', position: 'relative'}}>
            {isShowHistory && (
              <div
                style={{
                  borderTop: '1px solid #DDDDDD',
                  borderBottom: '1px solid #DDDDDD',
                  marginTop: 15,
                  width: '100%',
                  height: 26,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Typography
                  variant="body1"
                  style={{fontSize: 9, marginLeft: 9}}>
                  Delivery History
                </Typography>
                <ArrowForwardIosIcon
                  style={{
                    color: '#DDDDDD',
                    width: 15,
                    height: 15,
                    marginRight: 18,
                  }}
                />
              </div>
            )}
            {header === 'Item Information' && (
              <>
                <div
                  style={{
                    borderTop: '1px solid #DDDDDD',
                    borderBottom: '1px solid #DDDDDD',
                    marginTop: 10,
                    width: '100%',
                    height: 26,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Typography
                    variant="body1"
                    style={{fontSize: 9, marginLeft: 9}}>
                    Manage Asset
                  </Typography>
                  <ArrowForwardIosIcon
                    style={{
                      color: '#DDDDDD',
                      width: 15,
                      height: 15,
                      marginRight: 18,
                    }}
                  />
                </div>
                <div
                  style={{
                    borderBottom: '1px solid #DDDDDD',
                    width: '100%',
                    height: 26,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Typography
                    variant="body1"
                    style={{fontSize: 9, marginLeft: 9}}>
                    Movement History
                  </Typography>
                  <ArrowForwardIosIcon
                    style={{
                      color: '#DDDDDD',
                      width: 15,
                      height: 15,
                      marginRight: 18,
                    }}
                  />
                </div>
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
                position: 'absolute',
                bottom: '5px',
                left: '50%',
              }}>
              <img
                src={WaterMarks}
                style={{
                  width: 80,
                  height: 20,
                  position: 'relative',
                  left: '-50%',
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </>
    </MockupPhone>
  );
};

export default ProductPagePhone;
