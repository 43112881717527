import API from '../config/api';
import { Method } from 'axios';
import { store } from "../store/index";
import { logout } from '../store/auth/actions';
import { SPINNER_TOGGLE_OFF } from '../store/spinner/types';
import { GlobalAccessToken } from '@aglive/frontend-core';
import { callAPI } from './network';

export const authUrls = [API.POST.createUserWithAuth, API.POST.authUser, API.POST.refreshToken, API.POST.revokeToken, API.POST.ssoAuthUser]

export const refresh = () => {
    return new Promise(async (resolve, reject) => {
      const url = API.POST.refreshToken;
      const data = {};
      const PostMethod: Method = 'POST';
      
      callAPI({ url, method: PostMethod, data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
            console.error('refresh token -> error', error);
            if(store.getState().auth.isLoggedIn) {
              logout(action => store.dispatch(action), true);
            }
            store.dispatch({type: SPINNER_TOGGLE_OFF});
            reject(error);
          },
        );
    });
  }
  
const revokeToken = () => {
    return new Promise(async (resolve, reject) => {
        const url = API.POST.revokeToken;
        const data = {};
        const PostMethod: Method = 'POST';
        callAPI({ url, method: PostMethod, data })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
            },
        );
    });
}
  
export const authLogout = () => {
    return GlobalAccessToken.authLogout(revokeToken);
}
  