import { Typography, FormControl, Select, MenuItem,makeStyles,} from '@material-ui/core';
import COLOR from '../styled/colors';

type DropdownProps={
    name:string,
    handleChange:(event:any)=>void,
    itemNames:Array<string>,
    defaultValue:string,
    id?:string,
}

const useStyles = makeStyles((theme) => ({
    subTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        fontWeight: 600,
    },
    formControl: {
        width: '100%',
        maxWidth: 400,
    },
    select: {
        height: 48,
        borderColor: COLOR.GRAY_BORDER,
        width:'100%'
    },
}))
const Dropdown =(props: DropdownProps)=>{
    const classes = useStyles();
    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <Typography variant="h6" className={classes.subTitle}>
                {props.name}
            </Typography>
            <Select
                id={props.id}
                className={classes.select}
                value={props.defaultValue}
                onChange={props.handleChange}>
                {props.itemNames?.map((name, index) => (
                <MenuItem value={name} key={index} id={name}>
                    {name.toUpperCase()}
                </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default Dropdown;