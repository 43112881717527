import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MyButton from './button';

type Props={
   open: boolean;
   title: string;
   handleClose: () => void;
   leftButtonText?: string;
   rightButtonText?: string;
   leftButtonClick?: () => void;
   rightButtonClick?: () => void;
   isSettingCard?: boolean;
   size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
   marginBottom?: number;
   dialogStyle?: object;
}

const useStyles=makeStyles((theme) => ({
   root: {
      padding: 0,
      paddingBottom: theme.spacing(2),
      borderBottom: '2px solid',
      borderBottomColor: theme.palette.primary.main,
   },
   closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
   },
   buttonGrid: {
      marginTop: theme.spacing(4),
   },
   leftButton: {
      marginRight: theme.spacing(3),
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
   }
}));

const MyDialog=withStyles((theme) => ({
   paper: {
      padding: '48px 32px',
      maxHeight: '80%',
      overflowX: 'hidden',
   }
}))(Dialog);

const MyDialogContent=withStyles((theme) => ({
   root: {
      padding: 0,
   }
}))(DialogContent);

const DialogMessage: React.FC<Props>=(props) => {
   const classes=useStyles();

   if(props.isSettingCard) {
      return (
         <>
            <DialogTitle className={classes.root} disableTypography>
               <Typography variant="h3">{props.title}</Typography>
                  <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                     <CloseIcon />
                  </IconButton>
            </DialogTitle>
            <MyDialogContent>
               <Grid container>
                  <Grid container>
                     {props.children}
                  </Grid>
                  <Grid container justifyContent={(props.leftButtonText && props.rightButtonText) ? "space-between" : "flex-end"} alignItems="center" className={classes.buttonGrid}>
                     {props.leftButtonText
                        &&
                        <MyButton text={props.leftButtonText}
                        variant='outlined' width={160} fontSize={18} buttonClass={classes.leftButton} style={{marginRight: 0}} onClick={props.leftButtonClick} />}
                     
                     {props.rightButtonText
                        &&
                        <MyButton text={props.rightButtonText}
                        variant='contained' width={160} fontSize={18} onClick={props.rightButtonClick} id={`${props.rightButtonText}-RightBnt`}/>}
                  </Grid>
               </Grid>
            </MyDialogContent>
         </>
      );
   } else {
        return (
         <>
            <MyDialog fullWidth maxWidth={props.size ?? 'md'} onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
               {props.title ? <DialogTitle className={classes.root} disableTypography>
                     <Typography variant="h3">{props.title}</Typography>
                     <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                        <CloseIcon />
                     </IconButton>
                  </DialogTitle>
               : <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                  <CloseIcon />
               </IconButton>}
               <MyDialogContent style={{marginBottom: props.marginBottom ?? 0, ...props.dialogStyle}}>
                  <Grid container>
                     <Grid container>
                        {props.children}
                     </Grid>
                     {(props.leftButtonText || props.rightButtonText) && <Grid container justify="center" alignItems="center" className={classes.buttonGrid}>
                        {props.leftButtonText
                           &&
                           <MyButton text={props.leftButtonText}
                           variant='outlined' width={160} fontSize={18} buttonClass={classes.leftButton} onClick={props.leftButtonClick} />}
                        
                        {props.rightButtonText
                           &&
                           <MyButton text={props.rightButtonText}
                           variant='contained' width={160} fontSize={18} onClick={props.rightButtonClick} />}
                     </Grid>}
                  </Grid>
               </MyDialogContent>
            </MyDialog>
         </>
      );
   } 
}

export default DialogMessage;