import React, {useEffect, useState} from 'react';
import {Grid, TextField, FormLabel} from '@material-ui/core';
import {
  DatePicker as MaterialDatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {FormHelperText, Typography} from '@material-ui/core';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import COLOR from '../styled/colors';

type Props = {
  id?: string;
  label?: string;
  variant?: 'dialog' | 'inline' | 'static';
  format?: string;
  emptyLabel?: string;
  disableToolbar?: boolean;
  disabled?: boolean;
  buttonClass?: string;
  errorStatus?: boolean;
  errorMessage?: string;
  disablePast?: boolean;
  range?: boolean;
  style?: any;
  fontSize?: string;
  shouldDisableDate?: (date?: Date | null) => boolean;
  withBorder?: boolean;
  placeholder?: string;
  textAlign?: 'center' | 'right' | 'left';
  dateValue?: string;
  handleChange?: (date?: MaterialUiPickersDate | null) => void;
  readOnly?: boolean;
} & (
  | {
      range: true;
      dateValue?: Array<string>;
      handleChange?: (date?: Array<MaterialUiPickersDate> | null) => void;
    }
  | {
      range?: false;
    }
);

const DatePicker: React.FC<Props> = (props) => {
  const [startDate, setStartDate] = useState(
    props.dateValue ? props.dateValue[0] : null,
  );
  const [endDate, setEndDate] = useState(
    props.dateValue ? props.dateValue[1] : null,
  );
  useEffect(() => {
    if (Array.isArray(props.dateValue)) {
      setStartDate(props.dateValue[0]);
      setEndDate(props.dateValue[1]);
    }
  }, [props.dateValue]);
  return (
    <form
      noValidate
      style={props.style ?? {display: 'flex', flexDirection: 'column'}}>
      {props.label && (
        <Typography variant="h6" role="label" style={{fontWeight: 600}}>
          {props.label}
        </Typography>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <>
          {!props.range ? (
            <KeyboardDatePicker
              disableToolbar={props.disableToolbar ?? true}
              variant={props.variant ?? 'inline'}
              format={props.format ?? 'dd/MM/yyyy'}
              margin="normal"
              id={props.id}
              style={{
                margin: 0,
                border:
                  props.withBorder || props.withBorder === undefined
                    ? `solid 1px ${!props.errorStatus ? 'red' : 'lightgray'}`
                    : '0px',
                padding: '11px 8px',
                borderRadius: '5px',
              }}
              emptyLabel={props.emptyLabel ?? 'dd/mm/yyyy'}
              placeholder={props.placeholder ?? 'dd/mm/yyyy'}
              value={props.dateValue || null}
              onChange={(date) => props.handleChange(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              keyboardIcon={<DateRangeIcon style={{fill: '#373935'}} />}
              disablePast={props.disablePast}
              invalidDateMessage={
                props.dateValue === null ? '' : 'Invalid Date'
              }
              inputProps={{
                style: {
                  fontSize: props.fontSize ? `${props.fontSize}` : '18px',
                  textAlign: props.textAlign ?? 'left',
                },
              }}
              autoOk
              readOnly={props.readOnly}
              InputProps={{readOnly: props.readOnly}}
              disabled={props.disabled}
              shouldDisableDate={props.shouldDisableDate}
            />
          ) : (
            <>
              <RangeDatePicker
                {...props}
                id={`From-${props.id}`}
                range={false}
                value={startDate || null}
                maxDate={endDate ? new Date(endDate) : undefined}
                handleChange={(date) => {
                  const start = date
                    ? `${date.getFullYear()}-${
                        date.getMonth() + 1
                      }-${date.getDate()}`
                    : null;
                  setStartDate(start);
                  if (endDate && start) {
                    props.handleChange([new Date(start), new Date(endDate)]);
                  } else if (!start && !endDate) {
                    props.handleChange(null);
                  }
                }}
                invalidMessage={startDate === null ? '' : 'Invalid Date'}
                label="From"
              />
              <RangeDatePicker
                {...props}
                id={`To-${props.id}`}
                range={false}
                value={endDate || null}
                minDate={startDate ? new Date(startDate) : undefined}
                handleChange={(date) => {
                  const end = date
                    ? `${date.getFullYear()}-${
                        date.getMonth() + 1
                      }-${date.getDate()}`
                    : null;
                  setEndDate(end);
                  if (startDate && end) {
                    props.handleChange([new Date(startDate), new Date(end)]);
                  } else if (!startDate && !end) {
                    props.handleChange(null);
                  }
                }}
                invalidMessage={endDate === null ? '' : 'Invalid Date'}
                label="To"
              />
            </>
          )}
          {!props.errorStatus && (
            <FormHelperText style={{color: 'red', margin: '3px 14px 0'}}>
              {props.errorMessage}
            </FormHelperText>
          )}
        </>
      </MuiPickersUtilsProvider>
    </form>
  );
};

const RangeDatePicker: React.FC<
  Props & {
    value?: string;
    invalidMessage?: string;
    minDate?: Date;
    maxDate?: Date;
    id?:string;
  }
> = (props) => {
  return (
    <MaterialDatePicker
      id={props.id}
      disableToolbar={props.disableToolbar ?? true}
      variant={props.variant ?? 'dialog'}
      format={props.format ?? 'dd/MM/yyyy'}
      margin="normal"
      clearable={true}
      minDate={props.minDate}
      maxDate={props.maxDate}
      style={{
        margin: 3,
        backgroundColor: COLOR.WHITE,
        border:
          props.withBorder || props.withBorder === undefined
            ? `solid 1px ${props.errorStatus ? 'red' : 'lightgray'}`
            : '0px',
        padding: '3px 8px 1px',
        borderRadius: '5px',
      }}
      emptyLabel={props.emptyLabel ?? 'dd/mm/yyyy'}
      placeholder={props.placeholder ?? 'dd/mm/yyyy'}
      value={props.value}
      onChange={(date) => props.handleChange(date)}
      disablePast={props.disablePast}
      invalidDateMessage={props.invalidMessage}
      inputProps={{
        style: {
          fontSize: props.fontSize ? `${props.fontSize}` : '18px',
          textAlign: props.textAlign ?? 'left',
        },
      }}
      TextFieldComponent={(textFieldProps) => (
        <Grid container wrap={'nowrap'} style={{alignItems: 'center'}}>
          <FormLabel
            style={{fontSize: props.fontSize ? `${props.fontSize}` : '18px'}}>
            {props.label}
          </FormLabel>
          <TextField {...textFieldProps} />
        </Grid>
      )}
      autoOk
    />
  );
};

export default DatePicker;
