import {makeStyles} from '@material-ui/core';

import COLOR from '../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  previewContainer: {
    marginTop: 55,
    height: 635,
    overflow: 'hidden',
    overflowY: 'auto',
  },
  headerContainer: {
    backgroundColor: COLOR.WHITE,
    marginLeft: `calc(15% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    width: '70%',
  },
  sectionContainer: {
    backgroundColor: COLOR.WHITE,
    marginLeft: `calc(15% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    width: '70%',
    marginBottom: theme.spacing(1),
  },
  protectedBy: {
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: theme.spacing(1),
    verticalAlign: 'center',
    width: '90%',
  },
  imageContainer: {
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  logo: {
    width: '250px',
    maxHeight: '100px',
    objectFit: 'contain',
  },
  defaultImageText: {
    fontSize: 12,
    color: COLOR.WHITE,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 600,
  },
  mainSectionImg: {
    width: '250px',
    maxHeight: '150px',
    objectFit: 'contain',
  },
  additionalSectionImg: {
    width: '250px',
    maxHeight: '200px',
    objectFit: 'contain',
  },
  previewDefaultText: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
  },
  previewHeader: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
  },
  paragraphText: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  angusVerifiedContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '1px 0 1px 0',
    marginLeft: `calc(15% - ${theme.spacing(2)}px)`,
    width: `calc(70% + ${theme.spacing(2) * 2}px)`,
  },
  angusVerifiedText: {
    fontSize: 15,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    marginLeft: theme.spacing(1),
  },
  additionalSectionHeading: {
    backgroundColor: COLOR.WHITE,
    marginLeft: `calc(15% - ${theme.spacing(2)}px)`,
    width: `calc(70% + ${theme.spacing(4)}px)`,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  greenMarker: {
    backgroundColor: COLOR.GREENT_TEXT,
    width: '24px',
    height: '6px',
    marginRight: theme.spacing(1),
  },
  chainLine: {
    transform: 'scale(0.1, 1)',
    margin: 0,
    marginTop: -3,
    height: 30,
    border: `1px solid ${COLOR.GREEN_HIGHLIGHT}`,
    backgroundColor: COLOR.GREEN_HIGHLIGHT,
  },
  milestonesContainer: {
    marginLeft: -theme.spacing(2.5),
  },
  milestoneDetails: {
    color: COLOR.GRAY_TEXT,
  },
}));
