import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, {useMemo} from 'react';
import {CropReportType} from './crop/types';
import {getCropReportPurpose} from './crop/utils';
import {CropData} from './crop/types';

type TableHeaderCellProps = TableCellProps;
const useTableHeaderStyle = makeStyles((theme) => ({
  text: {
    fontWeight: 'bold',
  },
}));
const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  children,
  className,
  align = 'center',
  ...props
}) => {
  const classes = useTableHeaderStyle();
  return (
    <TableCell
      align={align}
      className={`${classes.text} ${className}`}
      {...props}>
      {children}
    </TableCell>
  );
};

type Props = {
  rows: Array<CropData>;
  type?: CropReportType;
};

const CropsTable: React.FC<Props> = ({rows, type}) => {
  const classes = useStyles();
  const isMonthlyReport = rows.length > 3;

  const headerColumns = useMemo(
    () => getHeaderColumns(isMonthlyReport, type).filter((col) => !col.hide),
    [isMonthlyReport, type],
  );
  const groupColumns = useMemo(() => getGroupColumns(type), [type]);

  return (
    <TableContainer component={Box} style={{minWidth: 800, overflowX: 'auto'}} data-cy={isMonthlyReport? 'MonthlyTable': 'YearlyTable'}>
      <Table>
        <TableHead>
          <TableRow>
            {groupColumns.map((col, i) => {
              const props = getHeaderCellProps('group', col, classes);
              return (
                <TableHeaderCell {...props} key={`group-${i}`}>
                  {col.labels.map((label, j) => (
                    <Typography
                      key={`group-label-${j}`}
                      display='block'
                      variant='inherit'>
                      {label}
                    </Typography>
                  ))}
                </TableHeaderCell>
              );
            })}
          </TableRow>
          <TableRow>
            {headerColumns.map((col, i) => {
              const props = getHeaderCellProps('header', col, classes);
              return (
                <TableHeaderCell {...props} key={`header-${i}`}>
                  {col.labels.map((label, j) => (
                    <Typography
                      display='block'
                      variant='inherit'
                      key={`header-label-${j}`}>
                      {label}
                    </Typography>
                  ))}
                </TableHeaderCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            const period = row.year ?? row.month;
            const isRollover = period.toLowerCase().includes('rollover');
            return (
              <TableRow key={i}>
                {i % 2 === 0 ? (
                  <TableCell
                    align='center'
                    className={`${classes.sticky}`}
                    rowSpan={2}>
                    {period}
                  </TableCell>
                ) : null}
                <TableCell
                  align='center'
                  className={`${
                    isMonthlyReport
                      ? classes.typeColumn
                      : classes.typeColumnYearly
                  }`}>
                  {row.type}
                </TableCell>
                {/* Plant generation */}
                <TableCell align='center'>
                  {row.generatedGenetic || 0}
                </TableCell>
                <TableCell align='center'>
                  {row.generatedProduction || 0}
                </TableCell>
                <TableCell align='center'>
                  {row.generatedExternal || 0}
                </TableCell>
                {/* Plant transfer */}
                <TableCell align='center'>
                  {isRollover ? 'N/A' : row.transferredAnotherHolder || 0}
                </TableCell>
                <TableCell align='center'>
                  {isRollover ? 'N/A' : row.transferredAnotherSite || 0}
                </TableCell>
                {type === 'medicinal' ? (
                  <TableCell align='center'>
                    {isRollover ? 'N/A' : row.transferExported || 0}
                  </TableCell>
                ) : null}
                {/* Harvesting */}
                <TableCell align='center'>
                  {isRollover ? 'N/A' : row.harvestedSuccess || 0}
                </TableCell>
                <TableCell align='center'>
                  {isRollover ? 'N/A' : row.harvestedLoss || 0}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'sticky',
    left: 0,
    zIndex: 800,
    backgroundColor: 'white',
  },
  typeColumn: {
    position: 'sticky',
    left: '93px',
    background: 'white',
    zIndex: 800,
  },
  typeColumnYearly: {
    position: 'sticky',
    left: '69px',
    background: 'white',
    zIndex: 800,
  },
  defaultHeader: {
    backgroundColor: '#F6F7F8',
  },
  generationGroup: {
    backgroundColor: '#1F7C3E',
    color: '#ffffff',
  },
  generationHeader: {
    backgroundColor: '#BEE4CB',
  },
  transferGroup: {
    backgroundColor: '#88B481',
    color: '#ffffff',
  },
  transferHeader: {
    backgroundColor: '#C9DCC8',
  },
  harvestGroup: {
    backgroundColor: '#9ECB5F',
    color: '#ffffff',
  },
  harvestHeader: {
    backgroundColor: '#D2E6BC',
  },
}));

type HeaderCellConfig = {
  labels: Array<string>;
  span?: number;
  sticky?: boolean;
  width?: number | string;
  group: 'default' | 'generation' | 'transfer' | 'harvest';
  typeColumn?: boolean;
  hide?: boolean;
  monthly?: boolean;
};

function getHeaderCellProps(
  type: 'group' | 'header',
  col: HeaderCellConfig,
  classes: ReturnType<typeof useStyles>,
) {
  let props: TableHeaderCellProps = {};
  let className = '';
  if (col.sticky) {
    className = `${className} ${classes.sticky}`;
  }
  if (col.typeColumn) {
    className = `${className} ${
      col.monthly ? classes.typeColumn : classes.typeColumnYearly
    }`;
  }
  if (col.group) {
    const typeName = type === 'group' ? 'Group' : 'Header';
    className = `${className} ${classes[`${col.group}${typeName}`] ?? ''}`;
  }
  if (className) {
    props.className = className;
  }
  if (col.width) {
    props.width = col.width;
  }
  if (col.span) {
    props.colSpan = col.span || 1;
  }
  return props;
}

function getGroupColumns(type: CropReportType): Array<HeaderCellConfig> {
  return [
    {
      labels: [''],
      span: 2,
      sticky: true,
      group: 'default',
    },
    {
      labels: ['Plant Generation'],
      group: 'generation',
      span: 3,
    },
    {
      labels: ['Plant Transfers'],
      group: 'transfer',
      span: type === 'medicinal' ? 3 : 2,
    },
    {
      labels: ['Plant Harvesting'],
      group: 'harvest',
      span: 2,
    },
  ];
}

const transferAnotherHolderLabel =
  '# Sold/transferred to another ND Act license holder';
const transferAnotherSiteLabel =
  '# Transferred to another site on the same ND Act license';
const plantExportedLabel = '# Plants Exported';
const harvestSuccessLabel = (purpose: string) => {
  return `# Plants harvested for ${purpose} purposes`;
};
const harvestDestroyedLabel = '# Plants destroyed*/ crop loss (excludes waste)';

function getHeaderColumns(isMonthlyReport: boolean, type: CropReportType) {
  const purpose = getCropReportPurpose(type);
  const headerColumns: Array<HeaderCellConfig> = [
    {
      labels: isMonthlyReport ? ['Month'] : ['Year'],
      width: 130,
      sticky: true,
      group: 'default',
    },
    {
      labels: ['Type'],
      width: 100,
      group: 'default',
      typeColumn: true,
      monthly: isMonthlyReport,
    },
    // Generation
    {
      labels: ['Genetic Stock Plants #'],
      width: 100,
      group: 'generation',
    },
    {
      labels: ['Production Plants #'],
      width: 100,
      group: 'generation',
    },
    {
      labels: ['External Plants #'],
      group: 'generation',
      width: 100,
    },
    // Transfer
    {
      labels: isMonthlyReport
        ? [transferAnotherHolderLabel, 'C1']
        : [transferAnotherHolderLabel],
      group: 'transfer',
      width: 240,
    },
    {
      labels: isMonthlyReport
        ? [transferAnotherSiteLabel, 'C2']
        : [transferAnotherSiteLabel],
      group: 'transfer',
      width: 240,
    },
    {
      labels: isMonthlyReport
        ? [plantExportedLabel, 'P6']
        : ['# Plants Exported'],
      group: 'transfer',
      width: 240,
      hide: type !== 'medicinal',
    },
    // Harvest
    {
      labels: isMonthlyReport
        ? [harvestSuccessLabel(purpose), 'N/A']
        : [harvestSuccessLabel(purpose)],
      group: 'harvest',
      width: 240,
    },
    {
      labels: isMonthlyReport
        ? [harvestDestroyedLabel, 'Onsite / C3']
        : [harvestDestroyedLabel],
      group: 'harvest',
      width: 240,
    },
  ];
  return headerColumns;
}

export default CropsTable;
