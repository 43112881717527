import {GmpPermit, OdcPermit} from '@aglive/data-model/dist/misc/business';
import {Grid, Typography} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {Buttons} from 'src/presentation/ButtonsGroup';
import MyTable from 'src/presentation/Table';
import {toggleModal, toggleModalOff} from 'src/store/modal/actions';
import {useAppDispatch} from 'src/utils/hooks';
import {TABLE_DATA_EMPTY_STYLE} from '../style';
import {useStyle} from '../style/useStyle';
import {getInitialPermitState} from './dataTemplate';
import {EditPermitFormRow} from './EditPermitFormRow';
import {PermitRowView} from './PermitRowView';
import {IndexedGmpLicense, IndexedOdcLicense} from './types';

export type PermitListLabels = {
  enterNumber: string;
  required: string;
  permitPlantLimit?: string;
  thcValue?: string;
  addPermit: string;
  action: string;
  permits: string;
  update: string;
  permitUpdateWarning: string;
  yes: string;
  no: string;
  continue: string;
  permitNumber: string;
  permitStarDate: string;
  permitExpiryDate: string;
};

type PermitListProps = {
  license: IndexedOdcLicense | IndexedGmpLicense;
  onSavePermit: (
    permitIndex: number,
    permit: OdcPermit | GmpPermit,
    onSuccess: (permit: OdcPermit | GmpPermit) => void,
  ) => void;
  onDeletePermit: (
    permitIndex: number,
    onDeleted: (deleted: boolean) => void,
  ) => void;
  onAddPermit: (
    permit: OdcPermit | GmpPermit,
    onSuccess: (permit: OdcPermit | GmpPermit) => void,
  ) => void;
  labels: PermitListLabels;
  onEditing: (isEditing: boolean) => void;
};

export const PermitTable: React.FC<PermitListProps> = ({
  license,
  labels,
  onAddPermit,
  onDeletePermit,
  onSavePermit,
  onEditing,
}) => {
  const tableHeaders = labels.permitPlantLimit
    ? [
        labels.permitNumber,
        labels.thcValue,
        labels.permitStarDate,
        labels.permitExpiryDate,
        labels.permitPlantLimit,
        labels.action,
      ]
    : [
        labels.permitNumber,
        labels.permitStarDate,
        labels.permitExpiryDate,
        labels.action,
      ];
  const dispatch = useAppDispatch();

  const classes = useStyle();
  const [localPermits, setLocalPermits] = useState<OdcPermit[] | GmpPermit[]>([
    ...license.permits,
  ]);
  const [editPermits, setEditPermits] = useState<boolean[]>(
    license.permits.map((permit) => false),
  );
  const [newPermitIndex, setNewPermitIndex] = useState<number | null>(null);

  const handleEditPermit = (permitIndex: number) => {
    setEditPermits((prev) =>
      prev.map((edit, i) => (i === permitIndex ? true : edit)),
    );
  };

  const handleDeletePermit = (permitIndex: number) => {
    if (newPermitIndex === permitIndex) {
      setLocalPermits((prev) => prev.filter((_permit, i) => i !== permitIndex));
      setEditPermits((prev) => prev.filter((_edit, i) => i !== permitIndex));
      setNewPermitIndex(null);
    } else {
      onDeletePermit(permitIndex, (deleted: boolean) => {
        if (deleted) {
          setLocalPermits((prev) =>
            prev.filter((_permit, i) => i !== permitIndex),
          );
          setEditPermits((prev) =>
            prev.filter((_edit, i) => i !== permitIndex),
          );
          if (newPermitIndex != null) {
            setNewPermitIndex((prev) => prev - 1);
            setEditPermits((prev) =>
              prev.map((edit, i) => (i === newPermitIndex - 1 ? true : edit)),
            );
          }
        }
      });
    }
  };

  const handleSavePermit = (
    permitIndex: number,
    permit: OdcPermit | GmpPermit,
  ) => {
    if (labels.permitPlantLimit && permit.permitId) {
      dispatch(
        toggleModal({
          status: 'warning',
          title: `${labels.update}?`,
          subtitle: [labels.permitUpdateWarning, '', `${labels.continue}?`],
          renderButton: (
            <Buttons
              leftButtonTitle={labels.no}
              rightButtonTitle={labels.yes}
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
              }}
              rightButtonOnClick={() => {
                dispatch(toggleModalOff());
                savePermit(permitIndex, permit);
              }}
            />
          ),
        }),
      );
    } else {
      savePermit(permitIndex, permit);
    }
  };

  const savePermit = (permitIndex: number, permit: OdcPermit | GmpPermit) => {
    setLocalPermits((prev) =>
      prev.map((oldPermit, i) => (i === permitIndex ? permit : oldPermit)),
    );
    if (newPermitIndex === permitIndex) {
      onAddPermit(permit, () => {
        setEditPermits((prev) =>
          prev.map((edit, i) => (i === permitIndex ? false : edit)),
        );
        setNewPermitIndex(null);
      });
    } else {
      onSavePermit(permitIndex, permit, () => {
        setEditPermits((prev) =>
          prev.map((edit, i) => (i === permitIndex ? false : edit)),
        );
      });
    }
  };

  const handleAddPermit = () => {
    if (newPermitIndex == null) {
      setLocalPermits((prev) => [
        ...prev,
        getInitialPermitState(labels.permitPlantLimit ? 'ODC' : 'GMP'),
      ]);
      setEditPermits((prev) => [...prev, true]);
      setNewPermitIndex(localPermits.length);
    }
  };

  useEffect(() => {
    onEditing(editPermits.some((editing, i) => editing));
  }, [editPermits]);

  return (
    <Grid item container xs={12} direction="column" style={{marginTop: 30}}>
      <Typography variant="h3" role="label" style={{marginBottom: 20}}>
        {labels.permits}
      </Typography>
      {localPermits && localPermits.length > 0 ? (
        <MyTable
          heads={tableHeaders}
          tableBody={
            <>
              {localPermits.map((permit, permitIndex) =>
                editPermits[permitIndex] ? (
                  <EditPermitFormRow
                    key={`permit-${permitIndex}`}
                    labels={labels}
                    initialPermit={permit}
                    onSubmit={(permit) => {
                      handleSavePermit(permitIndex, permit);
                    }}
                    onDelete={() => handleDeletePermit(permitIndex)}
                  />
                ) : (
                  <PermitRowView
                    key={`permit-${permitIndex}`}
                    labels={labels}
                    permit={permit}
                    onEdit={() => handleEditPermit(permitIndex)}
                    onDelete={() => handleDeletePermit(permitIndex)}
                  />
                ),
              )}
            </>
          }
          dataEmptyStyle={TABLE_DATA_EMPTY_STYLE}
        />
      ) : null}
      <Grid item style={{paddingTop: '2rem', width: 'max-content'}}>
        <Typography className={classes.hyperlink} onClick={handleAddPermit}>
          {labels.addPermit}
        </Typography>
      </Grid>
    </Grid>
  );
};
