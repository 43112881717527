import React from 'react';

import {Box, Grid} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import MockupPhoneMobDetails from '../../../presentation/MockupPhoneMobDetails';
import CreateProductTabAttachment from '../ProductProfile/CreateProductTabAttachment';
import MobMainSection from './MobMainSection';

export enum TabMap {
  mainSection,
  attachments,
}
const TabLabels = Object.values(TabMap).slice(0, 2);

interface MobDetailsProps {
  mob: TokenService.AnimalPromotion['details']['mob'];
  mobList: TokenService.AnimalPromotion['details']['mob']['linkMob'][];
  validationState: TokenService.AnimalPromotion['details']['mob'];
  tabValue: number;
  onMediaItemChange: (
    value: TokenService.AnimalPromotion['details']['mob']['media']['item'],
  ) => void;
  onMediaUrlChange: (value: string) => void;
  onLinkMobChange: (value: string) => void;
  onDisplayChange: (value: 'mob' | 'animal' | 'mob_animal') => void;
  onMsaChange: (value: boolean) => void;
  onSignedChange: (value: boolean) => void;
  onDeclarationsChange: (
    key: 'ERP' | 'BRD' | 'WHP' | 'WHP_ESI' | 'fiveInOne',
    value: boolean,
  ) => void;
  onAttachmentsChange: (keys: Array<number | string>, value: string) => void;
  onAttachmentAdd: () => void;
  onAttachmentDelete: (attachmentIndex: number) => void;
  onTabChange: (value: number) => void;
}

const MobDetails: React.FC<MobDetailsProps> = ({
  mob,
  mobList,
  validationState,
  tabValue,
  onMediaItemChange,
  onMediaUrlChange,
  onLinkMobChange,
  onDisplayChange,
  onMsaChange,
  onSignedChange,
  onDeclarationsChange,
  onAttachmentsChange,
  onAttachmentAdd,
  onAttachmentDelete,
  onTabChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={12} lg={5} justifyContent="center">
        <MockupPhoneMobDetails mob={mob} />
      </Grid>
      <Grid item xs={12} lg={7} id="MobDetailsForm">
        <Tabs
          tabHeaders={TabLabels as string[]}
          value={tabValue}
          setValue={onTabChange}>
          <TabPanel
            isShown={tabValue === TabMap.mainSection}
            index={TabMap.mainSection}>
            <Box p={3} className={classes.tabContainer}>
              <MobMainSection
                mob={mob}
                mobList={mobList}
                validationState={validationState}
                onMediaItemChange={onMediaItemChange}
                onMediaUrlChange={onMediaUrlChange}
                onLinkMobChange={onLinkMobChange}
                onDisplayChange={onDisplayChange}
                onMsaChange={onMsaChange}
                onSignedChange={onSignedChange}
                onDeclarationsChange={onDeclarationsChange}
              />
            </Box>
          </TabPanel>
          <TabPanel
            isShown={tabValue === TabMap.attachments}
            index={TabMap.attachments}>
            <Box p={3} className={classes.tabContainer}>
              <CreateProductTabAttachment
                attachment={mob.attachment}
                onAdd={onAttachmentAdd}
                onDelete={onAttachmentDelete}
                onChange={onAttachmentsChange}
              />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
};

export default MobDetails;
