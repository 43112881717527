import withHeader from '../../presentation/withHeader';
import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  makeStyles, 
  withStyles, 
  Box, 
  Table, 
  TableBody, 
  TableContainer, 
  TableRow, 
  TableCell, 
  Switch, 
  TextField
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import MyButton from '../../presentation/button';
import { assetStyles } from '../asset/Components';
import COLOR from '../../styled/colors';
import { Line } from '../../presentation/Line';
import { useLocation, useHistory } from 'react-router-dom';
import { replaceTag, getAnimalProfile } from '../../store/angus/actions';
import moment from 'moment';

export const useStyles = makeStyles((theme) => ({
  tablePadHead: {paddingBottom: 16, paddingTop: 16, fontWeight: 700, borderBottom: 'none'},
  tablePadCell: {paddingBottom: 16, paddingTop: 16, borderBottomColor: COLOR.GRAY_SOLID},
  tableSpace: {padding:16, paddingLeft: 8, paddingRight: 8, borderBottom: 'none'},
  bold: {fontWeight: 600},
  customInput: {
    root: {
      '& input:disabled + .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
        borderRadius: 0
      },
      '& input:disabled': {
        color: COLOR.BLACK,
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  }
}));

type tokenData = {
  timestamp: string;
  agliveToken: string;
};

const EditAnimal: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const state: any = useLocation().state;
  const { visualTag, rfid, nlis_id } = state;
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const userId = useAppSelector(state => state.auth.wallet);
  const classes = { ...assetStyles(), ...useStyles() };
  const isAnimalBusiness = businessProfile.industryType == "ANIMALS"
  
  const [isRfid, setIsRfid] = useState(!!rfid);
  const [newNlis, setNewNlis] = useState(nlis_id);
  const [newRfid, setNewRfid] = useState(rfid);
  const [tokenData, setTokenData] = useState({} as tokenData);

  const rows = [
    { name: 'Angus Visual Tag ID', value: 'Date Recorded' },
    { name: visualTag, value: moment(tokenData?.timestamp).format('DD-MM-YYYY') }
  ];

  const GreenSwitch = withStyles({
    switchBase: {
      color: COLOR.GREEN_BUTTON,
      '&$checked': {
        color: COLOR.GREEN_BUTTON,
        '& + $track': {
          backgroundColor: COLOR.GRAY_SOLID,
          border: `1px solid ${COLOR.GRAY_BORDER}`,
          opacity: 1
        }
      }
    },
    track: {
      backgroundColor: COLOR.GRAY_SOLID,
      border: `1px solid ${COLOR.GRAY_BORDER}`,
      opacity: 1
    },
    checked: {
      color: COLOR.GREEN_BUTTON
    },
  })(Switch);
  const handleChange = (e) => {
    if (e.target.name == 'nlis') {
      setNewNlis(e.target.value);
    } else {
      setNewRfid(e.target.value);
    }
  }

  const saveData = () => {
    let idChanged = {};
    if (newNlis !== nlis_id) {
      idChanged['nlis_id'] = newNlis;
    }
    if (newRfid !== rfid) {
      idChanged['rfid'] = newRfid;
    }
    dispatch(replaceTag(userId, tokenData?.agliveToken, {
      nlisEmail: businessProfile.users[0].email,
      nlisPassword: isAnimalBusiness? businessProfile.angusProfile.NLISPassword: undefined,
      nlisUserId: isAnimalBusiness? businessProfile.angusProfile.NLISUserID: undefined
    }, {
      angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
      replaceMLATags: false,
      ...idChanged
    })).then((resp) => {
      let newIDs = {nlis_id: '', rfid: ''};
      for (const eids of resp.data[0].externalIds) {
        if (eids.nlis_id) {
          newIDs['nlis_id'] = eids.nlis_id;
        } else if (eids.rfid) {
          newIDs['rfid'] = eids.rfid;
        }
      }
      history.replace({
        pathname: `/private/verified/recordAnimals/edit-animal`, 
        state: Object.assign(state, Object.assign(state, newIDs))
      });
      setNewNlis(newIDs['nlis_id']);
      setNewRfid(newIDs['rfid']);
    });
  }

  //in case they come back to edit but new ids not refreshed, go back to mob list
  const backTwice = () => {
    history.go(-2);
  }

  useEffect(() => {
    const animalID = [{
      [isRfid ? 'rfid' : 'nlis_id']: isRfid ? rfid : nlis_id
    }];
    dispatch(getAnimalProfile(animalID, backTwice)).then((resp) => {
      resp.forEach((calve) => {
        const agliveToken = calve.externalIds.filter((clv) => !!clv.agliveToken)[0];
        setTokenData(agliveToken);
      });
    });
  }, []);

  return (<>
    <Box key="dataBox">
      <Grid key="dataGrid" item container lg={6} md={6} sm={8} xs={12}>
        <TableContainer key="dataTableContainer">
          <Table key="dataTable" padding='none'>
            <TableBody key="dataTableBody">
              {rows.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell key={`cell1-${index}`} align="left" classes={{root: classes[`tablePad${index > 0 ? 'Cell' : 'Head'}`]}}>{row.name}</TableCell>
                  <TableCell key={`cell2-${index}`} classes={{root: classes.tableSpace}}>&nbsp;</TableCell>
                  <TableCell key={`cell3-${index}`} align="left" classes={{root: classes[`tablePad${index > 0 ? 'Cell' : 'Head'}`]}}>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
    <Box key="selectBox" mt={7} mb={2}>
      <Typography key="selectHeader" variant="h3">
        Select ID Type
      </Typography>
    </Box>
    <Line key="line" />
    <Box key="idToggle" mt={2}>
      <Grid key="idGridContainerToggle" item container lg={6} md={6} sm={8} xs={12}>
          <Typography key="idTextToggle" component="div">
            <Grid key="idGridToggle" component="label" container alignItems="center" spacing={1}>
              <Grid key="rfidToggle" item>RFID</Grid>
              <Grid key="switchToggle" item>
                <GreenSwitch key="greenSwitch" checked={!isRfid} onChange={() => setIsRfid(!isRfid)} />
              </Grid>
              <Grid key="nlisToggle" item>NLIS ID</Grid>
            </Grid>
          </Typography>
      </Grid>
    </Box>
    <Box key="idBox" mt={3}>
      <Grid key="idForm" container item spacing={4} lg={6} md={6} sm={8} xs={12} alignContent={'stretch'}>
        <Grid key="nlisItem" item style={{flexGrow:1}}>
          <Typography key="nlisLabel" className={classes.bold} gutterBottom={true}>NLIS ID</Typography>
          <TextField
            key="nlisInput"
            className={classes.customInput}
            placeholder={'NLIS ID'}
            value={newNlis}
            name="nlis"
            variant="outlined"
            disabled={isRfid}
            onChange={handleChange}
          />
        </Grid>
        <Grid key="rfidItem" item style={{flexGrow:1}}>
          <Typography key="rfidLabel" className={classes.bold} gutterBottom={true}>RFID</Typography>
          <TextField
            key="rfidInput"
            className={classes.customInput}
            placeholder={'RFID'}
            value={newRfid}
            name="rfid"
            variant="outlined"
            disabled={!isRfid}
            onChange={handleChange}
            id="RFIDInput"
          />
        </Grid>
      </Grid>
    </Box>
    <Box key="saveBox" mt={10} display="flex" justifyContent="flex-end">
      <MyButton
          key="saveButton"
          buttonClass={classes.saveButton}
          text={'Save'}
          variant="contained"
          disabled={newRfid === rfid && newNlis === nlis_id}
          onClick={saveData}
        />
    </Box>
  </>);
};

export default withHeader(
  {
    title: 'Edit Animal',
    margin: 60,
    back: true,
    id:'EditAnimal'
  },
  EditAnimal,
);