import React from 'react';

import {Grid} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import MockupPhoneTellYourStory from '../../../presentation/MockupPhoneTellYourStory';
import MainSection from './MainSection';
import AdditionalSection from './AdditionalSection';
import KeyMilestones from './KeyMilestones';
import { RecursivePartial } from '../../../utils/type';

export enum TabMap {
  mainSection,
  keyMilestones,
  additionalSection,
}
const TabLabels = Object.values(TabMap).slice(0, 3);

interface TellYourStoryProps {
  story: TokenService.ProductToken['details']['story'];
  mainSectionImageOption: 'image' | '';
  tabValue: number;
  isAngus?: boolean;
  hideSection?: {mainSection?: boolean, mainImage?: boolean, keyMilestones?: boolean; additionalSection?: boolean};
  tabRename?: {[key:string]: string},
  brandLogo: string | null;
  validationState: RecursivePartial<TokenService.ProductToken['details']['story']>;
  onTabChange: (value: number) => void;
  onMainSectionImageOptionChange: (value: 'image' | '') => void;
  onLogoChange: (value: string) => void;
  onLogoTypeChange: (
    value: TokenService.ProductToken['details']['story']['logoType'],
  ) => void;
  onMainSectionImageChange: (value: string) => void;
  onHeadlineChange: (headlineIndex: number, value: string) => void;
  onContentChange: (value: string) => void;
  onAddMilestonesEntry: () => void;
  onDeleteMilestonesEntry: (index: number) => void;
  onDupMilestonesEntry: (index: number) => void;
  onMilestonesTitleChange: (index: number, value: string) => void;
  onMilestonesDetailsChange: (index: number, value: string) => void;
  onAddAdditionalEntry: () => void;
  onDeleteAdditionalEntry: (index: number) => void;
  onDupAdditionalEntry: (index: number) => void;
  onAdditionalTitleChange: (index: number, value: string) => void;
  onAdditionalContentChange: (index: number, value: string) => void;
  onAdditionalMediaTypeChange: (
    index: number,
    value: TokenService.ProductToken['details']['story']['additional'][number]['mediaType'],
  ) => void;
  onAdditionalUrlChange: (index: number, value: string) => void;
}

const TellYourStory: React.FC<TellYourStoryProps> = ({
  story,
  mainSectionImageOption,
  tabValue,
  isAngus,
  hideSection,
  tabRename,
  brandLogo,
  validationState,
  onTabChange,
  onMainSectionImageOptionChange,
  onLogoChange,
  onLogoTypeChange,
  onMainSectionImageChange,
  onHeadlineChange,
  onContentChange,
  onAddMilestonesEntry,
  onDeleteMilestonesEntry,
  onDupMilestonesEntry,
  onMilestonesTitleChange,
  onMilestonesDetailsChange,
  onAddAdditionalEntry,
  onDeleteAdditionalEntry,
  onDupAdditionalEntry,
  onAdditionalTitleChange,
  onAdditionalContentChange,
  onAdditionalMediaTypeChange,
  onAdditionalUrlChange,
}) => (
  <>
    <Grid item container xs={12} lg={5} justifyContent="center">
      <MockupPhoneTellYourStory
        story={story}
        brandLogo={brandLogo}
        mainSectionImageOption={mainSectionImageOption}
        angusVerified={isAngus}
      />
    </Grid>
    <Grid item xs={12} lg={7} id="TellYourStoryForm">
      <Tabs
        tabHeaders={(TabLabels as string[]).filter((tl) => {
          return !hideSection || !hideSection[tl];
        }).map((tl) => tabRename && tabRename[tl] ? tabRename[tl] : tl)}
        value={tabValue}
        setValue={onTabChange}>
        {/* Main Section tab */}
        {!hideSection?.mainSection && 
        <TabPanel
          isShown={tabValue === TabMap.mainSection}
          index={TabMap.mainSection}>
          <MainSection
            story={story}
            mainSectionImageOption={mainSectionImageOption}
            validationState={validationState}
            hideImage={hideSection?.mainImage}
            onMainSectionImageOptionChange={onMainSectionImageOptionChange}
            onLogoChange={onLogoChange}
            onLogoTypeChange={onLogoTypeChange}
            onMainSectionImageChange={onMainSectionImageChange}
            onHeadlineChange={onHeadlineChange}
            onContentChange={onContentChange}
          />
        </TabPanel>}
        {/* Additional Section tab */}
        {!hideSection?.keyMilestones && 
        <TabPanel
          isShown={tabValue === TabMap.keyMilestones}
          index={TabMap.keyMilestones}>
          <KeyMilestones
            milestones={story.milestones}
            onAddMilestonesEntry={onAddMilestonesEntry}
            onDeleteMilestonesEntry={onDeleteMilestonesEntry}
            onDupMilestonesEntry={onDupMilestonesEntry}
            onMilestonesTitleChange={onMilestonesTitleChange}
            onMilestonesDetailsChange={onMilestonesDetailsChange}
          />
        </TabPanel>}
        {/* Additional Section tab */}
        {!hideSection?.additionalSection && 
        <TabPanel
          isShown={tabValue === TabMap.additionalSection}
          index={TabMap.additionalSection}>
          <AdditionalSection
            story={story}
            validationState={validationState}
            onAddAdditionalEntry={onAddAdditionalEntry}
            onDeleteAdditionalEntry={onDeleteAdditionalEntry}
            onDupAdditionalEntry={onDupAdditionalEntry}
            onAdditionalTitleChange={onAdditionalTitleChange}
            onAdditionalContentChange={onAdditionalContentChange}
            onAdditionalMediaTypeChange={onAdditionalMediaTypeChange}
            onAdditionalUrlChange={onAdditionalUrlChange}
          />
        </TabPanel>}
      </Tabs>
    </Grid>
  </>
);

export default TellYourStory;
