import React, {useRef, useState, useEffect} from 'react';

import {Grid, Typography} from '@material-ui/core';
import Qrcode from 'qrcode.react';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import agliveLogo from '../../../img/agliveLogo.png';
import NewDefaultImage from '../../../img/NewDefaultImage.png';
import AngusVerified from '../../../img/IconRecordAnimals.svg';
import COLOR from '../../../styled/colors';
import CONSTANT from '../../../config/constant';
interface PosterPreviewProps {
  display: TokenService.PromotionToken['details']['display'];
  story: TokenService.ProductToken['details']['story'];
  footerImageOption: string;
  angusVerified?: boolean;
  scale?: number;
  scanSampleHeading?: boolean;
  agliveToken?: string;
  qrOverlay?: boolean;
  border?: boolean;
  customQRWidth?: number;
  brandLogo: string | null;
}

const PosterPreview = React.forwardRef<undefined, PosterPreviewProps>(
  (
    {
      display,
      story,
      footerImageOption,
      angusVerified,
      scale = 1,
      scanSampleHeading,
      agliveToken,
      qrOverlay: overlay = false,
      border,
      customQRWidth,
      brandLogo,
    },
    ref,
  ) => {
    const classes = useStyles();
    const posterHeight = 792;

    const footerImageRef = useRef(null);
    const [qrCodeWidth, setQrCodeWidth] = useState(220);

    useEffect(() => {
      if (footerImageOption === '') {
        setQrCodeWidth(220);
      }
    }, [footerImageOption]);

    const getImageSize = () => {
      const imageHeight = footerImageRef?.current?.offsetHeight;
      if (imageHeight) {
        const newHeight = 270 - imageHeight + 160 + (angusVerified ? -25 : 20);
        setQrCodeWidth(newHeight < 220 ? newHeight : 220);
      } else {
        setQrCodeWidth(220);
      }
    };

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          transform: `scale(${scale})`,
          marginTop: (-posterHeight + posterHeight * scale) / 2,
          marginBottom: (-posterHeight + posterHeight * scale) / 2,
        }}
        ref={ref}
        id="PosterPreviewContainer">
        <style type="text/css">
          {'@media print{@page {size: A4 landscape}}'}
        </style>
        {scanSampleHeading && (
          <div className={classes.scanSample}>
            <Typography
              variant="h3"
              align="center"
              className={classes.scanSampleText}>
              Scan to see sample
            </Typography>
          </div>
        )}
        <div
          className={classes.previewContainer}
          style={{
            height: posterHeight,
            border: border ? `1px solid ${COLOR.GRAY_BORDER}` : '',
          }}>
          <div className={classes.mainContainer}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column">
              <Grid item className={classes.protectedBy}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}>
                  <Grid item>
                    <Typography
                      variant="body1"
                      display="inline"
                      style={{fontWeight: 600, fontSize: '25px'}}>
                      Protected by
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img src={agliveLogo} alt="Aglive Logo" width="110px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          {angusVerified && (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              className={classes.angusVerifiedContainer}>
              <Grid item>
                <img src={AngusVerified} alt="Angus Verified" width="35px" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  display="inline"
                  className={classes.angusVerifiedText}>
                  Angus Verified
                </Typography>
              </Grid>
            </Grid>
          )}

          <div className={classes.secondaryContainer}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column">
              {(story?.logoType === 'user' ||
                (story?.logoType === 'brand' && brandLogo)) && (
                <Grid item>
                  <div className={classes.imageContainer}>
                    <img
                      src={story?.logoType === 'user'
                      ? story?.logo || NewDefaultImage
                      : brandLogo}
                      alt="Promotion Logo"
                      className={classes.logo}
                    />
                  {!(story?.logo || (story?.logoType === 'brand' && brandLogo)) && (
                      <Typography className={classes.defaultImageText}>
                        Your Logo
                      </Typography>
                    )}
                  </div>
                </Grid>
              )}
              <Grid item className={classes.discoverMsgContainer}>
                <Typography
                  variant="body2"
                  align="center"
                  style={{fontWeight: 600}}>
                  Discover our quality story by scanning the code below
                </Typography>
              </Grid>

              <Grid
                item
                className={classes.qrContainer}
                style={{width: customQRWidth || qrCodeWidth}}>
                <Qrcode
                  value={
                    agliveToken
                      ? `${CONSTANT.SCAN_URL(agliveToken)}`
                      : 'Invalid Code'
                  }
                  size={customQRWidth - 16 || qrCodeWidth - 16}
                />
                {overlay && (
                  <div className={classes.qrOverlay}>
                    <div className={classes.qrOverlayInner}>
                      <Typography variant="h6">Save to preview</Typography>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>

          <div className={classes.footerContainer} ref={footerImageRef}>
            {footerImageOption === 'image' && (
              <>
                <img
                  src={display.footerImage || NewDefaultImage}
                  alt="Footer Img"
                  className={classes.footerImage}
                  onLoad={() => {
                    getImageSize();
                  }}
                />
                {!display.footerImage && (
                  <Typography className={classes.defaultImageText}>
                    Footer Image
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
    );
  },
);

export default PosterPreview;
