import { PageHeader } from '../../presentation/withHeader';
import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { assetStyles } from '../asset/Components';
import COLOR from '../../styled/colors';
import { Paragraph } from '../../presentation/word';
import { useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from "@material-ui/core/TableCell";
import {fetchGroupName} from '../../store/angus/actions';
import { useAppSelector } from "../../utils/hooks";

export const useStyles = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
    maxWidth: window.innerWidth / 3,
  },
}));

const AngusVerifiedDetails: React.FC<{}> = () => {
  const classes = { ...assetStyles(), ...useStyles() };
  const state: any = useLocation().state;
  const businessProfile = useAppSelector(state=>state.user.businessProfileData)
  const { angusAuthToken,acctID } = businessProfile.industryType == "ANIMALS"? businessProfile.angusProfile: {angusAuthToken:undefined,acctID:undefined}
  // const {angusAuthToken,acctID}=useAppSelector(state=>state.user.businessProfileData.angusProfile)
  const { title, details } = state;

  let rows = [
    { name: 'PIC Number', value: 'NLIS ID' },
    { name: details.pic, value: details.nlis_id },
    { name: 'RFID Number', value: title === 'Sire Profile' ? 'Sire Added' : 'Visual Tag' },
    {
      name: details.rfid, value: title === 'Sire Profile' ? details.verified_date ?
        new Date(details.verified_date).getDate() + '-' +
        (new Date(details.verified_date).getMonth() + 1).toString().padStart(2, "0") + '-' +
        new Date(details.verified_date).getFullYear()
        :
        '' : details.visual_tag
    },
  ];
  if (title === 'Animal Profile') {
    rows.push({ name: 'Birth Year', value: 'Angus Verified Date' },
      {
        name: details.birth_year, value: details.verified_date ?
          new Date(details.verified_date).getDate() + '-' +
          (new Date(details.verified_date).getMonth() + 1).toString().padStart(2, "0") + '-' +
          new Date(details.verified_date).getFullYear()
          :
          ''
      },
    );
  }
  const [groupName, setGroupName]=useState('');
  useEffect(()=>{
    if(details.sire_group_id){
      fetchGroupName(details.sire_group_id,angusAuthToken,acctID)
        .then((response:any)=>{
          if(response.result[0]?.group_name){
            setGroupName(response.result[0]?.group_name)
          }
        })
        .catch((err)=>{
          console.error(err)
        })
    }
  })


  const getRowStyle = (index) => {
    return (index % 2 === 0 ?
      { fontWeight: 700, borderBottom: "none" } :
      { fontWeight: 400 });
  }

  return (
    <PageHeader config={{ title: title, margin: 60, back: true, id:'AngusVerifiedDetails' }}>
      <Grid>
        <Typography variant="h3">
          Angus Verified
        </Typography>
        {title === 'Sire Profile' && <Grid container className={classes.greyBox}>
          <Paragraph fontWeight={700}>{`Angus Sire ID: ${details.sire_id}`}</Paragraph>
        </Grid>}
        <Grid container>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell align="left" style={getRowStyle(index)}>{row.name}</TableCell>
                    <TableCell align="left" style={getRowStyle(index)}>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {title === 'Animal Profile' && <Grid style={{ marginTop: 80 }}>
          <Typography variant="h3">
            Sire
          </Typography>
          <Paragraph fontWeight='700'>{details.sire_id ? 'Angus Sire ID' : 'Sire Group'}</Paragraph>
          <Paragraph>{details.sire_id ? details.sire_id : groupName?groupName:details.sire_group_id}</Paragraph>
        </Grid>}
      </Grid>
    </PageHeader>
  );
};

export default AngusVerifiedDetails;