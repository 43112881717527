import React from 'react';
import PowerBy from '../PowerBy';
import Qrcode from 'qrcode.react';
import './PrintTemplate.css';

export type PrintContent = {
  qrcode: string;
  descriptions: Array<{
    label: string;
    value: string;
  }>;
};

type PrintTemplateProps = {
  contents: Array<PrintContent>;
  tokenSize: number;
};

export const PrintTemplate = React.forwardRef<
  HTMLDivElement,
  PrintTemplateProps
>(({contents, tokenSize}, ref) => {
  if (contents.length > 0) {
    return (
      <div className="print-container" ref={ref}>
        {contents.map((content) => (
          <div className="print-page" key={content.qrcode}>
            <div className="page-break" />
            <div className="print-content">
              <div className="print-qrcode">
                <Qrcode
                  value={content.qrcode}
                  style={{
                    width: `${tokenSize}in`,
                    height: `${tokenSize}in`,
                  }}
                />
              </div>
              <div className="print-watermark">
                <PowerBy />
              </div>
              <div className="print-footer">
                <PrintTemplateFooter descriptions={content.descriptions} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
});

const PrintTemplateFooter: React.FC<{
  descriptions: PrintContent['descriptions'];
}> = ({descriptions}) => {
  return (
    <div className="descriptions-container">
      {descriptions?.map((desc, i) => {
        return (
          <div key={i} className="description">
            <div className="text title">{desc.label}</div>
            <div className="text">{desc.value}</div>
          </div>
        );
      })}
    </div>
  );
};
