import {Typography} from '@material-ui/core';
import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichEditor.css';
import COLOR from 'src/styled/colors';

type RichEditorProps = {
  id?: string;
  title: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  characterLimit?: number;
};

const editorModules = {
  toolbar: [['bold', 'italic', 'underline', 'link']],
  clipboard: {
    matchVisual: false
  }
};

const regexTags = /(<([^>]+)>)/gi;
function removeHTMLTags(html: string) {
  return html.replace(regexTags, '');
}

const CONTAINER_STYLE: React.CSSProperties = {
  paddingTop: 20,
  paddingBottom: 20,
};
const HEADER_STYLE: React.CSSProperties = {
  fontWeight: 700,
  paddingBottom: 10,
};

const FOOTER_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};

const FOOTER_CHARACTER_LIMIT_STYLE: React.CSSProperties = {
  marginLeft: 'auto',
};

const FOOTER_ERROR_STYLE: React.CSSProperties = {
  color: COLOR.RED,
};

const RichEditor: React.FC<RichEditorProps> = ({
  id,
  title,
  value,
  onChange,
  placeholder,
  characterLimit,
}) => {
  const strippedContent = characterLimit ? removeHTMLTags(value) : '';
  const [error, setErrorMessage] = useState<{message: string} | undefined>(
    undefined,
  );
  const handleChange = (newValue: string) => {
    const newContent = removeHTMLTags(newValue);
    if (newContent.length === 0) {
      setErrorMessage(undefined);
      onChange('');
      return;
    }
    if (characterLimit && newContent.length > characterLimit) {
      // so that this component rerenders
      setErrorMessage({message: 'Exceeds character limit'});
    } else {
      setErrorMessage(undefined);
      onChange(newValue);
    }
  };

  return (
    <div style={CONTAINER_STYLE} aria-errormessage={error?.message}>
      <Typography variant="h6" style={HEADER_STYLE}>
        {title}
      </Typography>
      <ReactQuill
        id={id}
        className={'rich-editor'}
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        modules={editorModules}
      />
      <div style={FOOTER_STYLE}>
        {error && (
          <Typography style={FOOTER_ERROR_STYLE} variant="body2">
            {error.message}
          </Typography>
        )}
        {characterLimit && (
          <Typography variant="body2" style={FOOTER_CHARACTER_LIMIT_STYLE}>
            {`${strippedContent.length}/${characterLimit} characters`}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default RichEditor;
