import React from 'react';
import { Typography, Grid, Radio, Box, TextField, Button, makeStyles } from '@material-ui/core';
import { TokenService } from '@aglive/data-model';
import {useAppDispatch, useAppSelector} from "../../../utils/hooks";

import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import TabHeading from '../TabHeading';

import {fileUploader} from '../../../store/product/actions';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../../../store/spinner/types';

import useStyles from './styles';

import COLOR from '../../../styled/colors';
import { toggleModal } from '../../../store/modal/actions';
import { RecursivePartial } from '../../../utils/type';

type displayType = {
  mediaOption: TokenService.ProductToken["details"]["pages"][number]["display"]["item"];
  mediaUrl: TokenService.ProductToken["details"]["pages"][number]["display"]["url"];
  validationState: RecursivePartial<TokenService.ProductToken["details"]["pages"][number]>;
  headline1: string;
  headline2: string;
  headline3: string;
  onChange: (keys: Array<number | string>, value: any) => void;
  onPageMediaSelect: (key: TokenService.ProductToken["details"]["pages"][number]["display"]["item"], value: string) => void;
};

const CreateProductTabDisplay: React.FC<displayType> = ({ 
  mediaOption,
  mediaUrl,
  validationState,
  headline1,
  headline2,
  headline3,
  onChange,
  onPageMediaSelect,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userid = useAppSelector(state => state.auth.wallet);
  const uploadImageHandler = async e => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > 10485760) {
          window.alert("Upload image size more than 10mb!");
        } else {
          const response = await fileUploader(userid,e.target.files[0]);
          const responseData = response[0].data;
          if (typeof responseData === 'string'){
            dispatch({ type: SPINNER_TOGGLE_OFF });
            onPageMediaSelect('image', responseData);
          }   
        }
      }
    } catch (e) {
      dispatch({ type: SPINNER_TOGGLE_OFF });
      
      dispatch(
        toggleModal({
          status: 'failed',
          title: e.title,
          subtitle: e.message,
          button: 'Close',
        }),
      )
    }
  };
  const displayImageUpload = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    displayImageUpload.current?.click();
  };

  return (
    <Grid item container direction="column">
      <TabHeading
        heading="Image or Video*"
        subheading="Enhance your story with an image or video"
      />
      <Box
        p={2}
        style={{
          backgroundColor: COLOR.GRAY_SOLID,
          height: 250,
          marginBottom: 5
        }}>
        <Grid item container direction="column" style={{marginBottom: 20}}>
          <Grid item container alignItems="center">
            <Radio
              color="primary"
              checked={mediaOption === 'image'}
              onChange={() => onPageMediaSelect('image', '')}
              value="image"
              name="product-display"
              inputProps={{ 'aria-label': 'image' }}
              id="DisplayImage-Radio"
            />
            <Typography variant="h6">Image</Typography>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item sm={8} style={{paddingLeft: 50}}>
              <TextField
                fullWidth
                variant="outlined"
                value={mediaOption === 'image' ? decodeURIComponent(mediaUrl?.split('/').reverse()[0]) : ''}
                placeholder="png, jpg or jpeg"
                disabled
                error={
                  !!validationState?.display?.url &&
                  validationState?.display?.item === 'image'
                }
                helperText={
                  validationState?.display?.item === 'image' &&
                  validationState?.display?.url
                }
                FormHelperTextProps={{
                  className: classes.errorHelperText,
                }}
                id="DisplayImage-TextField"
              />
            </Grid>
            <Grid item sm={3}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleClick}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'white',
                  height: 58.3
                }}
                disabled={mediaOption !== 'image'}
                >
                <Typography variant="h4" style={{ color: COLOR.GREEN_BUTTON}} noWrap>Upload File</Typography>
              </Button>
              <input type="file" data-cy="DisplayImage" accept="image/jpg, image/jpeg, image/png" onChange={uploadImageHandler} ref={displayImageUpload} style={{height: 0, width: 0}} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column">
          <Grid item container alignItems="center">
            <Radio
              color="primary"
              checked={mediaOption === 'video'}
              onChange={() => onPageMediaSelect('video', '')}
              value="video"
              name="product-display"
              inputProps={{ 'aria-label': 'video' }}
              id="DisplayVideo-Radio"
            />
            <Typography variant="h6">Video Link</Typography>
          </Grid>
          <Grid item container style={{paddingLeft: 50}}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={'Enter URL'}
              value={mediaOption === 'video' ? mediaUrl : ''}
              style={{
                marginBottom: 10,
                backgroundColor: 'white',
              }}
              onChange={e => onPageMediaSelect('video', e.target.value)}
              disabled={mediaOption !== 'video'}
              error={
                !!validationState?.display?.url &&
                validationState?.display?.item === 'video'
              }
              helperText={
                validationState?.display?.item === 'video' &&
                validationState?.display?.url
              }
              FormHelperTextProps={{
                className: classes.errorHelperText,
              }}
              id="DisplayVideo-TextField"
            />
          </Grid>
        </Grid>
      </Box>

      <TabHeading
        heading="Headline"
        subheading="Capture your audience's attention"
        containerStyle={{margin: '20px 0'}}
      />

      <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
        <Grid item container direction="column">
          <TextFieldWithLimit
            title="Line 1"
            content={headline1}
            limit={30}
            onChange={(value: string) => onChange(['headline', 0], value)}
            placeholder="Enter text"
            containerStyle={classes.textFieldContainer}
            style={classes.textField}
            id="Headline1"
          />

          <TextFieldWithLimit
            title="Line 2"
            content={headline2}
            limit={30}
            onChange={(value: string) => onChange(['headline', 1], value)}
            placeholder="Enter text"
            containerStyle={classes.textFieldContainer}
            style={classes.textField}
            id="Headline2"
          />

          <TextFieldWithLimit
            title="Line 3"
            content={headline3}
            limit={30}
            onChange={(value: string) => onChange(['headline', 2], value)}
            placeholder="Enter text"
            style={classes.textField}
            id="Headline3"
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default CreateProductTabDisplay;
