import { uniqueIdSchema } from "../config/schema";
import { validateJson } from "./jsonValidator";
import { ValidationError, WebErrorType } from "./error";
import { schemaType } from "./type";

export function removeEmptyAndLastUnderscore(data: Array<object>) {
  const removedData = [];
  Object.assign(removedData, data);
  for (const element of removedData) {
    const elementKeys = Object.keys(element);
    for (const key of elementKeys) {
      if (!element[key]) delete element[key];
      else {
        let keyNameWithoutUnder = "";
        // remove the last underscore
        if (key.substring(key.length - 1, key.length) === "_") {
          keyNameWithoutUnder = key.substring(0, key.length - 1);
          element[keyNameWithoutUnder] = element[key];
          delete element[key];
        }
        //lowercase all strings to match schema
        let newKey = keyNameWithoutUnder || key;
        if (typeof element[newKey] === 'string') {
          element[newKey] = element[newKey].toLowerCase();
        }
      }
    }
  }
  return removedData;
}

export function formUniqueIdArray(data: Array<object>, uniqueIdTypes: Array<string>) {
  const uniqueIdArrayObject = {};
  uniqueIdTypes.map((type) => {
    return (uniqueIdArrayObject[type] = []);
  });

  for (const element of data) {
    const elementKeys = Object.keys(element);
    for (const key of elementKeys) {
      if (uniqueIdTypes.includes(key)) {
        uniqueIdArrayObject[key].push(element[key]);
      }
    }
  }
  return uniqueIdArrayObject;
}

// https://www.npmjs.com/package/jsonschema
export type JSONSchemaValidationError = {
  path: Array<any>;
  property: string;
  message: string;
  schema: {[key:string]:any};
  instance: any;
  name: string;
  argument: any;
  stack:string;
}

function transformSchemaRegex(schema: schemaType) {
  for (let i in schema.items.properties) {
    if (schema.items.properties[i].pattern && typeof schema.items.properties[i].pattern === 'string') {
      schema.items.properties[i].pattern = new RegExp(schema.items.properties[i].pattern)
    }
  }
  return schema;
}

export async function validateWithSchema(
  data: Array<object>,
  schemaRaw: schemaType,
  validateDup: boolean,
  uniqueIdTypes: Array<string>
): Promise<undefined | WebErrorType<Array<JSONSchemaValidationError>>> {
  // 0. determine whether the CSV header matches with the selected schema
  // TODO: temp solution, only match the header length
  // const schemaHeaderLength = Object.keys(schema.items.properties).length;
  // const dataHeaderLength = Object.keys(data[0]).length;
  // if (schemaHeaderLength !== dataHeaderLength)
  //   throw new ValidationError("", "HEADER_NOT_MATCH", []);

  // 1. remove empty values and the last underscore
  const removedData = removeEmptyAndLastUnderscore(data);

  // to prevent call stack size exceeded, chunk the data before validation
  const chunkSize = 500;
  const chunked = removedData.reduceRight((result, item, idx, arr) => (result.push(arr.splice(0, chunkSize)), result), []);

  let schema = transformSchemaRegex({...schemaRaw});
  // 2. simple data validation
  let validationErrors: ValidationError[] = [];
  let chkIdx = 0;
  while (chunked[chkIdx]) {
    try {
      let chunkedValidationErrors = validateJson(chunked[chkIdx], schema).errors;

      let adjustedValidationErrors = chunkedValidationErrors.map(error => {
        let rowNo = error.path[0] + (chkIdx * chunkSize);
        error.path = [rowNo];
        error.property = `instance[${rowNo}]`;

        return error;
      });
      validationErrors = validationErrors.concat(adjustedValidationErrors);
      chkIdx++;
    } catch (e) {
      break;
    }
  }

  if (validationErrors.length)
    throw new ValidationError( // WebErrorType<Array<JSONSchemaValidationError>>
      "",
      "SIMPLE_VALIDATION",
      validationErrors
    );

  // 3. business level validation
  // 3.1 validate if any duplicate id
  if (validateDup && validationErrors.length <= 30) {
    const uniqueValidation = validateJson(
      formUniqueIdArray(removedData, uniqueIdTypes),
      uniqueIdSchema
    );
    if (uniqueValidation.errors.length)
      throw new ValidationError( // WebErrorType<Array<JSONSchemaValidationError>>
        "",
        "UNIQUE_VALIDATION",
        uniqueValidation.errors
      );
  }

  // 3.2 [deprecated tag/dev-v1.0.1] validate if there is at least one unique id
  return;
};
