import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
  useReducer,
} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import MapComponent, {
  Coords,
  MapRefObj,
  Weight,
} from '../../presentation/MapComponent';
import {MarkerType, HistoryType} from '../../presentation/MapMarkers';
import MyButton from '../../presentation/button';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import COLOR from '../../styled/colors';
import {Line} from '../../presentation/Line';
import {getUserCeresTags, createGeofence} from '../../store/profile/actions';
import CowSafe from '../../img/CowSafe.png';
import CowRed from '../../img/CowRed.png';
import CowSafeMale from '../../img/CowSafeMale.png';
import CowSafeFemale from '../../img/CowSafeFemale.png';
import CowRedMale from '../../img/CowRedMale.png';
import CowRedFemale from '../../img/CowRedFemale.png';
import Cereslogo from '../../img/Cereslogo.png';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import AlertSettingModal from './AlertSettingModal';
import Button from '@material-ui/core/Button';
import CONSTANT from '../../config/constant';
import {WebErrorType} from '../../utils/error';
import {TokenService} from '@aglive/data-model';
import moment from 'moment';
import {businessProfileTypes} from '../../store/profile/types';
import {useHistory} from 'react-router';
import {getBusinessProfile} from '../../store/profile/actions';
import useWindowDimensions from '../../utils/useWindowDimensions';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    maxWidth: '100%',
  },
  drawButton: {
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  saveButton: {
    width: 220,
  },
  warningBox: {
    backgroundColor: COLOR.ORANGE,
    padding: 10,
    color: COLOR.WHITE,
  },
  settingBoxContainer: {
    position: 'relative',
  },
  settingBox: {
    backgroundColor: COLOR.GRAY_BUTTON,
    padding: 30,
    color: COLOR.BLACK,
    position: 'absolute',
    zIndex: 2,
    width: 350,
    right: 0,
    top: 0,
    overflowY: 'auto',
  },
}));

type MarkerArray = {[pic: string]: Array<MarkerType>};
type WeightArray = {[pic: string]: Array<Weight>};

type TrackAnimalState = {
  markers: MarkerArray;
  weights: WeightArray;
  alerts: MarkerArray;
  showAnimals: boolean;
  showPolygon: boolean;
  editPolygon: boolean;
  newPolygon: boolean;
  polygonPoints: Coords[][];
  clearPolygon: boolean;
  chosenPIC: number;
  chosenDateInterval: number;
  showType: string;
  zoom: number;
  center: Coords;
  bounds: undefined | {ne: Coords; sw: Coords};
  modalOpen: boolean;
  ifAddLocation: boolean;
  scaleLocation?: Coords;
  lastReportTime?: string;
};

const defaultCenter: Coords = {lat: 40.8666667, lng: 34.56666666666667}; // geographical centre of earth
const initialState = (business: businessProfileTypes): TrackAnimalState => ({
  markers: {},
  weights: {},
  alerts: {},
  showAnimals: false,
  showPolygon: false,
  polygonPoints: business?.location?.length
    ? business.location[0]?.geofence
      ? [business.location[0]?.geofence]
      : []
    : [],
  clearPolygon: false, //in the state of clearing polygon
  editPolygon: false, //in the state of editing the polygon
  newPolygon: false, //in the state of new polygon
  chosenPIC: 0, //dropdown index
  chosenDateInterval: 1, //number of days
  showType: 'Data', //Data / Alert
  zoom: 2,
  center: defaultCenter,
  bounds: undefined,
  modalOpen: false,
  ifAddLocation: false,
  scaleLocation: undefined,
  lastReportTime: undefined,
});

type Action =
  | {type: 'toggle/showPolygon'}
  | {type: 'toggle/editPolygon'}
  | {type: 'toggle/showAnimals'}
  | {type: 'toggle/showModal'; modalOpen: boolean}
  | {type: 'change/dateInterval'; chosenDateInterval: number}
  | {type: 'change/showType'; showType: string}
  | {type: 'change/mapCenter'; zoom: number; center: Coords}
  | {type: 'change/bounds'; zoom: number; bounds: TrackAnimalState['bounds']}
  | {type: 'change/scaleLocation'; scaleLocation: Coords}
  | {
      type: 'edit/geofence';
      polygonPoints: Coords[][];
      showPolygon?: boolean;
      clearPolygon?: boolean;
      chosenPIC?: number;
      ifAddLocation?: boolean;
      newPolygon?: boolean;
    }
  | {type: 'save/geofence'}
  | {
      type: 'load/mapData';
      markers: MarkerArray;
      weights: WeightArray;
      alerts: MarkerArray;
    }
  | {type: 'set/lastReportTime'; lastReportTime: string};

const reducer = (
  prevState: TrackAnimalState,
  action: Action,
): TrackAnimalState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    case 'load/mapData':
    case 'edit/geofence':
    case 'change/dateInterval':
    case 'change/showType':
    case 'change/mapCenter':
    case 'change/bounds':
    case 'change/scaleLocation':
    case 'toggle/showModal':
    case 'set/lastReportTime':
      return {...prevState, ...actionData};
    case 'toggle/showPolygon':
      return {...prevState, showPolygon: !prevState.showPolygon};
    case 'toggle/editPolygon':
      return {...prevState, editPolygon: !prevState.editPolygon};
    case 'toggle/showAnimals':
      return {...prevState, showAnimals: !prevState.showAnimals};
    case 'save/geofence':
      return {...prevState, editPolygon: false, clearPolygon: false};
  }
};

const TrackAnimal: React.FC<{}> = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;
  const [state, localDispatch] = useReducer(
    reducer,
    initialState(businessProfile),
  );

  const geoFenceExists = useCallback(
    (index: number): boolean => {
      const geofence = businessProfile.location[index]?.geofence;
      return (geofence?.length as number) > 0;
    },
    [businessProfile],
  );

  let initialMarkers: {[pic: string]: Array<MarkerType>} = {};
  let initialWeights: {[pic: string]: Array<Weight>} = {};

  const initializeFields = useCallback(():
    | typeof initialMarkers
    | typeof initialWeights => {
    let initField: typeof initialMarkers | typeof initialWeights = {};
    businessProfile.location.forEach((loc) => {
      initField[loc?.PICAddress as string] = [];
    });
    return initField;
  }, [businessProfile.location]);
  /**Add 'add location' in dropdpwn */
  const picLocations: Array<string> =
    businessProfile.location?.map(
      (location) => location.PICAddress as string,
    ) ?? [];

  if (picLocations.length === 0) picLocations.push('No Available Location');
  picLocations.push('Add Location');
  const EDIT_BUSINESS_PROFILE = '/private/profile/businessProfile/edit';

  /**if Optiweigh user*/
  const useOptiweigh =
    businessProfile.industryType === 'ANIMALS' &&
    !!businessProfile.optiweigh?.apiKey;
  // Hard coded geolocation of scale
  /*const scaleLocation: Coords = React.useMemo(() => {
    return {lat: -31.90867, lng: 151.977128};
  }, []);*/

  const mapRef = useRef<MapRefObj>(null);
  const tagAlertStandard = businessProfile?.location?.find(
    (loc) => loc.PICAddress === picLocations[state.chosenPIC],
  )?.tagAlertStandard;

  const dateRange = Array.from(Array(7).keys()).map((val) => {
    return val === 0 ? 'Last 24 Hours' : `Last ${val + 1} Days`;
  });
  const polygonCallback = (polygonPath: Array<{lat: number; lng: number}>) => {
    localDispatch({
      type: 'edit/geofence',
      showPolygon: true,
      polygonPoints: [polygonPath],
      newPolygon: true,
    });
  };

  const changePIC = (
    e: React.ChangeEvent<{name?: string; value: unknown}>,
    child: ReactNode,
  ): void => {
    if (geoFenceExists(Number(e.target.value))) {
      localDispatch({
        type: 'edit/geofence',
        polygonPoints: [
          businessProfile.location[Number(e.target.value)].geofence,
        ],
        chosenPIC: Number(e.target.value),
        ifAddLocation: picLocations[Number(e.target.value)] === 'Add Location',
      });
    } else {
      localDispatch({
        type: 'edit/geofence',
        polygonPoints: [],
        chosenPIC: Number(e.target.value),
        ifAddLocation: picLocations[Number(e.target.value)] === 'Add Location',
      });
    }
  };

  const changeLocationBounds = useCallback(() => {
    if (
      state.polygonPoints.length > 0 &&
      Array.isArray(state.polygonPoints[0]) &&
      state.polygonPoints[0].length > 0
    ) {
      const latitudes = state.polygonPoints[0].map((pt) => pt.lat);
      const longitudes = state.polygonPoints[0].map((pt) => pt.lng);

      return {
        ne: {lat: Math.min(...latitudes), lng: Math.max(...longitudes)},
        sw: {lat: Math.max(...latitudes), lng: Math.min(...longitudes)},
      };
    } else {
      return undefined;
    }
  }, [state.polygonPoints]);

  const changeDate = useCallback((e) => {
    localDispatch({
      type: 'change/dateInterval',
      chosenDateInterval: Number(e.target.value),
    });
  }, []);

  const changeShowType = useCallback((e) => {
    localDispatch({type: 'change/showType', showType: e.target.value});
  }, []);
  /**save and read map center, zoom from cookie */
  const setCookie = (c_name, value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value =
      value + (exdays == null ? '' : '; expires=' + exdate.toUTCString());
    document.cookie = c_name + '=' + c_value;
  };
  const handleZoomChanged = (mapZoom) => {
    var cookiestring = mapZoom;
    if (mapZoom) {
      setCookie('MapZoom', cookiestring, 30);
    }
  };
  const handleCenterChanged = (mapCenter) => {
    let mapCenterString = JSON.stringify(mapCenter);
    if (mapCenter.lat && mapCenter.lng) {
      setCookie('MapCenter', mapCenterString, 30);
    }
  };
  const getCookie = (c_name) => {
    var i,
      x,
      y,
      ARRcookies = document.cookie.split(';');
    for (i = 0; i < ARRcookies.length; i++) {
      var subArray = ARRcookies[i].split('=');
      x = subArray[0];
      y = subArray[1];
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === c_name) {
        return y;
      }
    }
    return '';
  };
  const loadLastCenter = useCallback(() => {
    if (
      getCookie('MapZoom')?.length > 0 &&
      Object.keys(JSON.parse(getCookie('MapCenter'))).length > 0
    ) {
      let lastZoom = parseInt(getCookie('MapZoom'));
      let lastCenter = JSON.parse(getCookie('MapCenter'));
      localDispatch({
        type: 'change/mapCenter',
        center: lastCenter,
        zoom: lastZoom,
      });
    } else {
      localDispatch({
        type: 'change/mapCenter',
        center: defaultCenter,
        zoom: 2,
      });
    }
  }, []);

  /**
   * Format to meters distance
   * @param int accuracy
   */
  const formatAccuracy = useCallback((accuracy) => {
    const accuracyToMetres = new Map([
      [0, 2],
      [1, 5],
      [2, 10],
      [3, 25],
      [4, 50],
      [5, 100],
      [6, 300],
    ]);

    return accuracyToMetres.get(accuracy) ?? 0;
  }, []);

  const calculateWeightGain = useCallback(
    (details: TokenService.AssetToken['details']): number => {
      if (
        !details.date_of_weight ||
        !details.weight_at_birth ||
        !details.date_of_birth
      ) {
        return 0;
      }
      const daysPassed =
        Date.parse(details.date_of_weight) - Date.parse(details.date_of_birth);
      return Number(
        (daysPassed > 0
          ? (details.weight ?? 0 - details.weight_at_birth ?? 0) /
            (daysPassed / (1000 * 60 * 60 * 24))
          : 0
        ).toFixed(2),
      );
    },
    [],
  );

  const convertDate = useCallback(
    (details: TokenService.AssetToken['details']): string => {
      if (!details.date_of_weight) {
        return 'No Date';
      }
      let formattedDate: string | undefined;
      if (details.date_of_weight.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        formattedDate = details.date_of_weight.split('/').reverse().join('-');
      } else if (moment(details.date_of_weight).isValid()) {
        formattedDate = details.date_of_weight;
      }
      return formattedDate ?? 'Invalid Date Format';
    },
    [],
  );

  const centerCoordinate = (min: number, max: number) => {
    return (max - min) / 2 + min;
  };
  const cowIcon = (error: boolean, sex: string) => {
    if (error) {
      if (sex.toLowerCase() === 'female') return CowRedFemale;
      else if (sex.toLowerCase() === 'male') return CowRedMale;
      else return CowRed;
    } else {
      if (sex.toLowerCase() === 'female') return CowSafeFemale;
      else if (sex.toLowerCase() === 'male') return CowSafeMale;
      else return CowSafe;
    }
  };
  const formatMarkerData = useCallback(
    (
      data: {
        rfid: string;
        esn: string;
        sex: string;
        weight?: number;
        unit?: string;
        weightDate?: string;
        avgDailyGain?: number;
        assetHistory?: HistoryType[];
      },
      activity: TokenService.ConditionActivity,
    ): MarkerType => {
      return {
        lat: Number(activity.details.latitude),
        lng: Number(activity.details.longitude),
        info: {
          esn: data.esn,
          rfid: data.rfid,
          date: String(activity.details.observationDateUTC),
          activityText: String(activity.details.activityLevel),
          icon: activity.errorMsg?.length
            ? cowIcon(true, data.sex)
            : cowIcon(false, data.sex),
          warning: {
            color: COLOR.RED_WARNING,
          },
          circleRadius: formatAccuracy(
            Number(activity.details.locationAccuracy),
          ),
          temperature: String(activity.details.temperature),
          errors: activity.errorMsg ?? [],
          history: data.assetHistory,
          weight: data.weight,
          unit: data.unit,
          weightDate: data.weightDate,
          avgDailyGain: data.avgDailyGain ?? undefined,
        },
      };
    },
    [formatAccuracy],
  );

  const getTagData = useCallback(() => {
    dispatch(getUserCeresTags(useOptiweigh, state.chosenDateInterval))
      .then((resp: TokenService.AssetToken[]) => {
        let assetList: {
          [pic: string]: MarkerType[];
        } = initializeFields() as typeof initialMarkers;
        let errorList: {
          [pic: string]: MarkerType[];
        } = initializeFields() as typeof initialMarkers;
        let weightList: {
          [pic: string]: Weight[];
        } = initializeFields() as typeof initialWeights;
        let scaleLoc: undefined | Coords;
        let lastReportTime: string = '';
        resp.forEach((asset) => {
          const assetHistory: HistoryType[] = [];
          let assetErrors: MarkerType[] = [];
          let assetDetails!: MarkerType;
          let rfid = '',
            esn = '';
          asset.externalIds.forEach((exids) => {
            if (exids.ceres_tag_esn) {
              esn = exids.ceres_tag_esn;
            } else if (exids.ceres_tag_vid) {
              rfid = exids.ceres_tag_vid;
            }
          });
          const activityResp = asset.activities as unknown as
            | TokenService.ConditionActivity[]
            | TokenService.WeightActivity[];
          activityResp.forEach((hist) => {
            if (
              hist.type === 'addMovement' &&
              Number(hist.details.locationAccuracy) < 7
            ) {
              // get lastReportTime from the latest addMovement activity of assets
              if (!lastReportTime) {
                lastReportTime = hist.lastReportTime;
              }

              if (hist.lastReportTime > lastReportTime) {
                lastReportTime = hist.lastReportTime;
              }

              //exclude inaccurate locations
              assetHistory.push({
                lat: Number(hist.details.latitude),
                lng: Number(hist.details.longitude),
                date: String(hist.details.observationDateUTC),
              });
              if (hist.errorMsg?.length) {
                assetErrors.push(
                  formatMarkerData(
                    {rfid: rfid, esn: esn, sex: asset.details.sex ?? ''},
                    hist,
                  ),
                );
              }
              assetDetails = formatMarkerData(
                {
                  rfid: rfid,
                  esn: esn,
                  weight: asset.details.weight || asset.details.weight_at_birth,
                  unit: asset.details.unit,
                  weightDate: convertDate(asset.details),
                  avgDailyGain: calculateWeightGain(asset.details),
                  assetHistory: assetHistory,
                  sex: asset.details.sex ?? '',
                },
                hist,
              );
            }
          });
          if (asset.details.pic_id && assetList[asset.details.pic_id]) {
            if (assetHistory.length) {
              assetList[asset.details.pic_id].push(assetDetails);
            } else if (asset.details.unit) {
              rfid = asset.details.externalIds?.rfid as string; //no ceres tag id, should use back original rfid
              if (asset.details.location_lat) {
                scaleLoc = {
                  lat: asset.details.location_lat,
                  lng: asset.details.location_long,
                };
              }
              weightList[asset.details.pic_id].push({
                info: {
                  rfid: rfid,
                  weight:
                    asset.details.weight || asset.details.weight_at_birth || 0,
                  unit: asset.details.unit,
                  weightDate: convertDate(asset.details),
                  avgDailyGain: calculateWeightGain(asset.details),
                },
              });
            }
          }
          if (assetErrors.length > 0 && asset.details.pic_id) {
            errorList[asset.details.pic_id] =
              errorList[asset.details.pic_id].concat(assetErrors);
          }
        });
        //sort alerts by latest first
        for (let i in errorList) {
          errorList[i].sort((a: MarkerType, b: MarkerType) => {
            return a.info.date < b.info.date ? 1 : -1;
          });
        }

        // sort weight list by latest weightDate
        for (let i in weightList) {
          weightList[i].sort((a: Weight, b: Weight) => {
            const aValid = moment(a.info.weightDate, 'YYYY-MM-DD').isValid();
            const bValid = moment(b.info.weightDate, 'YYYY-MM-DD').isValid();

            if (!aValid && !bValid) return 0;

            if (!aValid && bValid) return 1;

            if (aValid && !bValid) return -1;

            return a.info.weightDate < b.info.weightDate ? 1 : -1;
          });
        }
        localDispatch({
          type: 'load/mapData',
          markers: assetList,
          alerts: errorList,
          weights: weightList,
        });
        if (scaleLoc) {
          localDispatch({
            type: 'change/scaleLocation',
            scaleLocation: scaleLoc,
          });
        }
        if (lastReportTime) {
          localDispatch({
            type: 'set/lastReportTime',
            lastReportTime: lastReportTime,
          });
        }
      })
      .catch((e: WebErrorType) => {
        if (e?.error?.status !== '401') {
          alert(
            `There is currently a problem contacting the server. Please contact Aglive support on ${CONSTANT.BUSINESS_SUPPORT_CHANNEL}.`,
          );
        }
      });
  }, [
    dispatch,
    state.chosenDateInterval,
    useOptiweigh,
    initializeFields,
    formatMarkerData,
    calculateWeightGain,
    convertDate,
  ]);

  // //const [bounds, setBounds] = useState(changeLocationBounds());
  // // get geolocation and setCenter
  // const getGeolocation = useCallback(() => {
  //   if (!navigator.geolocation) {
  //     alert('Sorry, geolocation not available!');
  //     localDispatch({type: 'change/mapCenter', center: defaultCenter, zoom: 2});
  //     return;
  //   }
  //   navigator.permissions.query({name: 'geolocation'}).then(function (result) {
  //     if (['granted', 'prompt'].includes(result.state)) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           localDispatch({
  //             type: 'change/mapCenter',
  //             center: {
  //               lat: position.coords.latitude,
  //               lng: position.coords.longitude,
  //             },
  //             zoom: 10,
  //           });
  //         },
  //         (error) => alert(error.message),
  //         {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
  //       );
  //     } else {
  //       localDispatch({
  //         type: 'change/mapCenter',
  //         center: defaultCenter,
  //         zoom: 2,
  //       });
  //     }
  //     result.onchange = function () {
  //       console.log(result.state);
  //     };
  //   });
  // }, []);

  const {height, width} = useWindowDimensions();

  useEffect(() => {
    if (state.ifAddLocation) {
      history.push({
        pathname: EDIT_BUSINESS_PROFILE,
        state: {historyPrePage: 'Map'},
      });
    }
  }, [state.ifAddLocation]);
  useEffect(() => {
    getTagData();
  }, [
    state.chosenDateInterval,
    getTagData,
    tagAlertStandard,
    businessProfile.location,
  ]);

  useEffect(() => {
    if (state.polygonPoints.length) {
      // set the bounds if it exists
      localDispatch({
        type: 'change/bounds',
        bounds: changeLocationBounds(),
        zoom: 10,
      });
    }
  }, [state.chosenPIC]); //do not add changeLocationBounds as dependency, otherwise editing geofence will trigger this

  useEffect(() => {
    if (!state.polygonPoints.length) {
      // getGeolocation();
      if (getCookie('MapZoom') && getCookie('MapCenter')) {
        loadLastCenter();
      } else {
        localDispatch({
          type: 'change/mapCenter',
          center: defaultCenter,
          zoom: 2,
        });
      }
    }
  }, [loadLastCenter, state.chosenPIC]);
  useEffect(() => {
    if (state.ifAddLocation) {
      history.push({
        pathname: EDIT_BUSINESS_PROFILE,
        state: {historyPrePage: 'Map'},
      });
    }
  }, [state.ifAddLocation]);

  useEffect(() => {
    dispatch(getBusinessProfile());
  }, []);

  // toggle modal if window size too small
  useEffect(() => {
    if (height < 500 || width < 1200) {
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'Screen size too small',
          subtitle: 'Please change to a larger screen to use the map',
        }),
      );
    } else {
      dispatch(toggleModalOff());
    }
  }, [dispatch, height, width]);

  return (
    <>
      <Box mb={5}>
        <Grid container spacing={3}>
          <Grid item container xs={4} direction="column">
            <Typography variant="h6" style={{fontWeight: 700, marginBottom: 5}}>
              {isAustralia ? 'PIC No.' : 'PID No.'}
            </Typography>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                {'Please select'}
              </InputLabel>
              <Select
                id={'PICSelect'}
                label={'Please Select'}
                value={state.chosenPIC}
                onChange={changePIC}>
                {picLocations?.map((PICAddress, index) => (
                  <MenuItem value={index} key={index} id={PICAddress}>
                    {PICAddress}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item container xs={4} direction="column">
            <Typography variant="h6" style={{fontWeight: 700, marginBottom: 5}}>
              Range
            </Typography>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                {'Please select'}
              </InputLabel>
              <Select
                id={'RangeSelect'}
                label={'Please Select'}
                value={state.chosenDateInterval}
                onChange={changeDate}>
                {dateRange.map((val, index) => (
                  <MenuItem value={index + 1} key={index + 1} data-cy={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item container xs={4} direction="column">
            <Typography variant="h6" style={{fontWeight: 700, marginBottom: 5}}>
              Show
            </Typography>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                {'Please select'}
              </InputLabel>
              <Select
                id={'ShowSelect'}
                label={'Please Select'}
                value={state.showType}
                onChange={changeShowType}>
                {['Data', 'Alerts'].map((val, index) => (
                  <MenuItem value={val} key={index} id={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h3" style={{marginBottom: 15}}>
        Display Map (
        {businessProfile?.location?.length
          ? state.showType === 'Alerts'
            ? `${
                state.alerts[
                  businessProfile?.location[state.chosenPIC]?.PICAddress
                ]?.length ?? 0
              } Alerts`
            : `${
                state.markers[
                  businessProfile?.location[state.chosenPIC]?.PICAddress
                ]?.length ?? 0
              } Active Tags`
          : '0 Active Tags'}
        )
      </Typography>
      <Line />
      <Box mb={2} mt={5}>
        <Grid container justify="space-between">
          <Grid
            item
            container
            justify="space-between"
            xs={5}
            style={{marginBottom: 10}}>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    id={'AnimalsToggle'}
                    checked={!state.showAnimals}
                    onChange={() => {
                      localDispatch({type: 'toggle/showAnimals'});
                    }}
                    name="showAnimals"
                    color="primary"
                  />
                }
                label="Display Animals"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    id={'GeofenceToggle'}
                    checked={state.showPolygon}
                    disabled={state.polygonPoints.length === 0}
                    onChange={() => {
                      localDispatch({type: 'toggle/showPolygon'});
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Display Geofence"
              />
            </Grid>
          </Grid>
          <Grid item container xs={6} justify="flex-end" spacing={2}>
            {state.polygonPoints.length === 0 ||
            !Array.isArray(state.polygonPoints[0]) ? (
              <Grid container item xs={6} justify="flex-end">
                <MyButton
                  text={'Draw Geofence'}
                  variant="contained"
                  buttonClass={classes.drawButton}
                  disabled={
                    state.polygonPoints.length > 0 &&
                    Array.isArray(state.polygonPoints[0]) &&
                    state.polygonPoints[0].length > 0
                  }
                  onClick={() => {
                    localDispatch({
                      type: 'edit/geofence',
                      polygonPoints: [],
                      showPolygon: false,
                      clearPolygon: true,
                    });
                    mapRef.current.drawingButton();
                  }}
                />
              </Grid>
            ) : (
              state.showPolygon && (
                <>
                  <Grid item xs={4}>
                    <MyButton
                      text={'Edit'}
                      variant="outlined"
                      buttonClass={classes.button}
                      disabled={
                        !(
                          state.polygonPoints.length > 0 &&
                          state.polygonPoints[0].length > 0
                        )
                      }
                      onClick={() => {
                        localDispatch({type: 'toggle/editPolygon'});
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyButton
                      text={'Clear All'}
                      variant="outlined"
                      buttonClass={classes.button}
                      disabled={
                        !(
                          state.polygonPoints.length > 0 &&
                          state.polygonPoints[0].length > 0
                        )
                      }
                      onClick={() => {
                        localDispatch({
                          type: 'edit/geofence',
                          polygonPoints: [],
                          showPolygon: false,
                          clearPolygon: true,
                        });
                      }}
                    />
                  </Grid>
                </>
              )
            )}
          </Grid>
        </Grid>
        <Grid item container justify="flex-end" xs={12} style={{marginTop: 20}}>
          <Button
            onClick={() => {
              localDispatch({
                type: 'toggle/showModal',
                modalOpen: !state.modalOpen,
              });
            }}>
            <span
              style={{
                color: COLOR.GREENT_TEXT,
                fontSize: 18,
                textTransform: 'capitalize',
              }}
              id="AlertSettings">
              <u>{'Alert Settings'}</u>
            </span>
          </Button>
        </Grid>
      </Box>

      {/* <Box className={classes.warningBox}>
        Current results are test data
      </Box> */}

      <Box className={classes.settingBoxContainer}>
        <MapComponent
          ref={mapRef}
          markers={
            businessProfile?.location?.length
              ? !state.showAnimals && state.showType === 'Data'
                ? state.markers[
                    businessProfile.location[state.chosenPIC]?.PICAddress
                  ]
                : state.showType === 'Alerts'
                ? state.alerts[
                    businessProfile.location[state.chosenPIC]?.PICAddress
                  ]
                : undefined
              : undefined
          }
          weights={
            businessProfile?.location?.length
              ? state.weights[
                  businessProfile.location[state.chosenPIC]?.PICAddress
                ]
              : []
          }
          scaleLocation={state.scaleLocation ? state.scaleLocation : undefined}
          sidebar={state.showType === 'Alerts'}
          locationGeofences={state.polygonPoints as Coords[][]}
          showPolygon={state.showPolygon}
          polygonEdit={state.editPolygon}
          onPolygonCompleteCallback={polygonCallback}
          polygonBounds={state.bounds}
          center={
            !state.polygonPoints.length && !state.clearPolygon
              ? state.center
              : undefined
          }
          zoom={state.zoom}
          handleZoomChanged={handleZoomChanged}
          handleCenterChanged={handleCenterChanged}
          loadLastCenter={loadLastCenter}
        />

        {state.modalOpen && (
          <Box className={classes.settingBox}>
            <AlertSettingModal
              pic={picLocations[state.chosenPIC]}
              open={state.modalOpen}
              handleClose={() => {
                localDispatch({type: 'toggle/showModal', modalOpen: false});
              }}
              rightButtonClick={() => {
                localDispatch({type: 'toggle/showModal', modalOpen: false});
              }}
              isSettingCard
            />
          </Box>
        )}
      </Box>
      <Grid container justify="space-between" style={{marginTop: 20}}>
        <Grid item>
          <Typography variant="subtitle2">
            {`Ceres Reported: ${
              state.lastReportTime
                ? moment(state.lastReportTime).format('D MMM, YYYY HH:mm')
                : 'No data'
            }`}
          </Typography>
        </Grid>
        <Grid item>
          <img src={Cereslogo} alt="Ceres Tag" width="150" />
        </Grid>
        {((state.newPolygon && state.showPolygon) || state.clearPolygon) && (
          <Grid container item justify="flex-end">
            <MyButton
              text={'Save'}
              variant="contained"
              buttonClass={classes.saveButton}
              disabled={
                !(
                  (state.polygonPoints.length > 0 && state.newPolygon) ||
                  state.clearPolygon
                )
              }
              onClick={() => {
                dispatch(
                  createGeofence(
                    state.polygonPoints.length > 0 &&
                      Array.isArray(state.polygonPoints[0])
                      ? state.polygonPoints[0]
                      : [],
                    state.chosenPIC,
                  ),
                );
                localDispatch({type: 'save/geofence'});
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withHeader(
  {
    title: 'Map',
    back: true,
  },
  TrackAnimal,
);
