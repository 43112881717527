import {
  Grid,
  Typography,
  GridSize,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import React, { ForwardRefRenderFunction, PropsWithChildren, forwardRef, useState, useImperativeHandle, useRef, useEffect, useCallback } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import CONSTANT from '../config/constant';
import { MultipleSelect } from './MultipleSelect';
import 'react-phone-number-input/style.css'

type InputValue = string | string[] | Date;

type FormInput = {
  type: string;
  title?: string;
  titleKey?: string;
  titleSuffix?: string;
  colSize?: number;
  name: string;
  id?: string;
  minRows?: number;
  disabled?: boolean;
  viewMode?: boolean;
  skip?: boolean;
  placeholder?: string;
  placeholderKey?: string;
  required?: boolean;
  validationRegex?: RegExp;
  helperText?: string;
  helperTextKey?: string;
  errorMsg?: string;
  errorMsgKey?: string;
  onChange?: (val: InputValue) => void;
  customValidation?: (val: InputValue) => {result: boolean; error?: string};
  minDate?: Date;
  maxDate?: Date;
  disablePast?: boolean;
  value?: InputValue;
  items?: ({label: string; value: string; disabled?: boolean} | string)[];
  dependsOn?: string;
  dependentItems?: {[key: string]: ({label: string; value: string} | string)[]};
  dependentErrorMsg?: {[key: string]: string};
  dependentValidationRegex?: {[key: string]: RegExp};
  dependentPlaceholder?: {[key: string]: string};
  dependentHelperText?: {[key: string]: string};
  dependentTitle?: {[key: string]: string};
  dependentTitleKey?: {[key: string]: string};
  dependentRequired?: boolean;
  customComponent?: any;
  rightButton?: React.ReactNode;
};

type Props = {
  formInputs: FormInput[],
  language?: 'en' | 'es',
  itemStyle?: React.CSSProperties,
  onReady?: (readyState: boolean) => void,
  readyState?: boolean
};

interface ValidateArgs {
  forceTouch?: boolean;
}
interface InputFunctions {
  getValue: () => InputValue;
  isValid: (opts?: ValidateArgs) => boolean;
}

export interface FormInputFunctions<T = {}> {
  getValues: () => T;
  isValid: (opts?: ValidateArgs) => boolean;
}

const GenerateField: ForwardRefRenderFunction<
  InputFunctions,
  PropsWithChildren<{field: FormInput, language: {[key: string]: string}, setReady: (readyState: boolean, newVal: InputValue) => void, dependentValue?: string}>
> = (props, ref): JSX.Element => {
  let errorMsg = props.field.errorMsg ?? props.language[props.field.errorMsgKey];
  let placeholder = props.field.placeholder ?? props.language[props.field.placeholderKey];
  let helperText = props.field.helperText ?? props.language[props.field.helperTextKey];
  let required = props.field.required;
  if (props.field.dependsOn && props.dependentValue) {
    if (props.field.dependentErrorMsg) errorMsg = props.field.dependentErrorMsg[props.dependentValue] ?? props.field.dependentErrorMsg.default;
    if (props.field.dependentPlaceholder) placeholder = props.field.dependentPlaceholder[props.dependentValue ?? props.field.dependentPlaceholder.default];
    if (props.field.dependentHelperText) helperText = props.field.dependentHelperText[props.dependentValue] ?? props.field.dependentHelperText.default;
  }
  if (props.field.dependentRequired && props.field.dependsOn) required = !!props.dependentValue;
  const [currentState, setCurrentState] = useState({value: props.field.value, errorMsg: '', touched: false, error: false});
  const onChangeFn = (field: FormInput, value: InputValue, forceValidate?: boolean, forceTouch?: boolean) => {
    if (!forceValidate) {
      field.onChange && field.onChange(value);
    }
    let hasError = required && ((typeof value === 'string' && !value.trim()) || (typeof value !== 'string' && !value));
    let errorMsgDisplay = hasError ? props.language.requiredField : '';
    const isTouched =
      !!forceTouch ||
      currentState.touched ||
      !['PHONE', 'INPUT', 'EMAIL', 'MASK', 'DATE'].includes(field.type);
    if (field.type === 'EMAIL' && !hasError && typeof value === 'string') {
      hasError = !CONSTANT.EMAIL_REGEX.test(value);
      errorMsgDisplay = props.language.inputValidEmail;
    }
    const validationRegex = field.dependsOn && field.dependentValidationRegex ? field.dependentValidationRegex[props.dependentValue] : field.validationRegex;
    if (validationRegex && !hasError && typeof value === 'string' && value.length) {
      hasError = !validationRegex.test(value);
      errorMsgDisplay = errorMsg;
    }
    if (field.customValidation && !hasError && value) {
      const {result, error} = field.customValidation(value);
      hasError = result;
      errorMsgDisplay = result ? error : '';
    }
    if (field.type === 'PHONE' && !hasError) {
      hasError = !isPossiblePhoneNumber(value);
      errorMsgDisplay = hasError ? props.language.validNumber : '';
    }
    props.setReady(!hasError, value);
    setCurrentState({value: value, error: hasError, errorMsg: errorMsgDisplay, touched: isTouched});
    return {value: value, error: hasError, errorMsg: errorMsgDisplay, touched: isTouched}
  }
  const onBlur = (e: React.FocusEvent) => {
    setCurrentState((prevState) => {
      return {...prevState, touched: true}
    });
  }
  useImperativeHandle(ref, () => ({
    getValue: () => {
      return currentState.value;
    },
    isValid: (opts?: ValidateArgs) => {
      // console.log('field', props.field.name, required, currentState);
      if (!currentState.touched) {
        return !onChangeFn(props.field, currentState.value, true, opts.forceTouch).error;
      }
      return !currentState.error;
    },
  }));
  useEffect(() => {
    if (props.field.dependsOn && props.dependentValue !== undefined) {
      if (props.field.type === 'SELECT') {
        const match = props.field.dependentItems[props.dependentValue]?.filter((i) => (typeof i === 'string' ? i : i.value) === currentState.value);
        if (!match?.length) {
          onChangeFn(props.field, '');
          // setCurrentState({value: '', error: false, errorMsg: '', touched: false});
          // props.setReady(false, '');
        }
      } else {
        onChangeFn(props.field, currentState.value);
      }
    }
  }, [props.dependentValue]);
  useEffect(() => {
    setCurrentState((prevState) => {
      return {...prevState, value: props.field.value};
    });
  }, [props.field.value]);
  if (props.field.viewMode) {
    return <Typography id={props.field.id ?? props.field.name} variant="h6" style={{marginTop: 8}}>{props.field.value}</Typography>
  }
  switch(props.field.type) {
    case 'INPUT':
    case 'EMAIL':
    case 'PASSWORD':
      return <TextField
        variant="outlined"
        value={currentState.value}
        onChange={(e: React.ChangeEvent<{name?: string; value: string}>) => {
          onChangeFn(props.field, e.target.value);
        }}
        id={props.field.id ?? props.field.name}
        type={props.field.type === 'PASSWORD' ? 'password' : 'text'}
        minRows={props.field.minRows}
        multiline={props.field.minRows > 0}
        disabled={props.field.disabled}
        placeholder={placeholder}
        error={currentState.touched ? currentState.error : undefined}
        onBlur={onBlur}
        helperText={currentState.error && currentState.touched ? currentState.errorMsg : helperText}
      />
    case 'MASK':
      return <InputMask
        mask={placeholder}
        maskChar={null}
        id={props.field.id ?? props.field.name}
        value={currentState.value}
        disabled={props.field.disabled}
        onChange={(e: React.ChangeEvent<{name?: string; value: string}>) => {
          onChangeFn(props.field, e.target.value)
        }}
        onBlur={onBlur}
        placeholder={props.field.placeholder}>
          {(props) => (
            <TextField
              {...props}
              fullWidth
              variant="outlined"
              helperText={currentState.error && currentState.touched ? currentState.errorMsg : helperText}
              error={currentState.touched ? currentState.error : undefined}
            />
          )}
      </InputMask>
    case 'PHONE':
      return <>
        <PhoneInput
          international
          defaultCountry="AU"
          id={props.field.id ?? props.field.name}
          placeholder={placeholder}
          countryCallingCodeEditable={false}
          inputComponent={MobileInputForm}
          value={currentState.value}
          error={currentState.touched ? currentState.error : undefined}
          onBlur={onBlur}
          countries={CONSTANT.PHONE_COUNTRY_CODES}
          onChange={(val) => {
            onChangeFn(props.field, val);
          }}
        />
        {((currentState.error && currentState.touched) || helperText) && (
          <FormHelperText style={{color: 'red', margin: '3px 54px 0'}}>
            {currentState.error && currentState.touched ? currentState.errorMsg : helperText}
          </FormHelperText>
        )}
      </>
    case 'SELECT':
    case 'MULTISELECT':
      const itemList = (props.field.dependentItems && props.dependentValue 
        ? props.field.dependentItems[props.dependentValue]?.map((it) => (typeof it === 'string' ? {label: it, value: it} : it))
        : props.field.items?.map((it) => (typeof it === 'string' ? {label: it, value: it} : it))) ?? [];
      return <FormControl
        variant="outlined"
        disabled={props.field.disabled}
        error={currentState.error}>
        {!currentState.value && <InputLabel>{props.field.placeholder ?? props.language.pleaseSelect}</InputLabel>}
        {props.field.type === 'MULTISELECT' ? 
          <MultipleSelect
            dataSet={itemList}
            id={props.field.id ?? props.field.name}
            value={currentState.value as string[]}
            selectedData={currentState.value as string[]}
            setSelectedData={(selectedData) => {
              onChangeFn(props.field, selectedData);
            }}
            variant="outlined"
            // placeholder={props.field.placeholder ?? props.language.pleaseSelect}
          />
        :<Select
          value={currentState.value}
          disabled={props.field.disabled}
          id={props.field.id ?? props.field.name}
          // label={props.field.placeholder ?? props.language.pleaseSelect}
          onChange={(e: React.ChangeEvent<{name?: string; value: string}>) => {
            onChangeFn(props.field, e.target.value);
          }}>
          {itemList.map((itm) => (
            <MenuItem value={itm.value} key={itm.value} disabled={itm['disabled']}>
              {itm.label}
            </MenuItem>
          ))}
        </Select>}
        {(currentState.error || helperText) && (
          <FormHelperText>
            {currentState.error ? currentState.errorMsg : helperText}
          </FormHelperText>
        )}
      </FormControl>
    case 'DATE':
      return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar={true}
            variant={'inline'}
            format={'dd/MM/yyyy'}
            margin={'normal'}
            id={props.field.id ?? props.field.name}
            disabled={props.field.disabled}
            disablePast={props.field.disablePast}
            emptyLabel={'dd/mm/yyyy'}
            onChange={(date, val) => {
              onChangeFn(props.field, date);
            }}
            value={currentState.value}
            onBlur={onBlur}
            KeyboardButtonProps={{'aria-label': 'change date'}}
            keyboardIcon={<DateRangeIcon style={{fill: '#373935'}} />}
            minDate={props.field.minDate}
            maxDate={props.field.maxDate}
            minDateMessage={props.field.minDate ? `Cannot be before ${moment(props.field.minDate).format('dd/mm/YYYY')}` : undefined}
            maxDateMessage={props.field.maxDate ? `Cannot be after ${moment(props.field.maxDate).format('dd/mm/YYYY')}` : undefined}
          />
        </MuiPickersUtilsProvider>
        {((currentState.error && currentState.touched) || helperText) && (
          <FormHelperText style={{color: 'red', margin: '3px 14px 0'}}>
            {currentState.error && currentState.touched ? currentState.errorMsg : helperText}
          </FormHelperText>
        )}
      </>
    default:
      return props.field.customComponent
  }
};

const Field = forwardRef(GenerateField);

const FormFields: ForwardRefRenderFunction<
  FormInputFunctions,
  PropsWithChildren<Props>
> = (props, ref) => {
  const language = CONSTANT[`${props.language ? props.language.toUpperCase() : 'EN'}_LABELS`];
  const inputRef = useRef([]);
  const [formReady, setFormReady] = useState(() => {
    return props.formInputs.map((rs, i) => {
      return !rs.required || rs.skip || !!rs.value;
    })
  });
  const [dependentValue, setDependentValue] = useState(() => {
    let values: {[key: string]: string} = {};
    props.formInputs.forEach((fi) => {
      if (fi.dependsOn) {
        values[fi.dependsOn] = props.formInputs.find((f) => f.name === fi.dependsOn).value as string;
      }
    });
    return values;
  });

  const changeReadyState = useCallback((idx: number) => (ready: boolean, newVal: InputValue) => {
    if ((ready || (props.formInputs[idx].dependentRequired && formReady[idx] !== ready)) && props.formInputs[idx].name in dependentValue) {
      setDependentValue((prev) => {
        return {...prev, [props.formInputs[idx].name]: newVal as string}
      });
    }
    if (formReady[idx] === ready) {
      return;
    }
    setFormReady((prevState) => {
      let tmp = [...prevState];
      tmp[idx] = ready;
      return tmp;
    });
  }, [formReady, props.formInputs, dependentValue]);

  useEffect(() => {
    inputRef.current = inputRef.current.slice(0, props.formInputs.length);
  }, [props.formInputs.length]);

  useEffect(() => {
    props.onReady && props.onReady(formReady.filter(fr => fr).length === props.formInputs.length);
  }, [formReady, props]);

  useImperativeHandle(ref, () => ({
    getValues: () => {
      let formFields: {[key: string]: InputValue} = {};
      props.formInputs.forEach((fi, i) => {
        if (!fi.skip && fi.type !== 'DIVIDER') {
          formFields[fi.name] = inputRef.current[i].getValue();
        }
      });
      return formFields;
    },
    isValid: (opts?: ValidateArgs) => {
      const validFields = props.formInputs.map((fi, i) => {
        return (
          fi.skip ||
          fi.type === 'DIVIDER' ||
          inputRef.current[i].isValid(opts)
        );
      });
      return validFields.filter((a) => !a).length === 0;
    },
  }));
  return (
    <>
      {props.formInputs.map((fi, i) => (fi.skip ? <React.Fragment key={`Form-${fi.name}-${i}`} />
        : fi.type === 'DIVIDER' ? <Grid key={`FormDivider-${i}`} xs={12} />
        : <Grid
          key={`Form-${fi.name}-${i}`}
          item
          container
          xs={fi.colSize as GridSize ?? 6}
          direction="column"
          style={{marginBottom: 20, ...props.itemStyle}}>
          {(fi.title || fi.titleKey || fi.dependentTitleKey || fi.dependentTitle) && 
            <Typography variant="h6" style={{fontWeight: 600}}>
              {fi.dependsOn && fi.dependentTitle 
                ? fi.dependentTitle[dependentValue[fi.dependsOn]] ?? fi.dependentTitle.default
                : fi.dependsOn && fi.dependentTitleKey 
                  ? language[fi.dependentTitleKey[dependentValue[fi.dependsOn]]] ?? language[fi.dependentTitleKey.default]
                  : fi.title ?? language[fi.titleKey]}
              {fi.titleSuffix ? ' ' + fi.titleSuffix : ''}
            </Typography>
          }
          <Box display={'flex'} flexDirection={'row'} >
            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Field
                ref={el => inputRef.current[i] = el} 
                dependentValue={fi.dependsOn ? dependentValue[fi.dependsOn] : undefined} 
                field={fi} 
                setReady={changeReadyState(i)} 
                language={language} 
              />
            </Box>
            {fi.rightButton}
          </Box>
        </Grid>)
      )}
    </>
  );
};

export const FormGenerator = forwardRef(FormFields);

/** mobile phone material ui input component */
const PhoneInputForm = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      variant="outlined"
    />
  )
};
export const MobileInputForm = forwardRef(PhoneInputForm);

/** mobile phone material ui input component */
const PhoneInputCustom = (props, ref) => {
  const phoneValidation = (value) => {
    if (!isPossiblePhoneNumber(value)) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
  };
  const [validPhone, setValidPhone] = useState(false);
  const error = props.error ?? validPhone;
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      variant="outlined"
      onBlur={(e) => phoneValidation(e.target.value)}
      error={error}
      helperText={error ? props.helperText : ''}
    />
  );
};
export const MobileInput = forwardRef(PhoneInputCustom);
