import React, {useEffect, useReducer, useCallback, useMemo} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import {
  weightSchema,
  pregnancySchema,
  deceasedSchema,
  heightSchema,
  treatmentSchema,
  traitSchema,
  uniqueIds,
  digirodeoAddUHF,
} from '../../config/schema';
import {LayerNode} from '../../store/site/types';
import {fetchSiteTokens, fetchCattleTreatment} from '../../store/site/actions';
import CONSTANT from '../../config/constant';
import {validateWithSchema} from '../../utils/csvReader';
import {cleanSelectedFile} from './Components';
import {manageAssetByCSV} from '../../store/asset/actions';
import {Buttons} from '../../presentation/ButtonsGroup';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';

type DropDownType = {
  title: string;
  key?: string;
  dbKey?: string;
  value: string;
  onChange: (e: React.ChangeEvent<{name?: string; value: unknown}>) => void;
  list: {label: string, value: string}[];
  ifUpperCase: boolean;
  hasSearch?: boolean; //if dropdown searchable
  required?: true,
};
interface ManageAssetDataType {
  label: string;
  index: number;
  schema: any;
  value: string;
  updateType: string;
  uniqueIds?: string[];
  dropDown?: Array<DropDownType>;
}

const INITIAL_MANAGE_DATA = {
  index: '' as string | number,
  file: null as any,
  csvData: [] as any[],
  validationResult: {
    type: null,
    validationError: [],
  },
  open: false,
  treatmentDropdown: {
    medication_name: '',
    dose_unit: '',
  },
  warakirriPregnancyDropdown: {
    conception_status: '',
    conception_method: '',
  },
  warakirriMoveDropdown: {
    site_moved_to: '',
    site_moved_to_region: '',
    site_moved_to_property_type: '',
    pic_id: '',
    pic_name: '',
  },
  warakirriStatusDropdown: {
    status: '',
  },
  medicationNameList: [],
};

type Action =
  | {type: 'change/index'; index: number}
  | {type: 'change/csv'; csvData: any[]}
  | {
      type: 'change/validationResult';
      validationResult: typeof INITIAL_MANAGE_DATA.validationResult;
    }
  | {type: 'change/open'; open: boolean}
  | {type: 'upload/file'; file: any}
  | {
      type: 'click/closeAssetDialog';
      file: any;
      csvData: any[];
      validationResult: typeof INITIAL_MANAGE_DATA.validationResult;
      open: boolean;
    }
  | {
      type: 'click/rightButtonClickIsErr';
      file: any;
      validationResult: typeof INITIAL_MANAGE_DATA.validationResult;
      open: boolean;
    }
  | {
      type: 'select/warakirriPregnancyDropdown';
      warakirriPregnancyDropdown: typeof INITIAL_MANAGE_DATA.warakirriPregnancyDropdown;
    }
  | {
      type: 'select/warakirriMoveDropdown';
      warakirriMoveDropdown: typeof INITIAL_MANAGE_DATA.warakirriMoveDropdown;
    }
  | {
      type: 'select/warakirriStatusDropdown';
      warakirriStatusDropdown: typeof INITIAL_MANAGE_DATA.warakirriStatusDropdown;
    }
  | {
      type: 'select/treatmentDropdown';
      treatmentDropdown: typeof INITIAL_MANAGE_DATA.treatmentDropdown;
    }
  | {
      type: 'fetch/medicationName';
      medicationNameList: {label: string; value: string}[];
    }
  | {
      type: 'select/customDropdown';
      [key: string]: string;
    };
const reducer = (
  prevState: typeof INITIAL_MANAGE_DATA,
  action: Action,
): typeof INITIAL_MANAGE_DATA => {
  const {type, ...actionData} = action;
  switch (action.type) {
    case 'change/index':
    case 'change/csv':
    case 'change/validationResult':
    case 'change/open':
    case 'select/warakirriPregnancyDropdown':
    case 'select/warakirriMoveDropdown':
    case 'select/warakirriStatusDropdown':
    case 'select/treatmentDropdown':
    case 'click/closeAssetDialog':
    case 'click/rightButtonClickIsErr':
    case 'upload/file':
    case 'fetch/medicationName':
    case 'select/customDropdown':
      return {...prevState, ...actionData};
  }
};
export const ManageAssetByCSVObject = () => {
  const dispatch = useAppDispatch();
  const [state, localDispatch] = useReducer(reducer, {...INITIAL_MANAGE_DATA});
  const siteTokens = useAppSelector((state) => state.site)?.sort(
    (token, token2) => {
      return token.details.siteName > token2.details.siteName ? 1 : -1;
    },
  );
  const locationState = useAppSelector((state) => state.location);
  const hasDigiRodeo = useAppSelector((state) => state.user.plugins.filter((plg) => plg.status === "activated")).find(
    (plg) => plg.name === 'DigiRodeo',
  );
  const isWarakirri = !!useAppSelector((state) => state.user.plugins.filter((plg) => plg.status === "activated")).find((plg) => plg.name === "Dairy Reports");
  const {customSchema, template} = useAppSelector((state) => ({customSchema: state.user.schema?.management, template: state.user.schema?.template}));

  const templateList = template && Object.keys(template).length ? template : CONSTANT.TEMPLATE.MANAGEMENT;

  const updateNormalForm = (
    e: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    localDispatch({type: 'change/index', index: Number(e.target.value)});
  };

  /**flat all pic sites and save pic and site relation map*/
  const siteStates: Array<{
    layers: LayerNode;
    location: string;
    region?: string;
    propertyType?: string;
  }> = siteTokens.map((token) => {
    const {layers, location, region, propertyType} = token.details;
    return {
      layers,
      location,
      region,
      propertyType,
    };
  });
  const {siteDetailsMap, siteMovedToList} = useMemo(() => {
    let sdm = new Map<
      string,
      {location: string; region?: string; propertyType?: string}
    >();
    let smtl: Array<{label: string, value: string}> = [];
    if (siteStates.length > 0) {
      siteStates.forEach((siteState) => {
        smtl.push({label: siteState.layers.name, value: siteState.layers.name});
        sdm.set(siteState.layers.name, {...siteState});
      });
    }
    return {siteDetailsMap: sdm, siteMovedToList: smtl}
  }, [siteStates]);
  
  const updateForm = useCallback(
    (fieldName: string) => (
      e?:
        | React.ChangeEvent<{
            name?: string;
            value?: unknown;
          }>
        | any, //treatment Autocompelete return {lable:string, number: string}
    ) => {
      localDispatch({
        type: 'select/customDropdown',
        [fieldName]: e?.label ?? e?.target?.value ?? ''
      })
    }, [])

  const normalDropDownList: {[key: string]: Array<DropDownType>} = {
    Treatment: [
      {
        title: 'Medication Name',
        value: state['medication_name'],
        key: 'medication_name',
        onChange: updateForm('medication_name'),
        list: state.medicationNameList,
        ifUpperCase: false,
        hasSearch: true,
        required: true,
      },
      {
        title: 'Dose Unit',
        value: state['dose_unit'],
        key: 'dose_unit',
        onChange: updateForm('dose_unit'),
        list: [{"label": "ML", "value": "ML"},{"label": "G", "value": "G"},{"label": "Unit", "value": "Unit"}],
        ifUpperCase: false,
        required: true,
      },
    ]
  }

  let normalData: Array<ManageAssetDataType> = [
    {
      label: 'Manage - Weight - Template',
      index: 0,
      schema: weightSchema,
      value: CONSTANT.TEMPLATE.MANAGEMENT.WEIGHT.VALUE,
      updateType: 'updateWeight',
    },
    {
      label: 'Manage - Pregnancy - Template',
      index: 1,
      schema: pregnancySchema,
      value: CONSTANT.TEMPLATE.MANAGEMENT.PREGNANCY.VALUE,
      updateType: 'updatePregnancyStatus',
    },
    {
      label: 'Manage - Deceased - Template',
      index: 2,
      schema: deceasedSchema,
      value: CONSTANT.TEMPLATE.MANAGEMENT.DECEASED.VALUE,
      updateType: 'updateDeceaseStatus',
    },
    {
      label: 'Manage - Height - Template',
      index: 3,
      schema: heightSchema,
      value: CONSTANT.TEMPLATE.MANAGEMENT.HEIGHT.VALUE,
      updateType: 'updateHeight',
    },
    {
      label: 'Manage - Treatment - Template',
      index: 4,
      schema: treatmentSchema,
      value: CONSTANT.TEMPLATE.MANAGEMENT.TREATMENT.VALUE,
      updateType: 'addTreatment',
      dropDown: normalDropDownList.Treatment,
    },
    {
      label: 'Manage - Traits - Template',
      index: 5,
      schema: traitSchema,
      value: CONSTANT.TEMPLATE.MANAGEMENT.TRAITS.VALUE,
      updateType: 'addTraits',
    },
  ];
  if (hasDigiRodeo && hasDigiRodeo.status === 'activated') {
    normalData.push({
      label: 'Manage - DigiRodeo - Template',
      index: 6,
      schema: digirodeoAddUHF,
      value: CONSTANT.TEMPLATE.MANAGEMENT.ATTACHMENT.VALUE,
      updateType: 'add_tag',
    });
  }

  if (customSchema) {
    let tmpData = {};
    for (const [key, csVal] of Object.entries(customSchema)) {
      const val = JSON.parse(JSON.stringify(csVal));
      tmpData[key] = val.map((sc) => {
        if (sc.dropDown) {
          sc.dropDown = sc.dropDown.map((dd) => ({
            title: dd.title,
            key: dd.key,
            dbKey: dd.dbKey,
            value: state[dd.key],
            hasSearch: dd.hasSearch,
            onChange: updateForm(dd.key),
            list: dd.key === 'site' ? siteMovedToList : dd.key === 'medication_name' ? state.medicationNameList : dd.list,
            ifUpperCase: dd.ifUpperCase,
            required: dd.required
          }))
        }
        return sc;
      })
    }
    if (tmpData['replace']) {
      normalData = tmpData['replace']
    }
    if (tmpData['add']) {
      normalData = normalData.concat(tmpData['add']);
    }
  }

  const dataSelection = normalData[Number(state.index)];
  const csvRequestData = () => {
    const uniqueIdTypes = dataSelection.uniqueIds ?? uniqueIds.Australia;
    const csvObj = {
      template: dataSelection.value as any,
      activityType: dataSelection.updateType as any,
      externalIds: uniqueIdTypes.reduce((sum, current) => sum + ',' + current),
      uniqueExternalIds: uniqueIdTypes.reduce(
        (sum, current) => sum + ',' + current,
      ),
      tokenType: CONSTANT.ASSETTYPE.ASSET as any,
      file: state.file,
      csvData: state.csvData,
      schemaType: dataSelection.schema.id,
    };
    if (dataSelection.schema.apiType) {
      csvObj['type'] = dataSelection.schema.apiType;
    }
    if (dataSelection.dropDown) {
      dataSelection.dropDown.forEach((dd) => {
        if (state[dd.key]) {
          csvObj[dd.dbKey ?? `preset_${dd.key}`] = state[dd.key];
          if (dd.key === 'site' && isWarakirri) {
            csvObj['preset_site_moved_to_region'] = siteDetailsMap.get(state[dd.key])?.region ?? '';
            csvObj['preset_site_moved_to_property_type'] = siteDetailsMap.get(state[dd.key])?.propertyType ?? '';
            csvObj['preset_pic_id'] = siteDetailsMap.get(state[dd.key])?.location;
            csvObj['preset_pic_name'] = Object.values(locationState.location)?.find(
              (item) =>
                item?.PICAddress ===
                siteDetailsMap.get(state[dd.key])?.location,
            )?.locationNickname ?? ''
          }
        }
      })
    }
    return csvObj;
  };
  const validateWithSchemaFunction = () => {
    const uniqueIdTypes = dataSelection.uniqueIds ?? uniqueIds.Australia;
    validateWithSchema(
      state.csvData,
      dataSelection.schema,
      false,
      uniqueIdTypes,
    )
      .then(() => {
        //reset to empty in case has previous error
        localDispatch({
          type: 'change/validationResult',
          validationResult: {
            type: null,
            validationError: [],
          },
        });
      })
      .catch((e) => {
        localDispatch({
          type: 'change/validationResult',
          validationResult: {
            type: e.type,
            validationError: e.error || [],
          },
        });
      });
    localDispatch({
      type: 'change/open',
      open: true,
    });
  };
  const closeAssetDialog = () => {
    cleanSelectedFile('csvReader');
    localDispatch({
      type: 'click/closeAssetDialog',
      file: '',
      csvData: [],
      validationResult: {type: null, validationError: []},
      open: false,
    });
  };
  const assetDialogRightButton = () => {
    localDispatch({
      type: 'change/open',
      open: false,
    });
    dispatch(manageAssetByCSV(csvRequestData()))
      .then((data) => {
        cleanSelectedFile('csvReader');
        localDispatch({type: 'upload/file', file: ''});
      })
      .catch((error) => {
        cleanSelectedFile('csvReader');
        localDispatch({type: 'upload/file', file: ''});
        const errorList = [];
        if (Array.isArray(error.error) && error.error[0].source?.data?.length) {
          error.error[0].source.data.forEach(
            (err: {
              row: number;
              errors: Array<string>;
              [key: string]: any; // product_number
            }) => {
              errorList.push({
                row: err.row,
                message:
                  err?.errors?.[0] ||
                  err.error.join('. ') ||
                  error.error[0].details,
              });
            },
          );
        } else {
          errorList.push(error);
        }
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'An error has occurred!',
            errorInfo:
              typeof error.message === 'string'
                ? errorList
                : 'Please try again later.',
            renderButton: (
              <Buttons
                leftButtonTitle="Cancel"
                rightButtonTitle="Upload CSV Again"
                leftButtonOnClick={() => {
                  dispatch(toggleModalOff());
                  cleanSelectedFile('csvReader');
                  localDispatch({type: 'upload/file', file: ''});
                }}
                rightButtonOnClick={() => {
                  dispatch(toggleModalOff());
                  cleanSelectedFile('csvReader');
                  localDispatch({type: 'upload/file', file: ''});
                }}
              />
            ),
          }),
        );
      });
  };
  const assetDialogrightButtonClickIsErr = () => {
    cleanSelectedFile('csvReader');
    localDispatch({
      type: 'click/rightButtonClickIsErr',
      file: '',
      validationResult: {type: null, validationError: []},
      open: false,
    });
  };
  const setFile = (file: any) => {
    localDispatch({
      type: 'upload/file',
      file: file,
    });
  };
  const setCSVData = (csvData: any[]) => {
    localDispatch({
      type: 'change/csv',
      csvData: csvData,
    });
  };
  useEffect(() => {
    dispatch(fetchSiteTokens());
    dispatch(fetchCattleTreatment(isWarakirri)).then((response) => {
      localDispatch({
        type: 'fetch/medicationName',
        medicationNameList: response?.map((item) => {
          return {
            label: item.details.product_name,
            value: item.details.product_name
          };
        }),
      });
    });
  }, [dispatch, isWarakirri]);
  return {
    state,
    updateNormalForm,
    csvRequestData,
    templateList,
    normalData,
    isWarakirri,
    dataSelection,
    validateWithSchemaFunction,
    closeAssetDialog,
    assetDialogRightButton,
    assetDialogrightButtonClickIsErr,
    setFile,
    setCSVData,
  };
};
