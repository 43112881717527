import React, { useState } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';

import MyButton from '../../../../presentation/button';
import {fileUploader} from '../../../../store/product/actions';
import {useAppSelector} from "../../../../utils/hooks";
import defaultImage from '../../../../img/defaultImage.png';
import COLOR from '../../../../styled/colors';
import TextFieldWithLimit from '../../../../presentation/TextFieldWithLimit';
import { TokenService } from '@aglive/data-model';

type displayTypes = 
  TokenService.BrandToken["details"]["launchScreen"]["display"] & 
  {
    onMainLogoChange: (url: string) => void;
    onSecondaryLogoChange: (url: string) => void;
    onLogoTextChange: (text: string) => void;
  };

const useStyle = makeStyles((theme) => ({
  tabs:{
    backgroundColor:COLOR.WHITE,
  }
}));

const CreateLaunchTabDisplay: React.FC<displayTypes> = ({ 
  mainLogo,
  secondaryLogo,
  logoText,

  onMainLogoChange,
  onSecondaryLogoChange,
  onLogoTextChange,
}) => {
  const userid = useAppSelector(state => state.auth.wallet);
  const classes = useStyle();
  const [logoType, setLogoType] = useState<'mainLogo' | 'secondaryLogo'>('mainLogo');

  const uploadImageHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        window.alert("Upload image size more than 10mb ！");
      } else {
        const response = await fileUploader(userid,e.target.files[0]);
        const responseData=response[0].data;
        if (typeof responseData === 'string') {
          if (logoType === 'mainLogo') {
            onMainLogoChange(responseData);
          } else {
            onSecondaryLogoChange(responseData);
          }
        }   
      }
    }
  };

  const displayImageUpload = React.useRef<HTMLInputElement>(null);
  const handleClick = (logoType: 'mainLogo' | 'secondaryLogo') => {
    setLogoType(logoType);
    displayImageUpload.current?.click();
  }

  return (
    <Grid item container direction="column">
      <Grid item container direction="column" style={{backgroundColor:COLOR.WHITE}}>
        <Grid item container style={{marginBottom:131}}>
          <Grid item xs={6} container direction="column" alignItems="center">         
            <div style={{marginTop:48,marginBottom:8}}>
              <Typography variant="h6" role="label" style={{marginBottom:8, fontWeight: 600}}>
                Main Logo*
              </Typography>
              <img src={mainLogo || defaultImage} height="95" width="186" alt="Display preview" />
            </div>
            <MyButton
              text={'Upload'}
              variant="contained"
              width={186}
              onClick={() => handleClick('mainLogo')}
              id="UploadMainLogoButton"
            /> 

            <input data-cy="MainLogo" type="file" accept="image/jpg, image/jpeg, image/png" onChange={uploadImageHandler} ref={displayImageUpload} style={{height: 0, width: 0}} />
          </Grid>

          <Grid item xs={6} container direction="column" alignItems="center">         
            <div style={{marginTop:48,marginBottom:8}}>
              <Typography variant="h6" role="label" style={{marginBottom:8, fontWeight: 600}}>
                Secondary Logo*
              </Typography>
              <img src={secondaryLogo || defaultImage} height="95" width="186" alt="Display preview" />
            </div>
              <MyButton
              text={'Upload'}
              variant="contained"
              width={186}
              onClick={() => handleClick('secondaryLogo')}
              id="UploadSecondaryLogoButton"
            />
          </Grid>
        </Grid>
      </Grid>

      
      <Grid item container direction="column" style={{marginTop:24,}} >
        <TextFieldWithLimit
          id="SecondaryLogoText"
          title={`Secondary Logo Text`}
          content={logoText}
          limit={20}
          style={classes.tabs}
          onChange={onLogoTextChange}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        style={{
          backgroundColor:'#FFFFFF',
          marginTop:29,
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Typography variant="h6" role="label" style={{ fontWeight: 500 }}>
          Apply your branding to the app
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CreateLaunchTabDisplay;