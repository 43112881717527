import moment from 'moment';

import {DashboardConfigType, DashboardWidgetType} from '../types';
import {data} from '../utils';
import API from '../../../../config/api';

type SiteLayer = {id: string, layerName: string, name: string, children: SiteLayer[]};
const digLayers = (layers: SiteLayer[], holdingArray: {label: string, value: string}[]) => {
  layers.forEach((l) => {
    holdingArray.push({label: l.layerName, value: l.id});
    if (l.children.length > 0) {
      holdingArray = digLayers(l.children, holdingArray);
    }
  });
  return holdingArray;
}

const dashboardConfig: DashboardConfigType = {
  rows: [
    {
      widgets: [
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'ODC Permit Number',
            key: 'odcPermit',
            source: 'odcPermit',
            transformData: (dataStore) => {
              return (dataStore?.odcPermit || []).map((p) => ({
                value: p.permitId,
                attribute: {
                  label: p.permitNumber,
                  key: p.permitId,
                },
              }));
            }
          },
        },
        {
          type: DashboardWidgetType.MULTIDROPDOWN,
          payload: {
            title: 'Site Layer',
            key: 'siteLayer',
            source: 'siteLayer',
            dependentKey: ['odcPermit'],
            transformData: (dataStore, store) => {
              if (store.odcPermit.value && dataStore?.siteLayer?.length) {
                const chosenSite = dataStore?.siteLayer.find((sl) => sl.permitId === store.odcPermit.value);
                if (!chosenSite) {
                  return [];
                }
                const layers = digLayers(chosenSite.details.layers.children, []);
                return layers.map((site) => ({
                  value: site.value,
                  attribute: {
                    label: site.label,
                    key: site.label,
                  },
                }))
              }
              return []
            }
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.PIE,
          payload: {
            title: (dependents, dataStore) => {
              const chosenSite = dataStore.odcPermit.find((odc) => odc.permitId === dependents.odcPermit.value);
              return `Permitted Number of Plants\n${chosenSite?.permitNumber}`
            },
            dependentKey: ['odcPermit'],
            key: 'plantStatus',
            showTotal: true,
            source: (_, dependencies) => {
              if (!dependencies.odcPermit.value) {
                return undefined
              }
              return ({
                url: API.GET.getComponentData,
                method: 'GET',
                params: {
                  input: {
                    odcPermitId: dependencies.odcPermit.value
                  },
                  category: 'widget',
                  subcategory: 'cannabis',
                  name: 'plantStatus',
                },
              })
            },
            transformData: (dataStore, state) => {
              const currPermit = dataStore.odcPermit.find(p => p.permitId === state.odcPermit.value);
              if (currPermit && !('maxNumber' in currPermit)) {
                return [{singleTotal: true, totalTitle: 'Total plants generated', greenTitle: "No limit\nof plants generated", value: currPermit.generated}]
              }
              return (dataStore?.plantStatus || []).map((st) => ({
                name: st._id,
                value: st.amount,
              }))
            },
            legend: {
              title: 'Status',
            }
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.BAR,
          payload: {
            title: 'Site Layer Overview',
            dependentKey: ['siteLayer', 'odcPermit'],
            key: 'siteLayerView',
            source: (dataStore, dependencies) => {
              if (!dependencies.siteLayer.value?.length || !dependencies.odcPermit.value) {
                return undefined
              }
              return ({
                url: API.GET.getComponentData,
                method: 'GET',
                params: {
                  input: {
                    layerId: (dependencies.siteLayer.value as {label: string, value: string}[]).map((sl) => sl.value),
                    odcPermitId: dependencies.odcPermit.value
                  },
                  category: 'widget',
                  subcategory: 'cannabis',
                  name: 'siteLayer',
                },
              })
            },
            transformData: (dataStore, state) => {
              let arr = dataStore?.siteLayerView ? [...dataStore?.siteLayerView] : [];
              if (state.odcPermit.value && dataStore?.siteLayer?.length) {
                const chosenSite = dataStore?.siteLayer.find((sl) => sl.permitId === state.odcPermit.value);
                if (chosenSite) {
                  const layers = digLayers(chosenSite.details.layers.children, []);
                  const sortingArray = layers.map((site) => site.label);
                  arr.sort((a, b) => sortingArray.indexOf(a._id) - sortingArray.indexOf(b._id))
                }
              }
              //sort according to
              return arr.map((so) => {
                return {
                  site: so._id,
                  num: so.amount
                }
              })
            },
            yLabel: 'No. of Plants',
            notes: 'Set the sites you wish to display by using the site layer filter',
            chartPosition: {right: 100},
            xAxis: [
              {key: 'site', style: {fontWeight: 'bold', fill: 'black'}, label: 'Site Layer', labelPosition: 'right'},
            ],
            bars: [
              [
                {key: 'num', fill: '#417521'},
              ],
            ],
          },
        },
      ],
    }
  ],
};

export default dashboardConfig;
