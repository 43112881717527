import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import moment from 'moment';
import COLOR from '../../styled/colors';

import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import {Buttons} from '../../presentation/ButtonsGroup';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';
import EditIcon from '@material-ui/icons/Edit';

import DeleteIcon from '@material-ui/icons/Delete';

import {makeStyles, Grid, Button, Typography} from '@material-ui/core';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {CASL} from '@aglive/frontend-core';

import {deleteGroup, fetchGroupData} from '../../store/group/actions';
import QrCodeIcon from '../../presentation/QrCodeIcon';
import PrintDialog from '../../presentation/print/PrintDialog';
import {
  PrintTemplate,
  PrintContent,
} from '../../presentation/print/PrintTemplate';
import {useReactToPrint} from 'react-to-print';
import {toPrintContent} from './utils';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  label: {
    textTransform: 'none',
  },
  hyperlink: {
    fontSize: '1.05rem',
    fontFamily: 'Open Sans',
    color: COLOR.GREENT_TEXT,
    width: 'max-content',
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
  },
  printLinkContainer: {
    marginLeft: 'auto',
    width: 'max-content',
    marginRight: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
}));

const ANIMAL_TABLE_HEADER = [
  'Group/Mob Name ',
  'Items/Animals',
  'Location e.g. PIC',
  'Date Created',
];

const PLANT_TABLE_HEADER = [
  'Group/Mob Name ',
  'Items',
  'Location',
  'Date Created',
];

const selectTableHeaders = (
  hasAction: boolean,
  tableHeaders: Array<string>,
) => {
  return [...tableHeaders, hasAction ? 'Action' : ''];
};

const GroupLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const isCannabis = useAppSelector(
    (state) =>
      state.user.businessProfileData.industryType === 'PLANTS' &&
      state.user.businessProfileData.subIndustryType === 'Cannabis',
  );
  const [query, setQuery] = useState('');
  const mob = useAppSelector((state) => state.group.groupData);

  const ability = CASL.useAbility(CASL.AbilityContext);
  const canUpdate = ability.can('update', 'group');
  const TABLE_HEADER = useMemo(
    () =>
      selectTableHeaders(
        canUpdate,
        isCannabis ? PLANT_TABLE_HEADER : ANIMAL_TABLE_HEADER,
      ),
    [canUpdate, isCannabis],
  );

  const printComponentRef = useRef();
  const [openPrintPreviewDialog, setOpenPrintPreviewDialog] = useState(false);
  const [openPrintDialog, setOpenPrintDialog] = useState(false);

  const [groupsToPrint, setGroupsToPrint] = useState<Array<PrintContent>>([]);
  const [tokenSize, setTokenSize] = useState(1);

  const userRole = useAppSelector((state) => state.user.userProfileData.role)
  const businessProfileData = useAppSelector((state) => state.user.businessProfileData);
  const locations = businessProfileData.location;
  const locationUser = userRole && userRole.includes('location-')
    ? locations?.find(
        (location) =>
          location.locationUniqueId === userRole.replace('location-','')
      )
      || null
    : false;
  useEffect(() => {
    locationUser === false
    ? dispatch(fetchGroupData())
    : locationUser !== null 
      ? dispatch(fetchGroupData(locationUser.PICAddress))
      : dispatch(toggleModal({
          status: 'warning',
          title: '',
          subtitle: 'Location UniqueId not found.',
        }));
        dispatch({
          type: "FETCH_GROUP_DATA",
          payload: [],
        })
  }, [dispatch]);

  const handleOpenPrintClick = (group?: PrintContent) => {
    setTokenSize(1);
    setOpenPrintPreviewDialog(false);
    setOpenPrintDialog(true);
    if (group) {
      return;
    }
    const groups = mob.map((item) =>
      toPrintContent({
        location: item.pic_id,
        groupName: item.name,
        agliveToken: item.agliveToken,
      }),
    );
    setGroupsToPrint(groups);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handlePrintClick = useCallback(() => {
    setOpenPrintDialog(false);
    handlePrint();
  }, []);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by group/mob name"
          />
        </Grid>
        {!isCannabis && (
          <CASL.Can I="create" a="group">
            <Grid item className={classes.buttonContainer}>
              <MyButton
                text={'Create New'}
                variant="contained"
                width={160}
                fontSize={18}
                onClick={() => history.push(`${path}/new`)}
              />
            </Grid>
          </CASL.Can>
        )}
      </Grid>
      {isCannabis ? (
        <CASL.Can I="update" a="group">
          <div className={classes.printLinkContainer}>
            <Typography
              className={classes.hyperlink}
              onClick={() => handleOpenPrintClick()}>
              Print All IDs
            </Typography>
          </div>
        </CASL.Can>
      ) : null}
      <MyTable
        heads={TABLE_HEADER}
        rows={mob
          ?.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map((mobItem, index) => [
            <Button
              onClick={() =>
                history.push(`${path}/view/${mobItem.agliveToken}`)
              }
              classes={{
                label: classes.label,
              }}>
              <span style={{color: COLOR.GREENT_TEXT}}>
                <u>{mobItem.name}</u>
              </span>
            </Button>,
            mobItem.animalsItemsCount,
            mobItem.pic_id,
            moment(mobItem.createdAt).format('DD/MM/YYYY'),
            <CASL.Can I="update" a="group">
              <div style={{display: 'flex'}}>
                {!isCannabis ? (
                  <div style={{marginRight: 20}}>
                    <EditIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      id={`editGroup-${index}`}
                      onClick={() =>
                        history.push(`${path}/edit/${mobItem.agliveToken}`)
                      }
                    />
                  </div>
                ) : null}
                {isCannabis ? (
                  <div style={{marginRight: 10}}>
                    <QrCodeIcon
                      id={`printGroup-${index}`}
                      style={{height: '28px'}}
                      onClick={() => {
                        setOpenPrintPreviewDialog(true);
                        setGroupsToPrint([
                          toPrintContent({
                            groupName: mobItem.name,
                            location: mobItem.pic_id,
                            agliveToken: mobItem.agliveToken,
                          }),
                        ]);
                      }}
                    />
                  </div>
                ) : null}
                <div style={{marginRight: 20}}>
                  <DeleteIcon
                    id={`deleteGroup-${index}`}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      dispatch(
                        toggleModal({
                          status: 'warning',
                          title: 'Delete Group/Mob?',
                          subtitle: [
                            'This action cannot be undone',
                            'Ungrouped items remain in your account',
                          ],
                          renderButton: (
                            <Buttons
                              leftButtonTitle="Cancel"
                              rightButtonTitle="Delete"
                              leftButtonOnClick={() =>
                                dispatch(toggleModalOff())
                              }
                              rightButtonOnClick={() => {
                                dispatch(toggleModalOff());
                                dispatch(deleteGroup(mobItem,(locationUser === false ? true : (locationUser !== null ? locationUser.PICAddress : false))));
                              }}
                            />
                          ),
                        }),
                      )
                    }
                  />
                </div>
              </div>
            </CASL.Can>,
          ])}
      />
      {openPrintPreviewDialog ? (
        <PrintDialog
          title="Display Group/Mob ID"
          open={openPrintPreviewDialog}
          onClose={() => setOpenPrintPreviewDialog(false)}
          contents={groupsToPrint}
          onPrintClick={() => handleOpenPrintClick(groupsToPrint[0])}
        />
      ) : null}
      {openPrintDialog ? (
        <PrintDialog
          title="Print Group/Mob ID"
          open={openPrintDialog}
          tokenSize={tokenSize}
          onTokenSizeChange={setTokenSize}
          onClose={() => setOpenPrintDialog(false)}
          contents={groupsToPrint}
          onPrintClick={handlePrintClick}
        />
      ) : null}
      <div style={{display: 'none'}}>
        <PrintTemplate
          tokenSize={tokenSize}
          ref={printComponentRef}
          contents={groupsToPrint}
        />
      </div>
    </>
  );
};

export default withHeader(
  {
    title: 'Groups/Mobs',
  },
  GroupLibrary,
);
