import { ContactInfo } from "./types";

export function getBusinessNameDisplay(contact: ContactInfo) {
  if (contact.type === 'incoming') {
    return contact.requestorBusinessName;
  }
  if ('businessName' in contact) {
    return contact.businessName;
  }
  return '';
}

export function getContactNameDisplay(contact: ContactInfo) {
  if (contact.type === 'incoming') {
    return contact.requestorName;
  }
  if ('name' in contact) {
    return contact.name;
  }
  return '';
}

export function getContactEmailDisplay(contact: ContactInfo) {
  if (contact.type === 'incoming') {
    return contact.requestorEmail;
  }
  if ('email' in contact) {
    return contact.email;
  }
  return '';
}

export function getStatusDisplay(contact: ContactInfo) {
  if (contact.status === 'active') {
    return 'Active'
  }
  if (contact.status === 'pending' && contact.type === 'outgoing') {
    return 'Pending'
  }
  if (contact.status === 'pending' && contact.type === 'incoming') {
    return 'Pending to Approve'
  }
  return contact.status;
}
