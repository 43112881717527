import React, {useMemo, useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {useAppDispatch, useAppSelector} from 'src/utils/hooks';
import CONSTANT from 'src/config/constant';
import {PageHeader} from 'src/presentation/withHeader';
import COLOR from 'src/styled/colors';
import CollapsedContainer from 'src/presentation/CollapsedContainer';
import {
  SearchContactResponse,
  inviteContact,
  searchContact,
} from 'src/store/contacts/action';
import {StyledTableCell, StyledTableRow} from 'src/presentation/Table';
import {ErrorOutline} from '@material-ui/icons';
import {ContactInfo} from './types';
import MyButton from 'src/presentation/button';
import {toggleModal, toggleModalOff} from 'src/store/modal/actions';
import {useHistory} from 'react-router-dom';
import {DialogContentSubtitle} from 'src/presentation/DialogContentSubtitle';
import {Buttons} from 'src/presentation/ButtonsGroup';
import {WebErrorType} from 'src/utils/error';

const useStyle = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: 200,
  },
  inputBox: {
    marginTop: '0.5rem',
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    padding: '0.25rem 0.5rem',
    '&:focus-within': {
      boxShadow: `0 0 0 2px ${COLOR.GREEN}`,
    },
  },
  sectionTitle: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '0.5rem 0.75rem',
    marginBottom: '1.75rem',
  },
  invitationContainer: {
    paddingLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '5rem',
  },
  collapseContainer: {
    paddingTop: '3rem',
    paddingLeft: '0.75rem',
  },
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  checkboxContainer: {
    marginTop: '5rem',
    padding: '0.5rem 0.75rem',
    backgroundColor: COLOR.GRAY_SOLID,
  },
  contactSearchResultContainer: {
    marginTop: '2rem',
  },
  contactNotFound: {
    marginTop: '2rem',
    fontWeight: 'bold',
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    padding: '1.5rem 0.5rem',
    borderRadius: '0.25em',
    backgroundColor: COLOR.GRAY_SOLID,
  },
  greenText: {
    color: COLOR.GREENT_TEXT,
  },
  contactTableContainer: {
    maxHeight: '40vh',
    padding: '0 2px 2px',
  },
}));

type ContactSearchStatus = 'initial' | 'ok';
const NewContact: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyle();
  const history = useHistory();

  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );

  const labels = useMemo(
    () =>
      businessProfile.businessCountry === 'Argentina'
        ? CONSTANT.ES_LABELS
        : CONSTANT.EN_LABELS,
    [businessProfile.businessCountry],
  );

  const [invitationMethod, setInvitationMethod] =
    useState<ContactInfo['invitedThrough']>('userId');

  const toggleInvitation = () => {
    setInvitationMethod((p) => (p === 'email' ? 'userId' : 'email'));
  };

  const [search, setSearch] = useState('');
  const [formInput, setFormInput] = useState({
    email: '',
    canViewConsignment: false,
  });

  const [contactSerachResult, setContacToAdd] = useState<{
    status: ContactSearchStatus;
    contacts: Array<SearchContactResponse>;
  }>({
    status: 'initial',
    contacts: [],
  });

  const [selectedContactId, setSelectedContactId] = useState('');

  const emailError = useMemo(() => {
    const hasError = CONSTANT.EMAIL_REGEX.test(formInput.email) === false;
    return formInput.email.length > 0 && hasError ? 'Invalid Email' : '';
  }, [formInput.email]);

  const isFormValid = useMemo(() => {
    if (invitationMethod === 'email') {
      return formInput.email.length > 0 && emailError.length === 0;
    }
    if (invitationMethod === 'userId') {
      return !!selectedContactId;
    }
    return false;
  }, [invitationMethod, formInput.email, emailError, selectedContactId]);

  const handleSearch = async () => {
    if (search.trim().length === 0) {
      return;
    }
    setSelectedContactId('');
    const result = await dispatch(searchContact(search));
    setContacToAdd({
      status: 'ok',
      contacts: result,
    });
  };

  const handleAddContact = () => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Contact Authority',
        customContent: (
          <DialogContentSubtitle
            texts={[
              'You are granting authority',
              'to this person to access',
              'and amend your account.',
              '\n',
              'Continue?',
            ]}
          />
        ),
        renderButton: (
          <Buttons
            leftButtonTitle={labels.no}
            rightButtonTitle={labels.yes}
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              invite();
            }}
          />
        ),
      }),
    );

    function invite() {
      if (invitationMethod === 'email') {
        dispatch(
          inviteContact(
            {
              invitedThrough: 'email',
              email: formInput.email,
              viewAllConsignments: formInput.canViewConsignment,
            },
            onInviteSuccess,
            onInviteFailure,
          ),
        );
      } else if (invitationMethod === 'userId') {
        dispatch(
          inviteContact(
            {
              invitedThrough: 'userId',
              recipientId: selectedContactId,
              viewAllConsignments: formInput.canViewConsignment,
            },
            onInviteSuccess,
            onInviteFailure,
          ),
        );
      }
    }

    function onInviteSuccess() {
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Invited',
          subtitle: 'Email invitation sent',
        }),
      );
      history.push('/private/add/contacts');
    }

    function onInviteFailure(error: WebErrorType) {
      // remove dot at the end
      let message =
        error.message?.replace(/\.\s+$/, '') ?? 'Fail to invite contact';
      let errorMessage = [message];
      if (message.split(' ').length > 5) {
        // wrap text into multiple lines
        errorMessage = message
          .split(' ')
          .reduce((result, current, i) => {
            const index = i <= 3 ? 0 : Math.ceil((i - 3) / 3);
            if (!result[index]) {
              result[index] = [];
            }
            result[index].push(current);
            return result;
          }, [])
          .map((words) => words.join(' '));
      }

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          customContent: <DialogContentSubtitle texts={errorMessage} />,
        }),
      );
    }
  };

  return (
    <PageHeader
      config={{
        title: labels.addContact,
        margin: 30,
        back: true,
      }}>
      <Grid container>
        <Grid item xs={12} className={classes.sectionTitle}>
          <Typography variant="h3">Search by:</Typography>
        </Grid>
        <Grid item xs={12} className={classes.invitationContainer}>
          {/* search contacts */}
          <CollapsedContainer
            isExpanded={invitationMethod === 'userId'}
            onClick={toggleInvitation}
            label="Username or Business Name">
            <Grid container item className={classes.collapseContainer}>
              <Grid item xs={12}>
                <Grid item xs={12} lg={6}>
                  <InputLabel
                    htmlFor="contactSearch"
                    className={classes.inputLabel}>
                    Username or Business Name
                  </InputLabel>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    component={'form'}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}>
                    <OutlinedInput
                      value={search}
                      id="contactSearch"
                      placeholder={'Search'}
                      onChange={(e) => setSearch(e.target.value)}
                      notched={false}
                      aria-errormessage={emailError}
                      endAdornment={
                        <InputAdornment position="start">
                          <Button
                            type="submit"
                            style={{
                              textTransform: 'capitalize',
                              color: COLOR.GREENT_TEXT,
                            }}>
                            <Typography variant="inherit" color="inherit">
                              Search
                            </Typography>
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {/* results of search */}
              {contactSerachResult.status === 'ok' &&
                contactSerachResult.contacts.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    className={classes.contactSearchResultContainer}>
                    <Typography className={classes.inputLabel}>
                      Please select one:
                    </Typography>
                    <TableContainer
                      component={Box}
                      className={classes.contactTableContainer}>
                      <Table aria-labelledby="searchContactsResult">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Business Name</StyledTableCell>
                            <StyledTableCell>Email Address</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contactSerachResult.contacts.map((contact) => {
                            return (
                              <StyledTableRow
                                key={contact.userId}
                                hover
                                onClick={() => {
                                  setSelectedContactId(contact.userId);
                                }}
                                role="radio"
                                aria-checked={
                                  contact.userId === selectedContactId
                                }
                                tabIndex={-1}
                                selected={contact.userId === selectedContactId}>
                                <TableCell>{contact.userName}</TableCell>
                                <TableCell>{contact.businessName}</TableCell>
                                <TableCell>{contact.userEmail}</TableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              {contactSerachResult.status === 'ok' &&
                contactSerachResult.contacts.length === 0 && (
                  <Grid item xs={12} lg={6}>
                    <Box className={classes.contactNotFound}>
                      <ErrorOutline className={classes.greenText} />
                      <Typography variant="inherit">
                        Contact not found, please try again.
                      </Typography>
                    </Box>
                  </Grid>
                )}
            </Grid>
          </CollapsedContainer>
          {/* invite email */}
          <CollapsedContainer
            isExpanded={invitationMethod === 'email'}
            onClick={toggleInvitation}
            label="Invite them now via email">
            <Box className={classes.collapseContainer}>
              <Grid item xs={12} lg={6}>
                <InputLabel
                  htmlFor="emailInvite"
                  className={classes.inputLabel}>
                  Enter your contact's email address
                </InputLabel>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={emailError.length > 0}>
                  <OutlinedInput
                    id="emailInvite"
                    type="email"
                    value={formInput.email}
                    onChange={(e) =>
                      setFormInput((p) => ({...p, email: e.target.value}))
                    }
                    aria-describedby="emailError"
                  />
                  <FormHelperText id="emailError">{emailError}</FormHelperText>
                </FormControl>
              </Grid>
            </Box>
          </CollapsedContainer>
        </Grid>
        {/* consignment checkbox */}
        <Grid item xs={12} className={classes.checkboxContainer}>
          <FormControlLabel
            aria-label="canViewConsignment"
            control={
              <Checkbox
                color="primary"
                checked={formInput.canViewConsignment}
                aria-checked={formInput.canViewConsignment}
                onChange={(e) => {
                  setFormInput((p) => ({
                    ...p,
                    canViewConsignment: e.target.checked,
                  }));
                }}
              />
            }
            label="Can view all consignments?"
          />
        </Grid>
        {/* Buttons */}
        <Grid
          item
          container
          justifyContent="flex-end"
          className={classes.buttonGroup}>
          <MyButton
            disabled={!isFormValid}
            text="Invite"
            variant="contained"
            onClick={handleAddContact}
          />
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default NewContact;
