import type {PoisonPermit} from '@aglive/data-model/dist/misc/business';
import {
  Collapse,
  Grid,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import COLOR from 'src/styled/colors';

const useStyle = makeStyles(() => ({
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
}));

type Props = {
  permits: PoisonPermit[];
  labels: {
    poisonPermit: string;
    poisonPermitNumber: string;
    poisonPermitStartDate: string;
    poisonPermitExpiryDate: string;
  };
};

const PoisonPermitsView: React.FC<Props> = ({permits, labels}) => {
  const classes = useStyle();
  const [collapses, setCollapses] = useState(
    new Array(permits?.length ?? 0).fill(false),
  );
  const handleCollapse = (index: number) => {
    setCollapses((prev) =>
      prev.map((collapse, i) => (i === index ? !collapse : collapse)),
    );
  };

  useEffect(() => {
    setCollapses(new Array(permits?.length ?? 0).fill(false));
  }, [permits.length]);

  return (
    <>
      <Grid container item>
        <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
          {labels.poisonPermit}
        </Typography>
      </Grid>
      {permits.map((permit, index) => (
        <Grid item xs={12} key={index} style={{marginBottom: '1.25rem'}}>
          <div className={classes.titleContainer}>
            <ListItem
              style={{paddingLeft: 0}}
              button
              onClick={() => handleCollapse(index)}>
              <Grid container justifyContent="space-between">
                <Typography variant="h3" role="label">
                  {`${labels.poisonPermit} ${index + 1}`}
                </Typography>
                {collapses[index] ? <ExpandLess /> : <ExpandMore />}
              </Grid>
            </ListItem>
          </div>
          <Collapse in={collapses[index]}>
            <Grid container style={{marginTop: '2rem'}}>
              <Grid
                item
                container
                xs={12}
                direction="column"
                style={{marginBottom: '2rem'}}>
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {labels.poisonPermitNumber}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {permit.permitNumber}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {labels.poisonPermitStartDate}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {moment(permit.startDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {labels.poisonPermitExpiryDate}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {moment(permit.expiryDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      ))}
    </>
  );
};

export default PoisonPermitsView;
