import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import DialogMessage from '../../presentation/DialogMessage';

export const assetStyles = makeStyles((theme) => ({
  marginTopText: {
    marginTop: theme.spacing(3),
  },
  text: {
    flex: '0 0 100%',
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 600, // TODO
    marginRight: theme.spacing(0.5),
  },
}));

type ConfirmMoveModalProps = {
  open: boolean;
  groupName: string;
  originPic: string;
  originSite: string;
  destinationPic: string;
  destinationSite: string;
  handleClose: () => void;
  rightButtonClick: () => void;
};

const ConfirmMoveModal: React.FC<ConfirmMoveModalProps> = (props) => {
  const classes = assetStyles();
  return (
    <DialogMessage
      open={props.open}
      title={`Confirm Move`}
      handleClose={props.handleClose}
      leftButtonText={'Cancel'}
      leftButtonClick={props.handleClose}
      rightButtonText={'Confirm and Save'}
      rightButtonClick={props.rightButtonClick}>
      <Typography
        variant="h6"
        className={`${classes.text} ${classes.marginTopText}`}>
        {`You are about to move ${props.groupName} `}
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        {'From '}
      </Typography>
      <Typography variant="h6" className={classes.text}>
        {`Location: ${props.originPic} `}
      </Typography>
      <Typography variant="h6" className={classes.text}>
        {`Site: ${props.originSite} `}
      </Typography>
      <Typography variant="h6" className={classes.subTitle}>
        {'To '}
      </Typography>
      <Typography variant="h6" className={classes.text}>
        {`Location: ${props.destinationPic} `}
      </Typography>
      <Typography variant="h6" className={classes.text}>
        {`Site: ${props.destinationSite} `}
      </Typography>
    </DialogMessage>
  );
};

export default ConfirmMoveModal;
