import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router';

import moment from 'moment';
import {Button, Grid} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import SearchBar from '../../../presentation/SearchBar';
import MyTable from '../../../presentation/Table';
import {PageHeader} from '../../../presentation/withHeader';
import {processBrandList} from '../CreateAssetPromotion/utils';
import {
  getPromotions,
  manageArchivePromotion,
} from '../../../store/promotion/actions';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import COLOR from '../../../styled/colors';
import GreenQRCodeIcon from '../../../img/GreenQRCodeIcon.svg';
import {HyperLink} from '../../../presentation/word';
import {fetchBrands} from '../../../store/brand/actions';
import {CompleteProductPromotion} from '../../../store/promotion/types';
import PreviewModal from '../PromotionLibrary/PreviewModal';
import {fetchProductProfileTokens} from '../ProductProfileLibrary/utils';
import {convertPromotionTokens} from '../PromotionLibrary/utils';

const tableHeaders = [
  'Promotion Name',
  'Status',
  'Expiry Date',
  'Preview',
  'Actions',
];

const ArchivedPromotionLibrary: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const industryType = useAppSelector(
    (state) => state.user.businessProfileData.industryType,
  );
  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );
  const userId = useAppSelector((state) => state.auth.wallet);

  const [brandList, setBrandList] = useState<
    Array<{
      name: string;
      agliveToken: string;
      logo: string;
    }>
  >([]);
  const [query, setQuery] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [previewPromotion, setPreviewPromotion] = useState<
    TokenService.PromotionToken
  >();
  const [promotions, setPromotions] = useState<
    (TokenService.PromotionToken)[]
  >([]);
  const [productProfiles, setProductProfiles] = useState<
    TokenService.ProductToken[]
  >([]);

  const PROMOTION_LIBRARY_PATH = `/private/${
    industryType === 'ANIMALS' ? 'assets/promotions' : 'codes/promotionCodes'
  }`;
  const VIEW_PROMOTION_PATH = `${PROMOTION_LIBRARY_PATH}/view`;

  useEffect(() => {
    (async () => {
      const [brandTokens, promotions, productProfiles] = await Promise.all([
        fetchBrands(dispatch),
        dispatch(
          getPromotions(industryType === 'ANIMALS' ? industryType : 'PRODUCTS'),
        ),
        fetchProductProfileTokens(),
      ]);
      if (brandTokens) {
        setBrandList(processBrandList(brandTokens));
      }
      setProductProfiles(productProfiles);
      // Convert product promotion tokens into tokens with product profile details
      const convertedPromotions = convertPromotionTokens(
        promotions,
        productProfiles,
      );
      // Overwrite logo if promotion's logo is selected as brand logo
      setPromotions(
        convertedPromotions?.map((promotion) => {
          const promotionDetails = promotion.details;
          if (brandTokens) {
            const tempBrandList = processBrandList(brandTokens);
            if (
              tempBrandList?.length > 0 &&
              'story' in promotionDetails && 
              promotionDetails.story?.logoType === 'brand'
            ) {
              const brandIndex = tempBrandList.findIndex(
                (brand) =>
                  brand.agliveToken === promotionDetails.brand.agliveToken,
              );
              if (brandIndex !== -1) {
                promotionDetails.story.logo = tempBrandList[brandIndex]?.logo;
              }
            }
          }
          return promotion;
        }),
      );
    })();
  }, [dispatch, userId]);

  const unarchivePromotion = (promotion: TokenService.PromotionToken) => {
    dispatch(
      manageArchivePromotion(promotion, false, () =>
        history.push(PROMOTION_LIBRARY_PATH),
      ),
    );
  };

  const ArchivedPromotionLibraryContent = (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search Promotion Name"
          />
        </Grid>
      </Grid>
      <MyTable
        heads={tableHeaders}
        rows={promotions
          ?.filter((promotion) => promotion.details.archive)
          .filter((promotion) =>
            promotion.details.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map((promotion, index) => [
            <Button
              onClick={() =>
                history.push(
                  `${VIEW_PROMOTION_PATH}/${
                    Object.values(promotion.externalIds)[0].agliveToken
                  }`,
                )
              }
              id={`PromotionName${index}`}>
              <span
                style={{
                  color: COLOR.GREENT_TEXT,
                  textTransform: 'capitalize',
                  width: '100%',
                  textAlign: 'left',
                }}>
                <u>{promotion.details.name}</u>
              </span>
            </Button>,
            <span style={{textTransform: 'capitalize'}}>
              {promotion.details.status}
            </span>,
            <span
              style={{
                color:
                  promotion.details.display.endDate &&
                  moment(promotion.details.display.endDate).valueOf() <
                    moment().valueOf()
                    ? COLOR.RED_WARNING
                    : '',
              }}>
              {promotion.details.display.endDate
                ? moment(promotion.details.display.endDate).format('DD/MM/YYYY')
                : '-'}
            </span>,
            <Button
              onClick={() => {
                setPreviewPromotion(promotion);
                setOpenPreview(true);
              }}
              id={`PreviewPoster${index}`}>
              <img src={GreenQRCodeIcon} />
              <span
                style={{
                  color: COLOR.GREENT_TEXT,
                  textTransform: 'capitalize',
                  marginLeft: '8px',
                }}>
                <u>Preview</u>
              </span>
            </Button>,
            <div style={{display: 'flex'}}>
              <HyperLink
                onClick={(e) => {
                  e.preventDefault();
                  unarchivePromotion(promotion);
                }}
                href=""
                id={`Unarchive${index}`}>
                Unarchive
              </HyperLink>
            </div>,
          ])}
      />

      <PreviewModal
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        previewPromotion={previewPromotion}
        isAngus={isAngus}
        brandLogo={previewPromotion ? 'story' in previewPromotion?.details ? (previewPromotion?.details.story?.logo || null) : null : null}
        archived
      />
    </>
  );

  return (
    <PageHeader
      config={{
        title:
          industryType === 'ANIMALS'
            ? 'Archived Promotions'
            : 'Archived Promotion Codes',
        back: true,
      }}>
      {ArchivedPromotionLibraryContent}
    </PageHeader>
  );
};

export default ArchivedPromotionLibrary;
