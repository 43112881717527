import React from 'react';

import {Box, Grid} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import FieldHeading from '../../../presentation/FieldHeading';
import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import MyButton from '../../../presentation/button';

interface KeyMilestonesProps {
  milestones: TokenService.ProductToken['details']['story']['milestones'];
  onAddMilestonesEntry: () => void;
  onDeleteMilestonesEntry: (index: number) => void;
  onDupMilestonesEntry: (index: number) => void;
  onMilestonesTitleChange: (index: number, value: string) => void;
  onMilestonesDetailsChange: (index: number, value: string) => void;
}

const KeyMilestones: React.FC<KeyMilestonesProps> = ({
  milestones,
  onAddMilestonesEntry,
  onDeleteMilestonesEntry,
  onDupMilestonesEntry,
  onMilestonesTitleChange,
  onMilestonesDetailsChange,
}) => {
  const classes = useStyles();

  return (
    <Box p={3} className={classes.tabContainer}>
      <Grid container direction="column">
        {milestones.length > 0 &&
          milestones.map((milestone, index) => (
            <React.Fragment key={index}>
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <FieldHeading title={`Milestone ${index + 1}`} subtitle="" />
                </Grid>
                <Grid item>
                  <DeleteIcon
                    style={{paddingRight: 24, cursor: 'pointer'}}
                    onClick={() => onDeleteMilestonesEntry(index)}
                    id={`DeleteMilestone${index}`}
                  />
                  <FileCopyIcon
                    style={{paddingRight: 16, cursor: 'pointer'}}
                    onClick={() => onDupMilestonesEntry(index)}
                    id={`CopyMilestone${index}`}
                  />
                </Grid>
              </Grid>

              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Grid item container direction="column">
                  <TextFieldWithLimit
                    id={`MilestoneHeading${index}`}
                    title="Heading Title"
                    content={milestone.title}
                    limit={30}
                    style={classes.inputField}
                    placeholder="e.g. Production Line"
                    onChange={(value: string) => {
                      onMilestonesTitleChange(index, value);
                    }}
                  />
                  <TextFieldWithLimit
                    id={`MilestoneDetails${index}`}
                    title="Details"
                    content={milestone.details}
                    limit={30}
                    style={classes.inputField}
                    placeholder="e.g. Secure Location"
                    onChange={(value: string) => {
                      onMilestonesDetailsChange(index, value);
                    }}
                  />
                </Grid>
              </Box>
            </React.Fragment>
          ))}
        <Grid item style={{alignSelf: 'flex-end'}}>
          <MyButton
            onClick={onAddMilestonesEntry}
            variant="outlined"
            text="Add"
            width={250}
            buttonClass={classes.contentButton}
            id="AddMilestoneBtn"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyMilestones;
