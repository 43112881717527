import React from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import MyButton from '../../../presentation/button';
import COLOR from '../../../styled/colors';

type featureNode = {
  mark: boolean;
  feature: string;
  scanView: Array<'Camera' | 'App'>;
};

export type MainLandingCardType = {
  title: string;
  subtitle: string;
  subtitleExample: string;
  helperText: string;
  features: Array<featureNode>;
  benefits: Array<string>;
  selectPath: string;
  onViewSample: () => void;
};

const useStyles = makeStyles((theme) => ({
  sampleButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    width: '100%',
    maxWidth: 152,
  },
  label: {
    fontSize: 10,
    color: 'white',
  }
}));

const MainLandingCard: React.FC<{config: MainLandingCardType}> = ({config}) => {
  const {
    title,
    subtitle,
    subtitleExample,
    helperText,
    features,
    benefits,
    selectPath,
    onViewSample,
  } = config;
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Card variant="outlined" style={{padding: 20, maxWidth: 450, minHeight: 750, marginBottom: 20}} data-cy={`MainLandingCard-${title}`}>
      <CardContent>
        <Grid container style={{marginBottom: 24, justifyContent: 'space-between'}}>
          <Grid item xs={8}>
            <Typography variant="h2" style={{fontSize: 25}} gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" style={{fontSize: 14}}>
              {subtitle}
            </Typography>
            <Typography color="textSecondary" style={{fontSize: 12}}>{subtitleExample}</Typography>
          </Grid>
          <Grid item container xs={4} style={{alignSelf: 'flex-start', justifyContent: 'flex-end'}}>
            <MyButton
              variant="outlined"
              width={152}
              fontSize={18}
              text="View Sample"
              buttonClass={classes.sampleButton}
              onClick={onViewSample}
              id="ViewSampleBtn"
            />
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: 30}}>
          <Grid item container style={{justifyContent: 'space-between', marginBottom: 15}}>
            <Typography variant="h6" style={{fontWeight: 600}}>Features</Typography>
            <Typography variant="h6" style={{fontWeight: 600}}>
              {`Scan View `}
              <Tooltip title={helperText} aria-label="camera scan">
                <HelpOutlineSharpIcon
                  style={{
                    fontSize: 16,
                    color: COLOR.GREEN_BUTTON,
                    marginBottom: -2,
                  }}
                />
              </Tooltip>
            </Typography>
          </Grid>
          {
            features.map(({mark, feature, scanView}) => (
              <Grid item container style={{ marginBottom: 10}}>
                <Grid item xs={2} sm={1}>
                  {
                    mark
                    ? <CheckCircleIcon style={{color: COLOR.GREEN_BUTTON}} />
                    : <CancelIcon style={{color: COLOR.RED}} />
                  }
                </Grid>
                <Grid item xs={5} sm={7} md={8}>
                  <Typography variant="h6">{feature}</Typography>
                </Grid>
                <Grid item xs={5} sm={4} md={3} style={{flexWrap: 'wrap'}}>
                  {
                    scanView.map(scan => (
                      <Chip
                        size="small"
                        label={scan}
                        classes={{
                          label: classes.label
                        }}
                        style={{
                          marginLeft: 4,
                          height: 16,
                          backgroundColor: scan === 'App' ? COLOR.GREEN_OPTION : undefined
                        }}
                      />
                    ))
                  }
                </Grid>
              </Grid>
            ))
          }
        </Grid>
        <Grid container direction="column" style={{height: 300}}>
          <Grid item>
            <Typography variant="h6" style={{fontWeight: 600}}>Benefits</Typography>
          </Grid>
          <Grid item style={{marginLeft: -15}}>
            <ul>
              {
                benefits.map(benefit => (
                  <li>
                    <Typography>{benefit}</Typography>
                  </li>
                ))
              }
            </ul>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <MyButton variant="contained" text="Create" onClick={() => history.push(`${path}/${selectPath}`)} />
      </CardActions>
    </Card>
  );
};

export default MainLandingCard;
