import React from "react";
import ReactPlayer from 'react-player/youtube';
import { Typography, makeStyles } from "@material-ui/core";
import SmallIPhone8 from "../img/SmallIPhone8.png";
import WaterMarks from "../img/Watermarks.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { HyperLink } from "./word";
import COLOR from '../../src/styled/colors';
import defaultImage from '../img/defaultImage.png';
import { TokenService } from "@aglive/data-model";

const useStyle = makeStyles((theme) => ({
  smalliPhone8: {
    backgroundImage: `url(${SmallIPhone8})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 50,
    height: 105,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#6D7278",
    borderRadius: 7,
    marginRight: 18,
    marginTop: 18,
  },
  mobilePreviewContainer: {
    width: 136,
    height: 136,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 24,
    marginRight: 16,
    borderBottom: "4px solid #1F7C3E",
    backgroundColor: COLOR.GRAY_SOLID,
  },
}));

interface iSmallIMockPhone {
  onClick: () => void;
  index: number;
  isFocused: boolean;
  page: TokenService.ProductToken["details"]["pages"][number];
  isShowHistory: boolean;
  id?:string
}

const SmallIMockPhone: React.FC<iSmallIMockPhone> = ({
  onClick,
  index,
  isFocused,
  page,
  isShowHistory,
  id
}) => {
  const classes = useStyle();

  return (
    <div
      className={classes.mobilePreviewContainer}
      onClick={onClick}
      id={id}
      style={{
        border: !isFocused && "none",
        backgroundColor: !isFocused && "white",
      }}>
      <Typography variant="h6" style={{ fontWeight: 600 }}>
        {index + 1}
      </Typography>
      <div className={classes.smalliPhone8}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ArrowBackIosIcon
            color="secondary"
            style={{
              marginTop: 15,
              marginLeft: 6.5,
              marginRight: 2,
              width: 3.5,
              height: 3,
            }}
          />
          <Typography
            variant="h5"
            role="label"
            style={{
              marginTop: 15,
              color: "white",
              fontWeight: 200,
              fontSize: 1.5,
            }}>
            Product Information
          </Typography>
        </div>

        <div
          style={{
            backgroundColor: "white",
            marginTop: 1,
            width: 37,
            height: 73.5,
            borderRadius: "3px 3px 0 0",
            marginLeft: 6.15,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}>
          <div>
            <div
              style={{
                width: '100%',
                height: 20,
                borderRadius: "3px 3px 0 0",               
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography variant="body1" style={{ fontSize: 1.5 }}>
                {page.display.url ? (
                  <img
                    src={!ReactPlayer.canPlay(page.display.url) ? page.display.url : defaultImage}
                    width="37"
                    height="20"
                    alt="Display preview"
                  />
                ) : (
                  <img
                    src={defaultImage }
                    width="37"
                    height="20"
                    alt="Display preview"
                  />
                )}
              </Typography>
            </div>
            <Typography
              variant="body1"
              style={{
                fontWeight: 600,
                fontSize: 1.5,
                marginTop: 4,
                marginLeft: 2,
              }}>
              {page.headline[0]}
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontWeight: 600,
                fontSize: 1.5,
                marginTop: 2,
                marginLeft: 2,
              }}>
              <strong>{page.headline[1]}</strong>
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
                fontSize: 1.5,
                marginTop: 2,
                marginLeft: 2,
              }}>
              {page.headline[2]}
            </Typography>
            {page.content.map(({ subheading, content }, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginLeft: 2,
                    marginTop: index === 0 ? 3 : 2,
                    fontSize: 2,
                  }}>
                  {subheading}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontSize: 1.5, marginLeft: 2, marginTop: 2 }}>
                  {content}
                </Typography>
              </React.Fragment>
            ))}
            {page.attachment.map(({ title, url }) => (
              <Typography
                variant="subtitle1"
                style={{
                  marginLeft: 2,
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 1.5,
                }}>
                {title}
                <HyperLink
                  href={url}
                  style={{ fontSize: 1.5, lineHeight: "3px" }}>
                  {url.split("/").reverse()[0]}
                </HyperLink>
              </Typography>
            ))}
          </div>
          {isShowHistory && (
            <div
              style={{
                borderTop: "1px solid #DDDDDD",
                borderBottom: "1px solid #DDDDDD",
                marginTop: 7,
                height: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Typography
                variant="body1"
                style={{ fontSize: 1.5, marginLeft: 2 }}>
                Delivery History
              </Typography>
              <ArrowForwardIosIcon
                style={{
                  color: "#DDDDDD",
                  width: 2,
                  height: 2,
                  marginRight: 6,
                }}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 1.5,
            }}>
            <img src={WaterMarks} style={{ width: 13, height: 3 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallIMockPhone;
