import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useReactToPrint} from 'react-to-print';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {useHistory, useParams, Redirect} from 'react-router-dom';

import MyButton from '../../presentation/button';
import {makeStyles} from '@material-ui/core/styles';

import {
  Grid,
  Typography,
  TextField,
  TableRow,
  Collapse,
  TableCell,
  Box,
} from '@material-ui/core';

import withHeader from '../../presentation/withHeader';
import {
  SiteTokenResponse,
  SiteQRCode,
  SiteAssetsResponse,
} from '../../store/site/types';
import CenteredTree from './SiteCenteredTree';
import {fetchAssetsBySite} from 'src/store/site/actions';
import {flattenTree} from 'src/store/site/tree';
import MyTable, {StyledTableCell} from '../../presentation/Table';
import {HyperLink} from '../../presentation/word';
import QrCodeIcon from '../../presentation/QrCodeIcon';

import COLOR from '../../styled/colors';
import CONSTANT from '../../config/constant';
import {CASL} from '@aglive/frontend-core';
import PagedAssetTable from './PagedAssetTable';
import FullWidthModal from '../../presentation/FullWidthModal';
import {
  PrintContent,
  PrintTemplate,
} from '../../presentation/print/PrintTemplate';
import PrintDialog from '../../presentation/print/PrintDialog';

const TABLE_HEADER = ['Layer Number', 'Layer Name', 'Site ID'];

const useStyles = makeStyles((theme) => ({
  printButton: {
    color: COLOR.GREEN,
    borderColor: COLOR.GREEN,
    width: 200,
  },
  button: {
    width: 200,
  },
  editBtn: {
    marginTop: 20,
  },
  fieldTextColor: {
    '& .MuiInputBase-root': {
      color: 'black',
    },
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
    textDecoration: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
  },
  emptyMessage: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  expandedRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    overflowY: 'auto',
    maxHeight: '800px',
  },
}));

const INITIAL_SITE_CODE_STATE = {
  displaySite: false,
  printDialog: false,
  tokenSize: 1,
  sites: [] as Array<SiteQRCode>,
};

const toPrintContent = (site: SiteQRCode): PrintContent => {
  return {
    qrcode: JSON.stringify(site),
    descriptions: [
      {
        label: 'Site Name',
        value: site.siteName,
      },
      {
        label: 'Layer Name',
        value: `${site.id.split('_')[1]} - ${site.layerName}`,
      },
    ],
  };
};

const ViewSite: React.FC<{siteState: SiteTokenResponse}> = ({siteState}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const componentRef = useRef();
  const classes = useStyles();
  const EDIT_PATH = `/private/register/site/edit/${siteState.externalIds[0].agliveToken}`;
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isPlants = useAppSelector(
    (state) => state.user.businessProfileData.industryType === 'PLANTS',
  );
  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;

  const isWarakirri = !!useAppSelector((state) =>
    state.user.plugins.filter((plg) => plg.status === 'activated'),
  ).find((plg) => plg.name === 'Dairy Reports');
  // custom modal
  const [modal, setModal] = useState(INITIAL_SITE_CODE_STATE);

  // printing content
  const [printContents, setPrintContents] = useState<Array<PrintContent>>([]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDisplaySiteClick = useCallback((site: SiteQRCode) => {
    setModal((prev) => ({
      ...prev,
      printDialog: false,
      displaySite: true,
      sites: [site],
    }));
  }, []);

  const handleOpenPrintDialog = useCallback(
    (allSites: Array<SiteQRCode>, site?: SiteQRCode) => {
      const sitesToPrint = site ? [site] : allSites;
      setModal((prev) => ({
        ...prev,
        printDialog: true,
        displaySite: false,
        sites: sitesToPrint,
      }));
      setPrintContents(sitesToPrint.map(toPrintContent));
    },
    [],
  );

  const handlePrintSites = useCallback(() => {
    setModal(INITIAL_SITE_CODE_STATE);
    if (printContents.length > 0) {
      handlePrint();
    }
  }, [printContents]);

  // Make a copy to prevent mutation, need deep copy
  const treeCopy = JSON.parse(JSON.stringify(siteState.details.layers));
  // full page content
  const fullPageContent: Array<SiteQRCode> = flattenTree(treeCopy)
    .slice(1)
    .map((node) => ({
      id: `${siteState.externalIds[0].agliveToken}_${node.id}`,
      siteName: siteState.details.siteName,
      layerName: node.layerName,
      location: siteState.details.location,
    }));

  const [siteAssets, setSiteAssets] = useState<SiteAssetsResponse>([]);
  const [expandedLayer, setExpandedLayer] = useState<string>();

  const handleClickLayer = (layerId: string) => {
    setExpandedLayer((prev) => (prev === layerId ? '' : layerId));
  };

  const isLayerExpanded = (layerId: string) => {
    return layerId === expandedLayer;
  };

  const [showFullscreenTree, setShowFullscreenTree] = useState(false);

  useEffect(() => {
    const siteAgliveToken = siteState?.externalIds[0]?.agliveToken;
    if (!siteAgliveToken) {
      return;
    }
    dispatch(fetchAssetsBySite(siteAgliveToken)).then((siteAssets) => {
      setSiteAssets(siteAssets);
    });
  }, [dispatch, siteState?.externalIds]);

  return (
    <>
      <Grid alignItems="center" container style={{marginBottom: 20}}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            {`Location ${
              isPlants ? '' : isAustralia ? 'e.g. PIC' : 'e.g. PID'
            }`}
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={siteState.details.location}
            margin="normal"
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            className={classes.fieldTextColor}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            {'Site Name'}
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={siteState.details.siteName}
            margin="normal"
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            className={classes.fieldTextColor}
            id="site-name"
          />
        </Grid>
      </Grid>

      {isWarakirri && (
        <Grid alignItems="center" container>
          <Grid item xs={6} style={{paddingBottom: 40}}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 'bold',
                marginTop: 10,
              }}>
              Region
            </Typography>
            <TextField
              style={{
                marginTop: 8,
                width: '100%',
              }}
              value={siteState.details.region || '-'}
              margin="normal"
              InputLabelProps={{
                shrink: false,
              }}
              disabled
              className={classes.fieldTextColor}
            />
          </Grid>
          <Grid item xs={6} style={{paddingBottom: 40}}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 'bold',
                marginTop: 10,
              }}>
              Property Type
            </Typography>
            <TextField
              style={{
                marginTop: 8,
                width: '100%',
              }}
              value={siteState.details.propertyType || '-'}
              margin="normal"
              InputLabelProps={{
                shrink: false,
              }}
              disabled
              className={classes.fieldTextColor}
            />
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid
          container
          item
          xs={6}
          direction="column"
          style={{paddingRight: 60}}>
          <Grid item style={{paddingBottom: 10}}>
            <Typography variant="h2">Site Layers</Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{paddingBottom: 10}}>
            <CASL.Can I="update" a="site">
              <HyperLink
                id="print-all-ids"
                href="#"
                onClick={() => handleOpenPrintDialog(fullPageContent)}>
                Print All IDs
              </HyperLink>
            </CASL.Can>
          </Grid>

          <MyTable
            heads={TABLE_HEADER}
            tableBody={
              <>
                {fullPageContent.map((node, i) => {
                  const layerId = node.id.split('_')[1];
                  const site = siteAssets?.find(
                    (item) => item.site === layerId,
                  );
                  return (
                    <React.Fragment key={`${i}.${layerId}`}>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          style={{borderBottom: 'none'}}>
                          {layerId}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{borderBottom: 'none'}}>
                          <div
                            className={isPlants ? classes.hyperlink : ''}
                            onClick={
                              isPlants
                                ? () => handleClickLayer(layerId)
                                : undefined
                            }>
                            {node.layerName}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{borderBottom: 'none'}}>
                          <QrCodeIcon
                            id={`site-qr-${i}`}
                            onClick={() => handleDisplaySiteClick(node)}
                          />
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderTop: 'none',
                          }}></TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderTop: 'none',
                          }}
                          colSpan={2}>
                          <Collapse in={isLayerExpanded(layerId)}>
                            <Box className={classes.expandedRowContainer}>
                              {site && site.assets.length > 0 ? (
                                site.assets.map((item, assetIndex) => {
                                  return (
                                    <PagedAssetTable
                                      key={`${item.assetName}-${assetIndex}`}
                                      assetName={item.assetName}
                                      strain={item.strain}
                                      codes={item.codes}
                                    />
                                  );
                                })
                              ) : (
                                <Box className={classes.emptyMessage}>
                                  No plants found under this layer
                                </Box>
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h2" style={{paddingBottom: 40}}>
            Site Map Preview
          </Typography>
          <CenteredTree
            tree={siteState.details.layers}
            onFullscreenClick={() => setShowFullscreenTree(true)}
          />
          <CASL.Can I="update" a="site">
            <Grid item container justifyContent="flex-end">
              <MyButton
                id="edit-site"
                text="Edit"
                variant="contained"
                buttonClass={classes.editBtn}
                onClick={() => history.push(EDIT_PATH)}
              />
            </Grid>
          </CASL.Can>
        </Grid>
      </Grid>
      {showFullscreenTree ? (
        <FullWidthModal
          open={showFullscreenTree}
          onClose={() => setShowFullscreenTree(false)}
          title={'Full Site Map'}>
          <CenteredTree tree={siteState.details.layers} fullscreen />
        </FullWidthModal>
      ) : null}
      {modal.displaySite ? (
        <PrintDialog
          title={'Display Site ID'}
          open={modal.displaySite}
          contents={modal.sites.map(toPrintContent)}
          onClose={() => setModal(INITIAL_SITE_CODE_STATE)}
          onPrintClick={() =>
            handleOpenPrintDialog(fullPageContent, modal.sites[0])
          }
        />
      ) : null}
      {modal.printDialog ? (
        <PrintDialog
          title={'Print Site ID'}
          open={modal.printDialog}
          contents={modal.sites.map(toPrintContent)}
          onClose={() => setModal(INITIAL_SITE_CODE_STATE)}
          tokenSize={modal.tokenSize}
          onTokenSizeChange={(tokenSize: number) =>
            setModal((prev) => ({...prev, tokenSize}))
          }
          onPrintClick={handlePrintSites}
        />
      ) : null}
      <div style={{display: 'none'}}>
        <PrintTemplate
          tokenSize={modal.tokenSize}
          contents={printContents}
          ref={componentRef}
        />
      </div>
    </>
  );
};

// if the data is not in store, redirect user back to library
const ViewSiteGuard = () => {
  const {agliveToken} = useParams<{agliveToken: string}>();
  const siteState = useAppSelector((state) =>
    state.site.find(
      (token) => token.externalIds[0].agliveToken === agliveToken,
    ),
  );

  if (siteState) {
    return <ViewSite siteState={siteState} />;
  } else {
    return <Redirect to="/private/register/site" />;
  }
};

export default withHeader(
  {
    title: 'View Site Map',
    back: true,
  },
  ViewSiteGuard,
);
