import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import React from 'react';
import {FormHelperText} from '@material-ui/core';

const DateEditInput: React.FC<
  Omit<KeyboardDatePickerProps, 'minDateMessage'> & {
    errorText: string;
    minDateErrorMessage?: string;
  }
> = ({
  disableToolbar = true,
  variant = 'inline',
  format = 'dd/MM/yyyy',
  margin = 'normal',
  emptyLabel = 'dd/mm/yyyy',
  KeyboardButtonProps = {
    'aria-label': 'change date',
  },
  minDateErrorMessage,
  errorText,
  keyboardIcon,
  ...rest
}) => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar={disableToolbar}
          variant={variant}
          format={format}
          margin={margin}
          emptyLabel={emptyLabel}
          KeyboardButtonProps={KeyboardButtonProps}
          keyboardIcon={
            keyboardIcon ?? <DateRangeIcon style={{fill: '#373935'}} />
          }
          minDateMessage={minDateErrorMessage}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {!!errorText && (
        <FormHelperText style={{color: 'red', margin: '3px 14px 0'}}>
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default DateEditInput;
