import React, { useRef, useState } from 'react';
import {Redirect, useHistory, useLocation, useParams} from 'react-router-dom';
import {Grid, makeStyles} from '@material-ui/core';

import {ManageUserType} from '../../store/add/types';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import CONSTANT from '../../config/constant';
import {PageHeader} from '../../presentation/withHeader';
import MyButton from '../../presentation/button';
import {CASL} from '@aglive/frontend-core';
import { FormGenerator, FormInputFunctions } from '../../presentation/FormGenerator';
import COLOR from '../../styled/colors';
import { inviteUserAgain, submitProject } from '../../store/add/actions';

const useStyle = makeStyles((theme) => ({
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
}));

const SingleUser: React.FC<{type: 'edit' | 'view' | 'add'}> = ({type}) => {
  const {id} = useParams<{id: string}>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyle();

  const userList: Array<ManageUserType> = useAppSelector(
    (state) => state.add.users,
  );
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const userid = useAppSelector((state) => state.auth.wallet);

  const userLabels =
    businessProfile.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  const location = useLocation<string>();
  const originStatus = location.state ? Object.values(location.state)[0] : '';
  const targetProjectIndex =
    originStatus === 'Pending'
      ? userList.findIndex((project) => project.invitationCode === id)
      : userList.findIndex((project) => project.userId === id);
  const user = userList[targetProjectIndex];
  const userInfo = {
    userId: user?.userId ? user?.userId : user?.invitationCode,
    name: user?.name,
    role: user?.role,
    phone: user?.phone,
    email: user?.email,
    status: originStatus as string,
    statusChange: '',
    roleChange: '',
  };
  const formRef = useRef<FormInputFunctions<typeof userInfo>>();

  const [formReady, setFormReady] = useState(userInfo?.name ? true : false);

  const roleList = [
    {label: userLabels.admin, value: 'admin'},
    {label: userLabels.user, value: 'user'},
    {label: userLabels.readOnly, value: 'read-only'}
  ];

  const formInputs = [
    {
      type: 'INPUT',
      name: 'name',
      titleKey: 'name',
      required: true,
      viewMode: type === 'view',
      value: userInfo?.name
    },
    {
      type: 'SELECT',
      name: 'role',
      titleKey: 'role',
      required: true,
      items: roleList,
      placeholder: `--${userLabels.role}--`,
      viewMode: type === 'view',
      value: userInfo?.role && type === 'view' ? roleList.find(rl => rl.value === userInfo.role).label : userInfo?.role?.toLowerCase()
    },
    {
      type: 'PHONE',
      name: 'phone',
      titleKey: 'phone',
      required: true,
      viewMode: type === 'view',
      value: userInfo?.phone
    },
    {
      type: 'EMAIL',
      name: 'email',
      titleKey: 'emailAddress',
      required: true,
      viewMode: type === 'view',
      value: userInfo?.email
    },
    {
      type: 'SELECT',
      name: 'status',
      titleKey: 'status',
      skip: !userInfo.name,
      required: true,
      placeholder: `--${userLabels.status}--`,
      items: [
        {label: userLabels.active, value: userLabels.active}, 
        {label: userLabels.inactive, value: userLabels.inactive}
      ],
      viewMode: type === 'view' || userInfo?.status === 'Pending',
      value: userInfo?.status === 'Pending' ? userLabels.pendingAcceptance : userInfo.status
    },
  ];

  // Redirect user back to UserLibrary if user could not be found
  if (!user) return <Redirect to="/private/add/users" />;

  return (
    <PageHeader
      config={{
        title: userLabels[userInfo?.status === '' && type === 'edit' ? 'addUser' : `${type}User`],
        margin: 30,
        back: true,
      }}>
      <Grid container spacing={3}>
        <FormGenerator ref={formRef} formInputs={formInputs} onReady={setFormReady} language={businessProfile.businessCountry === 'Argentina' ? 'es' : 'en'} />
        <CASL.Can I="update" a="user">
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{marginTop: 200}}>
            {type === 'view' ? <MyButton
              text="Edit"
              variant="contained"
              onClick={() =>
                history.push({
                  pathname: `/private/add/users/${id}`,
                  state: {status: originStatus},
                })
              }
            />
            : <>
                {originStatus === 'Pending' && <MyButton
                  text={userLabels.inviteAgain}
                  variant="outlined"
                  buttonClass={classes.button}
                  onClick={() =>
                    dispatch(
                      inviteUserAgain(
                        userInfo.userId,
                        userid,
                        businessProfile.businessCountry,
                        () => {
                          history.push('/private/add/users');
                        },
                      ),
                    )
                  }
                />}
                <MyButton
                  text={userInfo.status === '' ? userLabels.invite : userLabels.save}
                  variant="contained"
                  disabled={!formReady}
                  onClick={() => {
                    if (originStatus === 'Pending') {
                      dispatch(
                        submitProject(
                          {...userInfo, ...formRef.current.getValues()},
                          userid,
                          businessProfile.businessCountry,
                          () => {
                            history.push('/private/add/users');
                          },
                          true,
                        ),
                      );
                    } else {
                      const newVals = formRef.current.getValues();
                      let newData = {...userInfo, ...newVals};
                      if (newVals.role !== userInfo.role) {
                        newData['roleChange'] = 'Change';
                      }
                      if (newVals.status !== userInfo.status) {
                        newData['statusChange'] = newVals.status;
                      }
                      dispatch(
                        submitProject(
                          newData,
                          userid,
                          businessProfile.businessCountry,
                          () => {
                            history.push('/private/add/users');
                          },
                        ),
                      );
                    }
                  }}
                />
              </>
            }
          </Grid>
        </CASL.Can>
      </Grid>
    </PageHeader>
  );
};

export default SingleUser;
