import React, {useEffect, useCallback, useReducer} from 'react';
import COLOR from '../../styled/colors';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import {
  makeStyles,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TextField,
  Box,
  Switch,
  withStyles,
} from '@material-ui/core';
import MyButton from '../../presentation/button';
import {FormCard} from './FormCard';
import CONSTANT from '../../config/constant';
import moment from 'moment';
import {
  editSingleForm,
  fetchSingleForm,
  saveFormTemplate,
} from '../../store/form/actions';
import {
  FormAnswers,
  Question,
  QuestionInput,
  FormType,
  InputValueType,
  FormDocResponse,
  FormAnswer,
} from '../../store/form/types';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {ExpandMore} from '@material-ui/icons';
import {CASL} from '@aglive/frontend-core';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  formContainer1: {
    marginTop: 50,
  },
  formContainer2: {
    marginTop: -10,
  },
  formTitleContainer: {
    justifyContent: 'space-between',
  },
  formCardContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 25,
    padding: 10,
  },
  buttonContainer: {
    justifyContent: 'end',
    marginTop: 50,
  },
  label: {
    textTransform: 'none',
  },
  moreInfo: {
    color: COLOR.GRAY_TEXT,
    fontSize: 14,
    paddingRight: 10,
    paddingTop: 10
  }
}));

const initialState = {
  forms: [] as Array<FormType>,
  formTypes: [] as Array<{label: string; value: string}>,
  selectedFormType: '' as string,
  formName: '' as string,
  questionList: [] as Array<Question>,
  formAnswers: {} as FormAnswers,
  errorStates: {} as {[key: string]: boolean}, // if true: field is empty
  breakIndex: 0,
  canSubmit: true, // when click 'save': check each answer in formAnswers, if answer is null or '' && errorState is true, set canSubmit to false, fields show error msg
  completedAnswers: false,
  currentDocId: ''
};

type Action =
  | {type: 'set/forms'; forms: Array<FormType>}
  | {
      type: 'set/formTypes';
      formTypes: Array<{label: string; value: string}>;
    }
  | {type: 'update/selectedFormType'; selectedFormType: string}
  | {type: 'update/formName'; formName: string}
  | {type: 'set/questionList'; questionList: Array<Question>}
  | {type: 'update/formAnswers'; formAnswers: FormAnswers}
  | {type: 'update/errorStates'; errorStates: {[key: string]: boolean}}
  | {type: 'set/breakIndex'; breakIndex: number}
  | {type: 'set/canSubmit'; canSubmit: boolean}
  | {type: 'set/completedAnswers'; completedAnswers: boolean}
  | {type: 'set/currentDocId'; currentDocId: string};

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    case 'set/forms':
    case 'set/formTypes':
    case 'update/selectedFormType':
    case 'update/formName':
    case 'set/questionList':
    case 'update/formAnswers':
    case 'update/errorStates':
    case 'set/breakIndex':
    case 'set/canSubmit':
    case 'set/completedAnswers':
    case 'set/currentDocId':
      return {...prevState, ...actionData};
  }
};

type FormTemplateProps = {
  formDocId?: string;
  formType?: string;
  formName?: string;
  viewAnswers?: FormAnswers;
  handleCompletedAnswers?: (
    formType: string,
    formAnswers: FormAnswers,
    errorStates: {[key: string]: boolean},
    path: any,
    docId: any,
  ) => boolean;
  mode?: 'VIEW' | 'EDIT';
  handleFormMode?: (formType: string, mode: 'VIEW' | 'EDIT') => void;
  newForm?: boolean;
  completedAnswer?: boolean /**if form already completed, change switch status on edit consignment */;
};

const FormTemplate: React.FC<FormTemplateProps> = React.memo((props) => {
  const classes = useStyles();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {docId} = useParams<{docId: string}>();
  const dispatch = useAppDispatch();
  const [state, localDispatch] = useReducer(reducer, {
    ...initialState,
    selectedFormType: props.formType ?? '',
  });

  const handleFormTypeChange = (
    e: React.ChangeEvent<{name?: string; value: any}>,
  ) => {
    localDispatch({
      type: 'update/selectedFormType',
      selectedFormType: e.target.value,
    });
  };
  const handleFormNameChange = (e: React.ChangeEvent<{value: string}>) => {
    localDispatch({
      type: 'update/formName',
      formName: e.target.value,
    });
  };

  const initaiteInputValue = (input: QuestionInput): InputValueType => {
    switch (input.type) {
      case 'radio':
        return input.answersValues
          ? input.answersValues[0]
          : input.answers?.length
          ? input.answers[0]
          : null;
      case 'date':
        return moment().format('YYYY-MM-DD');
      case 'boolean_date':
        return 'false';
      case 'boolean_date_text':
        return 'false';
      case 'displayBox':
        return input.value;
      case 'file':
        return '';
      default:
        return null;
    }
  };

  // options -> default: initiate default subAnswers' states of subQustions when questionList change
  // options -> answer: initiate subAnswers' states of subQustions when change answer, such as switch from yes to no
  const initiateSubInputStates = (
    options: 'default' | 'answer',
    question: Question,
    answers: FormAnswers,
    errorStates: {[key: string]: boolean},
    value?: string,
  ) => {
    if (question.subQuestions) {
      Object.keys(question.subQuestions).forEach((key) => {
        if (
          (options === 'default' && key === question.default) ||
          (options === 'answer' && key === value)
        ) {
          question.subQuestions[key]?.inputs.forEach((input) => {
            answers[`${input.questionId}-${input.index}`] = {
              questionId: input.questionId,
              index: input.index,
              value: initaiteInputValue(input),
            };

            if (input.required) {
              errorStates[`${input.questionId}-${input.index}`] =
                input.type === 'date' ? false : true;
            }
          });
        }
      });
    } else if (question.inputs) {
      // for additional info textfield
      question.inputs.forEach((input) => {
        if (input.required) {
          errorStates[`${input.questionId}-${input.index}`] =
            input.type === 'date' ? false : true;
        }
      });
    }
  };

  const initiateBoxInputStates = (
    inputs: QuestionInput[],
    answers: FormAnswers,
    errorStates: {[key: string]: boolean},
    value?: string,
  ) => {
    inputs.forEach((input) => {
      // for 4 treatments, share questionId 131,132,133. Different in index
      if (input.type === 'boolean_date_text' || !input.questionId) {
        return;
      }
      answers[`${input.questionId}-${input.index}`] = {
        questionId: input.questionId,
        index: input.index,
        value: initaiteInputValue(input),
      };

      // use subQuestionId as question id for 'boolean_date_text' text
      if (input.type === 'boolean_date_text') {
        answers[`${input.subQuestionId}-${input.index}`] = {
          questionId: input.subQuestionId,
          index: input.index,
          // value: moment().format('YYYY-MM-DD'),
          value: '',
        };
      }
      if (input.type === 'boolean_date') {
        answers[`${input.subQuestionId}-${input.index}`] = {
          questionId: input.subQuestionId,
          index: input.index,
           // value: moment().format('YYYY-MM-DD'),
           value: '',
        };
      }
      if (input.required) {
        errorStates[`${input.questionId}-${input.index}`] = true;
      }
    });
  };

  // delete answers of subQuestions when change answer, such as switch from yes to no
  const deleteSubInputs = (
    question: Question,
    preValue: string,
    answers: FormAnswers,
    errorStates: {[key: string]: boolean},
  ): FormAnswers => {
    if (question.subQuestions) {
      Object.keys(question.subQuestions).forEach((key) => {
        if (key === preValue) {
          let firstBatch: QuestionInput[] = [];
          question.subQuestions[key].inputs?.forEach((input) => {
            let counter = 0;
            while (answers[`${input.questionId}-${counter}`]) {
              delete answers[`${input.questionId}-${counter}`];
              if (input.required) {
                delete errorStates[`${input.questionId}-${counter}`];
              }
              counter++;
            }
            if (input.index === 0) {
              firstBatch.push(input);
            }
          });
          if (question.subQuestions[key].multigroup && firstBatch.length) {
            const qIdx = state.questionList.findIndex((ql) => ql.questionId === question.questionId);
            updateQuestion(qIdx)(preValue, firstBatch);
          }
        }
      });
    }
    return answers;
  };

  const handleSubmit = useCallback(() => {
    for (const [key, answer] of Object.entries(state.formAnswers)) {
      if (!answer.value || answer.value === '') {
        if (state.errorStates[key]) {
          localDispatch({
            type: 'set/canSubmit',
            canSubmit: false,
          });
          return;
        }
      }
    }
    if (path.includes('edit')) {
      dispatch(
        editSingleForm(
          docId,
          state.selectedFormType,
          state.formName,
          Object.values(state.formAnswers),
          history,
        ),
      );
    } else {
      dispatch(
        saveFormTemplate(
          state.selectedFormType,
          state.formName,
          Object.values(state.formAnswers),
          history,
        ),
      );
    }
  }, [
    path,
    state.formAnswers,
    state.errorStates,
    state.selectedFormType,
    state.formName,
    dispatch,
    docId,
    history,
  ]);

  const fetchJson = useCallback(async () => {
    try {
      const res = await fetch(CONSTANT.FORM_JSON_LINK);
      const jsonData = await res.json();
      localDispatch({
        type: 'set/forms',
        forms: jsonData,
      });
      return jsonData;
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (
      !path.includes('edit') &&
      !path.includes('view') &&
      !path.includes('duplicate') &&
      !path.includes('consignment')
    )
      return;
    if (
      path.includes('consignment') &&
      !(props.formDocId || props.viewAnswers)
    ) {
      /**add default values for new form */
      if (props.newForm) {
        let questions = state.forms.find(
          (item) => item.type === state.selectedFormType,
        )?.questions;
        let answers = {};
        let errorStates = {};
        // initiate right default form answers
        questions?.forEach((question) => {
          if (question.questionId) {
            answers[`${question.questionId}-${question.index}`] = {
              questionId: question.questionId,
              index: question.index,
              value:
                // use default value of answers
                question.answers?.length && question.default
                  ? question.answers[question.answers.indexOf(question.default)]
                  : '',
            };
          }

          // initiate subQuestions inputs states
          initiateSubInputStates('default', question, answers, errorStates);

          if (question?.style === 'boxed' || question?.style === 'whole_box') {
            // initiate box style inputs states('boxed'/'whole_box')
            initiateBoxInputStates(question.inputs, answers, errorStates);
          }
          localDispatch({
            type: 'update/formAnswers',
            formAnswers: answers,
          });
          localDispatch({
            type: 'update/errorStates',
            errorStates: errorStates,
          });
        });
        return;
      } else {
        return;
      }
    }

    if (props.viewAnswers) {
      localDispatch({
        type: 'update/selectedFormType',
        selectedFormType: props.formType,
      });
    }
    if (props.completedAnswer === true) {
      localDispatch({
        type: 'set/completedAnswers',
        completedAnswers: true,
      });
    }
    
  }, [
    props.formDocId,
    props.viewAnswers,
    props.newForm,
    props.completedAnswer
  ]);

  const prepareQuestions = useCallback((forceReturn?: boolean, answers?: FormAnswers, formType?: string, formData?: FormType[]) => {
    let questions = (formData ?? state.forms).find(
      (item) => item.type === (formType ?? state.selectedFormType),
    )?.questions;
    const tmpAns = answers ?? state.formAnswers;//props.viewAnswers;
    let hasChanges = false;
    if (tmpAns && Object.values(tmpAns).length > 0) {
      questions?.forEach((q, idx) => {
        const chosenAns = tmpAns[`${q.questionId}-${q.index}`]?.value as string;
        if (q.subQuestions && q.subQuestions[chosenAns]) {
          let answerIdx = [];
          Object.values(tmpAns).forEach((fa) => {
            if (q.subQuestions && q.subQuestions[chosenAns] && q.subQuestions[chosenAns].inputs
              && fa.questionId === q.subQuestions[chosenAns].inputs[0].questionId) {
              answerIdx.push(fa.index);
            }
          });
          const maxIdx = Math.max(...answerIdx);
          //prepare extra inputs bases on previous answer
          if (q.subQuestions[chosenAns].addText && q.subQuestions[chosenAns].inputs[q.subQuestions[chosenAns].inputs.length - 1].index !== maxIdx) {
            const oriCopy = JSON.parse(JSON.stringify(q.subQuestions[chosenAns].inputs)).filter((ipt) => ipt.index === 0);
            let qCopy = JSON.parse(JSON.stringify(oriCopy));
            
            for (let i = 1; i <= maxIdx; i++) {
              qCopy = [...qCopy, ...oriCopy.map((cp) => {
                return {
                  ...cp,
                  index: i,
                  boxWrapIndex: i
                }
              })];
            }
            //if previously has multiple and the new answers do not, remove
            if (qCopy[qCopy.length-1].index > maxIdx) {
              qCopy.forEach((qc, qcidx) => {
                if (qc.index > maxIdx) {
                  delete qCopy[qcidx];
                }
              });
            }
            questions[idx].subQuestions[chosenAns].inputs = qCopy.filter((q) => q);
            hasChanges = true;
          }
        }
      });
    }
    if (forceReturn || hasChanges) {
      return questions;
    }
  }, [state.formAnswers, state.forms, state.selectedFormType]);

  const fetchForm = useCallback(async () => {
    try {
      const jsonData = await fetchJson();
      const id = path.includes('consignment') ? props.formDocId : docId;
      if (!id) return;
      const res = await fetchSingleForm(id);
      localDispatch({
        type: 'update/selectedFormType',
        selectedFormType: res.formType,
      });
      localDispatch({
        type: 'update/formName',
        formName: path.includes('duplicate')
          ? `Copy of ${res.details.name}`
          : res.details.name,
      });
      const answers = {};
      const errorStates = {};
      res.details.answers.forEach((ans) => {
        answers[`${ans.questionId}-${ans.index}`] = {
          questionId: ans.questionId,
          index: ans.index,
          value: ans.value,
        };
        errorStates[`${ans.questionId}-${ans.index}`] = false;
      });
      localDispatch({
        type: 'update/formAnswers',
        formAnswers: answers,
      });
      localDispatch({
        type: 'update/errorStates',
        errorStates: errorStates,
      });
      const tmp = prepareQuestions(true, answers, res.formType, jsonData);
      if (tmp) {
        localDispatch({
          type: 'set/questionList',
          questionList: tmp,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [docId, path, prepareQuestions, props.formDocId, state.forms]);

  useEffect(() => {
    const currDoc = (props.formDocId || docId) ? (props.formDocId ?? docId) : 'none';
    if (currDoc !== state.currentDocId) {
      fetchForm();
      localDispatch({
        type: 'set/currentDocId',
        currentDocId: currDoc,
      });
    }
  }, [props.formDocId, docId, state.currentDocId, fetchForm, state.questionList])

  useEffect(() => {
    if (!path.includes('consignment')) return;
    if (props?.formName && props?.formType) {
      localDispatch({
        type: 'update/selectedFormType',
        selectedFormType: props.formType,
      });
      localDispatch({
        type: 'update/formName',
        formName: props.formName,
      });
    }
  }, [props.formName, props.formType]);

  useEffect(() => {
    const questions = prepareQuestions(true);
    localDispatch({
      type: 'set/questionList',
      questionList: questions,
    });
    localDispatch({
      type: 'set/breakIndex',
      breakIndex:
        CONSTANT.FORM_COL_BREAK_INDEX[state.selectedFormType] ??
        Math.floor(questions?.length / 2),
    });
    const answers = {};
    const errorStates = {};
    if (props.viewAnswers) {
      Object.values(props.viewAnswers).forEach((ans) => {
        answers[`${ans.questionId}-${ans.index}`] = {
          questionId: ans.questionId,
          index: ans.index,
          value: ans.value,
        };
        errorStates[`${ans.questionId}-${ans.index}`] = false;
      });
    } else {
      if (
        !path.includes('formTemplate/new') &&
        !path.includes('consignment/new')
      )
        return;

      // initiate right default form answers
      questions?.forEach((question) => {
        answers[`${question.questionId}-${question.index}`] = {
          questionId: question.questionId,
          index: question.index,
          value:
            // use default value of answers
            question.answers?.length && question.default
              ? question.answers[question.answers.indexOf(question.default)]
              : '',
        };

        // initiate subQuestions inputs states
        initiateSubInputStates('default', question, answers, errorStates);

        if (question?.style === 'boxed' || question?.style === 'whole_box') {
          // initiate box style inputs states('boxed'/'whole_box')
          initiateBoxInputStates(question.inputs, answers, errorStates);
        }
      });
    }
    localDispatch({
      type: 'update/formAnswers',
      formAnswers: answers,
    });
    localDispatch({
      type: 'update/errorStates',
      errorStates: errorStates,
    });
  }, [state.selectedFormType, state.forms, props.viewAnswers]);

  useEffect(() => {
    const types = state.forms?.map((form) => {
      return {label: form.name, value: form.type};
    });
    localDispatch({
      type: 'set/formTypes',
      formTypes: types,
    });
  }, [state.forms]);

  // change top level answers of one question like yes/no
  const changeYesNoAnswer = useCallback(
    (question: Question, value: string, preValue: string) => {
      const subAnswers = {};
      const subErrorStates = {};
      initiateSubInputStates(
        'answer',
        question,
        subAnswers,
        subErrorStates,
        value,
      );
      deleteSubInputs(question, preValue, state.formAnswers, state.errorStates);
      localDispatch({
        type: 'update/formAnswers',
        formAnswers: {
          ...state.formAnswers,
          [`${question.questionId}-${question.index}`]: {
            ...state.formAnswers[`${question.questionId}-${question.index}`],
            value: value,
          },
          ...subAnswers,
        },
      });
      localDispatch({
        type: 'update/errorStates',
        errorStates: {
          ...state.errorStates,
          ...subErrorStates,
        },
      });
    },
    [state.formAnswers, state.errorStates],
  );

  const updateQuestion = useCallback((idx: number) => (subAnswer: string, inputs: QuestionInput[]) => {
    let copy = JSON.parse(JSON.stringify(state.questionList));
    copy[idx].subQuestions[subAnswer].inputs = inputs;
    localDispatch({
      type: 'set/questionList',
      questionList: copy,
    });
  }, [state.questionList]);

  // update input answers of subQuestions
  const updateSubAnswer = useCallback(
    (
      questionList: string | Array<string>,
      inputValue: InputValueType | Array<InputValueType>,
      index: number | null,
      subQuestionId?: string,
      subValue?: InputValueType,
      subIndex?: number | null,
      inputType?: string,
      remove?: boolean
    ) => {
      const questionId = typeof questionList !== 'string' ? questionList[0] : questionList;
      const value = Array.isArray(inputValue) ? inputValue[0] : inputValue;
      if (subQuestionId) {
        const answerCopy = {...state.formAnswers};
        if (value === false) {
          delete answerCopy[`${questionId}-${index}`];
          delete answerCopy[`${subQuestionId}-${subIndex}`];
        } else {
          answerCopy[`${questionId}-${index}`] = {
            questionId: questionId,
            index: index,
            value: value,
          }
          answerCopy[`${subQuestionId}-${subIndex}`] = {
            questionId: subQuestionId,
            index: subIndex,
            value: subValue,
          }
        }
        localDispatch({
          type: 'update/formAnswers',
          formAnswers: answerCopy,
        });
      } else {
        let updatedAnswers = {...state.formAnswers};
        if (typeof questionList !== 'string') {
          if (remove) {
            (questionList as Array<string>).forEach((ql) => {
              delete updatedAnswers[`${ql}-${index}`];
            });
            let answerIdx = Object.values(updatedAnswers).filter((ua) => ua.questionId === questionId).map((ua) => ua.index);
            const maxIdx = Math.max(...answerIdx);
            //if deleting in the middle, shift all indices of next answers and minus 1
            for (let i = index + 1; i <= maxIdx; i++) {
              // eslint-disable-next-line no-loop-func
              (questionList as Array<string>).forEach((ql) => {
                if (updatedAnswers[`${ql}-${i}`]) {
                  updatedAnswers[`${ql}-${i - 1}`] = {
                    ...updatedAnswers[`${ql}-${i}`],
                    index: i - 1
                  };
                  //if last one, remove it
                  if (!updatedAnswers[`${ql}-${i+1}`]) {
                    delete updatedAnswers[`${ql}-${i}`];
                  }
                }
              });
            }
          } else {
            let toAdd: {[key: string]: FormAnswer} = {};
            questionList.forEach((ql, idx) => {
              toAdd[`${ql}-${index}`] = {
                questionId: ql,
                index: index,
                value: Array.isArray(inputValue) ? inputValue[idx] : value
              };
            })
            updatedAnswers = {...updatedAnswers, ...toAdd};
          }
        } else {
          if (remove) {
            delete updatedAnswers[`${questionId}-${index}`];
          } else {
            updatedAnswers = {...updatedAnswers, [`${questionId}-${index}`]: {
              questionId: questionId,
              index: index,
              value: value,
            }};
          }
        }
        localDispatch({
          type: 'update/formAnswers',
          formAnswers: updatedAnswers,
        });
      }
      if (`${questionId}-${index}` in state.errorStates || inputType === 'boolean_date_text') {
        localDispatch({
          type: 'update/errorStates',
          errorStates: {
            ...state.errorStates,
            [`${questionId}-${index}`]:
              inputType === 'date' ||
              inputType === 'boolean_date'
                ? false
                : (inputType === 'boolean_date_text' && value === '') ? true 
                  : value
                    ? false
                    : true,
          },
        });
      }
    },
    [state.formAnswers, state.errorStates],
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={classes.bodyContainer}>
        {!path.includes('consignment') && (
          <>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                role="label"
                style={{fontWeight: 700, marginBottom: 10}}>
                Form Type
              </Typography>
              {path.includes('view') ||
              path.includes('edit') ||
              path.includes('duplicate') ? (
                <Typography
                  id="form-type"
                  variant="h6"
                  role="label"
                  style={{marginBottom: 10}}>
                  {
                    state.formTypes.find(
                      (type) => type.value === state.selectedFormType,
                    )?.label
                  }
                </Typography>
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="envd-form-type-select">
                    {'Please select'}
                  </InputLabel>
                  <Select
                    id={'formTypeSelect'}
                    labelId="report-type-select"
                    label="Please select"
                    value={state.selectedFormType}
                    onChange={handleFormTypeChange}>
                    {state.formTypes.map(({label, value}, index) => (
                      <MenuItem value={value} key={index}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                role="label"
                style={{fontWeight: 700, marginBottom: 10}}>
                Form Name
              </Typography>
              {path.includes('view') ? (
                <Typography
                  id="form-name"
                  variant="h6"
                  role="label"
                  style={{marginBottom: 10}}>
                  {state.formName}
                </Typography>
              ) : (
                <TextField
                  id={'formNameTextfield'}
                  fullWidth
                  variant="outlined"
                  value={state.formName}
                  onChange={handleFormNameChange}
                />
              )}
            </Grid>
          </>
        )}
        {state.selectedFormType && (
          <Grid container item>
            <Grid
              container
              className={
                !path.includes('consignment')
                  ? classes.formContainer1
                  : classes.formContainer2
              }>
              {!path.includes('consignment') && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.formTitleContainer}>
                    <Typography
                      variant="h3"
                      role="label"
                      style={{marginBottom: 8}}>
                      Answer Form
                    </Typography>
                    <ExpandMore />
                  </Grid>
                  <Divider style={{width: '100%'}} />
                </>
              )}
              <Grid container spacing={2} className={classes.formCardContainer}>
                <Grid container justifyContent='flex-end'><Typography className={classes.moreInfo}>For full question text please click More&gt;</Typography></Grid>
                <Grid container item xs={6} alignContent="flex-start">
                  {(state.questionList ?? []).map((question, index) => {
                    return (
                      <React.Fragment key={`wrap-${index}`}>
                        {index <= state.breakIndex && (
                          <FormCard
                            id={`FormCard-${index}-${question.questionId}`}
                            question={question}
                            index={index}
                            changeAnswer={changeYesNoAnswer}
                            updateSubAnswer={updateSubAnswer}
                            updateQuestion={updateQuestion(index)}
                            formAnswers={state.formAnswers}
                            errorStates={state.errorStates}
                            viewMode={
                              path.includes('view') || props.mode === 'VIEW'
                            }
                            showError={!state.canSubmit}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Grid container item xs={6} alignContent="flex-start">
                  {(state.questionList ?? []).map((question, index) => {
                    return (
                      <React.Fragment key={`wrap-${index}`}>
                        {index > state.breakIndex && (
                          <FormCard
                            id={`FormCard-${index}-${question.questionId}`}
                            question={question}
                            index={index}
                            changeAnswer={changeYesNoAnswer}
                            updateSubAnswer={updateSubAnswer}
                            updateQuestion={updateQuestion(index)}
                            formAnswers={state.formAnswers}
                            errorStates={state.errorStates}
                            viewMode={
                              path.includes('view') || props.mode === 'VIEW'
                            }
                            showError={!state.canSubmit}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.buttonContainer}>
              {path.includes('formTemplate/view') && (
                <CASL.Can I="update" a="form">
                  <MyButton
                    id="edit-form"
                    text={'Edit'}
                    variant="contained"
                    width={280}
                    fontSize={18}
                    onClick={() => {
                      history.replace(
                        `/private/add/formTemplate/edit/${docId}`,
                      );
                    }}
                  />
                </CASL.Can>
              )}
              {(path.includes('formTemplate/new') ||
                path.includes('formTemplate/duplicate') ||
                path.includes('formTemplate/edit')) && (
                <MyButton
                  text={'Save'}
                  variant="contained"
                  width={280}
                  fontSize={18}
                  disabled={!state.selectedFormType || !state.formName}
                  onClick={handleSubmit}
                />
              )}
              {path.includes('consignment') && !path.includes('view') && (
                <Box
                  ml={1}
                  mr={1}
                  height={56}
                  style={{
                    backgroundColor: COLOR.BLACK_BG,
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{color: COLOR.WHITE}}>
                    I have completed the answers
                  </Typography>
                  <Switch
                    checked={state.completedAnswers}
                    id={`answerToggle${state.selectedFormType}`}
                    onChange={() => {
                      if (
                        props?.handleCompletedAnswers(
                          state.selectedFormType,
                          state.formAnswers,
                          state.errorStates,
                          path,
                          props.formDocId,
                        )
                      ) {
                        localDispatch({
                          type: 'set/canSubmit',
                          canSubmit: false,
                        });
                      } else {
                        props.handleFormMode(
                          props.formType,
                          state.completedAnswers ? 'EDIT' : 'VIEW',
                        );
                        localDispatch({
                          type: 'set/completedAnswers',
                          completedAnswers: !state.completedAnswers,
                        });
                      }
                    }}
                    name="boolean"
                    color="primary"
                    size="medium"
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
});

export const CreateForm = withHeader(
  {
    title: 'Create Form Template',
    back: true,
  },
  FormTemplate,
);

export const EditForm = withHeader(
  {
    title: 'Edit Form',
    back: true,
  },
  FormTemplate,
);

export const ViewForm = withHeader(
  {
    title: 'View Form',
    back: true,
  },
  FormTemplate,
);

export const DuplicateForm = withHeader(
  {
    title: 'Duplicate Form',
    back: true,
  },
  FormTemplate,
);

export default FormTemplate;
