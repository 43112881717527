import React, {useState, useEffect, useCallback} from 'react';
import {
  Box,
  makeStyles,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import COLOR from '../../styled/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import Save from '@material-ui/icons/Save';
import {TokenService} from '@aglive/data-model';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {MobileInput} from '../../presentation/FormGenerator';
import CONSTANT from '../../config/constant';

type LocationUserType = {
  name: string;
  phone: string;
  email: string;
  status: string;
  edit: boolean;
  userId: string;
  role?: string;
  canEdit: boolean;
  invitation?: string;
};
type UserValidation = {[key: string]: {value: string; error: string}};

const useStyle = makeStyles(() => ({
  table: {
    marginTop: 30,
    '& td': {verticalAlign: 'top'},
  },
  tableHeader: {
    backgroundColor: COLOR.GRAY_SOLID,
    '& td': {
      borderBottom: 0,
      minWidth: 120,
      fontWeight: 700,
    },
  },
  tableEditPadding: {paddingTop: 10, paddingBottom: 10},
  tableIcons: {
    marginRight: 10,
    cursor: 'pointer',
  },
  linkButton: {
    textTransform: 'none',
    color: COLOR.GREENT_TEXT,
    padding: 0,
    verticalAlign: 'top',
  },
}));

const LocationUsers: React.FC<{
  businessLanguage: {[key: string]: string};
  users: TokenService.BusinessToken['details']['users'];
  rolePrefix: string;
  locationId: string;
  businessId: string;
  businessCountry?: string;
  editable?: boolean;
  currentUser: string;
  addUser?: (
    name: string,
    email: string,
    phone: string,
    invCode?: string,
  ) => void;
  editUser?: (
    userId: string,
    type: string,
    userInfo: {[key: string]: string},
  ) => void;
  inviteUser?: (userId: string, invCode: string) => void;
}> = ({
  businessLanguage,
  users,
  rolePrefix,
  locationId,
  businessId,
  businessCountry,
  editable,
  addUser,
  editUser,
  inviteUser,
  currentUser,
}) => {
  const getEstablishmentUsers = useCallback(
    (businessUsers: TokenService.BusinessToken['details']['users']) => {
      let locale: LocationUserType[] = [];
      businessUsers
        .filter((u) => u.role.includes(`${rolePrefix}-${locationId}`))
        .forEach((u) => {
          locale.push({
            name: u.name,
            phone: u.phone,
            email: u.email,
            status: u.deactivatedDate
              ? businessLanguage.inactive
              : u.userId
              ? businessLanguage.active
              : businessLanguage.pending,
            userId: u.userId,
            edit: false,
            role: u.role,
            canEdit: u.userId !== currentUser,
            invitation: u.invitationCode,
          });
        });
      return locale;
    },
    [],
  );
  const [locationUsers, setLocationUsers] = useState(
    getEstablishmentUsers(users),
  );
  const [userValidation, setUserValidation] = useState<UserValidation[]>(() => {
    let validation: UserValidation[] = [];
    locationUsers.forEach((lu, idx) => {
      validation.push({
        name: {value: lu.name, error: ''},
        phone: {value: lu.phone, error: ''},
        email: {value: lu.email, error: ''},
      });
    });
    return validation;
  });

  const handleLocUserUpdate = useCallback(
    (field: string, index: number) =>
      (e: React.ChangeEvent<{value: string}>) => {
        const copy = [...userValidation];
        copy[index][field] = {value: e.target.value, error: ''};
        setUserValidation(copy);
      },
    [userValidation],
  );

  const classes = useStyle();

  const isUseEstablishmentLabel = rolePrefix === 'establishment';

  useEffect(() => {
    setLocationUsers(getEstablishmentUsers(users));
  }, [users, getEstablishmentUsers]);

  return (
    <>
      <Typography variant="h3" role="label" style={{marginTop: 60}}>
        {isUseEstablishmentLabel
          ? businessLanguage.establishmentUsers
          : businessLanguage.locationUsers}
      </Typography>
      <TableContainer className={classes.table}>
        <Table>
          <TableBody>
            <TableRow key={'tableheader'} className={classes.tableHeader}>
              <TableCell align="left" style={{minWidth: 200}}>
                Name
              </TableCell>
              <TableCell align="left" style={{minWidth: 200}}>
                Phone
              </TableCell>
              <TableCell align="left" style={{minWidth: 200}}>
                Email
              </TableCell>
              <TableCell align="left">Status</TableCell>
              {editable && (
                <TableCell align="left" style={{minWidth: 130}}>
                  Action
                </TableCell>
              )}
            </TableRow>
            {locationUsers.map((row, userIndex) => (
              <TableRow key={`${row.name}_${userIndex}`}>
                <TableCell
                  align="left"
                  className={row.edit ? classes.tableEditPadding : ''}>
                  {row.edit ? (
                    <TextField
                      id="addLocationUserName"
                      variant="outlined"
                      value={userValidation[userIndex]?.name?.value}
                      size={'small'}
                      name={`name-${userIndex}`}
                      onChange={handleLocUserUpdate('name', userIndex)}
                      error={!!userValidation[userIndex]?.name?.error}
                      helperText={userValidation[userIndex]?.name?.error}
                    />
                  ) : (
                    row.name
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  className={row.edit ? classes.tableEditPadding : ''}>
                  {row.edit ? (
                    <>
                      {/*<TextField
                      variant="outlined"
                      value={userValidation[userIndex]?.phone?.value}
                      size={"small"}
                      name={`phone-${userIndex}`}
                      onChange={handleLocUserUpdate('phone', userIndex)}
                      error={!!userValidation[userIndex]?.phone?.error}
                      helperText={userValidation[userIndex]?.phone?.error}
                    />*/}
                      <PhoneInput
                        id="addLocationUserPhone"
                        international
                        defaultCountry="AU"
                        placeholder={businessLanguage.enterPhoneNumber}
                        countryCallingCodeEditable={false}
                        inputComponent={MobileInput}
                        value={userValidation[userIndex]?.phone?.value}
                        countries={CONSTANT.PHONE_COUNTRY_CODES}
                        helperText={userValidation[userIndex]?.phone?.error}
                        size={'small'}
                        onChange={(val) => {
                          const copy = [...userValidation];
                          copy[userIndex].phone = {
                            value: val,
                            error: businessLanguage.validNumber,
                          };
                          setUserValidation(copy);
                        }}
                      />
                    </>
                  ) : (
                    row.phone
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  className={row.edit ? classes.tableEditPadding : ''}>
                  {row.edit ? (
                    <TextField
                      id="addLocationUserEmail"
                      variant="outlined"
                      value={userValidation[userIndex]?.email?.value}
                      size={'small'}
                      name={`email-${userIndex}`}
                      onChange={handleLocUserUpdate('email', userIndex)}
                      error={!!userValidation[userIndex]?.email?.error}
                      helperText={userValidation[userIndex]?.email?.error}
                    />
                  ) : (
                    row.email
                  )}
                </TableCell>
                <TableCell align="left">{row.status}</TableCell>
                {editable && (
                  <TableCell align="left" style={{fontSize: 0}}>
                    {row.edit ? (
                      <Save
                        id="addLocationUserSave"
                        className={classes.tableIcons}
                        onClick={() => {
                          let validationCopy: typeof userValidation =
                            JSON.parse(JSON.stringify(userValidation));
                          let hasError = false;
                          let hasChanges = false;
                          ['name', 'phone', 'email'].forEach((key) => {
                            if (!validationCopy[userIndex][key].value) {
                              validationCopy[userIndex][key].error =
                                businessLanguage.requiredField;
                              hasError = true;
                            } else {
                              if (
                                key === 'phone' &&
                                !isPossiblePhoneNumber(
                                  validationCopy[userIndex][key].value,
                                )
                              ) {
                                //validationCopy[userIndex][key].error = businessLanguage.validNumber;
                                hasError = true;
                              } else if (
                                key === 'email' &&
                                !/^[\w.]+@\w+(\.[A-Za-z]+){1,3}$/.test(
                                  validationCopy[userIndex][key].value,
                                )
                              ) {
                                validationCopy[userIndex][key].error =
                                  businessLanguage.validEmail;
                                hasError = true;
                              }
                            }
                            if (
                              validationCopy[userIndex][key].value !== row[key]
                            ) {
                              hasChanges = true;
                            }
                          });
                          if (hasError) {
                            setUserValidation(validationCopy);
                            return;
                          } else {
                            if (row.role) {
                              if (hasChanges) {
                                editUser(row.userId, '', {
                                  name: userValidation[userIndex].name.value,
                                  email: userValidation[userIndex].email.value,
                                  phone: userValidation[userIndex].phone.value,
                                  invitationCode: row.invitation,
                                });
                              }
                            } else {
                              addUser(
                                userValidation[userIndex].name.value,
                                userValidation[userIndex].email.value,
                                userValidation[userIndex].phone.value,
                                row.invitation,
                              );
                            }
                            const tmp = [...locationUsers];
                            tmp[userIndex].edit = false;
                            setLocationUsers(tmp);
                          }
                        }}
                      />
                    ) : (
                      <EditIcon
                        id="addLocationUserEdit"
                        className={classes.tableIcons}
                        onClick={() => {
                          const tmp = [...locationUsers];
                          tmp[userIndex].edit = true;
                          setLocationUsers(tmp);
                        }}
                      />
                    )}
                    {currentUser !== row.userId && (
                      <>
                        {row.status === '-' ? (
                          <DeleteIcon
                            id="addLocationUserDelete"
                            className={classes.tableIcons}
                            onClick={() => {
                              if (locationUsers[userIndex].userId) {
                              }
                              const tmp = [...locationUsers];
                              tmp.splice(userIndex, 1);
                              const vTmp = [...userValidation];
                              vTmp.splice(userIndex, 1);
                              setLocationUsers(tmp);
                              setUserValidation(vTmp);
                            }}
                          />
                        ) : (
                          <Button
                            id="addLocationUserInvite"
                            className={classes.linkButton}
                            onClick={() => {
                              if (row.status === businessLanguage.pending) {
                                inviteUser(row.userId, row.invitation);
                              } else {
                                editUser(
                                  row.userId,
                                  row.status === businessLanguage.inactive
                                    ? 'activate'
                                    : 'deactivate',
                                  {},
                                );
                              }
                            }}>
                            <u>
                              {row.status === businessLanguage.pending
                                ? businessLanguage.inviteAgain
                                : row.status === businessLanguage.inactive
                                ? 'Activate'
                                : 'Deactivate'}
                            </u>
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {editable && (
        <Box mt={5}>
          <Button
            id={`addUserLocation-${locationId}`}
            className={classes.linkButton}
            onClick={() => {
              setLocationUsers([
                ...locationUsers,
                {
                  name: '',
                  phone: '',
                  email: '',
                  status: '-',
                  edit: true,
                  userId: '',
                  role: '',
                  canEdit: true,
                },
              ]);
              setUserValidation([
                ...userValidation,
                {
                  name: {value: '', error: ''},
                  phone: {value: '', error: ''},
                  email: {value: '', error: ''},
                },
              ]);
            }}>
            <u>
              {isUseEstablishmentLabel
                ? businessLanguage.addEstablishmentUsers
                : businessLanguage.addLocationUsers}
            </u>
          </Button>
        </Box>
      )}
    </>
  );
};

export default LocationUsers;
