import * as ExcelJS from 'exceljs';
import {getCropReportPurpose} from './crop/utils';
import {
  CropReportType,
  CropData,
  CropStockData,
  CropReportData,
  CropReportDetails,
} from './crop/types';

export async function downloadSpreadSheet(
  reportType: CropReportType,
  reportData: CropReportData,
  details: CropReportDetails,
) {
  const monthly = reportData.monthly;
  const yearly = reportData.annual;
  const stock = reportData.stock;
  const workbook = new ExcelJS.Workbook();
  const isScientific = reportType === 'scientific';
  const worksheet = workbook.addWorksheet(capitalize(reportType), {
    properties: {tabColor: {argb: '0000CD00'}},
  });

  /* Create worksheet */
  const monthlyData = getReportData(monthly, reportType);
  const yearlyData = getReportData(yearly, reportType);
  const stockData = getStockData(stock);
  const ws_data = getWorksheetData(
    details,
    monthlyData,
    yearlyData,
    stockData,
    reportType,
  );

  worksheet.addRows(ws_data);
  const reportRowConfig = getReportRowConfig(details.permits.length);

  mergeCells(worksheet, reportType, reportRowConfig);

  // Styling
  const reportTitleRow = worksheet.getRow(reportRowConfig.title);
  reportTitleRow.alignment = {vertical: 'middle', horizontal: 'center'};
  worksheet.getCell(`A${reportRowConfig.title}`).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '008EAADB'},
  };
  reportTitleRow.font = {
    name: 'Calibri',
    size: 24,
  };
  reportTitleRow.height = 30;

  // report subtitle
  const reportSubtitleRow = worksheet.getRow(reportRowConfig.subtitle);
  reportSubtitleRow.height = 40;
  reportSubtitleRow.font = {
    name: 'Calibri',
    size: 11,
  };
  worksheet.getRow(reportRowConfig.subtitle).alignment = {
    vertical: 'middle',
    horizontal: 'center',
  };

  // license details
  worksheet.getRow(reportRowConfig.licenseTitle).font = {
    name: 'Calibri',
    size: 11,
    underline: 'single',
  };

  // monthly report
  const monthlyTitleRow = worksheet.getRow(reportRowConfig.monthlyTitle);
  monthlyTitleRow.alignment = {vertical: 'middle', horizontal: 'center'};
  worksheet.getCell(`A${reportRowConfig.monthlyTitle}`).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '008EAADB'},
  };
  monthlyTitleRow.font = {
    name: 'Calibri',
    size: 24,
  };
  monthlyTitleRow.height = 30;

  // monthly table headers
  styleReportTableGroupHeaders(worksheet, reportRowConfig.monthlyGroupHeader);

  // monthly subheaders
  styleReportTableHeaders(
    worksheet,
    [reportRowConfig.monthlyHeader, reportRowConfig.monthlySubheader],
    60,
    isScientific,
  );

  // Not Applicable cells for rollover
  [`F${reportRowConfig.rollover}`, `I${reportRowConfig.rollover}`].forEach(
    (c) => {
      const cell = worksheet.getCell(c);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '00A5A5A5'},
      };
    },
  );

  // Month & THC Column
  worksheet.getColumn('A').width = 15;
  worksheet.getColumn('A').alignment = {vertical: 'middle'};
  worksheet.getColumn('B').width = 14;

  // Montly report border
  styleReportTableBorder(worksheet, {
    row: {
      start: reportRowConfig.monthlyGroupHeader,
      end: reportRowConfig.monthlyGroupHeader + 28,
    },
    col: {
      start: 'A',
      end: isScientific ? 'I' : 'J',
    },
  });

  // Annual report
  const annualTitleRow = worksheet.getRow(reportRowConfig.annualTitle);
  annualTitleRow.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  worksheet.getCell(`A${reportRowConfig.annualTitle}`).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '008EAADB'},
  };
  annualTitleRow.font = {
    name: 'Calibri',
    size: 24,
  };
  annualTitleRow.height = 40;

  // annual table header
  styleReportTableGroupHeaders(worksheet, reportRowConfig.annualGroupHeader);
  // annual table subheaders
  styleReportTableHeaders(
    worksheet,
    [reportRowConfig.annualHeader],
    70,
    isScientific,
  );

  // annual table borders
  styleReportTableBorder(worksheet, {
    row: {
      start: reportRowConfig.annualGroupHeader,
      end: reportRowConfig.annualGroupHeader + 3,
    },
    col: {
      start: 'A',
      end: isScientific ? 'I' : 'J',
    },
  });

  // stock reconcilation table
  worksheet.getRow(reportRowConfig.stockGroupHeader).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  // Group headers
  worksheet.getRow(reportRowConfig.stockGroupHeader).height = 20;
  const stockTableTitleCell = worksheet.getCell(
    `C${reportRowConfig.stockGroupHeader}`,
  );
  stockTableTitleCell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '00833C0B'},
  };
  stockTableTitleCell.font = {
    name: 'Calibri',
    size: 18,
    color: {
      argb: '00FFFFFF',
    },
  };
  // stock reconcilation table headers
  worksheet.getRow(reportRowConfig.stockHeader).height = 30;
  worksheet.getRow(reportRowConfig.stockHeader).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  [
    `C${reportRowConfig.stockHeader}`,
    `D${reportRowConfig.stockHeader}`,
  ].forEach((c) => {
    worksheet.getCell(c).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: '00F7CAAC'},
    };
  });
  worksheet.getCell(`E${reportRowConfig.stockHeader}`).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '00C55B10'},
  };
  // stock table borders
  styleReportTableBorder(worksheet, {
    row: {
      start: reportRowConfig.stockGroupHeader,
      end: reportRowConfig.stockGroupHeader + 3,
    },
    col: {
      start: 'A',
      end: 'E',
    },
  });

  // legend
  worksheet.getColumn('K').width = 25;

  // download
  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${details.licenseNumber}_${details.siteId}_${capitalize(reportType)}_${details.year}.xlsx`;
  downloadXlsx(buffer, filename);
}

type ReportRowConfig = ReturnType<typeof getReportRowConfig>;
function getReportRowConfig(permitLength: number) {
  const offset = permitLength < 5 ? 0 : permitLength - 5 + 1;
  return {
    title: 1,
    subtitle: 2,
    licenseTitle: 3,
    licenseHolder: 4,
    licenseNumber: 5,
    sideId: 6,
    reportingYear: 7,
    monthlyTitle: 9 + offset,
    monthlyGroupHeader: 10 + offset,
    monthlyHeader: 11 + offset,
    monthlySubheader: 12 + offset,
    monthlyData: 13 + offset,
    rollover: 13 + offset,
    annualTitle: 40 + offset,
    annualGroupHeader: 41 + offset,
    annualHeader: 42 + offset,
    annualData: 43 + offset,
    stockGroupHeader: 47 + offset,
    stockHeader: 48 + offset,
    stockData: 49 + offset,
  };
}

function getReportData(data: Array<CropData>, type: CropReportType) {
  const isScientific = type === 'scientific';
  const result: Array<Array<any>> = data.map((row) => {
    const period = row.year ?? row.month;
    const isRollover = period.toLowerCase().includes('rollover');
    const cells = [
      period,
      row.type,
      row.generatedGenetic ?? 0,
      row.generatedProduction ?? 0,
      row.generatedExternal ?? 0,
      isRollover ? 'Not applicable' : row.transferredAnotherHolder ?? 0,
      isRollover ? 'Not applicable' : row.transferredAnotherSite ?? 0,
    ];

    if (isScientific) {
      return cells.concat([
        isRollover ? 'Not applicable' : row.harvestedSuccess ?? 0,
        isRollover ? 'Not applicable' : row.harvestedLoss ?? 0,
      ]);
    }
    return cells.concat([
      isRollover ? 'Not applicable' : row.transferExported ?? 0, //scientific dont have this
      isRollover ? 'Not applicable' : row.harvestedSuccess ?? 0,
      isRollover ? 'Not applicable' : row.harvestedLoss ?? 0,
    ]);
  });

  return result;
}

function mergeCells(
  worksheet: ExcelJS.Worksheet,
  type: CropReportType,
  reportRowConfig: ReportRowConfig,
) {
  const isScientific = type === 'scientific';
  const titleCols = isScientific ? ['A', 'K'] : ['A', 'L'];
  const tableTitleCols = isScientific ? ['A', 'I'] : ['A', 'J'];
  const transferCols = isScientific ? ['F', 'G'] : ['F', 'H'];
  const harvestCols = isScientific ? ['H', 'I'] : ['I', 'J'];
  const monthlyHeaderRows = [
    reportRowConfig.monthlyHeader,
    reportRowConfig.monthlyHeader + 1,
  ]; // month header
  const monthlyRolloverRows = [
    reportRowConfig.rollover,
    reportRowConfig.rollover + 1,
  ];

  worksheet.mergeCells(
    `${titleCols[0]}${reportRowConfig.title}:${titleCols[1]}${reportRowConfig.title}`,
  ); // title
  worksheet.mergeCells(
    `${titleCols[0]}${reportRowConfig.subtitle}:${titleCols[1]}${reportRowConfig.subtitle}`,
  ); // subtitle
  worksheet.mergeCells(
    `A${reportRowConfig.licenseTitle}:C${reportRowConfig.licenseTitle}`,
  ); // license
  worksheet.mergeCells(
    `${tableTitleCols[0]}${reportRowConfig.monthlyTitle}:${tableTitleCols[1]}${reportRowConfig.monthlyTitle}`,
  ); // monthly
  worksheet.mergeCells(
    `C${reportRowConfig.monthlyGroupHeader}:E${reportRowConfig.monthlyGroupHeader}`,
  ); // generation
  worksheet.mergeCells(
    `${transferCols[0]}${reportRowConfig.monthlyGroupHeader}:${transferCols[1]}${reportRowConfig.monthlyGroupHeader}`,
  ); // transfers
  worksheet.mergeCells(
    `${harvestCols[0]}${reportRowConfig.monthlyGroupHeader}:${harvestCols[1]}${reportRowConfig.monthlyGroupHeader}`,
  ); // harvest

  worksheet.mergeCells(`A${monthlyHeaderRows[0]}:A${monthlyHeaderRows[1]}`); // month

  worksheet.mergeCells(`B${monthlyHeaderRows[0]}:B${monthlyHeaderRows[1]}`); // type
  // monthly generation
  worksheet.mergeCells(`C${monthlyHeaderRows[0]}:C${monthlyHeaderRows[1]}`); // genetic
  worksheet.mergeCells(`D${monthlyHeaderRows[0]}:D${monthlyHeaderRows[1]}`); // production
  worksheet.mergeCells(`E${monthlyHeaderRows[0]}:E${monthlyHeaderRows[1]}`); // external

  // rollover
  worksheet.mergeCells(
    `${transferCols[0]}${monthlyRolloverRows[0]}:${transferCols[1]}${monthlyRolloverRows[1]}`,
  ); // transfer
  worksheet.mergeCells(
    `${harvestCols[0]}${monthlyRolloverRows[0]}:${harvestCols[1]}${monthlyRolloverRows[1]}`,
  ); // harvest

  // months data
  mergeCellRows({
    worksheet: worksheet,
    offset: reportRowConfig.monthlyData,
    col: 'A',
    size: 26,
  });

  // Annual
  worksheet.mergeCells(
    `${tableTitleCols[0]}${reportRowConfig.annualTitle}:${tableTitleCols[1]}${reportRowConfig.annualTitle}`,
  ); // Annual report title
  worksheet.mergeCells(
    `C${reportRowConfig.annualGroupHeader}:E${reportRowConfig.annualGroupHeader}`,
  ); // generation
  worksheet.mergeCells(
    `${transferCols[0]}${reportRowConfig.annualGroupHeader}:${transferCols[1]}${reportRowConfig.annualGroupHeader}`,
  ); // transfer
  worksheet.mergeCells(
    `${harvestCols[0]}${reportRowConfig.annualGroupHeader}:${harvestCols[1]}${reportRowConfig.annualGroupHeader}`,
  ); // harvest
  mergeCellRows({
    worksheet: worksheet,
    offset: reportRowConfig.annualData,
    col: 'A',
    size: 2,
  });
  worksheet.mergeCells(
    `C${reportRowConfig.stockGroupHeader}:E${reportRowConfig.stockGroupHeader}`,
  ); // stock group title
}

function mergeCellRows(props: {
  worksheet: ExcelJS.Worksheet;
  offset: number;
  col: string;
  size: number;
}) {
  for (let i = 0; i < props.size; i = i + 2) {
    const start = i + props.offset;
    const end = start + 1;
    props.worksheet.mergeCells(`${props.col}${start}:${props.col}${end}`);
  }
}

function getStockData(data: Array<CropStockData>) {
  return data.map((row) => [
    '',
    row.type,
    row.generated ?? 0,
    row.processed ?? 0,
    row.unaccounted ?? 0,
  ]);
}

function downloadXlsx(buffer: any, filename: string) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const blob = new Blob([buffer], {type: fileType});
  //IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    //In FF link must be added to DOM to be clicked
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function nextChar(c: string) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

function styleReportTableGroupHeaders(
  worksheet: ExcelJS.Worksheet,
  row: number,
) {
  // monthly plant generation
  worksheet.getRow(row).height = 30;
  worksheet.getRow(row).alignment = {vertical: 'middle', horizontal: 'center'};

  const monthlyPlantGenerationCell = worksheet.getCell(`C${row}`);
  monthlyPlantGenerationCell.font = {
    name: 'Calibri',
    size: 18,
  };
  monthlyPlantGenerationCell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '00A8D08D'},
  };

  // monthly plant transfer
  const monthlyPlantTransferCell = worksheet.getCell(`F${row}`);
  monthlyPlantTransferCell.font = {
    name: 'Calibri',
    size: 18,
  };
  monthlyPlantTransferCell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '00BE9001'},
  };

  // monthly plant harvest
  const monthlyPlantHarvestCell = worksheet.getCell(`I${row}`);
  monthlyPlantHarvestCell.font = {
    name: 'Calibri',
    size: 18,
  };
  monthlyPlantHarvestCell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '002D75B5'},
  };
}

function styleReportTableHeaders(
  worksheet: ExcelJS.Worksheet,
  rows: Array<number>,
  height: number,
  isScientific: boolean,
) {
  const monthlyHeaderRow = worksheet.getRow(rows[0]);
  monthlyHeaderRow.height = height;
  monthlyHeaderRow.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'].forEach((c) => {
    const col = worksheet.getColumn(c);
    col.alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
    col.width = 15;
  });
  // generation
  ['C', 'D', 'E']
    .map((c) => `${c}${rows[0]}`)
    .forEach((c) => {
      const cell = worksheet.getCell(c);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '00E2EFD9'},
      };
    });
  // transfer, scientific - F11, G11
  const transferCols = isScientific ? ['F', 'G'] : ['F', 'G', 'H'];
  const transferIds = transferCols.flatMap((c) => rows.map((r) => `${c}${r}`));
  transferIds.forEach((c) => {
    const cell = worksheet.getCell(c);
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: '00FFE598'},
    };
  });
  // harvest, scientific - H11, I11
  const harvestCols = isScientific ? ['H', 'I'] : ['I', 'J'];
  const harvestIds = harvestCols.flatMap((c) => rows.map((r) => `${c}${r}`));
  harvestIds.forEach((c) => {
    const cell = worksheet.getCell(c);
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: '009CC2E5'},
    };
  });
}

type SpreadSheetRange = {
  row: {start: number; end: number};
  col: {start: string; end: string};
};

function styleReportTableBorder(
  worksheet: ExcelJS.Worksheet,
  range: SpreadSheetRange,
) {
  for (let r = range.row.start; r <= range.row.end; r++) {
    for (
      let c = range.col.start;
      c.charCodeAt(0) <= range.col.end.charCodeAt(0);
      c = nextChar(c)
    ) {
      if ((c === 'A' || c === 'B') && r === range.row.start) {
        continue;
      }
      const cellId = `${c}${r}`;
      const cell = worksheet.getCell(cellId);
      cell.border = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'},
      };
    }
  }
}

function capitalize(word: string) {
  return word[0].toUpperCase() + word.substring(1);
}

function reportTitle(type: CropReportType) {
  return `Cultivation of Cannabis Plants for ${capitalize(type)} Use`;
}

function reportSubtitle(type: CropReportType) {
  const usage =
    type.toLowerCase() === 'scientific'
      ? 'scientific or research purposes'
      : 'medicinal use';
  return `Use the 'Crops - ${capitalize(type)}' tab to report all plants generated for ${usage}.`;
}

function reportHeaderRow(type: CropReportType) {
  // scientific only 2 rows
  let row = [
    '',
    '',
    'Plant generation',
    'Plant generation',
    'Plant generation',
    'Plant transfers',
    'Plant transfers',
    'Plant transfers',
    'Plant harvesting',
    'Plant harvesting',
  ];
  if (type.toLowerCase() === 'scientific') {
    const index = row.indexOf('Plant transfers');
    row = row.filter((r, i) => i !== index);
  }
  return row;
}

function reportSubheaderRow(type: CropReportType, isAnnual?: boolean) {
  const purpose = getCropReportPurpose(type);
  let row = [
    isAnnual ? 'Year' : 'Month',
    'Type',
    // generation
    "Number of plants generated for genetic stock purposes (ie. 'Mothers')",
    'Number of new plants propagated / created for production purpose',
    'Number of plants obtained from an external / different site',
    // Transfer
    'Total plants sold / transferred to another ND Act license holder',
    'Total plants transferred to another site on the same ND Act license',
    'Total plants exported',
    //harvest
    `Number of plants harvested for ${purpose} purposes`,
    'Total number of plants destroyed*/ crop loss (excludes waste)',
  ];
  if (type.toLowerCase() === 'scientific') {
    row = row.filter((r) => r !== 'Total plants exported');
  }
  return row;
}

function reportSubheaderLabelRow(type: CropReportType) {
  let row = ['', '', '', '', '', 'C1', 'C2', 'P6', 'N/A', 'Onsite / C3'];
  if (type.toLowerCase() === 'scientific') {
    row = row.filter((r) => r !== 'P6');
  }
  return row;
}

function getLicenseDetailsData(details: CropReportDetails) {
  let result: Array<Array<any>> = [];
  const maxLength = details.permits.length > 4 ? details.permits.length : 4;
  for (let i = 0; i < maxLength; i++) {
    const permit = details.permits[i];
    const permitData = [
      permit?.permitNumber ?? '',
      permit?.startDate ?? '',
      permit?.expiryDate ?? '',
    ];
    if (i === 0) {
      result.push([
        'License holder name:',
        details.licenseHolderName,
        '',
        '',
        '',
        ...permitData,
        '',
        '',
        'Low THC= ≤1% THC',
      ]);
    } else if (i === 1) {
      result.push([
        'License number:',
        details.licenseNumber,
        '',
        '',
        '',
        ...permitData,
        '',
        '',
        'High THC= >1% THC',
      ]);
    } else if (i === 2) {
      result.push(['Site ID:', details.siteId, '', '', '', ...permitData]);
    } else if (i === 3) {
      result.push(['Reporting year:', details.year, '', '', '', ...permitData]);
    } else {
      result.push(['', '', '', '', '', ...permitData]);
    }
  }
  return result;
}

function getWorksheetData(
  details: CropReportDetails,
  monthlyData: Array<any>,
  yearlyData: Array<any>,
  stockData: Array<any>,
  type: CropReportType,
) {
  const ws_data = [
    // A1
    [reportTitle(type)],
    [reportSubtitle(type)],
    // license details
    [
      'License holder details',
      'License holder details',
      'License holder details',
      '',
      '',
      'Permit Number',
      'Permit start date',
      'Permit end date',
    ],
    ...getLicenseDetailsData(details),
    //
    [''],
    ['Monthly reports'],
    reportHeaderRow(type),
    reportSubheaderRow(type),
    reportSubheaderLabelRow(type),
  ]
    .concat(monthlyData)
    .concat([
      [''],
      [
        {
          richText: [
            {font: {size: 23, name: 'Calibri'}, text: 'Annual report\n'},
            {
              font: {size: 12, name: 'Calibri'},
              text: '(excludes rollover stock)',
            },
          ],
        },
      ] as any,
      reportHeaderRow(type),
      reportSubheaderRow(type),
    ])
    .concat(yearlyData)
    .concat([
      [''], // new line
      [''],
    ])
    .concat([
      // stock
      ['', '', 'Stock reconciliation'],
      [
        '',
        'Type',
        'Total plants generated',
        'Total plants processed',
        'Plants not accounted for',
      ],
    ])
    .concat(stockData);
  return ws_data;
}
