import React, {useEffect, useMemo, useState} from 'react';
import {CASL} from '@aglive/frontend-core';
import CONSTANT from 'src/config/constant';
import {useAppSelector, useAppDispatch} from 'src/utils/hooks';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import BusinessProfile from 'src/container/profile/BusinessProfile';
import EditBusinessProfile from 'src/container/profile/EditBusinessProfile';
import BrandProfile from 'src/container/brand/BrandLibrary/BrandLibrary';
import CreateBrand from 'src/container/brand/BrandProfile/CreateBrand';
import AssetInduction from 'src/container/asset/assetInduction';
import AssetInductionLibrary from 'src/container/asset/AssetInductionLibrary';
import ManageAsset from 'src/container/asset/ManageAsset';
import ManageAssetByCSV from 'src/container/asset/ManageAssetByCSV';
import AVPreSalesReport from 'src/container/verified/AVPreSalesReport';
import MyModal, {Message} from 'src/presentation/Modal';
import CodeLibrary from 'src/container/code/CodeLibrary';
import SecurityCodeLibrary from 'src/container/code/SecurityCode/SecurityCodeLibrary/SecurityCodeLibrary';
import UsedCodes from 'src/container/code/UsedCodes';
// Asset Profile
import AssetProfileLibrary from 'src/container/assetProfile/AssetProfileLibrary';
import CreateAssetProfile from 'src/container/assetProfile/CreateAssetProfile';
import ViewAssetProfile from 'src/container/assetProfile/ViewAssetProfile';
import ArchivedAssetProfiles from 'src/container/assetProfile/ArchivedAssetProfiles';
import GenerateAssets from 'src/container/code/GenerateAssets';
import Spinner from 'src/presentation/Spinner';
import ViewBrand from 'src/container/brand/BrandProfile/ViewBrand';
import UserLibrary from 'src/container/add/UserLibrary';
import MoveActivity from 'src/container/asset/MoveActivity';
import CreateMove from 'src/container/asset/CreateMove';
import {
  CreateGroup,
  AddToGroup,
  RemoveFromGroup,
} from 'src/container/group/CreateGroup';

import SiteLibrary from 'src/container/site/SiteLibrary';
import CreateSite from 'src/container/site/CreateSite';
import EditSite from 'src/container/site/EditSite';
import ViewSite from 'src/container/site/ViewSite';

import Sidebar from './presentation/Sidebar';
import Auth from 'src/container/auth';
import QRLogin from 'src/container/auth/QRLogin';
import SsoLogin from 'src/container/auth/SsoLogin';

import VerifiedActions from 'src/container/verified/VerifiedActions';
import AiJoining from 'src/container/verified/AiJoining';
import ViewAnimalProfile from 'src/container/verified/ViewAnimalProfile';
import ViewSireProfile from 'src/container/verified/ViewSireProfile';
import EditSire from 'src/container/verified/EditSire';
import AnimalProfile from 'src/container/verified/AnimalProfile';
import Treatment from 'src/container/verified/Treatment';
import Pregnancy from 'src/container/verified/Pregnancy';
import AngusVerifiedDetails from 'src/container/verified/AngusVerifiedDetails';
import OrderTags from 'src/container/verified/OrderTags';
import RecordAnimals from 'src/container/verified/RecordAnimals';
import MobDetails from 'src/container/verified/MobDetails';
import EditAnimal from 'src/container/verified/EditAnimal';
import RecordSires from 'src/container/verified/RecordSires';
import SireGroups from 'src/container/verified/SireGroups';
import SireGroupDetails, {
  EditGroupName,
} from 'src/container/verified/SireGroupDetails';
import AddSireToGroup from 'src/container/verified/AddSireToGroup';
import AddAnimalToMob from 'src/container/verified/AddAnimalToMob';
import Home from 'src/container/home';
import UserProfile from 'src/container/profile/UserProfile';
import {
  PluginActive,
  PluginInactive,
  ViewPlugin,
  EditPlugin,
} from 'src/container/profile/Plugins';
import PluginsLibrary from 'src/container/profile/PluginsLibrary';
import CeresTagReport from 'src/container/reports/CeresTagReport';
import TrackAnimal from 'src/container/map/TrackAnimal';
import {toggleModal} from './store/modal/actions';
import {appThunkLogout, initWalletConnect} from './store/auth/actions';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {MODAL_TOGGLE_OFF} from './store/modal/types';
import {WebErrorType} from './utils/error';
import COLOR from './styled/colors';
import ManagementReport from 'src/container/reports/ManagementReport';
import ToolBar from './presentation/ToolBar';
import HealthSupport from 'src/container/HealthSupport';
import MainLanding from 'src/container/promotion/MainLanding';
import PromotionLibrary from 'src/container/promotion/PromotionLibrary';
import CreateAssetPromotion from 'src/container/promotion/CreateAssetPromotion';
import AssetLanding from 'src/container/promotion/AssetLanding';
import ArchivedPromotionLibrary from 'src/container/promotion/ArchivedPromotionLibrary';
import ViewAssetPromotion from 'src/container/promotion/ViewAssetPromotion';
import CreateProductProfile from 'src/container/promotion/ProductProfile/CreateProductProfile';
import CreateProductPromotion from 'src/container/promotion/CreateProductPromotion';
import ViewProductPromotion from 'src/container/promotion/ViewProductPromotion';
import ProductProfileLibrary from 'src/container/promotion/ProductProfileLibrary';
import ArchivedProductProfileLibrary from 'src/container/promotion/ArchivedProductProfileLibrary';
import CodeMainLanding from 'src/container/code/MainLanding/index';
import EditProductProfile from 'src/container/promotion/ProductProfile/EditProductProfile';
import ViewProductProfile from 'src/container/promotion/ProductProfile/ViewProductProfile';
import EditBrand from 'src/container/brand/BrandProfile/EditBrand';
import GenerateSecurityCode from 'src/container/code/SecurityCode/GenerateCodes';
import UsedSecurityCodes from 'src/container/code/SecurityCode/UsedSecurityCodes';
import ArchivedBrandLibrary from 'src/container/brand/ArchivedBrandLibrary/ArchivedBrandLibrary';
import EditCode from 'src/container/code/SecurityCode/EditCodes/EditCode';
import ActivitiesReport from 'src/container/reports/ActivitiesReport';
import SingleUser from 'src/container/add/SingleUser';
import ConsigneeLibrary from 'src/container/add/ConsigneeLibrary';
import SingleConsignee from 'src/container/add/SingleConsignee';
import {
  CreateForm,
  DuplicateForm,
  EditForm,
  ViewForm,
} from 'src/container/asset/FormTemplate';
import FormLibrary from 'src/container/asset/FormLibrary';
import ConsignmentLibrary from 'src/container/consignment/consignmentLibrary';
import {
  CreateConsignment,
  EditConsignment,
  DuplicateConsignment,
} from 'src/container/consignment/consignment';
import ViewConsignment from 'src/container/consignment/ViewConsignment';
import AddCeresTag from 'src/container/add/AddCeresTag';
import GroupLibrary from 'src/container/group/GroupLibrary';
import {ViewGroup, EditGroup} from 'src/container/group/Group';
import SelectIndustryType from 'src/container/auth/SelectIndustryType';
import CropReports from 'src/container/reports/CropReports';
import ContactLibrary from 'src/container/add/contact/ContactLibary';
import ContactDetails from 'src/container/add/contact/ContactDetails';
import NewContact from 'src/container/add/contact/NewContact';

export default function RootRoute() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isSpinnerOpen = useAppSelector((state) => state.spinner.isOpen);
  const {isOpen: isModalOpen, ...modalState} = useAppSelector(
    (state) => state.modal,
  );
  const modalConfig: Message = modalState; // TS could not infer the correct type

  return (
    <Router>
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/">
          <Auth />
        </Route>
        <Route exact path="/qr-login">
          <QRLogin />
        </Route>
        <Route exact path="/select-industry">
          <SelectIndustryType />
        </Route>
        <Route path="/sso-login">
          <SsoLogin />
        </Route>
        <PrivateRoute isLoggedIn={isLoggedIn} path="/private">
          <ProtectedPages />
        </PrivateRoute>
      </Switch>
      <MyModal
        isOpen={isModalOpen}
        closeHandler={() => dispatch({type: MODAL_TOGGLE_OFF})}
        message={modalConfig}
      />
      <Spinner isOpen={isSpinnerOpen} />
    </Router>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  angusRoot: {
    background: COLOR.AA_BLACK,
  },
  sidebar: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: '200px',
  },
  content: {
    backgroundColor: theme.palette.secondary.main,
    margin: '5px 32px 0 32px',
    padding: '48px',
    borderRadius: '20px 20px 0 0',
    minHeight: '100vh',
    width: 'calc(100% - 64px)',
    flex: 1,
  },
  browserAlert: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '15px 30px',
    width: '100%',
  },
}));

function ProtectedPages() {
  const businessProfileData = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const businessPlugins = useAppSelector((state) => state.user.plugins);

  const isPlants = businessProfileData.industryType === 'PLANTS';
  const isAnimals = businessProfileData.industryType === 'ANIMALS';
  const isProducts = businessProfileData.industryType === 'PRODUCTS';
  const isMackasShowcase = useMemo(
    () =>
      !!businessPlugins?.find(
        (p) =>
          p.status === 'activated' &&
          p.name === CONSTANT.MACKAS_SHOWCASE_PLUGIN,
      ),
    [businessPlugins],
  );

  const allowProductProfile = isMackasShowcase || isProducts;

  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );
  const classes = useStyles();
  const {path} = useRouteMatch();
  const currentURL = useLocation();

  // For managing user permissions
  const userRole = useAppSelector((state) => state.user.userProfileData?.role);
  const [loadedData, setLoadedData] = useState(false)
  const ability = CASL.useAbility(CASL.AbilityContext);

  useEffect(() => {
    // Update permissions based on user role
    if (userRole) {
      CASL.updateAbility(ability, userRole);
    }
    setLoadedData(true)
  }, [userRole, ability]);

  return (
    <Grid
      container
      wrap="nowrap"
      className={
        isAngus && currentURL.pathname.includes('/verified')
          ? classes.angusRoot
          : classes.root
      }>
      {/* sidebar on the left*/}
      <Sidebar key={`${ability.rules.length}`} />

      {/* main content */}
      <Grid item container xs={10} direction="column">
        <ToolBar />
        <Grid item container className={classes.content}>
          <Switch>
            <Route exact path={`${path}/verified`}>
              <VerifiedActions />
            </Route>
            <Route exact path={`${path}/verified/recordSires`}>
              <RecordSires />
            </Route>
            <Route exact path={`${path}/verified/recordSires/edit-sire`}>
              <EditSire />
            </Route>
            <Route exact path={`${path}/verified/aiJoining`}>
              <AiJoining />
            </Route>
            <Route exact path={`${path}/verified/sireGroups`}>
              <SireGroups />
            </Route>
            <Route exact path={`${path}/verified/sireGroups/details`}>
              <SireGroupDetails />
            </Route>
            <Route
              exact
              path={`${path}/verified/sireGroups/details/editGroupName`}>
              <EditGroupName />
            </Route>
            <Route exact path={`${path}/verified/sireGroups/details/add-sire`}>
              <AddSireToGroup />
            </Route>
            <Route exact path={`${path}/verified/recordAnimals`}>
              <RecordAnimals />
            </Route>
            <Route exact path={`${path}/verified/recordAnimals/mob-details`}>
              <MobDetails />
            </Route>
            <Route
              exact
              path={`${path}/verified/recordAnimals/mob-details/add-animal`}>
              <AddAnimalToMob />
            </Route>
            <Route exact path={`${path}/verified/recordAnimals/edit-animal`}>
              <EditAnimal />
            </Route>
            <Route exact path={`${path}/verified/orderTags`}>
              <OrderTags />
            </Route>
            <Route exact path={`${path}/verified/animalProfile`}>
              <AnimalProfile />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/treatment`}>
              <Treatment />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/pregnancy`}>
              <Pregnancy />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/angus-verified`}>
              <AngusVerifiedDetails />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/view-animal`}>
              <ViewAnimalProfile />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/view-sire`}>
              <ViewSireProfile />
            </Route>
            <Route exact path={`${path}/verified/avpresale`}>
              <AVPreSalesReport />
            </Route>
            <Route exact path={`${path}/profile/users`}>
              <UserProfile view={true} />
            </Route>
            <Route exact path={`${path}/profile/users/edit`}>
              <UserProfile />
            </Route>
            <Route exact path={`${path}/profile/businessProfile`}>
              <BusinessProfile />
            </Route>
            {ability.can('update', 'business') && (
              <Route exact path={`${path}/profile/businessProfile/edit`}>
                <EditBusinessProfile />
              </Route>
            )}
            {(ability.can('update', 'establishment') || ability.can('update', 'location')) && (
              <Route
                exact
                path={`${path}/profile/businessProfile/editEstablishment`}>
                <EditBusinessProfile isEstablishment={true} />
              </Route>
            )}
            <Route exact path={`${path}/profile/brand`}>
              <BrandProfile />
            </Route>
            <Route exact path={`${path}/profile/brand/archived`}>
              <ArchivedBrandLibrary />
            </Route>
            {ability.can('create', 'brand') && (
              <Route exact path={`${path}/profile/brand/new`}>
                <CreateBrand />
              </Route>
            )}
            {ability.can('update', 'brand') && (
              <Route exact path={`${path}/profile/brand/edit/:id`}>
                <EditBrand />
              </Route>
            )}
            <Route exact path={`${path}/profile/brand/view/:id`}>
              <ViewBrand />
            </Route>
            {CONSTANT.ENABLE_PLUGIN && (
              <Route exact path={`${path}/profile/plugins`}>
                <PluginActive />
              </Route>
            )}
            <Route exact path={`${path}/profile/plugins/inactive`}>
              <PluginInactive />
            </Route>
            <Route exact path={`${path}/profile/plugins/add`}>
              <PluginsLibrary />
            </Route>
            <Route exact path={`${path}/profile/plugins/view/:name`}>
              <ViewPlugin />
            </Route>
            <Route exact path={`${path}/profile/plugins/edit/:name`}>
              <EditPlugin />
            </Route>
            {ability.can('update', 'asset') && (
              <Route exact path={`${path}/activity/manage`}>
                <ManageAsset />
              </Route>
            )}
            <Route exact path={`${path}/activity/move`}>
              <MoveActivity />
            </Route>
            <Route exact path={`${path}/activity/move/create`}>
              <CreateMove />
            </Route>
            {ability.can('update', 'asset') && (
              <Route exact path={`${path}/activity/csv`}>
                <ManageAssetByCSV />
              </Route>
            )}
            <Route exact path={`${path}/activity/group`}>
              <GroupLibrary />
            </Route>
            {ability.can('update', 'group') && (
              <Route exact path={`${path}/activity/group/new`}>
                <CreateGroup />
              </Route>
            )}

            <Route exact path={`${path}/activity/group/view/:id`}>
              <ViewGroup />
            </Route>

            {ability.can('update', 'group') && (
              <Route exact path={`${path}/activity/group/edit/:id`}>
                <EditGroup />
              </Route>
            )}

            {ability.can('update', 'group') && (
              <Route exact path={`${path}/activity/group/add/:gid/`}>
                <AddToGroup type="add" />
              </Route>
            )}
            {ability.can('update', 'group') && (
              <Route exact path={`${path}/activity/group/remove/:gid/`}>
                <RemoveFromGroup type="remove" />
              </Route>
            )}
            <Route exact path={`${path}/activity/consignment`}>
              <ConsignmentLibrary />
            </Route>
            {ability.can('create', 'consignment') && (
              <Route exact path={`${path}/activity/consignment/new`}>
                <CreateConsignment />
              </Route>
            )}
            {ability.can('update', 'consignment') && (
              <Route exact path={`${path}/activity/consignment/edit/:docId`}>
                <EditConsignment />
              </Route>
            )}
            {ability.can('create', 'consignment') && (
              <Route
                exact
                path={`${path}/activity/consignment/duplicate/:docId`}>
                <DuplicateConsignment />
              </Route>
            )}
            <Route exact path={`${path}/activity/consignment/view/:id`}>
              <ViewConsignment />
            </Route>

            <Route exact path={`${path}/register/asset`}>
              {!isPlants ? <AssetInductionLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/register/asset/new`}>
              {!isPlants ? <AssetInduction /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/register/site`}>
              <SiteLibrary />
            </Route>
            {ability.can('create', 'site') && (
              <Route exact path={`${path}/register/site/new`}>
                <CreateSite />
              </Route>
            )}
            <Route exact path={`${path}/register/site/:agliveToken`}>
              <ViewSite />
            </Route>
            {ability.can('update', 'site') && (
              <Route exact path={`${path}/register/site/edit/:agliveToken`}>
                <EditSite />
              </Route>
            )}
            <Route exact path={`${path}/codes`}>
              <CodeMainLanding />
            </Route>
            <Route exact path={`${path}/codes/securityCodes`}>
              <SecurityCodeLibrary />
            </Route>
            <Route exact path={`${path}/codes/securityCodes/new`}>
              <GenerateSecurityCode />
            </Route>
            <Route exact path={`${path}/codes/securityCodes/used`}>
              <UsedSecurityCodes />
            </Route>
            <Route exact path={`${path}/codes/securityCodes/edit/:entryId`}>
              <EditCode />
            </Route>
            <Route exact path={`${path}/codes/generate`}>
              <CodeLibrary />
            </Route>
            <Route exact path={`${path}/codes/generate/new`}>
              <GenerateAssets />
            </Route>
            <Route exact path={`${path}/codes/generate/used/:docId?`}>
              <UsedCodes />
            </Route>
            <Route exact path={`${path}/codes/promotionCodes`}>
              {isProducts ? <PromotionLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/new`}>
              {isProducts ? <CreateProductPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/edit/:id`}>
              {isProducts ? <CreateProductPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/view/:id`}>
              {isProducts ? <ViewProductPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/archived`}>
              {isProducts ? (
                <ArchivedPromotionLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/products`}>
              <MainLanding actionButton />
            </Route>
            <Route exact path={`${path}/products/productProfile`}>
              {allowProductProfile ? (
                <ProductProfileLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/products/productProfile/new`}>
              {allowProductProfile ? (
                <CreateProductProfile />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/products/productProfile/view/:id`}>
              {allowProductProfile ? (
                <ViewProductProfile />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/products/productProfile/edit/:id`}>
              {allowProductProfile ? (
                <EditProductProfile />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/products/productProfile/archived`}>
              {allowProductProfile ? (
                <ArchivedProductProfileLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/assets/assetProfile`}>
              <AssetProfileLibrary />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/new`}>
              <CreateAssetProfile />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/view/:id`}>
              <ViewAssetProfile />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/edit/:id`}>
              <CreateAssetProfile />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/archived`}>
              <ArchivedAssetProfiles />
            </Route>
            {ability.can('read', 'user') && (
            <Route exact path={`${path}/add/users`}>
              <UserLibrary />
            </Route>)}
            {ability.can('create', 'site') && (
              <Route exact path={`${path}/add/users/:id`}>
                <SingleUser key="EditUser" type="edit" />
              </Route>
            )}
            <Route exact path={`${path}/add/users/view/:id`}>
              <SingleUser key="ViewUser" type="view" />
            </Route>
            <Route exact path={`${path}/add/formTemplate`}>
              <FormLibrary />
            </Route>
            {ability.can('create', 'form') && (
              <Route exact path={`${path}/add/formTemplate/new`}>
                <CreateForm />
              </Route>
            )}
            {ability.can('update', 'form') && (
              <Route exact path={`${path}/add/formTemplate/edit/:docId`}>
                <EditForm />
              </Route>
            )}
            <Route exact path={`${path}/add/formTemplate/view/:docId`}>
              <ViewForm />
            </Route>
            {ability.can('create', 'form') && (
              <Route exact path={`${path}/add/formTemplate/duplicate/:docId`}>
                <DuplicateForm />
              </Route>
            )}
            <Route exact path={`${path}/add/consignees`}>
              <ConsigneeLibrary />
            </Route>
            {ability.can('create', 'consignee') && (
              <Route exact path={`${path}/add/consignees/new`}>
                <SingleConsignee key="AddConsignee" />
              </Route>
            )}
            {ability.can('update', 'consignee') && (
              <Route exact path={`${path}/add/consignees/:id`}>
                <SingleConsignee key="EditConsignee" />
              </Route>
            )}
            <Route exact path={`${path}/add/consignees/view/:id`}>
              <SingleConsignee key="ViewConsignee" view={true} />
            </Route>
            <Route exact path={`${path}/add/contacts`}>
              <ContactLibrary />
            </Route>
            <Route exact path={`${path}/add/contacts/view/:id`}>
              <ContactDetails key="ViewContactDetails" type="view" />
            </Route>
            {ability.can('update', 'contact') && (
              <Route exact path={`${path}/add/contacts/edit/:id`}>
                <ContactDetails key="EditContactDetails" type="edit" />
              </Route>
            )}
            {ability.can('update', 'contact') && (
              <Route exact path={`${path}/add/contacts/new`}>
                <NewContact />
              </Route>
            )}
            <Route exact path={`${path}/profile/map`}>
              <TrackAnimal />
            </Route>
            <Route exact path={`${path}/report/crops`}>
              <CropReports />
            </Route>
            <Route exact path={`${path}/report/ceresTagReport`}>
              <CeresTagReport />
            </Route>
            <Route exact path={`${path}/report/:type`}>
              <ManagementReport />
            </Route>
            <Route exact path={`${path}/healthSupport`}>
              <HealthSupport />
            </Route>
            <Route exact path={`${path}/assets`}>
              {isAnimals ? <AssetLanding /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions`}>
              {isAnimals ? <PromotionLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/new`}>
              {isAnimals ? <CreateAssetPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/edit/:id`}>
              {isAnimals ? <CreateAssetPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/view/:id`}>
              {isAnimals ? <ViewAssetPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/archived`}>
              {isAnimals ? (
                <ArchivedPromotionLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            {/* Hidden path for testing */}
            <Route exact path={`${path}/dev-test/report/activities`}>
              <ActivitiesReport />
            </Route>
            <Route exact path={`${path}/add/cerestag`}>
              <AddCeresTag />
            </Route>
            <Route exact path={`${path}/`}>
              <Home />
            </Route>
            {userRole && loadedData && !ability.can('read', 'dashboard') && (
              <Redirect to={`${path}/profile/businessProfile`} />
            )}
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, isLoggedIn, ...rest}) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(initWalletConnect(history)).catch((e) => {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Logout',
          CTAHandler: () => dispatch(appThunkLogout()),
        }),
      );
    });
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={() => (isLoggedIn ? children : <Redirect to="/" />)}
    />
  );
}
