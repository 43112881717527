import {makeStyles} from '@material-ui/core';
import COLOR from '../../../../styled/colors';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  fullWidthSearchBar: {
    marginRight: 0,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
  firstColumnWidth: {
    minWidth: '40%',
    width: '45%',
  },
  plantLimitNote: {
    margin: 'auto',
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '1rem 2rem',
    fontWeight: 'bold'
  }
}));

export default useStyles;
