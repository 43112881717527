import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import {Grid, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {TokenService} from '@aglive/data-model';

import withHeader from '../../../presentation/withHeader';
import {fetchBrands} from '../../../store/brand/actions';
import {getOnePromotion} from '../../../store/promotion/actions';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {processBrandList} from '../CreateAssetPromotion/utils';
import {useStyles} from './styles';
import ProgressBar from '../../../presentation/ProgressBar';
import MyButton from '../../../presentation/button';
import ViewTellYourStory from '../ViewTellYourStory';
import ViewMobDetails from '../ViewMobDetails';
import ViewDisplayPoster from '../ViewDisplayPoster';

const EDIT_ASSET_PROMOTION_PATH = '/private/assets/promotions/edit';
enum CreatePromotionSteps {
  tellYourStory,
  mobDetails,
  display,
}

const ViewAssetPromotion: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {id} = useParams<{id: string}>();

  const userId = useAppSelector((state) => state.auth.wallet);
  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );

  const [currentStep, setCurrentStep] = useState(
    CreatePromotionSteps.tellYourStory,
  );
  const [progressBarPercent, setProgressBarPercent] = useState(0);
  const [assetPromotion, setAssetPromotion] =
    useState<TokenService.AnimalPromotion['details']>();
  const [brandName, setBrandName] = useState('');
  const [brandLogo, setBrandLogo] = useState(null as string | null);

  useEffect(() => {
    fetchBrands(dispatch).then((brandTokens) => {
      if (id) {
        dispatch(getOnePromotion(id, history, 'ANIMALS')).then(
          (promotionToken) => {
            if (promotionToken && promotionToken.details.type === 'ANIMALS') {
              const promotionDetails = promotionToken.details;
              if (brandTokens) {
                const tempBrandList = processBrandList(brandTokens);
                if (
                  tempBrandList?.length > 0 &&
                  promotionDetails.brand.agliveToken
                ) {
                  const brandIndex = tempBrandList.findIndex(
                    (brand) =>
                      brand.agliveToken === promotionDetails.brand.agliveToken,
                  );
                  setBrandName(tempBrandList[brandIndex]?.name || '-');
                  if (promotionDetails.story.logoType === 'brand') {
                    setBrandLogo(tempBrandList[brandIndex]?.logo || null);
                  }
                }
              }
              setAssetPromotion(promotionDetails);
            }
          },
        );
      }
    });
  }, [dispatch, userId, id]);

  const progressBarSteps = [
    {
      label: 'Tell Your Story',
      onClick: () => {
        setProgressBarPercent(0);
        setCurrentStep(CreatePromotionSteps.tellYourStory);
      },
    },
    {
      label: 'Mob Details',
      onClick: () => {
        setProgressBarPercent(50);
        setCurrentStep(CreatePromotionSteps.mobDetails);
      },
    },
    {
      label: 'Display',
      onClick: () => {
        setProgressBarPercent(100);
        setCurrentStep(CreatePromotionSteps.display);
      },
    },
  ];

  const changeStep = (step: number) => {
    setCurrentStep(step);
    setProgressBarPercent((100 / (progressBarSteps.length - 1)) * step);
  };

  return (
    <>
      <Grid container spacing={8} justifyContent="center">
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Promotion Name
          </Typography>
          <Typography variant="h6" role="label" id="PromotionName">
            {assetPromotion?.name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Brand
          </Typography>
          <Typography variant="h6" role="label" id="PromotionBrand">
            {brandName || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <ProgressBar percent={progressBarPercent} steps={progressBarSteps} />
        </Grid>
      </Grid>
      <Grid container className={classes.stepContainer}>
        {currentStep === CreatePromotionSteps.tellYourStory && (
          <ViewTellYourStory
            brandLogo={brandLogo}
            story={assetPromotion?.story}
            mainSectionImageOption={assetPromotion?.story.image ? 'image' : ''}
            isAngus={isAngus}
          />
        )}
        {currentStep === CreatePromotionSteps.mobDetails && (
          <ViewMobDetails mob={assetPromotion.mob} />
        )}
        {currentStep === CreatePromotionSteps.display && (
          <ViewDisplayPoster
            display={assetPromotion.display}
            story={assetPromotion.story}
            footerImageOption={
              assetPromotion?.display.footerImage ? 'image' : ''
            }
            isAngus={isAngus}
            agliveToken={id}
            brandLogo={brandLogo}
          />
        )}
      </Grid>
      <Grid
        container
        justifyContent={currentStep === 0 ? 'flex-end' : 'space-between'}
        alignItems="center"
        className={classes.stepHyperlinkContainer}>
        {currentStep !== 0 && (
          <Grid item>
            <Grid
              container
              alignItems="center"
              onClick={() => {
                changeStep(currentStep - 1);
              }}>
              <ChevronLeftIcon className={classes.chevron} />
              <Typography className={classes.hyperlink}>
                Previous Step
              </Typography>
            </Grid>
          </Grid>
        )}
        {currentStep !== progressBarSteps.length - 1 && (
          <Grid item>
            <Grid
              container
              alignItems="center"
              onClick={() => {
                changeStep(currentStep + 1);
              }}>
              <Typography className={classes.hyperlink} id="NextStep">Next Step</Typography>
              <ChevronRightIcon className={classes.chevron} />
            </Grid>
          </Grid>
        )}
      </Grid>
      {!assetPromotion?.archive && (
        <Grid item container justifyContent="flex-end" spacing={3}>
          <Grid item>
            <MyButton
              text="Edit"
              variant="contained"
              onClick={() => {
                history.push(`${EDIT_ASSET_PROMOTION_PATH}/${id}`);
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'View Promotion',
    margin: 60,
    back: true,
  },
  ViewAssetPromotion,
);
