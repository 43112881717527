import {
  Collapse,
  Grid,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import MyTable from 'src/presentation/Table';
import COLOR from 'src/styled/colors';
import {Link, useRouteMatch} from 'react-router-dom';
import type {
  GmpLicense,
  OdcLicense,
} from '@aglive/data-model/dist/misc/business';
import {TABLE_DATA_EMPTY_STYLE} from '../style';
import { PermitRowView } from './PermitRowView';

const useStyle = makeStyles(() => ({
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
}));

type Labels = {
  license: string;
  licenseNumber: string;
  licenseStartDate: string;
  licenseExpiryDate: string;
  permits: string;
  permitNumber: string;
  permitStarDate: string;
  permitExpiryDate: string;
  permitPlantLimit?: string;
  thcValue?: string;
  addPermit: string;
};

type Props = {
  licenses: OdcLicense[] | GmpLicense[];
  labels: Labels;
  addPermitButton?: boolean;
};

const LicensesView: React.FC<Props> = ({licenses, labels, addPermitButton}) => {
  const {path} = useRouteMatch();
  const classes = useStyle();
  const [licenseCollapses, setLicenseCollapses] = useState(
    new Array(licenses?.length ?? 0).fill(false),
  );
  const handleLicenseCollapse = (index: number) => {
    setLicenseCollapses((prev) =>
      prev.map((collapse, i) => (i === index ? !collapse : collapse)),
    );
  };

  useEffect(() => {
    setLicenseCollapses(new Array(licenses?.length ?? 0).fill(false));
  }, [licenses.length]);

  const tableHeaders = labels.permitPlantLimit
    ? [
        labels.permitNumber,
        labels.thcValue,
        labels.permitStarDate,
        labels.permitExpiryDate,
        labels.permitPlantLimit,
      ]
    : [labels.permitNumber, labels.permitStarDate, labels.permitExpiryDate];

  return (
    <>
      <Grid container item>
        <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
          {labels.license}
        </Typography>
      </Grid>
      {licenses.map((license, index) => (
        <Grid
          item
          xs={12}
          key={`license-${index}`}
          style={{marginBottom: '1.25rem'}}>
          <div className={classes.titleContainer}>
            <ListItem
              style={{paddingLeft: 0}}
              button
              onClick={() => handleLicenseCollapse(index)}>
              <Grid container justifyContent="space-between">
                <Typography variant="h3" role="label">
                  {`${labels.license} ${index + 1}`}
                </Typography>
                {licenseCollapses[index] ? <ExpandLess /> : <ExpandMore />}
              </Grid>
            </ListItem>
          </div>
          <Collapse in={licenseCollapses[index]}>
            <Grid container style={{marginTop: '2rem'}}>
              <Grid
                item
                container
                xs={12}
                direction="column"
                style={{marginBottom: '2rem'}}>
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {labels.licenseNumber}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {license.licenseNumber}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {labels.licenseStartDate}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {moment(license.startDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {labels.licenseExpiryDate}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {moment(license.expiryDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Typography
                variant="h3"
                role="label"
                style={{marginTop: '3.5rem', marginBottom: '2rem'}}>
                {labels.permits}
              </Typography>
              <MyTable
                heads={tableHeaders}
                tableBody={
                  license.permits.map((permit) => 
                  <PermitRowView
                    key={permit.permitId}
                    labels={labels}
                    permit={permit}
                  />
                )
                }
                dataEmptyStyle={TABLE_DATA_EMPTY_STYLE}
              />
              {addPermitButton ? (
                <Grid item style={{paddingTop: '2rem'}}>
                  <Link to={`${path}/edit`} className={classes.hyperlink}>
                    {labels.addPermit}
                  </Link>
                </Grid>
              ) : null}
            </Grid>
          </Collapse>
        </Grid>
      ))}
    </>
  );
};

export default LicensesView;
