import API from '../../config/api';
import CONSTANT from '../../config/constant';

import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal} from '../modal/actions';
import {
  LayerNode,
  FETCH_SITE_TOKENS,
  SiteTokenResponse,
  SiteOptions,
  SiteAssetsResponse,
  UpdatedLinkedSite,
} from './types';
import {callAPI} from '../../utils/network';
import {WebErrorType, WebError} from '../../utils/error';
import {SiteMisc} from '@aglive/data-model';

export async function createNewSite(
  location: string,
  tree: LayerNode,
  region: string,
  propertyType: string,
) {
  const token: SiteTokenResponse['details'] = {
    siteName: tree.name,
    location: location, // .PICAddress,
    layers: tree,
    region,
    propertyType,
  };

  const response = await callAPI({
    url: API.POST.createToken,
    method: 'POST',
    data: {
      tokens: [
        {
          type: CONSTANT.ASSETTYPE.SITE,
          tokenId: '',
          externalIds: {},
          generateAgliveToken: true,
          details: token,
        },
      ],
    },
  });
  return response;
}

export function submitSiteToken(
  tree: LayerNode,
  location: string,
  history: any,
  allowDuplicate?: boolean,
  region?: string,
  propertyType?: string,
): AppThunk<Promise<void>> {
  // locationType
  return async (dispatch, getState) => {
    try {
      if (!tree || !location) throw new WebError('INVALID_INPUT');

      // sanitize input
      const siteTokensResArr = getState().site;
      if (!allowDuplicate) {
        const isDuplicatePIC = siteTokensResArr.find(
          (siteTokenRes) => siteTokenRes.details.location === location,
        );
        if (isDuplicatePIC)
          throw new WebError('INVALID_INPUT', {error: 'Repeated use of PIC'});
      }

      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      await createNewSite(location, tree, region, propertyType);

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Created',
          CTAHandler: history.goBack,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchSiteTokens(): AppThunk<Promise<boolean>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
        user: {userProfileData, businessProfileData},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: SiteMisc.SiteDetail[] = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [CONSTANT.ASSETTYPE.SITE],
          activityType: [],
        },
      });

      const locationList = businessProfileData.location;
      const filterPic = locationList?.find((ll) => {
        return (
          userProfileData.role?.startsWith('location-') &&
          userProfileData.role.replace('location-', '') === ll.locationUniqueId
        );
      });

      dispatch({
        type: FETCH_SITE_TOKENS,
        payload: response.filter((resp) => {
          return !filterPic || filterPic.PICAddress === resp.location;
        }),
      });

      return true;
    } catch (e) {
      if (e.code === 'E_NOT_FOUND') {
        dispatch({
          type: FETCH_SITE_TOKENS,
          payload: [],
        });
      } else {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchCattleTreatment(
  isWarakirri: boolean,
): AppThunk<Promise<Array<any>>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const docResponse = await callAPI({
        url: API.GET.getTreatmentDocument,
        method: 'GET',
        params: {
          type: isWarakirri ? 'warakirriCattleTreatment' : 'cattleTreatment',
          propertyName: 'status',
          value: 'active',
        },
      });

      dispatch({type: SPINNER_TOGGLE_OFF});
      return docResponse;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: e.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export async function modifySiteTokensPIC(
  updatedSiteTokens: Array<UpdatedLinkedSite>,
) {
  if (updatedSiteTokens.length === 0) {
    return;
  }
  const activityTokens = updatedSiteTokens.map((site) => ({
    externalIds: {
      agliveToken: site.agliveToken,
    },
    activities: [
      {
        type: 'updateSiteLayer',
        details: {
          ...site.siteDetails,
          location: site.newPICAddress,
        },
      },
    ],
  }));
  const response = await callAPI({
    url: API.POST.createActivity,
    method: 'POST',
    data: {
      tokens: activityTokens,
    },
  });
  return response;
}

export function modifySiteToken(
  modifiedTree: LayerNode,
  oldSiteToken: SiteTokenResponse,
  selectedLocation: string,
  history,
  region?: string,
  propertyType?: string,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      const token: SiteTokenResponse['details'] = {
        siteName: modifiedTree.name,
        location: selectedLocation,
        layers: modifiedTree,
        region,
        propertyType,
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              externalIds: {
                agliveToken: oldSiteToken.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'updateSiteLayer',
                  details: token,
                },
              ],
            },
          ],
        },
      });

      dispatch(fetchSiteTokens()).then((complete) =>
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Updated',
            CTAHandler: history.goBack,
          }),
        ),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteSiteToken(
  siteTokenRes: SiteTokenResponse,
  history,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: siteTokenRes.type,
              externalIds: {
                agliveToken: siteTokenRes.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'DEL_token',
                  details: siteTokenRes.details,
                },
              ],
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: () => dispatch(fetchSiteTokens()),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export const getSiteOptions =
  (): AppThunk<Promise<SiteOptions>> => async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const res = await fetch(CONSTANT.SITE_OPTIONS_URL);
      return res.json();
    } catch (error) {
      const webError = error as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: webError.title,
          subtitle: webError.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

export const getAssetsBySite = async (
  siteAgliveToken: string,
): Promise<SiteAssetsResponse> => {
  return await callAPI<SiteAssetsResponse>({
    url: `${API.GET.getSiteAssetsByExternalId}/${siteAgliveToken}`,
    method: 'GET',
  });
};

export const fetchAssetsBySite =
  (siteAgliveToken: string): AppThunk<Promise<SiteAssetsResponse>> =>
  async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      return await getAssetsBySite(siteAgliveToken);
    } catch (error) {
      const webError = error as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: webError.title,
          subtitle: 'Fail to fetch assets.',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
