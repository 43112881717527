import {GmpPermit, OdcPermit,} from '@aglive/data-model/dist/misc/business';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableRow,
  TextField,
} from '@material-ui/core';
import {useState} from 'react';
import {StyledTableCell} from 'src/presentation/Table';
import {
  INITIAL_PERMIT_VALIDATION_STATE,
  PermitValidationState,
} from './dataTemplate';
import DateEditInput from './DateEditInput';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import {
  ACTION_COLUMN_CONTAINER_STYLE,
  SELECT_MAX_NUMBER_CONTAINER_STYLE,
  TEXT_FIELD_ERROR_STYLE,
  TEXT_FIELD_NORMAL_STYLE,
} from '../style';
import {ThcValues} from './types';
import {validatePermit} from '../utils';
import {toggleModal} from 'src/store/modal/actions';
import {useAppDispatch} from 'src/utils/hooks';

type EditPermitFormLabels = {
  enterNumber: string;
  required: string;
  permitPlantLimit?: string;
  thcValue?: string;
};

type EditPermitFormProps = {
  initialPermit: OdcPermit | GmpPermit;
  labels: EditPermitFormLabels;
  onSubmit: (permit: OdcPermit | GmpPermit) => void;
  onDelete: () => void;
};

const canSubmitPermit = (data: PermitValidationState) => {
  return Object.values(data).some((value) => value.length > 0) === false;
};

export const EditPermitFormRow: React.FC<EditPermitFormProps> = ({
  initialPermit,
  labels,
  onSubmit,
  onDelete,
}) => {
  const dispatch = useAppDispatch();
  const [permit, setPermit] = useState<OdcPermit | GmpPermit>(initialPermit);
  const [permitValidation, setPermitValidation] = useState(
    INITIAL_PERMIT_VALIDATION_STATE,
  );
  const [maxNumberSelection, setMaxNumberSelection] = useState<
    'limitTo' | 'unlimited'
  >((permit as OdcPermit).maxNumber ? 'limitTo' : 'unlimited');

  const handleStateChange = (fieldName: string, value: any) => {
    const updatedValue =
      value instanceof Date ? moment(value).format('yyyy-MM-DD') : value;
    setPermit({...permit, [fieldName]: updatedValue});
    setPermitValidation({...INITIAL_PERMIT_VALIDATION_STATE});
  };

  const handleSubmit = (validateThc: boolean) => {
    const odcPermit = permit as OdcPermit;
    if (odcPermit.maxNumber) {
      if (odcPermit.maxNumber < odcPermit.generated) {
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Error',
            subtitle:
              'You cannot change the number of plants allowed to below the amount already generated',
          }),
        );
        return;
      }
    }
    if (
      validateThc &&
      !odcPermit.moreThanDefaultThc &&
      !odcPermit.lessThanDefaultThc
    ) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Error',
          subtitle: 'At least one THC value required.',
        }),
      );
      return;
    }
    const validatedPermitData = validatePermit(
      permit,
      labels,
      maxNumberSelection === 'limitTo',
    );
    setPermitValidation(validatedPermitData);
    if (canSubmitPermit(validatedPermitData)) {
      onSubmit(permit);
    }
  };

  const handleDelete = () => {
    onDelete();
  };
  const handleMaxNumberSelectionChange = (value: string) => {
    if (value === 'limitTo') {
      handleStateChange('maxNumber', '');
    }
    if (value === 'unlimited') {
      handleStateChange('maxNumber', undefined);
    }
    if (value === 'limitTo' || value === 'unlimited') {
      setMaxNumberSelection(value);
    }
  };
  const handleThcSelectionChange = (
    key: 'moreThanDefaultThc' | 'lessThanDefaultThc',
  ) => {
    setPermit((prev) => ({
      ...prev,
      [key]: !(prev as OdcPermit)[key],
    }));
  };
  return (
    <TableRow>
      <StyledTableCell align="left">
        <TextField
          placeholder={labels.enterNumber}
          variant="outlined"
          value={permit.permitNumber}
          onChange={(e) => {
            e.persist();
            handleStateChange('permitNumber', e.target.value);
          }}
          error={!!permitValidation.permitNumber}
          helperText={permitValidation.permitNumber}
        />
      </StyledTableCell>
      {labels.thcValue ? (
        <StyledTableCell align="left">
          <FormControlLabel
            value={'>1% THC' as ThcValues}
            control={
              <Checkbox
                defaultChecked={true}
                checked={(permit as OdcPermit).moreThanDefaultThc}
                onChange={() => handleThcSelectionChange('moreThanDefaultThc')}
                color="primary"
              />
            }
            label={'>1% THC' as ThcValues}
          />
          <FormControlLabel
            value={'≤1% THC' as ThcValues}
            control={
              <Checkbox
                checked={(permit as OdcPermit).lessThanDefaultThc}
                onChange={() => handleThcSelectionChange('lessThanDefaultThc')}
                color="primary"
              />
            }
            label={'≤1% THC' as ThcValues}
          />
        </StyledTableCell>
      ) : null}
      <StyledTableCell align="left">
        <DateEditInput
          style={
            !!permitValidation.permitStartDate
              ? TEXT_FIELD_ERROR_STYLE
              : TEXT_FIELD_NORMAL_STYLE
          }
          value={moment(permit.startDate) || null}
          id="date-picker-permit-start"
          onChange={(date) => {
            handleStateChange('startDate', date);
          }}
          errorText={permitValidation.permitStartDate as string}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <DateEditInput
          style={
            !!permitValidation.permitExpiryDate
              ? TEXT_FIELD_ERROR_STYLE
              : TEXT_FIELD_NORMAL_STYLE
          }
          value={moment(permit.expiryDate) || null}
          id="date-picker-permit-expiry"
          onChange={(date) => {
            handleStateChange('expiryDate', date);
          }}
          errorText={permitValidation.permitExpiryDate}
          minDate={moment(permit.startDate)?.toDate() || null}
          minDateErrorMessage={'Expiry date should be after the start date'}
        />
      </StyledTableCell>
      {labels.permitPlantLimit ? (
        <StyledTableCell align="left">
          <RadioGroup
            aria-label="permitPlantLimit"
            name="permitPlantLimit"
            value={maxNumberSelection}
            onChange={(e) => {
              handleMaxNumberSelectionChange(e.target.value);
            }}>
            <div style={SELECT_MAX_NUMBER_CONTAINER_STYLE}>
              <FormControlLabel
                value="limitTo"
                control={<Radio color="primary" />}
                label="Limit To"
              />
              <TextField
                variant="outlined"
                placeholder={labels.enterNumber}
                value={
                  isNaN((permit as OdcPermit).maxNumber)
                    ? ''
                    : (permit as OdcPermit).maxNumber
                }
                type="number"
                focused={maxNumberSelection === 'limitTo'}
                disabled={maxNumberSelection !== 'limitTo'}
                onChange={(e) => {
                  e.persist();
                  handleStateChange('maxNumber', parseInt(e.target.value));
                }}
                error={!!permitValidation.maxNumber}
                helperText={permitValidation.maxNumber}
              />
            </div>
            <FormControlLabel
              value="unlimited"
              control={<Radio color="primary" />}
              label="Unlimited"
            />
          </RadioGroup>
        </StyledTableCell>
      ) : null}
      <StyledTableCell align="left">
        <div style={ACTION_COLUMN_CONTAINER_STYLE}>
          <SaveIcon style={{cursor: 'pointer'}} onClick={() => handleSubmit(labels.thcValue?.length > 0)} />
          <DeleteIcon onClick={handleDelete} style={{cursor: 'pointer'}} />
        </div>
      </StyledTableCell>
    </TableRow>
  );
};
