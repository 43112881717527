import {GmpPermit, OdcPermit} from '@aglive/data-model/dist/misc/business';
import {TableRow} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import {StyledTableCell} from 'src/presentation/Table';
import {getThcValue} from '../utils';
import {ACTION_COLUMN_CONTAINER_STYLE} from '../style';

type PermitRowViewLabels = {
  permitPlantLimit?: string;
  thcValue?: string;
};

type PermitRowViewProps = {
  permit: OdcPermit | GmpPermit;
  labels: PermitRowViewLabels;
  onEdit: () => void;
  onDelete: () => void;
};
export const PermitRowView: React.FC<PermitRowViewProps> = ({
  permit,
  labels,
  onEdit,
  onDelete,
}) => {
  const expired = moment(permit.expiryDate).isBefore(moment.now());

  return (
    <TableRow>
      <StyledTableCell align="left">
        <span style={{fontSize: '1rem'}}>{permit.permitNumber}</span>
      </StyledTableCell>
      {labels.thcValue ? (
        <StyledTableCell align="left">
          <span style={{fontSize: '1rem'}}>{getThcValue(permit)}</span>
        </StyledTableCell>
      ) : null}
      <StyledTableCell align="left">
        <span style={{fontSize: '1rem'}}>
          {moment(permit.startDate).format('DD/MM/YYYY')}
        </span>
      </StyledTableCell>
      <StyledTableCell align="left">
        <span
          style={{
            fontSize: '1rem',
            ...(expired && labels.permitPlantLimit ? {color: 'red'} : {}),
          }}>
          {moment(permit.expiryDate).format('DD/MM/YYYY')}
        </span>
      </StyledTableCell>
      {labels.permitPlantLimit ? (
        <StyledTableCell align="left">
          <span style={{fontSize: '1rem'}}>
            {(permit as OdcPermit).maxNumber
              ? `${(permit as OdcPermit).maxNumber}`
              : 'Unlimited'}
          </span>
        </StyledTableCell>
      ) : null}
      <StyledTableCell align="left">
        {!expired || (expired && !labels.permitPlantLimit) ? (
          <div style={ACTION_COLUMN_CONTAINER_STYLE}>
            <EditIcon style={{cursor: 'pointer'}} onClick={() => onEdit()} />
            <DeleteIcon
              onClick={() => onDelete()}
              style={{cursor: 'pointer'}}
            />
          </div>
        ) : null}
      </StyledTableCell>
    </TableRow>
  );
};
