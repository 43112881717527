import {getBusinessProfile} from 'src/store/profile/actions';
import {toggleModal} from 'src/store/modal/actions';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from 'src/store/spinner/types';
import {AppThunk} from 'src/store/types';
import {callAPI} from 'src/utils/network';
import API from 'src/config/api';
import {WebErrorType} from 'src/utils/error';
import CONSTANT from 'src/config/constant';
import {AxiosRequestConfig} from 'axios';
import {
  ContactInvitationCode,
  RESET_INVATITION,
  ResetInvitation,
  SET_INVITATION,
  SetInvitation,
} from './types';

export function setInvitationAction(
  payload: ContactInvitationCode,
): SetInvitation {
  return {
    type: SET_INVITATION,
    payload: payload,
  };
}

export function resetInvitationAction(): ResetInvitation {
  return {
    type: RESET_INVATITION,
  };
}

// --- api actions ----
export type SearchContactResponse = {
  businessId: string;
  businessName: string;
  userName: string;
  userEmail: string;
  userId: string;
};

export function searchContact(
  query: string,
): AppThunk<Promise<Array<SearchContactResponse>>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI<SearchContactResponse[]>({
        url: API.GET.getComponentData,
        method: 'GET',
        params: {
          category: 'widget',
          subcategory: 'search',
          name: 'businessSearch',
          input: {
            search: query,
          },
        },
      });
      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return [];
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function unlinkContact(
  data: {
    contactId: string;
  },
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          data: data,
          activityType: 'delContact',
          businessId: agliveToken_business,
        }),
      );
      await dispatch(getBusinessProfile());
      console.log('unlink contact response?', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type SaveContactRequest = {
  contactId: string;
  name: string;
  email: string;
  businessName: string;
};
export function saveContact(
  data: SaveContactRequest,
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          activityType: 'upContact',
          businessId: agliveToken_business,
          data: data,
        }),
      );
      await dispatch(getBusinessProfile());
      console.log('saveContact response?', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type InviteContactRequest = {viewAllConsignments: boolean} & (
  | {
      invitedThrough: 'userId';
      recipientId: string;
    }
  | {
      invitedThrough: 'email';
      email: string;
    }
);

export function inviteContact(
  data: InviteContactRequest,
  onSuccess?: () => void,
  onFailure?: (error: WebErrorType) => void
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          activityType: 'addContact',
          businessId: agliveToken_business,
          data: data,
        }),
      );
      console.log('inviteContact response', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      onFailure(error);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type ReinviteContactRequest = {
  type: 'contact';
  contactId?: string;
};
export function inviteContactAgain(
  data: ReinviteContactRequest,
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          activityType: 'resendInvitationCode',
          businessId: agliveToken_business,
          data: data,
        }),
      );
      console.log('inviteContactAgain response,', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function revokeContact(
  data: {
    contactId: string;
  },
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          data: data,
          activityType: 'revokeContact',
          businessId: agliveToken_business,
        }),
      );
      console.log('revokeContact response?', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function rejectInvitation(
  data: {
    contactId: string;
  },
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          activityType: 'rejectInvitation',
          data: data,
          businessId: agliveToken_business,
        }),
      );
      console.log('rejectInvitation response?', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function approveInvitation(
  data: {
    contactId: string;
  },
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {user} = getState();
      const agliveToken_business = user?.userProfileData?.businessId;
      if (!agliveToken_business) {
        return;
      }
      const response = await callAPI(
        createContactActivity({
          data: data,
          activityType: 'acceptInvitation',
          businessId: agliveToken_business,
        }),
      );
      console.log('acceptInvitation response?', response);
      onSuccess?.();
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function bulkApproveInvitation(
  data: Array<{
    contactId: string;
  }>,
  onSuccess?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {user} = getState();
    const agliveToken_business = user?.userProfileData?.businessId;
    if (!agliveToken_business) {
      return;
    }
    dispatch({type: SPINNER_TOGGLE_ON});
    let hasError = false;
    for (let idx = 0; idx < data.length; idx++) {
      const requestData = data[idx];
      try {
        const response = await callAPI(
          createContactActivity({
            data: requestData,
            activityType: 'acceptInvitation',
            businessId: agliveToken_business,
          }),
        );
        console.log('acceptInvitation response?', requestData, response);
      } catch (e) {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
        hasError = true;
      }
    }
    if (!hasError) {
      onSuccess?.();
    }
    dispatch({type: SPINNER_TOGGLE_OFF});
  };
}

export function validateInvitationCode(params: {
  code: string;
  type: string;
}): AppThunk<Promise<boolean>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const validationRes = await callAPI({
        url: API.GET.validateCode,
        method: 'GET',
        params: params,
      });
      console.log('acceptInvitation response?', validationRes);
      // await dispatch(getBusinessProfile());
      console.log('validationRes', validationRes);
      return validationRes?.status === 'valid';
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return false;
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function linkInvitationCode(data: {invitationCode: string}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {user} = getState();
    const agliveToken_business = user?.userProfileData?.businessId;
    if (!agliveToken_business) {
      return;
    }
    dispatch({type: SPINNER_TOGGLE_ON});
    try {
      const response = await callAPI(
        createContactActivity({
          data: data,
          activityType: 'linkInvitationCode',
          businessId: agliveToken_business,
        }),
      );
      console.log('linkInvitationCode response?', response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Link invitation code',
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }
}

function createContactActivity({
  businessId,
  data,
  activityType,
}: {
  businessId: string;
  activityType: string;
  data: any;
}): AxiosRequestConfig {
  return {
    url: API.POST.createActivity,
    method: 'POST',
    data: {
      tokens: [
        {
          type: CONSTANT.ASSETTYPE.BUSINESS,
          externalIds: {agliveToken: businessId},
          activities: [
            {
              type: activityType,
              details: data,
            },
          ],
        },
      ],
    },
  };
}
