import React, {useEffect, useState} from 'react';
import COLOR from '../../styled/colors';
import MyButton from '../../presentation/button';
import Button from '@material-ui/core/Button';
import {makeStyles, Grid, Typography, Box} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {PageHeader} from '../../presentation/withHeader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {SiteTreeLayerNode} from '../../store/site/types';
import {flattenTree} from '../../store/site/tree';
import {Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import {addMoveActivity, PlantSite} from '../../store/activity/actions';
import {useHistory} from 'react-router-dom';
import ConfirmMoveModal from './ConfirmMoveModal';
import {fetchGroupTokens} from '../../store/activity/actions';
import CONSTANT from '../../config/constant';
import {callAPI} from '../../utils/network';
import API from '../../config/api';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {GroupDataTypes} from '../../store/activity/types';
import {toggleModal} from '../../store/modal/actions';
import {TokenService} from '@aglive/data-model';

type MoveHistoryState = {
  group: string;
  site: string;
  dest: string;
};

const CreateMove: React.FC<{}> = () => {
  const [selectedGroup, setSelectedGroup] = useState('');
  const [destination, selectedDestination] = useState('');
  const [selectedSite, setSelectedSite] = useState('');
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const industryType = useAppSelector(
    (state) => state.user.businessProfileData.industryType,
  );
  const groupList = useAppSelector((state) => state.activity.groups);
  const userid = useAppSelector((state) => state.auth.wallet);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const siteTokensResArr = useAppSelector((state) => state.site);

  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;

  const groupHandleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const index = event.target.value;
    setSelectedGroup(index as string);
    setSelectedSite('');
  };

  const picHandleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const index = event.target.value;
    selectedDestination(index as string);
  };
  const sitHandleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    const index = event.target.value;
    setSelectedSite(index as string);
  };
  const groupMoveList: GroupDataTypes['activities'] = React.useMemo(
    () => getGroupMoveActivities(groupList, selectedGroup),
    [groupList, selectedGroup],
  );

  const groupPIC =
    groupMoveList?.length > 0
      ? groupMoveList[groupMoveList.length - 1].details?.destination.location
      : groupList[Number(selectedGroup)]?.details.pic_id;

  const [groupDetailsList, setGroupDetailsList] = useState(
    [] as {key: string; value: string}[],
  );

  const siteTokensUnderGroup = React.useMemo(
    () =>
      siteTokensResArr.filter((token) => token.details.location === groupPIC),
    [siteTokensResArr, groupPIC],
  ); //move between same pic

  const siteNames = React.useMemo(() => {
    if (!siteTokensUnderGroup.length) {
      return [];
    }
    const result = flattenTree(siteTokensUnderGroup[0]?.details.layers)
      .slice(1)
      .map((site) => (typeof site === 'string' ? site : site.layerName));
    return result;
  }, [siteTokensUnderGroup]);

  const editExternal = siteTokensUnderGroup[0]?.externalIds[0].agliveToken;
  const [attachForms] = useState([{type: '', url: '', checked: false}]);
  const [attachFiles] = useState([{url: ''}]);
  const [declarationChecked] = useState(false);
  const [buttonAvailable, setButtonAvailable] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [gotIt, setGotIt] = useState(true);

  useEffect(() => {
    if (selectedGroup === '' || !groupPIC || !groupList.length) {
      return;
    }
    (async () => {
      const siteName = await getGroupSiteName(
        dispatch,
        groupList,
        selectedGroup,
        () => {
          setSelectedGroup('');
        },
      );
      const groupDetails = [
        {
          key: 'Items:',
          value: groupList[Number(selectedGroup)]?.details.items.length,
        },
        {key: 'Location:', value: groupPIC},
        {key: 'Site:', value: siteName},
      ];
      setGroupDetailsList(groupDetails);
    })();
  }, [dispatch, groupList, groupPIC, selectedGroup]);

  useEffect(() => {
    //move between same pic
    if (selectedGroup !== '' && selectedSite !== '' && destination !== '') {
      setButtonAvailable(true);
    }
  }, [
    selectedSite,
    destination,
    selectedGroup,
    attachFiles,
    attachForms,
    declarationChecked,
  ]);

  useEffect(() => {
    if (!userid) {
      return;
    }
    dispatch(fetchGroupTokens(userid, [CONSTANT.ASSETTYPE.SITE]));
    if (history.location.state && isMoveHistoryState(history.location.state)) {
      const {group, site, dest} = history.location.state;
      setSelectedGroup(group ?? '');
      setSelectedSite(site ?? '');
      selectedDestination(dest ?? '');
    }
  }, [dispatch, history.location.state, userid]);

  const setHistoryState = () => {
    history.replace({
      ...history.location,
      state: {
        group: selectedGroup,
        site: selectedSite,
        dest: destination,
      },
    });
  };

  const isMoveHistoryState = (state: unknown): state is MoveHistoryState => {
    const keys = ['group', 'site', 'dest'];
    return keys.every((key) => Object.keys(state).includes(key));
  };

  return (
    <>
      <PageHeader
        config={{
          title: 'Move',
          margin: 60,
          back: true,
        }}>
        <Grid alignItems="center" container className={classes.bodyContainer}>
          <Grid container className={classes.rowContainer} spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h3" role="label" style={{marginBottom: 8}}>
                From:
              </Typography>
              <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                <Typography variant="h6" role="label" style={{marginBottom: 8}}>
                  Select Group*
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {selectedGroup === '' && (
                    <InputLabel id="create-move-select-group" shrink={false}>
                      {'Please select'}
                    </InputLabel>
                  )}
                  <Select
                    id="SelectGroup"
                    labelId="create-move-select-group"
                    value={selectedGroup}
                    onChange={groupHandleChange}>
                    {groupList.map((group, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {group.details.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid
                  item
                  className={
                    selectedGroup === ''
                      ? classes.groupDetail2
                      : classes.groupDetail
                  }>
                  {groupDetailsList.map((detail) => {
                    return (
                      <div
                        className={classes.groupDetailRow}
                        key={detail.key + '0'}>
                        <Typography
                          variant="h6"
                          role="label"
                          className={classes.groupDetailTitle}
                          key={detail.key + '1'}>
                          {selectedGroup === '' ? (
                            <span>&nbsp;&nbsp;</span>
                          ) : (
                            <div>{detail.key}</div>
                          )}
                        </Typography>
                        <Typography
                          variant="h6"
                          role="label"
                          key={detail.key + '2'}>
                          {selectedGroup === '' ? (
                            <span>&nbsp;&nbsp;</span>
                          ) : (
                            <div>{detail.value}</div>
                          )}
                        </Typography>
                      </div>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  className={
                    gotIt === true ? classes.groupDetail : classes.groupDetail2
                  }>
                  <Grid item className={classes.groupDetailRow}>
                    <Typography
                      role="label"
                      style={{
                        flex: 1.3,
                        fontSize: 12,
                        marginTop: 10,
                        marginLeft: 16,
                      }}>
                      {gotIt === true ? (
                        <>
                          <span style={{fontWeight: 600}}>{'Tip:'}</span>{' '}
                          {
                            'Groups can be created by scanning individual items in the moblie app'
                          }
                        </>
                      ) : (
                        <span>&nbsp;&nbsp;</span>
                      )}
                    </Typography>
                    <Button onClick={() => setGotIt(false)}>
                      {gotIt === true ? (
                        <span
                          style={{
                            color: COLOR.GREENT_TEXT,
                            fontSize: 12,
                            flex: 1.1,
                          }}>
                          <u>{'OK, got it'}</u>
                        </span>
                      ) : (
                        <span>&nbsp;&nbsp;</span>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3" role="label" style={{marginBottom: 8}}>
                Destination:
              </Typography>
              <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                <Typography variant="h6" role="label" style={{marginBottom: 8}}>
                  {industryType === 'PLANTS' ? (
                    <>Select Location</>
                  ) : (
                    <>
                      Select{' '}
                      {isAustralia
                        ? CONSTANT.LOCALISATION_PIC.AU_LOCATION_PIC
                        : CONSTANT.LOCALISATION_PIC.CA_LOCATION_PID}
                    </>
                  )}
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {destination === '' && (
                    <InputLabel id="create-move-select-location" shrink={false}>
                      {'Please select'}
                    </InputLabel>
                  )}
                  <Select
                    labelId="create-move-select-location"
                    value={destination}
                    id="SelectLocation"
                    onChange={picHandleChange}>
                    <MenuItem value={'0'}>{groupPIC}</MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.groupDetailRow}>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{marginBottom: 8, marginTop: 24}}>
                    Select Site Map
                  </Typography>
                  <Tooltip
                    title="If you create a Site Map, we can help you track movements of registered items (e.g. plants, animals) from one location to another within this site"
                    style={{marginTop: 12, color: COLOR.GREENT_TEXT}}>
                    <IconButton aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {selectedSite === '' && (
                    <InputLabel id="create-move-select-site" shrink={false}>
                      {'Please select'}
                    </InputLabel>
                  )}
                  <Select
                    labelId="create-move-select-site"
                    value={selectedSite}
                    id="SelectSite"
                    onChange={sitHandleChange}>
                    {siteNames?.map((siteLayerName, index) => {
                      return (
                        <MenuItem value={index} key={index}>
                          {siteLayerName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selectedGroup !== '' && (
                  <>
                    <div
                      style={{flexDirection: 'row-reverse', display: 'flex'}}>
                      {editExternal ? (
                        <Link
                          to={'/private/register/site/edit/' + editExternal}
                          onClick={setHistoryState}
                          style={{
                            color: COLOR.GREENT_TEXT,
                            fontSize: 16,
                            marginTop: 8,
                          }}>
                          Edit Site Map
                        </Link>
                      ) : (
                        <Link
                          to="/private/register/site/new"
                          onClick={setHistoryState}
                          style={{
                            color: COLOR.GREENT_TEXT,
                            fontSize: 16,
                            marginTop: 8,
                          }}>
                          Create Site Map
                        </Link>
                      )}
                    </div>
                    <div
                      style={
                        selectedGroup === '' ? {marginTop: 8} : {marginTop: 22}
                      }>
                      <span>&nbsp;&nbsp;</span>
                    </div>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{marginTop: 58}}>
            <MyButton
              text="Save"
              variant="contained"
              disabled={!buttonAvailable}
              onClick={() => {
                setModalOpen(true);
              }}
            />
          </Grid>
        </Grid>
      </PageHeader>
      {modalOpen && (
        <ConfirmMoveModal
          open={modalOpen}
          groupName={groupList[Number(selectedGroup)].details.name}
          originPic={groupDetailsList[1].value}
          originSite={groupDetailsList[2].value}
          // destinationPic={picList[destination]}
          destinationPic={groupPIC}
          destinationSite={siteNames[Number(selectedSite)]}
          handleClose={() => {
            setModalOpen(false);
          }}
          rightButtonClick={() => {
            const siteLayers = flattenTree(
              siteTokensUnderGroup[0].details.layers,
            );
            const currentGroup = groupList[Number(selectedGroup)];
            const originLayerName = groupDetailsList[2]?.value;
            const originLocation = groupDetailsList[1].value;
            const destLayerName = siteNames[Number(selectedSite)];
            const siteAgliveToken =
              siteTokensUnderGroup[0]?.externalIds[0]?.agliveToken; // this is same for all layers in a site
            const originSite = getGroupSiteByLayerName(
              siteLayers,
              originLayerName,
              siteAgliveToken,
            );
            const destSite = getGroupSiteByLayerName(
              siteLayers,
              destLayerName,
              siteAgliveToken,
            );
            dispatch(
              addMoveActivity(
                currentGroup,
                originLocation,
                originSite,
                groupPIC,
                destSite,
                attachForms,
                attachFiles,
                () => {
                  history.goBack();
                },
              ),
            );
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};

function getGroupMoveActivities(
  groupList: Array<GroupDataTypes>,
  selectedGroup: string,
) {
  return (
    groupList[Number(selectedGroup)]?.activities.filter(
      (activity) => activity.type === 'move',
    ) ?? []
  );
}

function getGroupSiteByLayerName(
  siteLayers: Array<SiteTreeLayerNode>,
  layerName: string,
  agliveToken: string,
): string | PlantSite {
  const destSite = siteLayers.find((x) => x.layerName === layerName);
  const site = destSite?.layerName
    ? {
        layerName: destSite.layerName,
        layerId: destSite.id,
        agliveToken: agliveToken,
      }
    : layerName;
  return site;
}

type GroupMoveActivity = {
  details: {
    destination: {
      site:
        | string
        | {
            agliveToken: string;
            layerName: string;
            layerId: string;
          };
    };
  };
};

function getLatestSiteFromGroup(group: GroupDataTypes) {
  const moveActivities: Array<GroupMoveActivity> = group.activities.filter(
    (a) => (a as any).type === 'move',
  ) as any;
  const latestDest =
    moveActivities[moveActivities.length - 1]?.details?.destination?.site;
  return latestDest;
}

function getLatestSiteFromToken(group: TokenService.Token) {
  const moveActivities: Array<GroupMoveActivity> = group.activities.filter(
    (a) => (a as any).type === 'move',
  ) as any;
  const latestDest =
    moveActivities[moveActivities.length - 1]?.details?.destination?.site;
  return latestDest;
}

async function fetchGroupSiteName(groupToken: GroupDataTypes) {
  const groupItems = await callAPI<Array<TokenService.Token>>({
    url: API.POST.getTokenbyExternalId,
    method: 'POST',
    data: {
      externalIds: groupToken?.details.items.map((item) => {
        let copy = {...item}
        Object.entries(copy).forEach(([key, val]) => {
          if (!val) {
            delete copy[key]
          }
        })
        return copy;
      }),
    },
  });

  const sites = groupItems.reduce((result, token) => {
    const latestDest = getLatestSiteFromToken(token);
    if (latestDest) {
      result.push(latestDest);
    }
    return result;
  }, [] as Array<ReturnType<typeof getLatestSiteFromToken>>);

  const layerNames = sites.map((s) => {
    return typeof s === 'string' ? s : s.layerName;
  });
  let groupSiteName = '';
  if (layerNames.length > 0) {
    groupSiteName = layerNames.some((l) => l !== layerNames[0])
      ? CONSTANT.MULTIPLE_SITES
      : layerNames[0];
  }
  return groupSiteName;
}

async function getGroupSiteName(
  dispatch: ReturnType<typeof useAppDispatch>,
  groupList: Array<GroupDataTypes>,
  groupKey: string,
  onError: () => void,
): Promise<string | undefined> {
  if (groupKey !== '') {
    const currentGroup = groupList[Number(groupKey)];
    const currentGroupSite = getLatestSiteFromGroup(currentGroup);
    const currentSiteName =
      typeof currentGroupSite === 'string'
        ? currentGroupSite
        : currentGroupSite?.layerName;
    if (currentSiteName) {
      return currentSiteName;
    }
    let groupSiteName = undefined;
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      groupSiteName = await fetchGroupSiteName(currentGroup);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
      onError();
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
    return groupSiteName;
  }
}

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 10,
  },
  rowContainer: {
    flexGrow: 1,
  },
  rowContainer2: {
    flexGrow: 1,
    marginTop: 24,
  },
  rowContainer3: {
    flexGrow: 1,
    marginTop: 24,
    marginBottom: 50,
  },
  formControl: {
    background: COLOR.WHITE,
  },
  groupDetail: {
    background: COLOR.WHITE,
    width: '100%',
    marginTop: 24,
    border: `1px solid ${COLOR.GRAY_BORDER}`,
  },
  groupDetail2: {
    width: '100%',
    marginTop: 24,
  },
  groupDetailTitle: {
    marginRight: 24,
    marginLeft: 16,
    fontWeight: 600,
  },
  groupDetailRow: {
    marginTop: 4,
    marginBottom: 8,
    flexDirection: 'row',
    display: 'flex',
  },
  contentButton: {
    borderColor: COLOR.GREEN,
    color: COLOR.GREEN,
  },
}));
export default CreateMove;
