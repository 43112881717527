import React from 'react';
import {Grid, Box} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import TabHeading from '../TabHeading';
import COLOR from '../../../styled/colors';
import LabelData from '../../../presentation/LabelData';

type displayType = {
  mediaOption: TokenService.ProductToken['details']['pages'][0]['display']['item'];
  mediaUrl: TokenService.ProductToken['details']['pages'][0]['display']['url'];
  headline1: string;
  headline2: string;
  headline3: string;
};

const ViewProductTabDisplay: React.FC<displayType> = ({
  mediaOption,
  mediaUrl,
  headline1,
  headline2,
  headline3,
}) => {
  return (
    <Grid item container direction="column">
      <TabHeading
        heading="Image or Video*"
        subheading="Enhance your story with an image or video"
      />
      <Box
        p={3}
        style={{
          backgroundColor: COLOR.GRAY_SOLID,
          marginBottom: 5,
        }}>
        <LabelData
          label="Image"
          field={
            mediaOption === 'image'
            ? decodeURIComponent(mediaUrl?.split('/').reverse()[0]) || '-'
            : '-'}
          valueId="DisplayImage"
        />
        <LabelData
          label="Video Link"
          field={mediaOption === 'video' ? mediaUrl || '-' : '-'}
          valueId="DisplayVideo"
        />
      </Box>

      <TabHeading
        heading="Headline"
        subheading="Capture your audience's attention"
        containerStyle={{margin: '20px 0'}}
      />

      <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
        <LabelData
          label="Line 1"
          placeHolder="Enter Text"
          field={headline1}
          characters={30}
          valueId="Headline0"
        />
        <LabelData
          label="Line 2"
          placeHolder="Enter Text"
          field={headline2}
          characters={30}
          valueId="Headline1"
        />
        <LabelData
          label="Line 3"
          placeHolder="Enter Text"
          field={headline3}
          characters={30}
          valueId="Headline2"
        />
      </Box>
    </Grid>
  );
};

export default ViewProductTabDisplay;
