import React, { useEffect, useReducer } from 'react';
import moment from 'moment';
import { useHistory, useLocation, Link as RouterLink} from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

import { FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Radio, Select, TextField, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import MyButton from '../../../../presentation/button';
import ProgressBar from '../../../../presentation/ProgressBar';
import CreateProductProfileDelivery from '../../../promotion/ProductProfile/CreateProductProfileDelivery';
import MyTable from '../../../../presentation/Table';

import localActions from './actions';
import { fetchProductProfileTokens } from '../../../promotion/ProductProfileLibrary/utils';
import { toggleModal } from '../../../../store/modal/actions';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../../store/spinner/types';
import { callAPI } from '../../../../utils/network';

import withHeader from '../../../../presentation/withHeader';

import useStyles from './styles';

import COLOR from '../../../../styled/colors';
import API from '../../../../config/api';

import { TokenService } from '@aglive/data-model';
import { WebErrorType } from '../../../../utils/error';
import { iCodeEntryDocumentRequest } from '../../../../store/code/types';
import { INITIAL_CODE_STATE, securityCodeReducer } from './reducer';
import { REMOVE_TEMP_PRODUCT } from '../../../../store/product/types';

const CREATE_PRODUCT_PATH = '/private/products/productProfile/new';
const SECURITY_CODE_LIBRARY_PATH = '/private/codes/securityCodes';

const TABLE_HEADER = ['Level', 'Name', 'Quantity to Generate'];
const CODE_OPTIONS = ['item', 'carton', 'pallet'] as Array<TokenService.ProductSecurityCode["details"]["container"]>;

const GenerateSecurityCode: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const userID = useAppSelector(state => state.auth.wallet);
  const tempProduct = useAppSelector(state => state.product.tempProduct);
  const [securityCodeState, localDispatch] = useReducer(securityCodeReducer, INITIAL_CODE_STATE());

  useEffect(() => {
    fetchProductProfileTokens()
      .then(productProfiles => 
        localDispatch(
          localActions.loadProductProfileTokens(productProfiles.filter(profiles => !profiles.details.archive))
        )
      )
      .catch(e => {
        const error = e as WebErrorType;

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          })
        );
      });
  }, []);

  useEffect(() => {
    // the securityCodeState.state.isValidated serves as a relay and is only changed by onSubmit->reducer->validation
    // if all tests pass then isValidated = 'true', useEffect hook will be triggered on state change and trigger the following submission logic
    if (securityCodeState.state.isValidated) {
      onGenerate();
    }
  }, [securityCodeState.state.isValidated]);

  useEffect(() => {
    /**
     * If temp product is found in redux, it means that the user just
     * returned from create product profile page. Thus, populate the dropdown
     * with the created product profile and remove the temp product from redux store.
     */
    if (tempProduct && securityCodeState.state.product.productProfileArray.length > 0) {
      localDispatch(localActions.selectProductProfileId(tempProduct.externalIds.find(
        (externalId) => 'agliveToken' in externalId,
      ).agliveToken));
      dispatch({type: REMOVE_TEMP_PRODUCT});
    }
  }, [tempProduct, dispatch, securityCodeState.state.product.productProfileArray]);

  const onGenerate = async () => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      // retrieve fresh user profile and business profile
      const userProfileRes = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          type: ['user']
        },
      });

      const userProfile: TokenService.UserToken = userProfileRes[0];

      const businessProfileRes = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          externalIds: [{agliveToken: userProfile.details.businessId}],
        },
      });
      const businessProfile: TokenService.BusinessToken = businessProfileRes[0];

      const createTokenPayload = {
        type: securityCodeState.state.product.selectedProductProfile.type,
        externalIds: {
          agliveToken: securityCodeState.state.product.selectedProductProfile.externalIds[0].agliveToken,
        },
        activities: [{
          type: 'generateSecurityCode',
          details: {
            securityCodeType: securityCodeState.payload.container,
            quantity: Number(securityCodeState.state.quantity),
            product: securityCodeState.state.product.selectedProductProfile.externalIds[0],
            ...securityCodeState.payload,
            date: moment().toISOString(), 
            source: {
              companyName: businessProfile.details.companyName,
              companyNumber: businessProfile.details.companyNumber,
              creatorEmail: userProfile.details.emailAddress,
            },
          } as TokenService.ProductSecurityCode["details"]
        }]
      };

      // submit generate code request
      // add a generateSecurityCode activity to productProfile
      const generatedTokens: {message: string, data: Array<string>} = await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [createTokenPayload],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Creating Codes',
          subtitle: 'Security codes are being created now, this can take up to 10 minutes to complete.  Please check back later.',
          button: 'Close',
          CTAHandler: () => history.push(SECURITY_CODE_LIBRARY_PATH),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant='h6'
          style={{ 
            fontWeight: 'bold',
            marginBottom: 10,
          }}
        >
          {"Product Name"}
        </Typography>
        <Grid  direction="column" container className={classes.productContainer}>
          <Grid item container>
            <FormControl variant="outlined" error={!!securityCodeState.validation.product.length}>
              <InputLabel id="code-product">{"Please select"}</InputLabel>
              <Select
                label={"Please Select"}
                labelId="code-product"
                value={securityCodeState.payload.product.agliveToken}
                className={classes.dropdownContainer}
                onChange={event => localDispatch(localActions.selectProductProfileId(event.target.value as string))}
                open={securityCodeState.state.product.isOpen}
                onOpen={() => localDispatch(localActions.toggleProductDropdown())}
                onClose={() => localDispatch(localActions.toggleProductDropdown())}
                id="ProductProfile-Dropdown"
              >
                {securityCodeState.state.product.productProfileArray.map(({externalIds, details}) => 
                  <MenuItem value={externalIds[0].agliveToken} key={externalIds[0].agliveToken}>
                    {details.name}
                  </MenuItem>
                )}
              </Select>
              <FormHelperText id="ProductProfile-helper-text">{securityCodeState.validation.product}</FormHelperText>
            </FormControl>

            <Grid item className={classes.buttonContainer}>
              <RouterLink
                to={{
                  pathname: CREATE_PRODUCT_PATH,
                  state: {prevLocation: location.pathname},
                }}
                className={classes.hyperlink}>
                Create a product now
              </RouterLink>
            </Grid>
          </Grid>

          <Grid item style={{alignSelf: 'center', width: '60%'}}>
            <ProgressBar
              percent={securityCodeState.state.progressBarPosition}
              steps={[{
                label: 'Add Details',
                onClick: () => localDispatch(localActions.navigatePages('deliveryHistory'))
              }, {
                label: 'Generate Codes',
                onClick: () => localDispatch(localActions.navigatePages('generateCode'))
              }]}
            />
          </Grid>

          <Grid item container style={{marginTop: 50}}>
            {(() => {
              switch (securityCodeState.state.section) {
                case 'deliveryHistory':
                  return (
                    <CreateProductProfileDelivery
                      showDeliveryHistory={securityCodeState.payload.showDeliveryHistory}
                      showMap={securityCodeState.payload.showMap}
                      isConfidential={securityCodeState.payload.isConfidential}
                      onHistoryToggle={() => localDispatch(localActions.toggleDeliveryHistory())}
                      onMapViewToggle={() => localDispatch(localActions.toggleDeliveryMapView())}
                      onConfidentialModeToggle={() => localDispatch(localActions.toggleDeliveryConfidentialMode())}

                      mediaType={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].display.item}
                      mediaUrl={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].display.url}
                      headline1={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].headline[0]}
                      headline2={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].headline[1]}
                      headline3={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].headline[2]}
                      contentArr={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].content}
                      attachmentArr={securityCodeState.state.product.selectedProductProfile?.details?.pages[0].attachment}
                    />
                  );
                case 'generateCode':
                  return (
                    <Grid item container>
                      <Typography 
                        variant='h6'
                        style={{ 
                          fontWeight: 'bold',
                          marginBottom: 20,
                        }}
                      >
                        {"Select to Generate"}
                      </Typography>
                      <MyTable
                        heads={['', ...TABLE_HEADER]}
                        rows={CODE_OPTIONS.map((level, index) => ([
                          <Radio
                            color='primary'
                            checked={securityCodeState.payload.container === level}
                            onChange={e => localDispatch(localActions.selectProductContainer(e.target.value as TokenService.ProductSecurityCode["details"]["container"]))}
                            value={level}
                            name={`radio-${level}`}
                            inputProps={{ 'aria-label': level }}
                            id={`${level}-Radio`}
                          />,
                          index + 1,
                          level.charAt(0).toUpperCase() + level.slice(1),
                          <TextField
                            type="number"
                            size="small"
                            disabled={securityCodeState.payload.container !== level}
                            value={securityCodeState.payload.container === level ? securityCodeState.state.quantity : ''}
                            onChange={e => localDispatch(localActions.inputCodeQuantity(e.target.value))}
                            margin="normal" 
                            variant="outlined"
                            InputLabelProps={{
                              shrink: false,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            error={securityCodeState.payload.container === level && !!securityCodeState.validation.quantity.length}
                            helperText={
                              securityCodeState.payload.container === level
                              ? securityCodeState.validation.quantity
                              : ''
                            }
                            id={`${level}-TextField`}
                          />
                        ]))}
                      />
                    </Grid>
                  );
              }
            })()}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justify="space-between"
        style={{
          borderTop: `1px solid ${COLOR.GRAY_BORDER}`,
          marginTop: 40,
          paddingTop: 20,
          marginBottom: 75,
        }}>
          <Link
            component="button"
            underline="always"
            variant="body2"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility: securityCodeState.state.section !== 'deliveryHistory' ? 'visible' : 'hidden'
            }}
            onClick={() => localDispatch({ type: 'navigate/prev_step' })}
          >
            <ChevronLeftIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginRight: 10,
              }}
            />
            Previous Step
          </Link>

          <Link
            component="button"
            underline="always"
            variant="body2"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility: securityCodeState.state.section !== 'generateCode' ? 'visible' : 'hidden'
            }}
            onClick={() => localDispatch({ type: 'navigate/next_step' })}
            id="NextStep"
          >
            Next Step
            <ChevronRightIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginLeft: 10,
              }}
            />
          </Link>

      </Grid>

      <Grid item container justify="flex-end">
        <MyButton
          text="Generate"
          variant="contained"
          disabled={!securityCodeState.state.quantity.length || !securityCodeState.state.canSubmit}
          onClick={() => localDispatch(localActions.submitForm())}
          id="GenerateBtn"
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: "Generate Security Codes",
    back: true
  },
  GenerateSecurityCode
);
